import { useGetEntity, useNotistack, useQueryParams } from '../../../shared/hooks';
import { StatusType } from '../components/Status';
import { getCaseEntityName } from '../../../shared/services';
import { useCaseController } from './useCaseController';
import { useAppState } from '../../../shared/state';
import { useCaseExtraParams } from './useCaseExtraParams';
import { useTranslation } from 'react-i18next';

export function useChangeStatus(caseId: number, statusId: number) {
	const { changeCaseStatus } = useCaseController();
	const { showNotification } = useNotistack();
	const { changeUrlParams } = useQueryParams();
	const { companyId, caseType, searchQuery, isStaff, setStatusChangePayload } = useAppState();
	const { selectedFilterTemplate } = useCaseExtraParams({
		statusId
	});
	const { t } = useTranslation();

	const entityName = getCaseEntityName({
		statusId,
		caseType,
		searchQuery,
		selectedFilterTemplate
	});

	const { all: currentStatusCaseEntity } = useGetEntity({
		entity: 'cases',
		entityId: statusId,
		entityName
	});

	const currentCaseIndex = (currentStatusCaseEntity?.items as any[]).findIndex((cs) => {
		return (cs.case_ ? cs.entity_id : cs.id) === caseId;
	});
	const currentCase = (currentStatusCaseEntity?.items as any[]).find((cs) => {
		return (cs.case_ ? cs.entity_id : cs.id) === caseId;
	});

	const handleStatusChange = async (status: StatusType, isCaseActive?: boolean) => {
		//Send Status Change Request
		try {
			await changeCaseStatus({
				prevStatusId: statusId,
				nextStatusId: status.id,
				isStaffCase: isStaff,
				sourceIndex: currentCaseIndex,
				destinationIndex: 0,
				entityId: Number(caseId),
				searchEntityId: currentCase?.case_ ? currentCase.id : caseId,
				isDragging: false
			});

			//change status.id  query param in url
			if (isCaseActive) {
				changeUrlParams({ status: status.id });
			}
		} catch (e) {
			console.log('something went wrong', e);
			showNotification({ message: t('something_went_wrong'), variant: 'error' });
		}
	};
	const statusesEntityName = `AllStatuses-${companyId}`;
	const { all: statuses } = useGetEntity({
		entity: 'statuses',
		entityName: statusesEntityName
	});

	const handleMobileStatusChange = () => {
		setStatusChangePayload({
			prevStatus: statusId,
			searchEntityId: currentCase.case_ ? currentCase.id : caseId,
			entityId: caseId,
			sourceIndex: currentCaseIndex
		});
	};

	return { handleStatusChange, statuses, handleMobileStatusChange };
}
