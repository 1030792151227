import { withStyles } from '@material-ui/core/styles';
import { TableCell } from '../TableCell';

export const StyledTableCell = withStyles(() => ({
	// root: {
	// 	borderBottom: '.5px solid rgba(112, 124, 151, 0.25)',
	// 	borderTop: '.5px solid rgba(112, 124, 151, 0.25)',
	// 	'&:first-child': {
	// 		borderRadius: '10px 0 0 10px',
	// 		borderLeft: '.5px solid rgba(112, 124, 151, 0.25)'
	// 	},
	// 	'&:last-child': {
	// 		borderRadius: '0 10px  10px 0',
	// 		borderRight: '.5px solid rgba(112, 124, 151, 0.25)'
	// 	}
	// },
	// body: {
	// 	fontSize: 14
	// }
}))(TableCell);
