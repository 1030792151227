import dayjs from 'dayjs';

const locales = {
	UZ: () => import('dayjs/locale/uz-latn'),
	RU: () => import('dayjs/locale/ru'),
	EN: () => import('dayjs/locale/en'),
	AR: () => import('dayjs/locale/ar')
};

export const setDayJsLocale = (language: 'UZ' | 'RU' | 'EN') => {
	locales[language]?.().then(() => {
		const altLang = language === 'UZ' ? 'uz-latn' : language.toLowerCase();
		dayjs.locale(altLang);
	});
};
