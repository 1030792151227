import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
// import './index.css';
import 'assets/styles/tailwind.css';
import 'assets/styles/custom-styles.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { history } from 'store';
import { sentry, api } from 'shared/services';
import { configure as configureStore } from 'store';
import Routes from './routes';
import AppStateProvider from 'shared/state';
import { registerServiceWorker } from './serviceWorker';
import { Loader } from 'shared/components';
import './i18n';

const store = configureStore();
store.subscribe(() => {
	api.subscribe(store);
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const render = (Component: React.ComponentType<any>) => {
	ReactDOM.render(
		<Router {...{ history }}>
			<Provider {...{ store }}>
				<AppStateProvider>
					<React.Suspense fallback={<Loader tiled />}>
						<Component {...{ store }} />
					</React.Suspense>
				</AppStateProvider>
			</Provider>
		</Router>,
		document.getElementById('root') as HTMLElement
	);
};

render(Routes);

if (module.hot) {
	module.hot.accept('./routes', () => {
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		const NextApp = require('./routes').default;
		render(NextApp);
	});
}
sentry.init();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

registerServiceWorker();
