/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import EntityContainer from 'modules/entity/containers';
import EntityForm from 'modules/entity/forms';

import { KntAutoSizeTextField } from 'shared/components/Fields';
import { useState } from 'react';
import { Field } from 'formik';
import { Button } from 'shared/components/Elements';
import config from 'config';
import { useNotistack } from 'shared/hooks';
import { useParams } from 'react-router';
import { Loader } from 'shared/components';
import { useTranslation } from 'react-i18next';
import { TFormItem } from 'pages/BusinessProfileMain/components/Forms/FormsConfigTable';
import { isEmpty } from 'lodash';

const imgPath = `${config.PUBLIC_ASSETS}img/`;

function FeedbackPage() {
	const [temporaryLanguage, setTemporaryLanguage] = useState('EN');
	const { t } = useTranslation('translation', { lng: temporaryLanguage });
	const { showNotification } = useNotistack();
	const { companyUniqId } = useParams<{ companyUniqId: string }>();

	const changeLanguage = (language: string) => {
		setTemporaryLanguage(language);
	};

	return (
		<EntityContainer.One
			id={companyUniqId}
			relations={''}
			primaryKey={(data) => data.form_meta_info?.uuid}
			entity={'AllForms'}
			name={`Forms-${companyUniqId}`}
			url={`/feedback/${companyUniqId}/`}
			params={{}}
			onError={(error) => {}}
		>
			{({ item }) => {
				const formItem = item as TFormItem;
				return isEmpty(formItem) ? (
					<h3 className="text-center">Incorrect URL or URL has been deleted. Page not found</h3>
				) : (
					<div
						//dir={temporaryLanguage === 'AR' ? 'rtl' : 'ltr'}
						css={css`
							background-image: url(${imgPath}bg-welcome.jpg);
						`}
						className="feedback-page__wrapper min-h-full bg-cover bg-no-repeat"
					>
						<EntityForm.Main
							name={`Forms-${companyUniqId}`}
							entity="AllForms"
							url={`/feedback/${companyUniqId}/`}
							method="post"
							fields={formItem?.fields?.map((field) => ({
								required: field.required,
								name: field?.name,
								type: 'string',
								onSubmitKey: ''
							}))}
							onError={(error) => {
								showNotification({
									message: error?.response?.data?.message ?? t('something_went_wrong')
								});
								console.log(error);
							}}
							onSuccess={(data, resetForm) => {
								showNotification({
									message: t('feedback_send_success')
								});

								resetForm();
							}}
						>
							{({ submitForm, isSubmitting, setFieldValue }) => {
								return (
									<div className="flex-center-x mx-2 items-center sm:mx-0">
										<div className="w-full md:w-auto">
											{/* <div className="mt-6 sm:-ml-2.5 ">
												<LanguageSelect
													isRegistering={false}
													customLanguageSelect={{
														changeLanguage,
														language: temporaryLanguage
													}}
												/>
											</div> */}

											<form className="mt-20 w-full rounded bg-white px-5 py-3.5 shadow-2xl md:w-[28rem]">
												<h3 className="mt-2.5 mb-3.5 font-semibold text-kdark-424242">
													{formItem.title}
												</h3>
												{formItem?.fields
													?.sort((fieldA, fieldB) => fieldA.order - fieldB.order)
													.map((field) => {
														return (
															<Field
																key={field.id}
																inputElementClassNames="dark:bg-kgrey-light-grey-1 dark:placeholder-kgrey"
																label={field.name}
																name={field.name}
																component={KntAutoSizeTextField}
																type="textarea"
																placeholder={field?.placeholder}
																required={field.required}
																onBlur={(event: any) => {
																	setFieldValue(event.target.name, event.target.value.trim());
																}}
															/>
														);
													})}

												<div className="feedback-form__button flex justify-end">
													<Button
														onClick={submitForm}
														variant={'contained'}
														className={
															'h-8 w-fit !bg-primary-dark text-right !font-sans !text-white'
														}
													>
														{isSubmitting ? <Loader tiled size={24} /> : t('send_message')}
													</Button>
												</div>
											</form>
										</div>
									</div>
								);
							}}
						</EntityForm.Main>
					</div>
				);
			}}
		</EntityContainer.One>
	);
}

export default FeedbackPage;
