import React from 'react';
import { useSwitch } from 'shared/hooks';
import { useCaseRecurring } from './useCaseRecurring';
import { TimePicker } from '@material-ui/pickers';
import {
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	TextField,
	WhiteModal
} from 'shared/components/Elements';
import { RecurringOptionsForm } from './RecurringOptionsForm';
import { IOSSwitch } from 'shared/components/Elements/Fields/SwitchComp';
import { useTranslation } from 'react-i18next';
import { useTranslationHelpers } from 'shared/hooks/useTranslationHelpers';
import clsx from 'clsx';
import { convertToLocalizationKey } from 'shared/services/utils/convertToLocalizationKey';

type Props = {
	state: any;
	setState: any;
	setFieldValue: any;
	errors: any;
};

export function CreateCaseRecurring({ state, setState, setFieldValue, errors }: Props) {
	const { checked, handleChange } = useSwitch({ recurring: false });
	const { t } = useTranslation();
	const { isRTL } = useTranslationHelpers();

	//Case Recurring Option Logics
	const {
		onCloseRecurrDialog,
		showCustomRecurring,
		selectedTime,
		setSelectedTime,
		recurringOptions,
		handleInput,
		recurOption,
		ON_WEEK_DAY,
		currentDay,
		currentMonthDate,
		currentDayObj,
		setRecurringState,
		recurring
	} = useCaseRecurring({
		checked,
		setState,
		setFieldValue
	});

	return (
		<div className="react-select-dropdown mb-4">
			<div className="flex items-center">
				<FormControlLabel
					control={
						<IOSSwitch checked={checked.recurring} onChange={handleChange} name="recurring" />
					}
					label={t('recurring_case')}
				/>
			</div>

			{checked.recurring && (
				<div className="mt-2 flex">
					<div className={clsx('mb-4 flex-1 overflow-hidden', { 'pl-4': isRTL, 'pr-4': !isRTL })}>
						<FormControl className="w-full">
							{!recurOption && (
								<InputLabel className="!translate-x-3 !translate-y-3.5">
									{t('select_frequency')}
								</InputLabel>
							)}

							<TextField
								error={errors.recurring}
								helperText={t(convertToLocalizationKey(errors.recurring))}
								select
								fullWidth
								value={recurOption}
								name="recurOption"
								onChange={handleInput}
								size="small"
								variant="outlined"
								className="cc-recurring-timepicker"
							>
								{recurringOptions.map((option: any) => (
									<MenuItem key={option.value} value={option.value}>
										{recurOption === option.value && recurOption !== 'CUSTOM_VALUE'
											? option.text
											: option.name}
									</MenuItem>
								))}
							</TextField>
						</FormControl>
					</div>
					<div className="mb-5 w-1/4">
						<TimePicker
							variant="inline"
							value={selectedTime}
							//@ts-ignore
							onChange={setSelectedTime}
							inputVariant="outlined"
							size="small"
							autoOk
							fullWidth
							className="cc-recurring-timepicker"
						/>
					</div>

					<WhiteModal
						handleModalOpen={onCloseRecurrDialog}
						open={showCustomRecurring}
						maxWidth={315}
						minWidth={315}
					>
						<RecurringOptionsForm
							recurring={recurring}
							currentDay={currentDay}
							currentMonthDate={currentMonthDate}
							currentDayObj={currentDayObj}
							onCloseRecurrDialog={onCloseRecurrDialog}
							stateHandler={setRecurringState}
							recurringOptions={recurringOptions}
							selectedTime={selectedTime}
							ON_WEEK_DAY={ON_WEEK_DAY}
						/>
					</WhiteModal>
				</div>
			)}
		</div>
	);
}
