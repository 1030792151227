import React from 'react';
import { useAppState } from 'shared/state';
import get from 'lodash/get';
import { IUserMessage } from 'shared/interfaces';
import { Avatar, Button } from '../../Elements';
import { MdDone } from 'react-icons/md';
import { SocketEventTypes } from 'shared/typings/socketEvent.types';
import { toUpperCase } from 'shared/services/utils/toUpperCase';
import { convertMsgTime } from '../utils';
import capitalize from 'lodash/capitalize';
import { Trans, useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

function getMessageType(type: string, entityType: string, t: TFunction) {
	switch (type) {
		case SocketEventTypes.REMOVE_MEMBER:
		case SocketEventTypes.MEMBER_DELETED: {
			return t('entity_member_removed', { entity: entityType });
		}
		case SocketEventTypes.ADD_MEMBER:
		case SocketEventTypes.MEMBER_ADDED: {
			return t('entity_member_added', { entity: entityType });
		}
		case SocketEventTypes.USER_UNASSIGNED:
		case SocketEventTypes.UNASSIGNED_USER: {
			return t('entity_assignee_removed', { entity: entityType });
		}
		case SocketEventTypes.USER_ASSIGNED:
		case SocketEventTypes.ASSIGNED_USER: {
			return t('entity_assignee_added', { entity: entityType });
		}
		case SocketEventTypes.PRIORITY_CHANGED: {
			return t('priority_changed');
		}
		case SocketEventTypes.MEMBER_LEFT: {
			return t('member_left');
		}
		case SocketEventTypes.STATUS_CHANGED: {
			return t('status_changed');
		}
		default: {
			return 'message';
		}
	}
}

const DESCRIPTION_IS_NOT_VISIBLE_FOR = ['MEMBER_LEFT'];

export function StatusPriorityMessagePopup({ onClose }: { onClose: () => void }) {
	const { t } = useTranslation();
	const { statusPriorityMessagePopup } = useAppState();
	const msgType = toUpperCase(statusPriorityMessagePopup?.actualType as string) || 'message';
	const entityType = capitalize(statusPriorityMessagePopup?.type ?? 'case');

	const { to_user } = statusPriorityMessagePopup || {};

	const computedMessageType = getMessageType(msgType, entityType, t);

	const text = statusPriorityMessagePopup?.text;
	const fromUser = statusPriorityMessagePopup?.from_user as IUserMessage;
	const sentTime = statusPriorityMessagePopup?.sent_time as number;

	const renderText = () => {
		switch (msgType) {
			case SocketEventTypes.PRIORITY_CHANGED:
				return (
					<span>
						<Trans
							i18nKey="priority_changed_to"
							values={{ priority: text?.replace(/Priority Changed/gi, '') }}
						/>
					</span>
				);
			case SocketEventTypes.STATUS_CHANGED:
				return (
					<span>
						<Trans i18nKey="case_moved_to_status" values={{ text }} />
					</span>
				);
			case SocketEventTypes.ADD_MEMBER:
			case SocketEventTypes.MEMBER_ADDED:
				return (
					<span>
						<Trans
							i18nKey="member_added_by"
							values={{ fullname: `${to_user?.first_name} ${to_user?.last_name}` }}
						/>
					</span>
				);
			case SocketEventTypes.REMOVE_MEMBER:
			case SocketEventTypes.MEMBER_DELETED:
				return (
					<span>
						<Trans
							i18nKey="member_removed_by"
							values={{ fullname: `${to_user?.first_name} ${to_user?.last_name}` }}
						/>
					</span>
				);
			case SocketEventTypes.USER_ASSIGNED:
			case SocketEventTypes.ASSIGNED_USER:
				return (
					<span>
						<Trans
							i18nKey="member_assigned_by"
							values={{ fullname: `${to_user?.first_name} ${to_user?.last_name}` }}
						/>
					</span>
				);
			case SocketEventTypes.USER_UNASSIGNED:
			case SocketEventTypes.UNASSIGNED_USER:
				return (
					<span>
						<Trans
							i18nKey="member_unassigned_by"
							values={{ fullname: `${to_user?.first_name} ${to_user?.last_name}` }}
						/>
					</span>
				);
		}
	};

	const isDescriptionVisible = !DESCRIPTION_IS_NOT_VISIBLE_FOR.includes(msgType);

	return (
		<>
			<div className="priority-modal__heading mb-4 font-poppins text-xl font-bold lg:text-2xl ">
				{t('message_type_info', { type: `${computedMessageType}` })}
			</div>
			<div className="msg-info font-poppins">
				{isDescriptionVisible && (
					<div className="msg-info__header mb-4  flex">
						<div className="msg-info__icon mr-2 text-2xl">
							<MdDone />
						</div>
						<div className="msg-info__text">{renderText()}</div>
					</div>
				)}

				<div className="msg-info__body msg-info__body_sent text-md flex items-center lg:text-base">
					{get(fromUser, 'avatar') && (
						<div className="msg-info__avatar">
							<Avatar
								src={
									get(fromUser, 'avatar.url') ||
									(get(fromUser, 'avatar.thumbnail_key_150') as string)
								}
								letter={fromUser?.first_name?.[0].toUpperCase()}
								alt={fromUser?.first_name}
								classes={{ root: 'mr-2' }}
							/>
						</div>
					)}
					<div className="msg-info__content">
						<div className="msg-info__name font-semibold">{`${get(fromUser, 'first_name')} ${get(
							fromUser,
							'last_name'
						)}`}</div>
						<div className="msg-info__date text-sm text-kgrey">{convertMsgTime(sentTime)}</div>
					</div>
				</div>
				<div className="msg-info__footer border-color-kgrey mt-4 flex justify-end border-t pt-4">
					<Button variant="outlined" color="default" onClick={onClose}>
						{t('close')}
					</Button>
				</div>
			</div>
		</>
	);
}
