import { storage } from 'shared/services';
import Actions from './actions';

const token = storage.get('token');

const initialState = {
	isFetched: true,
	isAuthenticated: !!token,
	data: null,
	token: ''
};

export default (state = initialState, action: any = {}) => {
	switch (action.type) {
		case Actions.GetMe.REQUEST:
			return {
				...state,
				isFetched: false
			};

		case Actions.Login.SUCCESS:
			return {
				...state,
				isFetched: true,
				isAuthenticated: true,
				token: action.payload.token
			};

		case Actions.GetMe.SUCCESS:
			return {
				...state,
				isFetched: true,
				isAuthenticated: true,
				data: action.payload.data,
				token: storage.get('token')
			};

		case Actions.Logout.REQUEST:
		case Actions.Logout.SUCCESS:
		case Actions.Logout.FAILURE:
		case Actions.Login.FAILURE:
		case Actions.GetMe.FAILURE:
			return {
				...state,
				isFetched: true,
				isAuthenticated: false,
				token: '',
				data: {}
			};

		default:
			return state;
	}
};
