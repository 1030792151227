import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayJsUtils from '@date-io/dayjs';
import EntityContainer from 'modules/entity/containers';
import EntityForm from 'modules/entity/forms';
import { useAppState } from 'shared/state';
import { useCaseDetails } from '../../hooks/useCaseDetails';
import { useNotistack, useUpdateEntities } from 'shared/hooks';
import { Case, LinkedCase } from 'shared/interfaces';

import {
	CaseDetailsRow,
	CDAssignee,
	CDCopyToStaffCase,
	CdDataFields,
	CdDepartment,
	CdExpectedTimeOfCompletion,
	CDLinkedCases,
	CdNote,
	CDPriority,
	CdRating,
	CDReassignedCaseInfo,
	CdRecurringOptions,
	CDStaffAccess,
	CdStatus
} from './components';
import get from 'lodash/get';
import { capitalizeFirstLetter, time } from 'shared/services';
import { Button, ErrorBoundary, Loader } from 'shared/components';
import { getDepartmentPayload, getSubDepartmentPayload } from './utils';
import isEmpty from 'lodash/isEmpty';
import config from 'config';
import { Skeleton } from 'shared/components/Elements';
import { isMobile } from 'react-device-detect';
import dayjs from 'dayjs';
import { UserType } from 'shared/typings/user.types';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

type Props = {
	caseId: number | null;
	closeCaseDetailModal: () => void;
};

type CaseDetailObjType = {
	assignees: UserType[] | null;
	case: Case;
	department: null;
	linked_cases: { count: number; results: LinkedCase[] } | null;
	members: UserType[] | null;
};

function CaseDetailsInner({ caseId, closeCaseDetailModal }: Props) {
	const { companyId, caseType } = useAppState();
	const {
		params,
		state,
		setState,
		getCaseAccessMembers,
		members,
		assignedUsers,
		setAssignedUsers,
		setMembers,
		caseAccessListChangeRequest,
		loading,
		setLoading,
		setAssignRequired,
		assignRequired
	} = useCaseDetails({
		caseId,
		closeCaseDetailModal
	});
	const { t } = useTranslation();
	const { updateEntities } = useUpdateEntities();
	const { showNotification } = useNotistack();
	const { clearDepartment, depHierarchy, subDepsForHierarchy, consistedSubDeps } = state;

	return (
		<>
			{caseId ? (
				<MuiPickersUtilsProvider utils={DayJsUtils}>
					<div className="font-poppins">
						<EntityContainer.One
							id={String(caseId)}
							relations={''}
							entity={'case-detail'}
							name={`CaseDetail-${caseId}`}
							primaryKey={() => String(caseId)}
							url={`/v2/${companyId}/cases/${caseId}/`}
							params={{
								extra: {
									...params?.extra,
									assignees: 'true',
									members: 'true'
								}
							}}
						>
							{({ item, isFetched }) => {
								const caseDetailItem = { ...item } as CaseDetailObjType;
								const caseItem = caseDetailItem?.case ?? {};
								const caseMembers = caseDetailItem?.members ?? [];
								const caseAssignedUsers = caseDetailItem?.assignees ?? [];

								const caseTitle = caseItem.title;
								const caseNumber = caseItem.case_number;
								const client = get(caseItem, 'client_company.name');
								const clientUser = `${get(caseItem, 'client_user.first_name', '')} ${get(
									caseItem,
									'client_user.last_name',
									''
								)}`;
								const addedBy = `${get(caseItem, 'created_by.first_name', '')} ${get(
									caseItem,
									'created_by.last_name',
									''
								)}`;
								const createdDate = time.toLastTime(get(caseItem, 'created_date'), true, false);
								const department = caseItem?.department;
								const sub_department = caseItem?.sub_department;
								const status = caseItem?.status;
								const completeStatus = caseItem?.complete_status;
								const dueDate = caseItem?.due_date;
								const priorityName = caseItem.priority;
								const priority = {
									name: capitalizeFirstLetter(
										(priorityName ? priorityName?.toLowerCase() : '') as string
									),
									code: priorityName
								};
								const recurringId = caseItem.recurring_id;
								const rate = caseItem?.rate;
								const caseCreatorId = get(caseItem, 'created_by.id');
								const relatedTo = caseItem.related_to;

								return (
									<EntityForm.Main
										id={caseId}
										entity="cases"
										name={`Case-${caseId}`}
										url={`/${companyId}/cases/as_business/${caseId}/case_detail/`}
										method="patch"
										primaryKey="id"
										sendAsFormData={false}
										normalizeData={(data: any) => data}
										updateData={true}
										fields={[
											{
												name: 'department',
												value: isEmpty(depHierarchy)
													? clearDepartment
														? null
														: department
													: depHierarchy,
												onSubmitValue: (value, values) => getDepartmentPayload(values),
												onSubmitKey: '',
												required: true,
												type: 'object'
											},
											{
												name: 'sub_department',
												onSubmitValue: (value, values) => {
													const subDepValue = getSubDepartmentPayload(values);
													return subDepValue['id'] === '1' ? {} : subDepValue;
												},
												value: sub_department || {},
												required: caseType === config.CLIENT && !isEmpty(subDepsForHierarchy),
												onSubmitKey: '',
												type: 'object'
											},
											{
												name: 'dep_hierarchy',
												value: subDepsForHierarchy,
												onSubmitValue: () => null,
												onSubmitKey: '',
												type: 'object'
											},
											{
												name: 'status',
												value: status,
												onSubmitValue: (value) => get(value, 'id'),
												onSubmitKey: '',
												type: 'object'
											},
											{
												name: 'priority',
												value: priority,
												onSubmitValue: (value) => get(value, 'code'),
												onSubmitKey: '',
												type: 'object'
											},
											{
												name: 'due_date',
												onSubmitValue: (value) =>
													//@ts-ignore
													state.isDateTimeSet ? dayjs(value).utc().format() : null,
												value: dueDate,
												onSubmitKey: ''
											},
											{
												name: 'complete_status',
												onSubmitValue: (value) => (state.isDateTimeSet ? get(value, 'id') : null),
												required: state.isDateTimeSet,
												value: completeStatus,
												onSubmitKey: '',
												type: 'object'
											},
											{
												name: 'note',
												value: get(caseItem, 'note'),
												onSubmitKey: ''
											},
											{
												name: 'access_list',
												value: caseMembers,
												onSubmitValue: (value) =>
													value?.length
														? value?.map((member: any) =>
																member?.user ? member?.user?.id : member?.id
														  )
														: [],
												onSubmitKey: '',
												required: true,
												type: 'array'
											},
											{
												name: 'assignee_ids',
												value: caseAssignedUsers,
												onSubmitValue: (value) =>
													value?.length
														? value?.map((member: any) =>
																member?.user ? member?.user?.id : member?.id
														  )
														: [],
												onSubmitKey: '',
												required: true,
												type: 'array'
											}
										]}
										onSuccess={(response, resetForm): any => {
											updateEntities({
												entity: 'case-detail',
												entityId: caseId,
												updatingData: {
													case: response
												}
											});

											setLoading(false);
											closeCaseDetailModal();
											resetForm();
											showNotification({ message: t('update_success'), variant: 'success' });
										}}
										onError={(error, resetForm): any => {
											showNotification({ message: t('something_went_wrong'), variant: 'error' });
											resetForm();
										}}
									>
										{({ values, isSubmitting, setFieldValue, setFieldTouched, errors }) => {
											if (process.env.NODE_ENV === 'development') {
												console.log('case details values', values);
												console.log('case details state', state);
												console.log('case details errors', errors);
											}
											return (
												<div className="case-details react-select-dropdown">
													<h3 className="mb-2 text-xl font-bold">{t('case_details')}</h3>

													{/*Case Details Title*/}
													<CaseDetailsRow fieldName={t('case_title')}>{caseTitle}</CaseDetailsRow>

													{/*Case Details Case Number*/}
													<CaseDetailsRow fieldName={t('case_number')}>{caseNumber}</CaseDetailsRow>

													{/*Case Details Client*/}
													<CaseDetailsRow fieldName={t('client')}>{client}</CaseDetailsRow>

													{/*Case Details Client user*/}
													<CaseDetailsRow fieldName={t('report_by')}>{clientUser}</CaseDetailsRow>

													{/*Case Details Case Creator*/}
													<CaseDetailsRow fieldName={t('added_by')}>{addedBy}</CaseDetailsRow>

													{/*Case Details Case created time*/}
													<CaseDetailsRow fieldName={t('created_day')}>
														{createdDate}
													</CaseDetailsRow>

													{/*Case Details Departments and Sub department*/}
													{isFetched ? (
														<CdDepartment
															setState={setState}
															department={department}
															sub_department={sub_department}
															depHierarchy={state.depHierarchy}
															setFieldValue={setFieldValue}
															subDepsForHierarchy={subDepsForHierarchy}
															consistedSubDeps={consistedSubDeps}
															values={values}
															recurringId={recurringId}
															setMembers={setMembers}
															setAssignedUsers={setAssignedUsers}
															caseId={caseId}
															setAssignRequired={setAssignRequired}
														/>
													) : (
														<Skeleton />
													)}

													{/*Case Details Status*/}
													<CaseDetailsRow fieldName={t('status')}>
														<CdStatus />
													</CaseDetailsRow>

													{/*Case Details Priority*/}
													<CaseDetailsRow fieldName={t('priority')}>
														<CDPriority recurringId={recurringId} />
													</CaseDetailsRow>

													{/*Case Details Case data fields for mobile version*/}
													{isMobile ? (
														<CaseDetailsRow fieldName={t('case_chat_data_fields')} align="top">
															<CdDataFields dataFields={caseItem.data_field} />
														</CaseDetailsRow>
													) : null}

													{/*Case Details Staff Access*/}
													<CaseDetailsRow fieldName={t('staff_access')} align="top">
														<CDStaffAccess
															setFieldValue={setFieldValue}
															staffAccessList={values.access_list}
															members={members}
															setMembers={setMembers}
															caseId={caseId}
														/>
													</CaseDetailsRow>

													{/*Assigneeing members*/}
													<CaseDetailsRow fieldName={t('assignee_members')} align="top">
														<CDAssignee
															caseAssigneeList={values.assignee_ids}
															caseId={caseId}
															staffAccessList={values.access_list}
															setFieldValue={setFieldValue}
															getCaseAccessMembers={getCaseAccessMembers}
															setAssignedUsers={setAssignedUsers}
															assignedUsers={assignedUsers}
															department={values.department}
															subDepartments={values?.dep_hierarchy}
															assignRequired={assignRequired}
														/>
													</CaseDetailsRow>

													{/*Expected time of completion*/}
													<CaseDetailsRow fieldName={t('expected_time_of_completion')} align="top">
														<CdExpectedTimeOfCompletion
															state={state}
															setState={setState}
															setFieldValue={setFieldValue}
															dueDateValue={values?.due_date}
															setFieldTouched={setFieldTouched}
															recurringId={recurringId}
														/>
													</CaseDetailsRow>

													{/*	Case recurring options*/}
													{recurringId ? (
														<CdRecurringOptions
															recurringId={recurringId}
															state={state}
															setState={setState}
														/>
													) : null}

													{/*	Case rating*/}
													<CdRating rate={rate} />

													{/* Case note */}
													<CdNote />

													{/*	Reassigned case info*/}
													<CDReassignedCaseInfo
														caseCreatorId={caseCreatorId}
														relatedTo={relatedTo}
														closeCaseDetailModal={closeCaseDetailModal}
													/>

													{/*	Copy case to Staff case | Reassigning client to staff case*/}
													<CDCopyToStaffCase
														caseId={caseId}
														closeCaseDetailModal={closeCaseDetailModal}
														caseTitle={caseTitle}
													/>

													{/*	Linked Cases*/}
													<CDLinkedCases
														caseId={caseId}
														closeCaseDetailModal={closeCaseDetailModal}
														recurringId={recurringId}
													/>
													<div className="btn-row mt-6 flex justify-end">
														<Button type="button" onClickHandler={closeCaseDetailModal}>
															{t('cancel')}
														</Button>
														<Button
															disabled={
																(assignRequired && !values?.assignee_ids?.length) ||
																!values?.access_list?.length
															}
															type="submit"
															className="ml-4 hover:!text-primary"
															variant="contained"
														>
															{t('update')}
														</Button>
													</div>
													{(isSubmitting || loading) && <Loader tiled withOverlay />}
												</div>
											);
										}}
									</EntityForm.Main>
								);
							}}
						</EntityContainer.One>
					</div>
				</MuiPickersUtilsProvider>
			) : null}
		</>
	);
}

export function CaseDetails(props: Props) {
	return (
		<ErrorBoundary>
			<CaseDetailsInner {...props} />
		</ErrorBoundary>
	);
}
