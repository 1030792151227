import React, { useEffect, useMemo, useRef } from 'react';
import { useFormik } from 'formik';
import { KntInput } from 'shared/components/Fields';
import { AxiosError } from 'axios';
import * as yup from 'yup';
import { useNotification } from 'shared/hooks';
import { useDispatch } from 'react-redux';
import Actions from 'store/actions';
import { useHistory } from 'react-router-dom';
import config from 'config';
import axios from 'axios';
import { useAuth } from '../../../modules/auth/hooks/useAuth';
import { storage } from '../../../shared/services';
import { useTranslation } from 'react-i18next';
const FormRequest = Actions.entity.formRequest;

type Props = {
	modalHandler: () => void;
};

export function ProfileChangePassword({ modalHandler }: Props) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { userData } = useAuth();
	const history = useHistory();
	const { notifVisible, setNotifVisible, handleNotificationClose, Notification } =
		useNotification();

	const passwordRef = useRef<HTMLInputElement>(null);


	const validationSchema = useMemo(
		() =>
			yup.object({
				old_password: yup
					.string()
					.min(6, t('password_validation'))
					.required(t('old_password_required')),
				new_password: yup
					.string()
					.min(6, t('password_validation'))
					.required(t('password_required')),
				new_password_confirm: yup
					.string()
					.oneOf([yup.ref('new_password')], t('password_not_same'))
					.required(t('password_confirmation_required'))
			}),
		[t]
	);

	const formik = useFormik({
		initialValues: {
			old_password: '',
			new_password: '',
			new_password_confirm: ''
		},
		validationSchema: validationSchema,
		onSubmit: (values, { setFieldError, setSubmitting }) => {
			const filteredValues = { ...values };
			// @ts-ignore
			delete filteredValues.new_password_confirm;

			dispatch(
				FormRequest({
					entity: 'change_password',
					name: 'change_password',
					url: '/my/change-password/',
					method: 'put',
					params: {},
					values: filteredValues,
					cb: {
						success: (data: any) => {
							//Refresh token
							axios
								.post(`${config.API_ROOT}/user/auth/`, {
									username: userData.username,
									password: values.new_password
								})
								.then(({ data }) => {
									setSubmitting(false);
									setNotifVisible(true);
									storage.set('token', data.token);
									dispatch(Actions.auth.Login.success(data));
									setTimeout(() => {
										modalHandler();
									}, 1000);
								})
								.catch((error: AxiosError) => {
									dispatch(
										Actions.auth.Login.failure({
											error
										})
									);
								});
						},
						error: (error: AxiosError) => {
							setFieldError('token', error.response?.data?.message);

							Object.keys(error?.response?.data).forEach((errorKey: any) => {
								setFieldError(errorKey, error?.response?.data?.[errorKey][0]);
								if (errorKey === 'old_password') passwordRef?.current?.focus();
							});

							setSubmitting(false);
						},
						finally: () => {}
					}
				})
			);
		}
	});

	useEffect(() => {
		if (passwordRef?.current) passwordRef?.current.focus();
	}, []);

	return (
		<div>
			<form onSubmit={formik.handleSubmit}>
				<div className="mb-6 text-xl font-semibold">{t('change_password')}</div>
				<KntInput
					name={'old_password'}
					placeholder={t('old_password')}
					label={t('old_password')}
					required
					onChange={formik.handleChange}
					type={'password'}
					value={formik.values.old_password}
					error={formik.touched.old_password && Boolean(formik.errors.old_password)}
					helperText={formik.touched.old_password && formik.errors.old_password}
					ref={passwordRef}
				/>
				<KntInput
					name={'new_password'}
					placeholder={t('password')}
					label={t('password')}
					required
					onChange={formik.handleChange}
					type={'password'}
					value={formik.values.new_password}
					error={formik.touched.new_password && Boolean(formik.errors.new_password)}
					helperText={formik.touched.new_password && formik.errors.new_password}
				/>
				<KntInput
					name={'new_password_confirm'}
					placeholder={t('confirm_password')}
					label={t('password_confirmation')}
					required
					onChange={formik.handleChange}
					type={'password'}
					value={formik.values.new_password_confirm}
					error={formik.touched.new_password_confirm && Boolean(formik.errors.new_password_confirm)}
					helperText={formik.touched.new_password_confirm && formik.errors.new_password_confirm}
					classNames={'mb-8'}
				/>

				<button
					disabled={formik.isSubmitting}
					className={
						'bg-primary py-3.5 uppercase transition ' +
						'w-full rounded-lg text-center text-sm font-light text-white hover:bg-primary-dark'
					}
				>
					{formik.isSubmitting ? t('changing_password_please_wait') : t('change_password')}
				</button>
			</form>
			<Notification
				severity={'info'}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				handleClose={handleNotificationClose}
				open={notifVisible}
				message={t('password_changed_success')}
				autoHideDuration={1000}
			/>
		</div>
	);
}
