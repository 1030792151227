import React from 'react';

export function IconTelegram({ className = '' }: { className?: string }) {
	return (
		<svg
			width="17"
			height="17"
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M15.956 1.044a.643.643 0 00-.675-.148L1.14 6.039a.643.643 0 000 1.202L6.66 9.446l4.075-4.089.907.907-4.095 4.094 2.211 5.523a.643.643 0 00.598.405.643.643 0 00.592-.425L16.09 1.72a.643.643 0 00-.135-.675z"
				fill="currentColor"
			/>
		</svg>
	);
}
