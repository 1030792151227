import React from 'react';
import { Button, DialogActions, DialogTitle, WhiteModal } from './Elements';
import Loader from './Loader';
import { useTranslation } from 'react-i18next';

type Props = {
	toggleHandler: () => void;
	isOpen: boolean;
	featureHandler: (args: any) => void;
	hasLoader?: boolean;
	isLoading?: boolean;
	title: string | React.ReactNode;
	maxWidth?: number;
	noPadding?: boolean;
	titleClassNames?: string;
};

export function ConfirmModal({
	toggleHandler,
	isOpen,
	featureHandler,
	isLoading = false,
	hasLoader = false,
	maxWidth,
	noPadding = false,
	title,
	titleClassNames,
	...rest
}: Props) {
	const { t } = useTranslation();
	return (
		<>
			{isOpen ? (
				<WhiteModal
					handleModalOpen={toggleHandler}
					open={isOpen}
					maxWidth={maxWidth}
					noPadding={noPadding}
				>
					<DialogTitle id="alert-dialog-title" className={titleClassNames}>
						{title}
					</DialogTitle>
					<DialogActions>
						<Button onClick={toggleHandler} autoFocus variant="contained">
							{t('no')}
						</Button>
						<Button
							onClick={() => featureHandler(rest)}
							variant="contained"
							color="secondary"
							classes={{ root: '!mx-1.5' }}
						>
							{t('yes')}
						</Button>
					</DialogActions>
					{hasLoader ? (
						<>
							{isLoading && (
								<div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-70">
									<Loader />
								</div>
							)}
						</>
					) : null}
				</WhiteModal>
			) : null}
		</>
	);
}
