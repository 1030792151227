import React from 'react';

export function IconFilter({ className = '' }: { className?: string }) {
	return (
		<svg
			className={className}
			width="16"
			height="16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14Zm0 1A8 8 0 1 0 8-.001 8 8 0 0 0 8 16Z"
				fill="currentColor"
			/>
			<path
				d="M7 11.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5Zm-2-3a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5Zm-2-3a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5Z"
				fill="currentColor"
			/>
		</svg>
	);
}
