import { createSelector } from 'reselect';
import { IRootState } from 'shared/interfaces';
import { UserState } from './reducers';

const getUser = (state: IRootState) => state.user;

export const getCompany = createSelector(getUser, (user: UserState) => user.company);
export const getCompanyId = createSelector(getUser, (user: UserState) => {
	if (user.company)
		return user.company.company.status === 'ACTIVE' ? user.company.company.id : undefined;
});
export const getFCM = createSelector(getUser, (user: UserState) => user.fcm);
export const getIsUserCompaniesFetched = createSelector(
	getUser,
	(user: UserState) => user.isFetchedUser
);
export const getIsBusinessCompaniesFetched = createSelector(
	getUser,
	(user: UserState) => user.isFetchedBusiness
);

export const getUserCompanies = createSelector(getUser, (user: UserState) => user.userCompanies);

export const getBusinessCompanies = createSelector(
	getUser,
	(user: UserState) => user.businessCompanies
);

export const getSelectedBusiness = createSelector(
	getUser,
	(user: UserState) => user.selectedBusiness
);
export const getSelectedBusinessId = createSelector(
	getUser,
	(user: UserState) => user.selectedBusiness?.id
);
