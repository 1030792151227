import { useAxios, useNotistack, useUpdateEntities } from 'shared/hooks';
import { useCallback } from 'react';
import { AxiosError } from 'axios';

export function useActivationButtonController() {
	const { updateEntities } = useUpdateEntities();
	const { showNotification } = useNotistack();

	const { fetchData: businessActivationRequest, loading } = useAxios({
		url: '',
		dynamicUrl: true,
		cb: {
			success(data, args) {
				const { onAfterBusinessActivationRequest } = args[0] || {};

				if (typeof onAfterBusinessActivationRequest === 'function') {
					onAfterBusinessActivationRequest();
				}
			},
			error(error: AxiosError) {
				if (error.response?.status === 400) {
					showNotification({
						message: 'The activation request is invalid!',
						variant: 'error'
					});
				}
			}
		}
	});

	const sendBusinessActivationRequest = useCallback((url: string, custom_uuid: string) => {
		const computedUrl = `/sts${url.split('/sts')?.[1]}`;

		businessActivationRequest({
			url: computedUrl,
			onAfterBusinessActivationRequest: () => {
				updateEntities({
					entity: 'caseMessages',
					entityId: custom_uuid,
					updatingData: {
						settings: null
					}
				});
			}
		});
	}, []);

	return {
		sendBusinessActivationRequest,
		isBusinessActivationLoading: loading
	};
}
