import React from 'react';
import AsyncSelectJs from 'shared/components/Fields/AsyncSelectJs';
import { KntTwilioStyle } from 'shared/services';
import { Field } from 'formik';
import { useAppState } from 'shared/state';
import { useTranslation } from 'react-i18next';

export function CDPriority({ recurringId }: { recurringId: number | null }) {
	const { companyId } = useAppState();
	const { t } = useTranslation();
	return (
		<Field
			component={AsyncSelectJs}
			customStylesProps={KntTwilioStyle}
			name="priority"
			placeholder={t('select_priority')}
			optionLabel="name"
			optionValue="code"
			isSearchable
			isCached
			isDisabled={recurringId}
			loadOptionsUrl={`/${companyId}/cases/as_business/priorities/`}
			loadOptionsParams={(search: string) => ({
				extra: {
					search
				}
			})}
			loadOptionsKey="choices"
		/>
	);
}
