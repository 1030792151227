import { query } from 'js-query-builder';
import qs from 'qs';
import { QueryType } from 'shared/interfaces';
import isArray from 'lodash/isArray';

const queryBuilder = (
	url: string,
	{ fields = [], include = [], limit = 0, sort = '', filter = {}, page = 1, extra = {} }: QueryType
): string => {
	const queryObj = query(url);

	if (fields.length) {
		queryObj.param('fields', fields);
	}

	if (include.length) {
		queryObj.include(include);
	}

	if (limit > 0) {
		queryObj.param('page_size', Number(limit));
	}

	if (sort) {
		queryObj.sort(sort);
	}

	if (Object.keys(filter).length) {
		Object.keys(filter).forEach((item: string) => {
			const normalized = qs
				.stringify({ filter: { [item]: filter[item] } }, { encode: false })
				.split('&');
			normalized.forEach((item) => {
				const splited = item.split('=');
				if (splited.length === 2 && splited[0] && splited[1]) {
					queryObj.param(splited[0], splited[1]);
				}
			});
		});
	}

	if (Object.keys(extra).length) {
		Object.keys(extra).forEach((key) => {
			if (key && extra[key]) {
				//TODO:3830 - watch side-effects i enabled encodeValuesOnly option to encode symbols in search
				if (isArray(extra[key])) {
					queryObj.param(key, extra[key]);
				} else {
					const splitParamOption = qs
						.stringify({ [key]: extra[key] }, { encodeValuesOnly: true })
						.split('=');

					if (splitParamOption.length === 2 && splitParamOption[0] && splitParamOption[1]) {
						queryObj.param(splitParamOption[0], splitParamOption[1]);
					}
				}
			}
		});
	}

	if (page > 1) {
		queryObj.page(Number(page));
	}

	return decodeURIComponent(queryObj.build());
};

export default queryBuilder;
