import { useEffect, useState } from 'react';
import { useAppState } from '../state';

let hold: NodeJS.Timeout;

export function blinkTitle(opts: any) {
	if (!opts) opts = {};
	const delay = opts.delay || 0;
	const message = opts.message || '';
	const notifyOffPage = opts.notifyOffPage || false;
	const timeout = opts.timeout || false;
	const title = opts.title || document.title;

	if (notifyOffPage) {
		hold = setInterval(function () {
			if (document.hidden) blink();
		}, delay);
	} else {
		hold = setInterval(function () {
			blink();
		}, delay);
	}

	function blink() {
		document.title === title ? (document.title = message) : (document.title = title);
	}

	if (timeout) setTimeout(blinkTitleStop, timeout);
}

export function blinkTitleStop() {
	clearInterval(hold);
}

let hidden: any = null;
let visibilityChange: any = null;
if (typeof document.hidden !== 'undefined') {
	// Opera 12.10 and Firefox 18 and later support
	hidden = 'hidden';
	visibilityChange = 'visibilitychange';
} else {
	// @ts-ignore
	if (typeof document.msHidden !== 'undefined') {
		hidden = 'msHidden';
		visibilityChange = 'msvisibilitychange';
		// @ts-ignore
	} else if (typeof document.webkitHidden !== 'undefined') {
		hidden = 'webkitHidden';
		visibilityChange = 'webkitvisibilitychange';
	}
}

export function useUnreadCaseTab(pageName = 'Cases') {
	const [isHidden, setIsHidden] = useState(false);
	const { unreadCasesCountByStatus } = useAppState();

	const quantity = Object.keys(unreadCasesCountByStatus).length;
	//Blink title
	const handleVisibilityChange = () => {
		// @ts-ignore
		if (document[hidden]) {
			setIsHidden(true);
		} else {
			setIsHidden(false);
		}
	};
	//Blink title
	useEffect(() => {
		document.addEventListener(visibilityChange, handleVisibilityChange, false);

		if (quantity > 0) {
			blinkTitleStop();
			blinkTitle({
				title: `KENNEKT | ${pageName}`,
				message: `${quantity} unread cases`,
				delay: 1200,
				notifyOffPage: true
			});
		}

		return () => {
			blinkTitleStop();
			document.removeEventListener(visibilityChange, handleVisibilityChange);
		};
	}, [quantity]);

	return {
		unreadCasesQuantity: quantity,
		isHidden
	};
}
