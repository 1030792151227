import entitiesActions from './entities';
import systemActions from './system';
import authActions from '../../modules/auth/actions';
import entityActions from '../../modules/entity/actions';
import userActions from '../../modules/user/actions';

export default {
	entities: { ...entitiesActions },
	system: { ...systemActions },
	auth: { ...authActions },
	entity: { ...entityActions },
	user: { ...userActions }
};
