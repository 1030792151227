import React from 'react';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { DropzoneInputProps } from 'react-dropzone';
type Props = {
	getInputProps: <T extends DropzoneInputProps>(props?: T) => T;
	isDragAccept: boolean;
	isDragReject: boolean;
};

export function DropzoneArea({ getInputProps, isDragAccept, isDragReject }: Props) {
	return (
		<>
			<input {...getInputProps()} />
			<div className="chat-dropzone">
				<div className="chat-dropzone__content text-center">
					{isDragAccept && (
						<>
							<AttachFileIcon fontSize={'large'} color={'primary'} />
							<div className="chat-dropzone__text">Please drag files here to upload</div>
						</>
					)}
					{isDragReject && (
						<>
							<HighlightOffIcon color={'primary'} />
							<div className="chat-dropzone__text">File not supported</div>
						</>
					)}
				</div>
			</div>
		</>
	);
}
