import { DeleteButton } from './DeleteButton';
import { EditButton } from './EditButton';
import { ViewButton } from './ViewButton';
import { ReAssignButton } from './ReAssignButton';

const components = {
	DeleteButton,
	EditButton,
	ViewButton,
	ReAssignButton
};

export default components;
