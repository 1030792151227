import { useCallback, useState } from 'react';

export function useFormGuard() {
	const [formValues, setFormValues] = useState<string[]>([]);
	const [guardOpen, setGuardOpen] = useState(false);

	const formValuesSetter = useCallback(
		(fieldValues: string[]) => {
			if (fieldValues.length) setFormValues(fieldValues);
			if (formValues.length && !fieldValues.length) setFormValues([]);
		},
		[formValues]
	);

	return {
		formValues,
		guardOpen,
		setGuardOpen,
		formValuesSetter
	};
}
