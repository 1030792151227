import { useParams } from 'react-router';
import { useNotistack } from './useNotistack';
import { useEffect, useRef } from 'react';
import { useAxios } from './useAxios';

export function useConfirmationApi(redirectFn: () => void, isTransfer = false) {
	const params = useParams<{ code: string }>();

	const { showNotification } = useNotistack();
	const stsRequest = useRef(false);

	const { fetchData: handleRequest } = useAxios({
		url: '',
		dynamicUrl: true,
		cb: {
			success: (data, args) => {
				showNotification({
					message: data?.message || 'Successfully Confirmed',
					variant: 'success'
				});
				redirectFn();
			},
			error: (error, args) => {
				showNotification({
					message: error?.response?.data?.message || 'The request is incorrect',
					variant: 'error'
				});
				redirectFn();
			}
		}
	});

	useEffect(() => {
		if ('code' in params && !stsRequest.current) {
			stsRequest.current = true;

			handleRequest({ url: `${isTransfer ? '/transfer' : ''}/sts/${params.code}/` });
		}
	}, [params.code]);
}
