import React, { memo, useEffect, useMemo, useState } from 'react';
import {
	useDraftMessage,
	useGetEntityOne,
	useMessageMenu,
	useNotistack,
	useQueryParams
} from 'shared/hooks';
import { IFileMsgType } from 'shared/interfaces';
import { useChatContext, useChatInputContext } from 'shared/contexts';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useAppState } from 'shared/state';
import isEmpty from 'lodash/isEmpty';
import { useAuth } from 'modules/auth/hooks/useAuth';
import {
	AiFillCopy,
	AiFillDelete,
	AiFillEdit,
	AiFillInfoCircle,
	AiFillPushpin,
	AiOutlineCloudDownload
} from 'react-icons/ai';
import { RiReplyFill } from 'react-icons/ri';
import { HiTranslate, HiExternalLink } from 'react-icons/hi';
import config from 'config';
import { PiClockClockwise, PiPaperPlaneFill } from 'react-icons/pi';
import { useTranslation } from 'react-i18next';

const classList =
	'menu-item w-full flex items-center hover:bg-kgrey/20 hover:text-primary dark:hover:text-primary' +
	' transition px-3 py-3 leading-none cursor-pointer text-kgrey-chatGrey/70 dark:text-white text-sm';

type MenuItem = {
	textCode?: string;
	icon: React.ReactNode;
	text: string;
	handler: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	textOnly: boolean;
	isCopy: boolean;
	isOwnMsg: boolean;
	isDownload: boolean;
	copyText?: string;
	copyNotification?: string;
	viewInDeleted: boolean;
	viewInScheduled: boolean;
};

type FileUrlType = string | undefined | null;

type Props = {
	messageId?: number;
	handleClose: () => void;
	infoModalHandler: () => void;
	languageSelectModalHandler: () => void;
	fileType: IFileMsgType;
	fromUserFirstName: string;
	fromUserLastName: string;
	text: string;
	custom_uuid: string;
	isOwnMsg: boolean;
	repliedToId: number | null;
	fileUrl: FileUrlType;
	original_text?: string | null;
	isDeleted: boolean;
	scheduled_to?: number | null;
};

export const MessageMenu = memo(function MessageMenu({
	messageId,
	handleClose,
	fileType,
	fromUserFirstName,
	fromUserLastName,
	text,
	custom_uuid,
	isOwnMsg,
	repliedToId,
	infoModalHandler,
	fileUrl,
	original_text,
	languageSelectModalHandler,
	isDeleted,
	scheduled_to
}: Props) {
	const { t } = useTranslation();
	const {
		pinMessage,
		deleteMessage,
		handleSendScheduledMessageNow,
		handleReScheduledMessage,
		downloadFileHandler
	} = useMessageMenu(messageId, custom_uuid);
	const [isPinEnabled, setIsPinEnabled] = useState(false);
	const { setReply, setEditingMessage, editingMessage, setMessage, setReplyEditCancel } =
		useChatInputContext();
	const { showNotification } = useNotistack();
	const { entityId, entityType, isScheduledChat } = useChatContext();
	const { getLS, updateEntityDraft, removeLS, sendDeleteDraft } = useDraftMessage();
	const { userData } = useAuth();
	const draftObj = getLS();
	const selectedTextFragment = window.getSelection()?.toString();

	const { activeDraft, setActiveDraft } = useAppState();

	const chatEntity = useGetEntityOne({
		entity: 'ims-chats',
		entityId,
		entityName: `GroupChat-${entityId}One`
	});
	const { query } = useQueryParams();

	const isSupportCase = !!query.isSupportCase;

	const pinMessageHandler = () => pinMessage(entityId);
	const editMessageHandler = () => {
		setReply(null);

		const setEditMsgHandler = () => {
			setEditingMessage({
				id: messageId as number,
				text: original_text || text,
				custom_uuid,
				fileType,
				replied_to_id: repliedToId,
				...{ ...(scheduled_to ? { scheduled_to } : {}) }
			});
		};
		//for hot replacing edit message
		if (editingMessage) {
			setReplyEditCancel(true);
			setEditingMessage(null);

			if (activeDraft || entityId in draftObj) {
				updateEntityDraft('reset');
				setActiveDraft(null);
				removeLS(entityId);
				sendDeleteDraft();
				setMessage('');
			}

			setTimeout(() => {
				setEditMsgHandler();
			}, 50);
			return;
		}

		setEditMsgHandler();
	};

	const messageLink =
		entityType === 'case'
			? `${config.REACT_APP_URL}/case-messages?case=${entityId}&searchedMessageId=${messageId}&status=${query.status}`
			: `${config.REACT_APP_URL}/group-chat?chat=${entityId}&searchedMessageId=${messageId}`;

	// function setReplyDraft(draft: any) {
	// 	const copiedDraft = { ...draft };
	// 	if (copiedDraft.editing_id) {
	// 		copiedDraft.editing_id = null;
	// 		copiedDraft.replied_to = repliedToId;
	// 	}
	//
	// 	const finalData = {
	// 		...draftObj,
	// 		[entityId]: copiedDraft
	// 	};
	// 	const entityName = `${entityType}Draft`;
	// 	console.log('finalData in messageMenu reply', { finalData, entityName, repliedToId });
	// 	storage.set(entityName, JSON.stringify(finalData));
	// 	saveDraft();
	// }

	useEffect(() => {
		if (entityType === 'ims' && !isEmpty(chatEntity.item)) {
			const currentMember = chatEntity.item.members.find(
				(member: any) => member.user.id === userData.id
			);

			if (currentMember.is_allowed_pin_msg) {
				setIsPinEnabled(true);
			}
		}
		if (entityType === 'case') {
			setIsPinEnabled(true);
		}
	}, [chatEntity, entityType, userData.id]);

	const menuItems: MenuItem[] = useMemo(() => {
		return [
			{
				icon: <RiReplyFill />,
				text: t('reply_label'),
				handler: () => {
					setEditingMessage(null);
					setReply({
						text,
						file_type: fileType,
						from_user_first_name: fromUserFirstName,
						from_user_last_name: fromUserLastName,
						id: messageId
					});
					// console.log('draft in MenuItem', draftObj);
					// if (String(entityId) in draftObj) {
					// 	const draft = { ...draftObj[entityId] };
					// 	setReplyDraft(draft);
					// }
				},
				textOnly: false,
				isCopy: false,
				isDownload: false,
				isOwnMsg: false,
				viewInDeleted: false,
				viewInScheduled: false
			},
			{
				icon: <PiPaperPlaneFill />,
				text: t('send_now'),
				textCode: 'send_now',
				handler: handleSendScheduledMessageNow,
				textOnly: false,
				isCopy: false,
				isDownload: false,
				isOwnMsg: false,
				viewInDeleted: false,
				viewInScheduled: true
			},
			{
				icon: <PiClockClockwise />,
				text: t('reschedule'),
				textCode: 're_schedule',
				handler: handleReScheduledMessage,
				textOnly: false,
				isCopy: false,
				isDownload: false,
				isOwnMsg: false,
				viewInDeleted: false,
				viewInScheduled: true
			},
			{
				icon: <AiFillPushpin />,
				text: t('pin_title'),
				textCode: 'pin_title',
				handler: pinMessageHandler,
				textOnly: true,
				isCopy: false,
				isDownload: false,
				isOwnMsg: false,
				viewInDeleted: false,
				viewInScheduled: false
			},
			{
				icon: <HiTranslate />,
				text: t('change_language'),
				handler: languageSelectModalHandler,
				textOnly: true,
				isCopy: false,
				isDownload: false,
				isOwnMsg: false,
				viewInDeleted: false,
				viewInScheduled: false
			},
			{
				icon: <AiFillInfoCircle />,
				text: t('info'),
				handler: infoModalHandler,
				textOnly: false,
				isCopy: false,
				isDownload: false,
				isOwnMsg: false,
				viewInDeleted: true,
				viewInScheduled: false
			},
			{
				icon: <AiFillEdit />,
				text: t('edit'),
				handler: editMessageHandler,
				textOnly: false,
				isCopy: false,
				isDownload: false,
				isOwnMsg: true,
				viewInDeleted: false,
				viewInScheduled: true
			},
			{
				icon: <AiFillCopy />,
				text: `${selectedTextFragment ? t('copy_selected_text') : t('copy_text')}`,
				copyText: selectedTextFragment ? selectedTextFragment : text,
				copyNotification: t('message_copied_success'),
				handler: () => {},
				textOnly: true,
				isCopy: true,
				isDownload: false,
				isOwnMsg: false,
				viewInDeleted: true,
				viewInScheduled: true
			},
			{
				icon: <HiExternalLink />,
				text: t('copy_message_link'),
				copyText: messageLink,
				copyNotification: t('message_link_copied_success'),
				handler: () => {},
				textOnly: false,
				isCopy: true,
				isDownload: false,
				isOwnMsg: false,
				viewInDeleted: true,
				viewInScheduled: false
			},
			{
				icon: <AiFillDelete />,
				text: t('delete'),
				handler: () => deleteMessage(),
				textOnly: false,
				isCopy: false,
				isDownload: false,
				isOwnMsg: true,
				viewInDeleted: false,
				viewInScheduled: true
			},
			{
				icon: <AiOutlineCloudDownload />,
				text: t('download'),
				handler: () => {
					if (fileUrl) {
						downloadFileHandler(fileUrl, fileType);
					} else {
						showNotification({
							message: 'Something went wrong, while downloading file'
						});
					}
				},
				textOnly: false,
				isCopy: false,
				isDownload: true,
				isOwnMsg: false,
				viewInDeleted: false,
				viewInScheduled: true
			}
		];
	}, [
		setEditingMessage,
		setReply,
		text,
		fromUserFirstName,
		fromUserLastName,
		messageId,
		pinMessageHandler,
		infoModalHandler,
		editMessageHandler,
		deleteMessage
	]);

	return (
		<>
			<div className="max-w-fit rounded-xl pr-1 text-base">
				{menuItems
					.filter((item: MenuItem) => (text.length ? item : !item.textOnly)) //don't render copy and pin msg for media messages
					.filter((item: MenuItem) => (isOwnMsg ? item : !item.isOwnMsg)) //do not render edit & delete from opponent message
					.filter((item: MenuItem) => item?.textCode !== 'pin_title' || isPinEnabled) //Render Pin title only if it is case or group member as pin enable rights
					.filter((item: MenuItem) => (fileType ? item : !item.isDownload))
					.filter((item: MenuItem) => !(item?.textCode === 'pin_title' && isSupportCase)) //Do not show Pin title if it is Support Case which is opened right after creating feedback in we business
					.filter((item: MenuItem) => !(!item.viewInDeleted && isDeleted)) //Show only Info menu item for deleted message
					.filter((item: MenuItem) =>
						isScheduledChat
							? item.viewInScheduled
							: !(item?.textCode === 'send_now' || item?.textCode === 're_schedule')
					) //show menu items for scheduled messages
					.map((item: MenuItem) => {
						return (
							<React.Fragment key={item.text}>
								{item.isCopy ? (
									<CopyToClipboard
										text={item.copyText ?? ''}
										onCopy={() => {
											showNotification({
												message: item.copyNotification ?? 'Done',
												variant: 'info'
											});
											setTimeout(() => handleClose(), 0);
										}}
										key={item.text}
									>
										{/*CopyToClipboard lib does not work with component type*/}
										<button className={classList}>
											<div className="menu-item__icon mx-2">{item.icon}</div>
											<div className="menu-item__text">{item.text}</div>
										</button>
									</CopyToClipboard>
								) : (
									<MenuItemComponent
										fileUrl={fileUrl}
										item={item}
										handleClose={handleClose}
										key={item.text}
									/>
								)}
							</React.Fragment>
						);
					})}
			</div>
		</>
	);
});

type MenuItemButton = {
	item: MenuItem;
	handleClose: () => void;
	fileUrl: FileUrlType;
};

function MenuItemComponent({ item, handleClose, fileUrl }: MenuItemButton) {
	return (
		<>
			<button
				className={classList}
				onClick={(e) => {
					item.handler(e);
					handleClose();
				}}
			>
				<div className="menu-item__icon mx-2">{item.icon}</div>
				<div className="menu-item__text">{item.text}</div>
			</button>
		</>
	);
}

MessageMenu.displayName = 'MessageMenu';
