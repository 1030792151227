import { AiFillDelete } from 'react-icons/ai';
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';

type Props = {
	clickHandler: (entity?: any) => void;
	isDisabled?: boolean;
	className?: string;
};

export const DeleteButton = ({ clickHandler, isDisabled = false, className = '' }: Props) => {
	return (
		<div className={clsx({ 'cursor-not-allowed': isDisabled })}>
			<IconButton className={`group ${className}`} disabled={isDisabled} onClick={clickHandler}>
				<AiFillDelete size="18px" className="text-kgrey transition group-hover:text-kred-pink" />
			</IconButton>
		</div>
	);
};
