import React from 'react';
import { Avatar } from './Avatar';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		avatarColor: (props: Props) => ({
			backgroundColor: props.color ? props.color : ''
		}),
		root: (props: Props) => ({
			[theme.breakpoints.down('sm')]: {
				width: props.smallSize && `${props.smallSize}px`,
				height: props.smallSize && `${props.smallSize}px`
			},
			width: props.size && `${props.size}px`,
			height: props.size && `${props.size}px`
		})
	})
);

type Props = {
	color?: string;
	src?: string;
	letter?: string;
	alt?: string;
	className?: string;
	smallSize?: number;
	size?: number;
};

export function AvatarSized({ color, src, letter, alt, className = '', smallSize, size }: Props) {
	const classes = useStyles({ color, smallSize, size });

	return (
		<Avatar
			src={src}
			letter={letter}
			alt={alt}
			className={clsx(classes.avatarColor, className)}
			classes={{ root: classes.root }}
		/>
	);
}
