import React, { useCallback, useState } from 'react';

export function useSwitch(fields: Record<string, boolean>) {
	const initialObject = Object.keys(fields).reduce((acc, fieldName) => {
		return { ...acc, [fieldName]: fields[fieldName] };
	}, {});

	const [checked, setChecked] = useState<Record<string, boolean>>(initialObject);

	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setChecked({ ...checked, [event.target.name]: event.target.checked });
		},
		[checked]
	);

	return {
		checked,
		handleChange,
		setChecked
	};
}
