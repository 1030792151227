import React, { useEffect, useState } from 'react';
import { useAppState } from 'shared/state';
import { useGetEntityOne, useQueryParams } from 'shared/hooks';
import EntityForm from 'modules/entity/forms';
import { getCaseEntityName } from 'shared/services';
import { Button, ErrorBoundary, Loader } from 'shared/components';
import EntityContainer from 'modules/entity/containers';
import { PriorityType } from 'shared/typings/priority.types';
import { useCaseExtraParams } from '../hooks/useCaseExtraParams';
import FormControl from '@material-ui/core/FormControl';
import { FormControlLabel, Radio, RadioGroup } from 'shared/components/Elements';
import { useTranslation } from 'react-i18next';

type Props = {
	onSuccess: () => void;
	statusId?: number;
};

function PriorityChangeModalInner({ onSuccess }: Props) {
	const [option, setOption] = useState<PriorityType | null>(null);
	const { t } = useTranslation();

	//TODO: commented for reusability matters. May have side effect later!
	// const { entityId } = useChatContext();
	const { companyId, caseType, searchQuery, isStaff } = useAppState();
	const { query } = useQueryParams();

	const statusId = Number(query?.statusId) || Number(query?.status);
	const entityId = Number(query?.caseId) || Number(query?.case);

	const caseEntity = useGetEntityOne({
		entity: 'cases',
		entityId,
		entityName: `CaseChat-${entityId}`
	});

	useEffect(() => {
		const priority = caseEntity.item?.priority;
		setOption({
			name: `${priority[0]}${priority.slice(1).toLowerCase()}` as PriorityType['name'],
			code: caseEntity.item?.priority
		});
	}, []);

	const { selectedFilterTemplate } = useCaseExtraParams({
		statusId: Number(query?.status)
	});

	const entityName = getCaseEntityName({
		statusId,
		caseType,
		searchQuery,
		selectedFilterTemplate
	});

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target as HTMLInputElement;
		const opt = {
			name: value,
			code: value.toUpperCase()
		} as PriorityType;

		setOption(opt);
	};

	return (
		<>
			{/*Load Priorities*/}
			<EntityContainer.All
				entity="priorities"
				name={`AllPriorities-${companyId}`}
				url={`/${companyId}/cases/as_business/priorities/`}
				dataKey={(data: any) => data.choices}
				primaryKey="code"
				params={{
					limit: 10,
					page: 1
				}}
			>
				{({ items: priorityItems, isFetched }) => {
					const typedItems = priorityItems as PriorityType[];
					return (
						//Form Handler
						<EntityForm.Main
							entity="cases"
							name={entityName}
							url={`/${companyId}/cases/as_business/${entityId}/`}
							id={entityId}
							method={'patch'}
							updateData
							normalizeData={(data: any) => data}
							sendAsFormData={false}
							params={{
								extra: {
									client_company: isStaff ? companyId : null
								}
							}}
							fields={[
								{
									name: 'priority',
									value: option,
									onSubmitKey: '',
									onSubmitValue: (value, values) => value.code,
									type: 'object'
								}
							]}
							onSuccess={(data) => {
								onSuccess();
							}}
							onError={(error) => console.log('priority change error', error)}
						>
							{({ isSubmitting }) => {
								return (
									<div>
										<h1 className={'mb-4 text-2xl font-bold'}>{t('change_priority')}</h1>
										<FormControl component="fieldset">
											{/*Wait for option setup. otherwise there will error in console*/}
											{option ? (
												<RadioGroup
													aria-label="priority"
													name="priority"
													value={option?.code}
													onChange={handleChange}
												>
													<>
														{typedItems?.map((item) => {
															return (
																<div className={'-mb-3'} key={item.code}>
																	<FormControlLabel
																		value={item.code}
																		control={<Radio color="primary" />}
																		label={item.name}
																		key={item.code}
																	/>
																</div>
															);
														})}
													</>
												</RadioGroup>
											) : null}
										</FormControl>
										{!isFetched && <Loader tiled withOverlay />}
										<div className={'mt-8 flex justify-end'}>
											{isSubmitting && <Loader tiled withOverlay />}
											<Button onClickHandler={onSuccess} className={'mr-5'} type={'button'}>
												{t('cancel')}
											</Button>
											<Button
												disabled={!option || isSubmitting}
												type={'submit'}
												variant={'contained'}
												className="ml-4 hover:!text-primary"
											>
												{t('save')}
											</Button>
										</div>
									</div>
								);
							}}
						</EntityForm.Main>
					);
				}}
			</EntityContainer.All>
		</>
	);
}

export function PriorityChangeModal(props: Props) {
	return (
		<ErrorBoundary>
			<PriorityChangeModalInner {...props} />
		</ErrorBoundary>
	);
}
