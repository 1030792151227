import React from 'react';
import clsx from 'clsx';
import { IconFilterDelete } from '../../../shared/icons';
import { useTranslationHelpers } from 'shared/hooks/useTranslationHelpers';

type FilterItemProps = {
	children: React.ReactNode;
	onRemove?: () => void;
	alignment?: string;
	className?: string;
	iconClassName?: string;
	removeIcon?: boolean;
};

export function FilterItem({
	children,
	onRemove = () => {},
	alignment = 'center',
	className = '',
	iconClassName = '',
	removeIcon = false
}: FilterItemProps) {
	return (
		<div
			className={clsx('filtem-wrapper flex  justify-between', className, {
				'items-center': alignment === 'center',
				'items-start': alignment === 'top'
			})}
		>
			<div className="filter-wrapper__input flex-grow ">{children}</div>
			{removeIcon ? null : (
				<div
					className={clsx(
						'filter-wrapper__delete relative mx-2 cursor-pointer transition hover:text-kred-pink ',
						iconClassName
					)}
				>
					<div onClick={onRemove}>
						<IconFilterDelete />
					</div>
				</div>
			)}
		</div>
	);
}
