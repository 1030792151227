import React from 'react';

export function IconPin({ className = '' }: { className?: string }) {
	return (
		<svg
			width="13"
			height="13"
			className={className}
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M7.6.632a.437.437 0 01.31.128l4.33 4.33a.438.438 0 010 .62c-.42.42-.938.514-1.315.514-.155 0-.293-.016-.402-.034L7.78 8.932c.072.291.119.588.14.886.04.615-.028 1.477-.63 2.079a.438.438 0 01-.619 0L4.197 9.422l-2.785 2.784c-.17.171-1.066.79-1.237.619-.17-.17.448-1.067.619-1.237l2.784-2.785-2.474-2.475a.438.438 0 010-.619c.601-.602 1.463-.67 2.078-.63.299.021.595.068.886.14L6.81 2.48a2.426 2.426 0 01-.035-.404c0-.377.095-.895.516-1.315a.437.437 0 01.308-.128z" />
		</svg>
	);
}
