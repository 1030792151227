import React from 'react';
import { IconCheck } from '../../icons';

export function RoundCheck() {
	return (
		<div className="flex-centered h-[18px] w-[18px] rounded-full bg-primary-dark-150">
			<IconCheck />
		</div>
	);
}
