import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/Elements';
import { useChatInputContext } from 'shared/contexts';
import { TScheduleError } from '../hooks/useScheduleDatePick';

type Props = {
	onScheduleModalCloseClicked: () => void;
	handleSaveScheduleDate: () => void;
	scheduleError: TScheduleError;
};

export default function ScheduleDateTimeFooter({
	onScheduleModalCloseClicked,
	handleSaveScheduleDate,
	scheduleError
}: Props) {
	const { t } = useTranslation();
	const { setTempMessageEvent } = useChatInputContext();

	const onCloseClicked = useCallback(() => {
		onScheduleModalCloseClicked();
		setTempMessageEvent(null);
	}, []);
	return (
		<div className="footer__buttons flex justify-end gap-x-2">
			<Button onClick={onCloseClicked}>{t('cancel')}</Button>
			<Button
				onClick={handleSaveScheduleDate}
				classes={{ root: '!bg-primary-dark !text-white' }}
				variant="contained"
				disabled={scheduleError?.error}
			>
				{t('schedule_send')}
			</Button>
		</div>
	);
}
