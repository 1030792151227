import { useChatInputContext } from 'shared/contexts';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useTranslation } from 'react-i18next';

export type SchedulingPresetDate = {
	id: number;
	title: string;
	schedulingDate: dayjs.Dayjs;
	isHidden: boolean;
};

export type TScheduleError = {
	error: boolean;
	message: string;
} | null;

type Props = {
	onScheduleModalCloseClicked: () => void;
};

interface SchedulingDatePickReturnType {
	scheduleError: TScheduleError;
	handleSaveScheduleDate: () => void;
	handleDateChange: (date: any) => void;
	handleTimeChange: (date: MaterialUiPickersDate) => void;
	handleSelectPresetDateTime: (presetDate: SchedulingPresetDate) => void;
	toggleOpenCustomDate: () => void;
	openCustomDate: boolean;
	schedulingTime: {
		hour: number;
		minute: number;
	};
	schedulingDate: Date;
	selectedPresetDate: SchedulingPresetDate | null;
	selectedSchedulingDateTime: string | null;
}

export const useScheduleDatePick = ({
	onScheduleModalCloseClicked
}: Props): SchedulingDatePickReturnType => {
	const { t } = useTranslation();

	const { tempMessageEvent, setMessageScheduleDate } = useChatInputContext();

	//getting scheduled_to value of re-scheduling message.
	const alreadyScheduledMessageDate = tempMessageEvent?.data?.scheduled_to;

	const [scheduleError, setScheduleError] = useState<{ error: boolean; message: string } | null>(
		null
	);
	const [openCustomDate, setOpenCustomDate] = useState(false);

	//date value of calendar. If re-scheduled message, put scheduled_to value of that message. Otherwise, today's date.
	const [schedulingDate, setSchedulingDate] = useState(
		//Date constructor expects milliseconds not unix seconds
		alreadyScheduledMessageDate && tempMessageEvent?.type === 'RE_SCHEDULE'
			? new Date(alreadyScheduledMessageDate * 1000)
			: new Date()
	);

	//Time value of timepicker.
	const [schedulingTime, setSchedulingTime] = useState({
		hour: schedulingDate.getHours(),
		minute: schedulingDate.getMinutes()
	});
	const [selectedPresetDate, setSelectedPresetDate] = useState<SchedulingPresetDate | null>(null);
	const [selectedSchedulingDateTime, setSelectedSchedulingDateTime] = useState<string | null>(null);

	useEffect(() => {
		if (openCustomDate) {
			setSelectedSchedulingDateTime(
				dayjs(schedulingDate).hour(schedulingTime.hour).minute(schedulingTime.minute).format()
			);
		} else {
			setSelectedSchedulingDateTime(selectedPresetDate?.schedulingDate?.format() as string);
		}
	}, [schedulingTime, schedulingDate, selectedPresetDate, openCustomDate]);

	const toggleOpenCustomDate = () => {
		setScheduleError(null);
		setOpenCustomDate((prevState) => !prevState);
	};

	//If user selects preset date&time, set date&time value
	const handleSelectPresetDateTime = (presetDateTime: SchedulingPresetDate) => {
		setScheduleError(null);
		setSelectedPresetDate(presetDateTime);
	};

	//When Schedule Send clicked, set Date&Time values.
	const handleSaveScheduleDate = () => {
		if (!selectedSchedulingDateTime) {
			return setScheduleError({
				error: true,
				message: 'The time was not selected'
			});
		}

		const currentTime = new Date();
		const selectedTime = new Date(selectedSchedulingDateTime);

		if (selectedTime && selectedTime < currentTime) {
			// If the selected time is in the past, do not update the state
			setScheduleError({
				error: true,
				message: t('past_time_selected')
			});
			return;
		} else {
			setScheduleError(null);
		}

		setMessageScheduleDate(dayjs(selectedSchedulingDateTime).unix());
		setSelectedSchedulingDateTime(null);
		setSelectedPresetDate(null);

		onScheduleModalCloseClicked();
	};

	//For Calendar, set date value when date is selected
	const handleDateChange = (date: any) => {
		setSchedulingDate(date);
	};

	//For TimePicker, set time value when time is selected
	const handleTimeChange = (date: MaterialUiPickersDate) => {
		// Check if the selected time is in the past
		const currentTime = new Date();
		const selectedTime = new Date(schedulingDate);

		if (date) {
			selectedTime.setHours(date.hour(), date.minute(), 0, 0);
		}

		if (selectedTime && selectedTime < currentTime) {
			// If the selected time is in the past, do not update the state
			setScheduleError({
				error: true,
				message: t('past_time_selected')
			});
		} else {
			setScheduleError(null);
		}
		// If the selected time is in the future, update the state
		if (date) {
			setSchedulingTime({
				hour: date.hour(),
				minute: date.minute()
			});
		}
	};

	return {
		scheduleError,
		handleSaveScheduleDate,
		handleDateChange,
		handleTimeChange,
		toggleOpenCustomDate,
		openCustomDate,
		handleSelectPresetDateTime,
		schedulingDate,
		schedulingTime,
		selectedPresetDate,
		selectedSchedulingDateTime
	};
};
