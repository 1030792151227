import { loadEntitiesSuccess } from 'store/actions/entities';
import { formSuccess } from 'modules/entity/actions';
import { useDispatch } from 'react-redux';

type AppendEntity = {
	entityObject: any;
	primaryKey: string;
	entity: string;
	entityName: string;
	appendData?: boolean;
	prependData?: boolean;
	deleteData?: boolean;
	updateData?: boolean;
};

export function useUpdateEntity() {
	const dispatch = useDispatch();

	function appendToStore({
		entityObject,
		primaryKey,
		entity,
		entityName,
		appendData = false,
		prependData = false,
		deleteData = false,
		updateData = false
	}: AppendEntity) {
		const id = entityObject[primaryKey];
		const entitiesPayload = {
			[entity]: {
				[id]: entityObject
			}
		};

		dispatch(loadEntitiesSuccess(entitiesPayload));
		dispatch(
			formSuccess({
				id,
				entity,
				name: entityName,
				appendData,
				prependData,
				updateData,
				deleteData
			})
		);
	}

	return {
		appendToStore
	};
}
