import React, { useEffect, useState } from 'react';
import { IMessage, ScrollToMsg } from 'shared/interfaces';
import { useMessageFields } from 'shared/hooks/chat-hooks/useMessageFields';
import clsx from 'clsx';
import ReactPlayer from 'react-player/lazy';
import { RepliedMediaMsgWrapper } from './RepliedMediaMsgWrapper';
import { isMobile } from 'react-device-detect';
import isEqual from 'lodash/isEqual';
import { useQueryParams, useWhiteModal } from '../../../hooks';
import { EmojiCountInfo } from './EmojiCountInfo';
import { useCachedReactedUsers } from '../../../hooks/chat-hooks/useCachedReactedUsers';
import { DeletedMsg } from './DeletedMsg';

type Props = {
	message: IMessage;
	setScrollToMsgId: ScrollToMsg;
};

function areEqual(prev: any, next: any) {
	return (
		prev.message?.is_deleted === next.message?.is_deleted &&
		prev.message.file_type === next.message.file_type &&
		prev.message.file?.url === next.message.file?.url &&
		isEqual(prev.message.reacted_users, next.message.reacted_users) &&
		isEqual(prev.message?.replied_to, next.message?.replied_to)
	);
}

function VideoMsg_({ message, setScrollToMsgId }: Props) {
	const { is_deleted, file, replied_to, isOwnMsg, file_type, reacted_users, messageId } =
		useMessageFields(message);
	const { changeUrlParams, removeOneQueryParam, query } = useQueryParams();
	const [isPlaying, setIsPlaying] = useState(true);
	const isMediaActive = query.isMediaActive;

	const {
		open: isEmojiCountOpen,
		modalHandler: isEmojiCountOpenHandler,
		WhiteModal: EmojiWhiteModal
	} = useWhiteModal();

	const { cachedReactions } = useCachedReactedUsers({
		reacted_users,
		file_type,
		isEmojiCountOpenHandler
	});

	useEffect(() => {
		if (!isMediaActive) {
			handlePause();
		}
	}, [isMediaActive]);

	const onPlay = () => {
		changeUrlParams({ isMediaActive: true });
		setIsPlaying(true);
	};
	const onPause = () => removeOneQueryParam('isMediaActive');
	const onEnded = () => {
		removeOneQueryParam('isMediaActive');
	};

	function handlePause() {
		setIsPlaying(false);
	}

	if (is_deleted) {
		return <DeletedMsg isOwnMsg={isOwnMsg} type="video" />;
	}

	return (
		<div
			className={clsx(
				'overflow-hidden break-words text-sm text-kdark shadow-text-msg dark:text-white',
				{
					'rounded-b-xl rounded-tr-xl bg-white dark:bg-white/20': !isOwnMsg,
					'rounded-t-xl rounded-bl-xl bg-primary-200': isOwnMsg
				}
			)}
		>
			<div className={'flex flex-col items-center'}>
				<RepliedMediaMsgWrapper
					replied_to={replied_to}
					isOwnMsg={isOwnMsg}
					file_type={file_type as string}
					setScrollToMsgId={setScrollToMsgId}
				>
					<div className={clsx('video-msg h-72')}>
						<ReactPlayer
							{...(typeof file?.url === 'string' ? { url: file?.url } : {})}
							controls
							style={{ maxWidth: '100%', height: '100%' }}
							width={isMobile ? 300 : 360}
							height={'100%'}
							light={file?.thumbnail as string}
							playing={isPlaying}
							onPlay={onPlay}
							onPause={onPause}
							onEnded={onEnded}
						/>
					</div>
				</RepliedMediaMsgWrapper>
			</div>
			<div className="px-2">{reacted_users?.length ? cachedReactions : null}</div>
			<EmojiWhiteModal handleModalOpen={isEmojiCountOpenHandler} open={isEmojiCountOpen}>
				<EmojiCountInfo messageId={messageId} reactedUsers={reacted_users} />
			</EmojiWhiteModal>
		</div>
	);
}

export const VideoMsg = React.memo(VideoMsg_, areEqual);
