import { useAppState } from '../state';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { useQueryParams } from './useQueryParams';
import { useCallback } from 'react';

export function useFilterTemplateState() {
	const { selectedCaseFilter, caseType, chatsMentionList, unreadMessagesCount } = useAppState();
	const { userData } = useAuth();
	const { query } = useQueryParams();

	const entityId = Number(query?.case);

	const selectedFilterTemplate = selectedCaseFilter[caseType];
	const noMentionsLeftForThisCase = !chatsMentionList?.[entityId]?.length;
	const noUnreadCountLeftForThisCase = !unreadMessagesCount?.[entityId];

	let itShouldSatisfyActiveFilter = false;

	const hasUnreadFilter = selectedFilterTemplate
		? selectedFilterTemplate?.filter_json?.unread
		: false;

	const priorityFilter = selectedFilterTemplate?.filter_json?.query_web?.priority ?? null;
	const assigneeFilter = selectedFilterTemplate?.filter_json?.query_web?.assignees ?? null;
	const filterType: 'and' | 'or' = selectedFilterTemplate?.filter_type?.value || 'and';
	const andFilterType = filterType === 'and';
	const orFilterType = filterType === 'or';
	const hasPinnedFilter = selectedFilterTemplate?.filter_json?.pinned;
	const hasTaggedFilter = selectedFilterTemplate?.filter_json?.tagged;
	const filterConditions = [];
	const filterResults = [];

	if (hasUnreadFilter) {
		filterConditions.push(hasUnreadFilter);
		filterResults.push(noUnreadCountLeftForThisCase);
	}

	if (hasTaggedFilter) {
		filterConditions.push(hasTaggedFilter);
		filterResults.push(noMentionsLeftForThisCase);
	}

	if (andFilterType && filterConditions.length && filterConditions.every((filter) => !!filter)) {
		itShouldSatisfyActiveFilter = filterResults.every((result) => !!result);
	}

	if (orFilterType && filterConditions.length && filterConditions.some((filter) => !!filter)) {
		itShouldSatisfyActiveFilter = filterResults.some((result) => !!result);
	}

	const selectedFilterTemplateId = selectedFilterTemplate?.id;

	const isRemovedUserInAssigneeFilterList = useCallback(
		(userId: number) => {
			return assigneeFilter ? assigneeFilter.includes(userId?.toString()) : false;
		},
		[assigneeFilter]
	);

	return {
		hasUnreadFilter,
		selectedFilterTemplate,
		priorityFilter,
		assigneeFilter,
		selectedFilterTemplateId,
		filterType,
		andFilterType,
		hasPinnedFilter,
		itShouldSatisfyActiveFilter,
		isRemovedUserInAssigneeFilterList
	};
}
