import React from 'react';
import clsx from 'clsx';

type IconBtnType = {
	classnames?: string;
	colorClass?: string;
	hoverClass?: string;
	children: React.ReactNode;
	onClick?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};
export function IconBtn({
	classnames = '',
	colorClass = '',
	hoverClass = 'hover:text-primary',
	children,
	onClick = () => {}
}: IconBtnType) {
	return (
		<>
			<div
				className={clsx('chat-inp-controls__icon flex-centered mr-4 flex-shrink-0', classnames)}
				onClick={onClick}
			>
				<div
					className={clsx(
						'flex-centered h-6 w-6 cursor-pointer  transition',
						colorClass,
						hoverClass
					)}
				>
					{children}
				</div>
			</div>
		</>
	);
}
