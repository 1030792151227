import React from 'react';
import clsx from 'clsx';
import { getDeletedMessageType } from '../utils';
import { useTranslation } from 'react-i18next';

type Props = {
	type: 'image' | 'text' | 'audio' | 'pdf' | 'location' | 'file' | 'video' | 'contact';
	isOwnMsg: boolean;
};
export function DeletedMsg(props: Props) {
	const { type, isOwnMsg } = props;
	const messageTextType = getDeletedMessageType(type);
	const { t } = useTranslation();

	return (
		<div
			className={clsx(
				'break-words rounded-t-xl border border-kgrey-chatBorder bg-kgrey-light-grey-7 px-5 py-4 text-sm shadow-text-msg',
				{
					'rounded-br-xl': !isOwnMsg,
					'rounded-bl-xl': isOwnMsg
				}
			)}
		>
			{t('message_deleted', { message: t(`${type}_message`) })}
		</div>
	);
}
