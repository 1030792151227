import React, { useCallback, useState, useMemo } from 'react';
import { RenderReacted } from './useChatHelpers';
import { useUpdateEntities } from 'shared/hooks/useUpdateEntities';
import { useChatContext, useChatInputContext } from 'shared/contexts';
import { useAxios } from 'shared/hooks/useAxios';
const initialState = {
	mouseX: null,
	mouseY: null
};

export function useMessageActions(
	toggleReactionPicker?: (isToggled: boolean) => void,
	messageId?: number | undefined
) {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [coords, setCoords] = useState<any>(null);
	const { updateEntities } = useUpdateEntities();
	const { entityType } = useChatContext();
	const { setReplyEditCancel } = useChatInputContext();
	const [contextMenu, showContextMenu] = React.useState<{
		mouseX: null | number;
		mouseY: null | number;
	}>(initialState);

	const cachedCoords = useMemo(() => {
		return coords;
	}, [coords]);
	const cachedContextMenu = useMemo(() => {
		return contextMenu;
	}, [contextMenu]);

	//For sending reacted emoji
	const { fetchData: sendReaction } = useAxios({
		url: '',
		dynamicUrl: true,
		dynamicValues: true,
		method: 'post',
		cb: {
			success: (data, args) => {},
			error: (error, args) => {
				const [arg] = args;
				updateEntities({
					entity: `${entityType}Messages`,
					entityId: arg.custom_uuid,
					updatingData: { reacted_users: arg.initialValues }
				});
			}
		}
	});

	//For deleting reacted emoji
	const { fetchData: deleteEmoji } = useAxios({
		url: '',
		dynamicUrl: true,
		method: 'delete',
		cb: {
			success: (data, args) => {},
			error: (error, args) => {
				const [arg] = args;
				updateEntities({
					entity: `${entityType}Messages`,
					entityId: arg.custom_uuid,
					updatingData: { reacted_users: arg.initialValues }
				});
			}
		}
	});

	const handleContextClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
		event.preventDefault();
		showContextMenu({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4
		});
		setReplyEditCancel(true);
	}, []);

	const handleContextClose = useCallback(() => {
		showContextMenu(initialState);
	}, [initialState]);

	const handleClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
		setAnchorEl(event.currentTarget);
		setReplyEditCancel(true);
	}, []);
	const handleClose = useCallback(() => {
		setAnchorEl(null);
		setReplyEditCancel(false);
	}, []);

	//Rendering reacted emoji
	const renderReacted = useCallback(
		({
			reactedUsers,
			messageId,
			emoji,
			toBeDeleted = false,
			initialReactedMessage,
			custom_uuid
		}: RenderReacted) => {
			// Update emojies in UI
			updateEntities({
				entity: `${entityType}Messages`,
				entityId: custom_uuid,
				updatingData: { reacted_users: reactedUsers }
			});

			//Auto emoji picker close
			setTimeout(() => {
				if (toggleReactionPicker) {
					toggleReactionPicker(false);
				}
			}, 0);

			if (toBeDeleted) {
				deleteEmoji({
					url: `/messages/${messageId}/react/`,
					initialValues: initialReactedMessage,
					custom_uuid
				});
				return;
			}
			sendReaction({
				url: `/messages/${messageId}/react/`,
				values: { emoji },
				initialValues: initialReactedMessage,
				custom_uuid
			});
		},
		[toggleReactionPicker]
	);

	const getCoordinatesHandler = useCallback(
		(e: React.MouseEvent<HTMLDivElement>) => {
			const messageEl: HTMLDivElement | null = document.querySelector(
				`#message-${messageId} .message-content`
			);

			let messageElBounding: any;
			if (messageEl) {
				messageElBounding = messageEl.getBoundingClientRect();
			}
			const coords = {
				top: Math.abs(messageElBounding.top - e.pageY),
				left: Math.abs(messageElBounding.left - e.pageX)
			};
			setCoords(coords);
		},
		[messageId]
	);

	return {
		anchorEl,
		contextMenu: cachedContextMenu,
		handleContextClick,
		handleContextClose,
		handleClick,
		handleClose,
		renderReacted,
		coords: cachedCoords,
		getCoordinatesHandler
	};
}
