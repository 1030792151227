import clsx from 'clsx';
import { ReactNode } from 'react';
import Loader from '../../Loader';

type Props = {
	isDisabled?: boolean;
	type?: 'button' | 'submit';
	className?: string;
	children: ReactNode;
	isSubmitting?: boolean;
	color?: 'primary' | 'default' | 'transparent';
	iconComponent?: ReactNode | null;
	iconClassNames?: string;
	onClick?: () => void;
};

const primaryColorCssClasses = (isDisabled: boolean) =>
	clsx(
		' bg-primary-dark rounded-lg text-white px-5 py-2 border border-transparent ' +
			' transition-colors ' +
			' disabled:opacity-50',
		{ 'hover:border-primary-dark hover:bg-white hover:text-primary-dark': !isDisabled }
	);

const defaultColorCssClasses = (isDisabled: boolean) =>
	clsx(
		'bg-kgrey-bg rounded-xl text-sm md:text-base font-poppins text-center px-4 py-2 ' +
			' transition dark:bg-kdark-dark333 dark:text-white disabled:opacity-50',
		{ 'hover:bg-primary-200 dark:hover:bg-primary-200': !isDisabled }
	);

const transparentColorCssClasses = (isDisabled: boolean) =>
	clsx(
		'bg-transparent rounded-xl text-sm md:text-base font-poppins text-center px-4 py-2 ' +
			' transition dark:bg-primary-300 dark:text-white',
		{ 'hover:bg-primary-200 dark:hover:bg-primary-200': !isDisabled }
	);

export function KntButton({
	isDisabled,
	type,
	className,
	children,
	isSubmitting,
	color,
	iconComponent,
	iconClassNames,
	onClick
}: Props) {
	const chooseClasses = () => {
		switch (color) {
			case 'primary':
				return primaryColorCssClasses(!!isDisabled);
			case 'transparent':
				return transparentColorCssClasses(!!isDisabled);
			default:
				return defaultColorCssClasses(!!isDisabled);
		}
	};

	return (
		<button
			className={clsx(chooseClasses(), className, {
				'relative overflow-hidden': isSubmitting,
				'!hover:bg': isDisabled
			})}
			type={type === 'button' ? 'button' : 'submit'}
			disabled={isDisabled}
			onClick={onClick}
		>
			<div
				className={clsx({ 'cursor-not-allowed': isDisabled, 'flex items-center': iconComponent })}
			>
				{iconComponent ? (
					<div className={clsx('mx-2.5', iconClassNames)}>{iconComponent}</div>
				) : null}
				{children}
				{isSubmitting ? <Loader tiled withOverlay /> : null}
			</div>
		</button>
	);
}

KntButton.defaultProps = {
	isDisabled: false,
	type: 'button',
	className: '',
	isSubmitting: false,
	color: 'default',
	iconComponent: null,
	iconClassNames: '',
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onClick: () => {}
};
