import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import { useScheduleDatePick } from '../hooks/useScheduleDatePick';
import ScheduleDatePicker from './ScheduleDatePicker';
import SchedulePresetDatePicker from './SchedulePresetDatePicker';
import ScheduleDateTimeFooter from './ScheduleDateTimeFooter';

type Props = {
	onScheduleModalCloseClicked: () => void;
};

export function ScheduleDateTime({ onScheduleModalCloseClicked }: Props) {
	const {
		openCustomDate,
		scheduleError,
		handleDateChange,
		handleTimeChange,
		handleSaveScheduleDate,
		toggleOpenCustomDate,
		handleSelectPresetDateTime,
		schedulingDate,
		selectedPresetDate,
		schedulingTime
	} = useScheduleDatePick({ onScheduleModalCloseClicked });

	return (
		<MuiPickersUtilsProvider utils={DayjsUtils}>
			<div className="schedule-date__wrapper">
				{openCustomDate ? (
					<ScheduleDatePicker
						handleDateChange={handleDateChange}
						handleTimeChange={handleTimeChange}
						scheduleError={scheduleError}
						schedulingDate={schedulingDate}
						schedulingTime={schedulingTime}
					/>
				) : (
					<SchedulePresetDatePicker
						toggleOpenCustomDate={toggleOpenCustomDate}
						handleSelectPresetDateTime={handleSelectPresetDateTime}
						selectedPresetDate={selectedPresetDate}
						scheduleError={scheduleError}
					/>
				)}

				<ScheduleDateTimeFooter
					onScheduleModalCloseClicked={onScheduleModalCloseClicked}
					scheduleError={scheduleError}
					handleSaveScheduleDate={handleSaveScheduleDate}
				/>
			</div>
		</MuiPickersUtilsProvider>
	);
}
