import { takeEvery, takeLatest, call, put, all } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import cloneDeep from 'lodash/cloneDeep';
import { api, queryBuilder } from 'shared/services';
import EntitySchema from './schema';

import {
	EntityContTypes,
	LoadAllActionRequest,
	LoadOneActionRequest,
	FormActionRequest,
	loadAllSuccess,
	loadAllFailure,
	loadOneSuccess,
	loadOneFailure,
	formSuccess,
	formFailure
} from './actions';

import { loadEntitiesSuccess } from 'store/actions/entities';
import axios, { AxiosError } from 'axios';

const cancelLogs: { [index: string]: any } = {};

export function* LoadAll(action: LoadAllActionRequest) {
	const {
		entity,
		name,
		url,
		params,
		dataKey,
		metaKey,
		appendData,
		prependData,
		relations,
		primaryKey,
		infiniteScroll,
		isUniq,
		reverse,
		cb,
		replaceIds,
		isCancellable,
		cancelField,
		cursorBased,
		direction
	} = action.payload;

	try {
		let CancelToken;
		const calculatedCancelField = params?.extra?.[cancelField || ''];
		if (isCancellable) {
			if (calculatedCancelField in cancelLogs) {
				cancelLogs[calculatedCancelField].cancelAxFunc('Search has been cancelled');
			}

			cancelLogs[calculatedCancelField] = {
				cancelAxFunc: undefined,
				CancelToken: axios.CancelToken
			};
			CancelToken = cancelLogs[calculatedCancelField].CancelToken;
		}
		// due to cancelling feature we should choose one of call functions
		const { data } = isCancellable
			? yield call(
					api.request.get,
					queryBuilder(url, { ...params, extra: { ...params.extra, direction } }),
					{
						cancelToken: new CancelToken((c: any) => {
							cancelLogs[calculatedCancelField].cancelAxFunc = c;
						})
					}
			  )
			: yield call(
					api.request.get,
					queryBuilder(url, { ...params, extra: { ...params.extra, direction } })
			  );
		//only for chat messages to prevent bug when there is no custom_uuid
		let modifiedData;
		if (cursorBased) {
			modifiedData = {
				...data,
				results: data.results.map((item: any, idx: number) => {
					return item.custom_uuid ? item : { ...item, custom_uuid: (Date.now() + idx).toString() };
				})
			};
		}
		const normalized = normalize(
			typeof dataKey === 'function' ? dataKey(data) : (cursorBased ? modifiedData : data)[dataKey],
			[EntitySchema(entity, primaryKey, relations)]
		);

		const clonedData = cloneDeep(data);
		if (typeof dataKey === 'string') delete clonedData[dataKey];
		if (typeof dataKey === 'function') {
			clonedData.count = clonedData?.length;
		}

		const { page, limit } = params;
		let last_page;
		if (limit) {
			if (clonedData.count % limit === 0) {
				last_page = clonedData.count / limit;
			} else {
				last_page = Math.floor(clonedData.count / limit) + 1;
			}
		}

		const meta = {
			...clonedData,
			current_page: page,
			last_page,
			response_length: data?.results?.length
		};
		//remove succeed entity key from cancelLogs
		if (isCancellable) {
			delete cancelLogs[calculatedCancelField];
		}

		yield put(loadEntitiesSuccess(normalized.entities));

		yield put(
			loadAllSuccess({
				ids: reverse ? normalized.result.reverse() : normalized.result,
				entity,
				name,
				appendData,
				prependData,
				params,
				meta,
				infiniteScroll,
				isUniq,
				replaceIds,
				cursorBased
			})
		);
		yield call(cb?.success, data);
		//@ts-ignore
	} catch (error: AxiosError) {
		yield put(
			loadAllFailure({
				entity,
				name,
				error
			})
		);
	}
}

export function* LoadOne(action: LoadOneActionRequest): any {
	const { id, entity, name, url, params, primaryKey, relations, cb } = action.payload;

	try {
		const { data } = yield call(api.request.get, queryBuilder(url, params));

		let normalized = normalize(data, EntitySchema(entity, primaryKey, relations));

		if (primaryKey === id) {
			normalized = normalize(
				{ [primaryKey]: id, ...data },
				EntitySchema(entity, primaryKey, relations)
			);
		}
		yield put(loadEntitiesSuccess(normalized.entities));

		yield put(
			loadOneSuccess({
				entity,
				name,
				params,
				id
			})
		);
		//@ts-ignore
	} catch (error: AxiosError) {
		yield put(
			loadOneFailure({
				entity,
				name,
				error
			})
		);
		yield call(cb?.error, error);
	}
}

export function* Form(action: FormActionRequest): any {
	const {
		id,
		entity,
		name,
		url,
		params,
		method,
		primaryKey,
		values,
		relations,
		appendData = false,
		prependData = false,
		updateData = false,
		deleteData = false,
		normalizeData,
		cb
	} = action.payload;

	let normalized: any = {};

	try {
		const { data } = yield call(api.request[method], queryBuilder(url, params), values);

		if (normalizeData) {
			normalized = normalize(
				typeof normalizeData === 'function' ? normalizeData(data) : data[normalizeData],
				EntitySchema(entity, primaryKey, relations)
			);

			yield put(loadEntitiesSuccess(normalized.entities));

			yield put(
				formSuccess({
					id: normalized.result,
					entity,
					name,
					appendData,
					prependData,
					updateData,
					deleteData
				})
			);
		} else {
			if (typeof id !== 'undefined') {
				yield put(
					formSuccess({
						id,
						entity,
						name,
						appendData,
						prependData,
						updateData,
						deleteData
					})
				);
			}
		}

		yield call(cb.success, data, values);
		//@ts-ignore
	} catch (error: AxiosError) {
		if (normalizeData) {
			yield put(
				formFailure({
					id: normalized.result,
					entity,
					name,
					error
				})
			);
		} else {
			yield put(
				formFailure({
					entity,
					name,
					error
				})
			);
		}

		yield call(cb.error, error);
	} finally {
		yield call(cb.finally);
	}
}

export default function* root(): any {
	yield all([
		takeEvery(EntityContTypes.LOAD_ALL_ENTITY_REQUEST, LoadAll),
		takeEvery(EntityContTypes.LOAD_ONE_ENTITY_REQUEST, LoadOne),
		takeLatest(EntityContTypes.FORM_ENTITY_REQUEST, Form)
	]);
}
