import { MentionUserEntity } from 'shared/contexts/reducers/chatContextReducer';
import { UserType } from 'shared/typings/user.types';

export function replaceUsernameToFullname(
	inputString: string,
	mentionUsers: MentionUserEntity[],
	userData: UserType,
	shouldReturnAsHTML: boolean
) {
	return inputString?.replace(/@(\w+)(?!\.[a-z]{2,})\b/g, (match) => {
		const currentMatchedUser = mentionUsers.find((user) => match.slice(1) === user?.display_name);
		const loggedUserClientForEntity = mentionUsers.find(
			(user) => user.id === userData?.id && !user?.is_staff
		);
		const hideLastName =
			loggedUserClientForEntity && loggedUserClientForEntity?.id !== currentMatchedUser?.id;
		let fullName = match;

		if (currentMatchedUser) {
			fullName = `${currentMatchedUser?.first_name} ${
				!hideLastName ? currentMatchedUser?.last_name : ''
			}`;
		}

		if (!shouldReturnAsHTML) return fullName;

		return `<a class="text-kblue" title="${match}">${fullName}</a>`;
	});
}
