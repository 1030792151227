import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { api, queryBuilder } from 'shared/services';

import { QueryType } from 'shared/interfaces';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { AxiosError } from 'axios';

type AxiosParams = {
	url: string;
	method?: 'post' | 'get' | 'put' | 'patch' | 'delete';
	values?: any;
	params?: QueryType;
	initialLoad?: boolean;
	cb?: {
		success: (data: any, additionalArgs?: any) => void;
		error: (error: any, additionalArgs?: any) => void;
	};
	dynamicUrl?: boolean;
	dynamicValues?: boolean;
};

export const useAxios = ({
	url,
	method = 'get',
	values = {},
	params = {},
	initialLoad = false,
	cb = {
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		success: (data: any, additionalArgs?: any) => {},
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		error: (error: any, additionalArgs?: any) => {}
	},
	dynamicUrl,
	dynamicValues
}: AxiosParams) => {
	const { userData } = useAuth();
	const [response, setResponse] = useState(undefined);
	const [error, setError] = useState<AxiosError | null>(null);
	const [loading, setLoading] = useState(false);

	const fetchData = async (...args: any) => {
		setLoading(true);
		try {
			const parsedDynamicUrlObj = args.find(
				(arg: any) => typeof arg === 'object' && 'url' in arg && arg.url
			);
			const parsedDynamicValues = args.find(
				(arg: any) => typeof arg === 'object' && 'values' in arg && arg.values
			);

			const computedParams = parsedDynamicUrlObj?.params ?? params;

			// @ts-ignore
			const { data } = await api.request[method](
				queryBuilder(dynamicUrl ? parsedDynamicUrlObj?.url : url, computedParams),
				dynamicValues ? parsedDynamicValues.values : values
			);
			setResponse(data);
			cb.success(data, args);
			setLoading(false);
			return;
			// eslint-disable-next-line no-shadow
		} catch (error: any) {
			setError(error);
			setLoading(false);
			cb.error(error, args);
			// eslint-disable-next-line no-empty
		} finally {
		}
	};

	useEffect(() => {
		if (!isEmpty(userData) && initialLoad) fetchData();
	}, [userData]); // eslint-disable-line react-hooks/exhaustive-deps

	return { response, error, loading, fetchData };
};
