import React, { useState } from 'react';
import { CaseDetailsRow } from './CaseDetailsRow';
import { useAppState } from 'shared/state';
import config from 'config';
import { Button, Chip, Skeleton } from 'shared/components/Elements';
import EntityContainer from 'modules/entity/containers';
import { useNotistack, useQueryParams, useWhiteModal } from 'shared/hooks';
import { AddLinkedCaseForm } from './AddLinkedCaseForm';
import { LinkedCase } from 'shared/interfaces';
import get from 'lodash/get';
import { ConfirmModal } from 'shared/components';
import { CaseTypes } from 'shared/state/reducer';
import { useEntityForm } from '../../../../../modules/entity/hooks/useEntityForm';
import { useTranslation } from 'react-i18next';

type Props = {
	caseId: number;
	closeCaseDetailModal: () => void;
	recurringId: number | null;
};

export function CDLinkedCases({ caseId, closeCaseDetailModal, recurringId }: Props) {
	const { isStaff, companyId, setCaseType } = useAppState();
	const { WhiteModal, open, modalHandler } = useWhiteModal();
	const { changeUrlParams } = useQueryParams();
	const { showNotification } = useNotistack();
	const { t } = useTranslation();

	const [deleteModal, setDeleteModal] = useState<LinkedCase | null>(null);
	const [goToCaseModal, setGoToCaseModal] = useState<LinkedCase | null>();
	const [loading, setLoading] = useState(false);

	const closeDeletePopup = () => setDeleteModal(null);
	const closeGoToCaseModal = () => setGoToCaseModal(null);

	//Click handler to open Client case
	const handleOpenCase = (linkedCase: LinkedCase) => {
		//Close Open Linked Case Popup
		closeGoToCaseModal();

		//Close Case Details Popup
		closeCaseDetailModal();

		//Change CaseType to appropriate type
		setCaseType(isStaff ? (config.CLIENT as CaseTypes) : (config.STAFF as CaseTypes));

		//Open Linked Case
		changeUrlParams({ status: linkedCase.status_id, case: linkedCase.entity_id });
	};

	const handleDelete = () => {
		setLoading(true);
		handleCaseUnlink();
	};

	const { handleRequest: handleCaseUnlink } = useEntityForm({
		entity: 'linked-cases',
		name: `AllLinkedCases-${caseId}`,
		method: 'delete',
		url: `/${companyId}/cases/as_business/${caseId}/from_to/${deleteModal?.id}/`,
		deleteData: true,
		id: deleteModal?.id,
		cb: {
			success: (data) => {
				setLoading(false);
				setDeleteModal(null);
				showNotification({ message: 'Case has been unlinked!', variant: 'success' });
			},
			error: (error) => {
				setLoading(false);
			},
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			finally: () => {}
		}
	});

	const handleLinkedCaseClick = () => () => handleOpenCase(goToCaseModal!);

	return (
		<CaseDetailsRow
			fieldName={isStaff ? t('link_existing_client') : t('link_existing_staff')}
			align="top"
		>
			<div className="flex flex-wrap items-center">
				{/*Add Linked Case Button*/}
				<Button
					color="primary"
					variant="contained"
					onClick={modalHandler}
					className="!mr-4 !mb-2 !text-white"
					disabled={!!recurringId}
				>
					{t('add_link')}
				</Button>

				{/*Add Linked Case Form Popup*/}
				<WhiteModal handleModalOpen={modalHandler} open={open} maxWidth={446}>
					<AddLinkedCaseForm caseId={caseId} modalHandler={modalHandler} />
				</WhiteModal>

				{/*List of Linked cases*/}
				<EntityContainer.All
					entity="linked-cases"
					name={`AllLinkedCases-${caseId}`}
					url={`/${companyId}/cases/as_business/${caseId}/from_to/`}
					params={{
						page: 1,
						limit: 50,
						extra: {
							from_case: caseId
						}
					}}
				>
					{({ items, isFetched }) => {
						return (
							<>
								<div className="-my-2">
									{(items as LinkedCase[]).map((item, idx) => {
										return (
											<Chip
												key={item.id}
												label={`#${get(item, 'case_number')}`}
												onDelete={() => {
													setDeleteModal(item);
												}}
												variant="outlined"
												color="primary"
												onClick={() => {
													setGoToCaseModal(item);
												}}
												className="!my-2 !mr-2"
											/>
										);
									})}
								</div>
								{!isFetched && <Skeleton />}
							</>
						);
					}}
				</EntityContainer.All>

				{/*Confirm Popup for unlinking case*/}
				<ConfirmModal
					featureHandler={handleDelete}
					isOpen={Boolean(deleteModal)}
					toggleHandler={closeDeletePopup}
					title="Are you sure to unlink this case?"
					hasLoader
					isLoading={loading}
				/>

				{/*Confirm Popup for opening linked case*/}
				<ConfirmModal
					featureHandler={handleLinkedCaseClick()}
					isOpen={Boolean(goToCaseModal)}
					toggleHandler={closeGoToCaseModal}
					title="Are you sure to open the linked case?"
				/>
			</div>
		</CaseDetailsRow>
	);
}
