import { useEffect, useState } from 'react';
import { useAuth } from '../../modules/auth/hooks/useAuth';
import { CompSubscriptionType } from '../../modules/user/reducers';
import { useAppState } from '../state';
import { getSubscriptionRemainedTIme } from '../services';

export function useSidebarWidgets() {
	const { companyId } = useAppState();
	const { companies } = useAuth();
	const [subscription, setSubscription] = useState<CompSubscriptionType | null>(null);
	const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0 });

	useEffect(() => {
		if (companyId && companies.length) {
			const currentCompany = companies.find((com) => com.company.id === companyId);
			const subscription = currentCompany?.company.comp_subscription;

			if (subscription) {
				const remainingDayString = getSubscriptionRemainedTIme(subscription?.end_date);

				setTimeLeft({
					days: +remainingDayString[0],
					hours: +remainingDayString[1],
					minutes: +remainingDayString[2]
				});
				setSubscription(subscription);
			}
		}
	}, [companyId, companies]);
	return {
		timeLeft,
		subscription
	};
}
