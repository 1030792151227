import clsx from 'clsx';
import React from 'react';

type Props = {
	priority: 'HIGH' | 'CRITICAL' | 'LOW' | undefined;
};
export function CasePriorityLabel({ priority }: Props) {
	return (
		<div
			className={clsx('priority h-2 w-16 rounded-md', {
				'bg-priority-high': priority === 'HIGH',
				'bg-priority-low': priority === 'LOW',
				'bg-priority-critical': priority === 'CRITICAL'
			})}
		/>
	);
}
