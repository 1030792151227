import React from 'react';
import { Radio } from '../../Elements';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';
import clsx from 'clsx';
import { useTranslationHelpers } from 'shared/hooks/useTranslationHelpers';
import { Trans } from 'react-i18next';
import { convertToLocalizationKey } from 'shared/services/utils/convertToLocalizationKey';
type Props = {
	title: string;
	selected: boolean;
	handleTemplateClick: () => void;
};

const useStyles = makeStyles(
	createStyles({
		radioRoot: {
			padding: isMobile ? '0px' : '6px'
		}
	})
);

export function TemplateFilterItem({ title, selected, handleTemplateClick }: Props) {
	const classes = useStyles();
	const { isRTL } = useTranslationHelpers();

	return (
		<div
			className={clsx(
				'filter-item mb-3 flex items-center rounded-10 border  border-transparent transition hover:bg-primary-200',
				'dark:border-white/20 dark:bg-kdark dark:text-white dark:hover:bg-primary-200',
				{
					'bg-primary-lighter dark:bg-primary-200': selected,
					'bg-kgrey-bg': !selected,
					'pr-3.5': isRTL
				}
			)}
			onClick={handleTemplateClick}
		>
			<Radio color="primary" checked={selected} classes={{ root: classes.radioRoot }} />
			<div className="">
				<Trans i18nKey={convertToLocalizationKey(title)} defaults={title} />
			</div>
		</div>
	);
}
