import React from 'react';
import { IMessage } from 'shared/interfaces';
import { useMessageFields } from 'shared/hooks/chat-hooks/useMessageFields';
import isEqual from 'lodash/isEqual';
import clsx from 'clsx';
import { useAppState } from 'shared/state';
import { toUpperCase } from 'shared/services/utils/toUpperCase';
import { SocketEventTypes } from 'shared/typings/socketEvent.types';
import { useTranslation } from 'react-i18next';
import { useTranslationHelpers } from 'shared/hooks/useTranslationHelpers';

type Props = {
	message: IMessage;
};

type ServiceMsgTemplateType = {
	clickHandler?: () => void;
	clickable?: boolean;
};

const ServiceMsgTemplate: React.FC<ServiceMsgTemplateType> = ({
	children,
	clickHandler = () => {},
	clickable = false
}) => {
	const { languageDirection } = useTranslationHelpers();
	return (
		<div
			dir={languageDirection}
			className={clsx('flex-centered relative mb-2 leading-none text-kgrey-msg/70 lg:h-4', {
				'cursor-pointer transition hover:text-primary': clickable
			})}
			onClick={clickHandler}
		>
			<div className="line t-2 z-0 hidden h-px w-full border-b border-kgrey-msg/20 lg:block" />
			<div className="msg-service-text relative z-10 mx-2 text-center text-xs lg:flex-shrink-0">
				{children}
			</div>
			<div className="line t-2 z-0 hidden h-px w-full border-b border-kgrey-msg/20 lg:block" />
		</div>
	);
};

function areEqual(prev: any, next: any) {
	return (
		prev.message.text === next.message.text &&
		prev.message?.type === next.message?.type &&
		prev.message?.message_type === next.message?.message_type &&
		isEqual(prev.message.from_user, next.message.from_user) &&
		isEqual(prev.message.to_user, next.message.to_user)
	);
}

export const ServiceMsg = React.memo(function ServiceMsg({ message }: Props) {
	const { t } = useTranslation();
	const { text, type, from_user, to_user, message_type, sent_time, entity_type } =
		useMessageFields(message);
	const { setStatusPriorityMessagePopup } = useAppState();

	//Because when we receive message via socket type comes with message_type field.
	const actualType = message_type ? message_type : type;
	const isCase = entity_type === 'case';

	const setSPMessagePopupHandler = () => {
		setStatusPriorityMessagePopup({
			from_user,
			actualType,
			sent_time,
			text,
			to_user,
			type: isCase ? 'case' : 'group'
		});
	};

	const renderTextMessage = (type: string) => {
		switch (toUpperCase(type)) {
			case SocketEventTypes.STATUS_CHANGED: {
				return (
					<ServiceMsgTemplate clickable clickHandler={setSPMessagePopupHandler}>
						{text}
					</ServiceMsgTemplate>
				);
			}
			case SocketEventTypes.STATUS_NAME_CHANGED: {
				return <ServiceMsgTemplate>{t('status_name_changed_to', { text })}</ServiceMsgTemplate>;
			}
			case SocketEventTypes.PRIORITY_CHANGED: {
				return (
					<ServiceMsgTemplate clickable clickHandler={setSPMessagePopupHandler}>
						{text}
					</ServiceMsgTemplate>
				);
			}
			case SocketEventTypes.REQUEST_MANAGER: {
				return <ServiceMsgTemplate clickable={false}>{text}</ServiceMsgTemplate>;
			}
			case SocketEventTypes.ADD_MEMBER:
			case SocketEventTypes.MEMBER_ADDED: {
				return (
					<ServiceMsgTemplate clickable clickHandler={setSPMessagePopupHandler}>
						{t('user_added_user', {
							from_user: `${from_user?.first_name} ${from_user?.last_name}`,
							to_user: `${to_user?.first_name} ${to_user?.last_name}`,
							entity: isCase ? 'case' : 'group chat'
						})}
					</ServiceMsgTemplate>
				);
			}
			case SocketEventTypes.REMOVE_MEMBER:
			case SocketEventTypes.MEMBER_DELETED: {
				return (
					<ServiceMsgTemplate clickable clickHandler={setSPMessagePopupHandler}>
						{t('user_deleted_user', {
							from_user: `${from_user?.first_name} ${from_user?.last_name}`,
							to_user: `${to_user?.first_name} ${to_user?.last_name}`,
							entity: isCase ? 'case' : 'group chat'
						})}
					</ServiceMsgTemplate>
				);
			}
			case SocketEventTypes.LEFT_MEMBER:
			case SocketEventTypes.MEMBER_LEFT: {
				return (
					<ServiceMsgTemplate>
						{t('user_left_entity', {
							from_user: `${from_user?.first_name} ${from_user?.last_name}`,
							entity: isCase ? 'case' : 'group chat'
						})}
					</ServiceMsgTemplate>
				);
			}
			case 'ERROR': {
				return <ServiceMsgTemplate>💥Error occurred. This message is not valid</ServiceMsgTemplate>;
			}
			default:
				return (
					<ServiceMsgTemplate clickable clickHandler={setSPMessagePopupHandler}>
						{text}
					</ServiceMsgTemplate>
				);
		}
	};

	return <>{renderTextMessage(actualType)}</>;
}, areEqual);
