/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import ContentLoader from 'react-content-loader';
import { Avatar, WhiteModal } from '../../Elements';
import { IconCasePin } from 'shared/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useMemo } from 'react';
import useDimensions from 'react-use-dimensions';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { useCaseDetailsToggle } from 'pages/Cases/hooks';
import { CaseDetails } from 'pages/Cases/components/case-details';
import { useChatContext } from 'shared/contexts';
import { ScheduledChatHeader } from './ScheduledChatHeader';
import { useTranslation } from 'react-i18next';
import { useTranslationHelpers } from 'shared/hooks/useTranslationHelpers';
import { replaceUsernameToFullname } from '../utils';
import { useAuth } from 'modules/auth/hooks/useAuth';

const ChatHeaderInfoLoader = () => (
	<ContentLoader
		speed={2}
		width={200}
		height={40}
		viewBox="0 0 200 40"
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
	>
		<circle cx="43" cy="19" r="19" />
		<rect x="71" y="2" rx="5" ry="5" width="116" height="17" />
		<rect x="72" y="26" rx="5" ry="5" width="108" height="8" />
	</ContentLoader>
);

const PinTextLoader = () => (
	<ContentLoader
		speed={2}
		width={300}
		height={12}
		viewBox="0 0 300 12"
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
	>
		<rect x="1" y="-1" rx="3" ry="3" width="373" height="20" />
	</ContentLoader>
);

type Props = {
	data: {
		avatarSrc: string | undefined;
		avatarLetter: string;
		avatarAlt: string;
	} | null;
	HeaderActions: React.ReactNode;
	mainText: string;
	secondaryText?: string;
	pinnedMessage: { title: string; id: number };
	isFetched: boolean;
	scrollToHandler: (id: number | null) => void;
	toggleCaseDetails?: () => void;
	casePriority?: string;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '36px',
			height: '36px'
		}
	})
);

export function ChatHeaderLayout({
	data,
	HeaderActions,
	mainText,
	secondaryText,
	isFetched,
	pinnedMessage,
	scrollToHandler,
	toggleCaseDetails = () => {},
	casePriority = 'border-none'
}: Props) {
	const classes = useStyles();
	const { t } = useTranslation();
	const { isRTL } = useTranslationHelpers();

	const { closeCaseDetailModal, caseDetailsOpen } = useCaseDetailsToggle();
	const { isScheduledChat, mentionUsersList } = useChatContext();
	const { userData } = useAuth();

	const isTherePinnedMessage = useMemo(() => {
		return Object.values(pinnedMessage).filter((value) => value);
	}, [pinnedMessage]);

	const [chatPanelRef, { width: chatPanelWidth }] = useDimensions();
	const computedPinnedMsgTitle = replaceUsernameToFullname(
		pinnedMessage?.title,
		mentionUsersList,
		userData,
		false
	);

	if (isScheduledChat) {
		return <ScheduledChatHeader />;
	}

	return (
		<div
			ref={chatPanelRef}
			className={clsx(
				'chat__header relative flex flex-shrink-0 items-center justify-between border-b border-black/10 p-2  dark:bg-kdark lg:py-5',
				{
					'pr-0 lg:pl-5': !isRTL,
					'pl-0 lg:pr-5': isRTL
				}
			)}
		>
			<div className="chat-header__content grow-1 flex items-center justify-between xl:grow-0">
				<div
					onClick={toggleCaseDetails}
					className="chat-header__identity chat-header__identity-avatar flex cursor-pointer items-center"
				>
					{isFetched && data ? (
						<>
							<div className="h-9 w-9">
								<Avatar
									src={data.avatarSrc}
									letter={data.avatarLetter}
									alt={data.avatarAlt}
									className={'bg-primary-200'}
									classes={{ root: classes.root }}
								/>
							</div>
							<div
								className="chat-header__identity-text max-w-full px-2 dark:text-white"
								style={{ width: `${chatPanelWidth / 2}px` }}
							>
								<div
									className={clsx(' truncate text-xs font-semibold lg:max-w-full lg:text-base', {
										'max-w-160': isMobile
									})}
								>
									{mainText}
								</div>
								{secondaryText ? <div className="text-xs text-kgrey">{secondaryText}</div> : null}
							</div>
						</>
					) : (
						<ChatHeaderInfoLoader />
					)}
				</div>
			</div>
			<div
				className={clsx('chat__header-actions flex-center-y flex-shrink-0', {
					'ml-2 lg:mr-8': isRTL,
					'mr-2 lg:ml-8': !isRTL
				})}
			>
				{HeaderActions}
			</div>
			{isTherePinnedMessage.length ? (
				<div
					className="pinned-case absolute left-0 right-0 top-full z-10 flex cursor-pointer items-center justify-between border-t
				border-b border-kdark-black/10 bg-[#f2f2f2] pt-1.5 pb-1 pl-2 pr-5 dark:bg-kgrey/20"
					onClick={() => scrollToHandler(pinnedMessage?.id)}
				>
					<div
						className={clsx('case-pin-content overflow-hidden px-3 leading-none', casePriority, {
							'border-l-2': !isRTL,
							'border-r-2': isRTL
						})}
					>
						<div
							className="pin-title mb-1.5 text-primary-dark"
							css={css`
								font-size: 10px;
							`}
						>
							{t('pinned_title')}
						</div>
						<div className="pinned-text truncate text-xs leading-normal dark:text-white">
							{isFetched && data ? <>{computedPinnedMsgTitle}</> : <PinTextLoader />}
						</div>
					</div>
					{/*<div className="case-pin-icon ml-2 flex-shrink-0">
						<IconCasePin />
					</div>*/}
				</div>
			) : null}

			<WhiteModal
				handleModalOpen={closeCaseDetailModal}
				open={Boolean(caseDetailsOpen)}
				minWidth={362}
				maxWidth={550}
				scrollable
			>
				<CaseDetails caseId={caseDetailsOpen} closeCaseDetailModal={closeCaseDetailModal} />
			</WhiteModal>
		</div>
	);
}
