import { IAction } from 'shared/interfaces';

type lang = string;

export enum SystemActionTypes {
	CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
	RESET = 'RESET'
}

export type SystemAction = IAction<SystemActionTypes.CHANGE_LANGUAGE, lang>;
export type ResetAction = IAction<SystemActionTypes.RESET, ''>;

export function ChangeLanguage(lang: lang): SystemAction {
	return {
		type: SystemActionTypes.CHANGE_LANGUAGE,
		payload: lang
	};
}

export function Reset(): any {
	return {
		type: SystemActionTypes.RESET
	};
}

export default {
	ChangeLanguage,
	Reset
};
