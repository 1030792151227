import { FC } from 'react';
import clsx from 'clsx';
import { FieldProps } from 'formik';

type Props = {
	classNames: string;
	inputElementClassNames?: string;
	label: string;
	required: boolean;
	type: string;
	disabled?: boolean;
	onChangeHandler: (
		e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
	) => void;
	labelClasses?: string;
	onBlur?: (
		event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
	) => void;
	characterCount?: number;
	characterLimit?: number;
};
// eslint-disable-next-line react/function-component-definition
export const KntInputField: FC<Props & FieldProps> = ({
	classNames,
	label,
	field: { name, value, onChange, ...restField },
	form: { touched, errors },
	type = 'text',
	required,
	onChangeHandler,
	onBlur,
	disabled,
	inputElementClassNames = '',
	labelClasses = '',
	characterLimit,
	characterCount,
	...restProps
}) => {
	const inputClassNames = clsx(
		'border  border-transparent rounded-lg text-xs px-6 py-3.5' +
			' outline-none transition focus:border-primary focus:shadow-lg ' +
			'w-full placeholder-current placeholder-kgrey dark:bg-kdark hover:border-primary',
		inputElementClassNames,
		errors[name] && touched[name] && 'border-red-500',
		{
			'pointer-events-none bg-kgrey-disabled-bg dark:border-kgrey-inputBd': disabled,
			'bg-kgrey-light-grey-1': !disabled
		}
	);
	return (
		<div className={clsx('mb-4', classNames)}>
			{label ? (
				<label
					className={clsx(
						{ 'ml-2 mb-2 block text-sm font-light text-kgrey-menuText': !labelClasses },
						labelClasses
					)}
					htmlFor={name}
				>
					<span>{label}</span>
					{required ? <sup>*</sup> : null}
				</label>
			) : null}
			<div className="relative">
				{type === 'textarea' ? (
					<textarea
						id={name}
						name={name}
						value={value}
						className={inputClassNames}
						disabled={disabled}
						onChange={(e) => {
							onChange(e);
							if (typeof onChangeHandler === 'function') onChangeHandler(e);
						}}
						{...restProps}
						{...restField}
						onBlur={onBlur}
					/>
				) : (
					<input
						id={name}
						type={type}
						name={name}
						value={value}
						className={inputClassNames}
						disabled={disabled}
						onChange={(e) => {
							if (characterLimit && e.target.value.length > characterLimit) return;
							onChange(e);
							if (typeof onChangeHandler === 'function') onChangeHandler(e);
						}}
						{...restProps}
						{...restField}
						onBlur={onBlur}
					/>
				)}
			</div>
			<div className="flex justify-between">
				{errors[name] && touched[name] ? (
					<span className="text-xs text-red-500">{errors[name]}</span>
				) : null}
				{characterCount && characterLimit ? (
					<span className="mt-1 text-xs text-kgrey-menuText">
						{characterCount}/{characterLimit}
					</span>
				) : null}
			</div>
		</div>
	);
};

KntInputField.defaultProps = {
	disabled: false
};
