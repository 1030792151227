import { Reducer } from 'redux';
import { AxiosError } from 'axios';
import { UserAction, UserActions } from './actions';

export type ImgFile = {
	id: number;
	url: string;
	thumbnail_150: string;
	thumbnail: string;
};

export type SubscriptionOrder = {
	company: number;
	id: number;
	is_extra: boolean;
	is_monthly: boolean;
	is_paid: boolean;
	staffs_count: number;
};

export type CompSubscriptionType = {
	end_date: string;
	id: number;
	is_available: boolean;
	is_cancelled: boolean;
	order: SubscriptionOrder | null;
	paid_staffs_count: null | number;
	start_date: string;
	subscription_plan: number;
	subscription_type: 'TRIAL' | 'PRO';
	total_amount: string;
};

export type BusinessCompany = {
	id: number;
	name: string;
	is_individual: boolean;
	is_client: boolean;
	is_business: true;
	color: string;
	address: string | null;
	status: 'ACTIVE' | 'INACTIVE';
	users_count: number;
	logo: ImgFile;
	logo_dark: ImgFile;
	icon: ImgFile;
	icon_dark: ImgFile;
	industry: {
		id: number;
		name: string;
	};
	sub_industry: {
		id: number;
		name: string;
	};
	owner: {
		avatar: ImgFile;
		color: string;
		first_name: string;
		id: number;
		last_name: string;
		mobile: string;
		username: string;
	};
	overdue_cases_count: number;
	unread_cases_count: number;
	country: any;
	state: any;
	country_name: any;
	state_name: any;
	city: any;
	address_2: string;
	created_date: string;
	zipcode: any;
	about: any;
	phone: any;
	website: any;
	facebook_url: string;
	twitter_url: string;
	google_plus_url: string;
	due_date: any;
	lat: string;
	lng: string;
	qrcode: string;
	not_visible_for_new_clients: boolean;
	imported_from_file: any;
	default_company_for_all: boolean;
	comp_subscription: CompSubscriptionType;
};

export type UserRoles = 'manager' | 'i_admin' | 'admin' | 'member';

export type Company = {
	id: number;
	company: BusinessCompany;
	pin_time: string | null;
	is_selected: boolean;
	role: {
		name: UserRoles;
	};
};

export type UserState = {
	userCompanies: Company[];
	businessCompanies: Company[];
	isFetchedUser: boolean;
	isFetchedBusiness: boolean;
	error: AxiosError | null;
	errorBusiness: AxiosError | null;
	company: Company | null | undefined;
	fcm: string | null;
	selectedBusiness: Company | null;
};

const initialState = {
	userCompanies: [],
	isFetchedUser: true,
	isFetchedBusiness: true,
	error: null,
	errorBusiness: null,
	company: undefined,
	businessCompanies: [],
	fcm: null,
	selectedBusiness: null
};

// eslint-disable-next-line default-param-last
const userReducer: Reducer<UserState, UserActions> = (state = initialState, action) => {
	switch (action.type) {
		case UserAction.LOAD_USER_COMPANIES_REQUEST: {
			return {
				...state,
				isFetchedUser: false
			};
		}
		case UserAction.LOAD_USER_COMPANIES_SUCCESS: {
			return {
				...state,
				isFetchedUser: true,
				userCompanies: action.payload
			};
		}
		case UserAction.LOAD_USER_COMPANIES_FAIL: {
			return {
				...state,
				isFetchedUser: true,
				userCompanies: [],
				error: action.payload
			};
		}

		case UserAction.SET_USER_COMPANY: {
			return {
				...state,
				company: action.payload
			};
		}
		case UserAction.SET_USER_FCM: {
			return {
				...state,
				fcm: action.payload
			};
		}
		case UserAction.SET_SELECTED_BUSINESS: {
			return {
				...state,
				selectedBusiness: action.payload
			};
		}
		default:
			return state;
	}
};

export default userReducer;
