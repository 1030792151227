import { Popover } from 'shared/components/Elements';
import { MdScheduleSend } from 'react-icons/md';
import React from 'react';
import { useChatContext } from 'shared/contexts';
import { useTranslation } from 'react-i18next';

type Props = {
	onMenuItemClick: () => void;
	contextMenu: { mouseX: number | null; mouseY: number | null };
	onMenuClose: () => void;
};
export function SendButtonContextMenu({ onMenuItemClick, contextMenu, onMenuClose }: Props) {
	const { t } = useTranslation();
	const { isScheduledChat } = useChatContext();

	return contextMenu.mouseY !== null && !isScheduledChat ? (
		<Popover
			keepMounted
			open={true}
			onClose={onMenuClose}
			anchorReference="anchorPosition"
			anchorPosition={
				contextMenu.mouseX !== null
					? { top: contextMenu.mouseY - 60, left: contextMenu.mouseX - 100 }
					: undefined
			}
		>
			<div className="scheduling-menu__wrapper p-3 pl-2 ">
				<div
					onClick={onMenuItemClick}
					className="scheduling-menu__item hover:text flex cursor-pointer items-center gap-x-2 hover:text-primary"
				>
					<span className="menu-item__icon dark:text-white">
						<MdScheduleSend size={20} />
					</span>
					<div className="menu-item__text">{t('schedule_send')}</div>
				</div>
			</div>
		</Popover>
	) : null;
}
