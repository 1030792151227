import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { IMessage, ScrollToMsg } from 'shared/interfaces';
import { useMessageFields } from 'shared/hooks/chat-hooks/useMessageFields';
import clsx from 'clsx';
import { RepliedMediaMsgWrapper } from './RepliedMediaMsgWrapper';
import { LoaderWithProgress } from 'shared/components/Loader';
import { useChatContext, useChatInputContext, useChatSocket } from 'shared/contexts';
import isNumber from 'lodash/isNumber';
import { formSuccess } from 'modules/entity/actions';
import { useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { useQueryParams, useWhiteModal } from 'shared/hooks';
import { EmojiCountInfo } from './EmojiCountInfo';
import { useCachedReactedUsers } from 'shared/hooks/chat-hooks/useCachedReactedUsers';
import { DeletedMsg } from './DeletedMsg';
import { storage } from 'shared/services';
import { useTranslation } from 'react-i18next';

type Props = {
	message: IMessage;
	setScrollToMsgId: ScrollToMsg;
};

function areEqual(prev: any, next: any) {
	return (
		prev.message?.is_deleted === next.message?.is_deleted &&
		prev.message.file?.url === next.message.file?.url &&
		isEqual(prev.message?.replied_to, next.message?.replied_to) &&
		prev.message.messageId === next.message.messageId &&
		prev.message.custom_uuid === next.message.custom_uuid &&
		prev.message.edited === next.message.edited &&
		isEqual(prev.message.reacted_users, next.message.reacted_users) &&
		prev.message.played_time === next.message.played_time
	);
}

export const AudioMsg = memo(function AudioMsg({ message, setScrollToMsgId }: Props) {
	const {
		is_deleted,
		file,
		replied_to,
		messageId,
		custom_uuid,
		played_time,
		isOwnMsg,
		reacted_users,
		file_type
	} = useMessageFields(message);
	const { entityId, entityType, entityName, onGoingAudioPlayer } = useChatContext();
	const { sendJsonMessage } = useChatSocket();
	const { changeUrlParams, removeOneQueryParam, query } = useQueryParams();
	const {
		audioUploadProgress,
		editingMessage,
		setAudioCancelSending,
		setEditingMessage,
		setAudioUploadProgress
	} = useChatInputContext();
	const dispatch = useDispatch();
	const audioRef = useRef<any>();
	const audioEditedRef = useRef<any>(file?.url);
	const activeMedia = query.activeMedia;
	const {
		open: isEmojiCountOpen,
		modalHandler: isEmojiCountOpenHandler,
		WhiteModal: EmojiWhiteModal
	} = useWhiteModal();

	const { cachedReactions } = useCachedReactedUsers({
		reacted_users,
		file_type,
		isEmojiCountOpenHandler
	});

	useEffect(() => {
		if (!activeMedia) {
			audioRef?.current?.pause();
		}
	}, [activeMedia]);

	/*Case: 3644 - Playing audio even after AudioMsg unmounts because of message list Virtualizing
    use-case #1: if AudioMsg mounts in messages list, it should be played in audio tag itself with proper continuation.
    use-case #2: if AudioMsg unmounts in message list, it should be played in virtual Audio player declared in ChatContext
     with proper continuation of the audio.
  * */
	useEffect(() => {
		if (Number(activeMedia) === file?.id && audioRef.current) {
			onGoingAudioPlayer.src = file?.url as string;
			const audioContinueTime = JSON.parse(storage.get('audioContinueTime') as string);

			if (
				audioRef.current?.duration !== audioContinueTime?.continueTime &&
				file?.id === audioContinueTime?.fileId
			) {
				audioRef.current.currentTime = audioContinueTime?.continueTime;
			}

			onGoingAudioPlayer.pause();
			audioRef.current.play();
		}

		return () => {
			if (Number(activeMedia) === file?.id && !audioRef.current) {
				const audioContinueTime = JSON.parse(storage.get('audioContinueTime') as string);

				onGoingAudioPlayer.currentTime = audioContinueTime?.continueTime;

				onGoingAudioPlayer.play();
				onGoingAudioPlayer.ontimeupdate = onTimeUpdateHandler;
			}
		};
	}, [file, activeMedia]);

	const audioCancellerHandler = useCallback(() => {
		setAudioCancelSending(true);
		if (editingMessage) {
			setEditingMessage(null);
			setAudioUploadProgress({});
			setTimeout(() => audioRef.current && audioRef.current.load(), 100);
			return;
		}
		dispatch(
			formSuccess({
				entity: `${entityType}Messages`,
				name: entityName,
				id: custom_uuid,
				deleteData: true,
				appendData: false,
				updateData: false,
				prependData: false
			})
		);
	}, [entityName, entityType, custom_uuid, editingMessage]);
	//Load audio player again
	useEffect(() => {
		if (audioEditedRef.current !== file?.url) {
			audioRef.current && audioRef.current.load();
			audioEditedRef.current = file?.url;
		}
	}, [file]);

	function onAudioPlay() {
		if (query?.isMediaActive) {
			removeOneQueryParam('isMediaActive');
		}

		setTimeout(() => {
			changeUrlParams({ activeMedia: file?.id });
		}, 0);

		if (!played_time && !isOwnMsg) {
			sendJsonMessage({
				message_id: messageId,
				entity_id: entityId,
				entity_type: entityType,
				type: 'played'
			});
		}
	}

	function onAudioPause() {
		removeOneQueryParam('activeMedia');
	}

	//Storing current playing audio time to local storage to get exact playback time after AudioMsg unmounts
	const onTimeUpdateHandler = () => {
		const audioTime = {
			fileId: Number(activeMedia),
			continueTime: 0
		};

		if (audioRef?.current?.currentTime) {
			audioTime.continueTime = audioRef?.current?.currentTime;
		} else {
			audioTime.continueTime = onGoingAudioPlayer.currentTime;
		}

		storage.set('audioContinueTime', JSON.stringify(audioTime));
	};

	const currentProgress = custom_uuid in audioUploadProgress && audioUploadProgress[custom_uuid];

	if (is_deleted) {
		return <DeletedMsg isOwnMsg={isOwnMsg} type="audio" />;
	}

	return (
		<div
			className={clsx('text-kdark', {
				'rounded-b-xl rounded-tr-xl bg-white/10 dark:bg-kdark-lighter': !isOwnMsg,
				'rounded-t-xl rounded-bl-xl bg-primary-200': isOwnMsg && replied_to,
				'overflow-hidden': !replied_to
			})}
		>
			<RepliedMediaMsgWrapper replied_to={replied_to} setScrollToMsgId={setScrollToMsgId}>
				<div className={clsx('audio-wrapper relative')}>
					{!messageId || currentProgress ? (
						<AudioUploadCanceller
							audioUploadProgress={currentProgress || 100}
							cancelHandler={audioCancellerHandler}
							messageId={messageId}
						/>
					) : null}

					<audio
						className="audio-msg"
						controls
						onTimeUpdate={onTimeUpdateHandler}
						ref={audioRef}
						onPlay={onAudioPlay}
						onPause={onAudioPause}
					>
						<>
							{/*@ts-ignore*/}
							<source src={file && file.url} type="audio/ogg" />
							{/*@ts-ignore*/}
							<source src={file && file.url} type="audio/mpeg" />
						</>
						Your browser does not support the audio element.
					</audio>
				</div>
			</RepliedMediaMsgWrapper>
			<div className="px-2">{reacted_users?.length ? cachedReactions : null}</div>
			<EmojiWhiteModal handleModalOpen={isEmojiCountOpenHandler} open={isEmojiCountOpen}>
				<EmojiCountInfo messageId={messageId} reactedUsers={reacted_users} />
			</EmojiWhiteModal>
		</div>
	);
}, areEqual);

AudioMsg.displayName = 'AudioMsg';

type Cancel = {
	audioUploadProgress: number | undefined | boolean;
	messageId: number | undefined;
	cancelHandler: () => void;
};

function AudioUploadCanceller({ audioUploadProgress, cancelHandler }: Cancel) {
	const { t } = useTranslation();
	return (
		<div className={'absolute inset-0 z-10 flex items-center rounded-4xl border bg-kgrey-bgmenu'}>
			<div className="relative ml-1 h-12 w-12 flex-shrink-0">
				<div
					className={' absolute z-10 h-12 w-12 cursor-pointer rounded-full bg-white/70'}
					onClick={() => {}}
				>
					{audioUploadProgress ? (
						<div className={'absolute top-1 left-1'}>
							<LoaderWithProgress value={+audioUploadProgress} variant={'indeterminate'} />
						</div>
					) : null}
				</div>
			</div>
			<button
				className={clsx('ml-2 text-sm text-kgrey underline hover:no-underline', {
					underline: Number(audioUploadProgress) !== 100
				})}
				onClick={cancelHandler}
			>
				{isNumber(audioUploadProgress) && Number(audioUploadProgress) === 100
					? t('processing')
					: t('cancel')}
			</button>
		</div>
	);
}
