import React from 'react';

export function DropdownIndicator() {
	return (
		<svg width="9" height="8" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M6.232 7c-.77 1.333-2.694 1.333-3.464 0L.603 3.25c-.77-1.333.192-3 1.732-3h4.33c1.54 0 2.502 1.667 1.732 3L6.232 7Z"
				fill="#C3EDE9"
			/>
		</svg>
	);
}
