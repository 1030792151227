import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import get from 'lodash/get';

import EntitySchema from './schema';
import { IRootState, PayloadProps as ILoadAllProps, ILoadOneProps } from 'shared/interfaces';

const getEntities = (state: IRootState): object => state.entities;
const getEntity = (state: IRootState): object => state.entity;

const getAll = (): any =>
	createSelector(
		getEntities,
		(state: IRootState, props: ILoadAllProps) => props.entity,
		(state: IRootState, props: ILoadAllProps) =>
			get(state.entity, `${props.entity}.${props.name}`, []),
		(state: IRootState, props: ILoadAllProps) => props.primaryKey,
		(state: IRootState, props: ILoadAllProps) => props.relations,
		(entities, entityName, data, primaryKey, relations) => {
			let ids, isFetched, meta;
			if ('ids' in data) ids = data.ids;
			if ('isFetched' in data) isFetched = data.isFetched;
			if ('meta' in data) meta = data.meta;

			const normalized = denormalize(
				{ [entityName]: ids },
				{
					[entityName]: [EntitySchema(entityName, primaryKey, relations)]
				},
				{ ...entities, [entityName]: get(entities, entityName, {}) }
			);

			return {
				items: get(normalized, entityName, []),
				isFetched,
				meta
			};
		}
	);

const getOne = (): any =>
	createSelector(
		getEntities,
		(state: IRootState, props: ILoadOneProps) => props.id,
		(state: IRootState, props: ILoadOneProps) => props.entity,
		(state: IRootState, props: ILoadOneProps) =>
			get(state.entity, `${props.entity}.${props.name}One`, {}),
		(state: IRootState, props: ILoadOneProps) => props.primaryKey,
		(state: IRootState, props: ILoadOneProps) => props.relations,
		(entities, id, entityName, data, primaryKey, relations) => {
			let isFetched;
			if ('isFetched' in data) {
				isFetched = data.isFetched;
			}
			const normalized = denormalize(
				{ [entityName]: id },
				{
					[entityName]: EntitySchema(entityName, primaryKey, relations)
				},
				{ ...entities, [entityName]: get(entities, entityName, {}) }
			);
			return {
				isFetched,
				item: get(normalized, entityName, {})
			};
		}
	);

const getStatusIds = createSelector(
	getEntity,
	(state: IRootState, companyId: number | undefined) => companyId,
	(entity: { [key: string]: any }, companyId: number | undefined) => {
		const { statuses } = entity;
		return get(statuses, `AllStatuses-${companyId}.ids`);
	}
);

const getEntityObject = () => createSelector(getEntity, (entity) => entity);

export default {
	getAll,
	getOne,
	getStatusIds,
	getEntityObject
};
