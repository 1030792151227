import { CgMenuLeft } from 'react-icons/cg';
import { HiOutlineChevronLeft } from 'react-icons/hi';
import { useAppState } from 'shared/state';
import { IconWrapper } from '../../IconWrapper';

export function SidebarToggleBtn() {
	const { navCollapsed, toggleSidebar, collapseSidebar } = useAppState();
	return (
		<>
			<div
				className={
					'group flex h-full items-center justify-center border-r border-kgrey-border-solid pl-2 pr-2 dark:border-white/20 md:pl-5 md:pr-5'
				}
			>
				<div
					className="hidden h-full cursor-pointer items-center justify-center  font-poppins sm:flex"
					onClick={collapseSidebar}
				>
					<IconWrapper>
						{navCollapsed ? (
							<span className={'dark:text-white'}>
								<CgMenuLeft size={'12px'} />
							</span>
						) : (
							<span className={'dark:text-white'}>
								<HiOutlineChevronLeft size={'14px'} />
							</span>
						)}
					</IconWrapper>
					{/*<div className="text-base text-kdark-text pl-2 hidden sm:block leading-none group-hover:text-primary transition-colors dark:text-white">{`${*/}
					{/*	navCollapsed ? 'Menu' : 'Close Menu'*/}
					{/*}`}</div>*/}
				</div>
				<IconWrapper handler={toggleSidebar} classes={'sm:hidden dark:text-white'}>
					<CgMenuLeft size={'12px'} />
				</IconWrapper>
			</div>
		</>
	);
}
