import { IFileMsgType } from '../../interfaces';

export type Command = null | 'start' | 'stop' | 'none';
export type EditMsgType = { msgType: 'txtMsg' | 'fileMsg' | null; fileType: null | string };
export type AudioUploadProgressT = { [key: string]: number };

export type EditingMessageType = {
	id: number;
	text: string;
	fileType: IFileMsgType;
	custom_uuid: string;
	replied_to_id: number | null;
	scheduled_to?: number;
} | null;

export type CoordsType = {
	top: number;
	left: number;
} | null;

export type EditFileResponse = null | {
	// | {}
	height: any;
	id: number;
	key: string;
	name: string;
	param: any;
	thumbnail: string;
	thumbnail_150: any;
	url: string;
	width: any;
};

export type TempMessageEvent = any;

export type ReplyType = {
	from_user_first_name: string;
	from_user_last_name: string;
	text: string;
	file_type: IFileMsgType;
	id: number | undefined;
} | null;

export type ChatInputState = {
	message: string;
	reply: ReplyType;
	editingMessage: EditingMessageType;
	uploadMeta: {
		[key: string]: string;
	};
	cancelSending: boolean | string;
	command: Command;
	time: number;
	timerId: any;
	cancel: boolean;
	editProgress: number | null | undefined;
	editCancelUpload: boolean | string;
	tempMessageEvent: TempMessageEvent;
	clipboardFile: any;
	cancelAudioUpload: boolean;
	audioUploadProgress: AudioUploadProgressT;
	coords: CoordsType;
	replyEditCancel: boolean;
	isDelayedMessage: boolean;
	isMentionSearching: boolean;
	messageScheduleDate: number | null;
	openScheduleModal: boolean;
};

export const initialInputState = {
	message: '',
	reply: null,
	editingMessage: null,
	uploadMeta: {},
	cancelSending: false,
	command: null,
	time: 0,
	timerId: 0,
	cancel: false,
	tempFile: null,
	editProgress: null,
	editCancelUpload: false,
	editFileResponse: null,
	tempMessageEvent: null,
	editMsgType: { msgType: null, fileType: null },
	clipboardFile: false,
	cancelAudioUpload: false,
	audioUploadProgress: {},
	coords: null,
	replyEditCancel: false,
	isDelayedMessage: false,
	isMentionSearching: false,
	messageScheduleDate: null,
	openScheduleModal: false
};

export type SetMessage = {
	type: 'SET_MESSAGE';
	payload: string;
};

export type SetCancelSending = {
	type: 'SET_CANCEL_SENDING';
	payload: boolean | string;
};

export type SetReply = {
	type: 'SET_REPLY';
	payload: ReplyType;
};

export type SetEditingMessage = {
	type: 'SET_EDITING_MESSAGE';
	payload: EditingMessageType;
};

export type SetUploadMeta = {
	type: 'SET_UPLOAD_META';
	payload: { [key: string]: string } /*| {}*/;
};

export type SetCommand = {
	type: 'SET_COMMAND';
	payload: Command;
};

export type SetTime = {
	type: 'SET_TIME';
	payload: number;
};
export type SetTimerId = {
	type: 'SET_TIMER_ID';
	payload: number;
};

export type SetCancel = {
	type: 'SET_CANCEL';
	payload: boolean;
};

export type SetEditProgress = {
	type: 'SET_EDIT_PROGRESS';
	payload: number | null | undefined;
};
export type SetEditCancelUpload = {
	type: 'SET_EDIT_CANCEL_UPLOAD';
	payload: boolean | string;
};

export type SetTempMessageEvent = {
	type: 'SET_TEMP_MESSAGE_EVENT';
	payload: TempMessageEvent;
};

export type SetClipboardFile = {
	type: 'SET_CLIPBOARD_FILE';
	payload: any;
};

export type SetAudioCancelSending = {
	type: 'SET_AUDIO_CANCEL_SENDING';
	payload: boolean;
};

export type SetAudioUploadProgress = {
	type: 'SET_AUDIO_UPLOAD_PROGRESS';
	payload: AudioUploadProgressT;
};
export type SetCoords = {
	type: 'SET_COORDS';
	payload: CoordsType;
};

export type SetReplyEditCancel = {
	type: 'SET_REPLY_EDIT_CANCEL';
	payload: boolean;
};

export type SetIsDelayedMessage = {
	type: 'SET_IS_DELAYED_MESSAGE';
	payload: boolean;
};

export type SetIsMentionSearching = {
	type: 'SET_IS_MENTION_SEARCHING';
	payload: boolean;
};

export type SetMessageScheduleDate = {
	type: 'SET_MESSAGE_SCHEDULE_DATE';
	payload: number | null;
};

type Actions =
	| SetMessage
	| SetReply
	| SetEditingMessage
	| SetUploadMeta
	| SetCancelSending
	| SetCommand
	| SetTime
	| SetTimerId
	| SetCancel
	| SetEditProgress
	| SetEditCancelUpload
	| SetTempMessageEvent
	| SetClipboardFile
	| SetAudioCancelSending
	| SetAudioUploadProgress
	| SetCoords
	| SetReplyEditCancel
	| SetIsDelayedMessage
	| SetIsMentionSearching
	| SetMessageScheduleDate;

export const chatInputReducer = (state: ChatInputState, action: Actions): ChatInputState => {
	switch (action.type) {
		case 'SET_MESSAGE':
			return {
				...state,
				message: action.payload
			};
		case 'SET_CANCEL_SENDING': {
			return {
				...state,
				cancelSending: action.payload
			};
		}
		case 'SET_REPLY': {
			return {
				...state,
				reply: action.payload
			};
		}
		case 'SET_EDITING_MESSAGE': {
			return {
				...state,
				editingMessage: action.payload
			};
		}
		case 'SET_UPLOAD_META': {
			return {
				...state,
				uploadMeta: action.payload
			};
		}
		case 'SET_COMMAND': {
			return {
				...state,
				command: action.payload
			};
		}
		case 'SET_TIME': {
			return {
				...state,
				time: action.payload
			};
		}
		case 'SET_TIMER_ID': {
			return {
				...state,
				timerId: action.payload
			};
		}
		case 'SET_CANCEL': {
			return {
				...state,
				cancel: action.payload
			};
		}
		case 'SET_EDIT_PROGRESS': {
			return {
				...state,
				editProgress: action.payload
			};
		}
		case 'SET_EDIT_CANCEL_UPLOAD': {
			return {
				...state,
				editCancelUpload: action.payload
			};
		}

		case 'SET_TEMP_MESSAGE_EVENT': {
			return {
				...state,
				tempMessageEvent: action.payload
			};
		}

		case 'SET_CLIPBOARD_FILE': {
			return {
				...state,
				clipboardFile: action.payload
			};
		}
		case 'SET_AUDIO_CANCEL_SENDING': {
			return {
				...state,
				cancelAudioUpload: action.payload
			};
		}
		case 'SET_AUDIO_UPLOAD_PROGRESS': {
			return {
				...state,
				audioUploadProgress: action.payload
			};
		}
		case 'SET_COORDS': {
			return {
				...state,
				coords: action.payload
			};
		}
		case 'SET_REPLY_EDIT_CANCEL': {
			return {
				...state,
				replyEditCancel: action.payload
			};
		}
		case 'SET_IS_DELAYED_MESSAGE': {
			return {
				...state,
				isDelayedMessage: action.payload
			};
		}
		case 'SET_IS_MENTION_SEARCHING': {
			return {
				...state,
				isMentionSearching: action.payload
			};
		}
		case 'SET_MESSAGE_SCHEDULE_DATE': {
			return {
				...state,
				messageScheduleDate: action.payload
			};
		}
		default:
			return state;
	}
};
