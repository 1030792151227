import { Company } from './reducers';
import { AxiosError } from 'axios';
export type UserActions =
	| UserCompaniesRequest
	| UserCompaniesSuccess
	| UserCompaniesFail
	| UserCompanyType
	| UserFCMType
	| SetSelectedBusinessActionType;

export type UserCompaniesRequest = {
	type: typeof UserAction.LOAD_USER_COMPANIES_REQUEST;
};
export type UserCompaniesSuccess = {
	type: typeof UserAction.LOAD_USER_COMPANIES_SUCCESS;
	payload: Company[];
};

export type UserCompaniesFail = {
	type: typeof UserAction.LOAD_USER_COMPANIES_FAIL;
	payload: AxiosError;
};
export type UserCompanyType = {
	type: typeof UserAction.SET_USER_COMPANY;
	payload: Company | null;
};

export type UserFCMType = {
	type: typeof UserAction.SET_USER_FCM;
	payload: string;
};

export type SetSelectedBusinessActionType = {
	type: typeof UserAction.SET_SELECTED_BUSINESS;
	payload: Company | null;
};

export class UserAction {
	static readonly LOAD_USER_COMPANIES_REQUEST = 'LOAD_USER_COMPANIES/REQUEST';
	static readonly LOAD_USER_COMPANIES_SUCCESS = 'LOAD_USER_COMPANIES/SUCCESS';
	static readonly LOAD_USER_COMPANIES_FAIL = 'LOAD_USER_COMPANIES/FAIL';

	static readonly SET_USER_COMPANY = 'SET_USER_COMPANY';
	static readonly SET_USER_FCM = 'SET_USER_FCM';
	static readonly SET_SELECTED_BUSINESS = 'SET_SELECTED_BUSINESS';

	loadUserCompaniesRequest = (): UserCompaniesRequest => {
		return {
			type: UserAction.LOAD_USER_COMPANIES_REQUEST
		};
	};

	loadUserCompaniesSuccess = (companies: Company[]): UserCompaniesSuccess => {
		return {
			type: UserAction.LOAD_USER_COMPANIES_SUCCESS,
			payload: companies
		};
	};
	loadUserCompaniesFail = (error: AxiosError): UserCompaniesFail => {
		return {
			type: UserAction.LOAD_USER_COMPANIES_FAIL,
			payload: error
		};
	};

	setUserCompany = (company: Company): UserCompanyType => {
		return {
			type: UserAction.SET_USER_COMPANY,
			payload: company
		};
	};

	setUserFCM = (fcm: string): UserFCMType => {
		return {
			type: UserAction.SET_USER_FCM,
			payload: fcm
		};
	};

	setSelectedBusiness = (business: Company | null): SetSelectedBusinessActionType => {
		return {
			type: UserAction.SET_SELECTED_BUSINESS,
			payload: business
		};
	};
}

const {
	loadUserCompaniesRequest,
	loadUserCompaniesSuccess,
	loadUserCompaniesFail,
	setUserCompany,
	setUserFCM,
	setSelectedBusiness
} = new UserAction();

export default {
	loadUserCompaniesRequest,
	loadUserCompaniesSuccess,
	loadUserCompaniesFail,
	setUserCompany,
	setUserFCM,
	setSelectedBusiness
};
