export const caseFilterStyle = {
	//@ts-ignore
	control: (provided, state) => ({
		...provided,
		cursor: 'pointer',
		boxShadow: 'none',
		border: '1px solid #d8d7d7',
		borderRadius: '10px',
		backgroundColor: '#f8fafc',

		':hover': {
			border: '1px solid #00D0BD'
		}
	}),
	//@ts-ignore
	container: (provided) => ({
		...provided
	}),
	//@ts-ignore
	valueContainer: (provided) => ({
		...provided,
		padding: '2px 8px'
	}),
	dropdownIndicator: () => ({
		paddingRight: '5px',
		color: '#99979f'
	}),
	//@ts-ignore
	singleValue: (provided) => ({
		color: '#424653',
		fontWeight: '300',
		fontSize: '15px'
	}),
	//@ts-ignore
	indicatorSeparator: (provided) => ({
		...provided,
		display: 'none'
	}),
	//@ts-ignore
	placeholder: (provided) => ({
		...provided,
		color: '#A5A7B2',
		fontWeight: '500',
		fontSize: '16px'
	})
};

export const KntTwilioStyle = {
	//@ts-ignore
	control: (provided, state) => {
		// console.log('state', state);
		return {
			...provided,
			cursor: 'pointer',
			border: state.isFocused ? '1px solid transparent' : '1px solid #e0e0e0',
			boxShadow: state.isFocused ? '0px 0px 0 2px #00D0BD' : 'none',
			borderRadius: '8px',
			':hover': {
				border: '1px solid #00D0BD'
			}
		};
	},
	//@ts-ignore
	valueContainer: (provided) => ({
		...provided,
		padding: '6px 8px'
	}),
	dropdownIndicator: () => ({
		paddingRight: '5px',
		// paddingTop: '5px',
		fill: '#99979f',
		color: '#99979f'
	}),
	//@ts-ignore
	singleValue: (provided) => ({
		color: '#424653',
		fontWeight: '300',
		fontSize: '15px',
		wordBreak: 'break-all'
	}),
	//@ts-ignore
	indicatorSeparator: (provided) => ({
		...provided,
		display: 'none'
	}),
	//@ts-ignore
	placeholder: (provided) => ({
		...provided,
		color: '#8692A6',
		fontWeight: '300',
		fontSize: '12px'
	}),
	//@ts-ignore
	option: (provided, { data, isDisabled, isFocused }) => {
		return {
			...provided,
			backgroundColor: isFocused ? 'rgba(0, 208, 189, 0.2)!important' : 'white',
			color: isDisabled ? '#ccc' : '#424653'
		};
	}
};
