import React from 'react';
import { EmojiType } from 'shared/interfaces';
import { EmojiList } from './EmojiList';

type Props = {
	reactedUsers: EmojiType[];
	clickHandler: (isVisible: any) => void;
	fileType?: string | null;
};

export function MessageReactions({ reactedUsers, fileType = '', clickHandler }: Props) {
	return (
		<div className={'-mb-2 max-w-full'} onClick={clickHandler}>
			<EmojiList fileType={fileType} reactedUsers={reactedUsers} />
		</div>
	);
}
