import React from 'react';
import { SwitchBusinessSearch } from '../../../pages/BusinessManagement/components/SwitchBusinessSearch';
import { KntButton } from './KntButton';

type Props = {
	title: string;
	meta?: React.ReactNode | null;
	query?: string;
	handleSearch?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
	placeholder?: string;
	btnHandler?: () => void;
	btnText?: string;
	isPermitted?: boolean;
};

export function ListHeader({
	title,
	meta = null,
	handleSearch = () => {},
	query = '',
	placeholder = '',
	btnHandler = () => {},
	btnText = '',
	isPermitted = false
}: Props) {
	// console.log('query', query);
	return (
		<div className="flex-center-between rounded-t-10 bg-kgrey-bg px-5 pt-3 pb-3 font-medium dark:bg-kdark-lighter">
			<h3 className="dark:text-white">{title}</h3>
			{meta ? (
				meta
			) : (
				<div className="meta flex-center-x">
					<div>
						<SwitchBusinessSearch
							handleSearch={handleSearch}
							query={query}
							placeholder={placeholder}
						/>
					</div>
					{btnText ? (
						<KntButton
							isDisabled={!isPermitted}
							className="mx-4 !bg-white hover:text-primary disabled:opacity-60 dark:!bg-primary-300"
							onClick={btnHandler}
						>
							{btnText}
						</KntButton>
					) : null}
				</div>
			)}
		</div>
	);
}
