import React, { PropsWithChildren, FC } from 'react';
import clsx from 'clsx';
import { Skeleton } from 'shared/components/Elements';
import { ErrorBoundary } from 'shared/components';

type Props = {
	fieldName: string;
	align?: 'top' | 'center';
	classNames?: string;
};

export const CaseDetailsRowInner: FC<PropsWithChildren<Props>> = ({
	fieldName,
	children,
	align = 'center',
	classNames = ''
}) => {
	return (
		<div
			className={clsx(
				'case-details__row mb-2 flex',
				{ 'items-center': align === 'center' },
				classNames
			)}
		>
			<div className="mr-4  w-[100px] flex-shrink-0 text-xs font-semibold lg:w-[150px] lg:text-base">
				{fieldName}
			</div>
			<div
				className={clsx('flex-grow  text-xs lg:text-base', {
					'max-h-72 overflow-y-auto whitespace-pre-wrap break-words': typeof children === 'string'
				})}
			>
				{!children ? <Skeleton /> : children}
			</div>
		</div>
	);
};

export function CaseDetailsRow(props: PropsWithChildren<Props>) {
	return (
		<ErrorBoundary isCompact>
			<CaseDetailsRowInner {...props}>{props.children}</CaseDetailsRowInner>
		</ErrorBoundary>
	);
}
