import React from 'react';
import { BsApple } from 'react-icons/bs';
import { IconArrowDownLong } from '../../icons/IconArrowDownLong';
import { FaGooglePlay } from 'react-icons/fa';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import config from '../../../config';
import { Trans } from 'react-i18next';
import { useTranslationHelpers } from 'shared/hooks/useTranslationHelpers';
import clsx from 'clsx';
const imgPath = `${config.PUBLIC_ASSETS}img/`;

export function MobilePromptWidget() {
	const { isRTL } = useTranslationHelpers();
	return (
		<div className="px-4">
			<div className="mb-3.5 flex items-center text-sm text-kgrey">
				<div>
					<Trans
						i18nKey="scan_qr_code"
						components={{ PrimaryText: <span className="block font-medium text-primary-dark" /> }}
					/>
				</div>
				<div className="mt-4 ml-4">
					<IconArrowDownLong />
				</div>
			</div>
			<div className="flex items-center ">
				<div className={clsx({ 'ml-8': isRTL, 'mr-8': !isRTL })}>
					<a
						href={'https://apps.apple.com/us/app/kennekt-business-messenger/id1570637878'}
						target={'blank'}
						className="flex h-9 items-center rounded bg-primary-dark p-2 no-underline duration-100 ease-linear hover:scale-105"
					>
						<BsApple size={'14'} color={'white'} />
						<div className="ml-2">
							<div className="text-7px font-light leading-3 text-white">Download on the</div>
							<div className="text-10px font-bold leading-3 text-white">App Store</div>
						</div>
					</a>
					<a
						href={'https://play.google.com/store/apps/details?id=com.kennekt.businessmobile\n'}
						target={'blank'}
						className="mt-2 box-border flex h-9 items-center rounded border-sm border-kgrey p-2 no-underline duration-100 ease-linear hover:scale-105"
					>
						<FaGooglePlay size={'14'} color={'#00A09D'} />
						<div className="ml-2">
							<div className="text-7px font-light leading-3 text-kgrey">Get it on</div>
							<div className="text-10px font-bold leading-3  text-kdark dark:text-white">
								Google Play
							</div>
						</div>
					</a>
				</div>
				<div className="hidden h-20 w-20 bg-kgrey lg:inline-block">
					<LazyLoadImage src={`${imgPath}/qr-mobile-apps.png`} effect={'blur'} />
				</div>
			</div>
		</div>
	);
}
