import React, { useEffect, useMemo, useState } from 'react';
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { IconWrapper } from 'shared/components';
import { UnreadMessageType, useAppState } from 'shared/state';
import { isMobile } from 'react-device-detect';
import { BiChevronRight } from 'react-icons/bi';
import { useAuth } from '../../../../modules/auth/hooks/useAuth';
import { useAxios } from 'shared/hooks';
import { useTranslation } from 'react-i18next';
import { useTranslationHelpers } from 'shared/hooks/useTranslationHelpers';

type Props = {
	icon: React.ReactNode;
	text: string;
	noArrow?: boolean;
	isRounded?: boolean;
	classNames?: string;
	scrollToLeftMostUnread?: () => void;
} & NavLinkProps;

export function NavItem({
	icon,
	text,
	noArrow = true,
	isRounded = true,
	classNames = '',
	scrollToLeftMostUnread = () => {},
	...rest
}: Props) {
	const {
		toggleChatPanel,
		toggleSidebar,
		groupsUnreadCountData,
		unreadMessagesCount,
		companyId,
		areUnreadsFetched,
		caseType
	} = useAppState();
	const { isRTL } = useTranslationHelpers();
	const { companies, company } = useAuth();
	const { t } = useTranslation();

	const location = useLocation();
	const [otherBusinessesHasUnread, setOtherBusinessesHasUnread] = useState(false);
	const isActive =
		rest.to === '/news/news-list'
			? location.pathname.includes('news')
			: location.pathname === rest.to;
	const isCasesPage = location.pathname.includes('case-messages');
	const isSwitchBusinessItem = rest.to === '/switch-business';
	const unreadCasesAmount = useMemo(
		() => Object.values(unreadMessagesCount || {}).reduce((acc, curr) => acc + curr, 0),
		[unreadMessagesCount]
	);
	const hasAllUnreadsFetched = useMemo(
		() =>
			areUnreadsFetched['group'] && areUnreadsFetched[caseType === 'Staff' ? 'staff' : 'client'],
		[caseType, areUnreadsFetched.group, areUnreadsFetched.staff, areUnreadsFetched.client]
	);
	const { fetchData, loading, response } = useAxios({
		url: `/${companyId}/no_unread_left_for_company/`
	});

	useEffect(() => {
		//If there is any unread cases in not selected business companies
		const hasUnreadCount = companies.some((company) => {
			return !company.is_selected && company.company.unread_cases_count > 0;
		});

		if (isSwitchBusinessItem) setOtherBusinessesHasUnread(hasUnreadCount);
	}, [companies, company]);

	useEffect(() => {
		if (
			isSwitchBusinessItem &&
			hasAllUnreadsFetched &&
			!Object.keys(groupsUnreadCountData).length &&
			!Object.keys(unreadMessagesCount ?? {}).length &&
			!loading
		) {
			fetchData();
		}
	}, [groupsUnreadCountData, unreadMessagesCount]);

	const scrollToLeftMostUnreadHandler = () => {
		if (!isCasesPage) {
			return;
		}
		scrollToLeftMostUnread();
	};

	const handleMobileClick = () => {
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		return isMobile ? toggleSidebar() : scrollToLeftMostUnreadHandler();
	};

	const unreadGroupMessagesCountSum = calculateUnreadSum(groupsUnreadCountData);
	const isCasesPageAndThereIsUnreadCount = unreadCasesAmount > 0 && text === 'cases';
	const isGroupChatPageAndThereIsUnreadCount = unreadGroupMessagesCountSum > 0 && text === 'chats';
	const doOtherBusinessesHaveUnread = otherBusinessesHasUnread && isSwitchBusinessItem;
	const hasToIndicateUnreadState =
		isCasesPageAndThereIsUnreadCount ||
		isGroupChatPageAndThereIsUnreadCount ||
		doOtherBusinessesHaveUnread;


	return (
		<NavLink {...rest} onClick={() => toggleChatPanel(false)}>
			<div
				className={clsx(
					'group flex items-center justify-between px-3.5 py-2 transition-colors',
					isActive && 'bg-primary-200 ',
					{ 'rounded-xl': isRounded },
					classNames
				)}
				onClick={handleMobileClick}
			>
				<div className="icon-text-wr flex w-11/12 items-center">
					<IconWrapper isActive={isActive}>
						<div
							className={clsx(
								'fit-icon-size h-3.5 w-3.5 transition-colors group-hover:text-kdark-text dark:group-hover:text-white',
								isActive && 'dark:text-white',
								!isActive && 'text-kgrey',
								{
									'text-primary': hasToIndicateUnreadState
								}
							)}
						>
							{icon}
						</div>
					</IconWrapper>
					<div
						className={clsx(
							'flex-grow truncate font-poppins  text-sm font-semibold transition-colors group-hover:text-kdark-text dark:group-hover:text-white ',
							// isActive && 'dark:text-white text-kdark-text',
							// !isActive && 'text-kgrey-menuText',
							{
								'text-primary': hasToIndicateUnreadState,
								'text-kdark-text dark:text-white': isActive && !hasToIndicateUnreadState,
								'text-kgrey-menuText': !isActive && !hasToIndicateUnreadState,
								'pl-2': !isRTL,
								'pr-2': isRTL
							}
						)}
					>
						{t(text)}
					</div>
				</div>
				{noArrow && (
					<div className="menu-item__arrow flex-shrink-0  group-hover:text-primary">
						<BiChevronRight />
					</div>
				)}
			</div>
		</NavLink>
	);
}

function calculateUnreadSum(unreadObject: null | UnreadMessageType) {
	let unreadMessagesSum = 0;

	if (unreadObject !== null) {
		unreadMessagesSum = Object.values(unreadObject).reduce((acc: number, curr) => acc + curr, 0);
	}

	return unreadMessagesSum;
}
