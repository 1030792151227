import { withStyles } from '@material-ui/core/styles';
import { TableCell } from '../TableCell';

export const StyledTableHeadCell = withStyles(() => ({
	head: {
		backgroundColor: '#fafafa',
		paddingBottom: '16px',
		'&:first-child': {
			borderRadius: '0 0 0 10px'
		},
		'&:last-child': {
			borderRadius: '0 0  10px 0'
		},
		border: 'none 0',
		fontSize: '14px',
		color: '#727891'
	}
}))(TableCell);
