import { FocusEvent, useCallback, useEffect, useState } from 'react';
import { useAutoComplete, useAxios, useNotistack } from 'shared/hooks';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { ImgFile } from 'modules/user/reducers';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';
import { useAppState } from 'shared/state';
import { useGoogleMapsInit } from './useGoogleMapsInit';
import { BpImageUploaderType } from '../components/BPImageUploader';
import { useDispatch } from 'react-redux';
import userActions from 'modules/user/actions';
import { storage } from 'shared/services';
import { useAsStaff } from '../../../../BusinessManagement/hooks/useAsStaff';
import { useTranslation } from 'react-i18next';

type BusinessImgType = ImgFile | null;
export type FilesType = {
	icon: BusinessImgType;
	logo: BusinessImgType;
	icon_dark: BusinessImgType;
	logo_dark: BusinessImgType;
};

export function validateUrl(url: string) {
	const regex =
		/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
	return regex.test(url);
}

export function useBusinessProfile(isBusinessProfilePage = false) {
	const { company } = useAuth();
	const { loadAllBusiness } = useAsStaff();
	const { companyId } = useAppState();
	const { showNotification } = useNotistack();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { fetchData: deleteBusinessAvatarRequest, loading: isDeleteBusinessAvatarRequestLoading } =
		useAxios({
			url: `/${companyId}/my/company/`,
			method: 'patch',
			dynamicValues: true,
			cb: {
				success(data, args) {
					const [arg] = args;
					const field = arg?.field as BpImageUploaderType['field'];
					const isLogoField = field?.includes('logo');

					if (data && company) {
						const companyPayload = {
							...company,
							company: { ...data, owner: company?.company?.owner || {} }
						};

						storage.set('company', JSON.stringify(companyPayload));
						dispatch(userActions.setUserCompany(companyPayload));
						loadAllBusiness();

						showNotification({
							message: `The business ${isLogoField ? 'logo' : 'icon'} deleted successfully!`
						});
					}
				},
				error() {
					showNotification({
						message: t('something_went_wrong'),
						variant: 'error'
					});
				}
			}
		});

	const [uploadMeta, setUploadMeta] = useState<{
		progress: number | null;
		field: 'icon' | 'logo' | 'icon_dark' | 'logo_dark' | null;
	}>({
		progress: null,
		field: null
	});
	const [cancelSending, setCancelSending] = useState(false);
	const [socialErrors, setSocialErrors] = useState({
		twitter_url: false,
		facebook_url: false,
		website: false
	});
	const [files, setFiles] = useState<FilesType>({} as FilesType);
	const [selected, setSelected] = useState<{
		lat: string | null | number;
		lng: string | null | number;
	} | null>(null);

	const { isLoaded } = useGoogleMapsInit();
	const { searchInputRef, placeAddress } = useAutoComplete(isLoaded);
	// const calcedLat = !isEmpty(placeAddress) ? placeAddress.lat : company?.company.lat;
	// const calcedLng = !isEmpty(placeAddress) ? placeAddress.lng : company?.company.lng;

	const calcedLat = !isEmpty(placeAddress)
		? placeAddress.lat
		: company?.company.lat
		? company?.company.lat
		: -3.745;
	const calcedLng = !isEmpty(placeAddress)
		? placeAddress.lng
		: company?.company.lng
		? company?.company.lng
		: -38.523;

	const [map, setMap] = useState(null);
	const onLoad = useCallback(
		(mapArg) => {
			const bounds = new window.google.maps.LatLngBounds();
			mapArg.fitBounds(bounds);
			setMap(mapArg);
		},
		[isLoaded]
	);

	const deleteBusinessProfileAvatar = useCallback(
		(field: BpImageUploaderType['field']) => {
			deleteBusinessAvatarRequest({
				values: {
					//we are including country and state bcs they are required fields.
					country: company?.company?.country?.name ?? company?.company?.country,
					state: company?.company?.state?.name ?? company?.company?.state,
					[field]: null
				},
				field
			});
		},
		[company, files]
	);

	const onUnmount = useCallback((mapArg) => {
		setMap(null);
	}, []);

	const onMarkerDrag = useCallback((event) => {
		setSelected({ lat: event.latLng.lat(), lng: event.latLng.lng() });
	}, []);

	useEffect(() => {
		if (company?.company) {
			if ('icon' in company?.company) {
				setFiles((prev) => ({ ...prev, icon: company.company.icon as BusinessImgType }));
			}
			if ('icon_dark' in company?.company) {
				setFiles((prev) => ({ ...prev, icon_dark: company.company.icon_dark as BusinessImgType }));
			}
			if ('logo' in company?.company) {
				setFiles((prev) => ({ ...prev, logo: company.company.logo as BusinessImgType }));
			}
			if ('logo_dark' in company?.company) {
				setFiles((prev) => ({ ...prev, logo_dark: company.company.logo_dark as BusinessImgType }));
			}
		}
	}, [company]);

	useEffect(() => {
		if (company && company.company.address && isEmpty(placeAddress) && isBusinessProfilePage) {
			if (searchInputRef.current) {
				(searchInputRef.current as HTMLInputElement).value = company.company.address;
			}
		}
	}, [company]);

	const date = dayjs(company?.company.created_date).format('MMMM Do YYYY, h:mm:ss a');
	const socialUrlHandler = (e: FocusEvent<HTMLInputElement>, fieldName: string) => {
		const value = e.target?.value;
		const isUrlValid = validateUrl(value);

		if (!isUrlValid && value) {
			setSocialErrors((prev) => ({ ...prev, [fieldName]: true }));
			return;
		}
		setSocialErrors((prev) => ({ ...prev, [fieldName]: false }));
	};

	return {
		cancelSending,
		setCancelSending,
		socialErrors,
		setSocialErrors,
		uploadMeta,
		setUploadMeta,
		files,
		setFiles,
		selected,
		setSelected,
		searchInputRef,
		placeAddress,
		calcedLat,
		calcedLng,
		onLoad,
		onUnmount,
		onMarkerDrag,
		date,
		socialUrlHandler,
		companyId,
		isLoaded,
		map,
		deleteBusinessProfileAvatar,
		isDeleteBusinessAvatarRequestLoading
	};
}
