import { IconFilter } from 'shared/icons';
import { IoIosArrowDown } from 'react-icons/io';
import { TemplatesFilterDropdown } from './TemplatesFilterDropdown';
import { useAppState } from 'shared/state';
import { isMobile } from 'react-device-detect';
import clsx from 'clsx';
import { useCaseFilterSelect } from 'pages/Cases/hooks/useCaseFilterSelect';
import { useLongPress, useNotistack } from 'shared/hooks';
import { Trans, useTranslation } from 'react-i18next';
import { convertToLocalizationKey } from 'shared/services/utils/convertToLocalizationKey';

type Props = {
	handleModalOpen: () => void;
};

export function TemplatesFilterBtn({ handleModalOpen }: Props) {
	const { t } = useTranslation();
	const { caseType, selectedCaseFilter } = useAppState();
	const { onFilterTemplateClick } = useCaseFilterSelect();
	const { showNotification } = useNotistack();

	const currentSelectedFilter = selectedCaseFilter?.[caseType];

	const defaultOptions = {
		shouldPreventDefault: false,
		delay: 500
	};

	const onLongPressMobileSearch = () => {
		if (!currentSelectedFilter) {
			return;
		}
		onFilterTemplateClick(null);
		showNotification({ message: 'Filter has been reset', variant: 'info' });
	};

	const longPressEvent = useLongPress<HTMLDivElement>(
		{ onLongPress: onLongPressMobileSearch },
		defaultOptions
	);

	return (
		<div
			className={clsx(
				'filter-templates-button cursor-pointer' +
					' flex items-center  bg-kgrey-bg text-kdark-text' +
					' group transition hover:bg-primary-dark dark:bg-kdark dark:text-white dark:hover:bg-primary-dark ' +
					'relative dark:border dark:border-white/20',
				{
					'ml-5 h-8 w-[230px] rounded-10  px-4': !isMobile,
					' flex-centered ml-2 h-6 w-6 rounded-sm': isMobile
				}
			)}
			role="button"
			{...longPressEvent}
		>
			{/*<IconFilterTemplates className="text-kgrey-dark-2 group-hover:text-white transition" />*/}
			<IconFilter className="text-kgrey-dark-2 transition group-hover:text-white" />
			{!isMobile && (
				<span className="mx-2  w-4/5 truncate transition group-hover:text-white">
					{currentSelectedFilter?.title ? (
						<Trans
							i18nKey={convertToLocalizationKey(currentSelectedFilter?.title as string)}
							defaults={currentSelectedFilter?.title}
						/>
					) : (
						t('all_cases')
					)}
				</span>
			)}
			{!isMobile && (
				<IoIosArrowDown className="ml-auto transition group-hover:rotate-180 group-hover:text-white" />
			)}
			<div
				className={clsx(
					'filter-templates-dropdown  absolute border border-transparent bg-white' +
						' dark:border-white/20 dark:bg-kdark dark:text-white ' +
						' top-full translate-y-6 opacity-0' +
						' rounded-xl drop-shadow-lg  transition',
					{ 'left-2/4 -ml-[100px] w-[200px] p-2': isMobile, 'left-0 w-[334px] p-6': !isMobile }
				)}
			>
				<TemplatesFilterDropdown handleModalOpen={handleModalOpen} />
			</div>
		</div>
	);
}
