import React from 'react';
import { ItemProps } from 'react-virtuoso';

export const VirtuosoCustomItem = React.forwardRef<HTMLDivElement, ItemProps<any>>((props, ref) => {
	return (
		<div className="list-item-wrapper" {...props} ref={ref}>
			{props.children}
		</div>
	);
});

VirtuosoCustomItem.displayName = 'VirtuosoCustomItem';
