import React from 'react';
import { CaseItemMarkup } from '../../../../pages/Cases/components/CaseItemMarkup';
import { CaseType } from '../../../../pages/Cases/components/CaseItem';
import { useAppState } from '../../../state';

type Props = {
	caseData: CaseType;
};

function RecentCaseItem({ caseData }: Props) {
	const { unreadMessagesCount } = useAppState();
	const department = caseData.department,
		sub_department = caseData.sub_department,
		caseTitle = caseData.title,
		last_message_type = caseData.last_message_type,
		last_message_text = caseData.last_message_text,
		last_message_author = caseData.last_message_author,
		case_number = caseData.case_number,
		last_message_time = caseData.last_message_time,
		caseId = caseData.id,
		statusId = caseData?.status?.id,
		priority = caseData.priority,
		draftMessage = caseData.draft_message,
		assigneeIds = caseData.assignee_ids,
		relatedTo = caseData.related_to_id,
		recurringId = caseData.recurring_id,
		hasLinkedCase = caseData.has_linked_case;

	const hasUnread = unreadMessagesCount?.[caseId];

	return (
		<div className="mb-2">
			<CaseItemMarkup
				case_number={case_number}
				caseTitle={caseTitle}
				department={department}
				sub_department={sub_department}
				last_message_author={last_message_author}
				last_message_time={last_message_time}
				last_message_type={last_message_type}
				last_message_text={last_message_text}
				hasUnread={hasUnread}
				caseId={caseId}
				statusId={statusId}
				pin_time={caseData.pin_time}
				priority={priority}
				draftMessage={draftMessage}
				assigneeIds={assigneeIds}
				isDragging={false}
				isRecentCase={true}
				hasLinkedCase={hasLinkedCase}
				relatedTo={relatedTo}
				recurringId={recurringId}
			/>
		</div>
	);
}

export default RecentCaseItem;
