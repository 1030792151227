import { computeLength } from './computeLength';

type FormDataParams = {
	filename: string;
	file: File;
	entityType: 'case' | 'ims';
	entityId: string;
	custom_uuid: string;
	companyId: string;
	fileType: 'audio' | 'image' | 'video' | 'file';
	isEncrypted?: string;
	fileDuration?: string | null;
	repliedToId?: string | null;
};

export async function createMessageFileFormData({
	filename,
	file,
	entityType,
	fileType,
	companyId,
	entityId,
	custom_uuid,
	isEncrypted = 'false',
	fileDuration = null,
	repliedToId = null
}: FormDataParams) {
	const formData = new FormData();
	formData.append(filename, file);
	formData.append('entity_type', entityType);
	formData.append('company_id', companyId);
	formData.append('file_type', fileType);
	formData.append('is_encrypted', isEncrypted);
	formData.append('entity_id', entityId);
	formData.append('custom_uuid', custom_uuid);
	if (repliedToId) {
		formData.append('replied_to_id', repliedToId);
	}

	try {
		const duration = await computeLength(file);

		if (duration) {
			formData.append('file_duration', Math.floor(duration.duration).toString());
		}
	} catch (e) {
		// console.log('error audio', e);
	}

	if (fileDuration) {
		formData.append('file_duration', fileDuration);
	}

	return formData;
}
