import React from 'react';
import { Field } from 'formik';
import AsyncSelectJs from 'shared/components/Fields/AsyncSelectJs';
import { caseFilterStyle } from 'shared/services';
import { isMobile } from 'react-device-detect';
import { useAppState } from 'shared/state';
import config from 'config';
import { useTranslation } from 'react-i18next';
export function StatusFilterItem() {
	const { t } = useTranslation();
	const { companyId, caseType } = useAppState();

	return (
		<div className="dropdown mb-4">
			<div className="mb-2 text-sm leading-none text-kgrey-menuText dark:text-white">
				{t('status')}
			</div>
			<div className="w-full">
				<Field
					component={AsyncSelectJs}
					customStylesProps={caseFilterStyle}
					title="Status"
					optionLabel="title"
					optionValue="id"
					loadOptionsUrl={`/${companyId}/my/company/case_statuses/`}
					loadOptionsParams={(search: string) => ({
						extra: {
							search,
							is_staff: caseType === config.STAFF ? 'true' : ''
						}
					})}
					reverse
					isSearchable={!isMobile}
					name="filter_status"
					loadOptionsKey=""
					placeholder={t('all')}
					maxMenuHeight={220}
					isMulti
					isCached
				/>
			</div>
		</div>
	);
}
