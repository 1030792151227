import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { MdModeEditOutline } from 'react-icons/md';
import clsx from 'clsx';

type Props = {
	clickHandler: (entity?: any) => void;
	isDisabled?: boolean;
	className?: string;
};

export const EditButton = ({ clickHandler, isDisabled = false, className = '' }: Props) => {
	return (
		<div className={clsx({ 'cursor-not-allowed': isDisabled })}>
			<IconButton onClick={clickHandler} disabled={isDisabled} className={`group ${className}`}>
				<MdModeEditOutline size="18px" className="text-kgrey transition group-hover:text-primary" />
			</IconButton>
		</div>
	);
};
