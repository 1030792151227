import React from 'react';
import { filtersMockData } from './filterMockData';
import { useAppState } from '../../../shared/state';
import config from '../../../config';
import { useCaseFilterFunctions } from '../hooks';
import { AddingFilterItem } from './filter-items/AddingFilterItem';
import { convertToLocalizationKey } from 'shared/services/utils/convertToLocalizationKey';
import { useTranslation } from 'react-i18next';

type Props = {
	setFieldValue: any;
};

export function NCAddFilterItems({ setFieldValue }: Props) {
	const { t } = useTranslation();
	const { activeFilterTemplate, caseType } = useAppState();
	const { appendFilterToTemplate } = useCaseFilterFunctions();
	const filteredFilterItems = filtersMockData.filter((filterItem) => {
		return !activeFilterTemplate?.fields_json?.find((field) => field.name === filterItem.name);
	});
	const calculatedItems = activeFilterTemplate ? filteredFilterItems : filtersMockData;
	const filterClientCompanyAndClientTypeList = calculatedItems.filter(
		(item) =>
			!(
				(item.name === 'client_company' && caseType === config.STAFF) ||
				(item.name === 'client_type' && caseType === config.STAFF)
			)
	);

	return (
		<div className="!pr-4">
			{activeFilterTemplate ? (
				<>
					{filterClientCompanyAndClientTypeList.map((filterData) => {
						return (
							<AddingFilterItem
								key={filterData.name}
								title={t(convertToLocalizationKey(filterData.title))}
								onClick={() => {
									appendFilterToTemplate(filterData);

									if (
										filterData.name === 'unread' ||
										filterData.name === 'unresponded' ||
										filterData.name === 'pinned' ||
										filterData.name === 'expired' ||
										filterData.name === 'tagged' ||
										filterData.name === 'scheduled'
									) {
										setFieldValue(filterData.name, true);
									}
								}}
							/>
						);
					})}
				</>
			) : (
				<div className="text-center">{t('please_select_to_add_filter')}</div>
			)}
		</div>
	);
}
