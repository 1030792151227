import React, { useEffect, useState } from 'react';
import {
	convertUTCDateToLocalDate,
	createDayDetails,
	momentDays,
	setDropdownOptions,
	setRecurringOption,
	weekOrders
} from '../../recurring-feature/recurring.helper';
import dayjs from 'dayjs';
import { api, queryBuilder } from 'shared/services';
import { useAppState } from 'shared/state';
import { useNotistack } from 'shared/hooks';
import { CaseDetailsRow } from './CaseDetailsRow';
import clsx from 'clsx';
import { TimePicker } from '@material-ui/pickers';
import { Button, MenuItem, TextField, WhiteModal } from 'shared/components/Elements';
import { RecurringOptionsForm } from '../../recurring-feature/RecurringOptionsForm';
import { useTranslation } from 'react-i18next';
import { convertToLocalizationKey } from 'shared/services/utils/convertToLocalizationKey';
import { useTranslationHelpers } from 'shared/hooks/useTranslationHelpers';

type Props = {
	recurringId: number;
	state: any;
	setState: any;
};

const recuringStatuses = [
	{
		name: 'Active',
		code: 'ACTIVE'
	},
	{
		name: 'On Hold',
		code: 'ON_HOLD'
	},
	{
		name: 'End',
		code: 'ENDED'
	}
];

export function CdRecurringOptions({ recurringId, state, setState }: Props) {
	const [selectedTime, setSelectedTime] = useState(new Date());
	const { t } = useTranslation();
	const { isRTL } = useTranslationHelpers();
	const { companyId } = useAppState();
	const { showNotification } = useNotistack();
	const currentDate = new Date();
	const currentDay = currentDate.getDay();
	const currentMonthDate = currentDate.getDate();
	const currentWeekday = t(convertToLocalizationKey(momentDays[currentDay]));

	const currentDayObj = createDayDetails(
		dayjs(
			new Date(`${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentMonthDate}`)
		)
	);

	const {
		recurringStatus,
		recurringText,
		recurring,
		showCustomRecurring,
		recurOption,
		recurringOptions,
		dropdownPresetOptions,
		updateLoading
	} = state;

	const ON_WEEK_DAY = `${t(weekOrders[currentDayObj.weekOrder - 1])} ${t(
		convertToLocalizationKey(currentDayObj.day)
	)}`;
	const setRecurOption = (value: any) => setState((prev: any) => ({ ...prev, recurring: value }));
	const handleStateChange = (field: string, value: any) =>
		setState((prev: any) => ({ ...prev, [field]: value }));

	//Set recurring field from dropdown predefined options
	useEffect(() => {
		setRecurringOption({
			moment: dayjs,
			selectedTime,
			setRecurOption,
			recurOption,
			currentWeekday,
			currentDayObj,
			ON_WEEK_DAY,
			handler: setState
		});
	}, [recurOption, selectedTime]);

	//Setting up recurring options
	useEffect(() => {
		if (recurring && recurringText) {
			const date = convertUTCDateToLocalDate(new Date(`December 17, 1995 ${recurring?.on_time}`));
			setDropdownOptions({
				handler: setState,
				moment: dayjs,
				currentWeekday,
				ON_WEEK_DAY,
				editMode: true,
				customValue: {
					name: `${recurringText} at ${dayjs(date.toLocaleString()).format('HH:mm a')}`,
					value: 'CUSTOM_VALUE'
				},
				translate: t
			});
		}
	}, [recurringText, recurring, t]);

	//Get Recurring case object
	useEffect(() => {
		if (recurringId) {
			(async () => {
				try {
					const { data } = await api.request.get(
						queryBuilder(`/${companyId}/cases/recurring/${recurringId}/`, {})
					);
					setState((prev: any) => ({
						...prev,
						recurring: data,
						recurringStatus: data.recurring_status,
						recurringText: data.recurring_text
					}));
				} catch (e) {
					console.error(e);
				}
			})();
		}
	}, []);

	const handleInput = (e: any, handler: any) => {
		const {
			target: { name, value }
		} = e;
		handler((prev: any) => ({ ...prev, [name]: value }));
	};

	const handleRecurringStatusChange = async (e: any) => {
		const previousRecurredStatus = state.recurringStatus;
		handleInput(e, setState);
		const status = e.target.value;
		try {
			await api.request.patch(
				queryBuilder(`/${companyId}/cases/recurring/${recurringId}/change_status/`, {}),
				{ status }
			);
			handleStateChange('recurringStatus', status);
			showNotification({
				message: t('recurring_status_changed_success'),
				variant: 'success'
			});
		} catch (e) {
			handleStateChange('recurringStatus', previousRecurredStatus);
			showNotification({
				message: t('something_went_wrong'),
				variant: 'error'
			});
		}
	};

	const updateRecurringModal = async (config: any) => {
		setState((prev: any) => ({ ...prev, updateLoading: true }));
		try {
			await api.request.put(
				queryBuilder(`/${companyId}/cases/recurring/${recurringId}/`, {}),
				config
			);
			setState((prev: any) => ({
				...prev,
				recurring: config,
				recurringText: config.recurring_text,
				showCustomRecurring: false
			}));
			showNotification({
				message: t('update_success'),
				variant: 'success'
			});
		} catch (e) {
			showNotification({
				message: t('something_went_wrong'),
				variant: 'error'
			});
		} finally {
			setState((prev: any) => ({ ...prev, updateLoading: false }));
		}
	};

	const onCloseRecurrDialog = () => {
		handleStateChange('showCustomRecurring', false);
		handleStateChange('recurOption', 'CUSTOM_VALUE');
	};
	const showRecurringOptions = dropdownPresetOptions.includes(recurOption);

	return (
		<>
			<CaseDetailsRow
				fieldName={t('case_recurring_options')}
				classNames={clsx({
					'bg-kgrey-inputBg dark:bg-inherit py-4 rounded-lg': showRecurringOptions
				})}
				align="top"
			>
				<div>
					<div className="flex">
						<div
							className={clsx('mb-4 flex-1 overflow-hidden', {
								'w-[335px]': !showRecurringOptions,
								'pl-2': isRTL,
								'pr-2': !isRTL
							})}
						>
							<TextField
								select
								fullWidth
								value={recurOption}
								name="recurOption"
								onChange={(e) => handleInput(e, setState)}
								size="small"
								variant="outlined"
								className="cc-recurring-timepicker"
							>
								{recurringOptions.map((option: any) => (
									<MenuItem key={option.value} value={option.value}>
										{recurOption === option.value && recurOption !== 'CUSTOM_VALUE'
											? option.text
											: option.name}
									</MenuItem>
								))}
							</TextField>
						</div>
						{showRecurringOptions && (
							<div className="mb-5 w-[30%]">
								<TimePicker
									variant="inline"
									value={selectedTime}
									//@ts-ignore
									onChange={setSelectedTime}
									inputVariant="outlined"
									size="small"
									autoOk
									fullWidth
									className="cc-recurring-timepicker"
								/>
							</div>
						)}
					</div>

					{showRecurringOptions && (
						<>
							<Button
								variant="contained"
								disabled={updateLoading}
								color="primary"
								onClick={() => updateRecurringModal(recurring)}
							>
								{t('update_recurring_options')}
							</Button>
						</>
					)}
				</div>
			</CaseDetailsRow>
			<CaseDetailsRow fieldName={t('case_recurring_status')} align="top">
				<TextField
					select
					size="small"
					value={recurringStatus}
					name="recurringStatus"
					onChange={handleRecurringStatusChange}
					variant="outlined"
				>
					{recuringStatuses.map((option) => (
						<MenuItem key={option.code} value={option.code}>
							{option.name}
						</MenuItem>
					))}
				</TextField>
			</CaseDetailsRow>
			<WhiteModal
				handleModalOpen={onCloseRecurrDialog}
				open={showCustomRecurring}
				maxWidth={315}
				minWidth={315}
			>
				<RecurringOptionsForm
					recurring={recurring}
					currentDay={currentDay}
					currentMonthDate={currentMonthDate}
					currentDayObj={currentDayObj}
					onCloseRecurrDialog={onCloseRecurrDialog}
					stateHandler={setState}
					recurringOptions={recurringOptions}
					selectedTime={selectedTime}
					ON_WEEK_DAY={ON_WEEK_DAY}
					editMode
				/>
			</WhiteModal>
		</>
	);
}
