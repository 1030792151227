import React from 'react';
import AvatarMui, { AvatarProps } from '@material-ui/core/Avatar';

type Props = {
	src?: string;
	letter?: string;
	className?: string;
	alt?: string;
} & AvatarProps;

export function Avatar({ letter = '', ...rest }: Props) {
	return <AvatarMui {...rest}>{letter}</AvatarMui>;
}
