import React from 'react';
import { IMessage, ScrollToMsg } from 'shared/interfaces';
import { useMessageFields } from 'shared/hooks/chat-hooks/useMessageFields';
import clsx from 'clsx';
import { RepliedMediaMsgWrapper } from './RepliedMediaMsgWrapper';
import isEqual from 'lodash/isEqual';
import { useWhiteModal } from '../../../hooks';
import { useCachedReactedUsers } from '../../../hooks/chat-hooks/useCachedReactedUsers';
import { EmojiCountInfo } from './EmojiCountInfo';
import { DeletedMsg } from './DeletedMsg';

type Props = {
	message: IMessage;
	setScrollToMsgId: ScrollToMsg;
};

function areEqual(prev: any, next: any) {
	return (
		prev.message?.is_deleted === next.message?.is_deleted &&
		isEqual(prev.message?.contact, next.message?.contact) &&
		isEqual(prev.message?.replied_to, next.message?.replied_to) &&
		isEqual(prev.message.reacted_users, next.message.reacted_users)
	);
}

export const ContactMsg = React.memo(function ContactMsg({ message, setScrollToMsgId }: Props) {
	const { is_deleted, isOwnMsg, contact, replied_to, reacted_users, file_type, messageId } =
		useMessageFields(message);
	const {
		open: isEmojiCountOpen,
		modalHandler: isEmojiCountOpenHandler,
		WhiteModal: EmojiWhiteModal
	} = useWhiteModal();

	const { cachedReactions } = useCachedReactedUsers({
		reacted_users,
		file_type,
		isEmojiCountOpenHandler
	});
	if (is_deleted) {
		return <DeletedMsg isOwnMsg={isOwnMsg} type="contact" />;
	}
	return (
		<div
			className={clsx(
				'overflow-hidden break-words text-sm text-kdark shadow-text-msg dark:text-white',
				{
					'rounded-b-xl rounded-tr-xl bg-white dark:bg-white/20': !isOwnMsg,
					'rounded-t-xl rounded-bl-xl bg-primary-200': isOwnMsg
				}
			)}
		>
			<RepliedMediaMsgWrapper
				replied_to={replied_to}
				isOwnMsg={isOwnMsg}
				setScrollToMsgId={setScrollToMsgId}
			>
				<div
					className={clsx(
						'border border-kgrey-chatBorder px-5 py-4 text-sm text-kdark shadow-text-msg dark:text-white',
						{
							'rounded-b-xl rounded-tr-xl bg-white dark:bg-white/20': !isOwnMsg,
							'rounded-t-xl rounded-bl-xl bg-primary-200': isOwnMsg
						}
					)}
				>
					{contact?.phone_number?.map((num, index) => {
						return (
							<div className={'tel-contact mb-2'} key={index}>
								<span>
									Name: <span className={'ml-5'}>{contact?.name}</span>
								</span>
								<div className={'flex items-center'}>
									<span>Phone:</span>
									<a className={'ml-5 hover:text-primary'} href={`tel:${num}`} key={index}>
										{num}
									</a>
								</div>
							</div>
						);
					})}
				</div>
			</RepliedMediaMsgWrapper>
			<div
				className={clsx({
					'px-2': reacted_users?.length
				})}
			>
				{reacted_users?.length ? cachedReactions : null}
			</div>
			<EmojiWhiteModal handleModalOpen={isEmojiCountOpenHandler} open={isEmojiCountOpen}>
				<EmojiCountInfo messageId={messageId} reactedUsers={reacted_users} />
			</EmojiWhiteModal>
		</div>
	);
}, areEqual);
