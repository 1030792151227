type GetChatEntityNameType = {
	isScheduled: boolean;
	entityType: string | undefined;
	entityId: number | undefined;
};

export const getChatMessagesEntityName = ({
	isScheduled,
	entityType,
	entityId
}: GetChatEntityNameType) => {
	return `All${entityType?.[0].toUpperCase()}${entityType?.slice(1)}Messages-${entityId}-${
		isScheduled ? 'scheduled' : 'normal'
	}`;
};
