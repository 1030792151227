import React from 'react';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { Avatar } from 'shared/components/Elements';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

type Props = {
	firstName?: string;
	lastName?: string;
	src?: string;
	color?: string;
};

type userDataType = {
	firstName?: string;
	lastName?: string;
};

function getUserData({ firstName, lastName }: userDataType) {
	const FN = firstName?.[0].toUpperCase();
	const LN = lastName?.[0].toUpperCase();
	return FN && LN ? `${FN}${LN}` : '';
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		avatarColor: (props: Props) => ({
			backgroundColor: props.color ? props.color : ''
		}),
		root: {
			[theme.breakpoints.down('sm')]: {
				width: '32px',
				height: '32px'
			}
		}
	})
);

export function UserAvatar() {
	const { userData } = useAuth();
	const classes = useStyles({ color: userData?.color });
	const fullName = `${userData?.first_name} ${userData?.last_name}`;
	const src = userData?.avatar?.thumbnail_150;
	const letters = getUserData({ firstName: userData?.first_name, lastName: userData?.last_name });
	return (
		<div className="h-8 w-8 overflow-hidden rounded-full bg-kgrey-bg lg:h-10 lg:w-10">
			<Avatar
				src={src}
				letter={letters}
				alt={fullName}
				className={classes.avatarColor}
				classes={{ root: classes.root }}
			/>
		</div>
	);
}
