import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useTranslationHelpers() {
	const { i18n } = useTranslation();

	const languageDirection = useMemo(() => i18n.dir(i18n.language.toLowerCase()), [i18n.language]);

	const isRTL = useMemo(() => languageDirection === 'rtl', [languageDirection]);

	return { languageDirection, isRTL };
}
