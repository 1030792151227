/** @jsxImportSource @emotion/react */
import { useCallback, useEffect } from 'react';
import { useChatUpload } from '../../hooks';
import { DropzoneArea, CopyPasteDialog } from './components';
import clsx from 'clsx';

type Props = {
	ChatHeader: React.ReactNode;
	ChatBody: React.ReactNode;
	ChatInput: React.ReactNode;
};

export function ChatLayout({ ChatHeader, ChatBody, ChatInput }: Props) {
	const {
		getRootProps,
		getInputProps,
		isDragAccept,
		isDragReject,
		clipboardFile,
		handleClipboardClose,
		clipboardImageRef
	} = useChatUpload({
		isDragging: true
	});

	const classnames = clsx(
		'chat h-full focus-visible:outline-none flex flex-col justify-between font-poppins bg-no-repeat bg-cover bg-left-bottom',
		{ 'accept relative': isDragAccept, 'reject relative': isDragReject }
	);

	//Play audio
	const audioPlayFunction = useCallback((e) => {
		const audios = document.getElementsByTagName('audio');
		for (let i = 0, len = audios.length; i < len; i++) {
			if (audios[i] !== e.target) {
				audios[i].pause();
			}
		}
	}, []);

	//Playing one audio at the same time
	useEffect(() => {
		document.addEventListener('play', audioPlayFunction, true);
		return () => {
			document.removeEventListener('play', audioPlayFunction, true);
		};
	}, []);

	return (
		<div
			{...getRootProps({
				className: `${classnames}`
			})}
		>
			<DropzoneArea {...{ getInputProps, isDragAccept, isDragReject }} />
			{ChatHeader}
			{ChatBody}
			<div className="chat__footer flex-shrink-0">{ChatInput}</div>
			{clipboardFile && (
				<CopyPasteDialog {...{ clipboardFile, handleClipboardClose, clipboardImageRef }} />
			)}
		</div>
	);
}
