import config from 'config';

import { IAction } from 'shared/interfaces';
import { SystemActionTypes, SystemAction } from '../actions/system';

type ISysState = {
	currentLangCode: string;
};

const initialState: ISysState = {
	currentLangCode: config.DEFAULT_LANGUAGE
};

export default (state = initialState, action: IAction<SystemActionTypes>): ISysState => {
	switch (action.type) {
		case SystemActionTypes.CHANGE_LANGUAGE: {
			return {
				...state,
				currentLangCode: (action as SystemAction).payload
			};
		}

		default:
			return state;
	}
};
