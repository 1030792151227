import { useAxios } from './useAxios';
import { CaseFilterType, SelectedCaseFilter } from '../state/reducer';
import { useAppState } from '../state';
import { storage } from '../services';
import config from '../../config';

export function useCaseFilterSwitch() {
	const { setSelectedCaseFilter } = useAppState();
	const { fetchData: fetchCaseFilter } = useAxios({
		url: '',
		dynamicUrl: true,
		params: {
			extra: {
				is_selected: 'true'
			}
		},
		cb: {
			success: (data) => {
				const selectedCaseFilterObject = {
					[config.CLIENT]:
						(data.results as CaseFilterType[]).find(
							(filterItem) => !filterItem.is_staff && filterItem.is_selected
						) || null,
					[config.STAFF]:
						(data.results as CaseFilterType[]).find(
							(filterItem) => filterItem.is_staff && filterItem.is_selected
						) || null
				} as SelectedCaseFilter;

				if (selectedCaseFilterObject) {
					storage.set('selectedCaseFilter', JSON.stringify(selectedCaseFilterObject));

					setSelectedCaseFilter({
						filter: selectedCaseFilterObject,
						caseType: null,
						isInitialDispatch: true
					});
				}
			},
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			error: () => {}
		}
	});

	return { fetchCaseFilter };
}
