import clsx from 'clsx';

type Props = {
	title: string;
	className?: string;
};

export function StaticFilterItem({ title, className = '' }: Props) {
	return (
		<div
			className={clsx(
				'add-filter-item flex cursor-pointer items-center ' +
					'justify-between rounded-10 border border-transparent bg-primary-lighter ' +
					'px-3.5 py-2.5 text-sm leading-none text-kdark-text-2 transition' +
					' hover:bg-primary dark:border-white dark:bg-kdark dark:text-white',
				className
			)}
		>
			<div className="add-filter-item__text">{title}</div>
		</div>
	);
}
