import { useTranslation } from 'react-i18next';
import {
	useAxios,
	useNotistack,
	useQueryParams,
	useReorderEntityIds,
	useUpdateEntities
} from 'shared/hooks';
import { useAppState } from 'shared/state';

export function useCaseHTTPRequest() {
	const { showNotification } = useNotistack();
	const { companyId, searchQuery } = useAppState();
	const { getEntityData, modifyEntityIds } = useReorderEntityIds();
	const { updateEntities } = useUpdateEntities();
	const { changeUrlParams, query } = useQueryParams();
	const { t } = useTranslation();

	const { fetchData: sendCaseStatusChangeRequest } = useAxios({
		url: '',
		dynamicUrl: true,
		dynamicValues: true,
		method: 'patch',
		cb: {
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			success: (data, args) => {
				if (!searchQuery) {
					updateEntities({
						entity: 'cases',
						entityId: data.id,
						updatingData: {
							...data
						}
					});
				}

				if (data?.status?.id && query?.status) {
					changeUrlParams({ status: data?.status?.id });
				}
			},
			error: (error, args) => {
				const {
					prevSourceIds,
					prevDestinationIds,
					entityNameSource,
					entityNameDestination,
					prevSourceMeta,
					prevSourceParams,
					prevDestinationMeta,
					prevDestinationParams
				} = args[0];
				modifyEntityIds({
					ids: prevSourceIds,
					entityName: entityNameSource,
					entity: 'cases',
					meta: prevSourceMeta,
					params: prevSourceParams
				});
				modifyEntityIds({
					ids: prevDestinationIds,
					entityName: entityNameDestination,
					entity: 'cases',
					meta: prevDestinationMeta,
					params: prevDestinationParams
				});

				showNotification({
					message: t('something_went_wrong'),
					variant: 'error'
				});
			}
		}
	});

	const { fetchData: sendStatusReorderRequest } = useAxios({
		url: '',
		dynamicUrl: true,
		dynamicValues: true,
		method: 'post',
		cb: {
			success: (data, args) => {
				showNotification({
					message: t('reorder_success'),
					variant: 'success'
				});
			},
			error: (error, args) => {
				const { meta, params, entity, entityName } = getEntityData(
					'statuses',
					`AllStatuses-${companyId}`
				);
				const { prevIds } = args[0];
				modifyEntityIds({ ids: prevIds, entityName, entity, meta, params });
				showNotification({
					message: t('something_went_wrong'),
					variant: 'error'
				});
			}
		}
	});

	return {
		sendCaseStatusChangeRequest,
		sendStatusReorderRequest
	};
}
