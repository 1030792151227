import React from 'react';
import cx from 'clsx';
// import PropTypes from 'prop-types';
import { AsyncPaginate } from 'react-select-async-paginate';
import get from 'lodash/get';
import { FieldProps } from 'formik';
import { api, queryBuilder } from 'shared/services';
import qs from 'query-string';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

const load = async (
	search: string,
	prevOptions: Array<Record<string, any>>,
	{ page }: any,
	url: any,
	filterParams: any,
	params: object,
	loadOptionsKey: string | any,
	defaultOption: any,
	reverse: boolean,
	pageCount = 10
) => {
	const builtUrl = queryBuilder(url, {
		page,
		limit: pageCount,
		filter: filterParams(search),
		...(typeof params === 'function' ? params(search) : params)
	});

	const { data } = await api.request.get(builtUrl);

	const optionCount = data.count;
	const nextUrl = data && get(data, 'next');
	const indexUrl = nextUrl && nextUrl.indexOf('?');
	const queryParams = nextUrl && nextUrl.slice(indexUrl);
	const splitted = qs.parse(queryParams);
	const currentPage = splitted && Number(splitted.page) - 1;

	let last_page;
	if (optionCount % pageCount === 0) {
		last_page = optionCount / pageCount;
	} else {
		last_page = Math.floor(optionCount / pageCount) + 1;
	}

	let options = [];

	if (reverse) {
		options =
			typeof loadOptionsKey === 'function'
				? loadOptionsKey(data)
				: loadOptionsKey && isEmpty(defaultOption)
				? get(data, loadOptionsKey, []).reverse()
				: loadOptionsKey && !isEmpty(defaultOption)
				? !isEmpty(get(data, loadOptionsKey, [])) && currentPage !== null
					? [...get(data, loadOptionsKey, []).reverse()]
					: [...get(data, loadOptionsKey, []).reverse(), defaultOption]
				: data.reverse();
	} else {
		options =
			typeof loadOptionsKey === 'function'
				? loadOptionsKey(data)
				: loadOptionsKey && isEmpty(defaultOption)
				? get(data, loadOptionsKey, [])
				: loadOptionsKey && !isEmpty(defaultOption)
				? !isEmpty(get(data, loadOptionsKey, [])) && currentPage
					? [...get(data, loadOptionsKey, [])]
					: [...get(data, loadOptionsKey, []), defaultOption]
				: data;
	}

	return {
		options: options,
		hasMore: currentPage < last_page && currentPage !== null,
		additional: { page: currentPage + 1 }
	};
};

// const loadOld = async (
// 	search: string,
// 	prevOptions: Array<{}>,
// 	{ page }: any,
// 	url: any,
// 	filterParams: any,
// 	params: object,
// 	loadOptionsKey: string | Function
// ): Promise<any> => {
// 	const { data } = await api.request.get(
// 		queryBuilder(url, { page, filter: filterParams(search), ...params })
// 	);
//
// 	return {
// 		options: loadOptionsKey
// 			? typeof loadOptionsKey === 'function'
// 				? loadOptionsKey(data)
// 				: get(data, loadOptionsKey, [])
// 			: data,
// 		hasMore: get(data, '_meta.currentPage', 1) < get(data, '_meta.pageCount', 1),
// 		additional: { page: get(data, '_meta.currentPage', 1) + 1 }
// 	};
// };

interface Props {
	title: string;
	className?: string;
	optionLabel?: any;
	isSearchable?: boolean;
	menuPlacement?: boolean;
	loadOptionsKey: any;
	label?: string;
	isMulti?: boolean;
	placeholder?: string;
	options?: object[];
	optionValue?: any;
	loadOptionsUrl?: string;
	loadOptionsParams?: any;
	filterParams?: () => void;
	customStyles?: boolean;
	isClearable?: boolean;
	disableOptions?: [];
	children?: React.ReactNode;
	SelectComponent?: any;
	isLoading?: boolean;
	onChange?: (option: any) => void;
	reverse?: boolean;
	defaultOption?: any;
	onClearChange?: () => void;
	pageCount?: number;
	onCreateOption?: () => void;
}

interface FormValues {
	field: object;
	form: object;
}

interface Option {
	id: number;
	[key: string]: any;
}

// const AsyncSelectPropTypes = {
// 	title: PropTypes.string.isRequired,
// 	className: PropTypes.string,
// 	optionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
// 	optionLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
// 	isSearchable: PropTypes.bool,
// 	menuPlacement: PropTypes.string,
// 	loadOptionsKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
// 		.isRequired,
// 	field: PropTypes.object,
// 	label: PropTypes.string,
// 	isMulti: PropTypes.bool,
// 	placeholder: PropTypes.string,
// 	options: PropTypes.array,
// 	form: PropTypes.object,
// 	loadOptionsUrl: PropTypes.string,
// 	loadOptionsParams: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
// 	filterParams: PropTypes.func,
// 	customStyles: PropTypes.bool,
// 	isClearable: PropTypes.bool,
// 	disableOptions: PropTypes.array
// };

// const AsyncSelectDefaultProps = {
// 	title: '',
// 	className: null,
// 	optionValue: 'id',
// 	optionLabel: 'title',
// 	isSearchable: false,
// 	menuPlacement: 'bottom',
// 	disableOptions: [],
// 	loadOptionsKey: 'data',
// 	isMulti: false,
// 	field: {},
// 	label: 'Select',
// 	placeholder: 'Some Placeholder text',
// 	options: [],
// 	form: {},
// 	loadOptionsUrl: '/',
// 	loadOptionsParams: {},
// 	filterParams: (): object => ({}),
// 	customStyles: false,
// 	isClearable: true
// };

// type InferPropTypes<
// 	PropTypes,
// 	DefaultProps = {},
// 	Props = PropTypes.InferProps<PropTypes>
// > = {
// 	[Key in keyof Props]: Key extends keyof DefaultProps
// 		? Props[Key] | DefaultProps[Key]
// 		: Props[Key];
// };

// type Props = InferPropTypes<
// 	typeof AsyncSelectPropTypes,
// 	typeof AsyncSelectDefaultProps
// >;

const AsyncSelect: React.FC<Props & FieldProps<FormValues>> = ({
	disableOptions = [],
	className = '',
	label,
	isMulti,
	loadOptionsKey,
	placeholder,
	options,
	field,
	optionLabel,
	form: { errors, setFieldValue, setFieldTouched, touched },
	isSearchable,
	menuPlacement,
	loadOptionsUrl,
	optionValue,
	isClearable,
	loadOptionsParams = {},
	filterParams = () => {},
	onCreateOption,
	SelectComponent,
	isLoading,
	onChange,
	reverse = false,
	defaultOption = {},
	onClearChange,
	pageCount,
	...rest
}: Props & FieldProps<FormValues>): JSX.Element => {
	const classNames = cx(
		'field-container',
		touched[field.name] && errors[field.name] && 'has-error',
		className
	);
	const { t } = useTranslation();

	const customStyles = {
		indicatorSeparator: (props: any) => ({
			display: 'none'
		})
	};

	return (
		<div className={classNames}>
			<div>
				{label && <div>{label}</div>}
				<AsyncPaginate
					noOptionsMessage={() => t('no_options')}
					loadingMessage={() => `${t('loading')}...`}
					styles={customStyles}
					id={field.name}
					name={field.name}
					debounceTimeout={300}
					onChange={(option: any) => {
						setFieldValue(field.name, option);
						if (typeof onChange === 'function') {
							onChange(option);
						}
						if (typeof onClearChange === 'function' && option === null) {
							onClearChange();
						}
					}}
					onBlur={(): void => setFieldTouched(field.name, true)}
					getValue={(option: Option): any => option[optionValue]}
					getOptionLabel={(option: Option): any =>
						typeof optionLabel === 'function' ? optionLabel(option) : option[optionLabel]
					}
					getOptionValue={(option: Option): any =>
						typeof optionValue === 'function' ? optionValue(option) : option[optionValue]
					}
					value={field.value}
					additional={{ page: 1 }}
					//@ts-ignore
					loadOptions={(search: string, prevOptions: [], { page }: any): any => {
						return load(
							search,
							prevOptions,
							page,
							loadOptionsUrl,
							filterParams,
							loadOptionsParams,
							loadOptionsKey,
							defaultOption,
							reverse,
							pageCount
						);
					}}
					isOptionDisabled={(option: Option): any =>
						disableOptions
							.reduce((prev: Array<any>, curr: Option): Array<any> => [...prev, curr.id], [])
							.includes(option.id)
					}
					{...{
						isMulti,
						options,
						placeholder,
						isSearchable,
						isClearable,
						menuPlacement,
						onCreateOption,
						SelectComponent,
						isLoading
					}}
					{...rest}
				/>
				{touched[field.name] && errors[field.name] && (
					<div className="ant-form-explain">{errors[field.name]}</div>
				)}
			</div>
		</div>
	);
};

export default AsyncSelect;
