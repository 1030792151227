import { useDispatch, useSelector } from 'react-redux';
import { getFCM } from 'modules/user/selectors';
import { useAppState } from 'shared/state';
import { useAxios, useQueryParams } from 'shared/hooks/';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import Actions from 'store/actions';
import { storage } from 'shared/services';
import { useParams } from 'react-router';
import { IRootState } from '../interfaces';
import { CaseType, unreadTypes } from '../state/reducer';
import config from 'config';

type Props = {
	isSetPassword?: boolean;
	isEmailConfirmation?: boolean;
	isChangePassword?: boolean;
};

export function useLogout({
	isSetPassword = false,
	isEmailConfirmation = false,
	isChangePassword = false
}: Props) {
	const dispatch = useDispatch();
	const fcm = useSelector((state: IRootState) => getFCM(state));
	const {
		setRegisterInfo,
		setHasUnread,
		setAreUnreadsFetched,
		toggleDark,
		isDarkMode,
		setIsAppAvailable,
		setCaseType
	} = useAppState();
	const { query } = useQueryParams();

	const params = useParams<{ code?: string }>();
	const history = useHistory();

	useAxios({
		url: '/user/unauth/',
		method: 'delete',
		initialLoad: true,
		cb: {
			success: (data, args) => {
				// api.request.defaults.headers.common['Authorization'] = null;
			},
			error: (error, args) => {}
		}
	});

	const { fetchData: deleteFCM } = useAxios({ url: `/web-devices/${fcm}/`, method: 'delete' });
	useEffect(() => {
		setTimeout(() => {
			dispatch(
				Actions.auth.Logout.request({
					cb: () => {
						deleteFCM();
						dispatch({ type: 'LOGOUT/SUCCESS' });
						storage.remove('selectedBusiness');
						storage.remove('company');
						storage.remove('selectedCaseFilter');
						storage.remove('caseType');
						storage.remove('token');
						storage.remove('caseDraft');
						storage.remove('imsDraft');
						storage.remove('paymentWidget');
						storage.remove('casesReverseData');
						storage.remove('settingsData');
						storage.remove('darkMode');
						setIsAppAvailable(false);

						//re-setting default case type which is STAFF
						setCaseType(config.STAFF as CaseType);

						if (isDarkMode) {
							toggleDark();
						}
						setRegisterInfo(null);

						(['staff', 'client', 'group', 'all'] as unreadTypes[]).forEach((type) => {
							setAreUnreadsFetched({ type, value: false });
						});

						(['staff', 'client'] as ('client' | 'staff')[]).forEach((type) => {
							setHasUnread({ type, value: false });
						});

						if (isSetPassword) {
							setTimeout(() => {
								history.push(
									`/app/set-password/${params.code}?type=accept&username=${query.username}`
								);
							}, 300);
							return;
						}
						if (isChangePassword) {
							setTimeout(() => {
								history.push(`/change-password/?username=${query.username}&code=${query.code}`);
							}, 300);
							return;
						}

						if (isEmailConfirmation) {
							setTimeout(() => {
								history.push(`/app/sts/${params.code}`);
							}, 300);
							return;
						}
						if (query?.resignin) {
							history.push('/signin');
							localStorage.clear();
							document.location.reload();
						}
					}
				})
			);
		}, 300);
	}, []);
}
