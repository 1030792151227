import React from 'react';
import { Field } from 'formik';
import AsyncSelectJs from 'shared/components/Fields/AsyncSelectJs';
import { caseFilterStyle } from 'shared/services';
import { isMobile } from 'react-device-detect';
import { useAppState } from '../../../../shared/state';
import { ClientCompany, ClientUserType } from 'shared/typings/client.types';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';

type Props = {
	setFieldValue: any;
	values: Record<string, any>;
};

export function ClientCompanyFilterItem({ setFieldValue, values }: Props) {
	const { companyId } = useAppState();
	const { t } = useTranslation();
	return (
		<>
			<div className="dropdown mb-4">
				<div className="mb-2 text-sm leading-none text-kgrey-menuText dark:text-white">
					{t('client_company')}
				</div>
				<div className="w-full">
					<Field
						component={AsyncSelectJs}
						customStylesProps={caseFilterStyle}
						title="Client List"
						optionLabel={(option: ClientCompany) =>
							option.is_individual && option.owner
								? `${option?.owner.first_name} ${option?.owner.last_name}  (${option.name})`
								: option.name
						}
						optionValue="id"
						loadOptionsUrl={`/${companyId}/client_companies/`}
						loadOptionsParams={(search: string) => ({
							extra: {
								search
							}
						})}
						isSearchable={!isMobile}
						name="client_company"
						loadOptionsKey="results"
						placeholder={t('select_client')}
						onChange={(option: ClientCompany) => {
							setFieldValue('client_user', undefined);
						}}
						isCached
					/>
				</div>
			</div>
			{!isEmpty(get(values, 'client_company')) && (
				<div className="dropdown mb-4">
					<div className="mb-2 text-sm leading-none text-kgrey-menuText dark:text-white">
						{t('client_user')}
					</div>
					<div className="w-full">
						<Field
							component={AsyncSelectJs}
							customStylesProps={caseFilterStyle}
							title="Client User"
							optionLabel={(option: ClientUserType) => `${option.first_name} ${option.last_name}`}
							optionValue="id"
							loadOptionsUrl={`/${companyId}/client_companies/${get(
								values,
								'client_company.id'
							)}/employee/`}
							loadOptionsParams={(search: string) => ({
								extra: {
									search
								}
							})}
							isSearchable={!isMobile}
							name="client_user"
							loadOptionsKey="results"
							placeholder={t('select_client_user')}
						/>
					</div>
				</div>
			)}
		</>
	);
}
