/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-empty-function */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Form, FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { serialize } from 'object-to-formdata';

import { IECForm, IECFormAction, IField } from 'shared/interfaces';
import { formRequest } from '../actions';
import Translation from 'i18n';

interface DispatchProps {
	// eslint-disable-next-line react/no-unused-prop-types
	FormAction: ({
		id,
		entity,
		name,
		url,
		method,
		primaryKey,
		appendData,
		prependData,
		updateData,
		deleteData,
		normalizeData,
		params,
		values,
		relations,
		cb
	}: IECFormAction) => void;
}

interface FormValues {
	[name: string]: any;
}

interface OwnProps {
	children: ({
		handleSubmit,
		submitForm,
		values,
		isSubmitting,
		setFieldValue,
		setFieldError,
		setFieldTouched
	}: any) => React.ReactElement;
}

type Props = IECForm & OwnProps & DispatchProps;

// eslint-disable-next-line react/function-component-definition
const Main: React.FC<Props & FormikProps<FormValues>> = ({
	children,
	handleSubmit,
	submitForm,
	values,
	isSubmitting,
	setFieldValue,
	setFieldError,
	errors,
	setFieldTouched,
	touched,
	resetForm
}) => (
	<Form>
		{children({
			handleSubmit,
			submitForm,
			values,
			isSubmitting,
			setFieldValue,
			setFieldError,
			setFieldTouched,
			errors,
			touched,
			resetForm
		})}
	</Form>
);

const EnhacedForm = withFormik<Props, FormValues>({
	enableReinitialize: true,
	validationSchema: ({ fields }: any) => {
		if (!isArray(fields)) {
			return Yup.object().shape({});
		}

		const validationFields: any = {};

		fields.forEach((field: IField) => {
			let validationField;

			if (!field?.customValidation?.(field.value)) {
				// @ts-ignore
				switch (field.type) {
					case 'string':
						validationField = Yup.string().typeError(Translation.t('must_be_string'));
						break;
					case 'email':
						validationField = Yup.string().email(Translation.t('must_be_valid_email'));
						break;
					case 'object':
						validationField = Yup.object();
						break;
					case 'number':
						validationField = Yup.number().typeError(Translation.t('must_be_number'));
						break;
					case 'array':
						validationField = Yup.array();
						break;
					case 'boolean':
						validationField = Yup.boolean();
						break;
					case 'date':
						validationField = Yup.date();
						break;
					default:
						validationField = Yup.string();
				}
			}

			//Checking trailing and blank spaces in the field
			if (typeof field.value === 'string' && field.type === 'string') {
				validationField = Yup.string().trim(Translation.t('field_cannot_blank'));
			}

			if (typeof field.value === 'string' && field?.customValidation?.(field.value)) {
				validationField = Yup.string();
			}

			if (field.required) {
				validationField = validationField?.required(Translation.t('required'));
			}

			if (field.min) {
				validationField = Yup.number().min(
					field.min,
					Translation.t('minimum_character_validation', { minimum: field?.min })
				);
			}

			if (field.max) {
				validationField = Yup.number().max(
					field.max,
					Translation.t('maximum_character_validation', { maximum: field?.min })
				);
			}

			if (field.type === 'website') {
				validationField = Yup.string()
					.matches(
						/^(?:(ftp|http|https):\/\/)?(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
						Translation.t('enter_correct_url')
					)
					.typeError(Translation.t('please_enter_website'));
			}

			// @ts-ignore
			validationField = validationField.nullable();

			validationFields[field.name] = validationField;
		});

		return Yup.object().shape(validationFields);
	},
	mapPropsToValues: ({ fields }) => {
		return isArray(fields)
			? fields.reduce(
					(prev, curr) => ({
						...prev,
						[curr.name]: get(curr, 'value', '')
					}),
					{}
			  )
			: {};
	},
	handleSubmit: (
		values: any,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		{ props, setFieldError, setSubmitting, resetForm }
	) => {
		const {
			id,
			entity,
			name,
			url,
			params = {},
			method,
			primaryKey = 'id',
			fields,
			appendData = false,
			prependData = false,
			updateData = false,
			deleteData = false,
			normalizeData = '',
			relations = '',
			sendAsFormData = true,
			onSuccess = () => {},
			onError = () => {},
			FormAction
		} = props;

		// eslint-disable-next-line no-param-reassign
		values = { ...values };

		// if (typeof url === 'function') {
		// 	url = url({ ...values });
		// }

		fields.forEach((field): any => {
			// eslint-disable-next-line no-prototype-builtins
			if (field.hasOwnProperty('onSubmitValue')) {
				if (typeof field.onSubmitValue === 'function') {
					// eslint-disable-next-line no-prototype-builtins
					if (field.hasOwnProperty('onSubmitKey') && !isEmpty(values[field.onSubmitKey])) {
						// eslint-disable-next-line no-param-reassign
						values[field.onSubmitKey] = field.onSubmitValue(values[field.name], values);
						// eslint-disable-next-line no-param-reassign
						delete values[field.name];
					} else {
						// eslint-disable-next-line no-param-reassign
						values[field.name] = field.onSubmitValue(values[field.name], values);
					}
				}
			}
			// eslint-disable-next-line no-prototype-builtins
			if (field.hasOwnProperty('disabled')) {
				// eslint-disable-next-line no-param-reassign
				delete values[field.name];
			}
		});

		if (sendAsFormData) {
			// eslint-disable-next-line no-param-reassign
			values = serialize(values);
			// values.append('_method', 'PUT');
		}

		FormAction({
			id,
			entity,
			name,
			url,
			params,
			method,
			primaryKey,
			values,
			appendData,
			relations,
			prependData,
			updateData,
			deleteData,
			normalizeData,
			cb: {
				success: (data, formValues) => {
					onSuccess(data, resetForm, formValues);
				},
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				error: (error): any => {
					// Object.keys(error).forEach(item => {
					// 	setFieldError(item, error[item][0]);
					// });

					onError(error, resetForm);
				},
				finally: () => {
					setSubmitting(false);
				}
			}
		});
	}
})(Main);

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			FormAction: formRequest
		},
		dispatch
	);

export default connect(null, mapDispatchToProps)(EnhacedForm);
