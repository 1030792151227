import { useDispatch } from 'react-redux';
import Actions from 'store/actions';
const UpdateEntities = Actions.entities.updateEntitiesSuccess;

type UpdateData = {
	entity: string;
	entityId: number | string;
	updatingData: {
		[key: string]: any;
	};
};

export function useUpdateEntities() {
	const dispatch = useDispatch();

	function updateEntities({ entity, entityId, updatingData }: UpdateData): void {
		dispatch(
			UpdateEntities({
				entity,
				entityId: String(entityId),
				data: {
					...updatingData
				}
			})
		);
	}

	return {
		updateEntities
	};
}
