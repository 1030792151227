import React from 'react';
import { IconAddFilter } from 'shared/icons';

type Props = {
	title: string;
	onClick: () => void;
};

export function AddingFilterItem({ title, onClick }: Props) {
	return (
		<div
			className="add-filter-item border-kgrey-grey-6 text-kdark-grey-1 !mb-4 flex cursor-pointer
					 items-center justify-between rounded-10
					 border bg-kgrey-light-grey-3 !px-3.5 !py-1.5 text-sm leading-none transition hover:bg-kgrey-light-grey-5 dark:bg-kdark"
			onClick={onClick}
		>
			<div className="add-filter-item__text">{title}</div>
			<div className="add-filter-item__icon">
				<IconAddFilter />
			</div>
		</div>
	);
}
