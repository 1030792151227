import { useState, useCallback } from 'react';
import { WhiteModal } from 'shared/components/Elements';

export function useWhiteModal() {
	const [open, setOpen] = useState(false);
	const modalHandler = useCallback(() => {
		setOpen((prev) => !prev);
	}, []);

	return {
		WhiteModal,
		open,
		modalHandler
	};
}
