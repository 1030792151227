import { loadOneRequest } from 'modules/entity/actions';
import { useDispatch } from 'react-redux';
import { QueryType } from 'shared/interfaces';

type EntityOne = {
	entityId: string | number;
	entity: string;
	name: string;
	url: string;
	params?: QueryType;
	primaryKey?: string;
	relations?: string;
};

export function useLoadOne() {
	const dispatch = useDispatch();
	function reloadEntityOne({
		entityId,
		entity,
		name,
		url,
		params = {},
		primaryKey = 'id',
		relations = ''
	}: EntityOne) {
		dispatch(
			loadOneRequest({
				id: entityId,
				entity,
				name,
				url,
				params,
				primaryKey,
				relations
			})
		);
	}

	return { reloadEntityOne };
}
