// mostly code from reactjs.org/docs/error-boundaries.html
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { PureComponent, memo } from 'react';
import * as Sentry from '@sentry/browser';
import Button from '@material-ui/core/Button';
import ErrorIcon from '@material-ui/icons/Error';
import IconButton from '@material-ui/core/IconButton';
import { ErrorBoundary } from 'react-error-boundary';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';

type Props = RouteComponentProps & {
	isCompact?: boolean;
	resetKeys?: Array<unknown>;
	FallbackComponent?: any;
	onResetHandler?: () => void;
};

type State = {
	hasError: boolean;
	eventId: string;
	retry: number;
};

const DefaultErrorComp = memo(function ErrorComp({
	eventId,
	resetErrorBoundary
}: {
	eventId?: string;
	resetErrorBoundary: () => void;
}) {
	const { t } = useTranslation();
	return (
		<div
			className={
				'flex items-center justify-center gap-x-2 py-4 text-center dark:bg-kgrey-2d2d2d dark:text-white'
			}
		>
			<h1 className={'font-semibold dark:text-white'}>Unexpected Error</h1>
			<div className="flex-centered flex-col ">
				<Button variant="contained" size={'small'} onClick={resetErrorBoundary}>
					{t('dismiss')}
				</Button>
			</div>
		</div>
	);
});

class ErrorBoundaryPro extends PureComponent<Props, State> {
	public state = {
		hasError: false,
		eventId: '',
		retry: 1
	};

	errorHandlerCallback = (error: Error, info: { componentStack: string }) => {
		console.error('ErrorBoundaryProClass caught an error', error, info);

		Sentry.withScope((scope) => {
			scope.setTag('Custom-Tag', 'ErrorBoundry');
			scope.setExtras(info);
			const eventId = Sentry.captureException(error);
			this.setState({ eventId });
		});
	};

	public render() {
		const {
			isCompact = false,
			resetKeys,
			FallbackComponent,
			children,
			onResetHandler = () => {
				window.location.reload();
			}
		} = this.props;
		const { eventId } = this.state;

		return (
			<ErrorBoundary
				resetKeys={[resetKeys ?? this.props.location?.pathname]}
				onError={this.errorHandlerCallback}
				fallbackRender={({ error, resetErrorBoundary }) => {
					if (FallbackComponent) return <FallbackComponent />;
					if (isCompact) return <CompactErrorComp resetErrorBoundary={resetErrorBoundary} />;
					return <DefaultErrorComp eventId={eventId} resetErrorBoundary={resetErrorBoundary} />;
				}}
				onReset={() => {
					console.log('error state has been resetted');

					this.setState((prev) => ({ ...prev, retry: prev.retry + 1 }));

					if (this.state.retry === 2) {
						onResetHandler();
					}
				}}
			>
				{children}
			</ErrorBoundary>
		);
	}
}

type CompactErrorProps = {
	resetErrorBoundary: () => void;
};
function CompactErrorComp({ resetErrorBoundary }: CompactErrorProps) {
	return (
		<IconButton onClick={resetErrorBoundary} className="bg-disabled flex items-center gap-x-2">
			<ErrorIcon className={'!text-orange-400'} />
			<h3 className="text-xs">
				Unexpected Error <span className="cursor-pointer text-blue-400"> dismiss.</span>
			</h3>
		</IconButton>
	);
}

export default withRouter(ErrorBoundaryPro);
