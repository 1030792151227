export const newTemplateMockData = {
	isNew: true,
	filter_json: {
		query: {}
	},
	fields_json: [],
	is_selected: false,
	title: '',
	is_staff: null,
	is_default: false,
	filter_type: {
		value: 'and',
		label: 'And'
	}
};

export const filtersMockData = [
	{
		type: 'date',
		name: 'due_date',
		title: 'Case Created Date'
	},
	{
		type: 'static',
		name: 'unread',
		title: 'Unread'
	},
	{
		type: 'static',
		name: 'unresponded',
		title: 'Unresponded'
	},
	{
		type: 'static',
		name: 'expired',
		title: 'OverDue'
	},
	{
		type: 'static',
		name: 'pinned',
		title: 'Pinned'
	},
	{
		type: 'select',
		name: 'status',
		title: 'Status'
	},
	{
		type: 'select',
		name: 'priority',
		title: 'Priority'
	},
	{
		type: 'select',
		name: 'department',
		title: 'Department'
	},
	{
		type: 'select',
		name: 'client_type',
		title: 'Client type'
	},
	{
		type: 'select',
		name: 'client_company',
		title: 'Client list'
	},
	{
		type: 'select',
		name: 'member',
		title: 'Staff access'
	},
	{
		type: 'select',
		name: 'assignees',
		title: 'Assigned To'
	},
	{
		type: 'select',
		name: 'assigned_by',
		title: 'Assigned By'
	},
	{
		type: 'static',
		name: 'tagged',
		title: 'Tagged'
	},
	{
		type: 'static',
		name: 'scheduled',
		title: 'Scheduled'
	}
];

export const filteringTypeOptions = [
	{ label: 'And', value: 'and' },
	{ label: 'Or', value: 'or' }
];
