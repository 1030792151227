import { useQueryParams } from 'shared/hooks';
import React, { useCallback } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useTranslationHelpers } from 'shared/hooks/useTranslationHelpers';

export function ScheduledChatHeader() {
	const { removeMultipleParams } = useQueryParams();
	const { t } = useTranslation();
	const { isRTL } = useTranslationHelpers();

	const handleClickBackButton = useCallback(() => {
		removeMultipleParams(['isScheduledChat']);
	}, []);

	return (
		<div className="schedule-chat__header border-b border-black/10 p-2 pr-0 dark:bg-kdark lg:py-5 lg:pl-5">
			<div className="schedule-chat-header__content flex items-center gap-x-2">
				<span onClick={handleClickBackButton} className="header-content__icon cursor-pointer">
					<IoIosArrowBack className={clsx('dark:text-white', { 'rotate-180': isRTL })} />
				</span>
				<div className="header-content__text text-sm font-semibold dark:text-white md:text-base">
					{t('schedule_message')}
				</div>
			</div>
		</div>
	);
}
