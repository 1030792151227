import React, { useEffect } from 'react';
import { Field } from 'formik';
import { CustomDTPicker } from 'shared/components/Fields';
import { default as dayjs } from 'dayjs';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AsyncSelectJs from 'shared/components/Fields/AsyncSelectJs';
import { KntTwilioStyle } from 'shared/services';
import config from 'config';
import { useAppState } from 'shared/state';
import { useTranslation } from 'react-i18next';
import { IOSSwitch } from 'shared/components/Elements/Fields/SwitchComp';
import { FormControlLabel } from 'shared/components/Elements';
import { useSwitch } from 'shared/hooks';

type Props = {
	state: any;
	setState: any;
	dueDateValue: string | null;
	setFieldTouched: any;
	recurringId: number | null;
	setFieldValue: any;
};

export function CdExpectedTimeOfCompletion({
	state,
	setState,
	dueDateValue,
	setFieldTouched,
	recurringId,
	setFieldValue
}: Props) {
	const { caseType, companyId } = useAppState();
	const { t } = useTranslation();
	const { checked, handleChange, setChecked } = useSwitch({ due_date_toggle: false });

	//Show due date values if due date is set in case
	useEffect(() => {
		if (dueDateValue) {
			setChecked((prev) => ({ ...prev, due_date_toggle: true }));
			setState((prevState: any) => ({
				...prevState,
				isDateTimeSet: Boolean(dueDateValue)
			}));
		}
	}, [dueDateValue]);

	useEffect(() => {
		//If toggle is on, we set default ETC value to three days advance from now. 3340's fix.
		if (checked.due_date_toggle) {
			const defaultDate = dayjs().hour(8).minute(0).second(0).add(1, 'days').format();
			setFieldValue('due_date', defaultDate);
			setState((prevState: any) => ({
				...prevState,
				isDateTimeSet: true
			}));
		} else {
			setFieldValue('due_date', null);
			setState((prevState: any) => ({
				...prevState,
				isDateTimeSet: false
			}));
		}
	}, [checked.due_date_toggle]);

	return (
		<div>
			<FormControlLabel
				control={
					<IOSSwitch
						checked={checked.due_date_toggle}
						onChange={handleChange}
						name="due_date_toggle"
						size={'medium'}
						classes={{ root: '!ml-3.5' }}
					/>
				}
				label={''}
			/>
			{checked.due_date_toggle ? (
				<div className="my-3 flex">
					<div className={`flex-grow ${state.isDateTimeSet ? 'date-set' : 'date-unset'}`}>
						<Field
							component={CustomDTPicker}
							name="due_date"
							inputVariant="outlined"
							size="small"
							fullWidth
							disabled={recurringId}
							dateValue={dueDateValue}
							emptyLabel={`${dayjs()
								.hour(8)
								.minute(0)
								.second(0)
								.add(1, 'days')
								.format('ddd, MMM DD, YYYY, h:mm:ss a')} (${t('optional_hint')})`}
							isDateTimeSet={state.isDateTimeSet}
							onChange={() => {
								setState((prevState: any) => ({
									...prevState,
									isDateTimeSet: true
								}));
								setFieldTouched('complete_status');
							}}
						/>
					</div>
					{state.isDateTimeSet && (
						<>
							{/* Case: 4185 - removed x button bcs ETC toggler is doing x button's job, the button is not needed anymore */}
							{/* <div
								className="case-form__input-row-icon mx-1.5"
								onClick={() =>
									setState((prevState: any) => ({
										...prevState,
										isDateTimeSet: false
									}))
								}
							>
								<IconButton>
									<CloseIcon />
								</IconButton>
							</div> */}
							<div className="mx-1.5 min-w-[150px]">
								<Field
									component={AsyncSelectJs}
									customStylesProps={KntTwilioStyle}
									name="complete_status"
									optionLabel="title"
									optionValue="id"
									isSearchable
									isCached
									isDisabled={recurringId}
									placeholder={t('to_status_hint')}
									reverse={true}
									loadOptionsUrl={`/${companyId}/my/company/case_statuses/`}
									loadOptionsParams={(search: string) => ({
										extra: {
											search,
											is_staff: caseType === config.STAFF ? 'true' : ''
										}
									})}
									loadOptionsKey=""
								/>
							</div>
						</>
					)}
				</div>
			) : null}
		</div>
	);
}
