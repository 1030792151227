import React from 'react';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const tabsStyles = (theme: Theme) =>
	createStyles({
		root: {
			// borderBottom: "1px solid #DDE0EB",
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		},
		indicator: {
			backgroundColor: theme.palette.primary.main,
			height: '3px',
			borderRadius: '1px'
		},
		scroller: {
			display: 'flex',
			justifyContent: 'center'
		},
		flexContainer: {
			borderBottom: '1px solid #DDE0EB'
		}
	});

const tabStyles = (theme: Theme) =>
	createStyles({
		root: {
			textTransform: 'none',
			minWidth: 72,
			// @ts-ignore
			fontWeight: theme.typography.fontWeightMedium,
			color: '#000',
			fontSize: '18px',
			fontFamily: [
				'-apple-system',
				'BlinkMacSystemFont',
				'"Segoe UI"',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'sans-serif',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"'
			].join(','),
			'&:not(:last-child)': {
				marginRight: theme.spacing(2)
			},
			'&:hover': {
				color: theme.palette.primary.main,
				opacity: 1
			},
			'&$selected': {
				color: theme.palette.primary.main,
				fontWeight: theme.typography.fontWeightMedium
			},
			'&:focus': {
				color: theme.palette.primary.main
			}
		},
		selected: {}
	});

interface StyledTabsProps {
	value: number;
	// eslint-disable-next-line @typescript-eslint/ban-types
	onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

interface StyledTabProps {
	label: string;
}

export const CustomTabs = withStyles(tabsStyles)(Tabs);
export const CustomTab = withStyles(tabStyles)((props: StyledTabProps) => (
	<Tab disableRipple {...props} />
));

interface TabPanelProps {
	children?: React.ReactNode;
	index: any;
	value: any;
}

export function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <div>{children}</div>}
		</div>
	);
}
