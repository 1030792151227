import isEmpty from 'lodash/isEmpty';
import { Company } from '../../../modules/user/reducers';

export const getDefaultCompany = (companyList: Company[]) => {
	const isThereSelected = companyList.filter((company) => company.is_selected);

	if (!isEmpty(isThereSelected)) {
		return companyList.find((company) => company.is_selected);
	}
	const activeCompany = companyList.find(
		(company) => company.company?.is_business && company.company?.status === 'ACTIVE'
	);
	if (activeCompany) return activeCompany;

	const anyCompany = companyList.find((company) => company.company?.is_business);
	if (anyCompany) return anyCompany;
	return null;
};
