import React from 'react';
import clsx from 'clsx';
import { useChatContext } from 'shared/contexts';
import { useComponentVisible, useQueryParams } from 'shared/hooks';
import { ErrorBoundary } from 'shared/components';
import { MdSwapVert } from 'react-icons/md';
import { StatusType } from './Status';
import MenuItem from '@material-ui/core/MenuItem';
import { IconButton, Tooltip } from '@material-ui/core';
import { useChangeStatus } from '../hooks/useChangeStatus';
import { useTranslation } from 'react-i18next';
import { useTranslationHelpers } from 'shared/hooks/useTranslationHelpers';

function ChangeStatusPopoverInner() {
	const { t } = useTranslation();
	const { isRTL } = useTranslationHelpers();
	const { entityType } = useChatContext();
	const { query } = useQueryParams<{ case: number; status: number }>();
	const caseId = 'case' in query ? Number(query.case) : 0;
	const statusId = 'status' in query ? Number(query.status) : 0;
	const { handleStatusChange, statuses } = useChangeStatus(caseId, statusId);

	const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

	const handleStatusModal = () => {
		setIsComponentVisible((prev) => !prev);
	};

	const closeStatusModal = () => {
		setIsComponentVisible(false);
	};

	return (
		<>
			{entityType === 'case' ? (
				<div
					className={clsx(
						'chat-inp-controls__attach-icon flex-centered relative z-20 flex-shrink-0 border-white dark:border-white/20',
						{ 'border-r': !isRTL, 'border-l': isRTL }
					)}
				>
					<div className="cursor-pointer p-3" onClick={handleStatusModal}>
						<div
							className={clsx(
								'flex-centered h-5 w-5 transition hover:text-primary dark:text-white'
							)}
						>
							<div className="icon text-xl">
								<Tooltip title={t('change_status')} placement="top">
									<IconButton className={'mr-20 p-0'}>
										<MdSwapVert />
									</IconButton>
								</Tooltip>
							</div>
						</div>
					</div>
					<div
						onClick={closeStatusModal}
						ref={ref}
						className={clsx(
							'absolute bottom-14 flex max-h-96 flex-col overflow-y-auto bg-white dark:bg-kdark-424242',
							{
								'right-0': isRTL,
								'left-0': !isRTL
							}
						)}
					>
						{isComponentVisible &&
							statuses.items &&
							(statuses.items as StatusType[]).map((status) => {
								return (
									<MenuItem
										key={status.id}
										className="flex-shrink-0 dark:text-white"
										disabled={statusId === status.id}
										onClick={async () => {
											closeStatusModal();
											await handleStatusChange(status, 'status' in query);
										}}
									>
										{status.title}
									</MenuItem>
								);
							})}
					</div>
				</div>
			) : null}
		</>
	);
}

export function ChangeStatusPopover() {
	return (
		<ErrorBoundary>
			<ChangeStatusPopoverInner />
		</ErrorBoundary>
	);
}
