import { useChatContext, useChatInputContext } from '../../../contexts';
import { useCallback, useEffect, useState } from 'react';
import { RcFile } from 'rc-upload/lib/interface';

type Props = {
	handleUpload: (file: any, type?: string) => void;
};
export function useCheckForLastPageIsFetched(props: Props) {
	const { handleUpload } = props;
	const { isLastPageFetched, isScheduledChat } = useChatContext();
	const {
		editingMessage,
		setIsDelayedMessage,
		isDelayedMessage,
		messageScheduleDate,
		handleScheduleModalToggle
	} = useChatInputContext();
	// const { handleUploadStart } = useChatUpload({ isDragging: true });
	const [tempFileList, setTempFileList] = useState<any[]>([]);

	//we need this var. to check whether last page is fetch in case or group chat. Uploading should be performed only
	// after we are sure that the latest page has been fetched.
	const lastPageIsNotFetched = !isLastPageFetched && !editingMessage;

	useEffect(() => {
		const lookup: { [key: number]: boolean } = {};

		//!isDelayedMessage - waiting for the download of last page messages
		if (!isDelayedMessage && tempFileList.length) {
			tempFileList.forEach((file: RcFile) => {
				const key = file.lastModified as number;
				if (!lookup[key]) {
					handleUpload(file);
					lookup[key] = true;
				}
			});
			setTempFileList([]);
		}
	}, [isDelayedMessage]);

	//In scheduled file upload, delayed upload is used.
	useEffect(() => {
		const lookup: { [key: number]: boolean } = {};

		//!isDelayedMessage - waiting for the download of last page messages
		if (isScheduledChat && messageScheduleDate && tempFileList.length) {
			tempFileList.forEach((file: RcFile) => {
				const key = file.lastModified as number;
				if (!lookup[key]) {
					handleUpload(file);
					lookup[key] = true;
				}
			});
			setTempFileList([]);
		}
	}, [messageScheduleDate]);

	const handleScheduledFileUpload = useCallback(
		(FileList: RcFile[], openScheduleDateModal = false) => {
			if (openScheduleDateModal) {
				handleScheduleModalToggle();
			} else {
				setIsDelayedMessage(true);
			}

			setTempFileList(FileList);
			return false;
		},
		[]
	);

	return {
		lastPageIsNotFetched,
		setIsDelayedMessage,
		setTempFileList,
		tempFileList,
		handleScheduledFileUpload
	};
}
