import { useAxios, useNotistack, useReorderEntityIds } from 'shared/hooks';
import { DropResult } from 'react-beautiful-dnd';
import { useAppState } from '../../../shared/state';
import { useTranslation } from 'react-i18next';

type ChangeCaseStatusTypes = {
	entityId: number;
	sourceIndex?: number;
	destinationIndex?: number;
	droppableId: string;
};

export function useCaseFilterDnD() {
	const { t } = useTranslation();
	const { getEntityData, modifyEntityIds, insertingEntityId } = useReorderEntityIds();
	const { companyId } = useAppState();
	const { showNotification } = useNotistack();

	const { fetchData: caseFilterReorderRequest } = useAxios({
		url: '',
		dynamicUrl: true,
		dynamicValues: true,
		method: 'post',
		cb: {
			success: (data, args) => {
				showNotification({
					message: t('reorder_success'),
					variant: 'success'
				});
			},
			error: (error, args) => {
				const { prevDestinationIds, droppableId, sourceMeta, sourceParams } = args[0];
				showNotification({ message: t('something_went_wrong'), variant: 'error' });

				modifyEntityIds({
					ids: prevDestinationIds,
					entityName: droppableId,
					entity: 'case-filters',
					meta: { ...sourceMeta, count: (sourceMeta as any).count },
					params: sourceParams
				});
			}
		}
	});

	const onFilterTemplateDragEnd = async (result: DropResult) => {
		if (process.env.NODE_ENV === 'development') {
			console.log('result onStatusDragEnd', result);
		}

		const { draggableId, destination, source } = result;

		if (!destination) return;

		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return;
		}

		await reOrderCaseFilter({
			sourceIndex: source.index,
			destinationIndex: destination.index,
			entityId: Number(draggableId),
			droppableId: source.droppableId
		});

		async function reOrderCaseFilter({
			entityId,
			sourceIndex,
			destinationIndex,
			droppableId
		}: ChangeCaseStatusTypes) {
			const {
				ids: sourceIds,
				meta: sourceMeta,
				params: sourceParams
			} = getEntityData('case-filters', droppableId);

			const { prevDestinationIds, nextDestinationIds } = insertingEntityId({
				list: sourceIds as number[],
				index: destinationIndex,
				newEntityId: entityId
			});

			//Update destination status cases in redux
			modifyEntityIds({
				ids: nextDestinationIds,
				entityName: droppableId,
				entity: 'case-filters',
				meta: { ...sourceMeta, count: (sourceMeta as any).count },
				params: sourceParams
			});

			await caseFilterReorderRequest({
				url: `${companyId}/case-filters/insert_list/`,
				values: nextDestinationIds,
				prevDestinationIds,
				droppableId,
				sourceMeta,
				sourceParams
			});
		}
	};

	return {
		onFilterTemplateDragEnd
	};
}
