import React from 'react';
import { Link } from 'react-router-dom';
import config from 'config';
import { useAppState } from '../../../state';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../interfaces';
import { getCompany } from '../../../../modules/user/selectors';
import { Company } from '../../../../modules/user/reducers';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { MainLogo } from '../../Sidebar/components/MainLogo';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const imgPath = `${config.PUBLIC_ASSETS}img/`;

export function LogoKnt() {
	const { isDarkMode, companyId, navCollapsed } = useAppState();
	const company = useSelector((state: IRootState) => getCompany(state));
	const logoSrc = isDarkMode ? 'logo-kennekt-dark-1.svg' : 'logo-kennekt-1.svg';
	const logo = isMobile ? `${imgPath}/apple-icon-120x120.png` : `${imgPath}${logoSrc}`;
	const businessLogo = company?.company?.logo?.thumbnail_150;

	return (
		<Link
			className={clsx(
				'h-full focus:outline-none focus:ring focus:ring-primary-300 dark:border-white/20',
				{
					'flex-centered border-r border-kgrey-border-solid pr-2': isMobile,
					'lg:pr-5': !navCollapsed && !businessLogo,
					'lg:pr-4': !navCollapsed && !businessLogo,
					'lg:w-[221px]': !navCollapsed,
					relative: businessLogo
				}
			)}
			to={() => makeLogoUrl(companyId, company)}
		>
			<div
				className={clsx(' ', {
					'h-6 w-6': isMobile,
					'flex-centered h-full': Boolean(company),
					'h-[18px]': !Boolean(company)
				})}
			>
				{Boolean(company) ? (
					<MainLogo />
				) : (
					<img src={logo} alt="Kennekt" className={'object-contain'} />
				)}
			</div>
			{Boolean(company) ? null : (
				<div
					className={clsx('mt-1 text-8px leading-none tracking-[4px] text-kgrey', {
						hidden: isMobile
					})}
				>
					business
				</div>
			)}
		</Link>
	);
}

function makeLogoUrl(
	companyId: number | undefined | null,
	company: Company | null | undefined
): string {
	if (company === null) return '/add-business';
	if (companyId === undefined) return '/pending-page';
	return '/';
}
