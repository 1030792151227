import React from 'react';
import Select from 'react-select';
import { FieldProps } from 'formik';

type Props = {
	options: any[];
};

export const SelectField: React.FC<Props & FieldProps> = ({ options, field, form, ...rest }) => (
	<Select
		{...rest}
		options={options}
		name={field.name}
		className="my-react-select-container"
		classNamePrefix="my-react-select-prefix"
		// value={options ? options.find((option) => option.value === field.value) : ""}
		value={field.value}
		onChange={(option) => form.setFieldValue(field.name, option)}
		onBlur={field.onBlur}
		theme={(theme) => ({
			...theme,
			colors: {
				...theme.colors,
				primary: '#00A09D'
			}
		})}
	/>
);
