export function getFileType(imgFile: File) {
	const type =
		imgFile && imgFile.type.split('/')[0].length > 0 ? imgFile.type.split('/')[0] : 'file';

	switch (type) {
		case 'image':
			return 'image';
		case 'video':
			return 'video';
		case 'audio':
			return 'audio';
		default:
			return 'file';
	}
}
