import React from 'react';
import { useAppState } from '../../../../shared/state';
import config from '../../../../config';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';

type Props = {
	isSubmitting: boolean;
	values: any;
	isThereDataFieldsError: boolean;
	memberingType: 'Extra' | 'Custom';
	membersShow: boolean;
	scrollToRequiredElement: any;
	handleCancelClick: () => void;
};
export function CreateCaseButton({
	isSubmitting,
	values,
	isThereDataFieldsError,
	memberingType,
	membersShow,
	scrollToRequiredElement,
	handleCancelClick
}: Props) {
	const { caseType } = useAppState();
	const { t } = useTranslation();

	const depIsSelectedAndThereIsSubDepToSelectAndClientType =
		caseType === config.CLIENT && values.sub_department && '1' in get(values, 'sub_department', {});

	const ifCustomThereMustBeSelectedUsers =
		memberingType === 'Custom' &&
		(values?.exception_member_list?.length === 0 || values?.exception_member_list === null) &&
		membersShow;

	const isCreateCaseButtonDisabled =
		isSubmitting ||
		depIsSelectedAndThereIsSubDepToSelectAndClientType ||
		isThereDataFieldsError ||
		ifCustomThereMustBeSelectedUsers;

	return (
		<>
			<button
				className={
					'mr-5 rounded-lg border border-kgrey  px-5 py-2 text-kgrey transition hover:border-primary hover:text-primary focus:ring focus:ring-primary'
				}
				onClick={handleCancelClick}
				type="button"
			>
				{t('cancel')}
			</button>
			<button
				className={
					'rounded-lg bg-primary-dark px-5 py-2 text-white ' +
					'border border-transparent hover:border-primary-dark' +
					' transition-colors hover:bg-white ' +
					'hover:text-primary-dark focus:ring focus:ring-primary disabled:pointer-events-none disabled:opacity-50 dark:text-white dark:hover:bg-primary-300 dark:focus:ring-primary-300'
				}
				type="submit"
				onClick={scrollToRequiredElement}
				disabled={isCreateCaseButtonDisabled}
			>
				{t('create_case')}
			</button>
		</>
	);
}
