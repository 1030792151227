import React from 'react';

export function IconCreditCard(props: any) {
	return (
		<svg width="25" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M21.5 7.596c0-.993-.059-1.296-.224-1.605a1.227 1.227 0 0 0-.519-.517c-.307-.165-.61-.224-1.603-.224H5.846c-.993 0-1.296.059-1.605.224a1.227 1.227 0 0 0-.518.519c-.165.307-.223.61-.223 1.603v8.808c0 .993.058 1.296.223 1.605.117.221.298.402.52.517.307.166.61.224 1.603.224h13.308c.993 0 1.296-.058 1.605-.224.221-.116.402-.297.517-.518.165-.308.224-.611.224-1.604V7.596Zm1.5 0v8.808c0 1.338-.14 1.823-.4 2.31-.262.49-.646.875-1.135 1.136-.489.26-.975.4-2.31.4H5.845c-1.339 0-1.823-.14-2.31-.4a2.725 2.725 0 0 1-1.136-1.135c-.261-.488-.4-.974-.4-2.31v-8.81c0-1.338.139-1.823.4-2.31.26-.491.645-.875 1.134-1.136.489-.261.975-.4 2.31-.4h13.31c1.337 0 1.822.139 2.31.4.49.261.874.645 1.135 1.134.26.489.4.975.4 2.31l.002.003Z"
				fill="#2D2D2D"
			/>
			<path d="M2 7.5h21V9H2V7.5Zm0 3h21V12H2v-1.5ZM5 15h6v1.5H5V15Z" fill="#2D2D2D" />
		</svg>
	);
}
