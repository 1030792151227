import React from 'react';
import { KntTwilioStyle } from 'shared/services';

import { useAppState } from 'shared/state';
import { AsyncSelect } from 'shared/components';
import get from 'lodash/get';
import { FormControlLabel, Radio, RadioGroup } from 'shared/components/Elements';
import { useCDAssigneeEdit } from 'pages/Cases/hooks';
import { Department } from '../../../cases.types';
import { getLastSubDepartmentId } from '../../../helpers';
import { UserType } from 'shared/typings/user.types';
import { useTranslation } from 'react-i18next';

type Props = {
	caseId: number | null;
	getCaseAccessMembers: any;
	setFieldValue: any;
	department: Department;
	subDepartments: any;
	staffAccessList: any[];
	setAssignedUsers: any;
	assignedUsers: any[] | null;
	assignRequired: boolean;
	caseAssigneeList: UserType[];
};

export function CDAssignee({
	caseId,
	setFieldValue,
	staffAccessList,
	department,
	subDepartments,
	setAssignedUsers,
	assignedUsers,
	assignRequired,
	caseAssigneeList
}: Props) {
	const { companyId } = useAppState();
	const {t} = useTranslation()

	const { assigneeTypeValue, handleAssigneeTypeChange, handleAssignedMember } = useCDAssigneeEdit({
		caseId,
		staffAccessList,
		setFieldValue,
		setAssignedUsers,
		assignedUsers,
		caseAssigneeList
	});

	return (
		<div className="w-[336px]">
			<div>
				<RadioGroup
					aria-label="assignee-user"
					name="assignee-type"
					value={assigneeTypeValue}
					onChange={handleAssigneeTypeChange}
					className="!flex-row"
				>
					<FormControlLabel value="all" control={<Radio color="primary" />} label={t('all')} />
					<FormControlLabel
						value="department"
						control={<Radio color="primary" />}
						label={t('department_members')}
					/>
				</RadioGroup>
			</div>
			{/* @ts-ignore */}
			<AsyncSelect
				customStylesProps={KntTwilioStyle}
			
				name="assignee"
				placeholder={t('select_user_to_assign')}
				optionValue={(option: any) => (option?.user ? option?.user?.id : option.id)}
				optionLabel={(option: any) => {
					return option?.user
						? `${get(option, 'user.first_name')} ${get(option, 'user.last_name')}`
						: `${get(option, 'first_name')} ${get(option, 'last_name')}`;
				}}
				loadOptionsUrl={
					assigneeTypeValue === 'all'
						? `/${companyId}/members/`
						: department &&
						  subDepartments &&
						  `/${companyId}/departments/${getLastSubDepartmentId(
								department,
								subDepartments
						  )}/users/`
				}
				loadOptionsParams={(search: string) => ({
					limit: 50,
					extra: {
						search
					}
				})}
				loadOptionsKey="results"
				isSearchable
				isClearable
				isDisabled={!department && assigneeTypeValue === 'department'}
				alwaysRefetch
				customStyles="KntTwilioStyle"
				loadOptionsOnMenuOpen
				isMulti
				value={caseAssigneeList}
				filterOption={(option: any) => {
					if (caseAssigneeList.length) {
						return !caseAssigneeList
							?.reduce(
								(acc: any, member: any) => [...acc, member?.user ? member?.user?.id : member?.id],
								[]
							)
							.includes(option?.data?.user?.id);
					} else {
						return true;
					}
				}}
				onChange={(option: any) => {
					handleAssignedMember(option);
				}}
			/>
			{!caseAssigneeList?.length && assignRequired ? (
				<div className="mt-1 text-xs text-red-500">{t('assignee_required_validation')}</div>
			) : null}
		</div>
	);
}
