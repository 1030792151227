import React, { useState } from 'react';
import { useAxios, useNotistack, useQueryParams } from 'shared/hooks';
import { useAppState } from 'shared/state';
import Actions from 'store/actions';
import { useDispatch } from 'react-redux';
import { useSocketHelpers } from '../../../shared/userSocket/hooks/useSocketHelpers';
import { useTranslation } from 'react-i18next';

const UpdateEntities = Actions.entities.updateEntitiesSuccess;

type Pin = null | string | number;

export function useCaseMenu(
	pin_time: Pin,
	caseId: number,
	isFromChat = false,
	statusId?: number,
	searchedMessageId?: number | null
) {
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const { companyId } = useAppState();
	const { getEntityData, liftEntityToTop, moveEntityIdToBeginning } = useSocketHelpers();
	const { showNotification } = useNotistack();
	const open = Boolean(anchorEl);
	const { changeUrlParams } = useQueryParams();

	const [anchorElAssignee, setAnchorElAssignee] = React.useState<HTMLButtonElement | null>(null);

	const handleClickAssignee = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorElAssignee(event.currentTarget);
	};

	const handleCloseAssignee = () => {
		setAnchorElAssignee(null);
	};

	const handleChangeCasePriority = (caseId: number, statusId: number) => {
		changeUrlParams({
			statusId,
			caseId,
			fromCaseItem: 'true'
		});
	};

	const openAssignee = Boolean(anchorElAssignee);

	const dispatch = useDispatch();

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const caseEntityId = searchedMessageId ?? caseId;

	//pin and unpin handler
	const { fetchData: pinCase } = useAxios({
		url: `/${companyId}/cases/as_business/${caseId}/pin/`,
		method: `${pin_time ? 'delete' : 'post'}`,
		cb: {
			success: (data, args) => {
				const [isPinning] = args;

				//To move case to the top of status case list
				if (isPinning) {
					const { ids, params, entityName, entity, meta } = getEntityData('case', statusId);
					const newEntityIds = moveEntityIdToBeginning(ids as number[], caseEntityId);

					liftEntityToTop(newEntityIds, entity, entityName, params, meta);
				}

				dispatch(
					UpdateEntities({
						entity: 'cases',
						entityId: String(caseEntityId),
						data: {
							pin_time: isPinning ? Date.now() : null
						}
					})
				);
				// if (isFromChat) {
				// 	dispatch(
				// 		UpdateEntities({
				// 			entity: 'cases',
				// 			entityId: String(caseEntityId),
				// 			data: {
				// 				pin_time: isPinning ? Date.now() : null
				// 			}
				// 		})
				// 	);
				// }
			},
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			error: (error) => {}
		}
	});

	//pin and unpin handler
	const { fetchData: requestUpdate } = useAxios({
		url: `/${companyId}/cases/as_business/${caseId}/notify_if_not_answered/`,
		method: 'post',
		cb: {
			success: (data, args) => {
				showNotification({ message: t('requested_update_success'), variant: 'info' });
			},
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			error: (error) => {}
		}
	});

	const handleMenuItemClick = (menuType: string) => {
		switch (menuType) {
			case 'pin': {
				pinCase(true);
				break;
			}
			case 'unpin': {
				pinCase(false);
				break;
			}
			case 'manager': {
				requestUpdate();
				break;
			}
			default: {
				return null;
			}
		}
	};

	function handleCloseMenu() {
		setAnchorEl(null);
	}

	return {
		// setAnchorEl,
		// requestUpdate,
		pinCase,
		open,
		handleClick,
		anchorEl,
		handleMenuItemClick,
		handleCloseMenu,
		openAssignee,
		handleClickAssignee,
		handleCloseAssignee,
		anchorElAssignee,
		handleChangeCasePriority
	};
}
