import { loadAllRequest } from 'modules/entity/actions';
import { useDispatch } from 'react-redux';
import { QueryType } from 'shared/interfaces';

type EntityAll = {
	entity: string;
	name: string;
	url: string;
	params?: QueryType;
	primaryKey?: string;
	relations?: string;
	dataKey?: string | ((data: any) => any);
	metaKey?: string;
	appendData?: boolean;
	prependData?: boolean;
	infiniteScroll?: boolean;
	isUniq?: boolean;
	reverse?: boolean;
	cb?: {
		success: (data: any) => void;
	};
	replaceIds?: boolean;
};

export function useLoadAll() {
	const dispatch = useDispatch();
	function reloadEntityAll({
		entity,
		name,
		url,
		params = {
			page: 1,
			limit: 10
		},
		primaryKey = 'id',
		relations = '',
		dataKey = 'results',
		metaKey = '',
		appendData = false,
		prependData = true,
		infiniteScroll = true,
		isUniq = true,
		reverse = false,
		cb = {
			success: (data) => {}
		},
		replaceIds = true
	}: EntityAll) {
		dispatch(
			loadAllRequest({
				entity,
				name,
				url,
				params,
				primaryKey,
				relations,
				dataKey,
				metaKey,
				appendData,
				prependData,
				infiniteScroll,
				isUniq,
				reverse,
				cb,
				replaceIds
			})
		);
	}

	return { reloadEntityAll };
}
