import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { SnackbarProps } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
function Alert(props: AlertProps) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export type Color = 'success' | 'info' | 'warning' | 'error';

type Props = {
	severity: Color | undefined;
	handleClose: (event?: React.SyntheticEvent, reason?: string) => void;
} & SnackbarProps;

export const Notification: React.FC<Props> = ({
	severity,
	handleClose,
	message,
	...rest
}: Props) => {
	return (
		<Snackbar {...rest} onClose={handleClose}>
			<Alert onClose={handleClose} severity={severity}>
				{message}
			</Alert>
		</Snackbar>
	);
};
