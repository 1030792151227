import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { Button } from '../../Elements';
import React from 'react';
import { useActivationButtonController } from 'pages/Cases/hooks';
import { Loader } from '../../index';
import { IMessage } from 'shared/interfaces';

type Props = {
	acceptLink: string;
	rejectLink: string;
	message: IMessage;
};

export function ActivationButtons({ acceptLink, rejectLink, message }: Props) {
	const { sendBusinessActivationRequest, isBusinessActivationLoading } =
		useActivationButtonController();

	return (
		<div className="mt-3.5 flex gap-x-3">
			<Button
				variant={'contained'}
				color={'primary'}
				disabled={isBusinessActivationLoading}
				onClick={() => sendBusinessActivationRequest(acceptLink, message.custom_uuid)}
			>
				<span
					className={clsx('text-white', {
						'text-base': !isMobile,
						'text-xs': isMobile
					})}
				>
					Accept
				</span>
				{isBusinessActivationLoading && <Loader tiled size={24} />}
			</Button>
			<Button
				variant={'contained'}
				color={'secondary'}
				disabled={isBusinessActivationLoading}
				onClick={() => sendBusinessActivationRequest(rejectLink, message.custom_uuid)}
			>
				<span className={clsx('text-white', { 'text-base': !isMobile, 'text-xs': isMobile })}>
					Reject
				</span>
				{isBusinessActivationLoading && <Loader tiled size={24} />}
			</Button>
		</div>
	);
}
