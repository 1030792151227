/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
	useNotistack,
	useQueryParams,
	useWindowSize,
	useChatWindowResize,
	useRouter
} from 'shared/hooks';
import { FC, lazy, useEffect, useRef, useState } from 'react';
import { HeaderKnt, Sidebar } from 'shared/components';
import { useAppState } from 'shared/state';
import useDimensions from 'react-use-dimensions';
import { isMobile } from 'react-device-detect';
import { CaseChat } from 'pages/Cases/chat-components/CaseChat';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import { FaGripLinesVertical } from 'react-icons/fa';
import { ChatPanelWrapper } from '../components/Chat/components';
import { useTranslationHelpers } from 'shared/hooks/useTranslationHelpers';

const SIDEBAR_WIDTH = 285;
const SIDEBAR_WIDTH_COLLAPSED = 61;
const SIDEBAR_WIDTH_DIFFERENCE = 224;

export const MainLayout: FC = ({ children }) => {
	const { isRTL } = useTranslationHelpers();
	const [seeAllRef, { height: headerHeight }] = useDimensions();
	const [chatPanelRef, { width: chatPanelWidth }] = useDimensions();
	const {
		hasVScroll,
		navCollapsed,
		toggleChatPanel,
		chatPanelToggled,
		networkState,
		isInternetDisconnected
	} = useAppState();
	const { query } = useQueryParams();
	const { width: windowWidth, height: windowHeight } = useWindowSize();
	const { path } = useRouter();
	const { showNotification } = useNotistack();
	const [chatPanelUpdatedWidth, setChatPanelUpdatedWidth] = useState(0);
	const [calculatedValueForCss, setCalculatedValueForCss] = useState(0);
	const dragButtonRef = useRef<HTMLButtonElement>(null);
	const isCheckoutPage = path === '/checkout';

	//Chat panel resize logics
	useChatWindowResize(setChatPanelUpdatedWidth, dragButtonRef);

	//Show notification when internet has been disconnected
	useEffect(() => {
		if (networkState === 'offline' && !isInternetDisconnected) {
			showNotification({
				message: 'Your internet is disconnected',
				variant: 'error'
			});
		}
		if (isInternetDisconnected && networkState === 'online') {
			showNotification({
				message: 'Internet connection recovered'
			});
		}
	}, [networkState, isInternetDisconnected]);

	useEffect(() => {
		setChatPanelUpdatedWidth(chatPanelWidth);
	}, [chatPanelWidth]);

	useEffect(() => {
		//For dynamically set css values when show and hide chatpanel and set marginRight for cases wrapper
		const navIsMinimizedAndChatIsNotOpen = navCollapsed && isEmpty(query);
		setCalculatedValueForCss(
			navIsMinimizedAndChatIsNotOpen
				? chatPanelUpdatedWidth + SIDEBAR_WIDTH_DIFFERENCE
				: chatPanelUpdatedWidth
		);
		// setCalculatedValueForCss(chatPanelUpdatedWidth);
	}, [chatPanelUpdatedWidth, navCollapsed, query]);
	useEffect(() => {
		if (isEmpty(query) && chatPanelToggled) {
			toggleChatPanel(false);
		}
	}, [query]);

	// const windowHeight = useHeight(false) as number;

	// console.log('size info', {
	// 	chatPanelWidth,
	// 	windowWidth,
	// 	isMobile,
	// 	chatPanelToggled,
	// 	windowHeight,
	// 	navCollapsed
	// });
	// console.log({ calculatedValueForCss, chatPanelUpdatedWidth, query });

	const renderPage = () => {
		if (hasVScroll) {
			return (
				<div
					css={css`
						height: ${windowHeight && windowHeight - headerHeight}px;
					`}
				>
					{children}
				</div>
			);
		}
		return (
			<div
				css={css`
					height: ${windowHeight && windowHeight - headerHeight}px;
					overflow-x: hidden;
					overflow-y: auto;
				`}
			>
				{children}
			</div>
		);
	};
	return (
		<div
			className={clsx('inner-wrapper overflow-y-hidden', {
				'h-screen': !isMobile
			})}
			{...(isMobile ? { style: { height: `${windowHeight}px` } } : {})}
		>
			<div
				className="app-header h-[40px] border-b border-kgrey-border-solid pl-2 pr-2 transition-colors dark:border-white/20 dark:bg-kdark lg:h-[60px] lg:pl-4 lg:pr-4"
				ref={seeAllRef}
			>
				{<HeaderKnt />}
			</div>
			<div
				className="case-wrapper flex"
				css={css`
					height: ${windowHeight && windowHeight - headerHeight}px;
				`}
			>
				<Sidebar />
				<div
					className={clsx('app-container relative flex-grow overflow-hidden dark:bg-kdark')}
					css={css`
						width: ${windowWidth &&
						windowWidth -
							(isMobile ? 0 : navCollapsed ? SIDEBAR_WIDTH_COLLAPSED : SIDEBAR_WIDTH)}px;
					`}
				>
					<div
						className={clsx('rct-app-content')}
						css={css`
							${isRTL ? 'margin-left' : 'margin-right'}: ${chatPanelToggled && query?.case
								? navCollapsed
									? calculatedValueForCss //calculatedValueForCss - 75
									: calculatedValueForCss
								: 0}px;
						`}
					>
						<div className={clsx('rct-page', { 'bg-kgrey-bgmenu dark:bg-kdark': isCheckoutPage })}>
							<div className="rct-page">{renderPage()}</div>
						</div>
						<ChatPanelWrapper calculatedValueForCss={calculatedValueForCss} ref={chatPanelRef}>
							<button
								className={clsx(
									'chat-panel__resize-handler absolute  top-1/2 z-10 hidden w-3 shadow-chat-panel ' +
										'flex-centered h-12 rounded-lg border bg-white text-white ' +
										'border-border-solid border-1 border-dotted text-kgrey hover:border-primary hover:text-primary',
									{
										'lg:block': chatPanelToggled && !isEmpty(query) && !query?.isScheduledChat,
										'-right-1': isRTL,
										'-left-1 ': !isRTL
									}
								)}
								type={'button'}
								ref={dragButtonRef}
							>
								<div className={'relative text-xl'}>
									<FaGripLinesVertical className={'absolute left-[-5px] top-[-10px]'} />
								</div>
							</button>
							{query?.isScheduledChat ? (
								<div className="chat-panel__content h-full w-full border-l border-kgrey-msg/20 dark:border-white/20">
									<CaseChat />
								</div>
							) : (
								<>
									{/* In this line, there was chat toggle button that was removed previously */}
									<div className="chat-panel__content h-full w-full border-l border-kgrey-msg/20 dark:border-white/20">
										<CaseChat />
									</div>
								</>
							)}
						</ChatPanelWrapper>
					</div>
				</div>
			</div>
		</div>
	);
};
