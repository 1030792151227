import React from 'react';

export function IconAssigneeUser({ className = '' }: { className?: string }) {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="currentColor"
			className={className}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.9933 11.4467C14.5007 11.4467 16.5333 9.41407 16.5333 6.9067C16.5333 4.39933 14.5007 2.3667 11.9933 2.3667C9.48596 2.3667 7.45333 4.39933 7.45333 6.9067C7.45333 9.41407 9.48596 11.4467 11.9933 11.4467Z"
				fill="currentColor"
			/>
			<path
				d="M8 17.7667C8.01386 17.3979 8.13676 17.0414 8.35318 16.7424C8.56959 16.4433 8.8698 16.2152 9.21586 16.0868C9.56192 15.9583 9.93828 15.9354 10.2974 16.0208C10.6565 16.1062 10.9822 16.2962 11.2333 16.5667L13.8067 19.3334L18.3667 14.2467C16.483 12.9914 14.2634 12.336 12 12.3667C10.4299 12.327 8.8702 12.6317 7.4305 13.2594C5.9908 13.8872 4.70608 14.8226 3.66667 16.0001C3.57874 16.1173 3.53188 16.2602 3.53334 16.4067V20.0001C3.53318 20.3468 3.66815 20.6801 3.90961 20.929C4.15107 21.1779 4.48002 21.323 4.82667 21.3334H10.54L8.49334 19.1334C8.32424 18.9499 8.19349 18.7345 8.10877 18.4998C8.02406 18.2651 7.98708 18.0159 8 17.7667Z"
				fill="currentColor"
			/>
			<path
				d="M19.1733 21.3333C19.52 21.3229 19.8489 21.1778 20.0904 20.9289C20.3318 20.68 20.4668 20.3468 20.4667 20V17.4933L17.0467 21.3333H19.1733Z"
				fill="currentColor"
			/>
			<path
				d="M22.5133 12.4134C22.4479 12.3542 22.3714 12.3087 22.2882 12.2793C22.2051 12.25 22.1169 12.2374 22.0289 12.2424C21.9408 12.2473 21.8546 12.2697 21.7753 12.3082C21.696 12.3467 21.625 12.4006 21.5667 12.4667L13.82 21.1334L10.3533 17.4067C10.2964 17.3402 10.227 17.2855 10.1489 17.2458C10.0709 17.2061 9.98581 17.1821 9.89852 17.1753C9.81123 17.1685 9.72346 17.179 9.64021 17.2061C9.55696 17.2332 9.47987 17.2765 9.41333 17.3334C9.35085 17.3953 9.30125 17.4691 9.2674 17.5503C9.23356 17.6316 9.21613 17.7187 9.21613 17.8067C9.21613 17.8947 9.23356 17.9818 9.2674 18.0631C9.30125 18.1443 9.35085 18.2181 9.41333 18.28L13.8667 23.08L22.56 13.3334C22.6721 13.2035 22.7298 13.0354 22.7211 12.864C22.7124 12.6927 22.638 12.5312 22.5133 12.4134Z"
				fill="currentColor"
			/>
		</svg>
	);
}
