/**@jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useChatHelpers, useDraftMessage } from 'shared/hooks';
import { useChatContext, useChatInputContext } from 'shared/contexts';
import { HtmlParser } from 'shared/components';
import { useAppState } from '../../../state';
import { AiFillEdit } from 'react-icons/ai';
import { CgCloseO } from 'react-icons/cg';
import { useTranslation } from 'react-i18next';
import { convertToLocalizationKey } from 'shared/services/utils/convertToLocalizationKey';

type Props = {
	handleEditReplyCancelling: (isCancelling: boolean) => void;
};

export function EdiMessage({ handleEditReplyCancelling }: Props) {
	const { renderTextMessage } = useChatHelpers();
	const { entityId } = useChatContext();
	const { setEditingMessage, editingMessage /*setReplyEditCancel*/ } = useChatInputContext();
	const { activeDraft, setActiveDraft } = useAppState();
	const { updateEntityDraft, removeLS, sendDeleteDraft, getLS } = useDraftMessage();
	const draftObject = getLS();
	const { t } = useTranslation();

	const editMessageClickHandler = () => {
		setEditingMessage(null);
		handleEditReplyCancelling(true);

		// setTimeout(() => handleEditReplyCancelling(false), 500);
		// removeLS(entityId, () => {
		// 	updateEntityDraft('reset');
		// 	// sendDeleteDraft();
		// });

		if (activeDraft || entityId in draftObject) {
			updateEntityDraft('reset');
			setActiveDraft(null);
			removeLS(entityId);
			sendDeleteDraft();
		}
	};

	return (
		<>
			{editingMessage ? (
				<div className="relative z-10 flex w-full items-center overflow-hidden rounded-t-lg bg-white p-2 dark:bg-kdark-dark333">
					<div className="icon dark:text-white">
						<AiFillEdit />
					</div>
					<div
						className="px-3"
						css={css`
							width: calc(100% - 32px);
						`}
					>
						<div className="msg-author text-xs italic text-kgrey">{t('edit_message')}</div>
						<div className="msg-itself truncate text-sm dark:text-white">
							{editingMessage.text
								? renderTextMessage(editingMessage.text, HtmlParser)
								: t(convertToLocalizationKey(`${editingMessage?.fileType}_message`))}
						</div>
					</div>
					<div
						className="close flex-shrink-0 cursor-pointer transition hover:text-primary dark:text-white"
						onClick={editMessageClickHandler}
					>
						<CgCloseO />
					</div>
				</div>
			) : null}
		</>
	);
}
