// import { csvFileValidator } from "./index";
import { isEmpty } from 'lodash/isEmpty';

const requiredError = (headerName, rowNumber, columnNumber) => {
	return `<div class="red">${headerName}  is required in <strong>row ${rowNumber}</strong> / <strong>column ${columnNumber}</strong></div>`;
};
const roleValidateError = (headerName, rowNumber, columnNumber) => {
	return `<div class="red">${headerName}  must be one of "admin | manager | member" in <strong>row ${rowNumber}</strong> / <strong>column ${columnNumber}</strong></div>`;
};
const validateErrorClient = (headerName, rowNumber, columnNumber, data) => {
	const header =
		headerName === 'Client Email' ? 'Email' : headerName === 'Client Phone' ? 'Phone' : headerName;
	return `<div class="red"><strong>Row ${rowNumber - 1}</strong>: ${header} for <strong>${
		data[rowNumber - 1][1]
	} ${data[rowNumber - 1][2]}</strong> is not valid.</div>`;
};
const validateErrorBusiness = (headerName, rowNumber, columnNumber, data) => {
	const header =
		headerName === 'Staff Email' ? 'Email' : headerName === 'Staff Phone' ? 'Phone' : headerName;
	return `<div class="red"><strong>Row ${rowNumber - 1}</strong>: ${header} for <strong>${
		data[rowNumber - 1][0]
	} ${data[rowNumber - 1][1]}</strong> is not valid.</div>`;
};
const uniqueError = (headerName) => {
	return `<div class="red">${headerName} is not unique</div>`;
};
const isPhoneValid = function (phone) {
	// eslint-disable-next-line no-useless-escape
	// const reqExp = /((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/g;
	// const reqExp = /((\(\d{3}\)?)|(\d{3}))([\s-./]?)(\d{3})([\s-./]?)(\d{4})/;
	const reqExp =
		/^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i;
	return reqExp.test(phone);
};
const isEmailValid = function (email) {
	const reqExp =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return reqExp.test(email);
};

const isRoleValid = function (role) {
	return ['manager', 'member', 'admin'].includes(role);
};

const clientCSVConfig = {
	headers: [
		{
			name: 'Client Business Name',
			inputName: 'clientBusinessName',
			required: true,
			requiredError
		},
		{ name: 'First Name', inputName: 'firstName', required: true, requiredError },
		{
			name: 'Last Name',
			inputName: 'lastName',
			required: true,
			requiredError,
			unique: true,
			uniqueError
		},
		{
			name: 'Email',
			inputName: 'email',
			required: true,
			requiredError,
			unique: true,
			uniqueError,
			validate: isEmailValid,
			validateError: validateErrorClient
		},
		{
			name: 'Phone',
			inputName: 'phone',
			required: true,
			requiredError,
			unique: false,
			uniqueError,
			validate: isPhoneValid,
			validateError: validateErrorClient
		},
		{ name: 'Role', inputName: 'role', required: true },
		{ name: 'Admin Privilage', inputName: 'privilige', required: true }
	]
};

const employeeCSVConfig = {
	headers: [
		{ name: 'First Name', inputName: 'firstName', required: true, requiredError },
		{
			name: 'Last Name',
			inputName: 'lastName',
			required: true,
			requiredError,
			unique: true,
			uniqueError
		},
		{
			name: 'Email',
			inputName: 'email',
			required: true,
			requiredError,
			unique: true,
			uniqueError,
			validate: isEmailValid,
			validateError: validateErrorBusiness
		},
		{
			name: 'Phone',
			inputName: 'phone',
			required: true,
			requiredError,
			unique: false,
			uniqueError,
			validate: isPhoneValid,
			validateError: validateErrorBusiness
		},
		{
			name: 'Role',
			inputName: 'role',
			required: true,
			validate: isRoleValid,
			validateError: roleValidateError
		},
		{ name: 'Admin Privilage', inputName: 'privilige', required: true }
	]
};

const validateCsv = ({
	file,
	Papa,
	configHeaderLength,
	CSVConfig,
	setCsvData,
	setCsvPrimaryCheckError,
	setLoadingImage,
	moreTxt,
	lessTxt,
	NotificationManager,
	csvFileValidator
}) => {
	if (file.name.slice(-3).toLowerCase() === 'csv') {
		return new Promise((resolve, reject) => {
			Papa.parse(file, {
				complete: (parsedOutput) => {
					const { data } = parsedOutput;
					const dataColumnLength = data[0].length;

					//Check if there required length of columns. If it passes than we validate fields.
					if (dataColumnLength === configHeaderLength) {
						// we do csv validation
						// CSVFileValidator(file, CSVConfig).then((csvData) => {
						csvFileValidator(file, CSVConfig).then((csvData) => {
							if (isEmpty(csvData.inValidMessages)) {
								resolve(file);
							} else {
								setCsvData(
									csvData.inValidMessages
										.map((message) =>
											message.startsWith('<div') ? message : `<div class="red">${message}</div>`
										)
										.join('')
								);
								reject(new Error('Invalid csv file'));
								setLoadingImage(false);
							}
						});
					} else {
						if (dataColumnLength > configHeaderLength) {
							setCsvPrimaryCheckError(moreTxt);
						} else if (dataColumnLength < configHeaderLength) {
							setCsvPrimaryCheckError(lessTxt);
						}
						reject(new Error('Invalid csv file'));
						setLoadingImage(false);
					}
				}
			});
		});
	} else {
		setLoadingImage(false);
		NotificationManager.error('Invalid file type. Download please sample of file csv');
		return false;
	}
};

export default {
	employeeCSVConfig,
	clientCSVConfig,
	validateCsv
};
