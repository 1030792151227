import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { HtmlParser } from 'shared/components';
import { useChatHelpers } from 'shared/hooks';
import { IFileMsgType, IMessage } from 'shared/interfaces';

type Props = {
	text: string;
	fileType: string | null;
	fileUrl?: string | null;
	contact: IMessage['contact'];
	location: IMessage['location'];
	messageId?: number;
};

export function DeletedMessageContent({
	text,
	fileType,
	fileUrl,
	contact,
	location,
	messageId
}: Props) {
	const { renderTextMessage } = useChatHelpers();
	const { t } = useTranslation();
	const { long, lat } = location ?? {};
	const locationName = location?.formatted_name;

	const downloadFileHandler = useCallback(
		(fileUrl: string, fileType: IFileMsgType) => async () => {
			let blobUrl = null;
			const downloadLink = document.createElement('a');

			if (fileType === 'AUDIO') {
				const fileResponse = await fetch(fileUrl);
				const fileBlob = await fileResponse.blob();

				blobUrl = URL.createObjectURL(fileBlob);
				downloadLink.download = `audio_${messageId}.mp3`;
			}

			downloadLink.target = '_blank';
			downloadLink.href = blobUrl ?? fileUrl;
			downloadLink.style.display = 'none';

			document.body.append(downloadLink);
			downloadLink.click();

			document.body.removeChild(downloadLink);
		},
		[]
	);

	if (location) {
		return (
			<div>
				<div className="flex max-w-full">
					<strong>{t('address')}: </strong>
					<div className="ml-2 break-words">{locationName}</div>
				</div>
				<dl>
					<div className="flex-center-y">
						<dt className="mr-2 font-bold">Longitude: </dt>
						<dd>{long}</dd>
					</div>
					<div className="flex-center-y">
						<dt className="mr-2 font-bold">Latitude: </dt>
						<dd>{lat}</dd>
					</div>
				</dl>
			</div>
		);
	}

	//Handle contact message
	if (contact) {
		return (
			<div>
				{contact?.phone_number?.map((num, index) => {
					return (
						<div className={'tel-contact mb-2'} key={index}>
							<span>
								{t('name')}: <span className={'ml-5'}>{contact?.name}</span>
							</span>
							<div className={'flex items-center'}>
								<span>{t('phone')}:</span>
								<a className={'ml-5 hover:text-primary'} href={`tel:${num}`} key={index}>
									{num}
								</a>
							</div>
						</div>
					);
				})}
			</div>
		);
	}

	//Handle file messages
	if (fileType && fileUrl) {
		return (
			<a
				onClick={downloadFileHandler(fileUrl, fileType as IFileMsgType)}
				className="cursor-pointer text-kblue underline hover:no-underline"
			>
				{t('download_file_to_view')}
			</a>
		);
	}
	//Handle not available message
	if (!fileUrl && !text) {
		return <div>{t('unavailable_message')}</div>;
	}

	return (
		<>
			{text
				? renderTextMessage(text, HtmlParser)
				: `${fileType?.[0]}${fileType?.toLowerCase()?.slice(1)} message`}
		</>
	);
}
