import Scrollbars, { ScrollbarProps } from 'react-custom-scrollbars-2';

export function KntScrollBars(props: ScrollbarProps) {
	return (
		<Scrollbars
			{...props}
			renderThumbVertical={(props) => (
				<div {...props} style={{ ...props.style, backgroundColor: 'rgba(255, 255, 255, 0.3)' }} />
			)}
		>
			{props.children}
		</Scrollbars>
	);
}
