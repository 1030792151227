import { useEffect, useRef } from 'react';
import { useQueryParams } from '../../../shared/hooks';
import { StatusType } from '../components/Status';

export function useStatusCaseList(status: StatusType) {
	const { query } = useQueryParams();
	const elRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const isActive = query && Number(query.status) === status.id;
		if (isActive) elRef?.current?.scrollIntoView({ behavior: 'smooth', inline: 'start' });
	}, [query, status.id]);

	return {};
}
