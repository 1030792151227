import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { IoMdCalendar } from 'react-icons/io';
import { convertToLocalizationKey } from 'shared/services/utils/convertToLocalizationKey';
import { SchedulingPresetDate } from '../hooks/useScheduleDatePick';
import dayjs from 'dayjs';

type Props = {
	handleSelectPresetDateTime: (presetDate: SchedulingPresetDate) => void;
	selectedPresetDate: SchedulingPresetDate | null;
	scheduleError: {
		error: boolean;
		message: string;
	} | null;
	toggleOpenCustomDate: () => void;
};

const AFTERNOON_VALUE_IN_HOURS = 13;

export default function SchedulePresetDatePicker(props: Props) {
	const SCHEDULE_PRESET_VALUES = [
		{
			id: 1,
			title: 'Tomorrow morning',
			schedulingDate: dayjs().add(1, 'day').hour(8).minute(0).second(0),
			isHidden: false
		},
		{
			id: 2,
			title: 'Tomorrow afternoon',
			schedulingDate: dayjs().add(1, 'day').hour(13).minute(0).second(0),
			isHidden: new Date().getHours() < AFTERNOON_VALUE_IN_HOURS
		},
		{
			id: 3,
			title: 'Today afternoon',
			schedulingDate: dayjs().hour(13).minute(0).second(0),
			isHidden: new Date().getHours() >= AFTERNOON_VALUE_IN_HOURS
		},
		{
			id: 4,
			title: 'Monday morning',
			schedulingDate: dayjs().day(1).add(1, 'week').hour(8).minute(0).second(0),
			isHidden: false
		}
	];

	const { t } = useTranslation();

	const {
		handleSelectPresetDateTime,
		selectedPresetDate,
		scheduleError,
		toggleOpenCustomDate
	} = props;

	return (
		<div className="schedule-preset-date__wrapper">
			<h3 className="schedule-preset-date-header__text flex-1 pb-5 text-xl font-semibold">
				{t('schedule_send')}
			</h3>
			<div className="schedule-preset-date__content">
				{SCHEDULE_PRESET_VALUES.filter((presetDate) => !presetDate.isHidden).map(
					(presetData, idx) => (
						<div
							key={idx}
							onClick={() => handleSelectPresetDateTime(presetData)}
							className={clsx(
								'preset-content__item flex cursor-pointer items-center justify-between py-2 text-base',
								{
									'bg-primary-dark px-1 text-white': selectedPresetDate?.id === presetData.id
								}
							)}
						>
							<div className="preset-item__title">
								{t(convertToLocalizationKey(presetData.title))}
							</div>
							<div
								className={clsx('preset-item__date text-kgrey-dark-1', {
									'!text-white': selectedPresetDate?.id === presetData.id
								})}
							>
								{dayjs(presetData.schedulingDate.toDate()).format('MMM D, h:mm A')}
							</div>
						</div>
					)
				)}
			</div>
			{scheduleError ? (
				<div className="schedule-preset-date__error w-full border-b-1px border-kred-red pb-1 text-sm text-kred-red">
					{scheduleError.message}
				</div>
			) : null}
			<div
				className={clsx('schedule-preset-date__footer border-t-1px border-kgrey py-3', {
					'mt-4': !scheduleError
				})}
			>
				<div
					onClick={toggleOpenCustomDate}
					className="footer__open-custom-date flex cursor-pointer items-center gap-x-2 hover:text-primary"
				>
					<IoMdCalendar />
					<div>{t('choose_date_and_time')}</div>
				</div>
			</div>
		</div>
	);
}
