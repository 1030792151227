import { useCallback, useEffect } from 'react';
import storage from 'shared/services/storage';
import { useChatContext, useChatInputContext } from 'shared/contexts';

export function useForwardMessageForSchedule() {
	const { entityId, isScheduledChat } = useChatContext();
	const { setMessage } = useChatInputContext();

	const handleSaveMessageForSchedule = useCallback((message: string, entityId: number) => {
		const storedScheduledMsgPayload = {
			entityId,
			message
		};

		storage.set('message_for_schedule', JSON.stringify(storedScheduledMsgPayload));
	}, []);

	//Gets scheduled_message from localstorage and sets it as a message
	useEffect(() => {
		if (isScheduledChat) {
			const storedScheduledMessage = JSON.parse(storage.get('message_for_schedule') ?? '{}');

			if (storedScheduledMessage?.entityId === entityId) {
				setMessage(storedScheduledMessage?.message);
			}
		}
	}, [isScheduledChat, entityId]);

	return { handleSaveMessageForSchedule };
}
