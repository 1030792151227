import isEmpty from 'lodash/isEmpty';
import { useAxios } from './useAxios';
import { parseUnreadMessages } from './useInitialRequests';
import { UnreadMessageType, useAppState } from '../state';
import { AxiosError } from 'axios';
import { useCallback } from 'react';

type AllCompanyUnreadDataShape = {
	unread_messages_by_chats: Record<string, number>;
	unread_messages_by_client_cases: Record<string, number>;
	unread_messages_by_staff_cases: Record<string, number>;
	unread_cases_by_status: Record<string, number>;
	has_unread_cases: boolean;
	has_unread_chats: boolean;
};

type FetchAllCompanyUnreadCountsParamOptions = {
	groupUnread?: boolean;
	caseUnread?: boolean;
};

type ExtraParamType = {
	count: string;
	by_status: string;
	entity_type?: 'ims' | 'case';
};

function hasUnreadMessages(unreadObject: UnreadMessageType): boolean {
	return !isEmpty(unreadObject);
}

export function useUnreadCount() {
	const {
		companyId,
		setHasUnread,
		setUnreadMessagesCount,
		setAreUnreadsFetched,
		setUnreadLoadingError,
		setUnreadCasesCountByStatus,
		setGroupUnreadMessage
	} = useAppState();

	// case unread messages count fetcher for Staff cases
	const { fetchData: fetchStaffUnreadMessagesCount } = useAxios({
		url: `/${companyId}/message_counts/`,
		params: {
			extra: {
				as_business_company: 'true',
				as_client_company: 'true',
				case_type: 'staff'
			}
		},
		cb: {
			success: (data, args) => {
				const parsedData = parseUnreadMessages(data);
				setUnreadMessagesCount(parsedData, !args.length);
				setHasUnread({ type: 'staff', value: hasUnreadMessages(parsedData) });
				setAreUnreadsFetched({ type: 'staff', value: true });
			},
			error: (error: AxiosError) => {
				// eslint-disable-next-line no-console
				console.error('fetchStaffUnreadMessagesCount', error);
				setUnreadLoadingError(error.message);
			}
		}
	});

	// case unread messages count fetcher for Staff cases
	const { fetchData: fetchClientUnreadMessagesCount } = useAxios({
		url: `/${companyId}/message_counts/`,
		params: {
			extra: {
				as_business_company: 'true',
				case_type: 'client'
			}
		},
		cb: {
			success: (data, args) => {
				const parsedData = parseUnreadMessages(data);
				setUnreadMessagesCount(parsedData, !args.length);
				setHasUnread({ type: 'client', value: hasUnreadMessages(parsedData) });
				setAreUnreadsFetched({ type: 'client', value: true });
			},
			error: (error: AxiosError) => {
				// eslint-disable-next-line no-console
				console.error('fetchClientUnreadMessagesCount', error);
				setUnreadLoadingError(error.message);
			}
		}
	});

	const { fetchData: fetchAllUnreadCounts } = useAxios({
		url: '',
		dynamicUrl: true,
		cb: {
			success: (data, args) => {
				const { afterCompanyAllUnreadSuccess } = args?.[0];
				afterCompanyAllUnreadSuccess?.(data);
			},
			error: (error: AxiosError) => {
				// eslint-disable-next-line no-console
				console.error('fetchAllUnreadCounts', error);
			}
		}
	});

	//All unread counts fetching, including status, cases and group chats
	async function fetchCompanyAllUnreadCounts(
		paramOptions?: FetchAllCompanyUnreadCountsParamOptions
	) {
		const extraParams: ExtraParamType = {
			count: 'true',
			by_status: 'true'
		};

		if (paramOptions?.caseUnread) {
			extraParams.entity_type = 'case';
		}

		if (paramOptions?.groupUnread) {
			extraParams.entity_type = 'ims';
		}

		await fetchAllUnreadCounts({
			url: `/v2/${companyId}/unreads/`,
			params: {
				extra: extraParams
			},
			afterCompanyAllUnreadSuccess: (data: any) => {
				const unreadResponseData = data as AllCompanyUnreadDataShape;
				updateUnreadCountsInContext(unreadResponseData, paramOptions);
			}
		});
	}

	const updateUnreadCountsInContext = useCallback(
		(
			unreadResponseData: AllCompanyUnreadDataShape,
			paramOptions?: FetchAllCompanyUnreadCountsParamOptions
		) => {
			if (!isEmpty(paramOptions)) {
				if (paramOptions?.caseUnread) {
					setUnreadMessagesCount({
						...unreadResponseData?.unread_messages_by_client_cases,
						...unreadResponseData?.unread_messages_by_staff_cases
					});
					setUnreadCasesCountByStatus(unreadResponseData?.unread_cases_by_status);
				}

				if (paramOptions?.groupUnread) {
					setGroupUnreadMessage(unreadResponseData?.unread_messages_by_chats);
				}
			} else {
				setUnreadMessagesCount({
					...unreadResponseData?.unread_messages_by_client_cases,
					...unreadResponseData?.unread_messages_by_staff_cases
				});
				setUnreadCasesCountByStatus(unreadResponseData?.unread_cases_by_status);
				setGroupUnreadMessage(unreadResponseData?.unread_messages_by_chats);
			}

			setAreUnreadsFetched({ type: 'all', value: true });
		},
		[]
	);

	return {
		fetchCompanyAllUnreadCounts,
		fetchStaffUnreadMessagesCount,
		fetchClientUnreadMessagesCount
	};
}
