export enum SocketEventTypes {
	ADDED_ASSIGNED_USER = 'ADDED_ASSIGNED_USER',
	ADD_MEMBER = 'ADD_MEMBER',
	ASSIGNED_USER = 'ASSIGNED_USER',
	BUSINESS_TRANSFER = 'BUSINESS_TRANSFER',
	CASE_ASSIGNEE_UPDATED = 'CASE_ASSIGNEE_UPDATED',
	CASE_PINNED = 'CASE_PINNED',
	CASE_UNPINNED = 'CASE_UNPINNED',
	CONNECTION_REQUEST = 'CONNECTION_REQUEST',
	DELETE_DRAFT_MESSAGE = 'DELETE_DRAFT_MESSAGE',
	DELETE_MESSAGE = 'DELETE_MESSAGE',
	DRAFT_MESSAGE = 'DRAFT_MESSAGE',
	EDIT_MESSAGE = 'EDIT_MESSAGE',
	GROUP_CHAT_PERMISSIONS = 'GROUP_CHAT_PERMISSIONS',
	GROUP_CLOSED = 'GROUP_CLOSED',
	IMS_PINNED = 'IMS_PINNED',
	IMS_UNPINNED = 'IMS_UNPINNED',
	LEFT_MEMBER = 'LEFT_MEMBER',
	MARKED_READ_ALL = 'MARKED_READ_ALL',
	MEMBER_ADDED = 'MEMBER_ADDED',
	MEMBER_DELETED = 'MEMBER_DELETED',
	MEMBER_LEFT = 'MEMBER_LEFT',
	MESSAGE_PINNED = 'MESSAGE_PINNED',
	MESSAGE_READ = 'MESSAGE_READ',
	NEW_CASE = 'NEW_CASE',
	NEW_MESSAGE = 'NEW_MESSAGE',
	NO_UNREAD_LEFT = 'NO_UNREAD_LEFT',
	NO_UNREAD_LEFT_FOR_COMPANY = 'NO_UNREAD_LEFT_FOR_COMPANY',
	OWNER_ROLE_INVITATION = 'OWNER-ROLE-INVITATION',
	PRIORITY_CHANGED = 'PRIORITY_CHANGED',
	REACTED_MESSAGE = 'REACTED_MESSAGE',
	REMOVE_MEMBER = 'REMOVE_MEMBER',
	REMOVED_ASSIGNED_USER = 'REMOVED_ASSIGNED_USER',
	REQUEST_MANAGER = 'REQUEST_MANAGER',
	REQUEST_UPDATE = 'REQUEST_UPDATE',
	SOCKET_EVENT_TYPES = 'SOCKET_EVENT_TYPES',
	STAFF_EDITED = 'STAFF_EDITED',
	STAFF_INVITATION_PUSH = 'STAFF_INVITATION_PUSH',
	STAFF_INVITATION_REQUEST = 'STAFF_INVITATION_REQUEST',
	STATUS_CHANGED = 'STATUS_CHANGED',
	STATUS_NAME_CHANGED = 'STATUS_NAME_CHANGED',
	TYPING = 'TYPING',
	UNASSIGNED_USER = 'UNASSIGNED_USER',
	USER_ASSIGNED = 'USER_ASSIGNED',
	USER_DISCONNECTED = 'USER_DISCONNECTED',
	USER_INVITATION_REQUEST = 'USER_INVITATION_REQUEST',
	USER_TAGGED = 'USER_TAGGED',
	USER_UNASSIGNED = 'USER_UNASSIGNED',
	USER_UNTAGGED = 'USER_UNTAGGED',
	SCHEDULED_MESSAGE = 'SCHEDULED_MESSAGE',
	EDIT_SCHEDULED_MESSAGE = 'EDIT_SCHEDULED_MESSAGE',
	DELETE_SCHEDULED_MESSAGE = 'DELETE_SCHEDULED_MESSAGE',
	NO_SCHEDULED_MESSAGES_LEFT = 'NO_SCHEDULED_MESSAGES_LEFT',
	MESSAGE = 'MESSAGE',
	REPLIED_MESSAGE_DELETED = 'REPLIED_MESSAGE_DELETED',
	REASSIGNMENT = 'REASSIGNMENT'
}
