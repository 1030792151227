import React from 'react';
import { useAppState } from '../../../../shared/state';
import config from '../../../../config';
import { CaseTypes } from '../../../../shared/state/reducer';
import { useTranslation } from 'react-i18next';

export function CreateCaseSwitch() {
	const { caseType, setCaseType } = useAppState();
	const { t } = useTranslation();

	return (
		<>
			<div className="switch-toggle-custom switch-ios mb-4 font-poppins">
				<input
					id="client"
					name="casetype"
					type="radio"
					defaultChecked={caseType === config.CLIENT}
					tabIndex={-1}
				/>
				<label htmlFor="client" onClick={() => setCaseType(config.CLIENT as CaseTypes)}>
					{t('client')}
				</label>
				<input
					id="Staff"
					name="casetype"
					type="radio"
					defaultChecked={caseType === config.STAFF}
					tabIndex={-1}
				/>
				<label htmlFor="Staff" onClick={() => setCaseType(config.STAFF as CaseTypes)}>
					{t('staff')}
				</label>
				<a />
			</div>
		</>
	);
}
