import React, { useState } from 'react';
import { CaseDetailsRow } from './CaseDetailsRow';
import { CaseRelatedTo } from 'shared/interfaces';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { useAppState } from 'shared/state';
import config from 'config';
import { Chip } from 'shared/components/Elements';
import { CaseTypes } from 'shared/state/reducer';
import { useQueryParams } from 'shared/hooks';
import { ConfirmModal } from 'shared/components';

type Props = {
	relatedTo: CaseRelatedTo | null;
	caseCreatorId: number;
	closeCaseDetailModal: () => void;
};

export function CDReassignedCaseInfo({ relatedTo, caseCreatorId, closeCaseDetailModal }: Props) {
	const { userData } = useAuth();
	const { caseType, isAdmin } = useAppState();

	return (
		<>
			{caseType === config.STAFF && relatedTo && (caseCreatorId === userData.is || isAdmin) && (
				<CDReassignedCaseInfoComponent
					relatedTo={relatedTo}
					closeCaseDetailModal={closeCaseDetailModal}
				/>
			)}
		</>
	);
}

type CaseDetailsRowType = {
	relatedTo: CaseRelatedTo;
	closeCaseDetailModal: () => void;
};

function CDReassignedCaseInfoComponent({ relatedTo, closeCaseDetailModal }: CaseDetailsRowType) {
	const { setCaseType } = useAppState();
	const [goToCaseModal, setGoToCaseModal] = useState(false);
	const { changeUrlParams } = useQueryParams();

	//Open Client Case go to Popup handler
	const toggleClientCaseGotoPopup = () => setGoToCaseModal((prev) => !prev);

	//Click handler to open Client case
	const handleClick = () => {
		//Close ReassignCaseGoTo Popup
		toggleClientCaseGotoPopup();

		//Close Case Details Popup
		closeCaseDetailModal();

		//Change Case type to Clients
		setCaseType(config.CLIENT as CaseTypes);

		//Open Client Case
		changeUrlParams({ status: relatedTo.status_id, case: relatedTo.id });
	};

	return (
		<>
			<CaseDetailsRow fieldName="Related Client Case">
				<div>
					<Chip
						label={relatedTo.case_number}
						onClick={toggleClientCaseGotoPopup}
						variant="outlined"
						color="primary"
					/>
				</div>
			</CaseDetailsRow>
			<ConfirmModal
				featureHandler={handleClick}
				isOpen={Boolean(goToCaseModal)}
				toggleHandler={toggleClientCaseGotoPopup}
				title="Are you sure to open the related case?"
			/>
		</>
	);
}
