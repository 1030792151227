import { useEffect } from 'react';
import { FaRegCalendarCheck } from 'react-icons/fa';
import { CgCloseR } from 'react-icons/cg';
import { WhiteModal } from '../../../../shared/components/Elements';
import { DateRange } from 'react-date-range';
import { useNewCaseFilter } from '../../hooks';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import { useAppState } from '../../../../shared/state';
import clsx from 'clsx'; // theme css file
import { useTranslation } from 'react-i18next';
import { enUS, uz, ru, arSA } from 'date-fns/locale';
import { useTranslationHelpers } from 'shared/hooks/useTranslationHelpers';

type DueToFilterItemProps = {
	setFieldValue: any;
};

const getLocale = (language: string) => {
	switch (language) {
		case 'UZ': {
			return uz;
		}
		case 'RU': {
			return ru;
		}
		case 'EN': {
			return enUS;
		}
		case 'AR': {
			return arSA;
		}
	}
};

export function DueToFilterItem({ setFieldValue }: DueToFilterItemProps) {
	const { t, i18n } = useTranslation();
	const {
		state,
		setDateState,
		resetDatePicker,
		initDateState,
		open,
		setOpen,
		handleDatePickerOpen,
		formatCaseRangeDate,
		dateState,
		today
	} = useNewCaseFilter(() => {});

	//CASE: 4152 - setting default start and end value of case creation date
	useEffect(() => {
		setFieldValue(
			'start_date',
			formatCaseRangeDate(
				//@ts-ignore
				dateState?.[0]?.startDate
			)
		);
		setFieldValue(
			'end_date',
			formatCaseRangeDate(
				//@ts-ignore
				dateState?.[0]?.endDate
			)
		);
	}, [dateState]);

	const { isDarkMode } = useAppState();
	const { isRTL } = useTranslationHelpers();
	return (
		<div className="dropdown mb-4">
			<div className="mb-2 text-sm leading-none text-kgrey-menuText dark:text-white">
				{t('by_case_creation_date')}
			</div>
			<div className="w-full">
				<div className="relative z-0 h-14 rounded-lg">
					<div
						className={clsx(
							'absolute top-2 left-4 z-10 text-xs font-light text-[#7A8693] dark:text-white',
							{
								'left-4': !isRTL,
								'right-4': isRTL
							}
						)}
					>
						{t('from_to')}
					</div>
					<span
						className={clsx('absolute bottom-3 z-20 text-primary', {
							'right-5': !isRTL,
							'left-5': isRTL
						})}
					>
						<FaRegCalendarCheck />
					</span>
					{state.dateRange ? (
						<span
							className={
								'absolute right-5 top-2 z-20 text-kgrey ' +
								'cursor-pointer transition-colors hover:text-kdark '
							}
							onClick={() => {
								setDateState([initDateState]);
								resetDatePicker(setFieldValue);
							}}
						>
							<CgCloseR />
						</span>
					) : null}
					<input
						type="text"
						onClick={() => setOpen(true)}
						value={state.dateRange}
						readOnly
						placeholder={t('select_date')}
						className={
							'absolute h-full w-full rounded-lg outline-none ' +
							'bg-kgrey-filter-bg dark:bg-kdark-lighter dark:text-white' +
							' border border-kgrey-light-grey-4 px-4 pt-6 pb-2 text-kdark ' +
							'transition focus:border-primary focus:ring focus:ring-primary ' +
							'z-0 focus:ring-opacity-30'
						}
					/>
					<WhiteModal
						handleModalOpen={handleDatePickerOpen}
						open={open}
						minWidth={332}
						maxWidth={332}
						noPadding
						cropRounded
					>
						<div className="overlay-hidden -mt-5 rounded-lg" dir="ltr">
							<DateRange
								locale={getLocale(i18n.language)}
								editableDateInputs={true}
								onChange={(item) => {
									//@ts-ignore
									setDateState([item.selection]);
									setFieldValue(
										'start_date',
										formatCaseRangeDate(
											//@ts-ignore
											item?.selection?.startDate
										)
									);
									setFieldValue(
										'end_date',
										formatCaseRangeDate(
											//@ts-ignore
											item?.selection?.endDate
										)
									);
								}}
								moveRangeOnFirstSelection={false}
								//@ts-ignore
								ranges={dateState}
								className={clsx('filter-date-range-picker dark:bg-kdark-dark333', {
									'filter-date-range-picker__dark': isDarkMode
								})}
								rangeColors={['#00D0BD']}
								//setting maxDate will disable upcoming dates other than today
								maxDate={today}
							/>
						</div>
					</WhiteModal>
				</div>
			</div>
		</div>
	);
}

export default DueToFilterItem;
