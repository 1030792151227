import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Company } from 'modules/user/reducers';
import { storage } from 'shared/services';
import { getCompany } from 'modules/user/selectors';
import Actions from 'store/actions';
import { IRootState } from 'shared/interfaces';
import {
	useAxios,
	useCaseFilterSwitch,
	useLoadAll,
	useNotistack,
	useQueryParams,
	useRouter
} from 'shared/hooks';
import userActions from 'modules/user/actions';
import { useTranslation } from 'react-i18next';

const SetSelectedUserCompany = Actions.user.setUserCompany;

export function useAsStaff() {
	const company = useSelector((state: IRootState) => getCompany(state));
	const [tempCompanyId, setTempCompanyId] = useState<number | null>(
		company?.company?.id ? company?.company?.id : null
	);
	const { reloadEntityAll } = useLoadAll();
	const [openExitModal, setOpenExitModal] = useState<Company | null>(null);
	const { query, removeQueryParams } = useQueryParams<{ page: number }>();
	const { showNotification } = useNotistack();
	const { fetchCaseFilter } = useCaseFilterSwitch();
	const { history, location } = useRouter();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	function loadAllBusiness() {
		reloadEntityAll({
			url: '/user/companies/',
			entity: 'userCompanies',
			name: 'AllUserCompanies',
			params: {
				page: 1,
				limit: 10
			}
		});
	}

	function loadUserCompaniesRequest() {
		dispatch(userActions.loadUserCompaniesRequest());
	}

	function loadBusinessListOnPinChange() {
		if (query.ordering) {
			removeQueryParams();
			return;
		}
		loadAllBusiness();
	}

	const { fetchData: pinBusiness } = useAxios({
		url: '',
		dynamicUrl: true,
		method: 'post',
		cb: {
			success: (data, args) => {
				loadBusinessListOnPinChange();
			},
			error: (error, args) => {
				const [arg] = args;
			}
		}
	});

	const { fetchData: unpinBusiness } = useAxios({
		url: '',
		dynamicUrl: true,
		method: 'delete',
		cb: {
			success: (data, args) => {
				loadBusinessListOnPinChange();
			},
			error: (error, args) => {
				const [arg] = args;
			}
		}
	});
	const { fetchData: setSelectedBusinessAsync } = useAxios({
		url: '',
		dynamicUrl: true,
		cb: {
			success: async (data, args) => {
				const [params] = args;
				await saveSelectedBusiness(params.selectedCompany);

				if (!params.selectedCompany.company.comp_subscription?.is_available) {
					window.location.reload();
				}
			},
			error: (error, args) => {
				const [arg] = args;
			}
		}
	});

	const saveSelectedBusiness = async (business: Company) => {
		dispatch(userActions.loadUserCompaniesRequest());
		const selectedBusiness = { ...business, is_selected: true };
		dispatch(SetSelectedUserCompany(selectedBusiness));
		storage.set('company', JSON.stringify(selectedBusiness));
		await fetchCaseFilter({ url: `/${business.company.id}/case-filters/` });

		//do not redirect if switching business via notification
		if (!location.pathname.includes('preloader')) {
			setTimeout(() => {
				history.push('/case-messages');
			}, 0);
		}
	};

	useEffect(() => {
		if (company) setTempCompanyId(company.company.id);
	}, [company]);

	const handleChangeCompany = async (item: Company) => {
		const {
			company: { id }
		} = item;

		setTempCompanyId(id);
		await setSelectedBusinessAsync({
			url: `/user/set-selected-company/${id}/`,
			selectedCompany: item
		});
	};

	function pinHandler(entityId: number, thisCompanyId: number) {
		pinBusiness({
			url: `/user/companies/${entityId}/pin/${thisCompanyId}/`
		});
	}

	function unPinHandler(entityId: number, thisCompanyId: number) {
		unpinBusiness({
			url: `/user/companies/${entityId}/pin/${thisCompanyId}/`
		});
	}

	const pinUnpinHandler = (business: Company) => {
		const { pin_time: pinTime } = business;
		const { id: thisCompanyId } = business.company;
		const { id: companyEntityId } = business;
		if (pinTime) {
			unPinHandler(companyEntityId, thisCompanyId);
			return;
		}
		pinHandler(companyEntityId, thisCompanyId);
	};

	const showNotificationCantAllowExitSelectedBusiness = () => {
		showNotification({
			message: t('cannot_disconnect_from_selected_business'),
			variant: 'error'
		});
	};

	const disconnectHandler = ({ item, isOwner }: { item: Company; isOwner: boolean }) => {
		const isSelectedCompany = tempCompanyId === item.company.id;
		if (!isOwner) {
			if (isSelectedCompany) {
				showNotificationCantAllowExitSelectedBusiness();
				return;
			}
		}
		if (!isSelectedCompany && !isOwner) {
			setOpenExitModal(item);
		}
		if (isOwner) {
			showNotification({
				message: t('cannot_disconnect_from_own_business'),
				variant: 'error'
			});
		}
	};

	return {
		tempCompanyId,
		setTempCompanyId,
		handleChangeCompany,
		pinUnpinHandler,
		disconnectHandler,
		openExitModal,
		setOpenExitModal,
		loadAllBusiness,
		loadUserCompaniesRequest
	};
}
