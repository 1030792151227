import { useDispatch } from 'react-redux';
import { useState } from 'react';
import Actions from 'store/actions';

import { useAppState } from 'shared/state';
import { FilterTemplateType } from 'shared/typings';
import { SelectedCaseFilter } from 'shared/state/reducer';
import { useNotistack, useUnreadCount, useUpdateEntities } from 'shared/hooks';
import { storage } from 'shared/services';
import config from '../../../config';
import { useTranslation } from 'react-i18next';

const FormRequest = Actions.entity.formRequest;

export function useCaseFilterSelect() {
	const { t } = useTranslation();
	const {
		selectedCaseFilter,
		setSelectedCaseFilter,
		caseType,
		companyId,
		setActiveFilterTemplate
	} = useAppState();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const { updateEntities } = useUpdateEntities();
	const { showNotification } = useNotistack();
	const { fetchStaffUnreadMessagesCount, fetchClientUnreadMessagesCount } = useUnreadCount();

	const updateFilterInLS = (
		filter: FilterTemplateType | null,
		parsedLSFilter: SelectedCaseFilter
	) => {
		const filterObjectForSavingInLS = {
			...parsedLSFilter,
			[caseType]: filter
		};
		storage.set('selectedCaseFilter', JSON.stringify(filterObjectForSavingInLS));
	};

	const deselectPrevItem = (prevSelectedTemplate: SelectedCaseFilter) => {
		if (!prevSelectedTemplate[caseType]) return;
		updateEntities({
			entity: 'case-filters',
			entityId: prevSelectedTemplate?.[caseType]?.id as number,
			updatingData: {
				is_selected: false
			}
		});
	};

	const handleAfterSelectSuccess = (
		filter: FilterTemplateType | null,
		prevSelectedTemplate: SelectedCaseFilter,
		parsedLSFilter: SelectedCaseFilter
	) => {
		setSelectedCaseFilter({
			filter: {
				...prevSelectedTemplate,
				[caseType]: filter
			},
			caseType,
			isInitialDispatch: false
		});

		updateFilterInLS(filter, parsedLSFilter);
		deselectPrevItem(prevSelectedTemplate);

		if (filter) {
			updateEntities({
				entity: 'case-filters',
				entityId: filter.id,
				updatingData: {
					is_selected: true
				}
			});
		}
	};

	const onFilterTemplateClick = (filter: FilterTemplateType | null) => {
		const prevSelectedTemplate = { ...selectedCaseFilter } as SelectedCaseFilter;
		const filterTemplateInLS = storage.get('selectedCaseFilter');
		const parsedLSFilter = JSON.parse(filterTemplateInLS || '{}') as SelectedCaseFilter;

		setLoading(true);
		if (!filter) {
			dispatch(
				FormRequest({
					entity: 'case-filters',
					name: `AllCaseFilters-${companyId}-${caseType}`,
					url: `/${companyId}/case-filters/${prevSelectedTemplate[caseType]?.id}/deselect/`,
					method: 'post',
					params: {},
					values: {},
					cb: {
						success: (data: FilterTemplateType) => {
							handleAfterSelectSuccess(filter, prevSelectedTemplate, parsedLSFilter);
							setActiveFilterTemplate(null as unknown as FilterTemplateType);
						},
						error: (error: Error) => {
							console.error('error case filter selecting', error);
							showNotification({ message: t('something_went_wrong'), variant: 'error' });
							//Restore prev filter
							setSelectedCaseFilter({
								filter: prevSelectedTemplate,
								caseType,
								isInitialDispatch: true
							});
						},
						finally: () => {
							setLoading(false);
						}
					}
				})
			);

			return;
		}

		dispatch(
			FormRequest({
				entity: 'case-filters',
				name: `AllCaseFilters-${companyId}-${caseType}`,
				url: `/${companyId}/case-filters/${filter.id}/select/`,
				method: 'post',
				params: {},
				values: {},
				cb: {
					success: (data: FilterTemplateType) => {
						handleAfterSelectSuccess(filter, prevSelectedTemplate, parsedLSFilter);
					},
					error: (error: Error) => {
						console.error('error case filter selecting', error);
						showNotification({ message: t('something_went_wrong'), variant: 'error' });
						//Restore prev filter
						setSelectedCaseFilter({
							filter: prevSelectedTemplate,
							caseType,
							isInitialDispatch: true
						});
					},
					finally: () => {
						setLoading(false);
					}
				}
			})
		);
	};

	return {
		loading,
		onFilterTemplateClick,
		selectedCaseFilter
	};
}
