import {
	parseUnreadMessages,
	useAxios,
	useGetEntity,
	useNotistack,
	useReorderEntityIds
} from 'shared/hooks';
import { useAppState } from 'shared/state';
import { DropResult } from 'react-beautiful-dnd';
import config from 'config';
import { useCaseHTTPRequest } from './useCaseHTTPRequest';
import { storage } from 'shared/services';
import isEmpty from 'lodash/isEmpty';
import { useCallback } from 'react';
import merge from 'lodash/merge';

export function useCaseController() {
	const {
		companyId,
		isPermitted,
		searchQuery,
		selectedCaseFilter,
		caseType,
		unreadCasesCountByStatus,
		setUnreadCasesCountByStatus,
		setInitialStatusReverseState,
		setSettingsData,
		unreadMessagesCount,
		setChatsMentionObject,
		setScheduledMessageIds
	} = useAppState();
	const {
		getEntityData,
		reOrder,
		modifyEntityIds,
		reOrderStatusList,
		removingEntityId,
		insertingEntityId
	} = useReorderEntityIds();
	const { showNotification } = useNotistack();
	const parsedSelectedCaseFromLS = JSON.parse(storage.get('selectedCaseFilter') || '{}')[caseType];
	const selectedFilterTemplate = selectedCaseFilter[caseType] || parsedSelectedCaseFromLS;
	const { settingsData } = useAppState();

	const { all: statuses } = useGetEntity({
		entity: 'statuses',
		entityName: `AllStatuses-${companyId}`
	});

	const { sendCaseStatusChangeRequest, sendStatusReorderRequest } = useCaseHTTPRequest();

	const { fetchData: fetchBusinessSettings } = useAxios({
		url: `/${companyId}/settings/`,
		cb: {
			success: (data) => {
				const casesReverseData = data?.settings_json?.cases_reverse_data;
				//Save settings date to context
				if (typeof data === 'object') {
					setSettingsData(data);
					const computedSettingsData = merge({}, settingsData, data);
					storage.set('settingsData', JSON.stringify(computedSettingsData));
				}

				if (
					casesReverseData &&
					typeof casesReverseData === 'object' &&
					!isEmpty(casesReverseData)
				) {
					setInitialStatusReverseState(casesReverseData);
					storage.set('casesReverseData', JSON.stringify(casesReverseData));
				}
			},
			error: (error) => {
				console.log('settings error', error);
			}
		}
	});

	const { fetchData: fetchCasesUnreadCount } = useAxios({
		url: `/${companyId}/message_counts/`,
		params: {
			extra: {
				as_business_company: 'true',
				by_status: 'true',
				count_cases: 'true',
				case_type: caseType === config.CLIENT ? 'client' : 'staff',
				...{ ...(caseType === config.STAFF ? { as_client_company: 'true' } : {}) }
			}
		},
		cb: {
			success: (data, additionalArgs) => {
				const parsedData = parseUnreadMessages(data);
				const { fnCallback = 'empty' } = additionalArgs[0] || {};

				setUnreadCasesCountByStatus(parsedData);

				if (typeof fnCallback === 'function') {
					additionalArgs[0]?.fnCallback();
				}
			},
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			error: (e) => {
				console.log('parsedData error', e);
			}
		}
	});

	const { fetchData: fetchCasesMentionList } = useAxios({
		url: `/${companyId}/tagged_messages/`,
		// params: {},
		cb: {
			success: (data, additionalArgs) => {
				setChatsMentionObject(data);
			},
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			error: (e) => {
				console.log('tagged_messages error', e);
			}
		}
	});

	const { fetchData: fetchCasesScheduledMessageCount } = useAxios({
		url: `/${companyId}/scheduled_messages/`,
		cb: {
			success: (data) => {
				setScheduledMessageIds(data);
			},
			error: (error) => {
				console.log('scheduled messages error', error);
			}
		}
	});

	const incrementUnreadCaseCount = useCallback(
		(statusId: number, caseId: number) => {
			//TODO: later check why status_changed event type was needed for this logic
			const isUnreadCase = unreadMessagesCount?.[caseId];

			if (!isUnreadCase) {
				setUnreadCasesCountByStatus({
					...unreadCasesCountByStatus,
					[statusId]: unreadCasesCountByStatus[statusId]
						? unreadCasesCountByStatus[statusId] + 1
						: 1
				});
			}
		},
		[unreadMessagesCount, unreadCasesCountByStatus]
	);

	const decrementUnreadCaseCount = useCallback(
		(statusId: number, caseId?: number) => {
			setUnreadCasesCountByStatus({
				...unreadCasesCountByStatus,
				[statusId]: Math.max(--unreadCasesCountByStatus[statusId], 0)
			});
		},
		[unreadCasesCountByStatus]
	);

	const onStatusDragEnd = async (result: DropResult) => {
		console.log('result onStatusDragEnd', result);
		//Handle drag-n-drop status reordering
		if (result.type === config.STATUS_DROPPABLE_TYPE) {
			await handleStatusReorder(result);
		}
		if (result.type === config.CASES_DROPPABLE_TYPE) {
			await handleCaseDrag(result);
		}
	};

	const handleCaseDrag = async (result: DropResult) => {
		const { draggableId: draggableContainedId, destination, source } = result;

		if (!destination) return;

		const [draggableId, entityId] = draggableContainedId.split('|');

		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return;
		}

		const sourceDroppableId = Number(source.droppableId);
		const destinationDroppableId = Number(destination.droppableId);
		await changeCaseStatus({
			prevStatusId: sourceDroppableId,
			nextStatusId: destinationDroppableId,
			isStaffCase: false,
			sourceIndex: source.index,
			destinationIndex: destination.index,
			entityId: Number(entityId),
			searchEntityId: Number(draggableId),
			isDragging: true
		});
	};

	const handleStatusReorder = async (result: DropResult) => {
		const { ids, meta, params, entity, entityName } = getEntityData(
			'statuses',
			`AllStatuses-${companyId}`
		);
		if (result.destination && result.destination.index !== result.source.index) {
			//get reordered ids
			const { prevIds, newIds } = reOrder({
				list: ids as number[],
				startIndex: result.source.index,
				endIndex: result.destination.index
			});

			//Update redux state
			modifyEntityIds({ ids: newIds, entityName, entity, meta, params });
			//TODO: Check case filter. If there is case filter, do not reorder in backend

			if (!isPermitted) {
				showNotification({
					message: 'Members cannot make persistent reordering',
					variant: 'info'
				});
				return;
			}
			//prepare reordered status array for sending to api
			const newOrderedStatuses = reOrderStatusList(statuses.items, newIds);

			//call case order change api
			await sendStatusReorderRequest({
				url: `/${companyId}/my/company/case_statuses/insert_list/`,
				values: newOrderedStatuses,
				prevIds
			});
		}
	};
	type ChangeCaseStatusTypes = {
		prevStatusId: number;
		nextStatusId: number;
		isStaffCase?: boolean;
		entityId: number;
		sourceIndex?: number;
		destinationIndex?: number;
		isDragging?: boolean;
		searchEntityId?: number;
	};

	async function changeCaseStatus({
		prevStatusId,
		nextStatusId,
		entityId,
		isDragging = false,
		isStaffCase = false,
		sourceIndex,
		destinationIndex,
		searchEntityId
	}: ChangeCaseStatusTypes) {
		const entityNameSource = `AllBusinessCases-${prevStatusId}-${caseType}-${
			searchQuery.length
				? 'search'
				: `${selectedFilterTemplate ? selectedFilterTemplate.id : 'normal'}`
		}`;
		const entityNameDestination = `AllBusinessCases-${nextStatusId}-${caseType}-${
			searchQuery.length
				? 'search'
				: `${selectedFilterTemplate ? selectedFilterTemplate.id : 'normal'}`
		}`;

		const isStatusChanged = prevStatusId !== nextStatusId;

		const {
			ids: sourceIds,
			meta: sourceMeta,
			params: sourceParams
		} = getEntityData('cases', entityNameSource);

		const {
			ids: destinationIds,
			meta: destinationMeta,
			params: destinationParams
		} = getEntityData('cases', entityNameDestination);

		const { prevSourceIds, nextSourceIds } = removingEntityId({
			list: sourceIds as number[],
			index: sourceIndex,
			isDragging: false,
			entityId: searchEntityId ?? entityId
		});

		const { prevDestinationIds, nextDestinationIds } = insertingEntityId({
			list: destinationIds as number[],
			index: destinationIndex,
			newEntityId: searchEntityId ?? entityId
		});
		//In search mode we must also "take" case from normal mode. Because despite we fetch search cases again
		//We still have normal mode ids in redux store
		if (searchEntityId) {
			const normalEntityName = `AllBusinessCases-${prevStatusId}-${caseType}-${
				searchQuery.length
					? 'search'
					: `${selectedFilterTemplate ? selectedFilterTemplate.id : 'normal'}`
			}`;
			const {
				ids: normalSourceIds,
				meta: normalSourceMeta,
				params: normalSourceParams
			} = getEntityData('cases', normalEntityName);

			const { nextSourceIds } = removingEntityId({
				list: normalSourceIds as number[],
				index: sourceIndex,
				isDragging: false,
				entityId
			});

			modifyEntityIds({
				ids: nextSourceIds,
				entityName: normalEntityName,
				entity: 'cases',
				meta: { ...normalSourceMeta, count: (normalSourceMeta as any).count - 1 },
				params: normalSourceParams
			});
		}

		//Update source status cases in redux
		modifyEntityIds({
			ids: nextSourceIds,
			entityName: entityNameSource,
			entity: 'cases',
			meta: { ...sourceMeta, count: (sourceMeta as any).count - 1 },
			params: sourceParams
		});

		if (destinationIds) {
			//Update destination status cases in redux
			modifyEntityIds({
				ids: nextDestinationIds,
				entityName: entityNameDestination,
				entity: 'cases',
				meta: {
					...destinationMeta,
					count: isStatusChanged
						? (destinationMeta as any).count + 1
						: (destinationMeta as any).count
				},
				params: destinationParams
			});
		}

		if (isStatusChanged) {
			await sendCaseStatusChangeRequest({
				url: `/${companyId}/cases/as_business/${entityId}/`,
				values: {
					id: entityId,
					status: { id: nextStatusId.toString() },
					...(isStaffCase ? { client_company: { id: companyId } } : {})
				},
				prevSourceIds,
				prevDestinationIds,
				entityNameSource,
				entityNameDestination,
				prevSourceMeta: sourceMeta,
				prevSourceParams: sourceParams,
				prevDestinationMeta: destinationMeta,
				prevDestinationParams: destinationParams
			});
		}
	}

	return {
		onStatusDragEnd,
		changeCaseStatus,
		fetchCasesUnreadCount,
		fetchBusinessSettings,
		incrementUnreadCaseCount,
		decrementUnreadCaseCount,
		fetchCasesMentionList,
		fetchCasesScheduledMessageCount
	};
}
