import React, { useState } from 'react';
import { Notification } from 'shared/components/Elements';

export const useNotification = () => {
	const [notifVisible, setNotifVisible] = useState<boolean>(false);

	const handleNotificationClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setNotifVisible(false);
	};

	return {
		Notification,
		notifVisible,
		setNotifVisible,
		handleNotificationClose
	};
};
