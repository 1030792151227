import { IMessage } from '../../interfaces';
import { nanoid } from 'nanoid';
import { useQueryParams } from '../useQueryParams';
import { useEffect, useRef } from 'react';
import { useAppState } from 'shared/state';

export function useOnInit() {
	const { query } = useQueryParams();
	const lastReadMsgId = Number(query?.lastReadMessage);
	const entityId = query?.case || query?.chat;
	const timerRef = useRef<number | null>(null);
	const { searchQuery } = useAppState();

	useEffect(() => {
		return () => {
			if (timerRef.current) {
				clearTimeout(timerRef.current);
			}
		};
	}, [entityId]);

	function afterMessagesFetchService({
		data,
		isDelayedMessage,
		setIsDelayedMessage,
		setAllowToLoad,
		direction,
		scrollToMsgId,
		cursorBot,
		scrollIntoElement,
		setUpdateEntityPage,
		searchedMessageId,
		paginationDepsRef,
		cursor,
		setIsLastPageFetched,
		setIsFirstPageFetched,
		messagesLimit,
		setBottomLoading,
		isLoadedRepliedMessage,
		virtuosoRef
	}: any) {
		/*
		 * changes made according to cases 2382, 2400 - https://youtu.be/ztlHRqVIiHs
		 * */

		const messagesList = data.results ?? [];
		const totalMessagesCountInData = data?.count ?? 0;

		//3031's fix - scroll to lastReadMessage after fetching data to display Unread Label fully to UI
		const lastReadMsgIndex = messagesList.findIndex(
			(message: IMessage) => message.id === lastReadMsgId
		);

		const newCursorHead = messagesList[messagesList.length - 1]?.id;
		const newCursorTail = messagesList[0]?.id;
		const isInitialLoadingPage = cursor === null && direction !== 'previous' && !searchedMessageId;

		const stopperForBottomLoading =
			messagesList.length < messagesLimit &&
			(((paginationDepsRef.current?.direction === 'previous' ||
				/*3697 - I added extra checks because scrolling last tagged message doesnt stop bottom loading */
				(!searchQuery.length && !isLoadedRepliedMessage && cursor === scrollToMsgId)) &&
				paginationDepsRef.current.totalMessagesCount >= messagesList.length) ||
				(paginationDepsRef.current?.direction === null &&
					(totalMessagesCountInData <= 10 || (!isLoadedRepliedMessage && !searchedMessageId))));

		const hasToResumeFetchingAbility =
			!paginationDepsRef.current.isFirstPageFetched ||
			(!paginationDepsRef.current.isSetSearchReply &&
				!paginationDepsRef.current.isMessageHasBeenIndicated) ||
			(!paginationDepsRef.current.isFirstPageFetched &&
				paginationDepsRef.current.groupMessageSearchQuery);

		//Setting last page fetched flag || STOPPER FOR LAST PAGE(that is, we do not send request for loading next "pages")
		if (isInitialLoadingPage || stopperForBottomLoading || isDelayedMessage) {
			setIsLastPageFetched(true);
			setBottomLoading(false)

			//To resume loading upper pages when we scroll manually to  last page after group chat message search scrolling indication
			// If we do not set to true we can't load top not loaded pages
			setTimeout(() => {
				setAllowToLoad(true);
			}, 300);
		}

		//STOPPER FOR FIRST PAGE(that is, we do not send request for loading next "pages")
		setTimeout(() => {
			if (
				messagesList.length < messagesLimit &&
				(paginationDepsRef.current?.direction === 'next' ||
					(isLoadedRepliedMessage && paginationDepsRef.current?.direction === null)) &&
				(cursor > messagesList[messagesList.length - 1]?.id ||
					paginationDepsRef.current.cursorTop === cursor)
			) {
				setIsFirstPageFetched(true);
			}
		}, 0);
		//Scrolling to bottom of chat || We scroll to bottom only when we open the case(chat)
		//|| when click scroll to bottom button if there is gap(in scroll to search msg, scroll replied message, pinned mode )
		if (/*isInitialLoadingPage || */ isDelayedMessage) {
			// scrolling to bottom on initial load is now handled by Virtuoso initialTopMostItemIndex prop
			virtuosoRef?.current?.scrollToIndex({
				index: 'LAST',
				align: 'end',
				behavior: 'auto',
				offset: 100
			});
		}

		//Allow to load next or prev pages
		if (isInitialLoadingPage || hasToResumeFetchingAbility) {
			setTimeout(() => {
				setAllowToLoad(true);
			}, 300);
		}

		if (stopperForBottomLoading || isDelayedMessage) {
			setAllowToLoad(true);
		}

		//For scrolling to searched, replied, pinned message
		const calculatedScrollToMsgId = scrollToMsgId
			? scrollToMsgId
			: paginationDepsRef?.current?.scrollToMsgId;

		const isMessageLoaded = data.results.find(
			(msg: IMessage) => msg?.id === calculatedScrollToMsgId
		);

		const messageIndex = data.results.findIndex(
			(msg: IMessage) =>
				msg?.id === calculatedScrollToMsgId || msg?.message_id === calculatedScrollToMsgId
		);

		if (isMessageLoaded) {
			const scrollTo = () => scrollIntoElement({ msgId: calculatedScrollToMsgId, messageIndex });
			setTimeout(() => {
				scrollTo();
			}, 300);
		}

		// Update entityPage after pagination
		if (newCursorTail < cursor) {
			setUpdateEntityPage({ dir: 'next', uuid: nanoid() });
		}
		if (cursorBot < newCursorHead) {
			setUpdateEntityPage({ dir: 'previous', uuid: nanoid() });
		}
		//For sending message when page 1 is not loaded, e.g. from search, replied mode
		if (isDelayedMessage && cursor === null) setIsDelayedMessage(false);

		if (lastReadMsgIndex >= 0) {
			timerRef.current = window.setTimeout(() => {
				scrollIntoElement({
					msgId: lastReadMsgId,
					messageIndex: lastReadMsgIndex,
					blink: false,
					scrollType: 'auto',
					scrollAlign: 'start'
				});
			}, 0);
		}
	}

	return { afterMessagesFetchService };
}
