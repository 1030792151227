import React from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import Tabs, { TabsProps } from '@material-ui/core/Tabs';

type Props = {
	roundedTop?: boolean;
};

const useStyles = makeStyles<Theme, Props>((theme) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.type === 'dark' ? 'rgb(0, 208, 189, .1)' : '#f4f4f4',
			borderRadius: ({ roundedTop }) => (roundedTop ? '10px 10px 0 0 ' : '10px'),
			position: 'relative',
			padding: '5px'
			// marginBottom: ({ roundedTop }) => (roundedTop ? '-10px' : 0)
		},
		indicator: {
			backgroundColor: 'transparent'
		}
	})
);

export function KennektTabs(props: Props & Omit<TabsProps, keyof Props>) {
	const classes = useStyles(props);
	return <Tabs classes={classes} {...props} />;
}

KennektTabs.defaultProps = {
	roundedTop: false
};

// export const KennektTabs = withStyles((theme: Theme) => ({
// 	root: {
// 		backgroundColor: theme.palette.type === 'dark' ? 'rgb(0, 208, 189, .1)' : '#f4f4f4',
// 		borderRadius: props => props.roundedTop ? '10px 10px 0 0 ' : '10px',
// 		padding: '5px'
// 	},
// 	indicator: {
// 		backgroundColor: 'transparent'
// 	}
// }))(Tabs);
