import React, { useEffect } from 'react';
import { DateTimePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const propTypes = {
	onChange: PropTypes.func,
	form: PropTypes.object,
	field: PropTypes.object,
	isDateTimeSet: PropTypes.bool,
	dateValue: PropTypes.string
};

const useStyles = makeStyles(
	createStyles({
		root: {}
	})
);

export function CustomDTPicker(props: any) {
	const classes = useStyles();
	const { t } = useTranslation();

	const {
		form: { setFieldValue },
		field: { name },
		onChange,
		isDateTimeSet = false,
		dateValue = '',
		...rest
	} = props;

	const [selectedDate, handleDateChange] = React.useState<any>(
		!isEmpty(dateValue) ? new Date(dateValue) : new Date()
	);

	useEffect(() => {
		!isEmpty(dateValue) && handleDateChange(new Date(dateValue));
	}, [dateValue]);
	return (
		<DateTimePicker
			okLabel={t('ok')}
			cancelLabel={t('cancel')}
			value={isDateTimeSet ? selectedDate : null}
			className="custom-date-picker"
			classes={{ root: classes.root }}
			// value={selectedDate}
			{...rest}
			onChange={
				onChange
					? (date) => {
							handleDateChange(date);
							setFieldValue(name, date);
							onChange();
					  }
					: (date) => {
							handleDateChange(date);
							setFieldValue(name, date);
					  }
			}
		/>
	);
}

CustomDTPicker.propTypes = propTypes;
