// mostly code from reactjs.org/docs/error-boundaries.html
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Component, ErrorInfo } from 'react';
import { Redirect, Link } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import Button from '@material-ui/core/Button';
import ErrorIcon from '@material-ui/icons/Error';
import IconButton from '@material-ui/core/IconButton';

type Props = {
	// using `interface` is also ok
	isCompact?: boolean;
};
type State = {
	redirect: boolean;
	hasError: boolean;
	eventId: string;
};

// import { ErrorIndicator } from 'components';
class ErrorBoundary extends Component<Props, State> {
	public state = {
		redirect: false,
		hasError: false,
		eventId: ''
	};
	public static getDerivedStateFromError(): any {
		return { hasError: true };
	}
	// public componentDidCatch(error: Error, info: ErrorInfo): any {
	public componentDidCatch(error: Error, info: any): any {
		console.error('ErrorBoundary caught an error', error, info);

		Sentry.withScope((scope) => {
			scope.setTag('Custom-Tag', 'ErrorBoundry');
			scope.setExtras(info);
			const eventId = Sentry.captureException(error);
			this.setState({ eventId });
		});
	}
	public componentDidUpdate(): any {
		// if (this.state.hasError) {
		// 	setTimeout(() => this.setState({ redirect: true }), 5000);
		// }
	}
	public render(): React.ReactNode {
		const { isCompact = false } = this.props;

		if (this.state.redirect) {
			return <Redirect to="/" />;
		}

		if (this.state.hasError) {
			return (
				<>
					{!isCompact ? (
						<div className={'py-4 text-center dark:bg-kgrey-2d2d2d dark:text-white'}>
							<h1 className={'mb-2 font-semibold dark:text-white'}>Unexpected Error occured.</h1>
							<p className={'mb-2 dark:text-white'}>
								Please press{' '}
								<Link to="/" className={'underline hover:no-underline dark:text-white'}>
									here
								</Link>{' '}
								to go HomePage.
							</p>
							<p className={'mb-2 dark:text-white'}>Please inform us about this error</p>
							<Button
								variant="contained"
								color="secondary"
								onClick={() =>
									Sentry.showReportDialog({
										eventId: this.state.eventId
									})
								}
							>
								Send Error report!
							</Button>
						</div>
					) : (
						<IconButton
							className="bg-disabled"
							style={{
								height: '40px',
								width: '40px'
							}}
						>
							<ErrorIcon color={'secondary'} />
						</IconButton>
					)}
				</>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
