import React from 'react';
import { Field } from 'formik';
import AsyncSelectJs from '../../../../shared/components/Fields/AsyncSelectJs';
import { KntTwilioStyle } from '../../../../shared/services';
import { useAppState } from '../../../../shared/state';
import { useTranslation } from 'react-i18next';
import { useTranslationHelpers } from 'shared/hooks/useTranslationHelpers';
import clsx from 'clsx';

type Props = {
	isDisabled?: boolean;
};

export function CreateCasePriority({ isDisabled = false }: Props) {
	const { companyId } = useAppState();
	const { t } = useTranslation();
	const { isRTL } = useTranslationHelpers();
	return (
		<div className="react-select-dropdown mb-4 flex items-center">
			<label
				className={clsx('mb-2 block text-xs font-light text-kgrey-welcome', {
					'mr-4': !isRTL,
					'ml-4': isRTL
				})}
			>
				<span>{t('priority_hint')}</span>
			</label>
			<Field
				className="w-full"
				component={AsyncSelectJs}
				customStylesProps={KntTwilioStyle}
				name="priority"
				placeholder={t('select_priority')}
				optionLabel="name"
				optionValue="code"
				isSearchable
				isCached
				maxMenuHeight={150}
				isDisabled={isDisabled}
				loadOptionsUrl={`/${companyId}/cases/as_business/priorities/`}
				loadOptionsParams={(search: string) => ({
					extra: {
						search
					}
				})}
				loadOptionsKey="choices"
			/>
		</div>
	);
}
