import React from 'react';

export function IconHouse({ className = '' }: { className?: string }) {
	return (
		<svg
			className={className}
			width="16"
			height="17"
			viewBox="0 0 16 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.85875 0.577499C8.62667 0.358079 8.31939 0.235821 8 0.235821C7.68061 0.235821 7.37334 0.358079 7.14125 0.577499L1.08625 6.3C0.900853 6.47544 0.753228 6.68689 0.652421 6.92139C0.551614 7.15589 0.499748 7.4085 0.500001 7.66375V14.6263C0.500332 15.1233 0.698023 15.5999 1.04962 15.9513C1.40121 16.3026 1.87794 16.5 2.375 16.5H4.25C4.74728 16.5 5.2242 16.3025 5.57583 15.9508C5.92746 15.5992 6.125 15.1223 6.125 14.625V11.5C6.125 11.3342 6.19085 11.1753 6.30806 11.0581C6.42527 10.9408 6.58424 10.875 6.75 10.875H9.25C9.41576 10.875 9.57473 10.9408 9.69194 11.0581C9.80915 11.1753 9.875 11.3342 9.875 11.5V14.625C9.875 15.1223 10.0725 15.5992 10.4242 15.9508C10.7758 16.3025 11.2527 16.5 11.75 16.5H13.625C14.1223 16.5 14.5992 16.3025 14.9508 15.9508C15.3025 15.5992 15.5 15.1223 15.5 14.625V7.6625C15.4999 7.40737 15.4478 7.15494 15.3468 6.92066C15.2457 6.68639 15.098 6.47519 14.9125 6.3L8.85875 0.574999V0.577499Z"
				fill="#A5A7B2"
			/>
		</svg>
	);
}
