import React from 'react';
import TooltipMui, { TooltipProps } from '@material-ui/core/Tooltip';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
	createStyles({
		tooltip: {
			backgroundColor: '#f4f4f4',
			paddingLeft: '16px',
			paddingRight: '16px',
			paddingTop: '10px',
			paddingBottom: '10px',
			lineHeight: 1,
			fontSize: '12px',
			fontWeight: 500,
			borderRadius: '10px',
			color: '#2D2D2D'
		}
	})
);

// eslint-disable-next-line react/function-component-definition
export const Tooltip: React.FC<TooltipProps> = ({ children, ...rest }) => {
	const classes = useStyles();
	return (
		<TooltipMui {...rest} classes={{ tooltip: classes.tooltip }}>
			{children}
		</TooltipMui>
	);
};
