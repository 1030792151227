import React, { useState } from 'react';
import { CaseDetailsRow } from './CaseDetailsRow';
import { useAppState } from 'shared/state';
import config from 'config';
import EntityContainer from 'modules/entity/containers';
import { ReassignedRelatedCase } from 'shared/interfaces';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { Button, ConfirmModal } from 'shared/components';
import { CaseTypes } from 'shared/state/reducer';
import { useQueryParams } from 'shared/hooks';
import { Chip, Skeleton } from 'shared/components/Elements';
import get from 'lodash/get';

type Props = {
	caseId: number;
	closeCaseDetailModal: () => void;
	caseTitle: string;
};

export function CDCopyToStaffCase({ caseId, closeCaseDetailModal, caseTitle }: Props) {
	const { caseType, companyId } = useAppState();

	return (
		<>
			{caseType === config.CLIENT && (
				<EntityContainer.All
					entity={'related'}
					name={`Related-${caseId}`}
					url={`/${companyId}/cases/as_business/${caseId}/related/`}
					params={{}}
					dataKey={(data: any) => data}
					primaryKey={'id'}
				>
					{({ items, isFetched }) => {
						return (
							<>
								{isFetched ? (
									<CDCopyToStaffCaseComponent
										relatedCases={items as ReassignedRelatedCase[]}
										closeCaseDetailModal={closeCaseDetailModal}
										caseTitle={caseTitle}
										caseId={caseId}
									/>
								) : (
									<Skeleton />
								)}
							</>
						);
					}}
				</EntityContainer.All>
			)}
		</>
	);
}

type CDCopyToStaffCaseComponentProps = {
	relatedCases: ReassignedRelatedCase[];
	closeCaseDetailModal: () => void;
	caseTitle: string;
	caseId: number;
};
export function CDCopyToStaffCaseComponent({
	relatedCases,
	closeCaseDetailModal,
	caseTitle,
	caseId
}: CDCopyToStaffCaseComponentProps) {
	const { userData } = useAuth();
	const { setCaseType, showCaseCreatePopup, isAdmin } = useAppState();
	const { changeUrlParams } = useQueryParams();
	const [goToCaseModal, setGoToCaseModal] = useState<null | ReassignedRelatedCase>(null);
	const closeClientCaseGotoPopup = () => setGoToCaseModal(null);

	const openConfirmPopup = (relatedCase: ReassignedRelatedCase) => setGoToCaseModal(relatedCase);

	const handleCaseCreate = () => {
		//Close Case Details Popup
		closeCaseDetailModal();

		//Change CaseType to Clients
		setCaseType(config.STAFF as CaseTypes);

		//Open Create Case Form to create Staff Case from Client case
		showCaseCreatePopup();
		changeUrlParams({ copyToStaff: 'true', caseTitle, caseId });
	};

	//Click handler to open Client case
	const openCaseHandler = (relatedTo: ReassignedRelatedCase) => {
		//Close ReassignCaseGoTo Popup
		closeClientCaseGotoPopup();

		//Close Case Details Popup
		closeCaseDetailModal();

		//Change CaseType to Staff
		setCaseType(config.STAFF as CaseTypes);

		//Open Client Case
		changeUrlParams({ status: relatedTo.status_id, case: relatedTo.id });
	};

	const noReassignedCaseWasCreatedForCurrentUser = !relatedCases
		.reduce((acc, cs) => [...acc, cs?.reassigned_by?.id], [] as number[])
		.includes(userData.id);

	const openCaseFromConfirm = () => () => openCaseHandler(goToCaseModal!);

	const filteredRelatedCases = relatedCases.filter(
		(item) => get(item, 'reassigned_by.id') === userData.is || isAdmin
	);

	return (
		<>
			{noReassignedCaseWasCreatedForCurrentUser && (
				<CaseDetailsRow fieldName="Copy to a new staff case:">
					{/* Show Case Create button if current user has not created any case from current client case*/}
					<Button onClickHandler={handleCaseCreate}>Create</Button>
				</CaseDetailsRow>
			)}

			{filteredRelatedCases.length ? (
				<CaseDetailsRow fieldName="Related Staff Case(s)">
					{/*Show created related staff case from current user or from all other users if current user is Admin or manager*/}
					{filteredRelatedCases.map((item) => (
						<Chip
							label={item.case_number}
							onClick={() => openConfirmPopup(item)}
							variant="outlined"
							color="primary"
							key={item.case_number}
							className="!mr-2"
						/>
					))}
				</CaseDetailsRow>
			) : null}

			<ConfirmModal
				featureHandler={openCaseFromConfirm()}
				isOpen={Boolean(goToCaseModal)}
				toggleHandler={closeClientCaseGotoPopup}
				title="Are You Sure To Open Related Staff Case?"
			/>
		</>
	);
}
