/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
// import { compose } from 'recompose';
import isEqual from 'lodash/isEqual';
// import PropTypes from 'prop-types';

import { ILoadOneProps, IRootState, QueryType, ILoadOneActionProps } from 'shared/interfaces';
import { loadOneRequest } from '../actions';

import Selectors from '../selectors';
import { schema } from 'normalizr';

interface DispatchProps {
	LoadOne: ({
		entity,
		name,
		url,
		params: { fields, include, extra },
		primaryKey,
		relations,
		cb
	}: ILoadOneActionProps) => void;
}

interface StateProps {
	// eslint-disable-next-line @typescript-eslint/ban-types
	item: {};
	isFetched: boolean;
}

interface OwnProps {
	children: ({ isFetched, item }: StateProps) => React.ReactElement;
}

type Props = StateProps & ILoadOneProps & OwnProps & DispatchProps;

export class One extends Component<Props> {
	static defaultProps = {
		initialLoad: true,
		primaryKey: 'id'
	};

	componentDidMount() {
		const {
			id,
			entity,
			name,
			url,
			params,
			initialLoad = true,
			primaryKey = 'id',
			relations,
			onError = () => {}
		} = this.props;
		if (initialLoad) {
			this.Load(id, entity, name, url, params, primaryKey, relations, onError);
		}
	}

	componentDidUpdate(prevProps: ILoadOneProps) {
		const {
			id,
			entity,
			name,
			url,
			params,
			primaryKey = 'id',
			relations,
			onError = () => {}
		} = this.props;
		if (
			!isEqual(id, prevProps.id) ||
			!isEqual(url, prevProps.url) ||
			!isEqual(params, prevProps.params)
		) {
			this.Load(id, entity, name, url, params, primaryKey, relations, onError);
		}
	}

	Load = (
		id: string | number,
		entity: string,
		name: string,
		url: string,
		{ fields = [], include = [], extra = {} }: QueryType,
		primaryKey: string | schema.SchemaFunction,
		relations: string,
		onError: (data: any) => void
	) => {
		const { LoadOne } = this.props;
		LoadOne({
			id,
			entity,
			name,
			url,
			params: { fields, include, extra },
			primaryKey,
			relations,
			cb: {
				error: (data) => {
					onError(data);
				}
			}
		});
	};

	render() {
		const { item, isFetched, children } = this.props;

		return children({ item, isFetched });
	}
}

const mapStateToProps = () => {
	const getOne = Selectors.getOne();
	return (state: IRootState, props: ILoadOneProps) => {
		const { item, isFetched } = getOne(state, props);
		return { item, isFetched };
	};
};

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			LoadOne: loadOneRequest
		},
		dispatch
	);

export default connect<StateProps, DispatchProps, ILoadOneProps, IRootState>(
	mapStateToProps,
	mapDispatchToProps
)(One);
