import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type FooterNavItem = {
	url: string;
	text: string;
};

export function Footer() {
	const { t } = useTranslation();
	const navItems = [
		{ url: '/about-us', text: 'About Us ' },
		{ url: '/blog', text: 'Blog' },
		{ url: '/licence', text: 'License' }
	];

	return (
		<div className="flex flex-col  items-center justify-center py-6 text-kgrey-variant-2 sm:flex-row sm:justify-between">
			<div className={'mb-4 text-sm font-medium sm:mb-0'}>
				{t('all_rights_reserved')}
				<strong className={'uppercase  text-kdark dark:text-white'}>Kennekt</strong> ©{' '}
				{new Date().getFullYear()}
			</div>
			{/*<div className={'flex items-center'}>*/}
			{/*	{navItems.map((item: FooterNavItem) => (*/}
			{/*		<Link*/}
			{/*			key={item.url}*/}
			{/*			to={item.url}*/}
			{/*			className={'text-sm ml-3.5 mr-3.5 transition hover:text-kdark'}>*/}
			{/*			{item.text}*/}
			{/*		</Link>*/}
			{/*	))}*/}
			{/*</div>*/}
		</div>
	);
}
