import dayjs from 'dayjs';
import { convertUnixToTimeUnits } from './convertUnixToTimeUnits';

export function getSubscriptionRemainedTIme(endDate: string) {
	const currentDateUnix = Date.now();
	const endDateInUnix = dayjs(endDate).valueOf();
	const remainingTime = endDateInUnix - currentDateUnix;

	return convertUnixToTimeUnits(remainingTime).split(':');
}
