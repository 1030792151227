import { SingleValueProps, OptionProps, components } from 'react-select';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace LanguageSelectCustomComponents {
	export const SingleValue = ({ children, data, ...props }: SingleValueProps<any>) => {
		return (
			<components.SingleValue data={data} {...props}>
				<div className="flex w-full items-center justify-center gap-x-0.5 text-sm font-normal">
					<div className="h-4 w-6 px-0.5">
						<img
							className="h-full w-full object-cover"
							src={data?.icon?.thumbnail}
							alt={data?.label}
						/>
					</div>
					{data?.shortLabel}
				</div>
			</components.SingleValue>
		);
	};

	export const Option = ({ data, children, ...props }: OptionProps<any, any>) => {
		return (
			<components.Option data={data} {...props}>
				<div className="flex w-full items-center gap-x-0.5 text-sm font-normal">
					<img className="h-4 w-6 px-0.5" src={data?.icon?.thumbnail} alt={data?.label} />{' '}
					{children}
				</div>
			</components.Option>
		);
	};
}
