/** @jsxImportSource @emotion/react */
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import EntityContainer from 'modules/entity/containers';
import { IMessage, IRootState } from 'shared/interfaces';
import useDimensions from 'react-use-dimensions';
import { isSafari } from 'react-device-detect';
import clsx from 'clsx';
import { useChatContext } from 'shared/contexts/ChatContext';
import { ErrorBoundary } from 'shared/components';
import {
	ChatMessage,
	ChatMessagesLoader,
	VirtuosoCustomItem,
	VirtuosoFooter,
	VirtuosoList,
	VirtuosoScrollWrapper
} from 'shared/components/Chat/components';
import { useChatMessagesList } from 'shared/hooks/chat-hooks/useChatMessagesList';
import VizSensor from 'react-visibility-sensor';
import { useChatInputContext } from 'shared/contexts';
import { useOnInit, useQueryParams } from 'shared/hooks';
import { useAppState } from 'shared/state';
import { useConfirmedBtnActions } from '../hooks/useConfirmedBtnActions';
import { checkMsgAuthor, getChatMessagesEntityName } from 'shared/components/Chat/utils';
import { Virtuoso } from 'react-virtuoso';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

type Props = {
	caseId: number;
};

const SHOW_LOGS = true;

export function CaseMessagesListInner({ caseId }: Props) {
	const { t } = useTranslation();
	const [chatBodyRef, { height: chatBodyHeight }] = useDimensions();
	const { setIsDelayedMessage, isDelayedMessage } = useChatInputContext();
	const { isScheduledChat } = useChatContext();
	const { afterMessagesFetchService } = useOnInit();
	const { entityPage, unreadMessagesCount } = useAppState();
	const { isConfirmationRequired } = useConfirmedBtnActions();
	const entitiesObjectInState = useSelector((state: IRootState) => state.entities);

	const { query } = useQueryParams();
	const lastReadMsgIdFromQuery = (query.unread && Number(query.unread)) || null;
	const hasUnreadCached = useMemo(() => !!unreadMessagesCount?.[caseId], [caseId]);
	const hasUnread = !!unreadMessagesCount?.[caseId];
	const firstUnread = useRef<null | number | undefined>(null);

	const {
		entityId,
		isSetSearchReply,
		allowToLoad,
		setAllowToLoad,
		direction,
		messagesLimit,
		replyLoading,
		scrollToMsgId,
		state,
		setScrollPosition,
		isMsgHasBeenIndicated,
		virtuosoRef,
		entityType,
		isBottomLoading,
		setBottomLoading
	} = useChatContext();
	const {
		cursorTop,
		cursorBot,
		handlePageLoading,
		localEntityId,
		scrollIntoElement,
		isLoadedRepliedMessage,
		messageVisibleHandler,
		setUpdateEntityPage,
		searchedMessageId,
		paginationDepsRef,
		cursor,
		isLastPageFetched,
		setIsLastPageFetched,
		isFirstPageFetched,
		setIsFirstPageFetched,
		totalMessagesCount,
		removeUnreadCountFromCaseList,
		messagesUrl
	} = useChatMessagesList();

	if (process.env.NODE_ENV === 'development' && SHOW_LOGS) {
		console.log('CaseMessagesList', {
			isLastPageFetched,
			entityPage,
			allowToLoad,
			direction,
			state,
			cursorBot,
			cursorTop,
			isLoadedRepliedMessage,
			scrollToMsgId,
			isSetSearchReply,
			replyLoading,
			searchedMessageId,
			cursor,
			isFirstPageFetched,
			paginationDepsRef,
			totalMessagesCount,
			isBottomLoading
		});
	}

	const shouldStartFromFirstUnread = lastReadMsgIdFromQuery && direction === null;
	const entityName = getChatMessagesEntityName({
		isScheduled: isScheduledChat,
		entityType,
		entityId: localEntityId
	});

	if (!entitiesObjectInState?.['cases']?.[caseId]) {
		return <ChatMessagesLoader />;
	}

	return (
		<EntityContainer.All
			entity="caseMessages"
			name={entityName}
			url={messagesUrl}
			primaryKey={'custom_uuid' || 'id'}
			prependData={(direction === 'next' || direction === null) && !isSetSearchReply}
			appendData={direction === 'previous' && !isSetSearchReply}
			isUniq
			cursorBased
			direction={
				lastReadMsgIdFromQuery && direction === null
					? '1'
					: cursor === Number(scrollToMsgId)
					? '0'
					: direction === 'next' || direction === null
					? '-1'
					: '1'
			}
			replaceIds={
				(isLoadedRepliedMessage && cursor === scrollToMsgId && direction === null) ||
				//Fix prepended messages issues which had to be appended on case open. This is happened when reopened already opened and closes case which had new unread messages(2158, 2170)
				(cursor === null && (direction === 'next' || direction === null)) ||
				//TODO:MIGHT BE BREAKING CHANGE
				(hasUnread && !!lastReadMsgIdFromQuery)
			}
			params={{
				limit: messagesLimit,
				extra: {
					entity_id: localEntityId,
					cursor
					// cursor: cursor === null && firstUnreadMsg ? firstUnreadMsg : cursor
				}
			}}
			onInit={(data) => {
				afterMessagesFetchService({
					data,
					isDelayedMessage,
					setIsDelayedMessage,
					setAllowToLoad,
					direction,
					scrollToMsgId,
					isMsgHasBeenIndicated,
					cursorTop,
					cursorBot,
					scrollIntoElement,
					setUpdateEntityPage,
					searchedMessageId,
					paginationDepsRef,
					cursor,
					setIsLastPageFetched,
					messagesLimit,
					setIsFirstPageFetched,
					setBottomLoading,
					isLoadedRepliedMessage,
					virtuosoRef
				});
			}}
		>
			{({ items, isFetched, meta }) => {
				const messagesList = (items as IMessage[]) ?? [];
				const anywayAllowToLoadIfNotInScrollAndIndicateMode = allowToLoad || !isSetSearchReply;

				return (
					<div ref={chatBodyRef} id={'chat-body'} className={clsx(`chat__body flex-grow`)}>
						<div
							className={clsx('h-full px-2 lg:px-5', { 'pt-11': !isScheduledChat })}
							css={css`
								height: ${isSafari && `${chatBodyHeight}px`};
							`}
						>
							{/*Messages start*/}

							{meta && messagesList.length >= 0 ? (
								<Virtuoso
									context={{
										isFetched,
										items,
										isSetSearchReply,
										direction,
										replyLoading,
										isConfirmationRequired
									}}
									ref={virtuosoRef}
									firstItemIndex={meta?.firstItemIndex}
									increaseViewportBy={{ top: 800, bottom: 800 }}
									initialTopMostItemIndex={{
										index: shouldStartFromFirstUnread ? 0 : 'LAST',
										align: shouldStartFromFirstUnread ? 'start' : 'end',
										behavior: 'auto',
										offset: shouldStartFromFirstUnread ? 0 : 100
									}}
									atTopStateChange={(atTop) => {
										if (atTop) {
											if (
												anywayAllowToLoadIfNotInScrollAndIndicateMode && // allowToLoad is needed when open case from search
												isFetched &&
												!isFirstPageFetched
											) {
												handlePageLoading(true, 'next', entityId);
											}
										}
									}}
									atBottomStateChange={(atBottom) => {
										if (atBottom) {
											//todo: refactor this

											// if (isLastPageFetched) {
											setScrollPosition(true);
											// }

											//remove unread count if we react the end of chat and there is still unread count left
											if (isLastPageFetched && !!unreadMessagesCount?.[caseId]) {
												removeUnreadCountFromCaseList();
											}

											if (allowToLoad && isFetched && !isLastPageFetched) {
												setBottomLoading(true);
												handlePageLoading(true, 'previous', entityId);
											}
										}
										if (!atBottom) {
											setScrollPosition(false);
										}
									}}
									data={messagesList}
									itemContent={(_, item) => {
										const tempMessage = {
											id: 100000000,
											name: 'Error message',
											text: 'Error occured. This message is not valid',
											type: 'ERROR',
											from_user: {}
										};
										const idx = messagesList.findIndex((message) => message.id === item.id);

										//Set isFirstUnread to true if case has unread, not searched case
										if (
											Number(query?.lastReadMessage) === item.id &&
											hasUnreadCached &&
											!searchedMessageId
										) {
											firstUnread.current = messagesList[idx + 1]?.id;
										}
										const isFirstUnread =
											firstUnread.current && item.id && firstUnread.current === item.id;

										const mapKey = item ? item?.custom_uuid || item.id : tempMessage.id;

										const { isSameFromUserWithPrev, isSameFromUserWithNext } = checkMsgAuthor({
											currentMsgAuthorId: item.from_user.id,
											prevMsgAuthorId: messagesList[idx - 1]?.from_user.id,
											nextMsgAuthorId: messagesList[idx + 1]?.from_user.id,
											prevMsgType: messagesList[idx - 1]?.type,
											nextMsgType: messagesList[idx + 1]?.type
										});

										const content = (
											<>
												{isFirstUnread && messagesList?.length > 1 ? (
													<div className="my-2 mx-0 w-full bg-kgrey-inputBg text-center text-kdark-424242 dark:bg-kdark-dark333 dark:text-white">
														{t('unread_messages')}
													</div>
												) : null}
												<ChatMessage
													key={mapKey}
													message={item || tempMessage}
													isSameFromUserWithPrev={isSameFromUserWithPrev}
													isSameFromUserWithNext={isSameFromUserWithNext}
												/>
											</>
										);
										return (
											<VizSensor
												key={mapKey}
												offset={{ bottom: 50 }}
												scrollDelay={50}
												onChange={(isVisible) => {
													messageVisibleHandler(isVisible, item);
												}}
											>
												<div>{content}</div>
											</VizSensor>
										);
									}}
									components={{
										Scroller: VirtuosoScrollWrapper,
										List: VirtuosoList,
										Item: VirtuosoCustomItem,
										Footer: !isLastPageFetched && isBottomLoading ? VirtuosoFooter : undefined
									}}
								/>
							) : (
								<ChatMessagesLoader />
							)}
							{/*Messages end*/}
						</div>
					</div>
				);
			}}
		</EntityContainer.All>
	);
}

export function CaseMessagesList(props: Props) {
	const resetKeys = useMemo(() => props.caseId, [props.caseId]);

	const resetHandler = useCallback(() => {
		window.location.reload();
	}, []);

	return (
		<ErrorBoundary resetKeys={resetKeys as unknown as unknown[]} onResetHandler={resetHandler}>
			<CaseMessagesListInner {...props} />
		</ErrorBoundary>
	);
}
