import { IMessage, ScrollToMsg } from 'shared/interfaces';
import { useMessageFields } from 'shared/hooks/chat-hooks/useMessageFields';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { RepliedMediaMsgWrapper } from './RepliedMediaMsgWrapper';
import { useChatInputContext } from 'shared/contexts/ChatInputContext';
import { LoaderWithProgress } from 'shared/components/Loader';
import { useChatContext } from 'shared/contexts/ChatContext';
import { formSuccess } from 'modules/entity/actions';
import { useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { useGetEntity, useQueryParams, useWhiteModal } from '../../../hooks';
import Lightbox from 'react-awesome-lightbox';
import 'react-awesome-lightbox/build/style.css';
import { EmojiCountInfo } from './EmojiCountInfo';
import clsx from 'clsx';
import { useCachedReactedUsers } from '../../../hooks/chat-hooks/useCachedReactedUsers';
import { DeletedMsg } from './DeletedMsg';
import { useTranslation } from 'react-i18next';
import { useTranslationHelpers } from 'shared/hooks/useTranslationHelpers';

type Props = {
	message: IMessage;
	setScrollToMsgId: ScrollToMsg;
};

const MAX_IMAGE_HEIGHT = 430;
const MAX_IMAGE_WIDTH = 480;

function calculateAspectRatioFit(
	srcWidth: number,
	srcHeight: number,
	maxWidth = MAX_IMAGE_WIDTH,
	maxHeight = MAX_IMAGE_HEIGHT
) {
	const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

	return { width: srcWidth * ratio, height: srcHeight * ratio };
}

function areEqual(prev: any, next: any) {
	return (
		prev.message?.is_deleted === next.message?.is_deleted &&
		prev.message.file?.url === next.message.file?.url &&
		prev.message.custom_uuid === next.message.custom_uuid &&
		isEqual(prev.message?.replied_to, next.message?.replied_to) &&
		prev.message?.id === next.message?.id &&
		isEqual(prev.message?.reacted_users, next.message?.reacted_users) &&
		prev.message?.message_id === next.message?.message_id
	);
}

export const ImageMsg = memo(function ImageMsg({ message, setScrollToMsgId }: Props) {
	const {
		is_deleted,
		file,
		replied_to,
		isOwnMsg,
		custom_uuid,
		messageId,
		reacted_users,
		file_type
	} = useMessageFields(message);
	const { t } = useTranslation();
	const { isRTL } = useTranslationHelpers();
	const { changeUrlParams, removeOneQueryParam, query } = useQueryParams();
	const entityId = Number(query?.case) || Number(query?.chat);
	const [toggler, setToggler] = useState(false);
	const [dimensions, setDimension] = useState<{ width: number; height: number } | null>(null);
	const { uploadMeta, setCancelSending, editingMessage } = useChatInputContext();
	const { entityType, entityName } = useChatContext();
	const { all: entityMessages } = useGetEntity({
		entity: `${entityType}Messages`,
		entityName,
		entityId
	});
	const currentMessageImage = file?.url as string;
	const imageArray = entityMessages.items
		?.filter((message: IMessage) => message.file_type === 'IMAGE' && message.file)
		.map((message: IMessage) => message?.file?.url);

	const progress = Number(uploadMeta[custom_uuid]);
	const dispatch = useDispatch();
	const {
		open: isEmojiCountOpen,
		modalHandler: isEmojiCountOpenHandler,
		WhiteModal: EmojiWhiteModal
	} = useWhiteModal();

	const { cachedReactions } = useCachedReactedUsers({
		reacted_users,
		file_type,
		isEmojiCountOpenHandler
	});

	const cancelClickHandler = useCallback(
		(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			e.stopPropagation();
			setCancelSending(custom_uuid);

			dispatch(
				formSuccess({
					entity: `${entityType}Messages`,
					name: entityName,
					id: custom_uuid,
					deleteData: true,
					appendData: false,
					updateData: false,
					prependData: false
				})
			);
		},
		[entityType, entityType, custom_uuid]
	);

	const openImage = () => {
		setToggler((prev) => !prev);
		changeUrlParams({ isMediaActive: true });
	};
	const closeImage = () => {
		setToggler(false);
		removeOneQueryParam('isMediaActive');
	};

	const messageNativeWidth = message?.file?.width;
	const messageNativeHeight = message?.file?.height;

	//we have to calculate size on client side only if there is no size from backend. Actual for old messages
	useEffect(() => {
		const getMeta = (url: string, cb: any) => {
			const img = new Image();
			img.onload = () => cb(null, img);
			img.onerror = (err) => cb(err);
			img.src = url;
		};

		// if (message.file?.url) {
		if (!messageNativeWidth && !messageNativeHeight) {
			// Use like:
			getMeta(message?.file?.url as string, (err: any, img: any) => {
				const { width, height } = calculateAspectRatioFit(img?.naturalWidth, img?.naturalHeight);

				const computedHeight = img?.naturalHeight > MAX_IMAGE_HEIGHT ? height : img?.naturalHeight;
				const computedWidth = img?.naturalWidth > MAX_IMAGE_WIDTH ? width : img?.naturalWidth;

				setDimension({
					width: computedWidth,
					height: computedHeight
				});
			});
		}
	}, [messageNativeWidth, messageNativeHeight]);

	if (is_deleted) {
		return <DeletedMsg isOwnMsg={isOwnMsg} type="image" />;
	}

	return (
		<div
			className={clsx(
				'overflow-hidden break-words text-sm text-kdark shadow-text-msg dark:text-white',
				{
					'rounded-b-xl rounded-tr-xl bg-white dark:bg-white/20': !isOwnMsg,
					'rounded-t-xl rounded-bl-xl bg-primary-200': isOwnMsg
				}
			)}
		>
			<RepliedMediaMsgWrapper
				replied_to={replied_to}
				isOwnMsg={isOwnMsg}
				setScrollToMsgId={setScrollToMsgId}
			>
				<div
					className={clsx(
						'toggler-msg flex-centered  relative  w-full  overflow-hidden bg-white',
						`max-h-[430px]  max-w-[480px]`
					)}
					style={{
						height: `${(messageNativeHeight || dimensions?.height) ?? MAX_IMAGE_HEIGHT}px`,
						width: `${(messageNativeWidth || dimensions?.width) ?? MAX_IMAGE_HEIGHT}px`
					}}
				>
					<img
						onClick={openImage}
						src={currentMessageImage}
						alt={file?.name}
						className={'block h-full w-full object-cover'}
					/>
					{toggler ? (
						<span
							className={clsx(
								{ 'lb-custom-wrapper': imageArray.length === 1 },
								'z-10000 absolute -top-0.5 -right-3'
							)}
						>
							<Lightbox
								images={imageArray}
								keyboardInteraction={imageArray.length > 1}
								onClose={closeImage}
								startIndex={imageArray.indexOf(currentMessageImage)}
							/>
						</span>
					) : null}
					{custom_uuid in uploadMeta && (!messageId || editingMessage) && (
						<div
							className={
								'absolute top-auto left-auto right-auto bottom-auto z-10 h-12 w-12 cursor-pointer rounded-full bg-white/70'
							}
							onClick={cancelClickHandler}
						>
							{progress ? (
								<>
									<div className={'absolute top-1 left-1'}>
										<LoaderWithProgress
											value={Number(uploadMeta[custom_uuid])}
											variant={'indeterminate'}
										/>
									</div>
									<span
										className={clsx(
											'absolute top-12  mt-1 rounded-xl bg-white/70 p-2 text-sm leading-none underline hover:no-underline dark:bg-kdark-dark333',
											{
												'-left-2': !isRTL,
												'left-1': isRTL
											}
										)}
									>
										{t('cancel')}
									</span>
								</>
							) : null}
						</div>
					)}
				</div>
				<div className="px-2">{reacted_users?.length ? cachedReactions : null}</div>
				<EmojiWhiteModal handleModalOpen={isEmojiCountOpenHandler} open={isEmojiCountOpen}>
					<EmojiCountInfo messageId={messageId} reactedUsers={reacted_users} />
				</EmojiWhiteModal>
			</RepliedMediaMsgWrapper>
		</div>
	);
}, areEqual);
