import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

export const useRouter = () => {
	const location = useLocation();
	const history = useHistory();
	const params = useParams();
	const match = useRouteMatch();
	const [path, setPath] = useState(location.pathname);

	// Watch for location pathname change
	useEffect(() => {
		setPath(location.pathname);
	}, [location.pathname]);

	return {
		history,
		location,
		path,
		params,
		match
	};
};
