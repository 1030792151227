import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
	convertUTCDateToLocalDate,
	createModifiedOptions,
	days,
	recurrConfigCreator,
	repeat_types,
	weekDays
} from './recurring.helper';
import { RecurrOptionsElements } from './RecurringOptionsElements';
import { useTranslation } from 'react-i18next';

type Props = {
	recurring: any;
	editMode?: boolean;
	updateRecurringModal?: any;
	currentDay: any;
	currentMonthDate: any;
	currentDayObj: any;
	onCloseRecurrDialog: any;
	stateHandler: any;
	recurringOptions: any;
	selectedTime: any;
	ON_WEEK_DAY: any;
};

export function RecurringOptionsForm({
	currentDay,
	currentMonthDate,
	currentDayObj,
	onCloseRecurrDialog,
	stateHandler,
	recurringOptions,
	selectedTime,
	ON_WEEK_DAY,
	editMode = false,
	recurring = {},
	updateRecurringModal = () => {}
}: Props) {
	const { t } = useTranslation();
	const ON_DATE = `day ${currentMonthDate}`;

	const state = editMode
		? {
				repeat_every: recurring.repeat_every || 1,
				repeat_type: recurring.repeat_type || 'DAY',
				monday: recurring.monday || false,
				tuesday: recurring.tuesday || false,
				wednesday: recurring.wednesday || false,
				thursday: recurring.thursday || false,
				friday: recurring.friday || false,
				saturday: recurring.saturday || false,
				sunday: recurring.sunday || false,
				end_type: recurring.end_type || 'NEVER',
				month_repeat_type: recurring.month_repeat_type || 'ON_DATE',
				month_repeat_val: recurring.month_repeat_val || 0,
				occurrences: recurring.occurrences || 1,
				end_date: recurring.end_date || '',
				month_repeat_type_text: recurring.month_repeat_type_text || ''
		  }
		: {
				repeat_every: 1,
				repeat_type: 'DAY',
				monday: false,
				tuesday: false,
				wednesday: false,
				thursday: false,
				friday: false,
				saturday: false,
				sunday: false,
				recurring_text: 'generate text according to options',
				end_type: 'NEVER',
				month_repeat_type: 'ON_DATE',
				month_repeat_val: 0,
				occurrences: 1,
				end_date: '',
				month_repeat_type_text: ''
		  };

	const [recurrState, setRecurrState] = useState(state);
	let endDate;
	if (editMode) {
		endDate = recurring.end_date ? new Date(recurring.end_date) : new Date();
	} else {
		endDate = new Date();
	}

	const date = convertUTCDateToLocalDate(new Date(`December 17, 1995 ${recurring?.on_time}`));

	const [selectedDate, setSelectedDate] = useState(endDate);
	const [selectedEditTime, setSelectedEditTime] = useState(
		recurring.on_time ? new Date(date) : new Date()
	);
	const [monthItems, setMonthItems] = useState([
		{ text: 'Monthly on day 29', value: 'ON_DATE' },
		{ text: 'Monthly on the last Tuesday', value: 'ON_WEEK_DAY' }
	]);

	useEffect(() => {
		setRecurrState((prev) => ({ ...prev, end_date: dayjs(selectedDate).format() }));
	}, [selectedDate]);

	const handleDateChange = (date: any) => {
		setSelectedDate(date);
	};

	const {
		repeat_every,
		repeat_type,
		month_repeat_type,
		month_repeat_val,
		end_type,
		occurrences,
		end_date,
		monday,
		tuesday,
		wednesday,
		thursday,
		friday,
		saturday,
		sunday
	} = recurrState;

	//Settings initial default options
	useEffect(() => {
		//Set current day active

		const currentWeekDay = days[currentDay > 0 ? currentDay - 1 : 6];

		let currentWeekDayValue: any;
		if (editMode && currentWeekDay in recurring) {
			currentWeekDayValue = recurring[currentWeekDay];
		} else {
			currentWeekDayValue = false;
		}

		setRecurrState((prev) => ({
			...prev,
			[currentWeekDay]: !editMode ? true : currentWeekDayValue
		}));

		//Setting  month options
		setMonthItems([
			{ text: t('monthly_on_the_date', { date: ON_DATE }), value: 'ON_DATE' },
			{ text: t('monthly_on_the_date', { date: ON_WEEK_DAY }), value: 'ON_WEEK_DAY' }
		]);
	}, []);

	//Set month_repeat_val
	useEffect(() => {
		if (month_repeat_type === 'ON_WEEK_DAY') {
			setRecurrState((prev) => ({ ...prev, month_repeat_val: currentDayObj.weekOrder }));
		}
	}, [month_repeat_type]);

	//Set month_repeat_val
	useEffect(() => {
		let endDate;

		if (editMode) {
			endDate = recurring.end_date
				? new Date(recurring.end_date)
				: new Date(Date.now() + 2 * 365 * 24 * 60 * 60 * 1000);
		} else {
			endDate = new Date(Date.now() + 2 * 365 * 24 * 60 * 60 * 1000);
		}

		if (repeat_type === 'YEAR') {
			handleDateChange(endDate);
		}
	}, [repeat_type]);

	//Handle form inputs
	const handleInput = (e: any) => {
		const {
			target: { name, value }
		} = e;
		setRecurrState((prev) => ({ ...prev, [name]: value }));
	};

	// Weekday click to select handler
	const handleWeekdayClick = (day: any) => {
		setRecurrState((prev) => ({ ...prev, [day.value]: !(recurrState as any)[day.value] }));
	};

	//Submitting options config object
	const handleRecurrSubmit = (e: any) => {
		e.preventDefault();

		const config = recurrConfigCreator({
			repeat_every,
			moment: dayjs,
			end_type,
			end_date,
			selectedTime: editMode ? selectedEditTime : selectedTime,
			repeat_type,
			occurrences,
			monday,
			tuesday,
			wednesday,
			thursday,
			friday,
			saturday,
			sunday,
			month_repeat_type,
			ON_DATE,
			ON_WEEK_DAY,
			month_repeat_val,
			currentDay,
			days,
			translate: t
		}) as any;

		stateHandler((prev: any) => ({
			...prev,
			recurring: config,
			recurringOptions: createModifiedOptions(recurringOptions, config),
			recurOption: 'CUSTOM_VALUE',
			customRecurOption: { name: config.recurring_text, value: 'CUSTOM_VALUE' }, //for edit
			recurringText: config.recurringText //for edit
		}));

		editMode ? updateRecurringModal(config) : onCloseRecurrDialog();
	};

	return (
		<form onSubmit={handleRecurrSubmit}>
			<RecurrOptionsElements
				selectedTime={editMode ? selectedEditTime : selectedTime}
				setSelectedTime={setSelectedEditTime}
				handleInput={handleInput}
				repeat_every={repeat_every}
				repeat_type={repeat_type}
				repeat_types={repeat_types}
				weekDays={weekDays}
				recurrState={recurrState}
				handleWeekdayClick={handleWeekdayClick}
				month_repeat_type={month_repeat_type}
				monthItems={monthItems}
				selectedDate={selectedDate}
				handleDateChange={handleDateChange}
				occurrences={occurrences}
				onCloseRecurrDialog={onCloseRecurrDialog}
				handleRecurrSubmit={handleRecurrSubmit}
				editMode={editMode}
				end_type={end_type}
				setFormState={stateHandler}
			/>
		</form>
	);
}
