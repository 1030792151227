import React from 'react';
import { IMessage, ScrollToMsg } from 'shared/interfaces';
import { useMessageFields } from 'shared/hooks/chat-hooks/useMessageFields';
import clsx from 'clsx';
import { RepliedMediaMsgWrapper } from './RepliedMediaMsgWrapper';
import isEqual from 'lodash/isEqual';
import { FiFile } from 'react-icons/fi';
import { useWhiteModal } from '../../../hooks';
import { useCachedReactedUsers } from '../../../hooks/chat-hooks/useCachedReactedUsers';
import { EmojiCountInfo } from './EmojiCountInfo';
import { DeletedMsg } from './DeletedMsg';

type Props = {
	message: IMessage;
	setScrollToMsgId: ScrollToMsg;
};

function areEqual(prev: any, next: any) {
	return (
		prev.message?.is_deleted === next.message?.is_deleted &&
		prev.message.file?.url === next.message.file?.url &&
		prev.message.file?.name === next.message.file?.name &&
		isEqual(prev.message.reacted_users, next.message.reacted_users) &&
		isEqual(prev.message?.replied_to, next.message?.replied_to)
	);
}

export const FileMsg = React.memo(function FileMsg({ message, setScrollToMsgId }: Props) {
	const { is_deleted, isOwnMsg, file, replied_to, reacted_users, file_type, messageId } =
		useMessageFields(message);
	const url = file?.url as string;
	const {
		open: isEmojiCountOpen,
		modalHandler: isEmojiCountOpenHandler,
		WhiteModal: EmojiWhiteModal
	} = useWhiteModal();

	const { cachedReactions } = useCachedReactedUsers({
		reacted_users,
		file_type,
		isEmojiCountOpenHandler
	});

	if (is_deleted) {
		return <DeletedMsg isOwnMsg={isOwnMsg} type="file" />;
	}

	return (
		<div
			className={clsx(
				'overflow-hidden break-words text-sm text-kdark shadow-text-msg dark:text-white',
				{
					'rounded-b-xl rounded-tr-xl bg-white dark:bg-white/20': !isOwnMsg,
					'rounded-t-xl rounded-bl-xl bg-primary-200': isOwnMsg
				}
			)}
		>
			<RepliedMediaMsgWrapper
				replied_to={replied_to}
				isOwnMsg={isOwnMsg}
				setScrollToMsgId={setScrollToMsgId}
			>
				<div
					className={clsx(
						'border border-kgrey-chatBorder px-2 py-4 text-sm text-kdark shadow-text-msg dark:text-white lg:px-5',
						{
							'rounded-b-xl rounded-tr-xl bg-white dark:bg-white/20': !isOwnMsg,
							'rounded-t-xl rounded-bl-xl bg-primary-200': isOwnMsg
						}
					)}
				>
					<div className="case-chat-file">
						<div className="flex items-center">
							<div className="flex-centered hidden h-10 w-10 rounded-md bg-kgrey-bg text-2xl text-kdark lg:flex">
								<FiFile />
							</div>
							<a className={'ml-2 block underline hover:no-underline'} download href={url}>
								{file && file.name}
							</a>
						</div>
					</div>
				</div>
			</RepliedMediaMsgWrapper>
			<div
				className={clsx({
					'p-1': reacted_users?.length
				})}
			>
				{reacted_users?.length ? cachedReactions : null}
			</div>
			<EmojiWhiteModal handleModalOpen={isEmojiCountOpenHandler} open={isEmojiCountOpen}>
				<EmojiCountInfo messageId={messageId} reactedUsers={reacted_users} />
			</EmojiWhiteModal>
		</div>
	);
}, areEqual);
