import { useDispatch } from 'react-redux';
import Actions from 'store/actions';
import { formSuccess } from '../actions';

const { formRequest } = Actions.entity;

type EntityProps = {
	method: 'get' | 'post' | 'put' | 'delete' | 'patch';
	url: string;
	entity: string;
	name: string;
	params?: any;
	deleteData?: boolean;
	id?: string | number;
	cb: {
		success?: (data: any) => void;
		error?: (error: any) => void;
		finally?: () => void;
	};
	primaryKey?: string;
	values?: any;
	relations?: string;
	normalizeData?: string | (() => void);
};

export function useEntityForm({
	method,
	url,
	entity,
	name,
	params = {},
	deleteData = false,
	id,
	cb,
	primaryKey = 'id',
	values = {},
	relations = '',
	normalizeData
}: EntityProps) {
	const dispatch = useDispatch();

	const handleRequest = () => {
		dispatch(
			formRequest({
				entity,
				name,
				method,
				url,
				deleteData,
				id,
				cb: {
					// eslint-disable-next-line @typescript-eslint/no-empty-function
					success: cb?.success || (() => {}),
					// eslint-disable-next-line @typescript-eslint/no-empty-function
					error: cb?.error || (() => {}),
					// eslint-disable-next-line @typescript-eslint/no-empty-function
					finally: cb?.finally || (() => {})
				},
				params,
				primaryKey: 'id',
				values,
				relations,
				normalizeData
			})
		);
	};

	const handleLocalDelete = (
		dynamicId?: string | number,
		dynamicEntity?: string,
		dynamicName?: string
	) => {
		const calculatedId = dynamicId || id;
		const calculatedEntity = dynamicEntity || entity;
		const calculatedName = dynamicName || name;
		if (calculatedId && calculatedEntity && calculatedName) {
			dispatch(
				formSuccess({
					id: calculatedId,
					entity: calculatedEntity,
					name: calculatedName,
					appendData: false,
					prependData: false,
					updateData: false,
					deleteData
				})
			);
		}
	};

	return {
		handleRequest,
		handleLocalDelete
	};
}
