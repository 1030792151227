import { StylesConfig } from 'react-select';
import config from 'config';
import { CaseTypes } from '../../../state/reducer';
import { isMobile } from 'react-device-detect';

export type CaseTypeSelectType = {
	id: number;
	label: CaseTypes.Client | CaseTypes.Staff;
	value: CaseTypes.Client | CaseTypes.Staff;
	name: CaseTypes.Client | CaseTypes.Staff;
};

export const CLIENT_TYPE = {
	id: 0,
	label: config.CLIENT,
	value: config.CLIENT,
	name: config.CLIENT
};
export const STAFF_TYPE = {
	id: 1,
	label: config.STAFF,
	value: config.STAFF,
	name: config.STAFF
};
export type MyOptionType = {
	label: string;
	value: string;
};
export type IsMulti = false;

export const CASE_TYPE_OPTIONS = [STAFF_TYPE, CLIENT_TYPE];

export const caseTypeSelectCustomStyles: StylesConfig<MyOptionType, IsMulti> = {
	menu: (provided) => ({
		...provided,
		width: 150,
		right: -30
	}),
	option: (provided, state) => ({
		...provided,
		backgroundColor: state.isDisabled
			? undefined
			: state.isSelected
			? '#00A09D'
			: state.isFocused
			? '#00A09D'
			: undefined,
		'&:hover': {
			backgroundColor: '#B3F1EB',
			color: '#000'
		}
	}),
	control: (provided, state) => ({
		...provided,
		minHeight: isMobile ? '24px' : '40px',
		height: isMobile ? '24px' : '40px',
		boxShadow: state.isFocused ? '0 0 0 2px #00D0BD' : '0 0 0 0 transparent',
		borderRadius: isMobile ? '5px' : '10px',
		backgroundColor: '#f4f4f4',
		borderColor: (state as any).isActive
			? '#00D0BD'
			: state.isFocused
			? '#00D0BD'
			: (state as any).isSelected
			? '#00D0BD'
			: '#f4f4f4',
		'&:hover': {
			borderColor: '#00D0BD'
		},
		paddingLeft: '3px'
	}),
	singleValue: (provided, state) => {
		return { ...provided, fontSize: isMobile ? '10px' : '12px' };
	},
	indicatorsContainer: (provided, state) => {
		return {
			...provided,
			padding: '4px'
		};
	},
	valueContainer: (provided, state) => {
		return {
			...provided,
			height: isMobile ? '16px' : '34px',
			lineHeight: isMobile ? '16px' : '34px',
			backgroundColor: 'rgb(0, 208, 189, .2)',
			borderRadius: isMobile ? '3px' : '10px',
			padding: isMobile ? '2px' : '2px 8px'
		};
	},
	indicatorSeparator: (provided, state) => ({
		...provided,
		display: 'none'
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		padding: '4px'
	})
};
