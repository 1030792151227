/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { AsyncPaginate } from 'react-select-async-paginate';
import isEmpty from 'lodash/isEmpty';
import qs from 'query-string';
import { queryBuilder, api } from 'shared/services';

// const { data } = await api.request.get(api.queryBuilder(url, { page, limit: 10, filter: filterParams(search), ...params }));

const load = async (
	search,
	prevOptions,
	page,
	url,
	loadOptionsKey,
	filterParams,
	params,
	defaultOption,
	reverse,
	pageCount = 10
) => {
	// const pageCount = 10;

	const { data } = await api.request.get(
		queryBuilder(url, {
			page,
			limit: pageCount,
			filter: filterParams(search),
			...(typeof params === 'function' ? params(search) : params)
		})
	);

	const optionCount = data.count;
	const nextUrl = data && get(data, 'next');
	const indexUrl = nextUrl && nextUrl.indexOf('?');
	const queryParams = nextUrl && nextUrl.slice(indexUrl);
	const splitted = qs.parse(queryParams);
	const currentPage = splitted && Number(splitted.page) - 1;

	let last_page;
	if (optionCount % pageCount === 0) {
		last_page = optionCount / pageCount;
	} else {
		last_page = Math.floor(optionCount / pageCount) + 1;
	}

	let options = [];

	const addSubSubDeps = (acc, curr) => {
		if (curr.sub_departments.length > 0) {
			while (curr.sub_departments.length > 0) {
				return [...acc, curr, ...curr.sub_departments.reduce(addSubSubDeps, [])];
			}
		} else {
			return [...acc, curr];
		}
	};

	if (reverse) {
		options =
			loadOptionsKey && isEmpty(defaultOption)
				? get(data, loadOptionsKey, []).reverse()
				: loadOptionsKey && !isEmpty(defaultOption)
				? !isEmpty(get(data, loadOptionsKey, [])) && currentPage !== null
					? [...get(data, loadOptionsKey, []).reverse()]
					: [...get(data, loadOptionsKey, []).reverse(), defaultOption]
				: data.reverse();
	} else {
		options =
			loadOptionsKey && isEmpty(defaultOption)
				? get(data, loadOptionsKey, []).reduce(addSubSubDeps, [])
				: loadOptionsKey && !isEmpty(defaultOption)
				? !isEmpty(get(data, loadOptionsKey, [])) && currentPage !== null
					? [...get(data, loadOptionsKey, []).reduce(addSubSubDeps, [])]
					: [...get(data, loadOptionsKey, []).reduce(addSubSubDeps, []), defaultOption]
				: data;
	}

	return {
		options: options,
		hasMore: currentPage < last_page && currentPage !== null,
		additional: { page: currentPage + 1 }
	};
};

class AsyncSelect extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { loadOptionsUrl, loadOptionsParams, isCached = false } = this.props;
		if (
			(loadOptionsUrl !== prevProps.loadOptionsUrl ||
				prevProps.loadOptionsParams !== loadOptionsParams) &&
			!isCached
		) {
			this.setState(
				{
					loading: true
				},
				() => {
					this.setState({
						loading: false
					});
				}
			);
		}
	}

	render() {
		const {
			disableOptions,
			className,
			label,
			isMulti,
			placeholder,
			options,
			field,
			optionLabel,
			optionValue,
			form: { errors, setFieldValue, setFieldTouched, touched },
			isSearchable,
			isClearable,
			menuPlacement,
			loadOptionsUrl,
			loadOptionsKey,
			loadOptionsParams = {},
			filterParams = () => ({}),
			onCreateOption,
			SelectComponent,
			isLoading,
			reverse = false,
			defaultOption = {},
			onChange,
			onClearChange,
			pageCount,
			customStylesProps,
			...rest
		} = this.props;

		const classNames = cx(
			'field-container',
			touched[field.name] && errors[field.name] && 'has-error',
			className
		);

		const customStyles = {
			indicatorSeparator: (props) => ({
				display: 'none'
			})
		};

		return (
			<div className={classNames}>
				<div className="ant-row ant-form-item">
					{label && <div className="ant-label">{label}</div>}
					{!this.state.loading && (
						<AsyncPaginate
							styles={customStyles && customStylesProps}
							id={field.name}
							name={field.name}
							debounceTimeout={300}
							onChange={(option) => {
								// console.log("option Async", option);
								setFieldValue(field.name, option);
								if (typeof onChange === 'function') {
									onChange(option);
								}
								if (typeof onClearChange === 'function' && option === null) {
									onClearChange(null);
								}
							}}
							onBlur={() => setFieldTouched(field.name, true)}
							getValue={(option) => option[optionValue]}
							getOptionLabel={(option) =>
								typeof optionLabel === 'function' ? optionLabel(option) : option[optionLabel]
							}
							getOptionValue={(option) =>
								typeof optionValue === 'function' ? optionValue(option) : option[optionValue]
							}
							value={field.value}
							additional={{ page: 1 }}
							loadOptions={(search, prevOptions, { page }) =>
								load(
									search,
									prevOptions,
									page,
									loadOptionsUrl,
									loadOptionsKey,
									filterParams,
									loadOptionsParams,
									defaultOption,
									reverse,
									pageCount
								)
							}
							isOptionDisabled={(option) =>
								disableOptions.reduce((prev, curr) => [...prev, curr.id], []).includes(option.id)
							}
							{...{
								isMulti,
								options,
								placeholder,
								isSearchable,
								isClearable,
								menuPlacement,
								onCreateOption,
								SelectComponent,
								isLoading
							}}
							{...rest}
						/>
					)}
					{touched[field.name] && errors[field.name] && (
						<div className="ant-form-explain">{errors[field.name]}</div>
					)}
				</div>
			</div>
		);
	}
}

AsyncSelect.propTypes = {
	title: PropTypes.string.isRequired,
	className: PropTypes.string,
	optionValue: PropTypes.string,
	// optionLabel: PropTypes.string,
	isSearchable: PropTypes.bool,
	menuPlacement: PropTypes.string
};

AsyncSelect.defaultProps = {
	title: '',
	className: null,
	optionValue: 'id',
	// optionLabel: "title",
	isSearchable: false,
	menuPlacement: 'bottom',
	disableOptions: [],
	loadOptionsKey: 'items'
};

export default AsyncSelect;
