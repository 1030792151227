import React, { useCallback, useEffect, useState } from 'react';
import { BsChatSquareTextFill } from 'react-icons/bs';
import { IoLogOut, IoSettingsSharp } from 'react-icons/io5';
import { IoMdSwap } from 'react-icons/io';
import { ProSidebar, SidebarContent, SidebarFooter, Menu, SubMenu } from 'react-pro-sidebar';
import { NavLink } from 'react-router-dom';
import { useAppState } from 'shared/state';
import { NavItem } from './components/NavItem';
import { IconDashboard } from '../../icons';
import { BillingWidget } from '../BillingWidget';
import { BillingWidgetTrial } from '../BillingWidgetTrial';

import { useSidebarWidgets } from '../../hooks';
import clsx from 'clsx';
import { storage } from '../../services';

import { useSelector } from 'react-redux';
import { IRootState } from '../../interfaces';
import isEmpty from 'lodash/isEmpty';
import { scrollToStatus } from '../../../pages/Cases/helpers';
import selectors from '../../../modules/entity/selectors';
import { NavItemLogout } from './components/NavItemLogout';
import { useAuth } from '../../../modules/auth/hooks/useAuth';
import { AiFillMessage } from 'react-icons/ai';
import { /* webpackPreload: true */ MobilePromptWidget } from '../VoiceRecorder/MobilePromptWidget';
import { IconCasesMessages } from '../../icons/IconCasesMessages';
import { useTranslation } from 'react-i18next';
import { useTranslationHelpers } from 'shared/hooks/useTranslationHelpers';

const DAYS_LEFT_UNTIL_SUBSCRIPTION_END = 7;
const getStatusIds = selectors.getStatusIds;

type NavItemType = {
	text: string;
	iconComponent: React.ReactNode;
	to: string;
	type: string;
	isAvailableInNoPayment: boolean;
	availableForAll: boolean;
};

type settingsMenuType = {
	text: string;
	to: string;
	activeLink: string;
};

const navItems: NavItemType[] = [
	{
		text: 'dashboard',
		iconComponent: <IconDashboard />,
		to: '/dashboard',
		type: 'asRouterLink',
		isAvailableInNoPayment: false,
		availableForAll: true
	},
	{
		text: 'cases',
		iconComponent: <IconCasesMessages />,
		to: '/case-messages',
		type: 'asRouterLink',
		isAvailableInNoPayment: false,
		availableForAll: true
	},
	{
		text: 'chats',
		iconComponent: <BsChatSquareTextFill />,
		to: '/group-chat',
		type: 'asRouterLink',
		isAvailableInNoPayment: false,
		availableForAll: true
	}
	/*{
		text: 'News',
		iconComponent: <HiNewspaper />,
		to: '/news/news-list',
		type: 'asRouterLink',
		isAvailableInNoPayment: false,
		availableForAll: true
	}*/
];

const settingsMenu = [
	{
		text: 'billing_invoices',
		to: '/payments/billing',
		isAvailableInNoPayment: true,
		availableForAll: false,
		activeLink: 'payments'
	},
	{
		text: 'business_profile',
		to: '/business-profile',
		isAvailableInNoPayment: true,
		availableForAll: true,
		activeLink: 'business-profile'
	},
	{
		text: 'clients',
		to: '/clients-management/clients',
		isAvailableInNoPayment: false,
		availableForAll: true,
		activeLink: 'clients'
	},
	{
		text: 'csv_management',
		to: '/csv',
		isAvailableInNoPayment: false,
		availableForAll: true,
		activeLink: 'csv'
	},
	// {
	// 	text: 'Checkout',
	// 	to: '/checkout',
	// 	isAvailableInNoPayment: true,
	// 	availableForAll: false
	// },
	{
		text: 'departments',
		to: '/departments?is-staff=true',
		isAvailableInNoPayment: false,
		availableForAll: true,
		activeLink: 'departments'
	},
	{
		text: 'staff',
		to: '/staffs-management',
		isAvailableInNoPayment: true,
		availableForAll: true,
		activeLink: 'staffs'
	}
];

export function Sidebar() {
	const {
		navCollapsed,
		navToggled,
		toggleSidebar,
		companyId,
		unreadCasesCountByStatus,
		isAppAvailable,
		isAdmin,
		isDarkMode
	} = useAppState();

	const { t } = useTranslation();
	const { isRTL } = useTranslationHelpers();
	const { company, userData, companies } = useAuth();
	const companyIsActive = company?.company?.status === 'ACTIVE';
	const isDefaultCompany = company?.company?.default_company_for_all;

	const hasToShowQRCode = userData?.show_qr_code_in_business;
	const initialShowWidgetValue = JSON.parse(storage.get('paymentWidget') || 'true');

	const statusIds = useSelector((state: IRootState) => getStatusIds(state, companyId));
	const { timeLeft, subscription } = useSidebarWidgets();
	const [showWidget, setShowWidget] = useState<boolean>(initialShowWidgetValue);

	const showWidgetTimeLeft = timeLeft.days < 3;
	const pageUrl = window.location.href;
	const isQaOrDev = pageUrl.includes('qa') || process.env.NODE_ENV === 'development';

	useEffect(() => {
		if (!initialShowWidgetValue) {
			setShowWidget(showWidgetTimeLeft);
		}
	}, [showWidgetTimeLeft, initialShowWidgetValue]);

	const scrollToLeftMostUnread = useCallback(() => {
		if (!isEmpty(unreadCasesCountByStatus && statusIds)) {
			const statusIdsFromUnreadCases = Object.keys(unreadCasesCountByStatus);
			const unreadStatusesIds = statusIds.filter((statusId: number) =>
				statusIdsFromUnreadCases.includes(statusId.toString())
			);

			//Scroll to Status Wrapper Element
			scrollToStatus(unreadStatusesIds[0]);
		}
	}, [unreadCasesCountByStatus, statusIds]);

	const handleShowWidget = useCallback(() => {
		setShowWidget(false);
		storage.set('paymentWidget', 'false');
	}, []);

	return (
		<div className="flex-shrink-0 font-poppins">
			<ProSidebar
				collapsed={navCollapsed}
				toggled={navToggled}
				onToggle={toggleSidebar}
				breakPoint="sm"
				rtl={isRTL}
			>
				<SidebarContent>
					{companyId ? (
						<>
							{/*<MainLogo />*/}
							<NavItem
								icon={<IoMdSwap />}
								text={t('switch_business')}
								to="/switch-business"
								isRounded={false}
								classNames="mb-4"
							/>
							{navItems
								.filter((navItem) => (isAppAvailable ? navItem : navItem.isAvailableInNoPayment))
								.map((item: NavItemType) => {
									return (
										<NavItem
											icon={item.iconComponent}
											text={item.text}
											to={item.to}
											key={item.to}
											scrollToLeftMostUnread={scrollToLeftMostUnread}
										/>
									);
								})}
							<Menu iconShape="circle">
								<SubMenu
									title={t('business_settings')}
									className={clsx({
										'pro-sidebar_submenu': isDarkMode
									})}
									icon={<IoSettingsSharp />}
								>
									{settingsMenu
										.filter((menuItem) =>
											isAppAvailable
												? isQaOrDev
													? menuItem
													: menuItem.to !== '/csv'
												: menuItem.isAvailableInNoPayment
										)
										.filter((navItem) => (isAdmin ? navItem : navItem.availableForAll))
										.map((item: settingsMenuType) => {
											return (
												<li className={'mb-2'} key={item.text}>
													<NavLink
														to={item.to}
														className={clsx(
															'business-settings-link block font-medium' +
																' text-sm leading-none !text-kgrey-dark-3' +
																' inline-block hover:!text-kdark-text',
															{
																active: pageUrl.includes(item.activeLink),
																'dark:hover:!text-white': !pageUrl.includes(item.activeLink)
															}
														)}
													>
														{t(item.text)}
													</NavLink>
												</li>
											);
										})}
								</SubMenu>
							</Menu>
							{!isDefaultCompany && (
								<NavItem
									icon={<AiFillMessage />}
									text="KENNEKT Support"
									to="/support"
									isRounded={false}
									classNames="mb-4"
								/>
							)}
						</>
					) : companies?.length > 0 && !navCollapsed ? (
						<p className="ml-4 text-primary">{t('please_select_business')}</p>
					) : null}
					{!navCollapsed && hasToShowQRCode ? (
						<div className="mt-auto">
							<MobilePromptWidget />
						</div>
					) : null}
				</SidebarContent>
				<SidebarFooter
					className={clsx({
						'pt-10': showWidget && isAdmin
					})}
				>
					{isAdmin && !navCollapsed && companyIsActive && (
						<>
							{subscription?.subscription_type === 'PRO' ? (
								<>
									{showWidget ? (
										<BillingWidget
											handleShowWidget={handleShowWidget}
											type={
												timeLeft.days < DAYS_LEFT_UNTIL_SUBSCRIPTION_END ? 'warning' : 'default'
											}
											timeLeft={timeLeft}
										/>
									) : null}
								</>
							) : (
								<BillingWidgetTrial
									timeLeft={timeLeft}
									type={timeLeft.days < DAYS_LEFT_UNTIL_SUBSCRIPTION_END ? 'warning' : 'default'}
								/>
							)}
						</>
					)}
					<NavItemLogout icon={<IoLogOut />} text={t('logout')} to="/logout" noArrow={false} />
				</SidebarFooter>
			</ProSidebar>
		</div>
	);
}
