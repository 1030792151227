import React, { useEffect } from 'react';
import { Picker } from 'emoji-mart';
import IconButton from '@material-ui/core/IconButton';
import { isMobile } from 'react-device-detect';
import { RenderReacted, useChatHelpers, useComponentVisible, useGetEntity } from 'shared/hooks';
import { EmojiType, IMessage } from 'shared/interfaces';
import clsx from 'clsx';
import { BsThreeDots } from 'react-icons/bs';
import { useAppState } from 'shared/state';
import { useChatContext } from 'shared/contexts';

//Do not delete this variable
const emojiList = [
	{
		colons: ':+1:',
		emoticons: [],
		id: '+1',
		name: 'Thumbs Up Sign',
		native: '👍',
		short_names: ['+1', 'thumbsup'],
		skin: 1,
		unified: '1f44d',
		imageUrl: ''
	},
	{
		colons: ':hearts:',
		emoticons: [],
		id: 'hearts',
		name: 'Black Heart Suit',
		native: '♥️',
		short_names: ['hearts'],
		skin: null,
		unified: '2665-fe0f',
		imageUrl: ''
	},
	{
		colons: ':clap:',
		emoticons: [],
		id: 'clap',
		name: 'Clapping Hands Sign',
		native: '👏',
		short_names: ['clap'],
		skin: 1,
		unified: '1f44f',
		imageUrl: ''
	},
	{
		colons: ':handshake:',
		emoticons: [],
		id: 'handshake',
		name: 'Handshake',
		native: '🤝',
		short_names: ['handshake'],
		skin: 1,
		unified: '1f91d',
		imageUrl: ''
	},
	{
		colons: ':confused:',
		emoticons: [],
		id: 'confused',
		name: 'Confused Face',
		native: '😕',
		short_names: ['confused'],
		skin: null,
		unified: '1f615',
		imageUrl: ''
	},
	{
		colons: ':-1:',
		emoticons: [],
		id: '-1',
		name: 'Thumbs Down Sign',
		native: '👎',
		short_names: ['-1', 'thumbsdown'],
		skin: 1,
		unified: '1f44e',
		imageUrl: ''
	},
	{
		colons: ':rage:',
		emoticons: [],
		id: 'rage',
		name: 'Pouting Face',
		native: '😡',
		short_names: ['rage'],
		skin: null,
		unified: '1f621',
		imageUrl: ''
	}
];

const MoreButton = (handler: (isVisible: boolean) => void, isVisible: boolean) => {
	return (
		<IconButton aria-owns={'emoji-all'} aria-haspopup="true" onClick={() => handler(isVisible)}>
			<BsThreeDots />
		</IconButton>
	);
};

type Props = {
	messageId: number | undefined;
	isComponentVisible: boolean;
	custom_uuid: string;
	renderReacted: (payload: RenderReacted) => void;
	coordinates: {
		top: number;
		left: number;
	};
	isOwnMsg: boolean;
	reactedUsers: EmojiType[];
	classnames?: string;
};

export const EPicker = React.forwardRef<HTMLDivElement, Props>(function Pickers(props, ref) {
	const { reactToEmoji } = useChatHelpers();

	const {
		messageId,
		isComponentVisible,
		custom_uuid,
		renderReacted,
		coordinates,
		isOwnMsg,
		reactedUsers,
		classnames
	} = props;
	const currentMessageEl = document.querySelector(`#message-${messageId}`);
	const messagesWrapperEl = document.querySelector('.chat__body');

	useEffect(() => {
		if (!isComponentVisible) setIsComponentVisibleMore(false);
	}, [isComponentVisible]);

	const {
		ref: emojiRefMore,
		isComponentVisible: isComponentVisibleMore,
		setIsComponentVisible: setIsComponentVisibleMore
	} = useComponentVisible(false);

	const { isDarkMode } = useAppState();

	const getMessagePosition = (messageEl: any, messagesWrapperEl: any) => {
		const messageWrapperCoords = messagesWrapperEl.getBoundingClientRect();
		const messageCoords = messageEl.getBoundingClientRect();
		const moreEmojiHeight = 355;
		const fromWindowTopTillMessagesWrapper = isMobile ? 105 : 116;
		const enoughSpace = messageCoords.top - fromWindowTopTillMessagesWrapper + moreEmojiHeight;
		const isThereEnoughSpace = messageWrapperCoords.height > enoughSpace;
		return isThereEnoughSpace ? 'top' : 'bottom';
	};
	return (
		<div
			className={clsx('chat-bubble__react-emoji-wrp', classnames && classnames)}
			ref={ref}
			style={{
				top: coordinates?.top,
				[`${isOwnMsg ? 'right' : 'left'}`]: 0
			}}
			onClick={(e) => {
				e.stopPropagation();
			}}
		>
			{isComponentVisible && (
				<div className="chat-bubble__react-emoji flex items-center bg-white dark:bg-kdark">
					<div className="chat-bubble__react-emoji-custom">
						<Picker
							perLine={7}
							showSkinTones={false}
							onSelect={(e) =>
								reactToEmoji({
									emoji: e,
									reacted_users: reactedUsers,
									renderReacted,
									messageId,
									custom_uuid
								})
							}
							theme={isDarkMode ? 'dark' : 'light'}
							include={['custom']}
							custom={emojiList}
						/>
					</div>

					<div className="chat-bubble__react-emoji-more">
						{!isComponentVisibleMore && MoreButton(setIsComponentVisibleMore, true)}
						{isComponentVisibleMore && MoreButton(setIsComponentVisibleMore, false)}
						<span ref={emojiRefMore}>
							{isComponentVisibleMore && (
								<Picker
									style={{
										position: 'absolute',
										right: 0,
										[getMessagePosition(currentMessageEl, messagesWrapperEl)]: '43px',
										width: '300px'
									}}
									showPreview={false}
									theme={isDarkMode ? 'dark' : 'light'}
									showSkinTones={false}
									onSelect={(e) =>
										reactToEmoji({
											emoji: e,
											reacted_users: reactedUsers,
											renderReacted,
											messageId,
											custom_uuid
										})
									}
								/>
							)}
						</span>
					</div>
				</div>
			)}
		</div>
	);
});
