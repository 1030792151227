import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import { FaUserCog } from 'react-icons/fa';
import React from 'react';

type Props = {
	clickHandler: (entity?: any) => void;
	isDisabled?: boolean;
};

export const ReAssignButton = ({ clickHandler, isDisabled = false }: Props) => {
	return (
		<div className={clsx({ 'cursor-not-allowed': isDisabled })}>
			<IconButton className="group " disabled={isDisabled} onClick={clickHandler}>
				<FaUserCog size="18px" className="text-kgrey transition group-hover:text-primary" />
			</IconButton>
		</div>
	);
};
