import React, { useEffect, useRef } from 'react';
import { KntAutoSizeTextField } from 'shared/components/Fields';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

type Props = {
	titleRef: any;
};

export function CreateCaseTitle({ titleRef }: Props) {
	const titleInputRef = useRef<any>(null);
	const { t } = useTranslation();


	useEffect(() => {
		titleInputRef.current?.focus();
	}, []);

	return (
		<Field
			labelClasses="ml-6 text-xs font-light text-kgrey-welcome mb-2 block"
			name="title"
			innerRef={(element: HTMLTextAreaElement) => (titleInputRef.current = element)}
			component={KntAutoSizeTextField}
			type="textarea"
			inputElementClassNames="!text-15px font-light text-kdark-424653 dark:text-white"
			placeholder={t('enter_case_title')}
			required
			onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
				titleRef.current = e.target.value;
			}}
		/>
	);
}
