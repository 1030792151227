import { takeLatest, all } from 'redux-saga/effects';

import { storage } from 'shared/services';

import { SystemActionTypes, SystemAction } from '../actions/system';

function* ChangeLanguage(action: SystemAction): any {
	yield storage.set('language', action.payload);
}

export default function* root(): any {
	yield all([takeLatest(SystemActionTypes.CHANGE_LANGUAGE, ChangeLanguage)]);
}
