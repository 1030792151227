import { useState } from 'react';

export function useCaseDetailsToggle() {
	const [caseDetailsOpen, setCaseDetailsOpen] = useState<null | number>(null);

	const toggleCaseDetailsOpen = (caseId?: number) =>
		setCaseDetailsOpen((prev) => (caseId ? caseId : null));

	const closeCaseDetailModal = () => toggleCaseDetailsOpen();

	return {
		toggleCaseDetailsOpen,
		caseDetailsOpen,
		closeCaseDetailModal
	};
}
