import { ReplyType } from 'shared/contexts/reducers/chatInputReducer';
import get from 'lodash/get';

export const getRepliedToId = (reply: ReplyType) => {
	return reply && get(reply, 'id')
		? get(reply, 'id')
		: get(reply, 'message_id')
		? get(reply, 'message_id')
		: null;
};
