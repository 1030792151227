import React from 'react';
import { CaseDataField } from 'shared/interfaces';
import { Table, TableBody, TableCell, TableRow } from 'shared/components/Elements';
import { useTranslation } from 'react-i18next';

type Props = {
	dataFields: CaseDataField;
};

export function DataFieldsPopup({ dataFields }: Props) {
	const { t } = useTranslation();
	if (Array.isArray(dataFields)) {
		return (
			<div>
				<div className="mb-5 pl-4 text-2xl font-bold dark:text-white">
					{t('case_chat_data_fields')}
				</div>
				<Table>
					<TableBody>
						{dataFields.map(([field, value]) => {
							return (
								<TableRow key={field}>
									<TableCell>
										<strong>{field}</strong>
									</TableCell>
									<TableCell>{value}</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</div>
		);
	} else {
		return <h1>Sorry. Wrong Format.</h1>;
	}
}
