import React, { useState } from 'react';
import EntityContainer from 'modules/entity/containers';
import { useAppState } from '../../../shared/state';
import { FilterTemplateType } from '../../../shared/typings';
import { Loader, LoadMoreVisible } from '../../../shared/components';
import { NCTemplateItem } from './NCTemplateItem';
import config from '../../../config';
import { FilterTemplateDeleteModal } from './FilterTemplateDeleteModal';
import { useCaseFilterDnD, useCaseFilterFunctions } from '../hooks';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

export function NCTemplates() {
	const { t } = useTranslation();
	const { companyId, caseType } = useAppState();
	const [page, setPage] = useState(1);
	const { setActiveFilterTemplate, activeFilterTemplate } = useAppState();
	const { showDeletePopup, handleDeleteTemplate } = useCaseFilterFunctions();
	const { onFilterTemplateDragEnd } = useCaseFilterDnD();

	return (
		<DragDropContext onDragEnd={onFilterTemplateDragEnd}>
			<Droppable droppableId={`AllCaseFilters-${companyId}-${caseType}`}>
				{(provided) => {
					return (
						<div
							className="nc-templates relative pb-4"
							{...provided.droppableProps}
							ref={provided.innerRef}
						>
							<EntityContainer.All
								entity="case-filters"
								name={`AllCaseFilters-${companyId}-${caseType}`}
								url={`/${companyId}/case-filters/`}
								appendData
								params={{
									limit: 10,
									page,
									extra: {
										is_staff: caseType === config.STAFF ? 'true' : 'false'
									}
								}}
							>
								{({ items, isFetched, meta }) => {
									const filterTemplateItems = [...items] as FilterTemplateType[];
									const hasToLoadMore =
										isFetched &&
										meta?.current_page &&
										meta?.last_page &&
										meta?.current_page < meta?.last_page;
									return (
										<>
											{filterTemplateItems.map((filterItem, index) => {
												return (
													<>
														<Draggable
															draggableId={filterItem.id.toString()}
															index={index}
															key={filterItem.id}
														>
															{(providedProp) => {
																return (
																	<div
																		{...providedProp.draggableProps}
																		{...providedProp.dragHandleProps}
																		ref={providedProp.innerRef}
																	>
																		<NCTemplateItem
																			key={filterItem.id}
																			title={filterItem.title}
																			id={filterItem.id}
																			selected={filterItem.is_selected}
																			active={filterItem.id === activeFilterTemplate?.id}
																			isDefault={filterItem.is_default}
																			onClick={() => setActiveFilterTemplate(filterItem)}
																			onRemove={() => handleDeleteTemplate(filterItem)}
																		/>
																	</div>
																);
															}}
														</Draggable>
													</>
												);
											})}
											{provided.placeholder}
											{!isFetched && (
												<div style={{ textAlign: 'center' }}>
													<Loader />
												</div>
											)}
											{isFetched && hasToLoadMore && (
												<LoadMoreVisible
													setPage={() =>
														meta && meta.current_page && setPage(meta?.current_page + 1)
													}
												/>
											)}
										</>
									);
								}}
							</EntityContainer.All>
							<FilterTemplateDeleteModal
								handleModalOpen={handleDeleteTemplate}
								open={showDeletePopup}
							/>
						</div>
					);
				}}
			</Droppable>
		</DragDropContext>
	);
}
