import get from 'lodash/get';

export function getDepartmentPayload(values) {
	let ids = [];
	if ('10000' in values.dep_hierarchy) {
		const tempObject = { ...values.dep_hierarchy };
		delete tempObject['10000'];
		ids = tempObject;
	} else {
		ids = Object.keys(values.dep_hierarchy);
	}
	if (ids.length >= 2) {
		if (get(values, `dep_hierarchy.${ids[ids.length - 1]}`)) {
			return { id: ids[ids.length - 2] };
		} else if (ids[ids.length - 3]) {
			return { id: ids[ids.length - 3] };
		} else {
			return { id: get(values, 'department.id') };
		}
	} else {
		return { id: get(values, 'department.id') };
	}
}
