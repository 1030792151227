import React from 'react';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { useAppState } from '../../../state';
import { isMobile } from 'react-device-detect';
import clsx from 'clsx';
import { Avatar } from '@material-ui/core';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export function MainLogo() {
	const { company } = useAuth();
	const { navCollapsed, isDarkMode } = useAppState();

	const companyName = company?.company?.name;
	const companyIcon = company?.company?.icon;
	const companyIconDark = company?.company?.icon_dark;
	const companyLogo = company?.company?.logo;
	const companyLogoDark = company?.company?.logo_dark;

	return (
		<div className="flex h-full items-center font-bold text-primary ">
			{companyLogo ? (
				<div
					className={clsx('flex-shrink-0 overflow-hidden ', {
						'lg:-top-14px absolute lg:-left-[16px] lg:right-0 lg:h-[60px]':
							companyLogo && !navCollapsed
					})}
				>
					{navCollapsed ? (
						<div className="mr-2">
							{companyIcon ? (
								<>
									{isDarkMode ? (
										<Avatar
											alt={companyName}
											src={
												companyIconDark
													? companyIconDark?.thumbnail_150
													: companyIcon?.thumbnail_150
											}
										/>
									) : (
										<Avatar alt={companyName} src={companyIcon?.thumbnail_150} />
									)}
								</>
							) : (
								<Avatar alt={companyName} src={''}>
									{companyName?.[0]}
								</Avatar>
							)}
						</div>
					) : (
						<>
							{isDarkMode ? (
								<LazyLoadImage
									effect={'blur'}
									src={
										isMobile
											? companyIconDark
												? companyIconDark?.url
												: companyIcon?.url
											: companyLogoDark
											? companyLogoDark?.url
											: companyLogo?.url
									}
									alt="Company Logo"
									wrapperClassName={clsx('h-full', {
										'w-full': companyLogo && !isMobile
									})}
									className={clsx('h-full object-contain align-middle', {
										'w-full': companyLogo && !isMobile
									})}
								/>
							) : (
								<LazyLoadImage
									effect={'blur'}
									src={isMobile ? companyIcon?.url : companyLogo?.url}
									alt="Company Logo"
									wrapperClassName={clsx('h-full', {
										'w-full': companyLogo && !isMobile
									})}
									className={clsx('h-full object-contain align-middle', {
										'w-full': companyLogo && !isMobile
									})}
								/>
							)}
						</>
					)}
				</div>
			) : (
				<>
					{companyIcon ? (
						<div
							className={clsx(' mr-2 flex-shrink-0 overflow-hidden rounded-full', {
								'h-10 w-10': !isMobile,
								'ml-2 h-8 w-8': isMobile
							})}
						>
							<LazyLoadImage
								src={
									isDarkMode
										? companyIconDark
											? companyIconDark?.thumbnail_150
											: companyIcon?.thumbnail_150
										: companyIcon?.thumbnail_150
								}
								effect={'blur'}
								alt=""
								wrapperClassName="h-full"
								className="h-full object-cover"
							/>
						</div>
					) : (
						<>
							{navCollapsed ? (
								<div className="mr-2">
									<Avatar alt={companyName} src={''}>
										{companyName?.[0]}
									</Avatar>
								</div>
							) : (
								''
							)}
						</>
					)}
					<div className="max-w-[229px] truncate">{!navCollapsed && !isMobile && companyName}</div>
				</>
			)}
		</div>
	);
}
