import React, { useEffect } from 'react';
import clsx from 'clsx';
import { IconFilterDelete } from 'shared/icons';
import { Trans, useTranslation } from 'react-i18next';
import { convertToLocalizationKey } from 'shared/services/utils/convertToLocalizationKey';

type Props = {
	title: string;
	id: number;
	active?: boolean;
	selected?: boolean;
	onClick: () => void;
	isDefault: boolean;
	onRemove: () => void;
};

export function NCTemplateItem({
	title,
	id,
	onClick,
	active = false,
	selected = false,
	isDefault = false,
	onRemove
}: Props) {
	const { t } = useTranslation();
	useEffect(() => {
		if (selected) onClick();
	}, [selected]);

	return (
		<div className={clsx('nc-template-item mb-3 flex cursor-pointer items-center pr-3')}>
			<div
				className={clsx(
					'nc-template-item__body rounded-10 transition hover:bg-kgrey-light-grey-5' +
						' flex-center-between flex-grow px-4 py-2.5 text-sm leading-none',
					' dark:bg-kdark ',
					{ '!bg-primary-dark text-white': active, 'bg-kgrey-light-grey-3': !active }
				)}
				onClick={onClick}
			>
				<span>
					<Trans i18nKey={convertToLocalizationKey(title)} defaults={title} />
				</span>
				{selected && active ? <span>{t('selected_lower')}</span> : null}
			</div>
			{!selected && !active && !isDefault ? (
				<div onClick={onRemove}>
					<IconFilterDelete className="mx-2 flex-shrink-0 cursor-pointer transition hover:text-kred-pink" />
				</div>
			) : null}
		</div>
	);
}
