import localforage from 'localforage';
import { api, storage, queryBuilder } from 'shared/services';
import { takeEvery, call, put, all } from 'redux-saga/effects';

import Actions from 'store/actions';
import { AxiosError } from 'axios';

export function* LoginRequest(action: any) {
	const {
		values: { username, password },
		cb
	} = action.payload;

	// @ts-ignore
	try {
		const { data } = yield call(api.request.post, queryBuilder('/user/auth/', {}), {
			username,
			password
		});

		yield call(storage.set, 'token', data.token);
		yield put(Actions.auth.Login.success(data));

		yield call(cb.success);
		//@ts-ignore
	} catch (error: AxiosError) {
		yield put(
			Actions.auth.Login.failure({
				error
			})
		);

		yield call(cb.error, error.response.data);
		if (
			error.response.data.message === 'Signature has expired.' ||
			error.response.data.message === 'Error decoding signature.'
		) {
			yield call(storage.remove, 'token');
			window.location.reload();
		}
	} finally {
		yield call(cb.finally);
	}
}

export function* GetMeRequest() {
	try {
		const { data } = yield call(api.request.get, queryBuilder('/my/profile/', {}));

		yield put(
			Actions.auth.GetMe.success({
				data
			})
		);

		// eslint-disable-next-line @typescript-eslint/no-empty-function
		yield localforage.setItem('user', data, function (err) {});
	} catch (error) {
		yield put(
			Actions.auth.GetMe.failure({
				error
			})
		);
	}
}

export function* LogoutRequest(action: any) {
	const { cb } = action.payload;
	yield call(storage.remove, 'token');
	yield put(Actions.auth.Logout.success());
	yield call(cb);
}

export default function* root() {
	yield all([
		takeEvery(Actions.auth.Login.REQUEST, LoginRequest),
		takeEvery(Actions.auth.GetMe.REQUEST, GetMeRequest),
		takeEvery(Actions.auth.Logout.REQUEST, LogoutRequest)
	]);
}
