import React from 'react';
import clsx from 'clsx';

export const VirtuosoList = React.forwardRef<any, any>(
	({ children, context, style, ...props }, ref) => {
		return (
			<>
				<div className="hidden !pb-[70px]"></div>
				<div
					ref={ref}
					className={clsx({
						'!pb-[70px]': context.isConfirmationRequired && style.paddingBottom === 0
					})}
					style={style}
					{...props}
				>
					{children}
				</div>
			</>
		);
	}
);

VirtuosoList.displayName = 'VirtuosoList';
