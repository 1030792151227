import React from 'react';
import { Field } from 'formik';
import { caseFilterStyle } from 'shared/services';
import { SelectField } from 'shared/components/Fields';

const clientRatingOptions = [
	{ label: 'All', value: 'all' },
	{ label: '1', value: '1' },
	{ label: '2', value: '2' },
	{ label: '3', value: '3' },
	{ label: '4', value: '4' },
	{ label: '5', value: '5' }
];

export function RatingFilterItem() {
	return (
		<div className="dropdown mb-4">
			<div className="mb-2 text-sm leading-none text-kgrey-menuText dark:text-white">Rating</div>
			<div className="w-full">
				<Field
					component={SelectField}
					className="basic-single"
					classNamePrefix="select"
					isClearable
					isSearchable
					name="rate"
					options={clientRatingOptions}
					styles={caseFilterStyle}
				/>
			</div>
		</div>
	);
}
