import React, { forwardRef } from 'react';
import clsx from 'clsx';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import { FormikErrors } from 'formik';
import 'react-phone-input-2/lib/style.css';
import { useAppState } from '../../state';
import { useTranslation } from 'react-i18next';

type Props = {
	label: string;
	required?: boolean;
	classNames?: string;
	name: string;
	placeholder?: string;
	type?: string;
	value?: string;
	setPhoneValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined
	) => Promise<FormikErrors<any>> | Promise<void>;
	error?: boolean | undefined;
	helperText?: any;
	additionalInputClasses?: string;
} & Omit<PhoneInputProps, 'inputClass'>;

export const KntPhoneInput = forwardRef<HTMLInputElement | null, Props>(
	(
		{
			label,
			required,
			name,
			classNames = '',
			placeholder = '',
			type = 'tel',
			value,
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			setPhoneValue = () => {},
			error,
			helperText,
			additionalInputClasses,
			...restProps
		},
		ref
	) => {
		const { isDarkMode } = useAppState();
		const { t } = useTranslation();

		return (
			<div className={clsx('mb-4', classNames)}>
				<label className="ml-6 mb-2 block text-xs font-light text-kgrey-welcome">
					{label}
					{required ? <sup>*</sup> : null}
				</label>
				<div dir="ltr" className="relative">
					<PhoneInput
						country="us"
						searchPlaceholder={t('search')}
						{...(value ? { value } : {})}
						// eslint-disable-next-line no-unused-vars
						onChange={(phoneValue, data, event) => {
							setPhoneValue(name, phoneValue);
						}}
						containerClass={clsx({
							'phone-input-dark': isDarkMode
						})}
						inputClass={clsx(
							'!border !border-kgrey-inputBd !dark:border-white/30 !rounded-lg !text-xs !px-14 !py-3.5 !outline-none !transition ' +
								'!focus:border-primary !focus:shadow-lg !w-full !placeholder-current !placeholder-kgrey-welcome !dark:bg-kdark/70 !h-auto',
							error && '!border-red-500',
							additionalInputClasses
						)}
						{...restProps}
					/>
				</div>
				{error ? <span className="text-xs text-red-500">{helperText}</span> : null}
			</div>
		);
	}
);

KntPhoneInput.defaultProps = {
	required: false,
	classNames: '',
	placeholder: '',
	type: 'phone',
	value: '',
	additionalInputClasses: '',
	error: false,
	helperText: ''
};

KntPhoneInput.displayName = 'KntPhoneInput';
