export function convertUnixToTimeUnits(t: number) {
	const cd = 24 * 60 * 60 * 1000;
	const ch = 60 * 60 * 1000;
	let d = Math.floor(t / cd);
	let h = Math.floor((t - d * cd) / ch);
	let m = Math.round((t - d * cd - h * ch) / 60000);

	const pad = function (n: number) {
		return n < 10 ? '0' + n : n;
	};

	if (m === 60) {
		h++;
		m = 0;
	}
	if (h === 24) {
		d++;
		h = 0;
	}
	return [d, pad(h), pad(m)].join(':');
}
