export const registerServiceWorker = () => {
	const swUrl = `${process.env.PUBLIC_URL}/firebase-messaging-sw.js`;
	const swOfflineUrl = `${process.env.PUBLIC_URL}/offline-sw.js`;
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker
			.register(swUrl)
			.then(function (registration) {
				console.log('[SW]: SCOPE: notification', registration.scope);
				return registration.scope;
			})
			.catch(function (err) {
				return err;
			});

		navigator.serviceWorker
			.register(swOfflineUrl)
			.then(function (registration) {
				console.log('[SW]: SCOPE: offline', registration.scope);
				return registration.scope;
			})
			.catch(function (err) {
				return err;
			});
	}
};
