import { default as dayjs } from 'dayjs';

export function convertMsgTime(time: number) {
	const dateFormat = dayjs.unix(time).format('ddd, MMM D, h:mm A');
	const withYearDateFormat = dayjs.unix(time).format('ddd, MMM D YYYY, h:mm A');
	const year = dayjs.unix(time).format('YYYY');
	const current_year = new Date().getFullYear();
	if (Number(year) === Number(current_year)) {
		return dateFormat;
	}
	return withYearDateFormat;
}
