import React from 'react';
import clsx from 'clsx';
import { IconAssigneeUser } from '../../../shared/icons';
import { Popover } from '../../../shared/components/Elements';
import { AssigneeMembers } from './AssigneeMembers';

type Props = {
	assigneeIds: number[] | null;
	isCurrentUserAssigned: boolean | undefined;
	openAssignee: boolean;
	anchorElAssignee: HTMLButtonElement | null;
	handleCloseAssignee: () => void;
	caseId: number;
	handleClickAssignee: (event: React.MouseEvent<HTMLButtonElement>) => void;
	className?: string;
};

export function CaseAssigneeUsersPopover({
	assigneeIds,
	isCurrentUserAssigned,
	openAssignee,
	anchorElAssignee,
	handleCloseAssignee,
	handleClickAssignee,
	caseId,
	className = ''
}: Props) {
	return (
		<>
			{assigneeIds?.length ? (
				<>
					<button
						type={'button'}
						className={clsx(
							'ml-1  cursor-pointer transition hover:text-primary',
							{
								'text-primary': isCurrentUserAssigned,
								'text-[#A5A7B2]': !isCurrentUserAssigned
							},
							className
						)}
						onClick={handleClickAssignee}
					>
						<IconAssigneeUser />
					</button>
					{openAssignee ? (
						<Popover
							id={openAssignee ? 'assigned users popover' : undefined}
							open={openAssignee}
							anchorEl={anchorElAssignee}
							onClose={handleCloseAssignee}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right'
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left'
							}}
						>
							<AssigneeMembers entityId={caseId} />
						</Popover>
					) : null}
				</>
			) : null}
		</>
	);
}
