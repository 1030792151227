import React, { useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import EntityContainer from 'modules/entity/containers';
import { LoadMoreVisible } from '../../LoadMoreVisible';
import { KntScrollBars, Loader } from '../../index';
import { useAppState } from '../../../state';
import { TemplateFilterItem } from './TemplateFilterItem';
import { FilterTemplateType } from '../../../typings';
import config from '../../../../config';
import { useCaseFilterSelect } from '../../../../pages/Cases/hooks/useCaseFilterSelect';
import { ErrorBoundary } from 'shared/components';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

type Props = {
	handleModalOpen: () => void;
};

export function TemplatesFilterDropdownInner({ handleModalOpen }: Props) {
	const { t } = useTranslation();
	const [page, setPage] = useState(1);

	const { companyId, caseType } = useAppState();
	const { onFilterTemplateClick, loading, selectedCaseFilter } = useCaseFilterSelect();

	const isAnyFilterSelected = selectedCaseFilter?.[caseType];
	const reloadPage = () => window.location.reload();

	return (
		<div className="w-full text-left">
			<div className="flex-center-between mb-4">
				<button
					onClick={handleModalOpen}
					className={clsx(
						'rounded-md border px-2 py-1 transition hover:border-primary hover:text-primary',
						{
							'text-xs': isMobile
						}
					)}
				>
					{t('filter_setup')}
				</button>
				<p
					className={clsx(' transition hover:text-primary', {
						'text-primary': !isAnyFilterSelected,
						'text-kgrey': isAnyFilterSelected,
						'text-xs': isMobile,
						'text-base': !isMobile
					})}
					onClick={() => (isAnyFilterSelected ? onFilterTemplateClick(null) : reloadPage())}
				>
					{t('all_cases')}
				</p>
			</div>
			<div className="h-[180px]">
				<KntScrollBars className="rct-scroll" style={{ height: '180px' }}>
					{companyId ? (
						<EntityContainer.All
							entity="case-filters"
							name={`AllCaseFilters-${companyId}-${caseType}`}
							url={`/${companyId}/case-filters/`}
							appendData
							params={{
								limit: 10,
								page,
								extra: {
									is_staff: caseType === config.STAFF ? 'true' : 'false'
								}
							}}
						>
							{({ items, isFetched, meta }) => {
								const hasToLoadMore =
									isFetched &&
									meta?.current_page &&
									meta?.last_page &&
									meta?.current_page < meta?.last_page;
								const filterItems = [...items] as FilterTemplateType[];
								return (
									<>
										{filterItems.map((filterItem) => {
											return (
												<TemplateFilterItem
													key={filterItem.id}
													title={filterItem.title}
													selected={filterItem.is_selected}
													handleTemplateClick={() => onFilterTemplateClick(filterItem)}
												/>
											);
										})}
										{!isFetched && (
											<div style={{ textAlign: 'center' }}>
												<Loader />
											</div>
										)}
										{loading ? <Loader withOverlay tiled /> : null}
										{isFetched && hasToLoadMore ? (
											<LoadMoreVisible
												setPage={() => meta && meta.current_page && setPage(meta?.current_page + 1)}
											/>
										) : null}
									</>
								);
							}}
						</EntityContainer.All>
					) : null}
				</KntScrollBars>
			</div>
		</div>
	);
}

export function TemplatesFilterDropdown(props: Props) {
	return (
		<ErrorBoundary>
			<TemplatesFilterDropdownInner {...props} />
		</ErrorBoundary>
	);
}
