import { all, fork } from 'redux-saga/effects';
import authSagas from '../../modules/auth/sagas';
import entitySagas from '../../modules/entity/sagas';
import userSagas from '../../modules/user/sagas';
import systemSagas from './system';

let moduleSagas = { auth: authSagas, entity: entitySagas, user: userSagas } as any;
moduleSagas = Object.keys(moduleSagas).reduce(
	(prev, curr: string): any => [...prev, fork(moduleSagas[curr])],
	[]
);

let sagas = { system: systemSagas } as any;
sagas = Object.keys(sagas).reduce((prev, curr: any): any => [...prev, fork(sagas[curr])], []);

export default function* rootSaga(): any {
	yield all([...moduleSagas, ...sagas]);
}
