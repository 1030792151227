import React from 'react';
import { Field } from 'formik';
import { caseFilterStyle } from 'shared/services';
import { SelectField } from 'shared/components/Fields';
import { useTranslation } from 'react-i18next';

export function ClientTypeFilterItem() {
	const { t } = useTranslation();

	const cTypeOptions = [
		{ label: t('all'), value: '' },
		{ label: t('individual'), value: 'true' },
		{ label: t('business'), value: 'false' }
	];
	return (
		<div className="dropdown mb-4">
			<div className="mb-2 text-sm leading-none text-kgrey-menuText dark:text-white">
				{t('client_type')}
			</div>
			<div className="w-full">
				<Field
					component={SelectField}
					className="basic-single"
					classNamePrefix="select"
					isClearable
					isSearchable
					name="client_type"
					options={cTypeOptions}
					styles={caseFilterStyle}
				/>
			</div>
		</div>
	);
}
