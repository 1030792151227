import React from 'react';
import config from 'config';
import cx from 'clsx';
const spritePath = `${config.PUBLIC_ASSETS}sprite/sprite.svg`;

type SvgProps = {
	iconName: string;
	className?: string;
	width: string;
	height: string;
};

export function SpriteIcon({ iconName, className = '', width, height }: SvgProps): JSX.Element {
	const classes = cx('icon ', className);
	return (
		<svg className={classes} width={width} height={height}>
			<use xlinkHref={`${spritePath}#${iconName}`} />
		</svg>
	);
}
