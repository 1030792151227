import React from 'react';

import VisibilitySensor from 'react-visibility-sensor';
import { CircularProgress } from '@material-ui/core';

type Props = {
	setPage: (isVisible: boolean) => void;
};

export const LoadMoreVisible = ({ setPage = () => {} }: Props) => {
	return (
		<VisibilitySensor
			offset={{ bottom: -500 }}
			partialVisibility
			onChange={(isVisible) => {
				if (isVisible) {
					setPage(isVisible);
				}
			}}
		>
			{() => (
				<div className="case-spinner flex items-center justify-center">
					<CircularProgress />
				</div>
			)}
		</VisibilitySensor>
	);
};
