import React, { useState, useEffect, useCallback } from 'react';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { Company } from 'modules/user/reducers';
import { Branch } from '../../../../pages/BusinessManagement/components/BusinessLocations';
import { useGoogleMapsInit } from '../../../../pages/BusinessProfileMain/components/BusinessProfile/hooks';

export function branchAddress(branch: Branch) {
	return `${branch.address ? branch.address : 'No Address'}`;
}

const containerStyle = {
	width: '100%',
	height: '100%'
};

export type MapMarketData = {
	companyName: string;
	name: string;
	latLng: { lat: number; lng: number };
	isMain: boolean;
};

type Props = {
	branches: Branch[];
	currentCompany: Company;
	data: MapMarketData[];
};

const MapView = ({ data, branches, currentCompany }: Props) => {
	const { isLoaded } = useGoogleMapsInit();

	const [map, setMap] = useState(null);
	const [center, setCenter] = useState({
		lat: +currentCompany.company.lat || +data[0].latLng.lat,
		lng: +currentCompany.company.lng || +data[0].latLng.lng
	});
	const [selected, setSelected] = useState<MapMarketData | null>(null);

	const onLoad = useCallback(function callback(map) {
		const bounds = new window.google.maps.LatLngBounds();
		for (let i = 0; i < data.length; i++) {
			bounds.extend(new window.google.maps.LatLng(data[i].latLng.lat, data[i].latLng.lng));
		}

		map.fitBounds(bounds);
		setMap(map);
	}, []);

	const onUnmount = useCallback(function callback(map) {
		setMap(null);
	}, []);

	useEffect(() => {
		if (selected) {
			setCenter({ lat: selected.latLng.lat, lng: selected.latLng.lng });
		}
	}, [selected]);

	return (
		<>
			{isLoaded ? (
				<GoogleMap
					mapContainerStyle={containerStyle}
					center={{
						lat: center.lat,
						lng: center.lng
					}}
					zoom={10}
					onLoad={onLoad}
					onUnmount={onUnmount}
				>
					<>
						{data.map((location) => {
							return (
								<Marker
									key={location.name}
									draggable
									position={{
										lat: location.latLng.lat,
										lng: location.latLng.lng
									}}
									onClick={() => {
										setSelected(location);
									}}
								/>
							);
						})}
						{selected ? (
							<InfoWindow
								position={{ lat: selected.latLng.lat, lng: selected.latLng.lng }}
								onCloseClick={() => {
									setSelected(null);
								}}
							>
								<>
									<h3>{`${currentCompany.company.name} / ${
										selected.isMain ? 'Main branch' : selected.companyName
									}`}</h3>
									<div>{selected.name}</div>
								</>
							</InfoWindow>
						) : null}
					</>
				</GoogleMap>
			) : (
				<p>Loading map</p>
			)}
		</>
	);
};

export default MapView;
