import React, { useCallback, useEffect } from 'react';
import { useInitialRequests } from 'shared/hooks';
import { useEscapePressed } from './shared/hooks/chat-hooks/useEscapePressed';
import { MobilePromptModal } from './shared/components/MobilePromptModal';
import { useAppState } from './shared/state';
import { useAuth } from './modules/auth/hooks/useAuth';
import clsx from 'clsx';
import { useChangeLayoutDirection } from 'shared/hooks/useChangeLayoutDirection';

interface AppProps {
	children: React.ReactNode;
}

const App: React.FC<AppProps> = ({ children }) => {
	useInitialRequests();
	useEscapePressed();
	useChangeLayoutDirection();

	const { isShowMobilePrompt, setIsShowMobilePrompt, settingsData, isDarkMode } = useAppState();
	const { isAuthentificated } = useAuth();

	const closeMobilePrompt = useCallback(() => setIsShowMobilePrompt(false), []);

	return (
		<div className={clsx('wrapper h-full', { 'app-mode__dark': isDarkMode })}>
			<>{children}</>
			{settingsData && isAuthentificated && isShowMobilePrompt && (
				<MobilePromptModal toggleHandler={closeMobilePrompt} isOpen={isShowMobilePrompt} />
			)}
		</div>
	);
};

export default App;
