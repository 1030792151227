import { createSelector } from 'reselect';

import get from 'lodash/get';

const authUser = (state: any) => state.auth;

export const getUserToken = createSelector(authUser, (user) => get(user, 'token'));
export const getUserIsAuth = createSelector(authUser, (user) => get(user, 'isAuthenticated'));
export const getUserIsFetched = createSelector(authUser, (user) => get(user, 'isFetched'));
export const getUserData = createSelector(authUser, (user) => get(user, 'data'));
