import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

export function getSubDepartmentPayload(values) {
	let ids = [];
	if ('10000' in values.dep_hierarchy) {
		const tempObject = { ...values.dep_hierarchy };
		delete tempObject['10000'];
		ids = tempObject;
		const tempObjectKeys = Object.keys(tempObject);
		//fix bug sending  1 st level subdep empty when there is sub sub deps
		if (!isEmpty(tempObject) && tempObjectKeys.length === 1) {
			return { id: tempObjectKeys[0] };
		}
	} else {
		ids = Object.keys(values.dep_hierarchy).filter((id) => values.dep_hierarchy[id]);
	}

	if (ids.length > 0) {
		if (get(values, `dep_hierarchy.${ids[ids.length - 1]}`) || ids.length === 1) {
			return { id: ids[ids.length - 1] };
		} else {
			return { id: ids[ids.length - 2] };
		}
	} else {
		return {};
	}
}
