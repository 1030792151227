import React from 'react';
import { useAxios } from 'shared/hooks';
import { useAppState } from 'shared/state';
import { ErrorBoundary } from 'shared/components';
import ContentLoader from 'react-content-loader';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
type Props = { entityId: number };

function AssigneeMembersInner({ entityId }: Props) {
	const { t } = useTranslation();
	const { companyId } = useAppState();
	const { response, loading } = useAxios({
		url: `/${companyId}/cases/as_business/${entityId}/assigned_users/`,
		initialLoad: true
	});

	return (
		<div
			className={'assignee-tp relative border-b border-kgrey-border-solid font-poppins font-medium'}
		>
			<div className="assignee-tp__header border-b border-kgrey-border-solid py-4 px-5">
				{t('assigned_users')}
			</div>
			<div className="assignee-tp__content max-h-[210px] overflow-y-auto px-5 pt-4 pb-2">
				{loading ? (
					<AssigneeMembersLoaders />
				) : (
					<>
						{(Array.isArray(response) ? response : []).map((item: any) => {
							return (
								<div
									className="assignee-tp__item flex-center-y mb-2"
									key={item?.user ? item.user.id : item.id}
								>
									<div
										className={clsx('assignee-tp__img h-9 w-9 overflow-hidden rounded-full', {
											'flex-centered bg-kgrey font-semibold text-white': !(item?.user
												? item?.user.avatar
												: item?.avatar)
										})}
									>
										{item?.user?.avatar || item?.avatar ? (
											<img
												src={
													item?.user ? item?.user.avatar.thumbnail_150 : item?.avatar?.thumbnail_150
												}
												alt=""
											/>
										) : (
											<span>{item?.user ? item?.user?.first_name[0] : item?.first_name[0]}</span>
										)}
									</div>
									<div className="assignee-tp__name ml-2">{`${
										item?.user ? item.user.first_name : item.first_name
									} ${item?.user ? item.user.last_name : item.last_name}`}</div>
								</div>
							);
						})}
					</>
				)}
			</div>
		</div>
	);
}

function AssigneeMembersLoaders() {
	return (
		<>
			{[...Array(4)].map((_, index) => {
				return (
					<div className={'mb-2'} key={index}>
						<ContentLoader
							speed={2}
							width={215}
							height={36}
							viewBox="0 0 215 36"
							backgroundColor="#f3f3f3"
							foregroundColor="#ecebeb"
						>
							<rect x="319" y="265" rx="0" ry="0" width="1" height="1" />
							<circle cx="19" cy="18" r="17" />
							<rect x="46" y="10" rx="3" ry="3" width="51" height="12" />
							<rect x="107" y="10" rx="3" ry="3" width="121" height="12" />
						</ContentLoader>
					</div>
				);
			})}
		</>
	);
}

export function AssigneeMembers(props: Props) {
	return (
		<ErrorBoundary isCompact>
			<AssigneeMembersInner {...props} />
		</ErrorBoundary>
	);
}
