interface LengthResult {
	file: File;
	duration: number;
}

//https://stackoverflow.com/a/51918491/2813320
export function computeLength(file: File): Promise<LengthResult> {
	return new Promise((resolve, reject) => {
		if (!file.type.includes('audio')) {
			reject(new Error('File is not an audio file'));
			return;
		}

		const objectURL = URL.createObjectURL(file);
		const mySound = new Audio(objectURL);
		mySound.addEventListener(
			'canplaythrough',
			() => {
				URL.revokeObjectURL(objectURL);
				resolve({
					file,
					duration: mySound.duration
				});
			},
			false
		);
	});
}

//https://stackoverflow.com/a/66423032/2813320 - another function
