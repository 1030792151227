import React from 'react';
import EntityForm from 'modules/entity/forms';
import { useAppState } from 'shared/state';
import AsyncSelectJs from 'shared/components/Fields/AsyncSelectJs';
import { KntTwilioStyle } from 'shared/services';
import { Field } from 'formik';
import get from 'lodash/get';
import { Button } from 'shared/components/Elements';
import { Loader } from 'shared/components';
import { useLoadAll, useNotistack } from 'shared/hooks';
import { useTranslation } from 'react-i18next';

type Props = {
	caseId: number;
	modalHandler: () => void;
};

export function AddLinkedCaseForm({ caseId, modalHandler }: Props) {
	const { isStaff, companyId } = useAppState();
	const { showNotification } = useNotistack();
	const { reloadEntityAll } = useLoadAll();
	const { t } = useTranslation();

	return (
		<>
			<EntityForm.Main
				entity="linked-cases"
				name={`AllLinkedCases-${caseId}`}
				url={`/${companyId}/cases/as_business/${caseId}/from_to/`}
				method="post"
				primaryKey="id"
				sendAsFormData={false}
				normalizeData={(data: any) => data}
				prependData
				fields={[
					{
						name: 'to_case',
						required: true,
						onSubmitValue: (value) => ({ id: value.id }),
						onSubmitKey: '',
						type: 'object'
					},
					{
						name: 'status',
						onSubmitValue: (value) => value.id,
						onSubmitKey: '',
						type: 'object'
					}
				]}
				onSuccess={(response, resetForm): any => {
					setTimeout(() => modalHandler(), 0);

					reloadEntityAll({
						entity: 'linked-cases',
						name: `AllLinkedCases-${caseId}`,
						url: `/${companyId}/cases/as_business/${caseId}/from_to/`,
						params: {
							page: 1,
							limit: 50,
							extra: {
								from_case: caseId
							}
						}
					});
				}}
				onError={(error, resetForm): any => {
					// resetForm();
					showNotification({
						message:
							error.message === 'Request failed with status code 400'
								? t('case_already_linked_error')
								: t('something_went_wrong'),
						variant: 'error'
					});
				}}
			>
				{({ values, isSubmitting }) => {
					return (
						<div>
							<h1 className="mb-6 text-lg font-bold">{t('create_linked_case')}</h1>
							<div className="react-select-dropdown mb-4">
								<Field
									component={AsyncSelectJs}
									customStylesProps={KntTwilioStyle}
									name="status"
									optionLabel="title"
									optionValue="id"
									isSearchable
									isCached
									placeholder={t('select_status')}
									reverse={true}
									loadOptionsUrl={`/${companyId}/my/company/case_statuses/`}
									loadOptionsParams={(search: string) => ({
										extra: {
											search,
											is_staff: isStaff ? 'false' : 'true'
										}
									})}
									loadOptionsKey=""
								/>
							</div>
							{values.status && (
								<div className="react-select-dropdown">
									<Field
										component={AsyncSelectJs}
										customStylesProps={KntTwilioStyle}
										name="to_case"
										placeholder={t('select_case')}
										optionLabel={(option: any) => {
											return `${option.case_number} - ${option.title}`;
										}}
										optionValue="id"
										isSearchable
										isClearable
										loadOptionsKey="results"
										loadOptionsUrl={`/${companyId}/cases/as_business/`}
										loadOptionsParams={(search: string) => ({
											limit: 50,
											extra: {
												status: get(values, 'status.id'),
												search,
												client_company: isStaff ? '' : companyId
											}
										})}
									/>
								</div>
							)}
							<div className="mt-4 flex items-center justify-end">
								<Button variant="outlined" type="button" onClick={modalHandler}>
									{t('cancel')}
								</Button>
								<Button
									variant="contained"
									type="submit"
									className="!ml-4"
									color="primary"
									disabled={!values.status || !values.to_case || isSubmitting}
								>
									{t('add')}
								</Button>
							</div>
							{isSubmitting && <Loader tiled />}
						</div>
					);
				}}
			</EntityForm.Main>
		</>
	);
}
