import { useAppState } from 'shared/state';

export const useChatScheduledMsgCountUpdate = () => {
	const { scheduledMessageIds, setScheduledMessageIds } = useAppState();

	const incrementScheduledMsgCount = ({
		messageId,
		entityId
	}: {
		messageId: number;
		entityId: number;
	}) => {
		const newEntityScheduledMessagesList = [...(scheduledMessageIds?.[entityId] ?? [])];
		newEntityScheduledMessagesList.push(messageId);

		setScheduledMessageIds({
			...scheduledMessageIds,
			[entityId]: newEntityScheduledMessagesList
		});
	};

	const decrementScheduledMsgCount = ({
		messageId,
		entityId
	}: {
		messageId: number;
		entityId: number;
	}) => {
		const newEntityScheduledMessagesList = [...(scheduledMessageIds?.[entityId] ?? [])].filter(
			(msgId) => msgId !== messageId
		);

		setScheduledMessageIds({
			...scheduledMessageIds,
			[entityId]: newEntityScheduledMessagesList
		});
	};

	const removeEntityScheduledMessagesList = (entityId: number) => {
		const newEntityScheduledMessagesList = { ...scheduledMessageIds };
		delete newEntityScheduledMessagesList?.[entityId];

		setScheduledMessageIds(newEntityScheduledMessagesList);
	};

	return {
		incrementScheduledMsgCount,
		decrementScheduledMsgCount,
		removeEntityScheduledMessagesList
	};
};
