import React from 'react';
import { Field } from 'formik';
import AsyncSelectJs from 'shared/components/Fields/AsyncSelectJs';
import { caseFilterStyle } from 'shared/services';
import { isMobile } from 'react-device-detect';
import { useAppState } from 'shared/state';
import { useTranslation } from 'react-i18next';

export function PriorityFilterItem() {
	const { t } = useTranslation();
	const { companyId } = useAppState();
	return (
		<div className="dropdown mb-4">
			<div className="mb-2 text-sm leading-none text-kgrey-menuText dark:text-white">
				{t('priority')}
			</div>
			<div className="w-full">
				<Field
					component={AsyncSelectJs}
					optionLabel="name"
					optionValue="code"
					loadOptionsUrl={`/${companyId}/cases/as_business/priorities/`}
					loadOptionsParams={(search: string) => ({
						extra: {
							search
						}
					})}
					isSearchable={!isMobile}
					name="priority"
					loadOptionsKey="choices"
					customStylesProps={caseFilterStyle}
					placeholder={t('all')}
					isMulti
					isCached
				/>
			</div>
		</div>
	);
}
