import * as React from 'react';

export function IconCheck(props: any) {
	return (
		<svg width="8" height="6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M2.799 5.868.353 3.437a.357.357 0 0 1-.103-.264c0-.108.034-.195.103-.264l.542-.527a.327.327 0 0 1 .256-.117c.102 0 .193.039.27.117l1.641 1.64L6.578.507A.372.372 0 0 1 6.85.39c.103 0 .188.039.256.117l.542.527a.357.357 0 0 1 .103.264.357.357 0 0 1-.103.264l-4.32 4.306a.334.334 0 0 1-.265.117.334.334 0 0 1-.263-.117Z"
				fill="#00A09D"
			/>
		</svg>
	);
}
