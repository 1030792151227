import qs from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import React, { useCallback } from 'react';

export function usePagination(attr = 'page') {
	const location = useLocation();
	const history = useHistory();
	const urlQuery = qs.parse(location.search);

	const handlePageChange = (page: number) => {
		const search = { ...urlQuery, [attr]: page };
		history.push({
			search: qs.stringify(search)
		});
	};

	const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
		handlePageChange(value);
	};
	const removePage = useCallback(() => {
		const urlQueryClone = { ...urlQuery };
		delete urlQueryClone[attr];

		history.push({
			search: qs.stringify(urlQueryClone)
		});
	}, [urlQuery]);

	return {
		location,
		history,
		urlQuery,
		handlePage,
		page: Number(urlQuery[attr]),
		removePage,
		handlePageChange
	};
}
