import React from 'react';
import TableCellMui, { TableCellProps } from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core';
import { useTranslationHelpers } from 'shared/hooks/useTranslationHelpers';

export const TableCell: React.FC<TableCellProps> = ({ children, ...rest }) => {
	const { isRTL } = useTranslationHelpers();
	const useStyles = makeStyles({
		root: {
			borderBottom: '.5px solid rgba(112, 124, 151, 0.25)',
			borderTop: '.5px solid rgba(112, 124, 151, 0.25)',
			'&:first-child': {
				borderRadius: isRTL ? '0 10px 10px 0' : '10px 0 0 10px',
				[isRTL ? 'borderRight' : 'borderLeft']: '.5px solid rgba(112, 124, 151, 0.25)'
			},
			'&:last-child': {
				borderRadius: isRTL ? '10px 0 0 10px' : '0 10px 10px 0',
				[isRTL ? 'borderLeft' : 'borderRight']: '.5px solid rgba(112, 124, 151, 0.25)'
			},
			textAlign: isRTL ? 'right' : 'left'
		},
		body: {
			fontSize: 14
		}
	});

	const classes = useStyles();
	return (
		<TableCellMui {...rest} classes={classes}>
			{children}
		</TableCellMui>
	);
};
