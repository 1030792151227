import { FC } from 'react';
import clsx from 'clsx';
import { FieldProps, FormikErrors } from 'formik';
import PhoneInput from 'react-phone-input-2';
import { useAppState } from '../../state';
import { useTranslation } from 'react-i18next';

type Props = {
	classNames: string;
	label: string;
	required: boolean;
	type: string;
	disabled?: boolean;
	onChangeHandler: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined
	) => Promise<FormikErrors<any>> | Promise<void>;
	labelClasses?: string;
};
// eslint-disable-next-line react/function-component-definition
export const KntPhoneInputField: FC<Props & FieldProps> = ({
	classNames,
	label,
	field: { name, value, onChange, ...restField },
	form: { touched, errors },
	type = 'text',
	required,
	onChangeHandler,
	disabled,
	labelClasses = '',
	...restProps
}) => {
	const inputClassNames = clsx(
		'!border  !border-transparent !rounded-lg !text-xs !px-14 !py-3.5' +
			' !outline-none !transition !focus:border-primary !focus:shadow-lg ' +
			'!w-full !placeholder-current !placeholder-kgrey dark:!bg-kdark hover:!border-primary !h-auto',
		errors[name] && '!border-red-500',
		{ '!pointer-events-none !bg-kgrey-disabled-bg': disabled, '!bg-kgrey-light-grey-1': !disabled }
	);
	const { isDarkMode } = useAppState();
	const { t } = useTranslation();
	return (
		<div
			className={clsx(
				'mb-4 hover:!border-primary',
				classNames,
				isDarkMode ? 'dark-mode-react-tel-input' : ''
			)}
		>
			<label
				className={clsx(
					'ml-2 mb-2 block text-sm font-light text-kgrey-menuText dark:text-white',
					labelClasses
				)}
				htmlFor={name}
			>
				<span>{label}</span>
				{required ? <sup>*</sup> : null}
			</label>
			<div dir="ltr" className="relative">
				<PhoneInput
					searchPlaceholder={t('search')}
					country="us"
					{...(value ? { value } : {})}
					// eslint-disable-next-line no-unused-vars
					onChange={(phoneValue, data, event) => {
						if (typeof onChangeHandler === 'function') onChangeHandler(name, phoneValue);
					}}
					containerClass={clsx({
						'phone-input-dark': isDarkMode
					})}
					inputClass={inputClassNames}
					{...restProps}
				/>
			</div>
			{errors[name] && touched[name] ? (
				<span className="text-xs text-red-500">{errors[name]}</span>
			) : null}
		</div>
	);
};

KntPhoneInputField.defaultProps = {
	disabled: false
};
