/** @jsxImportSource @emotion/react */

import { forwardRef } from 'react';
import { useQueryParams } from '../../../hooks';
import { useAppState } from '../../../state';
import clsx from 'clsx';
import { css } from '@emotion/react';
import isEmpty from 'lodash/isEmpty';
import { useTranslationHelpers } from 'shared/hooks/useTranslationHelpers';

type Props = {
	calculatedValueForCss: number;
	children: React.ReactNode;
};

export const ChatPanelWrapper = forwardRef(
	({ children, calculatedValueForCss }: Props, chatPanelRef: any) => {
		const { query } = useQueryParams();
		const { chatPanelToggled } = useAppState();
		const { isRTL } = useTranslationHelpers();

		return (
			<div
				className={clsx(
					'chat-panel max-w-3/4 absolute  top-0 h-full w-full bg-gradient-to-b  transition lg:w-8/12 ' +
						'border-kgrey-msg/20 from-kgrey-bgmenu to-kgrey-bgmenu dark:from-kdark  dark:to-kdark',
					{ 'shadow-chat-panel': chatPanelToggled, 'right-0': !isRTL, 'left-0': isRTL },
					{ hidden: !query?.case && !chatPanelToggled }
					// { 'w-8/12': navCollapsed, 'w-7/12': !navCollapsed }
				)}
				css={css`
					transform: translateX(
						${chatPanelToggled && !isEmpty(query) ? 0 : `${calculatedValueForCss}px`}
					);
				`}
				ref={chatPanelRef}
			>
				{children}
			</div>
		);
	}
);

ChatPanelWrapper.displayName = 'ChatPanelWrapper';
