import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../interfaces';
import { loadAllSuccess } from '../../modules/entity/actions';
import { StatusType } from '../../pages/Cases/components/Status';

export type ReOrderParams = {
	list: number[];
	startIndex: number;
	endIndex: number;
};

export type RemovingEntityId = {
	list: number[];
	isDragging: boolean;
	entityId: number;
	index?: number;
};
export type InsertingEntityId = {
	list: number[];
	index?: number;
	newEntityId: number;
};

export type ModifyEntityIdsParams = {
	ids: number[];
	entity: string;
	entityName: string;
	params: any;
	meta: any;
};

export function useReorderEntityIds() {
	const entityObjectInReduxState = useSelector((state: IRootState) => state.entity);
	const dispatch = useDispatch();

	function reOrder({ list, startIndex, endIndex }: ReOrderParams) {
		const prevIds = Array.from(list);
		const newIds = Array.from(list);
		const [removed] = newIds.splice(startIndex, 1);
		newIds.splice(endIndex, 0, removed);

		return { newIds, prevIds };
	}

	function removingEntityId({ list, index, isDragging, entityId }: RemovingEntityId) {
		const prevIds = Array.from(list);
		const newIds = Array.from(list);

		if (isDragging && index) {
			newIds.splice(index, 1);
			return { prevSourceIds: prevIds, nextSourceIds: newIds };
		}
		return {
			prevSourceIds: prevIds,
			nextSourceIds: newIds.filter((caseId) => caseId !== entityId)
		};
	}

	function insertingEntityId({ list, index, newEntityId }: InsertingEntityId) {
		const prevIds = Array.from(list ?? []);
		const newIds = Array.from(list ?? []).filter((caseId) => caseId !== newEntityId);

		newIds.splice(index ? index : 0, 0, newEntityId);

		return { prevDestinationIds: prevIds, nextDestinationIds: newIds };
	}

	function reOrderStatusList(statuses: StatusType[], newIds: number[]) {
		return newIds.map((stId, index) => ({
			...statuses.find((st) => st.id === stId),
			order_number: index
		}));
	}

	function getEntityData(entity: string, entityName: string) {
		return {
			entity,
			entityName,
			ids: entityObjectInReduxState[entity]?.[entityName]?.ids,
			meta: entityObjectInReduxState[entity]?.[entityName]?.meta,
			params: entityObjectInReduxState[entity]?.[entityName]?.params
		};
	}

	function modifyEntityIds({ ids, entity, entityName, params, meta }: ModifyEntityIdsParams) {
		dispatch(
			loadAllSuccess({
				ids,
				entity,
				name: entityName,
				appendData: false,
				prependData: false,
				params,
				meta,
				infiniteScroll: false,
				isUniq: false,
				replaceIds: true
			})
		);
	}

	return {
		getEntityData,
		reOrder,
		modifyEntityIds,
		reOrderStatusList,
		removingEntityId,
		insertingEntityId
	};
}
