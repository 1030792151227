import React, {
	createContext,
	FC,
	useContext,
	useReducer,
	useRef,
	useMemo,
	useCallback
} from 'react';
import {
	AudioUploadProgressT,
	chatInputReducer,
	ChatInputState,
	Command,
	CoordsType,
	EditingMessageType,
	initialInputState,
	ReplyType,
	TempMessageEvent
} from './reducers/chatInputReducer';
import { useWhiteModal } from '../hooks';

type InputContextTypes = ChatInputState & {
	setMessage: (message: string) => void;
	setReply: (reply: ReplyType) => void;
	setEditingMessage: (editingMessage: EditingMessageType) => void;
	setUploadMeta: (uploadMeta: { [key: string]: string }) => void;
	setCancelSending: (isCancelling: string) => void;
	setCommand: (command: Command) => void;
	setTime: (time: number) => void;
	setTimerId: (timerId: any) => void;
	setCancel: (cancel: boolean) => void;
	setEditProgress: (editProgress: number | null | undefined) => void;
	setEditCancelUpload: (editCancelUpload: boolean | string) => void;
	setTempMessageEvent: (tempMessageEvent: TempMessageEvent) => void;
	clipboardFile: any;
	setClipboardFile: (value: any) => void;
	setAudioCancelSending: (isCancelling: boolean) => void;
	setAudioUploadProgress: (progress: AudioUploadProgressT) => void;
	setCoords: (coords: CoordsType) => void;
	setReplyEditCancel: (isCancelling: boolean) => void;
	setIsDelayedMessage: (isDelayed: boolean) => void;
	setIsMentionSearching: (isSearching: boolean) => void;
	setMessageScheduleDate: (date: number | null) => void;
	handleScheduleModalToggle: () => void;
};

const ChatInputContext = createContext<InputContextTypes>(null!);

export const ChatInputContextProvider: FC = ({ children }) => {
	const [state, dispatch] = useReducer(chatInputReducer, initialInputState);
	const {
		message,
		editingMessage,
		reply,
		uploadMeta,
		cancelSending,
		command,
		time,
		timerId,
		cancel,
		editProgress,
		editCancelUpload,
		tempMessageEvent,
		clipboardFile,
		cancelAudioUpload,
		audioUploadProgress,
		coords,
		replyEditCancel,
		isDelayedMessage,
		isMentionSearching,
		messageScheduleDate
	} = state;

	const initEditMsgType = useRef<any>(null);

	const audioUploadProgressCached = useMemo(() => audioUploadProgress, [audioUploadProgress]);
	const editingMessageCached = useMemo(() => editingMessage, [editingMessage]);
	const { open: openScheduleModal, modalHandler: handleScheduleModalToggle } = useWhiteModal();

	const setEditingMessage = useCallback(
		(editingMessage: EditingMessageType) =>
			dispatch({ type: 'SET_EDITING_MESSAGE', payload: editingMessage }),
		[]
	);
	const setAudioCancelSending = useCallback(
		(isCancelling: boolean) =>
			dispatch({ type: 'SET_AUDIO_CANCEL_SENDING', payload: isCancelling }),
		[]
	);
	const setAudioUploadProgress = useCallback(
		(progress: AudioUploadProgressT) =>
			dispatch({ type: 'SET_AUDIO_UPLOAD_PROGRESS', payload: progress }),
		[]
	);
	const setCoords = useCallback(
		(coords: CoordsType) => dispatch({ type: 'SET_COORDS', payload: coords }),
		[]
	);
	const setReplyEditCancel = useCallback(
		(isCancelling: boolean) => dispatch({ type: 'SET_REPLY_EDIT_CANCEL', payload: isCancelling }),
		[]
	);

	const setMessage = useCallback(
		(message: string) => dispatch({ type: 'SET_MESSAGE', payload: message }),
		[]
	);
	//do not wrap into useCallBack. There is issue
	const setReply =
		// useCallback(
		(reply: ReplyType) => dispatch({ type: 'SET_REPLY', payload: reply });
	// 	,
	// 	[]
	// );
	const setCommand = useCallback(
		(command: Command) => dispatch({ type: 'SET_COMMAND', payload: command }),
		[]
	);
	const setTime = useCallback((time: number) => dispatch({ type: 'SET_TIME', payload: time }), []);
	const setTimerId = useCallback(
		(timerId: any) => dispatch({ type: 'SET_TIMER_ID', payload: timerId }),
		[]
	);
	const setCancel = useCallback(
		(cancel: boolean) => dispatch({ type: 'SET_CANCEL', payload: cancel }),
		[]
	);
	const setClipboardFile = useCallback(
		(value: any) => dispatch({ type: 'SET_CLIPBOARD_FILE', payload: value }),
		[]
	);
	const setUploadMeta = useCallback(
		(uploadMeta: { [key: string]: string }) =>
			dispatch({ type: 'SET_UPLOAD_META', payload: uploadMeta }),
		[]
	);
	const setCancelSending = useCallback(
		(isCancelling: boolean | string) =>
			dispatch({ type: 'SET_CANCEL_SENDING', payload: isCancelling }),
		[]
	);
	const setEditProgress = useCallback(
		(editProgress: number | null | undefined) =>
			dispatch({ type: 'SET_EDIT_PROGRESS', payload: editProgress }),
		[]
	);
	const setEditCancelUpload = useCallback(
		(editCancelUpload: boolean | string) =>
			dispatch({ type: 'SET_EDIT_CANCEL_UPLOAD', payload: editCancelUpload }),
		[]
	);
	const setTempMessageEvent = useCallback(
		(tempMessageEvent: TempMessageEvent) =>
			dispatch({ type: 'SET_TEMP_MESSAGE_EVENT', payload: tempMessageEvent }),
		[]
	);
	const setIsDelayedMessage = useCallback(
		(isDelayed: boolean) => dispatch({ type: 'SET_IS_DELAYED_MESSAGE', payload: isDelayed }),
		[]
	);
	const setIsMentionSearching = useCallback(
		(isSearching: boolean) => dispatch({ type: 'SET_IS_MENTION_SEARCHING', payload: isSearching }),
		[]
	);

	const setMessageScheduleDate = useCallback((date: number | null) => {
		dispatch({ type: 'SET_MESSAGE_SCHEDULE_DATE', payload: date });
	}, []);

	const contextValues = useMemo(
		() => ({
			message,
			editingMessage: editingMessageCached,
			audioUploadProgress: audioUploadProgressCached,
			reply,
			uploadMeta,
			cancelSending,
			command,
			time,
			timerId,
			cancel,
			editProgress,
			editCancelUpload,
			tempMessageEvent,
			setMessage,
			setReply,
			setEditingMessage,
			setUploadMeta,
			setCancelSending,
			setCommand,
			setTime,
			setTimerId,
			setCancel,
			setEditProgress,
			setEditCancelUpload,
			setTempMessageEvent,
			initEditMsgType,
			clipboardFile,
			setClipboardFile,
			cancelAudioUpload,
			setAudioCancelSending,
			setAudioUploadProgress,
			setCoords,
			coords,
			replyEditCancel,
			setReplyEditCancel,
			isDelayedMessage,
			setIsDelayedMessage,
			setIsMentionSearching,
			isMentionSearching,
			messageScheduleDate,
			setMessageScheduleDate,
			openScheduleModal,
			handleScheduleModalToggle
		}),
		[
			message,
			editingMessageCached,
			audioUploadProgressCached,
			reply,
			uploadMeta,
			cancelSending,
			command,
			time,
			timerId,
			cancel,
			editProgress,
			editCancelUpload,
			tempMessageEvent,
			setMessage,
			setReply,
			setEditingMessage,
			setUploadMeta,
			setCancelSending,
			setCommand,
			setTime,
			setTimerId,
			setCancel,
			setEditProgress,
			setEditCancelUpload,
			setTempMessageEvent,
			initEditMsgType,
			clipboardFile,
			setClipboardFile,
			cancelAudioUpload,
			setAudioCancelSending,
			setAudioUploadProgress,
			setCoords,
			coords,
			replyEditCancel,
			setReplyEditCancel,
			isDelayedMessage,
			setIsDelayedMessage,
			setIsMentionSearching,
			isMentionSearching,
			messageScheduleDate,
			setMessageScheduleDate,
			openScheduleModal,
			handleScheduleModalToggle
		]
	);

	return <ChatInputContext.Provider value={contextValues}>{children}</ChatInputContext.Provider>;
};

export function useChatInputContext() {
	const context = useContext(ChatInputContext);

	if (!context) throw new Error('useChatInputContext must be inside ChatInputContextProvider');

	return context;
}
