import React from 'react';
import { RepliedMSgType, ScrollToMsg } from 'shared/interfaces';
import { getDeletedMessageType } from '../utils';

type Props = {
	replied_to: RepliedMSgType;
	setScrollToMsgId?: ScrollToMsg;
};

export function RepliedMsg({ replied_to, setScrollToMsgId }: Props) {
	return (
		<>
			{replied_to ? (
				<div
					className={'mb-2 cursor-pointer border-l border-primary-dark p-1 text-kdark/60'}
					onClick={() => {
						setScrollToMsgId?.(replied_to?.id);
					}}
				>
					<div className="author text-sm text-primary-dark">
						{getRepliedToName(replied_to) + ':'}
					</div>
					<div className="replied-msg text-xs italic  dark:text-white">
						{getRepliedToContent(replied_to)}
					</div>
				</div>
			) : null}
		</>
	);
}

function getRepliedToName(replied_to: any) {
	return replied_to?.from_user_name
		? replied_to?.from_user_name
		: `${replied_to.from_user?.first_name} ${replied_to.from_user?.last_name}`;
}

function getRepliedToContent(replied_to: any) {
	const deletedMessageType = getDeletedMessageType(replied_to?.file_type?.toLowerCase() as string);

	if (replied_to?.is_deleted) {
		return `${deletedMessageType} message was deleted`;
	}

	if (replied_to.text) {
		return replied_to.text;
	}

	return replied_to.file_type;
}
