// @ts-ignore
import { createRoutine } from 'redux-saga-routines';

const Login = createRoutine('LOGIN');
const Logout = createRoutine('LOGOUT');
const GetMe = createRoutine('GETME');

export default {
	Login,
	Logout,
	GetMe
};
