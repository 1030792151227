import { AiFillEye } from 'react-icons/ai';
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';

type Props = {
	clickHandler: (entity?: any) => void;
	isDisabled?: boolean;
};

export const ViewButton = ({ clickHandler, isDisabled = false }: Props) => {
	return (
		<div className={clsx({ 'cursor-not-allowed': isDisabled })}>
			<IconButton
				onClick={clickHandler}
				className="transition hover:text-primary"
				disabled={isDisabled}
			>
				<AiFillEye size="18px" className={clsx('text-primary', { '!text-kgrey': isDisabled })} />
			</IconButton>
		</div>
	);
};
