import React from 'react';

type Props = {
	field: string;
	value: string;
};

export function CaseChatDataFieldRow({ field, value }: Props) {
	return (
		<div className="flex w-full items-center justify-between border-b py-1 text-xs">
			<div className="w-2/4 italic text-kgrey-dark-3">{field}</div>
			<div className="w-2/4 font-medium">{value}</div>
		</div>
	);
}
