import { useEffect } from 'react';
import { useAppState } from '../../../shared/state';
import get from 'lodash/get';
import { api, queryBuilder } from 'shared/services';
import config from '../../../config';
import isEmpty from 'lodash/isEmpty';

export function useCaseCreateInitialRequests(setState: any, state: any) {
	const { companyId, caseType } = useAppState();
	//Render Async Select default option if there is only 1 client & user
	useEffect(() => {
		(async function () {
			try {
				const { data } = await api.request.get(queryBuilder(`/${companyId}/client_companies/`, {}));

				if (get(data, 'results').length === 1 && caseType === config.CLIENT) {
					const theOnlyCompany = get(data, 'results[0]');

					setState((prevState: any) => ({ ...prevState, client_company: theOnlyCompany }));

					if (theOnlyCompany) {
						const { data } = await api.request.get(
							queryBuilder(
								`/${companyId}/client_companies/${get(theOnlyCompany, 'id')}/employee/`,
								{}
							)
						);

						if (get(data, 'results').length === 1) {
							const theOnlyUser = get(data, 'results[0]');

							setState((prevState: any) => ({ ...prevState, client_user: theOnlyUser }));
						}
					}
				}
			} catch (err) {
				console.error(err);
			}
		})();
	}, []);

	//Setting client user if client company has only 1 user
	useEffect(() => {
		(async () => {
			if (!isEmpty(state.selected_client_company) && caseType === config.CLIENT) {
				const { data } = await api.request.get(
					queryBuilder(
						`/${companyId}/client_companies/${get(state, 'selected_client_company.id')}/employee/`,
						{}
					)
				);

				if (get(data, 'results').length === 1) {
					const theOnlyUser = get(data, 'results[0]');

					setState((prevState: any) => ({ ...prevState, client_user: theOnlyUser }));
				}
			}
		})();
	}, [state.selected_client_company]);

	//To set default status when creating case
	useEffect(() => {
		(async () => {
			const url = `/${companyId}/my/company/case_statuses/`;
			const params = {
				is_staff: caseType === config.STAFF /*|| query.copyToStaff */ ? 'true' : ''
			};
			const { data } = await api.request.get(queryBuilder(url, { extra: params }));
			const reversedStatusesList = [...data.reverse()];
			await setState((prevState: any) => ({
				...prevState,
				statuses: reversedStatusesList,
				status: reversedStatusesList[0]
			}));
		})();
	}, [caseType]);
}
