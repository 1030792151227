import { takeLatest, call, put, all } from 'redux-saga/effects';
import { UserAction } from './actions';
import { api, queryBuilder, storage } from 'shared/services';
import userActions from './actions';
import { Company } from './reducers';
import { getDefaultCompany } from 'shared/services';

function* loadUserCompanies() {
	try {
		const { data } = yield call(api.request, queryBuilder('/user/companies/', { limit: 100 }));
		yield put(userActions.loadUserCompaniesSuccess(data.results));

		let userCompany;
		const lsUserCompany = storage.get('company') && JSON.parse(storage.get('company') || '');
		const companyFromApi = getDefaultCompany(data.results) as Company;
		const isStoredCompanyExist =
			lsUserCompany && data.results?.find((company: Company) => company.id === lsUserCompany.id);

		if (lsUserCompany && isStoredCompanyExist) {
			if (lsUserCompany?.company.status === 'ACTIVE') {
				userCompany = lsUserCompany;
			}

			if (
				lsUserCompany?.company.status === 'INACTIVE' &&
				companyFromApi?.company.status === 'ACTIVE'
			) {
				userCompany = companyFromApi;
			}

			if (
				lsUserCompany?.company.status === 'INACTIVE' &&
				companyFromApi?.company.status === 'INACTIVE'
			) {
				userCompany = lsUserCompany;
			}
		} else {
			userCompany = companyFromApi;
			yield call(storage.set, 'company', JSON.stringify(companyFromApi));
		}

		yield put(userActions.setUserCompany(userCompany));
		if (companyFromApi.company.id === lsUserCompany?.company?.id) {
			yield put(userActions.setUserCompany(companyFromApi));
			storage.set('company', JSON.stringify(companyFromApi));
		}
		//@ts-ignore
	} catch (e: AxiosError) {
		yield put(userActions.loadUserCompaniesFail(e));
	}
}

export default function* root() {
	yield all([takeLatest(UserAction.LOAD_USER_COMPANIES_REQUEST, loadUserCompanies)]);
}
