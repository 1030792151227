import { schema } from 'normalizr';
import isEmpty from 'lodash/isEmpty';

export default (
	entityName: string,
	idAttribute: string | schema.SchemaFunction | undefined,
	relations: any
): any => {
	//idAttribute can now receive dynamic primary key because of schema.SchemaFunction
	//https://github.com/paularmstrong/normalizr/blob/master/docs/api.md#idattribute-usage
	const entitySchema = new schema.Entity(entityName, undefined, {
		idAttribute
	});

	if (!isEmpty(relations)) {
		entitySchema.define(relations);
	}

	return entitySchema;
};
