import { useChatContext, useChatInputContext, useChatSocket } from 'shared/contexts';
import { useDispatch } from 'react-redux';
import { loadOneRequest } from 'modules/entity/actions';
import { useAppState } from 'shared/state';
import { useAxios } from 'shared/hooks/useAxios';
import { useEntityForm } from 'modules/entity/hooks/useEntityForm';
import { useNotistack } from 'shared/hooks/useNotistack';
import { useUpdateEntities } from '../useUpdateEntities';
import { useChatMessage } from './useChatMessage';
import { useCallback } from 'react';
import { useGetEntity } from '../useGetEntity';
import { getChatMessagesEntityName } from '../../components/Chat/utils';
import { IFileMsgType, IMessage } from '../../interfaces';
import { useTranslation } from 'react-i18next';

export function useMessageMenu(messageId?: number | undefined, custom_uuid?: string) {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { entityType, entityId, isScheduledChat } = useChatContext();
	const { companyId } = useAppState();
	const { showNotification } = useNotistack();
	const { updateEntities } = useUpdateEntities();
	const { sendJsonMessage } = useChatSocket();
	const { sendDeleteScheduledMessage, sendScheduledMessageNow } = useChatMessage({
		sendJsonMessage,
		entityId,
		entityType
	});
	const {
		handleScheduleModalToggle,
		messageScheduleDate,
		setMessageScheduleDate,
		setTempMessageEvent,
		tempMessageEvent
	} = useChatInputContext();
	const entityName = getChatMessagesEntityName({
		entityId,
		entityType,
		isScheduled: isScheduledChat
	});
	const { all: messages } = useGetEntity({
		entityId: custom_uuid,
		entity: `${entityType}Messages`,
		entityName
	});

	const { fetchData: sendPinMessageRequest } = useAxios({
		url: '',
		dynamicUrl: true,
		method: 'post',
		cb: {
			success: (data, args) => {
				const [entityId, isCase, entityName, url] = args;
				reloadChatForChangingPinnedMessage({ entityId, isCase, entityName, url });
			},
			error: (error, args) => {}
		}
	});

	const { handleRequest: handleDeleteRequest } = useEntityForm({
		entity: entityType === 'case' ? 'caseMessages' : 'imsMessages',
		name: 'messages', //any name which is not actual name just to prevent updating store
		method: 'delete',
		url: `/messages/${messageId}/`,
		deleteData: true,
		id: custom_uuid as string | number | undefined,
		cb: {
			success: (data) => {
				showNotification({
					message: t('message_deleted_notif'),
					variant: 'info'
				});
				updateEntities({
					entity: entityType === 'case' ? 'caseMessages' : 'imsMessages',
					entityId: custom_uuid || '',
					updatingData: {
						is_deleted: true,
						file: null,
						text: ''
					}
				});
			},
			error: (error) => {
				showNotification({
					message: error?.response?.data?.message,
					variant: 'error'
				});
			},
			finally: () => {}
		}
	});

	const pinMessage = (entityId: number, msgId = messageId) => {
		const isCase = entityType === 'case';

		const entityName = isCase ? `CaseChat-${entityId}` : `GroupChat-${entityId}`;
		const url = isCase
			? `/${companyId}/cases/as_business/${entityId}/`
			: `/${companyId}/ims/groups/${entityId}/`;

		//Send request here
		sendPinMessageRequest(entityId, isCase, entityName, url, {
			url: `/messages/${msgId}/pin/`
		});
	};

	//Deleting chat or scheduled chat message.
	const deleteMessage = () => {
		if (isScheduledChat) {
			sendDeleteScheduledMessage(messageId as number);
		} else {
			handleDeleteRequest();
		}
	};

	const downloadFileHandler = useCallback(async (fileUrl: string, fileType: IFileMsgType) => {
		let blobUrl = null;
		const downloadLink = document.createElement('a');

		if (fileType === 'AUDIO') {
			const fileResponse = await fetch(fileUrl);
			const fileBlob = await fileResponse.blob();

			blobUrl = URL.createObjectURL(fileBlob);
			downloadLink.download = `audio_${messageId}.mp3`;
		}

		downloadLink.target = '_blank';
		downloadLink.href = blobUrl ?? fileUrl;
		downloadLink.style.display = 'none';

		document.body.append(downloadLink);
		downloadLink.click();

		document.body.removeChild(downloadLink);
	}, []);

	const handleSendScheduledMessageNow = () => {
		sendScheduledMessageNow(messageId as number, custom_uuid as string);
	};

	const handleReScheduledMessage = () => {
		const message = messages?.items?.find((message: IMessage) => message.id === messageId);
		setTempMessageEvent({
			type: 'RE_SCHEDULE',
			data: {
				messageId: message?.id || messageId,
				scheduled_to: message?.scheduled_to
			}
		});

		handleScheduleModalToggle();
	};

	type ReloadChat = {
		entityId: number;
		isCase: boolean;
		entityName: string;
		url: string;
	};

	function reloadChatForChangingPinnedMessage({ entityId, isCase, entityName, url }: ReloadChat) {
		dispatch(
			loadOneRequest({
				id: entityId,
				entity: isCase ? 'cases' : 'ims-chats',
				name: entityName,
				url,
				params: {},
				primaryKey: 'id',
				relations: ''
			})
		);
	}

	return {
		pinMessage,
		deleteMessage,
		handleSendScheduledMessageNow,
		handleReScheduledMessage,
		downloadFileHandler
	};
}
