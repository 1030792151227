/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { PayloadProps, IRootState, QueryType, ActionProps, IMetaAll } from 'shared/interfaces';
import { loadAllRequest } from '../actions';
import Selectors from '../selectors';
import { schema } from 'normalizr';

interface DispatchProps {
	LoadAll: ({
		entity,
		name,
		url,
		params: { page, limit, sort, fields, include, filter, extra },
		dataKey,
		metaKey,
		appendData,
		prependData,
		primaryKey,
		relations,
		infiniteScroll,
		isUniq,
		reverse,
		cb,
		replaceIds,
		isCancellable,
		cursorBased
	}: ActionProps) => void;
}

interface StateProps {
	// eslint-disable-next-line @typescript-eslint/ban-types
	items: Array<{}>;
	isFetched: boolean;
	meta: IMetaAll;
}

interface OwnProps {
	children: ({ isFetched, items, meta }: StateProps) => React.ReactElement;
}

type Props = StateProps & PayloadProps & OwnProps & DispatchProps;

export class All extends Component<Props> {
	static defaultProps = {
		isFetched: true,
		dataKey: 'results',
		metaKey: '_meta',
		appendData: false,
		prependData: false,
		initialLoad: true,
		primaryKey: 'id',
		infiniteScroll: false,
		isUniq: true,
		reverse: false,
		replaceIds: false,
		isCancellable: false,
		cb: {
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			success: () => {}
		},
		cursorBased: false,
		direction: ''
	};

	componentDidMount() {
		const {
			entity,
			name,
			url,
			params,
			dataKey = 'results',
			metaKey = '_meta',
			appendData = false,
			prependData = false,
			initialLoad = true,
			primaryKey = 'id',
			relations,
			infiniteScroll = false,
			isUniq = true,
			reverse = false,
			replaceIds = false,
			isCancellable = false,
			cancelField = '',
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			onInit = () => {},
			cursorBased = false,
			direction
		} = this.props;
		if (initialLoad) {
			this.Load(
				entity,
				name,
				url,
				params,
				dataKey,
				metaKey || '',
				appendData,
				prependData,
				primaryKey,
				relations || '',
				infiniteScroll,
				isUniq,
				reverse,
				onInit,
				replaceIds,
				isCancellable,
				cancelField,
				cursorBased,
				direction
			);
		}
	}

	componentDidUpdate(prevProps: PayloadProps) {
		const {
			entity,
			name,
			url,
			params,
			dataKey = 'results',
			metaKey = '_meta',
			appendData = false,
			prependData = false,
			primaryKey = 'id',
			relations,
			infiniteScroll = false,
			isUniq = true,
			reverse = false,
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			onInit = () => {},
			replaceIds = false,
			isCancellable,
			cancelField = '',
			cursorBased = false,
			direction = ''
		} = this.props;

		if (!isEqual(url, prevProps.url) || !isEqual(params, prevProps.params)) {
			this.Load(
				entity,
				name,
				url,
				params,
				dataKey,
				metaKey || '',
				appendData,
				prependData,
				primaryKey,
				relations || '',
				infiniteScroll,
				isUniq,
				reverse,
				onInit,
				replaceIds,
				isCancellable,
				cancelField,
				cursorBased,
				direction
			);
		}
	}

	Load = (
		entity: string,
		name: string,
		url: string,
		{
			page = 1,
			limit = 20,
			sort = '',
			fields = [],
			include = [],
			filter = {},
			extra = {}
		}: QueryType,
		// eslint-disable-next-line @typescript-eslint/ban-types
		dataKey: string | Function,
		metaKey: string,
		appendData: boolean,
		prependData: boolean,
		primaryKey: string | schema.SchemaFunction,
		relations: string,
		infiniteScroll: boolean,
		isUniq: boolean,
		reverse: boolean,
		onInit: (data: any) => void,
		replaceIds: boolean,
		isCancellable: boolean | undefined,
		cancelField: string | undefined,
		cursorBased: boolean,
		direction: string | undefined
	) => {
		const { LoadAll } = this.props;

		LoadAll({
			entity,
			name,
			url,
			params: {
				page,
				limit,
				sort,
				fields,
				include,
				filter,
				extra
			},
			dataKey,
			metaKey,
			appendData,
			prependData,
			primaryKey,
			relations,
			infiniteScroll,
			isUniq,
			reverse,
			cb: {
				success: (data) => {
					onInit(data);
				}
			},
			replaceIds,
			isCancellable,
			cancelField,
			cursorBased,
			direction
		});
	};

	render() {
		const { items, isFetched, meta, children } = this.props;

		return children({
			items,
			meta,
			isFetched
		});
	}
}

const mapStateToProps = () => {
	const getAll = Selectors.getAll();
	return (state: IRootState, props: PayloadProps) => {
		const { items, isFetched, meta } = getAll(state, props);
		return { items, isFetched, meta };
	};
};

// type Props = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			LoadAll: loadAllRequest
		},
		dispatch
	);

export default connect<StateProps, DispatchProps, PayloadProps, IRootState>(
	mapStateToProps,
	mapDispatchToProps
)(All);
