import React from 'react';
import { Field } from 'formik';
import AsyncSelectJs from 'shared/components/Fields/AsyncSelectJs';
import { caseFilterStyle } from 'shared/services';
import { isMobile } from 'react-device-detect';
import { useAppState } from '../../../../shared/state';
import { useNewCaseFilter } from '../../hooks';
import { AsyncSelectSubDep } from '../../../../shared/components/Fields';
import get from 'lodash/get';
import { Department } from '../../cases.types';
import { useTranslation } from 'react-i18next';

type Props = {
	setFieldValue: any;
	isThereAnySubdepartments: boolean;
	values: Record<string, any>;
};

export function DepartmentFilterItem({ setFieldValue, isThereAnySubdepartments, values }: Props) {
	const { t } = useTranslation();
	const { companyId, isStaff } = useAppState();
	const { setDepartments, makeFlatSubdepartments } = useNewCaseFilter(() => {});
	return (
		<>
			<div className="dropdown mb-4">
				<div className="mb-2 text-sm leading-none text-kgrey-menuText dark:text-white">
					{t('department')}
				</div>
				<div className="w-full">
					<Field
						component={AsyncSelectJs}
						title="Department"
						optionLabel="name"
						optionValue="id"
						loadOptionsUrl={`/${companyId}/departments/`}
						loadOptionsParams={(search: string) => ({
							extra: {
								search,
								is_staff: isStaff ? 'true' : 'false',
								ordering: 'name'
							}
						})}
						isSearchable={!isMobile}
						name="department"
						loadOptionsKey="results"
						placeholder={t('all')}
						isMulti
						customStylesProps={{
							...caseFilterStyle,
							multiValueLabel: (provided: any) => ({
								...provided,
								wordBreak: 'break-all',
								whiteSpace: 'initial'
							})
						}}
						onChange={(option: Department[]) => {
							setFieldValue('sub_department', undefined);
							if (option) {
								setDepartments(
									option.map((dep: Department) => dep.id),
									[]
								);
							} else {
								setDepartments([], []);
							}
						}}
						isCached
					/>
				</div>
			</div>
			{isThereAnySubdepartments ? (
				<div className={'dropdown mb-4'}>
					<div className="mb-2 text-sm leading-none text-kgrey-menuText dark:text-white">
						{t('sub_department')}
					</div>
					<div className="w-full">
						<Field
							component={AsyncSelectSubDep}
							title="Sub Department"
							optionLabel={(option: Department) =>
								`${get(option, 'department.name')} [${get(option, 'name')}]`
							}
							optionValue="id"
							customStylesProps={{
								...caseFilterStyle,
								multiValueLabel: (provided: any) => ({
									...provided,
									wordBreak: 'break-all',
									whiteSpace: 'initial'
								})
							}}
							loadOptionsUrl={`/${companyId}/departments/sub_departments/`}
							loadOptionsParams={(search: string) => ({
								extra: {
									search,
									department_ids: get(values, 'department')
										.reduce((acc: any, curr: Department) => [...acc, curr.id], [])
										.join(','),
									ordering: 'name'
								}
							})}
							onChange={(option: Department[]) => {
								if (option) {
									makeFlatSubdepartments(option, values.department);
								} else {
									setDepartments(
										(values?.department || []).map((dep: Department) => dep.id),
										[]
									);
								}
							}}
							name="sub_department"
							loadOptionsKey="results"
							placeholder={t('all')}
							isMulti
							isSearchable={!isMobile}
							isCached
						/>
					</div>
				</div>
			) : null}
		</>
	);
}
