import { useSelector } from 'react-redux';
import { IRootState } from 'shared/interfaces';
import selectors from 'modules/entity/selectors';
import { useAppState } from 'shared/state';
import { useEffect } from 'react';
import { StatusReverseState } from 'shared/state/reducer';
const getStatusIds = selectors.getStatusIds;

export function useCaseLoadingLogics() {
	const { companyId, setInitialStatusReverseState, statusReverseState } = useAppState();
	const statusIds = useSelector((state: IRootState) => getStatusIds(state, companyId));

	//Create initial status reverse state
	useEffect(() => {
		if (statusIds && !statusReverseState) {
			const initialReverseStatusObject = statusIds.reduce(
				(acc: StatusReverseState, curr: number) => {
					return {
						...acc,
						[curr]: false
					};
				},
				{}
			);

			setInitialStatusReverseState(initialReverseStatusObject);
		}
	}, [statusIds]);
}
