import React, { useState } from 'react';
import { Company } from 'modules/user/reducers';
import { ErrorBoundary } from 'shared/components';
import MapView from './map/MapView';
import { Branch } from '../../../pages/BusinessManagement/components/BusinessLocations';
import { DeletedMsg } from '../Chat/components/DeletedMsg';

export function branchAddress(branch: Branch) {
	return `${branch.address ? branch.address : 'No Address'}`;
}

type Props = {
	branches: Branch[];
	currentCompany: Company;
};

export type MapMarkerType = {
	companyName: string;
	name: string;
	geometry: [string, string];
	isMain: boolean;
};

function LocationsInner({ branches, currentCompany }: Props) {
	const [data, setData] = useState(
		[{ ...currentCompany.company, isMain: true }, ...branches].reduce((acc: any, curr: any) => {
			if (curr.lat && curr.lat) {
				return [
					...acc,
					{
						companyName: curr.name,
						name: branchAddress(curr),
						latLng: { lat: +curr.lat, lng: +curr.lng },
						isMain: !!curr.isMain
					}
				];
			}
			return [...acc];
		}, [])
	);

	return (
		<div className="h-full w-full">
			<div className="h-full w-full">
				<MapView {...{ branches, currentCompany, data }} />
			</div>
		</div>
	);
}

export function Locations(props: Props) {
	return (
		<ErrorBoundary>
			<LocationsInner {...props} />
		</ErrorBoundary>
	);
}
