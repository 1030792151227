import React, { useState } from 'react';
import { useAppState } from 'shared/state';
import { WhiteModal, Button, DialogActions, DialogTitle } from 'shared/components/Elements';
import { ErrorBoundary, Loader } from 'shared/components';
import { useEntityForm } from 'modules/entity/hooks/useEntityForm';
import { useNotistack } from 'shared/hooks';
import { FilterTemplateType } from 'shared/typings';
import { useTranslation } from 'react-i18next';
import { convertToLocalizationKey } from 'shared/services/utils/convertToLocalizationKey';

type Props = {
	handleModalOpen: (item: FilterTemplateType | null) => void;
	open: FilterTemplateType | null;
};

function FilterTemplateDeleteModalInner({ handleModalOpen, open }: Props) {
	const { t } = useTranslation();
	const { companyId, caseType } = useAppState();
	const [loading, setLoading] = useState(false);
	const { showNotification } = useNotistack();

	const { handleRequest: handleDeleteRequest } = useEntityForm({
		entity: 'case-filters',
		name: `AllCaseFilters-${companyId}-${caseType}`,
		method: 'delete',
		url: `/${companyId}/case-filters/${open?.id}/`,
		deleteData: true,
		id: open?.id,
		cb: {
			success: (data) => {
				handleModalOpen(null);
				setLoading(false);
				showNotification({ message: t('successfully_deleted'), variant: 'success' });
			},
			error: (error) => {
				setLoading(false);
			},
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			finally: () => {}
		}
	});

	const rejectRequest = (branch: FilterTemplateType) => {
		setLoading(true);
		handleDeleteRequest();
	};

	const handleDelete = () => {
		if (open) rejectRequest(open);
	};

	const closeModal = () => {
		handleModalOpen(null);
	};

	return (
		<div className="relative">
			<WhiteModal handleModalOpen={closeModal} open={Boolean(open)}>
				<DialogTitle id="alert-dialog-title">
					{t('are_you_sure_to_delete_filter_item', {
						filter: t(convertToLocalizationKey(open?.title as string))
					})}
				</DialogTitle>
				<DialogActions>
					<Button onClick={closeModal} autoFocus variant="contained">
						{t('cancel')}
					</Button>
					<Button onClick={handleDelete} variant="contained" color="secondary">
						{t('delete')}
					</Button>
				</DialogActions>
				{loading && (
					<div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-70">
						<Loader />
					</div>
				)}
			</WhiteModal>
		</div>
	);
}

export function FilterTemplateDeleteModal(props: Props) {
	return (
		<ErrorBoundary>
			<FilterTemplateDeleteModalInner {...props} />
		</ErrorBoundary>
	);
}
