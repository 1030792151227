import { useSelector } from 'react-redux';
import { useAppState } from 'shared/state';
import { useChatContext } from 'shared/contexts';
import { useAxios, useQueryParams } from 'shared/hooks';
import Selectors from 'modules/entity/selectors';
import { CaseStatus } from 'shared/interfaces';
import { useSocketHelpers } from 'shared/userSocket/hooks/useSocketHelpers';
import { useCallback, useMemo } from 'react';
const getAll = Selectors.getAll();

export function useConfirmedBtnActions() {
	const { companyId, isStaff } = useAppState();
	const { entityId, entityType } = useChatContext();
	const { query, changeUrlParams } = useQueryParams();
	const { changeStatus } = useSocketHelpers();

	const statuses = useSelector((state) =>
		getAll(state, {
			entity: 'statuses',
			name: `AllStatuses-${companyId}`
		})
	)?.items as CaseStatus[];

	const currentStatus = useMemo(
		() => statuses.find((status) => status.id === Number(query?.status)),
		[query, statuses]
	);

	const isConfirmationRequired = currentStatus?.is_confirmation_required;

	//Change status in backend
	const { fetchData: confirmationButtonsRequest, loading } = useAxios({
		url: '',
		dynamicUrl: true,
		dynamicValues: true,
		method: 'patch',
		cb: {
			success: (data, args) => {
				const [arg] = args;

				if (arg.type === 'confirmed') {
					handleConfirmed(arg.currentStatus);
					return;
				}

				handleNotConfirmed(arg.currentStatus);
			},
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			error: (error, args) => {}
		}
	});

	const sendStatusChangeRequest = useCallback(
		(type: 'confirmed' | 'not_confirmed') => {
			confirmationButtonsRequest({
				url: `/${companyId}/cases/as_business/${entityId}/`,
				values: {
					id: entityId,
					status: {
						id:
							type === 'confirmed' ? currentStatus?.confirmed?.id : currentStatus?.not_confirmed?.id
					},
					...(isStaff ? { client_company: { id: companyId } } : {})
				},
				type,
				currentStatus
			});
		},
		[currentStatus, entityId]
	);

	function handleNotConfirmed(currentStatusArg: CaseStatus) {
		changeUrlParams({ status: currentStatusArg?.not_confirmed?.id, case: entityId });

		if (currentStatusArg?.not_confirmed?.title) {
			changeStatus(
				entityType,
				entityId,
				currentStatusArg?.id,
				currentStatusArg?.not_confirmed?.id,
				currentStatusArg?.not_confirmed?.title
			);
		}
	}

	function handleConfirmed(currentStatusArg: CaseStatus) {
		changeUrlParams({ status: currentStatusArg?.confirmed?.id, case: entityId });

		if (currentStatusArg?.confirmed?.title) {
			changeStatus(
				entityType,
				entityId,
				currentStatusArg?.id,
				currentStatusArg?.confirmed?.id,
				currentStatusArg?.confirmed?.title
			);
		}
	}

	return {
		sendStatusChangeRequest,
		statusChangeReqLoading: loading,
		currentStatus,
		isConfirmationRequired
	};
}
