import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import Grid from '@material-ui/core/Grid';
import 'react-quill/dist/quill.snow.css';
import clsx from 'clsx';
import { useAppState } from '../../state';

const modules = {
	toolbar: [
		[{ header: '1' }, { header: '2' }, { font: [] }],
		[{ size: [] }],
		['bold', 'italic', 'underline', 'strike', 'blockquote'],
		[{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
		['link', 'image', 'video'],
		[{ color: ['#fff'] }],
		['clean']
	],
	clipboard: {
		// toggle to add extra line breaks when pasting HTML:
		matchVisual: false
	}
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
	'header',
	'font',
	'size',
	'bold',
	'italic',
	'underline',
	'strike',
	'blockquote',
	'list',
	'bullet',
	'indent',
	'link',
	'image',
	'video'
];

type Props = {
	onChange: any;
	form: any;
	field: any;
	keywords: any;
	option: any;
};

function QuillEditorNews(props: Props) {
	const {
		form: { setFieldValue, errors },
		field: { name, value: valueFormik },
		onChange,
		keywords,
		option,
		...rest
	} = props;
	const [value, setValue] = React.useState(valueFormik);
	const [code, setCode] = useState('');
	const { isDarkMode } = useAppState();

	useEffect(() => {
		setValue((prevState: any) => `${prevState} ${code}`);
	}, [code]);

	useEffect(() => {
		setValue(valueFormik);
	}, [option]);

	useEffect(() => {
		setFieldValue(name, value);
	}, [value]);

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} sm={9}>
				<ReactQuill
					theme="snow"
					value={value}
					onChange={setValue}
					modules={modules}
					formats={formats}
					{...rest}
					className={clsx({ 'border border-kred-pink': name in errors, dark_quill: isDarkMode })}
				/>
			</Grid>
			<Grid item xs={12} sm={3}>
				<div className="vars-column">
					<div className="vars-column__title">Variables</div>
					<div className="vars-column__content">
						{keywords.map((keyword: any) => {
							return (
								<div
									className="vars-column__item mb-4"
									key={keyword.code}
									onClick={() => setCode(keyword.code)}
								>
									{keyword.name}
								</div>
							);
						})}
					</div>
				</div>
			</Grid>
		</Grid>
	);
}

export default QuillEditorNews;
