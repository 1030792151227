import React from 'react';

export function IconCasesMessages({ className = '' }: { className?: string }) {
	return (
		<svg
			className={className}
			width="13"
			height="12"
			viewBox="0 0 13 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="5.53845"
				y="12"
				width="5.53846"
				height="8.30769"
				rx="1.44"
				transform="rotate(-180 5.53845 12)"
				fill="currentColor"
			/>
			<rect
				x="12.9231"
				y="12"
				width="5.53846"
				height="12"
				rx="1.44"
				transform="rotate(-180 12.9231 12)"
				fill="currentColor"
			/>
		</svg>
	);
}
