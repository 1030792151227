import React from 'react';
import { useTranslation } from 'react-i18next';
import { HtmlParser } from 'shared/components';
import { useChatHelpers } from 'shared/hooks';
import { convertToLocalizationKey } from 'shared/services/utils/convertToLocalizationKey';

type Props = {
	text: string;
	fileType: string | null;
};

export function SimpleChatMessage({ text, fileType }: Props) {
	const { renderTextMessage } = useChatHelpers();
	const { t } = useTranslation();

	return (
		<>
			{text
				? renderTextMessage(text, HtmlParser)
				: t(convertToLocalizationKey(`${fileType}_message`))}
		</>
	);
}
