import React from 'react';
import { useCaseFilterFunctions } from '../hooks';
import { useAppState } from '../../../shared/state';
import DueToFilterItem from './filter-items/DueToFilterItem';
import { Field } from 'formik';
import { StatusFilterItem } from './filter-items/StatusFilterItem';
import { PriorityFilterItem } from './filter-items/PriorityFilterItem';
import { DepartmentFilterItem } from './filter-items/DepartmentFilterItem';
import { ClientTypeFilterItem } from './filter-items/ClientTypeFilterItem';
import { ClientCompanyFilterItem } from './filter-items/ClientCompanyFilterItem';
import { MemberFilterItem } from './filter-items/MemberFilterItem';
import { RatingFilterItem } from './filter-items/RatingFilterItem';
import { StaticFilterItem } from './filter-items/StaticFilterItem';
import { KntInputField, SelectField } from '../../../shared/components/Fields';
import { caseFilterStyle } from '../../../shared/services';
import { filteringTypeOptions } from './filterMockData';
import { FilterItem } from './FilterItem';
import { useTranslation } from 'react-i18next';

type Props = {
	setFieldValue: any;
	isThereAnySubdepartments: boolean;
	values: Record<string, any>;
};

export function NCEditTemplate({ setFieldValue, isThereAnySubdepartments, values }: Props) {
	const { t } = useTranslation();
	const { removeFilterItemFromTemplate } = useCaseFilterFunctions();
	const { activeFilterTemplate } = useAppState();
	const removeFilterHandler = (name: string) => {
		removeFilterItemFromTemplate(name);

		if (name === 'due_date') {
			setFieldValue('start_date', null);
			setFieldValue('end_date', null);
		}
		if (name === 'client_company') {
			setFieldValue('client_user', null);
		}

		if (name === 'department') {
			setFieldValue('sub_department', null);
		}

		if (name === 'status') {
			setFieldValue('filter_status', null)
		}

		setFieldValue(name, null);
	};
	const fields = activeFilterTemplate?.fields_json ?? [];

	const renderFields = (name: string) => {
		switch (name) {
			case 'due_date': {
				return (
					<FilterItem onRemove={() => removeFilterHandler('due_date')} iconClassName="top-1">
						<DueToFilterItem setFieldValue={setFieldValue} />
					</FilterItem>
				);
			}
			case 'status': {
				return (
					<FilterItem onRemove={() => removeFilterHandler('status')} iconClassName="top-1">
						<StatusFilterItem />
					</FilterItem>
				);
			}
			case 'priority': {
				return (
					<FilterItem onRemove={() => removeFilterHandler('priority')} iconClassName="top-1">
						<PriorityFilterItem />
					</FilterItem>
				);
			}
			case 'department': {
				return (
					<>
						<FilterItem
							onRemove={() => removeFilterHandler('department')}
							alignment="top"
							className="relative"
							iconClassName="relative top-9"
						>
							<DepartmentFilterItem
								values={values}
								setFieldValue={setFieldValue}
								isThereAnySubdepartments={isThereAnySubdepartments}
							/>
						</FilterItem>
					</>
				);
			}
			case 'client_type': {
				return (
					<>
						<FilterItem onRemove={() => removeFilterHandler('client_type')} iconClassName="top-1">
							<ClientTypeFilterItem />
						</FilterItem>
					</>
				);
			}
			case 'client_company': {
				return (
					<FilterItem
						onRemove={() => removeFilterHandler('client_company')}
						iconClassName="top-9"
						alignment="top"
					>
						<ClientCompanyFilterItem setFieldValue={setFieldValue} values={values} />
					</FilterItem>
				);
			}
			case 'member': {
				return (
					<FilterItem onRemove={() => removeFilterHandler('member')} iconClassName="top-1">
						<MemberFilterItem
							label={t('staff_access')}
							name="member"
							placeholder={t('select_staff', { calculatedStaffAccessType: '' })}
						/>
					</FilterItem>
				);
			}
			case 'assignees': {
				return (
					<FilterItem onRemove={() => removeFilterHandler('assignees')} iconClassName="top-1">
						<MemberFilterItem
							label={t('assigned_to')}
							name="assignees"
							placeholder={t('select_assignee')}
						/>
					</FilterItem>
				);
			}
			case 'assigned_by': {
				return (
					<FilterItem onRemove={() => removeFilterHandler('assigned_by')} iconClassName="top-1">
						<MemberFilterItem
							label={t('assigned_by')}
							name="assigned_by"
							placeholder={t('select_assigned_by')}
						/>
					</FilterItem>
				);
			}
			case 'rate': {
				return (
					<FilterItem onRemove={() => removeFilterHandler('rate')} iconClassName="top-1">
						<RatingFilterItem />
					</FilterItem>
				);
			}
			case 'unread': {
				return (
					<FilterItem
						key="unread"
						onRemove={() => removeFilterHandler('unread')}
						className="!mb-4"
						removeIcon={activeFilterTemplate?.is_default}
					>
						<StaticFilterItem title={t('unread')} />
					</FilterItem>
				);
			}
			case 'unresponded': {
				return (
					<FilterItem
						key="unresponded"
						onRemove={() => removeFilterHandler('unresponded')}
						className="!mb-4"
						removeIcon={activeFilterTemplate?.is_default}
					>
						<StaticFilterItem title={t('unresponded')} />
					</FilterItem>
				);
			}
			case 'expired': {
				return (
					<FilterItem
						key="expired"
						onRemove={() => removeFilterHandler('expired')}
						className="!mb-4"
						removeIcon={activeFilterTemplate?.is_default}
					>
						<StaticFilterItem title={t('overdue')} />
					</FilterItem>
				);
			}
			case 'pinned': {
				return (
					<FilterItem
						key="pinned"
						onRemove={() => removeFilterHandler('pinned')}
						className="!mb-4"
						removeIcon={activeFilterTemplate?.is_default}
					>
						<StaticFilterItem title={t('pinned')} />
					</FilterItem>
				);
			}
			case 'tagged': {
				return (
					<FilterItem
						key="tagged"
						onRemove={() => removeFilterHandler('tagged')}
						className="!mb-4"
						removeIcon={activeFilterTemplate?.is_default}
					>
						<StaticFilterItem title={t('tagged')} />
					</FilterItem>
				);
			}
			case 'scheduled': {
				return (
					<FilterItem
						key="scheduled"
						onRemove={() => removeFilterHandler('scheduled')}
						className="!mb-4"
						removeIcon={activeFilterTemplate?.is_default}
					>
						<StaticFilterItem title={t('scheduled')} />
					</FilterItem>
				);
			}
			default:
				return null;
		}
	};

	return (
		<div>
			{activeFilterTemplate ? (
				<>
					{!activeFilterTemplate.is_default ? (
						<div className="flex ">
							<div className="case-form__input-row mx-2 mb-4 flex-grow">
								<Field
									label={t('filter_title')}
									name="title"
									component={KntInputField}
									placeholder={t('type_template_name')}
									required
									classNames="mb-2"
									autoFocus
									maxLength={40}
									inputElementClassNames="!py-2.5 border-kgrey-light-grey-4"
								/>
								<div className="amount flex justify-end text-xs text-kgrey">{`${
									values.title?.length ?? 0
								}/40`}</div>
							</div>
							{activeFilterTemplate.fields_json.length > 1 ? (
								<div className="case-form__input-row ">
									<div className="mx-2 mb-2 block text-sm font-light text-kgrey-menuText">
										{t('filtering_type')}
									</div>
									<div className="!w-28">
										<Field
											component={SelectField}
											className="basic-single"
											classNamePrefix="select"
											defaultValue={filteringTypeOptions[0]}
											name="filter_type"
											styles={caseFilterStyle}
											options={filteringTypeOptions.map((option) => ({
												...option,
												label: t(option.value)
											}))}
										/>
									</div>
								</div>
							) : null}
						</div>
					) : null}

					<>
						{activeFilterTemplate.is_default ? (
							<div className={`case-form__input-row-wrap`}>
								<StaticFilterItem
									title={t('unread_and_assigned_to_me')}
									className="pointer-events-none !mb-2"
								/>
								<div className="!mb-2 !text-xs">{t('or_uppercase')}</div>
								<StaticFilterItem title={t('draft')} className="pointer-events-none !mb-2" />
								<div className="!mb-2 !text-xs">{t('or_uppercase')}</div>
								<StaticFilterItem
									title={t('overdue_and_assigned_to_me')}
									className="pointer-events-none !mb-2"
								/>
								<div className="!mb-2 !text-xs">{t('or_uppercase')}</div>
								<StaticFilterItem
									title={t('overdue_and_assigned_by_me')}
									className="pointer-events-none !mb-2"
								/>
								<div className="!mb-2 !text-xs">{t('or_uppercase')}</div>
								<StaticFilterItem
									title={t('unresponded_and_assigned_to_me')}
									className="pointer-events-none !mb-2"
								/>
							</div>
						) : (
							<div className="react-select-dropdown">
								{fields.map((field) => {
									return (
										<React.Fragment key={field.name}>{renderFields(field.name)}</React.Fragment>
									);
								})}
							</div>
						)}
					</>
				</>
			) : (
				<div className="text-center">{t('please_select_template_filter')}</div>
			)}
		</div>
	);
}
