import React, { useEffect } from 'react';
import { OptionProps } from 'rc-mentions/lib/Option';
import { useChatContext, useChatInputContext } from 'shared/contexts';
import { useAxios } from 'shared/hooks';
import config from 'config';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { mentionEventChannel } from 'eventChannels/mention';
const imgPath = `${config.PUBLIC_ASSETS}img/`;

const ALL = 'ALL';
const PREFIX = '@';

export function useMentionApi() {
	const { message, setIsMentionSearching } = useChatInputContext();
	const [mentionUsers, setMentionUsers] = React.useState<any[]>([]);

	const { entityId, setMentionUsersList } = useChatContext();
	const { userData } = useAuth();

	const MEMBERS_URL = `/entity/${entityId}/members/`;

	useEffect(() => {
		if (!message.length) {
			setIsMentionSearching(false);
		}
	}, [message, setIsMentionSearching]);

	const { fetchData: reFetchChatMembers } = useAxios({
		url: MEMBERS_URL,
		cb: {
			success: async (data, args) => {
				const memberListWithoutCurrentUser = data.filter(
					({ id }: { id: number }) => id !== userData.id
				);

				setMentionUsersList(data);

				const options = memberListWithoutCurrentUser.map(
					({ first_name, last_name, display_name, avatar, id }: any) => {
						const computedName = display_name ? display_name : '';
						const computedImageUrl = avatar?.thumbnail ?? `${imgPath}avatar-placeholder.png`;

						return {
							key: String(id),
							value: `${computedName}<${first_name} ${last_name}>`,
							className: 'dynamic-option',
							label: (
								<MentionOption
									computedImageUrl={computedImageUrl}
									computedName={`${first_name} ${last_name}`}
									display_name={display_name}
								/>
							)
						};
					}
				);

				setMentionUsers(options);
			},
			error: (error, args) => {}
		}
	});

	useEffect(() => {
		const unSubscribeMentionUpdate = mentionEventChannel.on('onMembersUpdate', () => {
			reFetchChatMembers();
		});

		return () => {
			unSubscribeMentionUpdate();
		};
	}, []);

	//When chat is changed, need to re-fetch tagging members again
	useEffect(() => {
		reFetchChatMembers();
	}, [entityId]);

	const onSearch = () => {
		setIsMentionSearching(true);
	};

	const onOptionSelect = (option: OptionProps, prefix: string) => {
		setIsMentionSearching(false);
	};

	return {
		onSearch,
		onOptionSelect,
		ALL,
		PREFIX,
		mentionUsers,
		reFetchChatMembers
	};
}

type MentionProps = {
	computedImageUrl: string;
	computedName: string;
	display_name: string | null;
};
function MentionOption(props: MentionProps) {
	const { computedImageUrl, computedName, display_name } = props;
	return (
		<div className="flex items-center">
			<div className="mr-2 h-6 w-6 overflow-hidden rounded">
				<img src={computedImageUrl} alt={computedName} />
			</div>
			<div>
				<div className="align-middle text-sm font-medium leading-none dark:text-white">
					{computedName}
				</div>
				{/*3581 - commenting out username bcs I am asked to not to show auto generated usernames while tagging*/}
				{/*{!!display_name && (
					<span className="text-xs font-light leading-none text-kgrey-dark-3 dark:text-kgrey">
						@{display_name}
					</span>
				)}*/}
			</div>
		</div>
	);
}
