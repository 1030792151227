import React, { useEffect, useMemo } from 'react';
import { KntTwilioStyle } from 'shared/services';

import { useAppState } from 'shared/state';
import { AsyncSelect } from 'shared/components';
import get from 'lodash/get';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

type Props = {
	caseId: number | null;
	staffAccessList: any[];
	members: any[] | null;
	setMembers: any;
	setFieldValue: any;
};

export function CDStaffAccess({ staffAccessList, members, setMembers, setFieldValue }: Props) {
	const { companyId, isManager: isLoggedUserManager, isAdmin, role: userRole } = useAppState();
	const isRemovingAllMemberDisabled = useMemo(() => {
		return staffAccessList.some(
			(member) => member.role === 'admin' || member.roles?.[0] === 'admin'
		);
	}, [userRole, staffAccessList]);
	const { t } = useTranslation();

	useEffect(() => {
		if (members !== null) {
			setFieldValue('access_list', members);
		}
	}, [members]);

	const handleMembers = (option: any[]) => {
		setMembers(option);
	};

	return (
		<div className={'w-[336px]'}>
			{/* @ts-ignore */}
			<Field
				component={AsyncSelect}
				customStylesProps={{
					...KntTwilioStyle,
					multiValueRemove: (provided: any, state: any) => {
						const { role, roles } = state?.data;
						const currentMemberRole = roles ? roles[0] : role;
						const isCurrentCaseMemberAdmin = currentMemberRole === 'admin';
						const isCurrentCaseMemberManager = currentMemberRole === 'manager';

						//disable removing high level roles for manager
						const disableRemovingForManager = isLoggedUserManager && isCurrentCaseMemberAdmin;

						//Disable removing high level roles for member
						const disableRemovingForMember =
							userRole === 'member' && (isCurrentCaseMemberManager || isCurrentCaseMemberAdmin);

						const isRemovingCurrentMemberDisabled =
							isCurrentCaseMemberAdmin || disableRemovingForManager || disableRemovingForMember;

						return {
							...provided,
							pointerEvents: isRemovingCurrentMemberDisabled ? 'none' : 'auto'
						};
					}
				}}
				name="staff"
				placeholder={t('select_staff', { calculatedStaffAccessType: '' })}
				optionValue={(option: any) => (option?.user ? option?.user?.id : option?.id)}
				optionLabel={(option: any) => {
					return option?.user
						? `${get(option, 'user.first_name')} ${get(option, 'user.last_name')}`
						: `${get(option, 'first_name')} ${get(option, 'last_name')}`;
				}}
				loadOptionsUrl={`/${companyId}/members/`}
				loadOptionsParams={(search: string) => ({
					limit: 50,
					extra: {
						search
					}
				})}
				loadOptionsKey="results"
				isSearchable
				alwaysRefetch
				customStyles="KntTwilioStyle"
				loadOptionsOnMenuOpen
				isMulti
				value={staffAccessList}
				closeMenuOnSelect={false}
				filterOption={(option: any) => {
					return !staffAccessList
						.reduce((acc, member) => [...acc, member?.user ? member?.user.id : member?.id], [])
						.includes(option?.data?.user.id);
				}}
				onChange={handleMembers}
				isClearable={!isRemovingAllMemberDisabled}
			/>
			<div>
				{!staffAccessList?.length ? (
					<div className="mt-1 text-xs text-red-500">{t('staff_access_empty_validation')}</div>
				) : null}
			</div>
		</div>
	);
}
