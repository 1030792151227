import React, { useState, forwardRef } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useTranslationHelpers } from 'shared/hooks/useTranslationHelpers';

type Props = {
	label?: string;
	required?: boolean;
	classNames?: string;
	name: string;
	placeholder?: string;
	type?: string;
	value?: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (event?: React.FocusEvent<HTMLInputElement>) => void;
	error: boolean | undefined;
	// helperText: string | false | undefined | string[];
	helperText: any;
	disabled?: boolean;
};

export const KntInput = forwardRef<HTMLInputElement | null, Props>(function KntInput(
	{
		label = '',
		required,
		name,
		classNames = '',
		placeholder = '',
		type = 'text',
		value,
		onChange = () => {},
		onBlur = () => {},
		error,
		helperText,
		disabled = false
	},
	ref
) {
	const [isShown, setIsShown] = useState(false);
	const { t } = useTranslation();
	const { isRTL } = useTranslationHelpers();

	return (
		<div className={clsx('mb-4', classNames)}>
			{!!label && (
				<label className="ml-6 mb-2 block text-xs font-light text-kgrey-welcome">
					<span>{label}</span>
					{required ? <sup>*</sup> : null}
				</label>
			)}
			<div className="relative">
				<input
					name={name}
					type={isShown ? 'text' : type}
					className={clsx(
						'kennekt-input rounded-lg border border-kgrey-inputBd px-6 py-3.5 text-xs outline-none transition dark:border-white/30 ' +
							'w-full placeholder-current placeholder-kgrey-welcome focus:border-primary focus:shadow-lg dark:bg-kdark/70',
						error && 'border-red-500',
						{ 'bg-kgrey-bgmenu': disabled }
					)}
					placeholder={placeholder}
					{...(value ? { value: value } : {})}
					onChange={(e) => {
						onChange(e);
					}}
					ref={ref}
					disabled={disabled}
					onBlur={(event) => {
						onBlur(event);
					}}
				/>

				{type === 'password' ? (
					<span
						className={clsx(
							'absolute top-2/4 z-10 -translate-y-2/4 cursor-pointer text-xs text-kgrey-welcome transition hover:text-primary',
							{ 'left-2': isRTL, 'right-2': !isRTL }
						)}
						onClick={() => setIsShown((prev) => !prev)}
					>
						{isShown ? t('hide') : t('show')}
					</span>
				) : null}
			</div>
			{error ? <span className={'text-xs text-red-500'}>{helperText}</span> : null}
		</div>
	);
});
