import React, { DOMElement, useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Loader } from '../../index';
import { GrClose } from 'react-icons/gr';
import IconButton from '@material-ui/core/IconButton';
import { AiOutlineZoomIn, AiOutlineZoomOut } from 'react-icons/ai';
import { GrRotateLeft, GrRotateRight } from 'react-icons/gr';
import clsx from 'clsx';
import { useWindowSize } from '../../../hooks';

type Props = {
	file: string;
	fileName: string | undefined;
	onClose: () => void;
};

export function PdfViewer({ file, fileName, onClose }: Props) {
	const [numPages, setNumPages] = React.useState<number | null>(null);
	const [isPdfLoaded, setIsPdfLoaded] = React.useState(false);
	const [zoom, setZoom] = useState(1);
	const [rotate, setRotate] = useState(0);
	const { height: windowHeight } = useWindowSize();
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (ref.current && windowHeight) {
			ref.current.style.height = `${windowHeight - 150}px`;
		}
	}, [windowHeight]);

	function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
		setNumPages(numPages);
		setIsPdfLoaded(true);
	}

	function zoomHandler(scaling: 'in' | 'out') {
		if (scaling === 'in') {
			setZoom((prev) => prev + 0.1);
		}

		if (scaling === 'out') {
			setZoom((prev) => (prev > 0.5 ? prev - 0.1 : 0.5));
		}
	}

	function rotateHandler(direction: 'left' | 'right') {
		if (direction === 'left') {
			setRotate((prevState) => {
				return prevState === 0 ? 270 : prevState - 90;
			});
		}
		if (direction === 'right') {
			setRotate((prevState) => {
				return prevState === 360 ? 90 : prevState + 90;
			});
		}
	}

	// file = `https://cors-anywhere.herokuapp.com/${file}`;

	return (
		<div>
			<div>{!isPdfLoaded && <Loader tiled />}</div>
			<div className="mb-8 flex items-center justify-between">
				<h2 className="font-bold">
					{fileName}
					<a
						href={file}
						download
						className="ml-2 text-sm font-light text-kgrey transition hover:text-primary"
					>
						( Download Pdf )
					</a>
				</h2>
				<div>
					<IconButton size="medium" onClick={() => rotateHandler('left')}>
						<GrRotateLeft />
					</IconButton>
					<IconButton size="medium" onClick={() => rotateHandler('right')}>
						<GrRotateRight />
					</IconButton>
				</div>
				<div>
					<IconButton size="medium" onClick={() => zoomHandler('out')}>
						<AiOutlineZoomOut />
					</IconButton>
					<IconButton size="medium" onClick={() => zoomHandler('in')}>
						<AiOutlineZoomIn />
					</IconButton>
				</div>
				<div
					onClick={onClose}
					style={{ zIndex: 1000 }}
					className="flex-centered h-8 w-8 cursor-pointer rounded-full border border-kgrey bg-kgrey-bg transition hover:border-primary hover:bg-primary-200"
				>
					<GrClose className="hover:text-primary" />
				</div>
			</div>
			<Document
				file={file}
				onLoadSuccess={onDocumentLoadSuccess}
				externalLinkTarget="_blank"
				loading={<h2>Loading file... Please wait...</h2>}
				className={clsx('overflow-auto')}
				inputRef={ref}
			>
				{Array.from(new Array(numPages), (el, index) => (
					<React.Fragment key={index}>
						<Page
							key={`page_${index + 1}`}
							pageNumber={index + 1}
							loading="Loading page.."
							scale={zoom}
							rotate={rotate}
						/>
						<div className=" flex-centered">
							<p className="my-2 h-8 w-full w-8 rounded-full border border-kgrey text-center font-poppins text-xs leading-8 text-kgrey">
								{index + 1}
							</p>
						</div>
					</React.Fragment>
				))}
			</Document>
		</div>
	);
}
