import React from 'react';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { useWhiteModal } from '../../../hooks';
import { useHistory } from 'react-router';
import { ProfileChangePassword } from 'pages/UserProfile/components/ProfileChangePassword';
import { FiLogOut } from 'react-icons/fi';
import { HiOutlineUserCircle } from 'react-icons/hi';
import { MdSecurity } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

type HeaderNavItem = {
	icon: React.ReactNode;
	text: string;
	handler: () => void;
};

export function UserPopover() {
	const { t } = useTranslation();
	const { userData } = useAuth();
	const history = useHistory();
	const { open, modalHandler, WhiteModal } = useWhiteModal();
	// const { notifVisible, setNotifVisible, handleNotificationClose, Notification } =
	// 	useNotification();

	const navItems = [
		{
			icon: <HiOutlineUserCircle />,
			text: t('profile'),
			handler: () => {
				history.push('/user-profile');
			}
		},
		{
			icon: <MdSecurity />,
			text: t('change_password'),
			handler: () => {
				modalHandler();
				// history.push('/user-profile')
			}
		},
		{
			icon: <FiLogOut />,
			text: t('logout'),
			handler: () => {
				history.push('/logout');
			}
		}
	];

	return (
		<div className={'user-popover rounded-xl bg-white font-poppins'}>
			<div className="user-popover__header bg-primary-dark px-4 py-2 text-white">
				<div className="user-popover__name mb-1 text-sm font-semibold">{`${userData.first_name} ${userData.last_name}`}</div>
				<div className="user-popover__email text-xs font-medium text-white/80">
					{userData.username}
				</div>
			</div>
			<div className="user-popover__nav dark:bg-kdark">
				{navItems.map((item: HeaderNavItem) => {
					return (
						<div
							key={item.text}
							// to={item.url}
							className="user-popover__nav-item group flex cursor-pointer items-center p-3 transition hover:text-primary"
							onClick={item.handler}
						>
							<div className="user-popover__icon flex-centered mr-3 h-6 w-6 rounded bg-kgrey/10 text-kgrey-menuText group-hover:text-primary">
								{item.icon}
							</div>
							<div className="user-popover__text text-xs font-medium">{item.text}</div>
						</div>
					);
				})}
			</div>
			<WhiteModal handleModalOpen={modalHandler} open={open}>
				<ProfileChangePassword modalHandler={modalHandler} />
			</WhiteModal>
		</div>
	);
}
