import { useSelector } from 'react-redux';
import { IRootState } from '../interfaces';
import { useQueryParams } from './useQueryParams';
import get from 'lodash/get';
import { useCallback } from 'react';

type Props = {
	pageKey: string;
	entity: string;
	entityName: string;
};

export function usePageChangeAfterEntityDelete({ pageKey = 'page', entity, entityName }: Props) {
	const { query, changeUrlParams } = useQueryParams();
	const page = query?.[pageKey] as string | undefined;

	const entityFromRedux = useSelector((state: IRootState) =>
		get(state, `entity.${entity}.${entityName}`, '')
	);

	const entityCount = entityFromRedux?.ids?.length;

	const forwardToPreviousPage = useCallback(() => {
		if (entityCount && entityCount < 2 && page) {
			changeUrlParams({ [pageKey]: parseInt(page) - 1 });
		}
	}, [entityCount, page, pageKey, changeUrlParams]);

	return {
		forwardToPreviousPage
	};
}
