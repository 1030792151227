import React, { useEffect, useRef, useState } from 'react';
import { Field } from 'formik';

import { isMobile } from 'react-device-detect';
import get from 'lodash/get';
import AsyncSelectJs from 'shared/components/Fields/AsyncSelectJs';
import { KntTwilioStyle } from 'shared/services';
import { SupRequired } from '../../../../shared/components';

type Props = {
	companyId: any;
	setFieldValue: any;
	titleRef: any;
	setState: any;
	state: any;
	values: any;
};

export const CreateCaseClientUser = React.forwardRef<any, Props>(function CreateCaseClientUser(
	props,
	ref
) {
	const { companyId, setFieldValue, titleRef, setState, state, values } = props;
	const [loading, setLoading] = useState(false);
	const clientCompanyTypeRef = useRef(values.client_company?.id);

	//These 2 useEffect is necessary for unmount and remount departments AsyncSelect when we change case type
	//If we do not remount this components AsyncSelect component does not refetch new departments due to isCached=true prop
	//If we do not set isCached to true, navigating with tabs does work properly/

	useEffect(() => {
		if (values.client_company?.id !== clientCompanyTypeRef.current) {
			setLoading(true);
			clientCompanyTypeRef.current = state.type;
		}
	}, [values.client_company?.id]);

	useEffect(() => {
		if (loading) {
			setLoading(false);
		}
	}, [loading]);

	return (
		<>
			{!loading && (
				<div className="react-select-dropdown mb-4">
					<label className="ml-6 mb-2 block text-xs font-light text-kgrey-welcome">
						<span>Client User Name</span>
						<SupRequired />
					</label>
					<Field
						component={AsyncSelectJs}
						customStylesProps={KntTwilioStyle}
						name="client_user"
						placeholder="Client User Name Here"
						optionLabel={(option: any) => `${option?.first_name} ${option?.last_name}`}
						optionValue="id"
						isSearchable={!isMobile}
						isClearable
						isCached
						autoComplete="off"
						loadOptionsKey="results"
						loadOptionsUrl={`/${companyId}/client_companies/${get(
							values,
							'client_company.id'
						)}/employee/`}
						loadOptionsParams={(search: string) => ({
							extra: {
								search
							}
						})}
						onChange={() => {
							setFieldValue('title', titleRef.current);
							setState((prevState: any) => ({ ...prevState, client_user: {} }));
						}}
					/>
				</div>
			)}
		</>
	);
});
