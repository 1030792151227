import React, { useEffect } from 'react';
import { useSwitch } from 'shared/hooks';
import clsx from 'clsx';
import { SupRequired } from '../../../../shared/components';
import AsyncSelectJs from '../../../../shared/components/Fields/AsyncSelectJs';
import { KntTwilioStyle } from '../../../../shared/services';
import { useAppState } from '../../../../shared/state';
import get from 'lodash/get';
import { Field } from 'formik';
import { IOSSwitch } from '../../../../shared/components/Elements/Fields/SwitchComp';
import { FormControlLabel } from '../../../../shared/components/Elements';
import { useTranslation } from 'react-i18next';

const buttonsJson = [
	{
		title: 'Extra'
	},
	{
		title: 'Custom'
	}
];

type Props = {
	state: any;
	setState: any;
	setFieldValue: any;
	setFormState: any;
};

export function CreateCaseStaffAccess({ state, setState, setFieldValue, setFormState }: Props) {
	const { checked, handleChange } = useSwitch({ staff_access_toggle: false });
	const { companyId } = useAppState();
	const { t } = useTranslation();

	const toggleActiveClass = (title: string) => {
		setFieldValue('member_list', []);
		setFieldValue('exception_member_list', []);
		setState((prevState: any) => ({ ...prevState, memberingType: title }));
	};
	const { memberingType } = state;

	useEffect(() => {
		setFormState((prev: any) => ({ ...prev, membersShow: checked.staff_access_toggle }));
	}, [checked.staff_access_toggle]);

	const calculatedStaffAccessType = memberingType === 'Extra' ? `(${t('optional_hint')})` : '';

	return (
		<div className="react-select-dropdown mb-4">
			<div className="flex items-center">
				<FormControlLabel
					control={
						<IOSSwitch
							checked={checked.staff_access_toggle}
							onChange={handleChange}
							name="staff_access_toggle"
						/>
					}
					label={t('modify_staff_access_list')}
				/>
			</div>

			{checked.staff_access_toggle && (
				<>
					<div className="my-3.5 -mr-4 flex">
						{buttonsJson.map((btn) => {
							return (
								<button
									className={clsx(
										'mr-4 w-1/2 rounded-10 py-1 text-base ring-primary transition focus:ring',
										{
											'bg-primary-200': btn.title === memberingType,
											'bg-kgrey-filter-bg dark:bg-kdark': btn.title !== memberingType
										}
									)}
									key={btn.title}
									type="button"
									onClick={() => toggleActiveClass(btn.title)}
								>
									{t(btn.title.toLowerCase())}
								</button>
							);
						})}
					</div>
					<div>
						<label className="ml-6 mb-2 block text-xs font-light text-kgrey-welcome">
							<span>{t('staff_access')}</span>
							{memberingType === 'Custom' && <SupRequired />}
						</label>
						<Field
							component={AsyncSelectJs}
							customStylesProps={KntTwilioStyle}
							name={`${memberingType === 'Extra' ? 'member_list' : 'exception_member_list'}`}
							placeholder={t('select_staff', { calculatedStaffAccessType })}
							optionLabel={(option: any) =>
								`${get(option, 'user.first_name', '')} ${get(option, 'user.last_name', '')}`
							}
							optionValue="id"
							isSearchable
							isClearable
							isMulti
							isCached
							loadOptionsKey="results"
							loadOptionsUrl={`/${companyId}/members/`}
							loadOptionsParams={(search: string) => ({
								extra: {
									search
								}
							})}
						/>
					</div>
				</>
			)}
		</div>
	);
}
