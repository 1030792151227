import * as React from 'react';

function SvgIconMessage(props: any) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="12.286" height="12.286" {...props}>
			<path
				data-name="script [#1603]"
				d="M7.372 12.286H2.457A2.46 2.46 0 010 9.829v-.615A.615.615 0 01.614 8.6h1.843V2.457A2.46 2.46 0 014.915 0h4.914a2.46 2.46 0 012.457 2.457v.614a.615.615 0 01-.614.614H9.829v6.144a2.46 2.46 0 01-2.457 2.457zM4.915 1.229a1.23 1.23 0 00-1.229 1.228V8.6h2.458a1.23 1.23 0 011.228 1.229v1.229A1.231 1.231 0 008.6 9.829v-8.6zm-3.686 8.6a1.23 1.23 0 001.228 1.229h3.686v-.614a.615.615 0 00-.614-.614zm8.6-8.6v1.228h1.229a1.23 1.23 0 00-1.23-1.228z"
				fill="#fff"
			/>
		</svg>
	);
}

export default SvgIconMessage;
