import React, { useEffect } from 'react';
import EntityContainer from 'modules/entity/containers';
import { useGetEntityOne, useNotistack, useQueryParams } from 'shared//hooks';
import { useAppState } from 'shared/state';
import { Case } from 'shared/interfaces';
import { CaseChatHeader } from 'pages/Cases/chat-components/CaseChatHeader';
import { ChatLayout } from 'shared/components/Chat/ChatLayout';
import { CaseMessagesList } from './CaseMessagesList';
import { CaseChatInputControls } from './CaseChatInputControls';
import { ChatContextProvider, ChatInputContextProvider, ChatSocketProvider } from 'shared/contexts';
import { CaseType } from '../components/CaseItem';
import config from '../../../config';
import { CaseTypes } from 'shared/state/reducer';

export function CaseChat() {
	const { query, removeQueryParams } = useQueryParams<{ case: number; status: number }>();
	const { toggleChatPanel, chatPanelToggled, companyId, caseType, setCaseType } = useAppState();
	const caseId = 'case' in query ? query.case : undefined;
	const { showNotification } = useNotistack();

	const caseChatEntity = useGetEntityOne({
		entity: 'cases',
		entityId: +caseId!,
		entityName: `CaseChat-${caseId}`
	});

	useEffect(() => {
		if (!chatPanelToggled && 'case' in query) toggleChatPanel();
	}, [query, chatPanelToggled]);

	//Set case actual case type when open not selected case from foreground notification
	useEffect(() => {
		if ('business_company' in caseChatEntity?.item) {
			const isStaff =
				(caseChatEntity?.item as CaseType).business_company.id ===
				(caseChatEntity?.item as CaseType).client_company.id;

			const caseTypeFromObject = isStaff ? config.STAFF : config.CLIENT;

			if (caseType !== caseTypeFromObject) {
				setCaseType(caseTypeFromObject as CaseTypes);
			}
		}
	}, [caseChatEntity]);

	return (
		<>
			{caseId && companyId ? (
				<EntityContainer.One
					id={String(caseId)}
					relations={''}
					entity={'cases'}
					name={`CaseChat-${caseId}`}
					url={`/${companyId}/cases/${
						query.isSupportCase ? 'as_client' : 'as_business'
					}/${caseId}/`}
					params={{}}
					onError={(error) => {
						//if current user does not have access to the case, It should be closed
						if (error?.response?.status === 404) {
							removeQueryParams();
							showNotification({ variant: 'error', message: "You don't have access to the case" });
						}
					}}
				>
					{({ item, isFetched }) => {
						const caseData = { ...item } as Case;
						return (
							<>
								<ChatContextProvider entityId={Number(caseId)} entityType={'case'}>
									<ChatInputContextProvider>
										<ChatSocketProvider entityId={Number(caseId)} entityType={'case'}>
											<ChatLayout
												ChatHeader={<CaseChatHeader isFetched={isFetched} caseData={caseData} />}
												ChatBody={<CaseMessagesList caseId={Number(caseId)} />}
												ChatInput={<CaseChatInputControls />}
											/>
										</ChatSocketProvider>
									</ChatInputContextProvider>
								</ChatContextProvider>
							</>
						);
					}}
				</EntityContainer.One>
			) : null}
		</>
	);
}
