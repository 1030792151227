import React from 'react';
import config from '../../config';
import { NewButton } from './Elements';
import { useRouter } from '../hooks';
import clsx from 'clsx';
import { useAppState } from '../state';
import { Trans, useTranslation } from 'react-i18next';
const imgPath = `${config.PUBLIC_ASSETS}img/`;

type Props = {
	timeLeft: {
		days: number;
		hours: number;
		minutes: number;
	};
	type?: 'default' | 'warning';
};
export function BillingWidgetTrial({ timeLeft, type = 'default' }: Props) {
	const { history } = useRouter();
	const { isAppAvailable } = useAppState();
	const { t } = useTranslation();

	const navigateToCheckout = () => history.push('/payments/billing');
	const timeLeftInfo = `${timeLeft.days} ${timeLeft.days > 1 ? t('days') : t('day')} ${
		timeLeft.days === 0 ? `${timeLeft.hours} ${t('hours')} ${timeLeft.minutes} ${t('minutes')}` : ''
	}`;

	return (
		<div className="b-widget px-5">
			<div className="b-widget__trial-info mb-8 text-center text-xs text-kgrey">
				{!isAppAvailable ? (
					<div className="font-medium text-kred-pink">{t('your_trial_finished')}</div>
				) : (
					<Trans
						i18nKey="billing_trial_info"
						values={{ timeLeftInfo }}
						components={{
							timeLeftInfoComponent: <strong className="font-bold text-primary-dark" />
						}}
					/>
				)}
			</div>

			<div
				className={clsx('b-widget__content rounded-10 p-2.5', {
					'bg-kgrey-widget-bg': type === 'default',
					'bg-kred-billing-warn': type === 'warning'
				})}
			>
				<div className="b-widget__img mx-auto -mt-6 mb-3.5 h-[70px] w-[118px]">
					<img src={`${imgPath}img-trial-widget.png`} alt="" className="h-auto max-w-full" />
				</div>
				<div className="b-widget__text mb-3 text-center text-sm text-kdark dark:text-white">
					{t('choose_right_plan')}
				</div>
				<NewButton
					fullWidth
					color="primary"
					shape="square"
					size="bigger"
					onClick={navigateToCheckout}
				>
					{t('start_with_plan')}
				</NewButton>
			</div>
		</div>
	);
}
