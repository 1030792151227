import { default as dayjs } from 'dayjs';

const time = {
	toYear: (timestamp: number): any => {
		return dayjs.unix(timestamp).year();
	},
	current: (format = 'DD.MM.YYYY'): any => {
		return dayjs().format(format);
	},
	to: (timestamp: number, format = 'DD.MM.YYYY'): any => {
		return dayjs.unix(timestamp).format(format);
	},
	// toChat: (timestamp: number): any => {
	// 	return dayjs(timestamp, 'X').calendar(null, {
	// 		sameDay: 'HH:mm',
	// 		lastDay: '[Вчера], HH:mm',
	// 		sameElse: 'DD.MM.YYYY'
	// 	});
	// },

	getDay: (day: any, activeDate: any): any => {
		const currDay = new Date().getDay();

		const dd = dayjs.unix(activeDate).format('YYYYMMDD');
		const ndd = dayjs(dd).startOf('day').add(6, 'hours');

		const ddStart = Number(dayjs(ndd).format('X'));
		const ddEnd = ddStart + 86399;

		return {
			start: ddStart - (currDay - day) * 86400,
			end: ddEnd - (currDay - day) * 86400
		};
	},
	toLastTime: (time: number | string, withDay = false, isUnix = true) => {
		const withDayFormat = 'ddd, MMM D, h:mm A';
		const withDayFormatYY = 'ddd, MMM D YY, h:mm A';

		const dayjsTime = isUnix && typeof time === 'number' ? dayjs.unix(time) : dayjs(time);

		const dateFormat = dayjsTime.format(withDay ? withDayFormat : 'MMM D');
		const withYearDateFormat = dayjsTime.format(withDay ? withDayFormatYY : 'MMM D, YY');
		const year = dayjsTime.format('YY');
		const current_year = new Date().getFullYear();
		if (year.toString() === current_year.toString()) {
			return dateFormat;
		}
		return withYearDateFormat;
	}
};

export default time;
