import { IMessage, ScrollToMsg } from 'shared/interfaces';
import { useMessageFields } from 'shared/hooks/chat-hooks/useMessageFields';
import React, { memo, useCallback, useState } from 'react';
import { RepliedMediaMsgWrapper } from './RepliedMediaMsgWrapper';
import { useChatInputContext } from 'shared/contexts/ChatInputContext';
import { LoaderWithProgress } from 'shared/components/Loader';
import { useChatContext } from 'shared/contexts/ChatContext';
import { formSuccess } from 'modules/entity/actions';
import { useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { WhiteModal } from '../../Elements';
import { PdfViewer } from './PdfViewer';
import { useQueryParams, useWhiteModal } from '../../../hooks';
import { useCachedReactedUsers } from '../../../hooks/chat-hooks/useCachedReactedUsers';
import { EmojiCountInfo } from './EmojiCountInfo';
import clsx from 'clsx';
import { DeletedMsg } from './DeletedMsg';
import { useTranslation } from 'react-i18next';
import { useTranslationHelpers } from 'shared/hooks/useTranslationHelpers';

type Props = {
	message: IMessage;
	setScrollToMsgId: ScrollToMsg;
};

function areEqual(prev: any, next: any) {
	return (
		prev.message?.is_deleted === next.message?.is_deleted &&
		prev.message.file?.url === next.message.file?.url &&
		prev.message.custom_uuid === next.message.custom_uuid &&
		isEqual(prev.message?.replied_to, next.message?.replied_to) &&
		prev.message?.id === next.message?.id &&
		isEqual(prev.message.reacted_users, next.message.reacted_users) &&
		prev.message?.message_id === next.message?.message_id
	);
}

export const PDFMsg = memo(function ImageMsg({ message, setScrollToMsgId }: Props) {
	const {
		is_deleted,
		file,
		replied_to,
		isOwnMsg,
		custom_uuid,
		messageId,
		reacted_users,
		file_type
	} = useMessageFields(message);
	const [toggler, setToggler] = useState(false);
	const { uploadMeta, setCancelSending, editingMessage } = useChatInputContext();
	const { entityType, entityName } = useChatContext();
	const { changeUrlParams, removeOneQueryParam } = useQueryParams();
	const imageFile = file?.url as string;
	const progress = Number(uploadMeta[custom_uuid]);
	const dispatch = useDispatch();
	const {
		open: isEmojiCountOpen,
		modalHandler: isEmojiCountOpenHandler,
		WhiteModal: EmojiWhiteModal
	} = useWhiteModal();

	const { cachedReactions } = useCachedReactedUsers({
		reacted_users,
		file_type,
		isEmojiCountOpenHandler
	});
	const { t } = useTranslation();
	const { isRTL } = useTranslationHelpers();

	const pdfScreenshot = file?.thumbnail || undefined;
	const pdfWidth = file?.width || 240;
	const pdfHeight = file?.height || 320;

	const cancelClickHandler = useCallback(
		(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			e.stopPropagation();
			setCancelSending(custom_uuid);

			dispatch(
				formSuccess({
					entity: `${entityType}Messages`,
					name: entityName,
					id: custom_uuid,
					deleteData: true,
					appendData: false,
					updateData: false,
					prependData: false
				})
			);
		},
		[entityType, entityType, custom_uuid]
	);

	const closePdfViewerPopup = () => {
		removeOneQueryParam('isMediaActive');
		setToggler(false);
	};

	const openPdfPopupHandler = () => {
		changeUrlParams({ isMediaActive: true });
		setToggler(!toggler);
	};

	if (is_deleted) {
		return <DeletedMsg isOwnMsg={isOwnMsg} type="pdf" />;
	}

	return (
		<div
			className={clsx(
				'overflow-hidden break-words text-sm text-kdark shadow-text-msg dark:text-white',
				{
					'rounded-b-xl rounded-tr-xl bg-white dark:bg-white/20': !isOwnMsg,
					'rounded-t-xl rounded-bl-xl bg-primary-200': isOwnMsg
				}
			)}
		>
			<RepliedMediaMsgWrapper
				replied_to={replied_to}
				isOwnMsg={isOwnMsg}
				setScrollToMsgId={setScrollToMsgId}
				style={{ width: `${pdfWidth}px` }}
			>
				<div
					className={'toggler-msg relative max-h-[320px]'}
					onClick={openPdfPopupHandler}
					style={{ height: `${pdfHeight}px` }}
				>
					<img
						src={pdfScreenshot}
						alt={file?.name}
						className={'max-h-full w-full object-cover pt-3'}
					/>
					{custom_uuid in uploadMeta && (!messageId || editingMessage) && (
						<div
							className={
								'absolute top-auto left-24 right-auto bottom-16 z-10 h-12 w-12 cursor-pointer rounded-full bg-white/70'
							}
							onClick={cancelClickHandler}
						>
							{progress ? (
								<>
									<div className={'absolute top-1 left-1'}>
										<LoaderWithProgress
											value={Number(uploadMeta[custom_uuid])}
											variant={'indeterminate'}
										/>
									</div>
									<span
										className={clsx(
											'absolute top-12 mt-1 rounded-xl bg-white/70 p-2 text-sm leading-none underline hover:no-underline dark:bg-kdark-dark333',
											{
												'-left-2': !isRTL,
												'left-1': isRTL
											}
										)}
									>
										{t('cancel')}
									</span>
								</>
							) : null}
						</div>
					)}
				</div>
				<h6 className="p-2 text-xs text-kgrey-menuText">{file?.name}</h6>
				<div
					className={clsx({
						'px-2': reacted_users?.length
					})}
				>
					{reacted_users?.length ? cachedReactions : null}
				</div>
				<EmojiWhiteModal handleModalOpen={isEmojiCountOpenHandler} open={isEmojiCountOpen}>
					<EmojiCountInfo messageId={messageId} reactedUsers={reacted_users} />
				</EmojiWhiteModal>
				{/* Pdf Viewer Popup */}
				{toggler ? (
					<WhiteModal
						handleModalOpen={closePdfViewerPopup}
						open={!!toggler}
						scrollable={false}
						// noMaxHeight
					>
						<PdfViewer file={imageFile} fileName={file?.name} onClose={closePdfViewerPopup} />
					</WhiteModal>
				) : null}
			</RepliedMediaMsgWrapper>
		</div>
	);
}, areEqual);
