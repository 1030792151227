import React from 'react';
import clsx from 'clsx';
import { BiLike } from 'react-icons/bi';

type LikeButtonTextProps = {
	toggleEmojiPicker: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	isOwnMsg: boolean;
};

export const LikeButtonText = React.memo(function LikeButton({
	toggleEmojiPicker,
	isOwnMsg
}: LikeButtonTextProps) {
	return (
		<div
			className={clsx(
				'message-like-button for-text cursor-pointer  text-2xl text-primary opacity-0 transition transition hover:text-primary-dark',
				{ 'order-first': isOwnMsg, 'ml-2': !isOwnMsg, 'mr-2': isOwnMsg }
			)}
			onClick={toggleEmojiPicker}
		>
			<BiLike />
		</div>
	);
});
