import { useAppState } from 'shared/state';
import { useCallback } from 'react';

export function useChatMentionUpdate() {
	const { chatsMentionList, setChatsMentionObject, companyId } = useAppState();

	type IncrementType = {
		entityId: number;
		messageId: number;
		currentMsgCompanyId: number;
	};
	const incrementChatMentions = useCallback(
		({ entityId, messageId, currentMsgCompanyId }: IncrementType) => {
			if (companyId !== currentMsgCompanyId) {
				return;
			}

			const mentionObject = {
				...chatsMentionList,
				[entityId]: chatsMentionList[entityId]
					? [messageId, ...chatsMentionList[entityId]]
					: [messageId]
			};

			setChatsMentionObject(mentionObject);
		},
		[companyId, setChatsMentionObject, chatsMentionList]
	);
	type DecrementArgsType = {
		entityId: number;
		messageIds?: number[];
		currentMsgCompanyId?: number;
		removeAll?: boolean;
	};

	const decrementChatMentions = useCallback(
		({ entityId, messageIds = [], currentMsgCompanyId, removeAll = false }: DecrementArgsType) => {
			if (companyId !== currentMsgCompanyId || !chatsMentionList?.[entityId]?.length) {
				return;
			}

			const mentionIds = chatsMentionList?.[entityId]?.slice();
			const filteredIds = mentionIds.filter((id) => !messageIds.includes(id));
			const mentionObject = {
				...chatsMentionList,
				[entityId]: removeAll ? [] : filteredIds
			};

			setChatsMentionObject(mentionObject);
		},
		[companyId, setChatsMentionObject, chatsMentionList]
	);

	return {
		incrementChatMentions,
		decrementChatMentions
	};
}
