import { useEffect, useMemo, useState } from 'react';
import { useAxios } from './useAxios';
import { LanguageType } from 'shared/state/reducer';
import { setDayJsLocale } from 'shared/services/utils/setLibLocales';
import { storage } from 'shared/services';
import { useTranslation } from 'react-i18next';
import { useAppState } from 'shared/state';

type OptionType = {
	value: number;
	label: string;
	icon: { thumbnail: string };
	shortLabel: string;
	is_default: boolean;
};

type TLanguageSelect = {
	isLanguageLoading: boolean;
	changedLanguage: LanguageType | null;
	LANGUAGE_OPTIONS: LanguageType[];
	handleLanguageChange: () => (option: OptionType) => void;
	defaultLanguage: LanguageType;
};

type TProps = {
	isRegistering: boolean;
	customLanguageSelect?: {
		changeLanguage: (language: string) => void;
		language: string;
	};
};

export function useLanguageSelect({
	customLanguageSelect,
	isRegistering
}: TProps): TLanguageSelect {
	const [changedLanguage, setChangedLanguage] = useState<LanguageType | null>(null);
	const { setLanguage } = useAppState();
	const { i18n } = useTranslation();
	const selectedLanguage = storage.get('i18nextLng');

	const { fetchData: changeAccountLanguage } = useAxios({
		url: '/my/profile/',
		method: 'patch',
		dynamicValues: true,
		cb: {
			success: (data, args) => {
				const { accountLanguageChangeSuccessHandler } = args?.[0];

				accountLanguageChangeSuccessHandler?.();
			},
			error: (error) => {}
		}
	});

	const {
		response: languageOptionsData,
		fetchData: fetchLanguageData,
		loading: isLanguageLoading
	} = useAxios({
		url: '/system-languages/'
	});

	const LANGUAGE_OPTIONS = useMemo(
		() =>
			(languageOptionsData as any)?.results?.map((language: LanguageType) => ({
				value: language?.id,
				label: language?.name,
				icon: language?.icon,
				shortLabel: language?.short_name,
				is_default: language?.is_default
			})),
		[languageOptionsData]
	);

	useEffect(() => {
		(async () => {
			await fetchLanguageData();
		})();
	}, []);

	useEffect(() => {
		if (customLanguageSelect) {
			setChangedLanguage(
				LANGUAGE_OPTIONS?.find(
					(option: OptionType) => option?.shortLabel === customLanguageSelect?.language
				)
			);
		} else {
			setChangedLanguage(
				LANGUAGE_OPTIONS?.find((option: OptionType) => option?.shortLabel === selectedLanguage)
			);
		}
	}, [customLanguageSelect, selectedLanguage, LANGUAGE_OPTIONS]);

	const handleLanguageChange = () => (option: OptionType) => {
		if (option) {
			if (customLanguageSelect) {
				customLanguageSelect?.changeLanguage?.(option?.shortLabel);
				return;
			}

			storage.set('selected_language', JSON.stringify(option));

			if (isRegistering) {
				setLanguage(option as any);
				i18n.changeLanguage(option?.shortLabel);
				setDayJsLocale(option?.shortLabel as any);
				//setYupLocale(option?.shortLabel as any);

				return;
			}

			changeAccountLanguage({
				values: {
					language: option?.value
				},
				accountLanguageChangeSuccessHandler: () => {
					setLanguage(option as any);
					i18n.changeLanguage(option?.shortLabel);
					setDayJsLocale(option?.shortLabel as any);
					//setYupLocale(option?.shortLabel as any);
				}
			});
		}
	};

	const defaultLanguage = LANGUAGE_OPTIONS?.find((option: OptionType) => option.is_default);

	return {
		isLanguageLoading,
		defaultLanguage,
		LANGUAGE_OPTIONS,
		changedLanguage,
		handleLanguageChange
	};
}
