import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CircularProgressProps } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useAppState } from '../../state';
type LoaderProps = {
	full?: boolean;
	// color?: 'primary' | 'secondary' | 'inherit';
	tiled?: boolean;
	withOverlay?: boolean;
} & CircularProgressProps;

const useStyles = makeStyles((theme) => ({
	colorPrimary: {
		color: theme.palette.primary.main
	}
}));

function Loader({ full /*, color = 'primary',*/, tiled, withOverlay, ...rest }: LoaderProps) {
	const classes = useStyles();
	const { isDarkMode } = useAppState();
	return (
		<>
			{tiled ? (
				<div
					className={clsx(
						'absolute inset-0 flex items-center justify-center text-primary',
						withOverlay && `${isDarkMode ? 'bg-dark' : 'bg-white'}  bg-opacity-70`
					)}
				>
					<CircularProgress
						// color={color}
						{...rest}
						// classes={{ colorPrimary: classes.colorPrimary }}
					/>
				</div>
			) : (
				<div
					className={clsx(
						full && 'flex h-screen w-screen items-center justify-center  text-primary'
					)}
				>
					<CircularProgress
						{...rest}
						// color={color}
						// classes={{ colorPrimary: classes.colorPrimary }}
					/>
				</div>
			)}
		</>
	);
}

export default Loader;
