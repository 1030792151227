import React from 'react';

export function IconDownloadPDF({ className = '' }: { className?: string }) {
	return (
		<svg
			id="Capa_1"
			enableBackground="new 0 0 512 512"
			viewBox="0 0 512 512"
			xmlns="http://www.w3.org/2000/svg"
			className={className}>
			<g>
				<g>
					<g>
						<path fill="currentColor" d="m159.867 8.78-86.66 86.65h86.66z" />
						<path
							fill="currentColor"
							d="m190.409 181.88v133.3c0 33.84 27.53 61.37 61.37 61.37h173.1v90.45c0 8.28-6.72 15-15 15h-256.96l35.53-43.82c10.28-12.68 12.39-30.4 5.36-45.13-6.2-12.99-18.67-21.93-32.72-23.85v-57.51c0-23.48-19.1-42.57-42.57-42.57h-54.1v-143.69h110.44c8.28 0 15-6.72 15-15v-110.43h220.02c8.28 0 15 6.72 15 15v105.51h-173.1c-33.84 0-61.37 27.53-61.37 61.37z"
						/>
					</g>
				</g>
				<path
					fill="currentColor"
					d="m300.71 231.11c0 6.81-5.97 12.57-13.02 12.57-2.83 0-7.71.02-12.18.05-.06-13.12-.02-8.01-.07-25.2h12.25c7.05 0 13.02 5.76 13.02 12.58z"
				/>
				<path
					fill="currentColor"
					d="m368.11 218.53h-12.64c.02 9.83.04 24.17.04 29.91 0 6.83.04 20.57.07 30.04 4.75-.03 10.03-.07 13.02-.12 15.07-.27 21.82-15.17 21.82-29.83 0-14.5-5.86-30-22.31-30zm0 0h-12.64c.02 9.83.04 24.17.04 29.91 0 6.83.04 20.57.07 30.04 4.75-.03 10.03-.07 13.02-.12 15.07-.27 21.82-15.17 21.82-29.83 0-14.5-5.86-30-22.31-30zm112.51-68.02h-228.84c-17.3 0-31.37 14.07-31.37 31.37v133.3c0 17.3 14.07 31.37 31.37 31.37h228.84c17.3 0 31.38-14.07 31.38-31.37v-133.3c0-17.3-14.08-31.37-31.38-31.37zm-192.93 113.17c-2.84 0-7.78.02-12.28.05v24.8c0 5.52-4.47 10-10 10-5.52 0-10-4.48-10-10v-80c0-5.44 4.42-10 10-10h22.28c18.2 0 33.02 14.61 33.02 32.58 0 17.96-14.82 32.57-33.02 32.57zm81.26 34.67c-6.46.11-22.61.18-23.3.18-5.32 0-10.2-4.02-10.2-11.3v-78.62c0-.03 0-.06 0-.06-.01-5.53 4.47-10.02 10-10.02h22.66c25.3 0 42.31 20.09 42.31 50 0 28.45-17.45 49.4-41.47 49.82zm95.47-60.58c5.52 0 10 4.48 10 10s-4.48 10-10 10h-19.85v30.76c0 5.52-4.47 10-10 10-5.52 0-10-4.48-10-10v-80c0-5.52 4.48-10 10-10h32.43c5.52 0 10 4.48 10 10s-4.48 10-10 10h-22.43v19.24zm-96.31-19.24h-12.64c.02 9.83.04 24.17.04 29.91 0 6.83.04 20.57.07 30.04 4.75-.03 10.03-.07 13.02-.12 15.07-.27 21.82-15.17 21.82-29.83 0-14.5-5.86-30-22.31-30z"
				/>
				<g>
					<path
						fill="currentColor"
						d="m165.147 419.288-71.405 88.062c-2.388 2.941-5.974 4.65-9.761 4.65-3.796 0-7.382-1.709-9.77-4.65l-71.405-88.062c-3.05-3.762-3.67-8.949-1.584-13.322 2.086-4.374 6.502-7.156 11.345-7.156h24.299v-87.123c0-6.938 5.622-12.568 12.568-12.568h69.084c6.946 0 12.568 5.631 12.568 12.568v87.123h24.299c4.843 0 9.259 2.782 11.345 7.156 2.087 4.373 1.467 9.56-1.583 13.322z"
					/>
				</g>
			</g>
		</svg>
	);
}
