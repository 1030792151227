import React from 'react';
import { RoundCheck } from '../RoundCheck';
type Props = {
	text: string;
};
export function PaymentFeature({ text }: Props) {
	return (
		<div className="bill-feature flex-center-y mb-4 last:mb-0">
			<RoundCheck />
			<span className="ml-2.5 text-xs text-kgrey-2d2d2d dark:text-white">{text}</span>
		</div>
	);
}
