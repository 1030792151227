import React from 'react';

export function IconAddFilter() {
	return (
		<svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path opacity=".01" fill="#00A09D" d="M0 0h20v20H0z" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.667 10a8.333 8.333 0 1 1 16.667 0 8.333 8.333 0 0 1-16.667 0Zm1.667 0a6.667 6.667 0 1 0 13.333 0 6.667 6.667 0 0 0-13.333 0Z"
				fill="#00A09D"
			/>
			<path
				d="M12.5 9.167h-1.666V7.5a.833.833 0 0 0-1.667 0v1.667H7.5a.833.833 0 1 0 0 1.666h1.667V12.5a.833.833 0 1 0 1.667 0v-1.667H12.5a.833.833 0 1 0 0-1.666Z"
				fill="#00A09D"
			/>
		</svg>
	);
}
