import React, { useEffect } from 'react';
import { IconWrapper } from '../../IconWrapper';
import { IconRecentDropdown } from '../../../icons';
import { RecentCasesDropdown } from './RecentCasesDropdown';
import { useComponentVisible, useQueryParams } from '../../../hooks';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useTranslationHelpers } from 'shared/hooks/useTranslationHelpers';

export function RecentCasesBtn() {
	const {
		ref: recentDropdownRef,
		isComponentVisible,
		setIsComponentVisible
	} = useComponentVisible(false);
	const { query } = useQueryParams();
	const { t } = useTranslation();
	const { isRTL } = useTranslationHelpers();

	useEffect(() => {
		if (query?.case) {
			setIsComponentVisible(false);
		}
	}, [query]);

	return (
		<div
			className={clsx(' group relative flex cursor-pointer items-center', {
				'ml-4': !isMobile,
				'ml-2': isMobile
			})}
			onClick={() => setIsComponentVisible(true)}
		>
			<IconWrapper>
				<IconRecentDropdown className="text-kgrey transition group-hover:text-primary" />
			</IconWrapper>
			{!isMobile && (
				<div className="mx-2 hidden text-base text-kdark-text transition group-hover:text-primary dark:text-white xl:block">
					{t('recent_case')}
				</div>
			)}
			{isComponentVisible ? (
				<div
					ref={recentDropdownRef}
					className={clsx(
						'' +
							'absolute w-96 shadow-recent-panel-2' +
							'top-full rounded-xl bg-white' +
							'  z-20 translate-y-64 p-6 transition dark:bg-kdark-dark333',
						{ 'right-0 ': !isRTL, 'left-0': isRTL }
					)}
				>
					<RecentCasesDropdown />
				</div>
			) : null}
		</div>
	);
}
