import React, { useEffect, useState } from 'react';
import { Case, CaseDataField, CaseDep } from 'shared/interfaces';
import isEmpty from 'lodash/isEmpty';
import config from 'config';
import { useDraftMessage, useQueryParams, useUpdateEntities, useWhiteModal } from 'shared/hooks';
import { useAppState } from 'shared/state';
import { ChatHeaderLayout } from 'shared/components/Chat/components/ChatHeaderLayout';
import { useCaseMenu } from '../hooks/useCaseMenu';
import MenuItem from '@material-ui/core/MenuItem';
import { Button, Menu, Tooltip } from 'shared/components/Elements';
import { useChatContext } from 'shared/contexts/ChatContext';
import { useChatSocket } from 'shared/contexts/ChatSocketContext';
import { ConfirmModal, ErrorBoundary } from 'shared/components';
import { IconDownloadPDF, IconPin } from '../../../shared/icons';
import { IoClose } from 'react-icons/io5';
import { AiOutlineMore, AiFillInfoCircle, AiFillQuestionCircle } from 'react-icons/ai';
import { useCaseDetailsToggle } from '../hooks';
import { CaseDetails } from '../components/case-details';
import { DataFieldsPopup } from '../components/case-details/components';
import { isMobile } from 'react-device-detect';
import { CaseAssigneeUsersPopover } from '../components/CaseAssigneeUsersPopover';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { PriorityStrings } from 'shared/typings/priority.types';
import { PriorityChangeModal } from '../components/PriorityChangeModal';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useTranslationHelpers } from 'shared/hooks/useTranslationHelpers';
import { useChatInputContext } from 'shared/contexts';

type Props = {
	caseData: Case;
	isFetched: boolean;
};

function getPriorityClass(priority: PriorityStrings) {
	switch (priority) {
		case 'CRITICAL': {
			return 'border-priority-critical';
		}
		case 'HIGH': {
			return 'border-priority-high';
		}
		case 'LOW': {
			return 'border-priority-low';
		}
		case 'NORMAL': {
			return 'border-priority-normal';
		}
		default: {
			return 'border-none';
		}
	}
}

export function CaseChatHeaderInner({ caseData, isFetched }: Props) {
	const { t } = useTranslation();
	const { isRTL } = useTranslationHelpers();
	const { removeMultipleParams, query } = useQueryParams();
	const { toggleChatPanel, setActiveDraft, activeDraft } = useAppState();
	const { entityId, setScrollToMessageHandler, lastReadMessageId } = useChatContext();
	const { message } = useChatInputContext();
	const { closeSocket } = useChatSocket();
	const { saveDraft, getLS } = useDraftMessage();
	const { userData, company, token } = useAuth();
	const { updateEntities } = useUpdateEntities();
	const [confirm, setConfirm] = useState(false);

	//CaseChat component is used Kennekt Support page too. That's why we have
	//some different UI
	const isSupportCase = query.isSupportCase;

	const statusId = caseData?.status?.id;
	const caseId = caseData?.case_ ? (caseData as any)?.entity_id : caseData?.id;
	const assigneeIds = caseData?.assignee_ids;

	const [dataFieldsPopup, showDataFieldsPopup] = useState<CaseDataField | null>(null);
	const { toggleCaseDetailsOpen, caseDetailsOpen, closeCaseDetailModal } = useCaseDetailsToggle();

	const pinTime = caseData?.pin_time;
	const draftObject = getLS();

	const {
		open,
		handleClick,
		anchorEl,
		handleMenuItemClick,
		handleCloseMenu,
		anchorElAssignee,
		handleClickAssignee,
		handleCloseAssignee,
		openAssignee
	} = useCaseMenu(caseData?.pin_time, caseData?.id, true, statusId);

	const promptCloseChat = () => {
		setConfirm((prev) => !prev);
	};
	const closeCaseChat = () => {
		removeMultipleParams([
			'status',
			'case',
			'searchedMessageId',
			'hasUnreadMessage',
			'lastReadMessage',
			'unread',
			'isSupportCase',
			'isScheduledChat',
			'activeMedia'
		]);
		toggleChatPanel();
		closeSocket?.();
		if (entityId in draftObject) saveDraft('user', message);

		try {
			updateEntities({
				entity: 'cases',
				entityId: caseId,
				updatingData: {
					last_read_message: lastReadMessageId
				}
			});
		} catch (e) {}
	};

	const mainText = getDepSubName(caseData?.department, caseData?.sub_department);
	const secondaryText = getCaseInfo(caseData);
	let data = null;

	if (isFetched && !isEmpty(caseData)) {
		data = {
			avatarSrc: caseData?.sub_department
				? caseData?.sub_department?.icon?.thumbnail
				: caseData?.department?.icon?.thumbnail,
			avatarLetter: caseData?.department?.name?.[0],
			avatarAlt: caseData?.department?.name
		};
	}

	useEffect(() => {
		if ('draft_message' in caseData && caseData?.draft_message && !activeDraft) {
			setActiveDraft(caseData?.draft_message);
		}
	}, [caseData]);

	const isCurrentUserAssigned = assigneeIds?.includes(userData.id);

	const toggleCaseDetails = () => toggleCaseDetailsOpen(entityId);
	const { open: openPriority, modalHandler: setOpenPriority, WhiteModal } = useWhiteModal();

	const handleDownloadHistory = () => {
		const utcOffset = dayjs().utcOffset() / 60;
		const url = `${config.API_ROOT}/case_history/${entityId}/pdf/?tz=${utcOffset}`;
		const headers = new Headers();
		headers.append('Authorization', `Token ${token}`);

		fetch(url, { headers })
			.then((response) => response.blob())
			.then((blob) => {
				// Create  blob  URL
				const blobUrl = window.URL.createObjectURL(blob);

				// Create a temporary anchor el
				const anchor = document.createElement('a');
				anchor.href = blobUrl;
				anchor.download = `case_#${caseData?.case_number}_history.pdf`; // Set the desired filename and extension
				anchor.style.display = 'none';

				// Append the anchor to the DOM and click it to trigger download
				document.body.appendChild(anchor);
				anchor.click();

				// Clean up
				document.body.removeChild(anchor);
				window.URL.revokeObjectURL(blobUrl);
			})
			.catch((error) => {
				console.error('Error downloading file:', error);
			});
	};

	return (
		<ChatHeaderLayout
			isFetched
			toggleCaseDetails={toggleCaseDetails}
			HeaderActions={
				<>
					{!isMobile && caseData?.data_field?.length ? (
						<>
							<Tooltip title={t('show_data_fields')} placement="top">
								<button className="!mr-4" onClick={() => showDataFieldsPopup(caseData?.data_field)}>
									{/* <span className="hidden md:inline-block  lg:hidden  xl:inline-block">
										{t('show_data_fields')}
									</span> */}
									<AiFillQuestionCircle className="inline-block text-[#A5A7B2]" size={'2em'} />
								</button>
							</Tooltip>
							<WhiteModal
								handleModalOpen={() => showDataFieldsPopup(null)}
								open={Boolean(dataFieldsPopup)}
								minWidth={362}
								maxWidth={550}
								scrollable
							>
								<DataFieldsPopup dataFields={dataFieldsPopup} />
							</WhiteModal>
						</>
					) : null}

					<Tooltip title={t('download_message_history')} placement="top">
						<button onClick={handleDownloadHistory} className="mr-1">
							<IconDownloadPDF className="h-6 w-6 text-[#A5A7B2] transition hover:text-primary" />
						</button>
					</Tooltip>
					<CaseAssigneeUsersPopover
						caseId={caseId}
						anchorElAssignee={anchorElAssignee}
						assigneeIds={assigneeIds}
						handleClickAssignee={handleClickAssignee}
						handleCloseAssignee={handleCloseAssignee}
						openAssignee={openAssignee}
						isCurrentUserAssigned={isCurrentUserAssigned}
						className="mr-2"
					/>

					{pinTime ? (
						<div className={'mr-4'}>
							<IconPin className={'text-kgrey-menuText'} />
						</div>
					) : null}
					{!isSupportCase ? (
						<div
							className="more-btn w-4 cursor-pointer text-right text-kdark-dark333
						 transition hover:text-primary dark:text-white dark:hover:text-primary"
							onClick={handleClick}
						>
							<AiOutlineMore className={'inline text-3xl'} />
						</div>
					) : null}

					<Menu
						id="long-menu"
						anchorEl={anchorEl}
						keepMounted
						open={open}
						onClose={handleCloseMenu}
					>
						<MenuItem
							onClick={() => {
								handleCloseMenu();
								toggleCaseDetailsOpen(entityId);
							}}
						>
							{t('case_details')}
						</MenuItem>
						<MenuItem onClick={setOpenPriority}>{t('change_priority')}</MenuItem>
						<MenuItem
							onClick={() => {
								handleCloseMenu();
								handleMenuItemClick(caseData?.pin_time ? 'unpin' : 'pin');
							}}
						>
							{caseData?.pin_time ? t('unpin_case') : t('pin_case')}
						</MenuItem>
						<MenuItem
							onClick={() => {
								handleCloseMenu();
								handleMenuItemClick('manager');
							}}
						>
							{t('request_update')}
						</MenuItem>
						<WhiteModal handleModalOpen={setOpenPriority} open={openPriority}>
							<PriorityChangeModal onSuccess={setOpenPriority} />
						</WhiteModal>
					</Menu>
					<button
						className={clsx(
							'flex-center-y border-border-solid px-4 py-2 text-kred-pink transition hover:opacity-80',
							{ 'border-l lg:ml-4': !isRTL, 'border-r lg:mr-4': isRTL }
						)}
						type={'button'}
						onClick={isSupportCase ? promptCloseChat : closeCaseChat}
					>
						<div className="icon ">
							<IoClose />
						</div>
					</button>
					<WhiteModal
						handleModalOpen={closeCaseDetailModal}
						open={Boolean(caseDetailsOpen)}
						minWidth={362}
						maxWidth={550}
						scrollable
					>
						<CaseDetails caseId={caseDetailsOpen} closeCaseDetailModal={closeCaseDetailModal} />
					</WhiteModal>
					<ConfirmModal
						featureHandler={closeCaseChat}
						isOpen={confirm}
						toggleHandler={promptCloseChat}
						title={t('exit_message_if_from_feedback')}
						maxWidth={600}
						titleClassNames="!p-0 !mb-4 whitespace-pre-line"
					/>
				</>
			}
			mainText={mainText}
			secondaryText={secondaryText}
			data={data}
			pinnedMessage={{ title: caseData?.title, id: caseData?.pinned_message?.id }}
			casePriority={getPriorityClass(caseData?.priority as PriorityStrings)}
			scrollToHandler={setScrollToMessageHandler}
		/>
	);
}

export function getDepSubName(dep: CaseDep, subdep: CaseDep) {
	return dep ? `${dep?.name} ${subdep?.name ? '/ ' + subdep?.name : ''}` : '';
}

export function getCaseInfo(caseData: Case) {
	return `Case #${caseData?.case_number} | ${caseData?.status?.title?.toUpperCase()}`;
}

export function CaseChatHeader(props: Props) {
	return (
		<ErrorBoundary>
			<CaseChatHeaderInner {...props} />
		</ErrorBoundary>
	);
}
