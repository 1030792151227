import React from 'react';

import { DatePicker, TimePicker } from '@material-ui/pickers';
import { Button, MenuItem, TextField } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { useTranslation } from 'react-i18next';
import { convertToLocalizationKey } from 'shared/services/utils/convertToLocalizationKey';

type Props = {
	selectedTime: any;
	handleInput: any;
	repeat_every: any;
	repeat_type: any;
	repeat_types: any;
	weekDays: any;
	recurrState: any;
	handleWeekdayClick: any;
	month_repeat_type: any;
	monthItems: any;
	selectedDate: any;
	handleDateChange: any;
	occurrences: any;
	onCloseRecurrDialog: any;
	handleRecurrSubmit: any;
	end_type: any;
	setSelectedTime: any;
	editMode: any;
	setFormState: any;
};

export function RecurrOptionsElements({
	selectedTime,
	handleInput,
	repeat_every,
	repeat_type,
	repeat_types,
	weekDays,
	recurrState,
	handleWeekdayClick,
	month_repeat_type,
	monthItems,
	selectedDate,
	handleDateChange,
	occurrences,
	onCloseRecurrDialog,
	handleRecurrSubmit,
	end_type,
	setSelectedTime = () => {},
	editMode = false,
	setFormState = () => {}
}: Props) {
	const { t, i18n } = useTranslation();
	return (
		<div className="case-form recurr-options">
			{editMode && (
				<div className="case-form__input-row mb-5">
					<div className="text-dm mb-1.5 mb-2.5 mr-2.5  text-kgrey">{t('time')}</div>
					<div className="case-form__input-wrapper">
						<TimePicker
							variant="inline"
							value={selectedTime}
							onChange={setSelectedTime}
							inputVariant="outlined"
							size="small"
							autoOk
							// ampm={false}
						/>
					</div>
				</div>
			)}

			<div className="case-form__input-row  mb-5">
				<div className="text-dm mb-1.5 mb-2.5 text-kgrey ">{t('repeat_every')}</div>
				<div className={`case-form__input-wrapper flex items-center`}>
					<div className="recurr-options__rep-input-wr mr-2.5">
						<TextField
							variant="outlined"
							fullWidth
							autoComplete="off"
							name="repeat_every"
							size="small"
							type="number"
							onChange={handleInput}
							value={repeat_every > 0 ? repeat_every : 1}
						/>
					</div>
					<div className="recurr-options__rep-select-wr">
						<TextField
							select
							size="small"
							value={repeat_type}
							name="repeat_type"
							onChange={handleInput}
							variant="outlined"
						>
							{repeat_types.map((option: any) => (
								<MenuItem key={option.code} value={option.code}>
									{repeat_every > 1 && i18n.language === 'EN'
										? `${option.name}s`
										: t(convertToLocalizationKey(option.name))}
								</MenuItem>
							))}
						</TextField>
					</div>
				</div>
			</div>
			{(repeat_type === 'WEEK' || repeat_type === 'MONTH') && (
				<div className="case-form__input-row mb-5">
					{repeat_type === 'WEEK' && (
						<>
							<div className="text-dm mb-1.5 text-kgrey ">{t('repeat_on')}</div>
							<div className="case-form__input-wrapper">
								<div className="recurr-options flex items-center">
									{weekDays.map((day: any) => (
										<div
											className={`recurr-options__day ${recurrState[day.value] ? 'active' : ''}`}
											key={day.value}
											onClick={() => handleWeekdayClick(day)}
										>
											{day.name}
										</div>
									))}
								</div>
							</div>
						</>
					)}
					{repeat_type === 'MONTH' && (
						<div className="case-form__input-row mb-5">
							<div className="case-form__input-wrapper">
								<div className="recurr-options flex items-center">
									<div className="recurr-options__month-wr">
										<TextField
											SelectProps={{
												classes: { root: '!whitespace-normal' }
											}}
											select
											value={month_repeat_type}
											name="month_repeat_type"
											onChange={handleInput}
											size="small"
											variant="outlined"
										>
											{monthItems.map((option: any) => (
												<MenuItem key={option.value} value={option.value}>
													{option.text}
												</MenuItem>
											))}
										</TextField>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			)}

			<div className="case-form__input-row mb-5">
				<div className="text-dm mb-1.5 text-kgrey ">{t('ends')}</div>
				<div className="case-form__input-wrapper">
					<div className="recurr-options">
						<FormControl component="fieldset">
							<RadioGroup
								aria-label="gender"
								name="end_type"
								value={end_type}
								onChange={handleInput}
							>
								<div className="recurr-options__radio mb-2.5">
									<FormControlLabel
										value="NEVER"
										control={<Radio color="primary" />}
										label={t('never')}
									/>
								</div>
								<div className="recurr-options__radio mb-2.5 flex items-center">
									<div className="recurr-options__form-radio">
										<FormControlLabel
											value="DATETIME"
											control={<Radio color="primary" />}
											label={t('date')}
										/>
									</div>
									<div className="recurr-options__form-field">
										<DatePicker
											disableToolbar
											variant="inline"
											value={selectedDate}
											onChange={handleDateChange}
											// format="ddd, MMM DD, YYYY"
											format="MMM DD, YYYY"
											disabled={end_type !== 'DATETIME'}
											inputVariant="outlined"
											size="small"
											autoOk
										/>
									</div>
								</div>
								<div className="recurr-options__radio mb-2.5 flex items-center">
									<div className="recurr-options__form-radio">
										<FormControlLabel
											value="OCCURRENCES"
											control={<Radio color="primary" />}
											label={t('after')}
										/>
									</div>
									<div className="recurr-options__form-field recurr-options__form-field_occur flex items-center dark:bg-transparent">
										<div className="recurr-options__occ-input">
											<TextField
												size="small"
												autoComplete="off"
												name="occurrences"
												type="number"
												onChange={handleInput}
												value={occurrences > 0 ? occurrences : 1}
												// min="0"
											/>
										</div>
										<div className="recurr-options__occ-text">{`${
											occurrences > 1 ? t('occurrences') : t('occurrence')
										}`}</div>
									</div>
								</div>
							</RadioGroup>
						</FormControl>
					</div>
				</div>
			</div>
			<div className="case-form__submit-row flex items-center justify-end">
				<Button
					variant="contained"
					className="!mr-4"
					onClick={() => {
						!editMode && setFormState((prev: any) => ({ ...prev, recurOption: 'DAILY' }));
						onCloseRecurrDialog();
					}}
				>
					{t('cancel')}
				</Button>
				<Button
					className="ml-20"
					variant="contained"
					color="primary"
					// disabled={}
					type="button"
					onClick={handleRecurrSubmit}
				>
					{t('done')}
				</Button>
			</div>
		</div>
	);
}
