import React from 'react';

export function IconSwitchBSort({ className = '' }: { className?: string }) {
	return (
		<svg
			width="19"
			className={className}
			height="21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="19"
				y=".128"
				width="20.308"
				height="19"
				rx="4"
				transform="rotate(90 19 .128)"
				fill="#333"
				fillOpacity=".05"
			/>
			<path
				d="M6.73 11.467c0 .157.058.307.161.419a.537.537 0 0 0 .392.173.537.537 0 0 0 .392-.173.614.614 0 0 0 .163-.42V8.159l.716.766a.536.536 0 0 0 .39.166.537.537 0 0 0 .387-.173.613.613 0 0 0 .162-.414.615.615 0 0 0-.155-.417L7.675 6.31a.537.537 0 0 0-.392-.174.537.537 0 0 0-.391.174L5.229 8.086a.627.627 0 0 0-.007.845.523.523 0 0 0 .79-.007l.717-.766v3.309ZM12.27 9.098a.614.614 0 0 0-.162-.42.537.537 0 0 0-.391-.173.537.537 0 0 0-.392.174.614.614 0 0 0-.162.419v3.308l-.717-.766a.557.557 0 0 0-.18-.132.523.523 0 0 0-.61.125.627.627 0 0 0 .007.845l1.662 1.777a.537.537 0 0 0 .392.173.537.537 0 0 0 .391-.173l1.663-1.777a.615.615 0 0 0 .155-.417.613.613 0 0 0-.162-.413.537.537 0 0 0-.387-.174.536.536 0 0 0-.39.166l-.716.766V9.098Z"
				fill="#A5A7B2"
			/>
		</svg>
	);
}

IconSwitchBSort.defaultProps = {
	className: ''
};
