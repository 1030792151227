import { IAction } from 'shared/interfaces';
import {
	EntitiesActionTypes,
	UpdateEntitiesActionSuccess,
	LoadEntitiesActionSuccess
} from '../actions/entities';
import { mergeWith } from 'lodash';
import isArray from 'lodash/isArray';
// import
type Entity = { [entity: string]: { [entityId: string]: object } };

const initialState: Entity = {};

export default (state = initialState, action: IAction<EntitiesActionTypes>): Entity => {
	switch (action.type) {
		case EntitiesActionTypes.LOAD_ENTITIES_SUCCESS: {
			//replaced merge to mergeWith. They are similar but mergeWith accepts customizer function for flexibility
			return mergeWith(
				{},
				state,
				(action as LoadEntitiesActionSuccess).payload,
				(stateValues, entitiesPayloadValues) => {
					//!!There is problem if there is any array value in fetched data payload,
					//state values stays unchanged even if new changed data comes
					//so here, old state value will be reassigned to fresh data payload value.

					if (isArray(stateValues)) {
						return entitiesPayloadValues;
					}

					//The method works like "merge" if undefined is returned
					return undefined;
				}
			);
		}

		case EntitiesActionTypes.UPDATE_ENTITIES_SUCCESS: {
			const { entity, entityId, data } = (action as UpdateEntitiesActionSuccess).payload;
			return {
				...state,
				[entity]: {
					...state?.[entity],
					[entityId]: {
						...state?.[entity]?.[entityId],
						...data
					}
				}
			};
		}

		default:
			return state;
	}
};
