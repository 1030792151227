import React, { useCallback } from 'react';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
	focusVisible?: string;
}

interface Props extends SwitchProps {
	classes: Styles;
}

export const IOSSwitch = withStyles((theme: Theme) =>
	createStyles({
		root: {
			width: 30,
			height: 18,
			padding: 0,
			margin: theme.spacing(1)
		},
		switchBase: {
			padding: 1,
			top: '1px',
			left: '1px',
			// '&$disabled': {
			// 	backgroundColor: 'yellow',
			// 	color: 'purple',
			// 	'& + $track': {
			// 		backgroundColor: 'brown'
			// 	}
			// },
			'&$checked': {
				transform: 'translateX(13px)',
				color: theme.palette.common.white,
				'& + $track': {
					backgroundColor: '#52d869',
					opacity: 1,
					border: 'none'
				}
			},

			'&$focusVisible $thumb': {
				color: '#52d869',
				border: '2px solid #fff'
			}
		},
		thumb: {
			width: 13,
			height: 13
		},
		track: {
			borderRadius: 26 / 2,
			// border: `1px solid ${theme.palette.grey[400]}`,
			// backgroundColor: theme.palette.grey[50],
			backgroundColor: '#F2F2F2',
			opacity: 1,
			transition: theme.transitions.create(['background-color', 'border'])
		},
		checked: {},
		focusVisible: {},
		disabled: {}
	})
)(({ classes, ...props }: Props) => {
	return (
		<Switch
			focusVisibleClassName={classes.focusVisible}
			disableRipple
			classes={{
				root: classes.root,
				switchBase: classes.switchBase,
				thumb: classes.thumb,
				track: classes.track,
				checked: classes.checked
			}}
			{...props}
		/>
	);
});

type SwitchCompProps = {
	checked: boolean;
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	name: string;
	label?: string;
	disabled?: boolean;
	classNames?: string;
};

const useStyles = makeStyles(() =>
	createStyles({
		formControlRoot: {
			margin: 0
		}
	})
);

// https://stackoverflow.com/questions/63249267/how-can-i-override-the-style-of-the-material-ui-switch-component-when-checked

export function SwitchComp(props: SwitchCompProps) {
	const classes = useStyles();
	const { handleChange, checked, name, label, disabled, classNames = '' } = props;
	const Component = useCallback(
		() => (
			<IOSSwitch
				checked={checked}
				className={classNames}
				onChange={handleChange}
				name={name}
				disabled={disabled}
			/>
		),
		[checked]
	);
	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{label ? (
				<FormControlLabel
					classes={{ root: classes.formControlRoot }}
					control={<Component />}
					label={label}
				/>
			) : (
				<Component />
			)}
		</>
	);
}

SwitchComp.defaultProps = {
	label: '',
	disabled: false
};
