import React, { useEffect, useRef, useState } from 'react';
import { Field } from 'formik';
import AsyncSelectJs from 'shared/components/Fields/AsyncSelectJs';
import { KntTwilioStyle } from 'shared/services';
import { useAppState } from 'shared/state';
import config from 'config';
import { SupRequired } from '../../../../shared/components';
import { useTranslation } from 'react-i18next';
import { useTranslationHelpers } from 'shared/hooks/useTranslationHelpers';
import clsx from 'clsx';

type Props = {
	state: any;
	setState: any;
};

export function CreateCaseStatus({ state, setState }: Props) {
	const { caseType, companyId } = useAppState();
	const { t } = useTranslation();
	const { isRTL } = useTranslationHelpers();
	const [loading, setLoading] = useState(false);
	const caseTypeRef = useRef(caseType);

	//These 2 useEffect is necessary for unmount and remount departments AsyncSelect when we change case type
	//If we do not remount this components AsyncSelect component does not refetch new departments due to isCached=true prop
	//If we do not set isCached to true, navigating with tabs does work properly/

	useEffect(() => {
		if (caseType !== caseTypeRef.current) {
			setLoading(true);
			caseTypeRef.current = caseType;
		}
	}, [caseType]);

	useEffect(() => {
		if (loading) {
			setLoading(false);
		}
	}, [loading]);

	return (
		<div className="react-select-dropdown mb-4 flex  items-center">
			<label
				className={clsx('mb-2 block text-xs font-light text-kgrey-welcome', {
					'mr-4': !isRTL,
					'ml-4': isRTL
				})}
			>
				<span>{t('status_hint')}</span>
			</label>
			{!loading && (
				<Field
					className="w-full"
					component={AsyncSelectJs}
					customStylesProps={KntTwilioStyle}
					name="status"
					optionLabel="title"
					optionValue="id"
					isSearchable
					isCached
					maxMenuHeight={200}
					placeholder={t('select_status')}
					reverse={true}
					loadOptionsUrl={`/${companyId}/my/company/case_statuses/`}
					onChange={(option: any) => {
						setState((prev: any) => ({ ...prev, status: option }));
					}}
					loadOptionsParams={(search: string) => ({
						extra: {
							search,
							is_staff: caseType === config.STAFF ? 'true' : ''
						}
					})}
					loadOptionsKey=""
				/>
			)}
		</div>
	);
}
