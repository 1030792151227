import React from 'react';
import { BsFillBrightnessHighFill } from 'react-icons/bs';
import { RiMoonFill } from 'react-icons/ri';
import { useAppState } from 'shared/state';
import { IconWrapper } from '../../IconWrapper';

export function DarkToggleBtn() {
	const { toggleDark, isDarkMode } = useAppState();
	return (
		<div
			className="group flex h-full cursor-pointer items-center pl-2 lg:pl-5"
			onClick={toggleDark}
		>
			<IconWrapper>
				{isDarkMode ? (
					<span className={'text-white group-hover:text-primary'}>
						<BsFillBrightnessHighFill size={'15px'} />
					</span>
				) : (
					<span className={'text-black group-hover:text-primary'}>
						<RiMoonFill size={'11px'} />
					</span>
				)}
			</IconWrapper>
		</div>
	);
}
