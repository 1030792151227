import React from 'react';

export function IconClientUsers({ className = '' }: { className?: string }) {
	return (
		<svg
			width="20"
			height="21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M15.714 4.607v10.536a.357.357 0 0 0 .709.064l.006-.064V5.5h.892a1.25 1.25 0 0 1 1.246 1.147l.004.103v8.572a2.321 2.321 0 0 1-2.19 2.318l-.131.003H3.75a2.321 2.321 0 0 1-2.318-2.19l-.004-.131V4.607a1.25 1.25 0 0 1 1.148-1.245l.102-.005h11.786a1.25 1.25 0 0 1 1.246 1.148l.004.102v10.536V4.607ZM6.962 9.79H5.178a1.25 1.25 0 0 0-1.245 1.147l-.005.103v1.783a1.25 1.25 0 0 0 1.148 1.245l.102.005h1.784a1.25 1.25 0 0 0 1.246-1.148l.003-.102v-1.783a1.25 1.25 0 0 0-1.147-1.247l-.102-.003ZM12.678 13h-2.5l-.072.005a.536.536 0 0 0 0 1.062l.072.005h2.5l.073-.005a.536.536 0 0 0 0-1.062L12.68 13Zm-7.5-2.14h1.784a.179.179 0 0 1 .174.137l.005.042v1.783a.179.179 0 0 1-.138.173L6.962 13H5.178a.178.178 0 0 1-.173-.138L5 12.822v-1.783a.178.178 0 0 1 .138-.174l.04-.005h1.784-1.784Zm7.5-1.071h-2.5l-.072.004a.536.536 0 0 0 0 1.062l.072.005h2.5l.073-.005a.536.536 0 0 0 0-1.062l-.072-.004Zm0-3.22H4.464l-.073.006a.536.536 0 0 0 0 1.061l.073.005h8.215l.072-.005a.536.536 0 0 0 0-1.061l-.072-.005Z"
				fill="currentColor"
			/>
		</svg>
	);
}
