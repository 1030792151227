import React from 'react';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export function LoaderWithProgress(props: CircularProgressProps & { value: number }) {
	return (
		<Box position="relative" display="inline-flex">
			<CircularProgress variant="determinate" {...props} />
			<Box
				top={0}
				left={0}
				bottom={0}
				right={0}
				position="absolute"
				display="flex"
				alignItems="center"
				justifyContent="center"
			>
				<Typography
					className="dark:text-kdark-dark333"
					variant="caption"
					component="div"
					color="initial"
				>{`${Math.round(props.value)}%`}</Typography>
			</Box>
		</Box>
	);
}
