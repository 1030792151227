import * as React from 'react';

export function IconDownload({ className }: { className: string }) {
	return (
		<svg
			width="24"
			height="24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path d="M12 18.55 6.5 12h11L12 18.55ZM10 2h4v2h-4V2Zm0 3h4v2h-4V5Z" fill="currentColor" />
			<path d="M10 8h4v5.5h-4V8ZM3 20h18v2H3v-2Z" fill="currentColor" />
		</svg>
	);
}
