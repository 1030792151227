import React from 'react';
import { Footer } from 'shared/components';

export const StandardPageLayout: React.FC = ({ children }) => {
	return (
		<div className={'flex h-full flex-col px-4 py-5'}>
			<div className="flex-grow">{children}</div>
			<div className="footer mt-auto">
				<Footer />
			</div>
		</div>
	);
};
