import Loader from '../../Loader';
import React from 'react';

export const VirtuosoFooter = () => {
	return (
		<div className="chat-loader absolute left-1/2 -translate-x-1/2">
			<Loader />
		</div>
	);
};

VirtuosoFooter.displayName = 'VirtuosoFooter';
