import { useEffect, useState } from 'react';
import {
	createDayDetails,
	momentDays,
	setDropdownOptions,
	setRecurringOption,
	weekOrders
} from './recurring.helper';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { convertToLocalizationKey } from 'shared/services/utils/convertToLocalizationKey';
import { TCreateCaseFormState } from '../CreateCaseForm';
import { isEmpty } from 'lodash';

type CaseHookArgs = {
	checked: any;
	setFieldValue: (name: string, value: any, validate?: boolean) => void;
	setState: React.Dispatch<React.SetStateAction<TCreateCaseFormState>>;
};

export function useCaseRecurring({ checked, setFieldValue, setState }: CaseHookArgs) {
	const [recurringState, setRecurringState] = useState({
		recurring: {},
		recurringOptions: [],
		recurOption: null,
		showCustomRecurring: false
	});
	const { t } = useTranslation();

	const { recurringOptions, recurOption, recurring, showCustomRecurring } = recurringState;

	const [selectedTime, setSelectedTime] = useState(dayjs().hour(8).minute(0));

	const currentDate = new Date();
	const currentDay = currentDate.getDay();
	const currentMonthDate = currentDate.getDate();
	const currentWeekday = t(convertToLocalizationKey(momentDays[currentDay]));

	const currentDayObj = createDayDetails(
		dayjs(
			new Date(`${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentMonthDate}`)
		)
	);
	const ON_WEEK_DAY = `${t(weekOrders[currentDayObj.weekOrder - 1])} ${t(
		convertToLocalizationKey(currentDayObj.day)
	)}`;

	const setRecurOption = (value: any) =>
		setRecurringState((prev) => ({ ...prev, recurring: value }));

	//Set recurring field from dropdown predefined options
	useEffect(() => {
		setRecurringOption({
			moment: dayjs,
			selectedTime,
			setRecurOption,
			recurOption,
			currentWeekday,
			currentDayObj,
			ON_WEEK_DAY,
			handler: setRecurringState
		});
	}, [recurOption, selectedTime]);

	//Set recurring field
	useEffect(() => {
		if (checked.recurring) {
			setTimeout(() => {
				setFieldValue('recurring', !isEmpty(recurring) ? recurring : null, true);
			}, 0);

			setState((prev) => ({
				...prev,
				isRecurringSet: true
			}));
		} else {
			setTimeout(() => {
				setFieldValue('recurring', null, true);
			}, 0);
			setState((prev) => ({
				...prev,
				isRecurringSet: false
			}));
			setRecurringState((prevState: any) => ({ ...prevState, recurOption: null }));
		}
	}, [recurring, setFieldValue, checked.recurring]);

	//Setting up recurring options
	useEffect(() => {
		setDropdownOptions({
			handler: setRecurringState,
			moment: dayjs,
			currentWeekday,
			ON_WEEK_DAY,
			translate: t
		});
	}, []);

	const onCloseRecurrDialog = () => {
		setRecurringState((prev) => ({ ...prev, showCustomRecurring: false }));
	};

	//Handle form inputs
	const handleInput = (e: any) => {
		const {
			target: { name, value }
		} = e;
		setRecurringState((prev) => ({ ...prev, [name]: value }));
	};

	return {
		onCloseRecurrDialog,
		showCustomRecurring,
		selectedTime,
		setSelectedTime,
		recurringOptions,
		handleInput,
		recurOption,
		ON_WEEK_DAY,
		currentDay,
		currentMonthDate,
		currentDayObj,
		setRecurringState,
		recurring
	};
}
