import { useEffect, useRef } from 'react';
import selectors from 'modules/entity/selectors';
import { useSelector } from 'react-redux';
import { IRootState } from 'shared/interfaces';
import isEmpty from 'lodash/isEmpty';
import { scrollToStatus } from '../helpers';
import { useAppState } from 'shared/state';

const getStatusIds = selectors.getStatusIds;

export function useScrollToMostLeftUnread() {
	const { unreadCasesCountByStatus, companyId, caseType } = useAppState();
	const statusIds = useSelector((state: IRootState) => getStatusIds(state, companyId));
	const isScrolledRef = useRef(false);
	const isInitialLoad = useRef(true);

	// Scroll to left most unread
	useEffect(() => {
		if (!isEmpty(unreadCasesCountByStatus) && statusIds) {
			const statusIdsFromUnreadCases = Object.keys(unreadCasesCountByStatus);
			const unreadStatusesIds = statusIds.filter((statusId: number) =>
				statusIdsFromUnreadCases.includes(statusId.toString())
			);

			if (!isScrolledRef.current) {
				const scrollStatusId = unreadStatusesIds[0];

				//Scroll to Status Wrapper Element
				const statusElement = scrollToStatus(scrollStatusId);
				if (!statusElement || isInitialLoad.current) {
					isScrolledRef.current = true;
					isInitialLoad.current = false;
				}
			}

			// document.querySelector('.app-container')?.classList.add('left-7');
		}
	}, [statusIds, unreadCasesCountByStatus, caseType]);

	useEffect(() => {
		isScrolledRef.current = false;
		isInitialLoad.current = true;
	}, [caseType, statusIds]);
}
