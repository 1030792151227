import { useChatContext, useChatInputContext } from 'shared/contexts';
import { useAppState } from 'shared/state';
import clsx from 'clsx';
import { PiClockClockwise } from 'react-icons/pi';
import React from 'react';
import { IconBtn } from './IconBtn';

export function ScheduledChatIcon(props: { onClick: () => void }) {
	const { entityId, isScheduledChat } = useChatContext();
	const { command } = useChatInputContext();
	const { scheduledMessageIds } = useAppState();
	return (
		<IconBtn
			colorClass={'text-2xl relative dark:text-white'}
			classnames={clsx({
				hidden: command === 'start' || isScheduledChat || !scheduledMessageIds[entityId]?.length
			})}
			onClick={props.onClick}
		>
			<PiClockClockwise />
		</IconBtn>
	);
}
