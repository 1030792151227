import { useRef, useEffect, useState } from 'react';
import config from 'config';

const apiKey = config.GMAP_API_KEY;
const mapApiJs = 'https://maps.googleapis.com/maps/api/js';
const geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';

// load google map api js
function loadAsyncScript(src: string) {
	return new Promise((resolve) => {
		const script = document.createElement('script');

		Object.assign(script, {
			type: 'text/javascript',
			async: true,
			src
		});

		script.addEventListener('load', () => resolve(script));
		document.head.appendChild(script);
	});
}

export type PlaceAddressType = {
	city: string;
	state: string;
	zip: string;
	country: string;
	houseNumber: string;
	street: string;
	lat: number;
	lng: number;
	plain: () => string;
};

const extractAddress = (place: any): PlaceAddressType => {
	const address = {
		city: '',
		state: '',
		zip: '',
		country: '',
		houseNumber: '',
		street: '',
		lat:
			typeof place.geometry?.location?.lat === 'function'
				? place.geometry?.location?.lat()
				: place.geometry?.location?.lat,
		lng:
			typeof place.geometry?.location?.lng === 'function'
				? place.geometry?.location?.lng()
				: place.geometry?.location?.lng,
		plain() {
			const houseNumber = this.houseNumber ? `${this.houseNumber}, ` : '';
			const street = this.street ? `${this.street}` : '';
			return houseNumber + street;
		}
	};

	if (!Array.isArray(place?.address_components)) {
		return address;
	}

	place.address_components.forEach((component: any) => {
		const { types } = component;
		const value = component.long_name;

		if (types.includes('locality')) {
			address.city = value;
		}
		if (types.includes('route')) {
			address.street = value;
		}
		if (types.includes('street_number')) {
			address.houseNumber = value;
		}

		if (types.includes('administrative_area_level_1')) {
			address.state = value;
		}

		if (types.includes('postal_code')) {
			address.zip = value;
		}

		if (types.includes('country')) {
			address.country = value;
		}
	});

	return address;
};

export function useAutoComplete(isLoaded: boolean) {
	const searchInputRef = useRef(null);
	const [placeAddress, setPlaceAddress] = useState<PlaceAddressType>({} as PlaceAddressType);
	// init gmap script
	const initMapScript = () => {
		// if script already loaded
		// @ts-ignore
		if (window?.google) return Promise.resolve();
		const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly&language=en`;
		return loadAsyncScript(src);
	};

	const onChangeAddress = (autocomplete: any) => {
		// console.log("autocomplete", autocomplete);
		const place = autocomplete.getPlace();
		const address = extractAddress(place);
		// @ts-ignore
		searchInputRef.current.value = address.plain();
		setPlaceAddress(address);
	};

	// init autocomplete
	const initAutoComplete = () => {
		if (!searchInputRef.current) return;

		// @ts-ignore
		// @ts-ignore
		const autocomplete = new window.google.maps.places.Autocomplete(searchInputRef.current);
		autocomplete.setFields(['address_component', 'geometry']);
		autocomplete.addListener('place_changed', () => onChangeAddress(autocomplete));
	};

	const reverseGeocode = ({
		latitude: lat,
		longitude: lng
	}: {
		latitude: number;
		longitude: number;
	}) => {
		const url = `${geocodeJson}?key=${apiKey}&latlng=${lat},${lng}&language=en`;

		if (searchInputRef.current) {
			// @ts-ignore
			searchInputRef.current.value = 'Getting your location...';
		}

		fetch(url)
			.then((response) => response.json())
			.then((location) => {
				const place = location.results[0];
				const address = extractAddress(place);
				setPlaceAddress(address);
				// @ts-ignore
				if (searchInputRef.current) searchInputRef.current.value = address.plain();
			});
	};

	const findMyLocation = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position) => {
				reverseGeocode(position.coords);
			});
		}
	};

	// load map script after mounted
	useEffect(() => {
		if (isLoaded !== undefined) {
			// @ts-ignore
			if (isLoaded && window?.google) {
				initAutoComplete();
			}
			return;
		}
		initMapScript().then(() => initAutoComplete());
	}, [isLoaded, initAutoComplete]);

	return {
		searchInputRef,
		placeAddress,
		findMyLocation
	};
}
