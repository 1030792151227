import firebase from 'firebase/app';
import 'firebase/messaging';
import 'firebase/auth';
import 'firebase/database';

// Initialize Firebase
const config = {
	apiKey: 'AIzaSyBYL4QAhRMPghTiFm0P9dRG6za5I9pXnoc',
	authDomain: 'konnex-client.firebaseapp.com',
	databaseURL: 'https://konnex-client.firebaseio.com',
	projectId: 'konnex-client',
	storageBucket: 'konnex-client.appspot.com',
	messagingSenderId: '285415707623',
	appId: '1:285415707623:web:867663ac4a06fbe8632342'
};

//@ts-ignore
export let messaging: any;
firebase.initializeApp(config);
// messaging = firebase.messaging();

if (firebase.messaging.isSupported()) {
	messaging = firebase.messaging();
}

// const messaging = firebase.messaging();

export const requestFirebaseNotificationPermission = () =>
	new Promise((resolve, reject) => {
		messaging
			.requestPermission()
			.then(() => messaging.getToken())
			.then((firebaseToken: string) => {
				resolve(firebaseToken);
			})
			.catch((err: Error) => {
				reject(err);
			});
	});
export const requestFirebaseNotificationPermissionAsync = async () => {
	try {
		await messaging.requestPermission();
		const token = await messaging.getToken();
		messaging.onMessage((payload: any) => {
			if (process.env.NODE_ENV !== 'production') {
				console.log('payload of push when app is in foreground in init', payload);
			}
		});
		// console.log("fcm token", token);
		return token;
	} catch (err) {
		console.error('fcm error', err);
	}
};

export const onMessageListener = () =>
	new Promise((resolve) => {
		messaging.onMessage((payload: any) => {
			return resolve(payload);
		});
	});

const auth = firebase.auth();
const database = firebase.database();
export { auth, database };
