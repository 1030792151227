import React, { Dispatch, useEffect } from 'react';
import { FormikErrors } from 'formik/dist/types';
import { KntInputField } from '../Fields';
import { Field } from 'formik';
import AsyncSelectJs from '../Fields/AsyncSelectJs';
import { KntTwilioStyle } from '../../services';
import { Loader } from '../index';
import { useTranslation } from 'react-i18next';

type CreateBusinessFormElementsProps = {
	values: { [key: string]: any };
	isSubmitting: boolean;
	setCustomRequiredFields: Dispatch<
		React.SetStateAction<{
			custom_industry: boolean;
		}>
	>;
};

type BValues = {
	name: string;
	industry: { id: number | string; name: string };
	sub_industry: { id: number | string; name: string };
	custom_industry: string;
	custom_sub_industry: string;
};

export function CreateBusinessFormElements({
	values,
	isSubmitting,
	setCustomRequiredFields
}: CreateBusinessFormElementsProps) {
	const { t } = useTranslation();
	const isCustomIndustry = (values as BValues).industry?.id === 'customIndustry';
	const isCustomSubIndustry = (values as BValues).sub_industry?.id === 'customSubIndustry';

	useEffect(() => {
		setCustomRequiredFields((prev) => ({ ...prev, custom_industry: isCustomIndustry }));
	}, [isCustomIndustry, setCustomRequiredFields]);

	return (
		<>
			<div>
				<Field
					label={t('enter_new_business_name')}
					name="name"
					component={KntInputField}
					placeholder={t('enter_new_business_name')}
					inputElementClassNames={'dark:bg-kdark-lighter dark:text-white'}
					required
				/>
			</div>
			{(!isCustomIndustry || !values.custom_industry || values.custom_industry.length === 0) && (
				<div className={'react-select-dropdown mb-4'}>
					<label className="ml-6 mb-2 block text-xs font-light text-kgrey-welcome">
						<span>{t('industry')}</span>
						<sup>*</sup>
					</label>
					<Field
						component={AsyncSelectJs}
						customStylesProps={KntTwilioStyle}
						name="industry"
						placeholder={t('industry_name_placeholder', 'industry')}
						optionLabel="name"
						optionValue="id"
						isSearchable
						isClearable
						loadOptionsKey="results"
						loadOptionsUrl={'/industries/'}
						defaultOption={{
							name: t('suggest_industry', { industry: t('industry') }),
							id: 'customIndustry'
						}}
						loadOptionsParams={(search: string) => ({
							extra: {
								search
							}
						})}
						onChange={(option: any) => {}}
					/>
				</div>
			)}
			{/*  Industry finish*/}
			{values.industry && !isCustomIndustry && (
				<div className={'react-select-dropdown mb-4'}>
					<label className="ml-6 mb-2 block text-xs font-light text-kgrey-welcome">
						<span>{t('sub_industry')}</span>
					</label>
					<Field
						component={AsyncSelectJs}
						customStylesProps={KntTwilioStyle}
						name="sub_industry"
						placeholder={t('sub_industry_name_placeholder')}
						optionLabel="name"
						optionValue="id"
						isSearchable
						isClearable
						defaultOption={{
							name: t('suggest_industry', { industry: t('sub_industry') }),
							id: 'customSubIndustry'
						}}
						loadOptionsKey="results"
						loadOptionsUrl={`/industries/${values.industry?.id}/sub_industries/`}
						loadOptionsParams={(search: string) => ({
							extra: {
								search
							}
						})}
						onChange={(option: any) => {}}
					/>
				</div>
			)}
			{/*  Sub Industry finish*/}
			{isCustomIndustry && (
				<div>
					<Field
						label={t('custom_industry')}
						name="custom_industry"
						component={KntInputField}
						placeholder={t('enter_custom_industry')}
						inputElementClassNames={'dark:bg-kdark-lighter dark:text-white'}
						required
					/>
				</div>
			)}
			{/*  Custom industry end*/}
			{(isCustomIndustry || isCustomSubIndustry) && (
				<div>
					<Field
						label={t('custom_sub_industry', {
							required: `${isCustomIndustry ? `(${t('optional_hint')})` : ''}`
						})}
						name="custom_sub_industry"
						component={KntInputField}
						placeholder={t('enter_custom_sub_industry')}
						inputElementClassNames={'dark:bg-kdark-lighter dark:text-white'}
					/>
				</div>
			)}
			<div className={'flex items-center justify-end'}>
				{isSubmitting && <Loader tiled withOverlay />}
				<button
					className={
						'rounded-lg bg-primary-dark px-5 py-2 text-white ' +
						'border border-transparent hover:border-primary-dark' +
						' transition-colors hover:bg-white ' +
						'hover:text-primary-dark disabled:pointer-events-none disabled:opacity-50 dark:text-white dark:hover:bg-primary-300 dark:focus:ring-primary-300'
					}
					type={'submit'}
					disabled={isSubmitting}
				>
					{t('create_new_business')}
				</button>
			</div>
		</>
	);
}
