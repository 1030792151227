import React, { useState } from 'react';
import { Button } from 'shared/components/Elements';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useConfirmedBtnActions } from '../hooks/useConfirmedBtnActions';
import { ErrorBoundary, Loader } from 'shared/components';
import { isMobile } from 'react-device-detect';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		btnRoot: {
			marginLeft: '10px',
			marginRight: '10px',
			color: 'white'
		},
		wrapper: {
			margin: theme.spacing(1),
			position: 'relative'
		}
	})
);

function ConfirmationButtonsInner() {
	const classes = useStyles();
	const { sendStatusChangeRequest, statusChangeReqLoading, currentStatus } =
		useConfirmedBtnActions();
	const [confirmType, setConfirmType] = useState('');
	const { t } = useTranslation();

	return (
		<>
			{currentStatus?.is_confirmation_required ? (
				<div className={'relative z-10 flex w-full justify-center p-2'}>
					<div className={classes.wrapper}>
						<Button
							variant={'contained'}
							color={'secondary'}
							classes={{ root: classes.btnRoot }}
							disabled={statusChangeReqLoading}
							onClick={() => {
								setConfirmType('not_confirmed');
								sendStatusChangeRequest('not_confirmed');
							}}
						>
							<span className={clsx({ 'text-base': !isMobile, 'text-xs': isMobile })}>
								{t('not_confirmed')}
							</span>
						</Button>
						{statusChangeReqLoading && confirmType === 'not_confirmed' && (
							<Loader tiled size={24} />
						)}
					</div>
					<div className={classes.wrapper}>
						<Button
							variant={'contained'}
							color={'primary'}
							classes={{ root: classes.btnRoot }}
							disabled={statusChangeReqLoading}
							onClick={() => {
								setConfirmType('confirmed');
								sendStatusChangeRequest('confirmed');
							}}
						>
							<span className={clsx({ 'text-base': !isMobile, 'text-xs': isMobile })}>
								{t('confirmed')}
							</span>
						</Button>
						{statusChangeReqLoading && confirmType === 'confirmed' && <Loader tiled size={24} />}
					</div>
				</div>
			) : null}
		</>
	);
}

export function ConfirmationButtons() {
	return (
		<ErrorBoundary isCompact>
			<ConfirmationButtonsInner />
		</ErrorBoundary>
	);
}
