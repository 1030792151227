type CheckMsgAuthorType = {
	currentMsgAuthorId: number;
	prevMsgAuthorId: number;
	nextMsgAuthorId: number;
	prevMsgType: string;
	nextMsgType: string;
};

export function checkMsgAuthor({
	currentMsgAuthorId,
	prevMsgAuthorId,
	nextMsgAuthorId,
	prevMsgType,
	nextMsgType
}: CheckMsgAuthorType) {
	const isSameFromUserWithPrev =
		currentMsgAuthorId === prevMsgAuthorId &&
		(prevMsgType === 'MESSAGE' || prevMsgType === 'new_message');

	const isSameFromUserWithNext =
		currentMsgAuthorId === nextMsgAuthorId &&
		(nextMsgType === 'MESSAGE' || nextMsgType === 'new_message');

	return {
		isSameFromUserWithPrev,
		isSameFromUserWithNext
	};
}
