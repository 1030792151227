import { useEffect, useState } from 'react';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { requestFirebaseNotificationPermissionAsync } from '../firebase';
import { useAxios } from './useAxios';
import Actions from 'store/actions';
import { useDispatch } from 'react-redux';

const SetUserFCM = Actions.user.setUserFCM;
export const useFCM = () => {
	const { userData, isAuthentificated } = useAuth();
	const dispatch = useDispatch();
	const [fscSendData, setfcmSendData] = useState<{
		registration_id: string;
		username: string;
		is_web: boolean;
		is_business_app: boolean;
	} | null>(null);
	const { fetchData } = useAxios({
		url: '/web-devices/',
		method: 'post',
		values: fscSendData
	});

	useEffect(() => {
		if (fscSendData) fetchData();
	}, [fscSendData]);

	useEffect(() => {
		(async () => {
			if (isAuthentificated) {
				try {
					const token = await requestFirebaseNotificationPermissionAsync();
					dispatch(SetUserFCM(token));
					if (token && userData) {
						setfcmSendData({
							registration_id: token,
							username: userData.username,
							is_web: true,
							is_business_app: true
						});
					}
				} catch (e: any) {
					console.error('firebase token get error', e);
				}
			}
		})();
	}, [userData]);
};
