import React, { useEffect, useRef, useState } from 'react';
import { Field } from 'formik';
import AsyncSelectJs from 'shared/components/Fields/AsyncSelectJs';
import { KntTwilioStyle } from 'shared/services';
import {
	depOnChange,
	handleCaseInputChange,
	runSideEffectsAfterDepartmentChange
} from '../../helpers';
import get from 'lodash/get';
import { CreateCaseSubDepComponent } from './CreateCaseSubDep';
import { CreateCaseDataFields } from '../CreateCaseDataFields';
import { useAppState } from 'shared/state';
import { isMobile } from 'react-device-detect';
import config from '../../../../config';
import { useTranslation } from 'react-i18next';

type Props = {
	setFieldValue: any;
	titleRef: any;
	setState: any;
	data_fields_errors: any;
	data_fields_names: any;
	departmentRef: any;
	state: any;
	setFormState: any;
	values: any;
	data_fields: any;
	subDepIdsRef: any;
	setAssigneeTypeValue: any;
};

export function CreateCaseDepartments({
	setFieldValue,
	titleRef,
	setState,
	data_fields_errors,
	data_fields_names,
	departmentRef,
	state,
	setFormState,
	values,
	data_fields,
	subDepIdsRef,
	setAssigneeTypeValue
}: Props) {
	const { companyId, caseType } = useAppState();
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);

	const caseTypeRef = useRef(caseType);

	//These 2 useEffect is necessary for unmount and remount departments AsyncSelect when we change case type
	//If we do not remount this components AsyncSelect component does not refetch new departments due to isCached=true prop
	//If we do not set isCached to true, navigating with tabs does work properly/

	useEffect(() => {
		if (caseType !== caseTypeRef.current) {
			setLoading(true);
			caseTypeRef.current = caseType;
		}
	}, [caseType]);

	useEffect(() => {
		if (loading) {
			setLoading(false);
		}
	}, [loading]);

	return (
		<>
			<div className="react-select-dropdown mb-4">
				{!loading && (
					<Field
						component={AsyncSelectJs}
						customStylesProps={KntTwilioStyle}
						name="department"
						placeholder={t('select_department')}
						optionLabel="name"
						optionValue="id"
						isSearchable={!isMobile}
						isClearable
						isCached
						openMenuOnFocus
						loadOptionsKey="results"
						loadOptionsUrl={`/${companyId}/departments/`}
						loadOptionsParams={(search: string) => ({
							extra: {
								search,
								is_staff: caseType === config.STAFF ? 'true' : 'false',
								ordering: 'name'
							}
						})}
						onChange={(option: any) => {
							depOnChange({
								option,
								setFieldValue,
								titleRef,
								setState,
								data_fields_errors,
								data_fields_names
							});
							runSideEffectsAfterDepartmentChange({
								departmentRef,
								option,
								setFieldValue
							});
							setAssigneeTypeValue('all');
						}}
					/>
				)}
			</div>

			{get(values, 'department') && get(values, 'department.sub_departments_count') > 0 && (
				<>
					{Object.keys(values.sub_department).map((subdep) => {
						return (
							<CreateCaseSubDepComponent
								key={subdep}
								{...{
									subdep,
									companyId,
									values,
									setFieldValue,
									setFormState,
									titleRef,
									subDepIdsRef,
									setAssigneeTypeValue
								}}
							/>
						);
					})}
				</>
			)}
			{/*Render Case Chat Data fields*/}
			{data_fields.length > 0 && (
				<CreateCaseDataFields
					allProps={{
						data_fields,
						setState,
						handleCaseInputChange,
						setFieldValue,
						data_fields_names,
						titleRef
					}}
				/>
			)}
		</>
	);
}
