import React from 'react';

export function IconVertical({ className = '' }: { className?: string }) {
	return (
		<svg
			width="6"
			height="24"
			className={className}
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6 21a3 3 0 10-6 0 3 3 0 006 0zm0-9a3 3 0 10-6 0 3 3 0 006 0zM3 0a3 3 0 110 6 3 3 0 010-6z"
				// fill="#6A7480"
			/>
		</svg>
	);
}

export default IconVertical;
