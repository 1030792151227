import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

type Props = {
	allProps: any;
};

export const CreateCaseDataFields = React.forwardRef<any, Props>(function CreateCaseDataFields(
	props,
	ref
) {
	const {
		data_fields,
		setState,
		handleCaseInputChange,
		setFieldValue,
		data_fields_names,
		titleRef
	} = props.allProps;
	const { t } = useTranslation();

	return (
		<div className="case-form__data-fields mb-15">
			<div className="ml-6 mb-2 block text-xs font-light text-kgrey-welcome">
				<span>{t('department_data_fields')}</span>
			</div>
			{data_fields.map((field: any) => {
				const error = field.isRequired && data_fields_names[field.fieldName].length === 0;
				return (
					<div className={clsx('mb-4')} key={field.id}>
						<div className="relative">
							<input
								required={field.isRequired}
								name={field.fieldName}
								type="text"
								className={clsx(
									'w-full rounded-lg border border-kgrey-inputBd px-6 py-3.5 text-xs !text-15px font-light text-kdark-424653 placeholder-current placeholder-kgrey-welcome outline-none transition focus:border-primary focus:shadow-lg dark:bg-kdark dark:text-white',
									error && 'border-red-500'
								)}
								placeholder={`${field.dataName} ${field.isRequired ? '' : '(Optional)'}`}
								value={data_fields_names[field.fieldName]}
								onChange={(e) => {
									handleCaseInputChange({ event: e, setState });
									setFieldValue('title', titleRef.current);
								}}
							/>
						</div>
						{error ? <span className={'text-xs text-red-500'}>{t('required')}</span> : null}
					</div>
				);
			})}
		</div>
	);
});
