/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import cx from 'clsx';
import get from 'lodash/get';
import { AsyncPaginate } from 'react-select-async-paginate';
import isEmpty from 'lodash/isEmpty';
import qs from 'query-string';
import { queryBuilder, api } from 'shared/services';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';

// const { data } = await api.request.get(api.queryBuilder(url, { page, limit: 10, filter: filterParams(search), ...params }));

const load = async (
	search,
	prevOptions,
	page,
	url,
	loadOptionsKey,
	filterParams,
	params,
	defaultOption,
	reverse,
	pageCount = 10
) => {
	// const pageCount = 10;
	// console.log("defaultOption", defaultOption);
	const { data } = await api.request.get(
		queryBuilder(url, {
			page,
			limit: pageCount,
			filter: filterParams(search),
			...(typeof params === 'function' ? params(search) : params)
		})
	);

	const optionCount = data.count;
	const nextUrl = data && get(data, 'next');
	const indexUrl = nextUrl && nextUrl.indexOf('?');
	const queryParams = nextUrl && nextUrl.slice(indexUrl);
	const splitted = qs.parse(queryParams);
	const currentPage = splitted && Number(splitted.page) - 1;
	// console.log("splitted", splitted);
	// console.log("currentPage", currentPage);

	let last_page;
	if (optionCount % pageCount === 0) {
		last_page = optionCount / pageCount;
	} else {
		last_page = Math.floor(optionCount / pageCount) + 1;
	}

	let options = [];

	if (reverse) {
		options =
			loadOptionsKey && isEmpty(defaultOption)
				? get(data, loadOptionsKey, []).reverse()
				: loadOptionsKey && !isEmpty(defaultOption)
				? !isEmpty(get(data, loadOptionsKey, [])) && currentPage !== null
					? [...get(data, loadOptionsKey, []).reverse()]
					: [...get(data, loadOptionsKey, []).reverse(), defaultOption]
				: data.reverse();
	} else {
		options =
			loadOptionsKey && isEmpty(defaultOption)
				? get(data, loadOptionsKey, [])
				: loadOptionsKey && !isEmpty(defaultOption)
				? !isEmpty(get(data, loadOptionsKey, [])) && currentPage
					? [...get(data, loadOptionsKey, [])]
					: [...get(data, loadOptionsKey, []), defaultOption]
				: data;
	}

	// console.log("currentPage", currentPage);
	// console.log("last_page", last_page);

	return {
		// options: loadOptionsKey && isEmpty(defaultOption) ? get(data, loadOptionsKey, []) : isEmpty(defaultOption) ? data : [defaultOption],
		options,
		hasMore: currentPage < last_page && currentPage !== null,
		additional: { page: currentPage + 1 }
	};
};

class AsyncSelect extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false
		};
	}

	selectRef = React.createRef();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	componentDidUpdate(prevProps, prevState, snapshot) {
		const { loadOptionsUrl, loadOptionsParams, isCached = false } = this.props;
		if (
			(loadOptionsUrl !== prevProps.loadOptionsUrl ||
				prevProps.loadOptionsParams !== loadOptionsParams) &&
			!isCached
		) {
			// eslint-disable-next-line react/no-did-update-set-state
			this.setState(
				{
					loading: true
				},
				() => {
					this.setState({
						loading: false
					});
				}
			);
		}
	}

	render() {
		const {
			disableOptions,
			className,
			label,
			isMulti,
			placeholder,
			options,
			field,
			optionLabel,
			optionValue,
			form: { errors, setFieldValue, setFieldTouched, touched },
			isSearchable,
			isClearable,
			menuPlacement,
			loadOptionsUrl,
			loadOptionsKey,
			loadOptionsParams = {},
			filterParams = () => ({}),
			onCreateOption,
			SelectComponent,
			isLoading,
			reverse = false,
			defaultOption = {},
			onChange,
			onClearChange,
			pageCount,
			customStylesProps,
			innerRef = null,
			labelClasses = '',
			reFocusAfterChange = false,
			t,
			required = false,
			...rest
		} = this.props;

		const classNames = cx(
			'field-container',
			touched[field.name] && errors[field.name] && 'has-error',
			className
		);

		const customStyles = {
			indicatorSeparator: (props) => ({
				display: 'none'
			})
		};
		// console.log('errors', errors);
		// console.log("this.state, this.props", { props: this.props, state: this.state.loading });
		const { loading } = this.state;
		return (
			<div className={classNames}>
				<div className="ant-row ant-form-item ">
					{label && (
						<div className={`${labelClasses}`}>
							{label}
							{required ? <sup>*</sup> : null}
						</div>
					)}
					{!loading && (
						<AsyncPaginate
							noOptionsMessage={() => t('no_options')}
							loadingMessage={() => `${t('loading')}...`}
							styles={customStyles && customStylesProps}
							id={field.name}
							name={field.name}
							className={clsx('w-full', { reFocusAfterChange: reFocusAfterChange })}
							debounceTimeout={300}
							onChange={(option) => {
								// console.log("option Async", option);
								// console.log("on change trigger");
								setFieldValue(field.name, option);
								if (typeof onChange === 'function') {
									onChange(option);
								}
								if (typeof onClearChange === 'function' && option === null) {
									onClearChange(null);
								}
							}}
							onBlur={() => {
								// console.log("on blur trigger");
								setFieldTouched(field.name, true);
							}}
							getValue={(option) => option[optionValue]}
							getOptionLabel={(option) =>
								typeof optionLabel === 'function' ? optionLabel(option) : option[optionLabel]
							}
							getOptionValue={(option) =>
								typeof optionValue === 'function' ? optionValue(option) : option[optionValue]
							}
							value={field.value}
							additional={{ page: 1 }}
							tabIndex={0}
							selectRef={reFocusAfterChange ? innerRef : this.selectRef}
							loadOptions={(search, prevOptions, { page }) =>
								load(
									search,
									prevOptions,
									page,
									loadOptionsUrl,
									loadOptionsKey,
									filterParams,
									loadOptionsParams,
									defaultOption,
									reverse,
									pageCount
								)
							}
							isOptionDisabled={(option) =>
								disableOptions?.reduce((prev, curr) => [...prev, curr.id], []).includes(option.id)
							}
							{...{
								isMulti,
								options,
								placeholder,
								isSearchable,
								isClearable,
								menuPlacement,
								onCreateOption,
								SelectComponent,
								isLoading
							}}
							{...rest}
						/>
					)}
					{touched[field.name] && errors[field.name] && (
						<span className="text-xs text-red-500">{errors[field.name]}</span>
					)}
				</div>
			</div>
		);
	}
}

AsyncSelect.propTypes = {
	title: PropTypes.string,
	className: PropTypes.string,
	optionValue: PropTypes.string,
	isSearchable: PropTypes.bool,
	menuPlacement: PropTypes.string
};

AsyncSelect.defaultProps = {
	className: null,
	optionValue: 'id',
	// optionLabel: "title",
	isSearchable: false,
	menuPlacement: 'bottom'
	// disableOptions: [],
	// loadOptionsKey: 'items'
};

export default withTranslation()(AsyncSelect);
