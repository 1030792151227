import React, { useCallback } from 'react';
import clsx from 'clsx';
import { Avatar, Menu, WhiteModal } from 'shared/components/Elements';
import { LastMessageView } from 'shared/components/LastMessageView';
import { IconVertical } from 'shared/icons/IconVertical';
import MenuItem from '@material-ui/core/MenuItem';
import { time } from 'shared/services';
import { IconPin } from 'shared/icons';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useCaseMenu } from '../hooks/useCaseMenu';
import { useFilterTemplateState, useQueryParams, useRouter, useWhiteModal } from 'shared/hooks';
import { nanoid } from 'nanoid';
import { useAppState } from 'shared/state';
import { Priority } from 'shared/interfaces';
import { EntityDraft } from '../../GroupChat/types';
import { ErrorBoundary } from 'shared/components';
import { getDepartmentIcon } from '../helpers';
import { ToggleCaseDetailsOpen } from './case-details/CaseDetails.types';
import { TbCopy } from 'react-icons/tb';
import { BiCalendar, BiLink } from 'react-icons/bi';
import { SiMaildotru } from 'react-icons/si';
import { CaseMenuStatusChange } from './CaseMenuStatusChange';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { CaseAssigneeUsersPopover } from './CaseAssigneeUsersPopover';
import { PriorityChangeModal } from './PriorityChangeModal';
import { CASES_UNREAD_BADGE_COUNT } from '../constants';
import { useEntityForm } from 'modules/entity/hooks/useEntityForm';
import { ScheduledIcon } from 'shared/components';
import { CasePriorityLabel } from './CasePriorityLabel';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			width: '36px',
			height: '36px'
		}
	})
);

export type Dep = {
	id: number;
	name: string;
	icon:
		| {
				thumbnail: string | null;
				thumbnail_150: string | null;
		  }
		| string
		| null;
};

type Props = {
	hasUnread: number | undefined;
	department: Dep;
	sub_department: Dep;
	caseTitle: string;
	last_message_type: string | null;
	last_message_text: string;
	last_message_author: {
		first_name: string;
		last_name: string;
	};
	case_number: string;
	last_message_time: number;
	caseId: number;
	pin_time: null | string;
	statusId: number;
	searchedMessageId?: number | null;
	priority: Priority;
	draftMessage: EntityDraft;
	assigneeIds: number[] | null;
	isDragging?: boolean;
	isRecentCase?: boolean;
	toggleCaseDetailsOpen?: ToggleCaseDetailsOpen;
	relatedTo: boolean | null;
	hasLinkedCase: boolean | null;
	recurringId: number | null;
	lastReadMessage?: number | null;
};

function CaseItemMarkupInner({
	hasUnread,
	department,
	sub_department,
	caseTitle,
	last_message_type,
	last_message_text,
	last_message_author,
	case_number,
	last_message_time,
	caseId,
	pin_time,
	statusId,
	searchedMessageId = null,
	priority,
	draftMessage,
	assigneeIds,
	isDragging,
	isRecentCase = false,
	toggleCaseDetailsOpen = () => {},
	relatedTo,
	recurringId,
	hasLinkedCase,
	lastReadMessage = null
}: Props) {
	const classes = useStyles();
	const { t } = useTranslation();

	const {
		open,
		handleClick,
		anchorEl,
		handleMenuItemClick,
		handleCloseMenu,
		openAssignee,
		handleClickAssignee,
		handleCloseAssignee,
		anchorElAssignee,
		handleChangeCasePriority
	} = useCaseMenu(pin_time, caseId, false, statusId, searchedMessageId);
	const { open: openPriorityModal, modalHandler: togglePriorityModal } = useWhiteModal();
	const { userData } = useAuth();
	const { history, location } = useRouter();

	const { changeUrlParams, query, removeMultipleParams } = useQueryParams<{
		case: number;
		status: number;
		searchedMessageId?: number;
		reloadStatusId?: number;
		reloadCases?: boolean;
	}>();
	const { hasUnreadFilter, selectedFilterTemplate, andFilterType } = useFilterTemplateState();
	const queryCaseId = query?.case ? parseInt(query?.case as string) : null;
	const isFromReAssignmentPage = query?.reAssign === 'true';

	const {
		setChatUuid,
		setActiveDraft,
		unreadMessagesCount,
		caseType,
		chatsMentionList,
		scheduledMessageIds
	} = useAppState();

	const hasMentions = chatsMentionList?.[caseId]?.length;
	const hasScheduledMessages = scheduledMessageIds?.[caseId]?.length;

	const entityName = `AllBusinessCases-${statusId}-${caseType}-${
		selectedFilterTemplate ? selectedFilterTemplate.id : 'normal'
	}`;

	const { handleLocalDelete } = useEntityForm({
		deleteData: true,
		method: 'delete',
		url: '',
		entity: 'cases',
		name: entityName,
		cb: {
			success: () => {},
			error: () => {},
			finally: () => {}
		}
	});

	const isActive =
		query &&
		(query.searchedMessageId
			? Number(query.searchedMessageId) === searchedMessageId
			: Number(query.case) === caseId);

	const isCurrentUserAssigned = assigneeIds?.includes(userData.id);

	const onCaseItemClick = useCallback(() => {
		const isInCasePage = location.pathname.includes('/case-messages');

		if (!isInCasePage) {
			history.push('/case-messages');
		}

		//Remove case from UI if there is selected case filter template which has unread filter
		if (queryCaseId) {
			const noUnreadLeftForThisCase = !unreadMessagesCount?.[queryCaseId];
			if (noUnreadLeftForThisCase && hasUnreadFilter && andFilterType) {
				handleLocalDelete(queryCaseId, 'cases', entityName);
			}
		}

		changeUrlParams(
			{
				status: statusId,
				case: caseId,
				...{ ...(query.reloadCases ? { reloadStatusId: statusId } : {}) }, // https://clck.ru/34j6WK
				...{ ...(query.reloadStatusId ? { reloadStatusId: statusId } : {}) }, // https://clck.ru/34j6WK
				...{ ...(searchedMessageId ? { searchedMessageId } : {}) },
				...{
					...(lastReadMessage && hasUnread && !searchedMessageId
						? { unread: lastReadMessage, hasUnreadMessage: true, lastReadMessage }
						: {})
				}
			},
			true
		);
		setChatUuid(nanoid());
		setActiveDraft(draftMessage);
	}, [
		statusId,
		caseId,
		searchedMessageId,
		lastReadMessage,
		hasUnread,
		draftMessage,
		changeUrlParams,
		setActiveDraft,
		unreadMessagesCount,
		queryCaseId
	]);

	return (
		<div
			className={clsx(
				'relative flex h-full w-full rounded-xl pl-5 pt-3 pr-4  pb-4 font-poppins  dark:bg-kdark-lighter',
				{ 'bg-primary-300 dark:bg-primary-300': isActive },
				{ 'bg-kgrey-bg': !isActive },
				{ 'bg-primary-200': isDragging }
			)}
		>
			<div
				className={clsx('group w-9/12 cursor-pointer pr-2 transition  hover:opacity-50', {
					'pointer-events-none': isFromReAssignmentPage
				})}
				onClick={onCaseItemClick}
			>
				<div className="mb-7 flex gap-x-2">
					{!(priority === 'NORMAL') ? <CasePriorityLabel priority={priority} /> : null}

					{hasScheduledMessages ? <ScheduledIcon /> : null}
				</div>

				<div className="case-info mb-2.5 flex items-start">
					<div className="case-info__img h-9 w-9">
						<Avatar
							src={getDepartmentIcon({ department, sub_department }) as string}
							letter={
								sub_department
									? sub_department?.name?.[0].toUpperCase()
									: department?.name?.[0]?.toUpperCase()
							}
							alt={sub_department?.name}
							classes={{ root: classes.root }}
						/>
					</div>
					<div className="mx-2.5 overflow-hidden ">
						<div
							className="case-title mb-2 truncate font-semibold transition-colors dark:text-white"
							title={caseTitle}
						>
							{caseTitle}
						</div>
						<div className="case-dep mb-1.5 truncate text-xs leading-normal text-kgrey-menuText">
							{department?.name}
						</div>
						<div className="case-subdep truncate text-xs leading-none text-kgrey">
							{sub_department?.name}
						</div>
					</div>
				</div>
				<div className="case-msg-row">
					{last_message_author ? (
						<LastMessageView
							last_message_type={last_message_type}
							last_message_text={last_message_text}
							last_message_author={last_message_author}
							draftMessage={draftMessage}
						/>
					) : null}
				</div>
			</div>
			<div className="flex w-3/12 flex-col">
				<div className="top-numbers mb-3 flex items-start justify-between">
					<div className="case-number text-sm font-semibold leading-none text-kdark-text transition-colors dark:text-white">
						{`#${case_number}`}
					</div>
					{isRecentCase || isFromReAssignmentPage ? null : (
						<div
							className="more-btn -mt-2 -mr-3 w-4 cursor-pointer py-2 px-5 text-right text-kgrey-menuText transition-colors hover:text-primary dark:text-white dark:hover:text-primary"
							onClick={handleClick}
						>
							<IconVertical className={'inline'} />
						</div>
					)}
					{open ? (
						<Menu
							id="long-menu"
							anchorEl={anchorEl}
							keepMounted
							open={open}
							onClose={handleCloseMenu}
						>
							<MenuItem
								onClick={() => {
									handleCloseMenu();
									toggleCaseDetailsOpen(caseId);
								}}
							>
								{t('case_details')}
							</MenuItem>
							<MenuItem
								onClick={() => {
									handleChangeCasePriority(caseId, statusId);
									togglePriorityModal();
								}}
							>
								{t('change_priority')}
							</MenuItem>
							<CaseMenuStatusChange
								anchorEl={anchorEl}
								handleCloseMenu={handleCloseMenu}
								statusId={statusId}
								caseId={caseId}
							/>
							<MenuItem
								onClick={() => {
									handleCloseMenu();
									handleMenuItemClick(pin_time ? 'unpin' : 'pin');
								}}
							>
								{pin_time ? t('unpin_case') : t('pin_case')}
							</MenuItem>
							<MenuItem
								onClick={() => {
									handleCloseMenu();
									handleMenuItemClick('manager');
								}}
							>
								{t('request_update')}
							</MenuItem>
							<WhiteModal open={openPriorityModal} handleModalOpen={togglePriorityModal}>
								<PriorityChangeModal
									onSuccess={() => {
										togglePriorityModal();
										handleCloseMenu();
										removeMultipleParams(['caseId', 'statusId', 'fromCaseItem']);
									}}
								/>
							</WhiteModal>
						</Menu>
					) : null}
				</div>
				<div className="date text-sm text-kgrey">{time.toLastTime(last_message_time)}</div>
				{!!assigneeIds?.length && (
					<div className="flex-center-y mt-auto justify-end leading-none text-kgrey-menuText">
						<CaseAssigneeUsersPopover
							caseId={caseId}
							anchorElAssignee={anchorElAssignee}
							assigneeIds={assigneeIds}
							handleClickAssignee={handleClickAssignee}
							handleCloseAssignee={handleCloseAssignee}
							openAssignee={openAssignee}
							isCurrentUserAssigned={isCurrentUserAssigned}
						/>
					</div>
				)}

				{[pin_time, relatedTo, recurringId, hasLinkedCase, hasUnread, hasMentions].filter(Boolean)
					.length ? (
					<div className="feature-buttons mt-auto flex items-center justify-end leading-none text-kgrey-menuText">
						{pin_time ? (
							<div className="px-1">
								<IconPin className={'text-kgrey-menuText'} />
							</div>
						) : null}
						{relatedTo ? (
							<div className="px-1">
								<TbCopy />
							</div>
						) : null}
						{recurringId ? (
							<div className="px-1">
								<BiCalendar />
							</div>
						) : null}
						{hasLinkedCase ? (
							<div className="px-1">
								<BiLink />
							</div>
						) : null}
						{hasUnread ? (
							<div
								className={clsx(
									'ml-2 h-6 w-6 flex-shrink-0 rounded-full bg-primary text-center text-sm font-semibold leading-6 text-white',
									{
										'w-fit px-1': hasUnread >= CASES_UNREAD_BADGE_COUNT
									}
								)}
							>
								{hasUnread}
							</div>
						) : null}
						{hasMentions ? (
							<div className=" px-1 text-lg text-primary">
								<SiMaildotru />
							</div>
						) : null}
					</div>
				) : null}
			</div>
		</div>
	);
}

export function CaseItemMarkup(props: Props) {
	return (
		<ErrorBoundary isCompact>
			<CaseItemMarkupInner {...props} />
		</ErrorBoundary>
	);
}
