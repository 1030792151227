import React, { useState } from 'react';
import { CaseDataField } from 'shared/interfaces';
import { KntButton } from 'shared/components';
import { CaseChatDataFieldRow } from '../../CaseChatDataFieldRow';
import { useTranslation } from 'react-i18next';

type Props = {
	dataFields: CaseDataField;
};

export function CdDataFields({ dataFields }: Props) {
	const [dataFieldsVisible, setDataFieldsVisible] = useState(false);
	const { t } = useTranslation();

	const toggleDataFields = () => setDataFieldsVisible((prev) => !prev);

	return (
		<>
			{dataFields ? (
				<div className="my-4 w-full">
					<>
						<KntButton
							color={dataFieldsVisible ? 'primary' : 'default'}
							className="mb-4"
							onClick={toggleDataFields}
						>
							{t('show_data_fields')}
						</KntButton>

						{dataFieldsVisible
							? Array.isArray(dataFields)
								? dataFields.map(([field, value]) => {
										return <CaseChatDataFieldRow field={field} value={value} key={field} />;
								  })
								: Object.keys(dataFields).map((field) => {
										return (
											<CaseChatDataFieldRow field={field} value={dataFields[field]} key={field} />
										);
								  })
							: null}
					</>
				</div>
			) : (
				<p>{t('no_data_fields')}</p>
			)}
		</>
	);
}
