import React, { useEffect, useState } from 'react';
import { useAppState } from 'shared/state';
import { useAxios, useNotistack } from 'shared/hooks';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import AsyncSelectJs from 'shared/components/Fields/AsyncSelectJs';
import { KntTwilioStyle } from 'shared/services';
import { isMobile } from 'react-device-detect';
import config from 'config';
import { Field } from 'formik';
import { CaseDetailsRow } from './CaseDetailsRow';
import { Skeleton } from 'shared/components/Elements';
import { useTranslation } from 'react-i18next';

type Props = {
	setState: any;
	department: any;
	sub_department: any;
	depHierarchy: any;
	setFieldValue: any;
	subDepsForHierarchy: any;
	consistedSubDeps: any;
	values: any;
	recurringId: number | null;
	setMembers: any;
	caseId: number;
	setAssignedUsers: any;
	setAssignRequired: any;
};

export function CdDepartment({
	setState,
	department,
	sub_department,
	depHierarchy,
	setFieldValue,
	subDepsForHierarchy,
	consistedSubDeps,
	values,
	recurringId,
	setMembers,
	setAssignedUsers,
	caseId,
	setAssignRequired
}: Props) {
	const [loadingDepartments, setLoadingDeparments] = useState(true);
	const { companyId, caseType } = useAppState();
	const { showNotification } = useNotistack();
	const { t } = useTranslation();

	//Loading Departments hierarchy;
	const { fetchData: fetchDepartment } = useAxios({
		url: `/${companyId}/departments/${get(department, 'id')}/sub_departments/`,
		cb: {
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			success: (data) => {
				if (data?.results.length) {
					setState((prev: any) => ({
						...prev,
						depHierarchy: {
							id: department.id, //Because we must load first level departments sub departments
							name: department.name,
							sub_department: {
								id: department.id,
								name: 'Please Select Sub Departments'
							}
						}
					}));
				}
				setLoadingDeparments(false);
			},
			error: (error, additionalArgs) => {
				console.log('department load error', { response: error.response, department });
				setLoadingDeparments(false);
			}
		}
	});

	//Loading Departments hierarchy;
	const { fetchData: fetchHierarchy } = useAxios({
		url: `/${companyId}/departments/${get(sub_department, 'id')}/hierarchy/`,
		cb: {
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			success: (data) => {
				setState((prev: any) => ({ ...prev, depHierarchy: data }));
				setLoadingDeparments(false);
			},
			error: async (error, additionalArgs) => {
				console.log('hierarchy error', { response: error.response, department });
			}
		}
	});

	//Loading sub departments hierarchy or show fetch department to define whether it has sub department
	//if the department has sub department we show Sub department select to load departments
	//if there is no sub_departments we don't show Sub department select
	useEffect(() => {
		if (sub_department) {
			setAssignRequired(sub_department?.assign_required);
		} else {
			setAssignRequired(department?.assign_required);
		}
		(async () => {
			if (sub_department) {
				await fetchHierarchy();
			} else {
				await fetchDepartment();
			}
		})();
	}, []);

	useEffect(() => {
		return () => {
			setLoadingDeparments(true);
		};
	}, []);

	useEffect(() => {
		if (!isEmpty(depHierarchy) && setState) {
			iterateNestedSubDepartments(depHierarchy);
		}
	}, [depHierarchy, setState]);

	const iterateNestedSubDepartments = (obj: any) => {
		function buildSubDeps(prev: any, key: string) {
			const outerDepList = Object.keys(prev.subDepsForHierarchy).filter(
				(id: string) => Number(id) < Number(obj[key].id)
			);

			if (!isEmpty(obj[key])) {
				return {
					...prev.subDepsForHierarchy,
					[obj[key].id]: {
						...obj[key],
						department:
							Object.keys(prev.subDepsForHierarchy).length > 0
								? {
										id: Number(outerDepList[outerDepList.length - 1])
								  }
								: { id: obj.id }
					}
				};
			} else if (isEmpty(obj[key])) {
				return {
					...prev.subDepsForHierarchy,
					//Just to show select which indicates that there sub department in this department
					10000: {
						id: 1,
						name: 'Please Select Sub Departments',
						department:
							Object.keys(prev.subDepsForHierarchy).length > 0
								? {
										id: Number(outerDepList[outerDepList.length - 1])
								  }
								: { id: obj.id }
					}
				};
			}
		}

		Object.keys(obj).forEach((key) => {
			if (key === 'sub_department' && obj[key] !== null) {
				setState((prev: any) => {
					return {
						...prev,
						subDepsForHierarchy: buildSubDeps(prev, key),
						consistedSubDeps: buildSubDeps(prev, key)
					};
				});
			}
			if (typeof obj[key] === 'object' && obj[key] !== null) {
				iterateNestedSubDepartments(obj[key]);
			}
		});
	};

	const { fetchData: depChangeAccessListRequest } = useAxios({
		url: '',
		// values: '',
		dynamicValues: true,
		dynamicUrl: true,
		method: 'post',
		cb: {
			success: (data, args) => {
				setMembers(data);
			},
			error: (error, args) => {
				const [arg] = args;
				showNotification({
					message: error.code,
					variant: 'error'
				});
				setMembers(arg.previousMembersSet);
			}
		}
	});

	//TODO: isDisabled: recurring
	const onDepChange = (option: any, setFieldValue: any) => {
		if (option) {
			const assignRequired = option.assign_required;
			const defaultAssignees = option.default_assignees;
			if (option.sub_departments_count > 0) {
				setState((prev: any) => ({
					...prev,
					subDepsForHierarchy: {},
					depHierarchy: {
						id: option.id,
						name: option.name,
						sub_department: {
							id: 1,
							name: 'Please Select Sub Departments',
							department: {
								id: option.id
							}
						}
					},
					clearDepartment: false
				}));
			} else {
				setState((prev: any) => ({
					...prev,
					depHierarchy: {},
					subDepsForHierarchy: {}
				}));
				setTimeout(() => {
					setFieldValue('department', option);
				}, 0);
			}
			if (assignRequired && defaultAssignees.length) {
				setAssignedUsers(defaultAssignees);
			} else {
				setAssignedUsers([]);
			}
			setAssignRequired(assignRequired);
		} else {
			setState((prev: any) => ({
				...prev,
				depHierarchy: {},
				subDepsForHierarchy: {},
				clearDepartment: true
			}));
		}
	};

	const onSubDepChange = (option: any, subdep: any) => {
		//When we select any option
		if (option) {
			for (const subdepId in subDepsForHierarchy) {
				//Selecting the current option value
				if (Number(subdepId) === Number(subdep)) {
					/*If selected sub department does not have sub sub department
																										We delete all other saved option s and replace current option value
																									*/
					if (option.sub_departments.length === 0) {
						const obj = { ...subDepsForHierarchy };

						Object.keys(obj).forEach((subdepId) => {
							if (Number(subdepId) > Number(subdep)) {
								delete obj[subdepId];
							}
						});
						delete obj[subdep];

						setState((prev: any) => ({
							...prev,
							subDepsForHierarchy: {
								...obj,
								[option.id]: option
							},
							consistedSubDeps: {
								...obj,
								[option.id]: option
							}
						}));
						/*
							if subdep has sub sub departments, delete all other options
							and save current subdeps sub department
						*/
					} else {
						const obj = { ...subDepsForHierarchy };
						Object.keys(obj).forEach((subdepId) => {
							if (Number(subdepId) > Number(subdep)) {
								delete obj[subdepId];
							}
						});
						delete obj[subdep];

						setState((prev: any) => ({
							...prev,
							subDepsForHierarchy: {
								...obj,
								[option.id]: option,
								[get(option, 'sub_departments[0].id')]: null
							},
							consistedSubDeps: {
								...obj,
								[option.id]: option,
								[get(option, 'sub_departments[0].id')]: null
							}
						}));
					}
				}
			}
			setAssignRequired(option?.assign_required);
			//When we delete the option
		} else {
			const obj = { ...subDepsForHierarchy };
			const subDepHierarchyArray = Object.keys(obj);
			for (const subdepId in subDepsForHierarchy) {
				if (Number(subdepId) === Number(subdep) || Number(subdepId) > Number(subdep)) {
					Object.keys(obj).forEach((subdepId) => {
						if (Number(subdepId) > Number(subdep)) {
							delete obj[subdepId];
						}
					});
				}
			}
			setState((prev: any) => ({
				...prev,
				subDepsForHierarchy: {
					...obj,
					[subdep]: null
				}
			}));
			setAssignRequired(obj[subDepHierarchyArray[0]]?.assign_required);
		}
	};

	const fetchAndUpdateAccessList = (computedDepartmentId: number) => {
		depChangeAccessListRequest({
			url: `/${companyId}/cases/as_business/${caseId}/case_access_list/`,
			values: { department: { id: computedDepartmentId } }
		});
	};
	const getComputedDepartmentId = (option: { id: number } | null, index: number) => {
		let computedDepartmentId: number;
		if (option) {
			computedDepartmentId = option.id;

			//If sub dep is removed by click x button
		} else {
			const subDepKeys = Object.keys(subDepsForHierarchy);
			const parentSubDepId = subDepKeys[index - 1];
			const parentSubDepartment = get(subDepsForHierarchy, `${parentSubDepId}`, null);

			//if there is any sub department above removed one
			if (parentSubDepartment) {
				computedDepartmentId = parentSubDepartment.id;
			} else {
				//get list of members by top most department id(if no sub departments remained)
				computedDepartmentId = depHierarchy.id;
			}
		}

		return computedDepartmentId;
	};

	return (
		<div className="react-select-dropdown">
			{loadingDepartments ? (
				<Skeleton />
			) : (
				<CaseDetailsRow fieldName={t('department')}>
					{
						<Field
							component={AsyncSelectJs}
							customStylesProps={KntTwilioStyle}
							name="department"
							placeholder={t('select_department')}
							optionLabel="name"
							optionValue="id"
							isSearchable={!isMobile}
							isDisabled={recurringId}
							isCached
							openMenuOnFocus
							loadOptionsKey="results"
							loadOptionsUrl={`/${companyId}/departments_compact/`}
							loadOptionsParams={(search: string) => ({
								extra: {
									search,
									is_staff: caseType === config.STAFF ? 'true' : 'false',
									ordering: 'name'
								}
							})}
							onChange={(option: any) => {
								setFieldValue('sub_department', undefined);
								onDepChange(option, setFieldValue);

								// Fetch new list of case members access list
								if (option?.id) {
									fetchAndUpdateAccessList(option?.id);
								}
							}}
						/>
					}
				</CaseDetailsRow>
			)}
			{loadingDepartments ? (
				<Skeleton />
			) : (
				<>
					{Object.keys(subDepsForHierarchy).map((subdep, index) => {
						return (
							<CaseDetailsRow fieldName={t('sub_department')} key={subdep}>
								<Field
									component={AsyncSelectJs}
									customStylesProps={KntTwilioStyle}
									name={`dep_hierarchy[${subdep}]`}
									isDisabled={recurringId}
									placeholder={t('select_sub_department')}
									optionLabel="name"
									optionValue="id"
									isSearchable={!isMobile}
									isClearable
									loadOptionsKey="results"
									loadOptionsUrl={`/${companyId}/departments/${
										get(consistedSubDeps, `${subdep}.department.id`, 0) ||
										Object.keys(values.dep_hierarchy)[
											Object.keys(values.dep_hierarchy).length - 2
										] ||
										get(values, 'department.id')
									}/sub_departments/`}
									loadOptionsParams={(search: any) => ({
										extra: {
											search,
											ordering: 'name'
										}
									})}
									onChange={(option: any) => {
										onSubDepChange(option, subdep);

										// Fetch new list of case members access list (taking into consideration removing a sub department too)
										const computedDepartmentId = getComputedDepartmentId(option, index);
										fetchAndUpdateAccessList(computedDepartmentId);
									}}
								/>
							</CaseDetailsRow>
						);
					})}
				</>
			)}
		</div>
	);
}
