import React from 'react';
import AsyncSelectJs from 'shared/components/Fields/AsyncSelectJs';
import { KntTwilioStyle } from 'shared/services';
import config from 'config';
import { Field } from 'formik';
import { useAppState } from 'shared/state';
import { useTranslation } from 'react-i18next';

export function CdStatus() {
	const { companyId, caseType } = useAppState();
	const { t } = useTranslation();
	return (
		<Field
			component={AsyncSelectJs}
			customStylesProps={KntTwilioStyle}
			name="status"
			optionLabel="title"
			optionValue="id"
			isSearchable
			isCached
			placeholder={t('select_status')}
			reverse={true}
			loadOptionsUrl={`/${companyId}/my/company/case_statuses/`}
			loadOptionsParams={(search: string) => ({
				extra: {
					search,
					is_staff: caseType === config.STAFF ? 'true' : ''
				}
			})}
			loadOptionsKey=""
		/>
	);
}
