import { useSelector } from 'react-redux';
import * as authSelectors from '../selectors';
import { getCompany, getUserCompanies } from '../../user/selectors';
import { IRootState } from '../../../shared/interfaces';

export const useAuth = () => {
	const isAuthentificated = useSelector((state) => authSelectors.getUserIsAuth(state));
	const token = useSelector((state) => authSelectors.getUserToken(state));
	const isFetched = useSelector((state) => authSelectors.getUserIsFetched(state));
	const userData = useSelector((state) => authSelectors.getUserData(state));
	const company = useSelector((state: IRootState) => getCompany(state));
	const companies = useSelector((state: IRootState) => getUserCompanies(state));

	return {
		isAuthentificated,
		token,
		isFetched,
		userData,
		company,
		companies
	};
};
