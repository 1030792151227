import React from 'react';
import clsx from 'clsx';

type Props = {
	isActive?: boolean;
	handler?: () => void;
	classes?: string;
};

export const IconWrapper: React.FC<Props> = ({
	isActive = false,
	classes = '',
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	handler = () => {},
	children
}) => {
	return (
		<div
			className={clsx(
				'icon text-grey flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-sm bg-kgrey-icon-bg transition-colors group-hover:text-primary dark:bg-kgrey-bg/10 dark:text-white/50',
				classes,
				isActive && 'text-kdark-text'
			)}
			onClick={handler}
		>
			{children}
		</div>
	);
};
