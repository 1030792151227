import { useJsApiLoader } from '@react-google-maps/api';
import config from 'config';

const apiKey = config.GMAP_API_KEY;
const librariesArray = ['places'];
export function useGoogleMapsInit() {
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: apiKey as string,
		// @ts-ignore
		libraries: librariesArray,
		language: 'en'
	});

	return {
		isLoaded
	};
}
