import React from 'react';
import clsx from 'clsx';
import { useRouter } from '../hooks';
import { useAppState } from '../state';
import { Trans, useTranslation } from 'react-i18next';
import { useTranslationHelpers } from 'shared/hooks/useTranslationHelpers';

type Props = {
	type?: 'default' | 'warning';
	timeLeft: {
		days: number;
		hours: number;
		minutes: number;
	};
	handleShowWidget: () => void;
};

export function BillingWidget({ type = 'default', timeLeft, handleShowWidget }: Props) {
	const { t } = useTranslation();
	const { isRTL } = useTranslationHelpers();
	const { history } = useRouter();
	const { isAppAvailable } = useAppState();
	const navigateToCheckout = () => history.push('/payments/billing');
	const timeLeftInfo = ` ${timeLeft.days} ${t('days')} ${
		timeLeft.days === 0 ? `${timeLeft.hours} ${t('hours')} ${timeLeft.minutes} ${t('minutes')}` : ''
	}`;
	return (
		<div className="b-widget mb-4 px-5">
			<div
				className={clsx('b-widget__content  rounded-10 p-2.5', {
					'bg-kgrey-widget-bg': type === 'default',
					'bg-kred-billing-warn': type === 'warning'
				})}
			>
				<div className="b-widget__trial-info mb-8 text-center text-xs text-kgrey">
					{timeLeft.days <= 0 ? (
						<>{t('upgrade_your_business')}</>
					) : (
						<Trans
							i18nKey="billing_widget_info"
							values={{
								timeLeftInfo
							}}
							components={{
								timeLeftInfoComponent: <strong className="font-bold text-primary-dark" />
							}}
						/>
					)}
				</div>
				<div className="b-widget__bottom flex-center-between">
					{isAppAvailable ? (
						<div
							onClick={() => handleShowWidget()}
							className="b-widget__dismiss cursor-pointer text-xs text-kgrey-menuText transition hover:text-primary-dark"
						>
							{t('dismiss')}
						</div>
					) : null}

					<div
						className={clsx(
							'b-widget__upgrade cursor-pointer text-xs text-primary-dark underline hover:no-underline',
							{ 'ml-auto': !isRTL, 'mr-auto': isRTL }
						)}
						onClick={navigateToCheckout}
					>
						{t('upgrade_plan')}
					</div>
				</div>
			</div>
		</div>
	);
}
