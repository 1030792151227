import { useAuth } from 'modules/auth/hooks/useAuth';
import { Button, TextField } from 'shared/components/Elements';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { Loader } from 'shared/components';
import List from '@material-ui/core/List';
import { useAxios, useNotistack } from 'shared/hooks';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import { Scrollbars } from 'react-custom-scrollbars-2';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Actions from 'store/actions';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

type Languagetype = {
	id: string;
	name_en: string;
	family: string;
	name_native: string;
	iso: string;
};

type Props = {
	modalHandler: () => void;
};

export function ChangeContentLanguage({ modalHandler }: Props) {
	const { t } = useTranslation();
	const { userData } = useAuth();
	const [query, setQuery] = useState('');
	const [selectedLanguage, setSelectedLanguage] = useState<Languagetype>(
		userData?.content_translation_language
	);
	const [searchQuery] = useDebounce(query, 500);
	const { showNotification } = useNotistack();
	const dispatch = useDispatch();

	const {
		response: languageResponse,
		loading,
		fetchData: fetchLanguages
	} = useAxios({
		url: '/translation/languages/',
		params: {
			extra: {
				search: searchQuery
			}
		},
		initialLoad: true
	});

	const { fetchData: updateUserSettings, loading: isUpdateUserSettingLoading } = useAxios({
		url: '/my/profile/',
		method: 'put',
		values: {
			content_translation_language: selectedLanguage.id,
			username: userData?.username,
			first_name: userData?.first_name,
			last_name: userData?.last_name
		},
		cb: {
			success: (data) => {
				dispatch(
					Actions.auth.GetMe.success({
						data
					})
				);
				showNotification({
					message: t('translation_language_changed_success'),
					variant: 'success'
				});

				modalHandler();
			},
			error: () => {
				showNotification({
					message: t('something_went_wrong'),
					variant: 'error'
				});
			}
		}
	});

	useEffect(() => {
		fetchLanguages();
	}, [searchQuery]);

	const languageList = languageResponse ? (languageResponse as Array<Languagetype>) : [];

	function handleUpdateUserSettings() {
		updateUserSettings();
	}

	return (
		<div className="language-select">
			<div className="language-select__wrapper">
				<div className="language-select__search">
					<TextField
						value={query}
						fullWidth
						variant="outlined"
						size="small"
						placeholder={t('search_language')}
						onChange={(e) => {
							setQuery(e.target.value);
						}}
					/>
				</div>
				<Scrollbars className="rct-scroll" autoHide style={{ height: 400 }}>
					<List className="language-select__list" dense>
						{languageList.map((language, idx) => (
							<ListItem
								key={language.id}
								button={true}
								className="language-select__item !pl-2"
								onClick={() => setSelectedLanguage(language)}
							>
								<ListItemText primary={language.name_en} />
								{selectedLanguage && selectedLanguage.id === language.id && (
									<>
										<ListItemSecondaryAction>
											<IconButton edge="end" aria-label="checked">
												<CheckCircleOutlineIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</>
								)}
							</ListItem>
						))}
						{!loading && !languageList.length && searchQuery.length && (
							<div className="flex h-60 items-center justify-center">
								{t('no_matching_language_found')}
							</div>
						)}
						{loading ? (
							<div className="flex items-center justify-center">
								<Loader />
							</div>
						) : null}
					</List>
				</Scrollbars>
				<div className="language-select__buttons flex-center-end">
					<Button onClick={modalHandler} variant="outlined" className="!mr-4" type="button">
						{t('cancel')}
					</Button>
					<Button
						variant="contained"
						type="button"
						disabled={!selectedLanguage || isUpdateUserSettingLoading}
						onClick={handleUpdateUserSettings}
						className="hover:!bg-primary hover:!text-white"
					>
						{isUpdateUserSettingLoading ? <Loader size={12} /> : t('save')}
					</Button>
				</div>
			</div>
		</div>
	);
}
