import { CaseType } from '../../state/reducer';

type GetCaseEntityName = {
	statusId: number;
	caseType: CaseType;
	searchQuery: string;
	selectedFilterTemplate: { id: number } | null;
};
export function getCaseEntityName({
	statusId,
	caseType,
	searchQuery,
	selectedFilterTemplate
}: GetCaseEntityName) {
	return `AllBusinessCases-${statusId}-${caseType}-${
		searchQuery.length
			? 'search'
			: `${selectedFilterTemplate ? selectedFilterTemplate.id : 'normal'}`
	}`;
}
