import React from 'react';
import { RepliedMSgType, ScrollToMsg } from 'shared/interfaces';
import clsx from 'clsx';
import { RepliedMsg } from './RepliedMsg';

type Props = {
	replied_to: RepliedMSgType;
	isOwnMsg?: boolean;
	file_type?: string;
	setScrollToMsgId?: ScrollToMsg;
	noBg?: boolean;
	children: React.ReactNode;
	additionalClasses?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const RepliedMediaMsgWrapper = React.memo(function RepliedMediaMsgWrapper({
	replied_to,
	isOwnMsg,
	children,
	file_type = '',
	setScrollToMsgId,
	additionalClasses,
	...divProps
}: Props) {
	return (
		<div
			{...divProps}
			className={clsx(
				{
					'rounded-lg border border-primary p-2 lg:p-4 ': replied_to,
					'bg-white/60 dark:bg-transparent': !isOwnMsg && file_type !== 'VIDEO'
				},
				additionalClasses
			)}
		>
			<RepliedMsg replied_to={replied_to} setScrollToMsgId={setScrollToMsgId} />
			{children}
		</div>
	);
});

RepliedMediaMsgWrapper.displayName = 'RepliedMediaMsgWrapper';
