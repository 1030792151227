import React, { useMemo } from 'react';
import { Emojies } from '../../components';

export function useCachedReactedUsers({ reacted_users, file_type, isEmojiCountOpenHandler }: any) {
	const cachedReactedUsers = useMemo(() => {
		return reacted_users;
	}, [reacted_users]);

	const cachedReactions = useMemo(() => {
		return (
			<Emojies.MessageReactions
				fileType={file_type}
				reactedUsers={cachedReactedUsers}
				clickHandler={isEmojiCountOpenHandler}
			/>
		);
	}, [isEmojiCountOpenHandler, cachedReactedUsers]);

	return { cachedReactions };
}
