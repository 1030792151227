import { combineReducers } from 'redux';
import { IRootState /*, IAction*/ } from 'shared/interfaces';

import authReducers from '../../modules/auth/reducers';
import userReducers from '../../modules/user/reducers';
import entityReducers from '../../modules/entity/reducers';
import entitiesReducers from './entities';
import systemReducers from './system';

const moduleReducers = { auth: authReducers, user: userReducers, entity: entityReducers };
const reducers = { entities: entitiesReducers, system: systemReducers };

export const rootReducer = combineReducers({
	...moduleReducers,
	...reducers
});

// console.log('rootReducer', rootReducer);

export default (state: IRootState, action: any) => {
	return action.type === 'LOGOUT/SUCCESS'
		? // @ts-ignore
		  rootReducer(undefined, action)
		: // @ts-ignore
		  rootReducer(state, action);
};

// export default (state: IRootState, action: IAction) =>
// 	action.type === 'LOGOUT/SUCCESS' ? rootReducer(undefined, action) : rootReducer(state, action);
