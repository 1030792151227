import React from 'react';
type Props = {
	className?: string;
};

export function IconFilterDelete({ className = '' }: Props) {
	return (
		<svg
			width="14"
			height="15"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M5.444 10.177V7.882M8.556 10.177V7.882"
				stroke="currentColor"
				strokeWidth="1.2"
				strokeLinecap="round"
			/>
			<path
				d="M0 4h14c-.31 0-.464 0-.594.017a2 2 0 0 0-1.722 1.722c-.017.13-.017.285-.017.594v3.608c0 1.886 0 2.829-.586 3.414-.586.586-1.529.586-3.414.586H6.333c-1.885 0-2.828 0-3.414-.586-.586-.585-.586-1.528-.586-3.414V6.333c0-.31 0-.464-.017-.594A2 2 0 0 0 .594 4.017C.464 4 .31 4 0 4Z"
				fill="currentColor"
				fillOpacity=".2"
				stroke="currentColor"
				strokeWidth="1.2"
				strokeLinecap="round"
			/>
			<path
				d="M5.498 1.283c.088-.08.283-.153.555-.204C6.325 1.028 6.658 1 7 1c.343 0 .675.028.947.079.272.051.467.123.556.204"
				stroke="currentColor"
				strokeWidth="1.2"
				strokeLinecap="round"
			/>
		</svg>
	);
}
