import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

type Props = {
	isTextMessage: boolean;
	isOwnMsg: boolean;
	clickHandler: () => void;
	isTranslated: boolean;
};
export function TranslateBtn(props: Props) {
	const { t } = useTranslation();
	const { isTextMessage, isOwnMsg, clickHandler, isTranslated } = props;
	return (
		<>
			{isTextMessage ? (
				<button
					className={clsx(
						'translate-button ml-auto text-primary  transition hover:text-primary-dark',
						{
							'mr-24': !isOwnMsg,
							'mr-2': isOwnMsg,
							'opacity-0': !isTranslated
						}
					)}
					onClick={clickHandler}
				>
					{isTranslated ? t('show_original_text') : t('translate')}
				</button>
			) : null}
		</>
	);
}
