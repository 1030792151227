import React, { useRef, useState, useEffect } from 'react';
import { useChatInputContext } from 'shared/contexts/ChatInputContext';
import { useChatUpload } from './useChatUpload';
import { useChatHelpers } from './useChatHelpers';
import { EmojiData } from 'emoji-mart';
import { useChatContext } from '../../contexts';
import { MentionsRef } from 'rc-mentions/es/Mentions';

export function useChatInput(
	handleFormSubmit: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void
) {
	const inputRef = useRef<MentionsRef>(null);
	const [localTime, setLocalTime] = useState(0);
	const {
		message,
		isDelayedMessage,
		setMessage,
		setTime,
		timerId,
		setTimerId,
		setCommand,
		setCancel,
		cancel,
		setIsDelayedMessage,
		editingMessage,
		messageScheduleDate,
		handleScheduleModalToggle
	} = useChatInputContext();
	const { uploadAudioFile } = useChatUpload({ isDragging: false });
	const { insertAtCursor } = useChatHelpers();
	const [tempAudioFile, setTempAudioFile] = useState<any>(null);

	const { isLastPageFetched, isScheduledChat } = useChatContext();

	useEffect(() => {
		setTime(localTime);
	}, [localTime]);

	useEffect(() => {
		//!isDelayedMessage - waiting for the download of last page messages
		if (!isDelayedMessage && tempAudioFile) {
			uploadAudioFile(tempAudioFile);
			setTempAudioFile(null);
		}
	}, [isDelayedMessage]);

	useEffect(() => {
		if (isScheduledChat && messageScheduleDate && tempAudioFile) {
			uploadAudioFile(tempAudioFile);
			setTempAudioFile(null);
		}
	}, [messageScheduleDate]);

	const focusInInput = () => {
		inputRef.current && inputRef.current.focus();
	};

	const handlePressKey = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
			if (e.target instanceof HTMLTextAreaElement) {
				const { selectionStart, selectionEnd } = e.target;

				const newValue =
					message.substring(0, selectionStart) + '\n' + message.substring(selectionEnd);
				setMessage(newValue);

				if (inputRef.current?.textarea) {
					inputRef.current.textarea.value = newValue;
					inputRef.current.textarea.selectionStart = inputRef.current.textarea.selectionEnd =
						selectionStart + 1;
				}
			}
			return;
		}
		if (e.keyCode === 13 && e.shiftKey) {
			return;
		}
		if (e.key === 'Enter') {
			e.preventDefault();
			handleFormSubmit(e);
			return;
		}
	};

	const onEmojiClick = (event: EmojiData) => {
		// @ts-ignore
		const { native } = event;

		insertAtCursor(inputRef?.current?.textarea, native);
		if (inputRef?.current && inputRef?.current?.textarea?.value)
			setMessage(inputRef.current?.textarea?.value);

		focusInInput();
	};

	const handleStartRecordAudio = () => {
		// useInterval();
		const timerId = setInterval(() => {
			setLocalTime((time) => time + 1000);
		}, 1000);

		setTimerId(timerId);
		setCommand('start');
		setTime(0);
	};

	const handleStopRecordAudio = () => {
		clearInterval(timerId);
		setCancel(false);
		setCommand('stop');
		// if (isEditing) {
		// 	setEditMsgType({ msgType: 'fileMsg', fileType: 'AUDIO' });
		// }
		setLocalTime(0);
	};

	const handleCancelRecord = () => {
		setCancel(true);
		clearInterval(timerId);
		setCommand('stop');
		setLocalTime(0);
	};

	const handleOnStopRecording = (blob: any) => {
		if (!cancel) {
			if (isScheduledChat && !messageScheduleDate && !editingMessage) {
				handleScheduleModalToggle();
				setTempAudioFile(blob);
				return;
			}

			if (!isLastPageFetched && !editingMessage) {
				setIsDelayedMessage(true);
				setTempAudioFile(blob);
			} else {
				void uploadAudioFile(blob);
			}
		}
		setCommand(null);
	};

	return {
		inputRef,
		handlePressKey,
		handleStartRecordAudio,
		handleStopRecordAudio,
		handleCancelRecord,
		handleOnStopRecording,
		onEmojiClick
	};
}
