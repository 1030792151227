import get from 'lodash/get';
import uniq from 'lodash/uniq';
import isEqual from 'lodash/isEqual';
import isArray from 'lodash/isArray';
import { IAction, IMetaAll } from 'shared/interfaces';
import {
	DecrementEntityTotal,
	EntityContTypes,
	FormActionFailure,
	FormActionRequest,
	FormActionSuccess,
	IncrementEntityTotal,
	LoadAllActionFailure,
	LoadAllActionRequest,
	LoadAllActionSuccess,
	LoadOneActionFailure,
	LoadOneActionRequest,
	LoadOneActionSuccess,
	ResetEntityList
} from './actions';

type IEntityConState = {
	[entity: string]: {
		[name: string]: {
			ids?: (number | string)[];
			isFetched?: boolean;
			meta?: IMetaAll;
			error?: Error;
			id?: number | string;
			params?: Record<string, any>;
		};
	};
};

const initialState: IEntityConState = {
	all: {
		someName: {
			ids: [],
			isFetched: false,
			meta: {}
		}
	}
};

function filterDuplicate(oldIds: (string | number)[], newIds: (string | number)[]) {
	return newIds.filter((id) => !oldIds.includes(id));
}

export default (state = initialState, action: IAction<EntityContTypes>): IEntityConState => {
	switch (action.type) {
		case EntityContTypes.LOAD_ALL_ENTITY_REQUEST: {
			const { entity, name } = (action as LoadAllActionRequest).payload;
			return {
				...state,
				[entity]: {
					...get(state, entity, {}),
					[name]: {
						...get(state, `${entity}.${name}`, {}),
						isFetched: false
					}
				}
			};
		}

		case EntityContTypes.LOAD_ALL_ENTITY_SUCCESS: {
			const {
				entity,
				name,
				ids,
				meta,
				appendData,
				prependData,
				params: { page, ...params },
				// params,
				infiniteScroll,
				isUniq,
				replaceIds,
				cursorBased
			} = (action as LoadAllActionSuccess).payload;

			const isNewParams = !isEqual(params, get(state, `${entity}.${name}.params`, {}));
			const prevIds = get(state, `${entity}.${name}.ids`, []) as [];
			const stateCurrentPage = get(state, `${entity}.${name}.meta.current_page`, 1);
			const nextCurrentPage = meta.current_page;

			const currentFirstItemIndex = replaceIds
				? (meta.count as number)
				: (get(state, `${entity}.${name}.meta.firstItemIndex`, meta.count) as number);

			const DEFAULT_PAGE_SIZE = 10;

			const computedFirstItemIndex = Math.max(
				currentFirstItemIndex - (meta?.response_length || params.limit || DEFAULT_PAGE_SIZE),
				0
			);

			const nextIds =
				(isNewParams && !cursorBased) || replaceIds
					? [...ids]
					: nextCurrentPage === 1 && !cursorBased
					? [...prevIds, ...filterDuplicate(prevIds, ids)]
					: appendData
					? [...prevIds, ...filterDuplicate(prevIds, ids)]
					: prependData
					? [...filterDuplicate(prevIds, ids), ...prevIds]
					: [...ids];

			let newMeta = {};
			if (meta) {
				newMeta = {
					...meta,
					//if we fetch pages that doesnt have data in some cases,
					// we put last fetched total count from state bcs there is no count in those pages. case: 3350
					count:
						Number(meta.current_page) > Number(meta.last_page) && Number(meta.count) > 0
							? Math.max(
									Number(meta.count),
									get(state, `${entity}.${name}.meta.count`, meta.count) as number
							  )
							: meta.count,
					currentPage:
						!infiniteScroll ||
						isNewParams ||
						(typeof meta.current_page === 'number' && meta.current_page > stateCurrentPage)
							? meta.current_page
							: stateCurrentPage,
					firstItemIndex: appendData ? currentFirstItemIndex : computedFirstItemIndex
				};
			}

			return {
				...state,
				[entity]: {
					...get(state, entity, {}),
					[name]: {
						...get(state, `${entity}.${name}`, {}),
						ids: isUniq ? uniq(nextIds) : nextIds,
						meta: newMeta,
						params,
						isFetched: true
					}
				}
			};
		}

		case EntityContTypes.LOAD_ALL_ENTITY_FAILURE: {
			const { entity, name, error } = (action as LoadAllActionFailure).payload;
			return {
				...state,
				[entity]: {
					...get(state, entity, {}),
					[name]: {
						...get(state, `${entity}.${name}`, {}),
						error,
						isFetched: !(error?.message === 'Search has been cancelled')
					}
				}
			};
		}

		case EntityContTypes.LOAD_ONE_ENTITY_REQUEST: {
			const { entity, name } = (action as LoadOneActionRequest).payload;

			return {
				...state,
				[entity]: {
					...get(state, entity, {}),
					[`${name}One`]: {
						...get(state, `${entity}.${name}One`, {}),
						isFetched: false
					}
				}
			};
		}

		case EntityContTypes.LOAD_ONE_ENTITY_SUCCESS: {
			const { id, entity, name } = (action as LoadOneActionSuccess).payload;
			return {
				...state,
				[entity]: {
					...get(state, entity, {}),
					[`${name}One`]: {
						...get(state, `${entity}.${name}One`, {}),
						id,
						isFetched: true
					}
				}
			};
		}

		case EntityContTypes.LOAD_ONE_ENTITY_FAILURE: {
			const { entity, name, error } = (action as LoadOneActionFailure).payload;
			return {
				...state,
				[entity]: {
					...get(state, entity, {}),
					[`${name}One`]: {
						...get(state, `${entity}.${name}One`, {}),
						error,
						isFetched: true
					}
				}
			};
		}

		case EntityContTypes.FORM_ENTITY_REQUEST: {
			const { entity, name } = (action as FormActionRequest).payload;

			return {
				...state,
				[entity]: {
					...get(state, entity, {}),
					[name]: {
						...get(state, `${entity}.${name}`, {})
						// isFetched: false
					}
				}
			};
		}

		case EntityContTypes.FORM_ENTITY_SUCCESS: {
			const { id, entity, name, appendData, prependData, updateData, deleteData } = (
				action as FormActionSuccess
			).payload;

			const newIds = isArray(id) ? [...id] : [id];

			const prevIds: any = get(state, `${entity}.${name}.ids`, []);
			const nextIds = appendData
				? [...prevIds, ...newIds]
				: prependData
				? [...newIds, ...prevIds]
				: [...newIds];

			return {
				...state,
				[entity]: {
					...get(state, entity, {}),
					[name]: {
						...get(state, `${entity}.${name}`, {}),
						isFetched: true,
						ids: updateData
							? prevIds
							: deleteData
							? prevIds.filter((sid: number): boolean => sid !== id)
							: uniq(nextIds)
					}
				}
			};
		}

		case EntityContTypes.FORM_ENTITY_FAILURE: {
			const { entity, name, error } = (action as FormActionFailure).payload;

			return {
				...state,
				[entity]: {
					...get(state, entity, {}),
					[name]: {
						...get(state, `${entity}.${name}`, {}),
						error,
						isFetched: true
					}
				}
			};
		}
		case EntityContTypes.INCREMENT_ENTITY_TOTAL: {
			const { entityName, entity } = (action as IncrementEntityTotal).payload;

			return {
				...state,
				[entity]: {
					...get(state, entity, {}),
					[entityName]: {
						...get(state, `${entity}.${entityName}`, {}),
						meta: {
							...get(state, `${entity}.${entityName}.meta`, {}),
							count: (get(state, `${entity}.${entityName}.meta.count`, 0) as number) + 1
						}
					}
				}
			};
		}
		case EntityContTypes.DECREMENT_ENTITY_TOTAL: {
			const { entityName, entity } = (action as DecrementEntityTotal).payload;

			return {
				...state,
				[entity]: {
					...get(state, entity, {}),
					[entityName]: {
						...get(state, `${entity}.${entityName}`, {}),
						meta: {
							...get(state, `${entity}.${entityName}.meta`, {}),
							count: (get(state, `${entity}.${entityName}.meta.count`, 0) as number) - 1
						}
					}
				}
			};
		}

		case EntityContTypes.RESET_ENTITY_LIST: {
			const { entityName, entity } = (action as ResetEntityList).payload;

			return {
				...state,
				[entity]: {
					...get(state, entity, {}),
					[entityName]: {
						...get(state, `${entity}.${entityName}`, {}),
						ids: [],
						meta: undefined
					}
				}
			};
		}
		default:
			return state;
	}
};
