import React from 'react';
import clsx from 'clsx';
export interface ButtonProps
	extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
		React.AriaAttributes {
	color?: 'primary' | 'secondary' | 'default';
	size?: 'default' | 'bigger';
	shape?: 'rounded' | 'square';
	fullWidth?: boolean;
	additionalClasses?: string;
}

export const NewButton: React.FC<ButtonProps> = (props) => {
	const {
		children,
		color = 'default',
		size = 'default',
		shape = 'rounded',
		fullWidth = false,
		className,
		...rest
	} = props;

	return (
		<button
			className={clsx(
				'transition disabled:!pointer-events-none disabled:!bg-kgrey disabled:!bg-kgrey-menuText/10 disabled:!text-kdark',
				{
					'bg-kgrey-bg text-kdark hover:bg-kgrey': color === 'default',
					'h-7 text-13px leading-7': size === 'default',
					'h-[38px] text-sm leading-[38px]': size === 'bigger',
					'rounded-full': shape === 'rounded',
					'rounded-10': shape === 'square',
					'px-5': !fullWidth && size === 'default',
					'w-full': fullWidth,
					'bg-kred-red-200 text-kred-red hover:bg-kred-red-400': color === 'secondary',
					'border border-transparent bg-primary-dark text-white hover:border-primary-dark hover:bg-white hover:text-primary-dark':
						color === 'primary'
				},
				className
			)}
			{...rest}
		>
			{children}
		</button>
	);
};
