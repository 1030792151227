import React from 'react';
import { ChatMessagesLoader } from './ChatMessagesLoader';
import Loader from '../../Loader';
import clsx from 'clsx';

export const VirtuosoScrollWrapper = React.forwardRef<any, any>(
	({ children, context, ...props }, ref) => {
		const { isFetched, items, isSetSearchReply, direction, replyLoading } = context;

		return (
			<div ref={ref} {...props}>
				{/*Initial loader*/}
				{!isFetched && items.length === 0 && !isSetSearchReply && <ChatMessagesLoader />}
				{/*Loader when paginating up*/}
				<>
					{!isFetched &&
						!isSetSearchReply &&
						items.length > 0 &&
						(direction === 'next' || direction === null) && (
							<div className="chat-loader absolute left-1/2 -translate-x-1/2 ">
								<Loader />
							</div>
						)}
				</>

				{(!isFetched || replyLoading) && isSetSearchReply ? (
					<ChatMessagesLoader />
				) : (
					<>
						<div className={clsx('virtuoso-wrapper')} id="">
							{/*Messages start*/}
							{children}
							{/*Messages end*/}
						</div>
					</>
				)}
			</div>
		);
	}
);

VirtuosoScrollWrapper.displayName = 'VirtuosoScrollWrapper';
