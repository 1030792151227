import * as React from 'react';

function SvgIconRemove(props: any) {
	return (
		<svg
			id="minus-sign"
			xmlns="http://www.w3.org/2000/svg"
			width="11"
			height="11"
			viewBox="0 0 11 11"
			{...props}
		>
			<path
				id="Path_10256"
				data-name="Path 10256"
				d="M80.969,135.77H77.523a.586.586,0,0,0,0,1.171h3.446a.586.586,0,0,0,0-1.171Z"
				transform="translate(-73.746 -130.856)"
				fill="#fff"
			/>
			<path
				id="Path_10257"
				data-name="Path 10257"
				d="M5.5,0A5.5,5.5,0,1,0,11,5.5,5.5,5.5,0,0,0,5.5,0Zm0,9.857A4.56,4.56,0,0,1,1.143,5.5,4.56,4.56,0,0,1,5.5,1.143,4.56,4.56,0,0,1,9.857,5.5,4.56,4.56,0,0,1,5.5,9.857Z"
				fill="#fff"
			/>
		</svg>

		// <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" {...props}>
		// 	<path
		// 		data-name="Exclusion 2"
		// 		d="M64 121.899c-35.293 0-64-27.34-64-60.945S28.707.001 64 .001s64 27.344 64 60.953-28.715 60.945-64 60.945zm0-109.707c-28.234 0-51.2 21.875-51.2 48.762s22.969 48.754 51.2 48.754 51.2-21.871 51.2-48.754S92.23 12.192 64 12.192zm19.215 54.855H44.797a6.256 6.256 0 01-6.395-6.094 6.258 6.258 0 016.395-6.1h38.418a6.255 6.255 0 016.383 6.1 6.253 6.253 0 01-6.382 6.094z"
		// 		fill="#fff"
		// 	/>
		// </svg>
	);
}

export default SvgIconRemove;
