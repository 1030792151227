import { newTemplateMockData } from '../filter-components/filterMockData';
import { FilterTemplateType } from '../../../shared/typings';
import { useAppState } from '../../../shared/state';
import { useCallback, useState } from 'react';

export function useCaseFilterFunctions() {
	const { setActiveFilterTemplate, activeFilterTemplate } = useAppState();
	const [isImmediateApply, setIsImmediateApply] = useState(false);
	const [showDeletePopup, setShowDeletePopup] = useState<FilterTemplateType | null>(null);
	const addNewFilterTemplate = () => {
		setActiveFilterTemplate(newTemplateMockData as unknown as FilterTemplateType);
	};

	const saveAndApply = useCallback(() => setIsImmediateApply(true), []);

	const appendFilterToTemplate = useCallback(
		(filterObject) => {
			if (activeFilterTemplate && Array.isArray(activeFilterTemplate.fields_json)) {
				setActiveFilterTemplate({
					...activeFilterTemplate,
					fields_json: [...activeFilterTemplate.fields_json, filterObject]
				});
			}
		},
		[activeFilterTemplate]
	);

	const removeFilterItemFromTemplate = useCallback(
		(name) => {
			if (activeFilterTemplate && Array.isArray(activeFilterTemplate.fields_json)) {
				setActiveFilterTemplate({
					...activeFilterTemplate,
					fields_json: activeFilterTemplate.fields_json.filter(
						(filterItem) => filterItem.name !== name
					)
				});
			}
		},
		[activeFilterTemplate]
	);

	const handleOnTemplateClick = useCallback((template) => {
		setActiveFilterTemplate(template);
	}, []);

	const handleDeleteTemplate = useCallback(
		(item: FilterTemplateType | null) => setShowDeletePopup(item),
		[]
	);

	return {
		addNewFilterTemplate,
		isImmediateApply,
		saveAndApply,
		appendFilterToTemplate,
		removeFilterItemFromTemplate,
		handleOnTemplateClick,
		handleDeleteTemplate,
		showDeletePopup
	};
}
