import React from 'react';
import clsx from 'clsx';
import { useTranslationHelpers } from 'shared/hooks/useTranslationHelpers';

export interface ButtonProps
	extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
		React.AriaAttributes {}

export const SimpleBtn: React.FC<ButtonProps> = ({ children, disabled, className, ...rest }) => {
	const { isRTL } = useTranslationHelpers();
	return (
	<button
			disabled={disabled}
			className={clsx(
				'inline-block rounded-10 bg-kgrey-bg py-2 px-4 text-base  disabled:opacity-60 dark:bg-primary-300 dark:text-white',
				{
					'hover:bg-primary-200 dark:hover:bg-primary-200': !disabled,
					'ml-5': !isRTL,
					'mr-5': isRTL
				},
				className
			)}
			{...rest}
		>
			<div className={clsx({ 'cursor-not-allowed': disabled })}>{children}</div>
		</button>
	);
};
