import * as React from 'react';

function SvgIconEdit(props: any) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="15.999"
			height="16"
			viewBox="0 0 15.999 16"
			{...props}
		>
			<path
				d="M9.61,16a1.658,1.658,0,0,1-1.18-.489L.245,7.326A.84.84,0,0,1,0,6.737V1.669A1.671,1.671,0,0,1,1.67,0H6.736a.832.832,0,0,1,.589.242L9.979,2.9l2.767,2.768,2.765,2.764a1.672,1.672,0,0,1,0,2.36l-2.288,2.288L10.79,15.512A1.658,1.658,0,0,1,9.61,16ZM2.5,1.669a.836.836,0,0,0-.835.835V6.217a.42.42,0,0,0,.122.3L9.02,13.741a.836.836,0,0,0,1.18,0L13.741,10.2a.837.837,0,0,0,0-1.18L6.513,1.791a.419.419,0,0,0-.3-.122ZM4.016,4.851a.835.835,0,1,1,.835-.835A.836.836,0,0,1,4.016,4.851Z"
				transform="translate(0 0)"
				fill="#fff"
			/>
		</svg>
	);
}

export default SvgIconEdit;
