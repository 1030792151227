import EntityContainer from 'modules/entity/containers';
import { KntScrollBars, Loader } from '../../index';
import { useAppState } from '../../../state';
import { CaseType } from '../../../../pages/Cases/components/CaseItem';
import RecentCaseItem from './RecentCaseItem';
import { ErrorBoundary } from 'shared/components';

export function RecentCasesDropdownInner() {
	const { companyId } = useAppState();
	return (
		<div>
			<KntScrollBars className="rct-scroll" style={{ height: '400px' }} autoHide>
				<EntityContainer.All
					entity="recent-cases"
					name={`AllRecentCases-${companyId}`}
					url={`/${companyId}/widgets/cases/as_business/`}
					params={{
						limit: 10,
						page: 1,
						extra: {
							ordering: 'last_view_time'
						}
					}}
					replaceIds
				>
					{({ items, isFetched }) => {
						const caseItems = [...items] as CaseType[];

						return (
							<>
								{caseItems.map((caseItem) => {
									return (
										<RecentCaseItem
											key={caseItem.id}
											caseData={caseItem}
											// isFetched={isFetched}
											// isDragging={false}
										/>
									);
								})}
								{!isFetched && (
									<div style={{ textAlign: 'center' }}>
										<Loader />
									</div>
								)}
							</>
						);
					}}
				</EntityContainer.All>
			</KntScrollBars>
		</div>
	);
}

export function RecentCasesDropdown() {
	return (
		<ErrorBoundary>
			<RecentCasesDropdownInner />
		</ErrorBoundary>
	);
}
