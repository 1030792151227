import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export function useChangeLayoutDirection() {
	const { i18n } = useTranslation();

	useEffect(() => {
		// Check the current language
		const currentLang = i18n.language;

		// Set the 'dir' attribute based on the language
		if (currentLang === 'AR') {
			document.documentElement.setAttribute('dir', 'rtl');
		} else {
			document.documentElement.setAttribute('dir', 'ltr');
		}
	}, [i18n.language]);
}
