import React from 'react';
import { IMessage, ScrollToMsg } from 'shared/interfaces';
import { useMessageFields } from 'shared/hooks/chat-hooks/useMessageFields';
import clsx from 'clsx';
import { RepliedMsg } from './RepliedMsg';
import { useChatHelpers, useWhiteModal } from 'shared/hooks';
import { HtmlParser } from 'shared/components';
import isEqual from 'lodash/isEqual';
import { EmojiCountInfo } from './EmojiCountInfo';
import { useCachedReactedUsers } from 'shared/hooks/chat-hooks/useCachedReactedUsers';
import { ActivationButtons } from './ActivationButtons';
import { useChatContext } from 'shared/contexts';
import { DeletedMsg } from './DeletedMsg';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { replaceUsernameToFullname } from '../utils';
import { useAppState } from 'shared/state';

type Props = {
	message: IMessage;
	setScrollToMsgId: ScrollToMsg | undefined;
	toggleEmojiPicker: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

function areEqual(prev: any, next: any) {
	return (
		prev.message?.is_deleted === next.message?.is_deleted &&
		prev.message.text === next.message.text &&
		prev.setScrollToMsgId === next.setScrollToMsgId &&
		prev.toggleEmojiPicker === next.toggleEmojiPicker &&
		isEqual(prev.message.reacted_users, next.message.reacted_users) &&
		isEqual(prev.message.settings, next.message.settings) &&
		isEqual(prev.message?.replied_to, next.message?.replied_to)
	);
}

export const TextMsg = React.memo(function TextMsg({ message, setScrollToMsgId }: Props) {
	const { is_deleted, isOwnMsg, text, replied_to, reacted_users, messageId, file_type, settings } =
		useMessageFields(message);
	const { renderTextMessage } = useChatHelpers();
	const { userData } = useAuth();
	const { isDarkMode } = useAppState();
	const {
		open: isEmojiCountOpen,
		modalHandler: isEmojiCountOpenHandler,
		WhiteModal: EmojiWhiteModal
	} = useWhiteModal();

	const { cachedReactions } = useCachedReactedUsers({
		reacted_users,
		file_type,
		isEmojiCountOpenHandler
	});
	const { mentionUsersList } = useChatContext();

	const isThereActivationLink = settings && 'activation' in settings;
	const sanitizedMentionMsg = replaceUsernameToFullname(text, mentionUsersList, userData, true);

	if (is_deleted) {
		return <DeletedMsg isOwnMsg={isOwnMsg} type="text" />;
	}

	return (
		<div
			className={clsx(
				'break-words border border-kgrey-chatBorder px-5 py-4 text-sm text-kdark shadow-text-msg dark:text-white',
				{
					'rounded-b-xl rounded-tr-xl bg-white dark:bg-white/20': !isOwnMsg,
					'rounded-t-xl rounded-bl-xl bg-primary-200': isOwnMsg,
					'pb-2': reacted_users?.length
				}
			)}
		>
			<RepliedMsg replied_to={replied_to} setScrollToMsgId={setScrollToMsgId} />
			{text.includes('<') ? (
				<div
					className={clsx({ 'invitation-message': isDarkMode })}
					dangerouslySetInnerHTML={{ __html: text }}
				/>
			) : (
				<div className="whitespace-pre-wrap">
					{renderTextMessage(sanitizedMentionMsg, HtmlParser)}
				</div>
			)}
			{isThereActivationLink ? (
				<ActivationButtons
					acceptLink={settings?.activation?.accept}
					message={message}
					rejectLink={settings?.activation?.cancel}
				/>
			) : null}
			<div className="pr-2">{reacted_users?.length ? cachedReactions : null}</div>
			<EmojiWhiteModal handleModalOpen={isEmojiCountOpenHandler} open={isEmojiCountOpen}>
				<EmojiCountInfo messageId={messageId} reactedUsers={reacted_users} />
			</EmojiWhiteModal>
		</div>
	);
}, areEqual);
