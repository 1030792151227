import { useEffect } from 'react';
import { useQueryParams } from '../useQueryParams';

export function useEscapePressed() {
	const { removeQueryParams, removeOneQueryParam } = useQueryParams();

	const { query } = useQueryParams();
	const isActive = query.isMediaActive;

	useEffect(() => {
		const keyPressHandler = (e: KeyboardEvent) => {
			const pressedKey = e.key;

			if (pressedKey === 'Escape') {
				if (isActive) {
					removeOneQueryParam('isMediaActive');
				} else {
					removeQueryParams();
				}
			}
		};

		document.addEventListener('keydown', keyPressHandler);

		return () => {
			document.removeEventListener('keydown', keyPressHandler);
		};
	}, [isActive, removeOneQueryParam, removeQueryParams]);
}
