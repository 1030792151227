import React from 'react';
import Select from 'react-select';
import { DropdownIndicator } from './DropdownIndicator';
import {
	CASE_TYPE_OPTIONS,
	CLIENT_TYPE,
	STAFF_TYPE,
	caseTypeSelectCustomStyles,
	MyOptionType
} from './CaseTypeSelectMocksAndTypes';
import config from 'config';
import { useAppState } from '../../../state';
import { CaseType } from '../../../state/reducer';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { useQueryParams } from '../../../hooks';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

export function CaseTypeSelect() {
	const { caseType, setCaseType } = useAppState();
	const { removeQueryParams, query } = useQueryParams();
	const { t } = useTranslation();

	const handleCaseTypeSwitch = (option: MyOptionType | null) => {
		//If any case is open, we must close it before we switch case type
		if (!isEmpty(query)) {
			removeQueryParams();
		}

		if (option) {
			setCaseType(option.value as CaseType);
		}
	};

	//option label should be in localization
	const calculatedOptions = CASE_TYPE_OPTIONS.map((option) => ({
		...option,
		label: t(option.label.toLowerCase())
	}));

	return (
		<div
			className={clsx('react-select-dropdown', { 'ml-4 w-24': !isMobile, 'ml-2 w-14': isMobile })}
		>
			<Select
				className="case-type-select-container"
				classNamePrefix="case-type-select"
				options={calculatedOptions}
				components={{ DropdownIndicator }}
				value={calculatedOptions.find((option) => option.value === caseType)}
				onChange={(option) => {
					handleCaseTypeSwitch(option);
				}}
				isSearchable={false}
				styles={caseTypeSelectCustomStyles}
			/>
		</div>
	);
}
