import { useEffect } from 'react';
import Actions from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useAppState } from 'shared/state';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { storage } from 'shared/services';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import { SelectedCaseFilter } from 'shared/state/reducer';
import { nanoid } from 'nanoid';
import { useFCM } from './useFCM';
import { IRootState } from '../interfaces';
import { getCompany } from '../../modules/user/selectors';
import { useUnreadCount } from './useUnreadCount';
import { useCaseFilterSwitch } from './useCaseFilterSwitch';
import { useCaseController } from '../../pages/Cases/hooks/useCaseController';
import { useRouter } from './useRouter';
import { useAxios } from './useAxios';
import { useQueryParams } from './useQueryParams';
import { useTranslation } from 'react-i18next';
import { setDayJsLocale } from 'shared/services/utils/setLibLocales';
import { casesEventChannel } from 'eventChannels/cases';

const GetMeRequest = Actions.auth.GetMe.request;
const LoadUserCompanies = Actions.user.loadUserCompaniesRequest;

export function parseUnreadMessages(data: { [key: string]: number }) {
	return Object.keys(data).reduce((acc, curr) => {
		const key = curr.split('_')[1];
		return { ...acc, [key]: data[curr] };
	}, {});
}

export const useInitialRequests = () => {
	const dispatch = useDispatch();
	const {
		companyId,
		setAppDeviceUuid,
		setNetworkState,
		setSelectedCaseFilter,
		caseType,
		isDarkMode,
		setInitialStatusReverseState,
		setSettingsData,
		settingsData,
		isShowMobilePrompt,
		setIsShowMobilePrompt,
		setUnreadMessagesCount,
		setIsInternetDisconnected,
		isInternetDisconnected,
		networkState,
		setLanguage
	} = useAppState();
	const { i18n } = useTranslation();
	const { userData, isAuthentificated } = useAuth();
	const company = useSelector((state: IRootState) => getCompany(state));
	const { fetchCompanyAllUnreadCounts } = useUnreadCount();
	const { fetchCaseFilter } = useCaseFilterSwitch();
	const { fetchBusinessSettings, fetchCasesMentionList, fetchCasesScheduledMessageCount } =
		useCaseController();

	const history = useHistory();

	const { location } = useRouter();

	useAxios({
		url: '/messages/mark-as-delivered/',
		method: 'post',
		initialLoad: true
	});

	useEffect(() => {
		storage.set('darkMode', isDarkMode.toString());
	}, [isDarkMode]);

	//Show mobile prompt window
	useEffect(() => {
		if (settingsData && !settingsData?.settings_json?.isMobilePromptShown && !isShowMobilePrompt) {
			setIsShowMobilePrompt(true);
		}
	}, [settingsData, isShowMobilePrompt, setIsShowMobilePrompt]);

	useEffect(() => {
		if (isAuthentificated) {
			storage.set('caseType', caseType);

			//redirect to dashboard if we route to signin or signup page in authenticated mode
			if (location.pathname.includes('signin') || location.pathname.includes('signup')) {
				history.push('/dashboard');
			}
		}
	}, [caseType, isAuthentificated]);

	useEffect(() => {
		setAppDeviceUuid(nanoid());

		const onlineHandler = () => {
			setNetworkState('online');
		};

		const offlineHandler = () => {
			setNetworkState('offline');
			setIsInternetDisconnected(true);
		};

		window.addEventListener('online', onlineHandler);

		window.addEventListener('offline', offlineHandler);

		return () => {
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			window.removeEventListener('online', onlineHandler);
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			window.removeEventListener('offline', offlineHandler);
		};
	}, []);

	useEffect(() => {
		let timerId: ReturnType<typeof setTimeout>;
		if (isInternetDisconnected && networkState === 'online') {
			//1) set isInternetDisconnected to false
			//2) re-fetch unread cases
			//3) re-fetch unread message
			//4) re-fetch cases

			setIsInternetDisconnected(false);

			setTimeout(async () => {
				// refetch cases and group unread counts. Because while internet was disconnected there might come new messages
				await fetchCompanyAllUnreadCounts();
				await fetchCasesMentionList();
				await fetchCasesScheduledMessageCount();

				//re-fetch unread cases if user is in case message page
				const isInCasesPage = location.pathname.includes('case-messages');

				//do not modify search params if user is not in cases page
				if (!isInCasesPage) {
					return;
				}

				//TODO: legacy code - remove later
				/* if (reloadUrl?.reloadCases) {
					removeMultipleParams(['reloadCases', 'reloadStatusId']);
				}

				if (reloadUrl?.forceUpdate) {
					removeOneQueryParam('forceUpdate');
				} else {
					changeUrlParams(
						{
							forceUpdate: true
						},
						!reloadUrl?.case
					);
				} */

				casesEventChannel.emit('forceUpdateCasesList');
			}, 500);
		}

		return () => {
			clearInterval(timerId);
		};
	}, [isInternetDisconnected, networkState, location]);

	// Get firebase token and send to backend to register notifications
	useFCM();

	// Get signed-in user
	useEffect(() => {
		if (storage.get('token') !== null) {
			dispatch(GetMeRequest());
		}
	}, [GetMeRequest]);

	function getSavedDataFromLS(key: string) {
		const data = storage.get(key);

		if (data) {
			return JSON.parse(data);
		}
		return null;
	}

	function saveCaseFilter(filter: SelectedCaseFilter) {
		setSelectedCaseFilter({
			filter,
			caseType: null,
			isInitialDispatch: true
		});

		if (!getSavedDataFromLS('selectedCaseFilter'))
			storage.set('selectedCaseFilter', JSON.stringify(filter));
	}
	/*
			Get unread messages count if there companyId
		 Because without them is impossible to get the data

		a) Load Case and Group Chat unread count

		b) Handling caseFilter on initial load:
	  	1. Check if there filter in LocalStorage.
	  	If yes, take from there .
	  	If no, make api call

	  	c) get settingsJSON
	*/
	useEffect(() => {
		if (companyId) {
			(async () => {
				setUnreadMessagesCount({});
				// refetch cases and group unread due to changed selected company (a)
				await fetchCompanyAllUnreadCounts();

				//get settings
				const casesReverseData = getSavedDataFromLS('casesReverseData');
				const settingsDataLS = getSavedDataFromLS('settingsData');

				if (
					casesReverseData &&
					typeof casesReverseData === 'object' &&
					!isEmpty(casesReverseData)
				) {
					setInitialStatusReverseState(casesReverseData);
				}

				//Retrieve settings from LS
				if (settingsDataLS) {
					setSettingsData(settingsDataLS);
				}
				//Fetch settings in case there are any updates
				await fetchBusinessSettings();

				//Fetch chat mention list
				await fetchCasesMentionList();

				//fetch chat scheduled message count
				await fetchCasesScheduledMessageCount();

				//Updating case filters on initial load (b)
				const caseFilterInLS = getSavedDataFromLS('selectedCaseFilter');
				if (caseFilterInLS) {
					saveCaseFilter(caseFilterInLS);
					return;
				}
				//We fetch case filter from api if no selectedFilter in LS
				await fetchCaseFilter({ url: `/${companyId}/case-filters/` });
			})();
		}
	}, [companyId]);

	// Load user companies after getme request done(we need username email)
	useEffect(() => {
		if (!isEmpty(userData) && !companyId) {
			dispatch(LoadUserCompanies());

			setLanguage(userData?.language);
			i18n.changeLanguage(userData?.language?.short_name);
			setDayJsLocale(userData?.language?.short_name);
		}
	}, [userData, companyId, i18n]);

	// Redirecting to Pending and Add business pages for new users
	useEffect(() => {
		if (company === null) {
			history.push('add-business');
		}

		if (company?.company.status === 'INACTIVE') {
			history.push('pending-page');
		}
	}, [company]);
};
