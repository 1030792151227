import React, { useCallback, useEffect, useState } from 'react';
import { ChatInputLayout } from 'shared/components/Chat/components';
import { useChatInputContext } from 'shared/contexts/ChatInputContext';
import { useChatMessage, useDraftMessage, useMessageMenu } from 'shared/hooks';
import { useChatSocket } from 'shared/contexts/ChatSocketContext';
import { useChatContext } from 'shared/contexts/ChatContext';
import { nanoid } from 'nanoid';
import { useAppState } from 'shared/state';
import { useSelector } from 'react-redux';
import Selectors from 'modules/entity/selectors';

const getOne = Selectors.getOne();

export function CaseChatInputControls() {
	const {
		message,
		setMessage,
		reply,
		setReply,
		editingMessage,
		isDelayedMessage,
		setIsDelayedMessage,
		isMentionSearching,
		messageScheduleDate,
		setMessageScheduleDate,
		handleScheduleModalToggle
	} = useChatInputContext();
	const { entityId, entityType, isLastPageFetched, isScheduledChat } = useChatContext();
	const { sendJsonMessage } = useChatSocket();
	const { sendChatMessage } = useChatMessage({
		sendJsonMessage,
		entityId,
		entityType
	});
	const { removeLS, updateEntityDraft, getLS, sendDeleteDraft } = useDraftMessage();
	const [tempMessageEvent, setTempMessageEvent] = useState<
		React.FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLTextAreaElement> | null
	>(null);
	const draftObject = getLS();
	const { activeDraft, setActiveDraft } = useAppState();
	const { pinMessage } = useMessageMenu();

	const caseChatEntity = useSelector((state) =>
		getOne(state, {
			id: entityId,
			entity: entityType === 'case' ? 'cases' : 'ims-chats',
			name: `${entityType === 'case' ? 'CaseChat' : 'Group'}-${entityId}One`
		})
	);

	const onSubmit =
		//todo: finish memoizing
		// useCallback(
		(e: React.FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLTextAreaElement>) => {
			if (isMentionSearching) {
				return;
			}

			e?.preventDefault();
			if (tempMessageEvent) setTempMessageEvent(null);

			// if (!!caseChatEntity.item?.draft_message) {
			//
			// 	removeLS(entityId, () => {
			// 		updateEntityDraft('reset');
			// 	});
			// }

			if (activeDraft || entityId in draftObject) {
				updateEntityDraft('reset');
				setActiveDraft(null);
				removeLS(entityId);
				sendDeleteDraft();
			}
			//Manually pin the message if edited message is pinned message
			if (editingMessage && editingMessage?.id === caseChatEntity?.item.pinned_message?.id) {
				pinMessage(caseChatEntity?.item.id, editingMessage.id);
			}

			sendChatMessage({
				type: 'text',
				replied_to_id: reply ? reply.id : null,
				replied_to: null,
				msgSendingChatId: entityId,
				uuid: nanoid(),
				edited: !!editingMessage,
				is_draft: !!caseChatEntity.item?.draft_message,
				scheduled_to: messageScheduleDate
			});
			setMessage('');
			setMessageScheduleDate(null);

			if (reply) setReply(null);
		};
	// 	,
	// 	[tempMessageEvent, setTempMessageEvent, activeDraft, setActiveDraft, entityId]
	// );

	//sending text message after selecting scheduling date&time in schedule chat
	useEffect(() => {
		if (isScheduledChat && messageScheduleDate && tempMessageEvent) {
			onSubmit(tempMessageEvent);
		}
	}, [messageScheduleDate]);

	//Sending message when not all initial pages have been loaded
	useEffect(() => {
		if (!isDelayedMessage && tempMessageEvent) {
			//send delayed message when page 1 messages has been loaded
			onSubmit(tempMessageEvent);
		}
	}, [isDelayedMessage]);

	const handleSubmit = useCallback(
		(e: React.FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLTextAreaElement>) => {
			e.preventDefault();
			if (isScheduledChat && !messageScheduleDate && !editingMessage && message) {
				handleScheduleModalToggle();
				setTempMessageEvent(e);
				return;
			}

			if (!isLastPageFetched && !editingMessage) {
				//if we are  in the above through scrolling to upper
				// pages, we must load page 1 message before sending message
				setIsDelayedMessage(true);
				setTempMessageEvent(e);
				return;
			}
			onSubmit(e);
		},
		[
			isLastPageFetched,
			editingMessage,
			setIsDelayedMessage,
			setTempMessageEvent,
			onSubmit,
			isScheduledChat,
			messageScheduleDate
		]
	);

	return <ChatInputLayout onFormSubmit={handleSubmit} />;
}
