import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import List from '@material-ui/core/List';
import ChatIcon from '@material-ui/icons/Chat';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ListItem } from '../Elements';
import { useAppState } from 'shared/state';
import { Paper } from '@material-ui/core';

type NavItem = {
	text: string;
	iconComponent: React.ReactNode;
	to: string;
	type: string;
};
const navItems: NavItem[] = [
	{
		text: 'Case messages',
		iconComponent: <QuestionAnswerIcon />,
		to: '/case-messages',
		type: 'asRouterLink'
	},
	{
		text: 'Group chat',
		iconComponent: <ChatIcon />,
		to: '/group-chat',
		type: 'asRouterLink'
	},
	{
		text: 'News',
		iconComponent: <ChatIcon />,
		to: '/chat',
		type: 'asRouterLink'
	},
	{
		text: 'Business Management',
		iconComponent: <ChatIcon />,
		to: '/switch-business',
		type: 'asRouterLink'
	},
	{
		text: 'Video calls',
		iconComponent: <ChatIcon />,
		to: '/chat',
		type: 'asRouterLink'
	},
	{
		text: 'Usefull links',
		iconComponent: <ChatIcon />,
		to: '/chat',
		type: 'asRouterLink'
	},
	{
		text: 'Profile',
		iconComponent: <ChatIcon />,
		to: '/chat',
		type: 'asRouterLink'
	}
];

const useStyles = makeStyles(
	createStyles({
		nullPaddings: {
			padding: 0
		},
		paperRoot: {
			height: '100%'
		}
	})
);
export function SidebarKnt() {
	const classes = useStyles();
	const { history, companyId } = useAppState();
	const onLogout = () => history.push('/logout');

	return (
		<div className="flex h-full w-72 flex-col font-poppins">
			{/*<div className="h-16 flex-shrink-0">*/}
			{/*	<LogoKnt name={'Kennekt Client'} />*/}
			{/*</div>*/}
			{/*<Paper elevation={0} square>*/}
			{/*	<div className="h-20 flex-shrink-0 flex items-center p-4">*/}
			{/*		<UserKnt*/}
			{/*			color="rgb(180, 83, 9)"*/}
			{/*			src={'https://media.istockphoto.com/vectors/boy-with-a-laptop-vector-id1220272928?s=612x612'}*/}
			{/*			firstName="Abdurahman ibn Xattab"*/}
			{/*			lastName="Maksadkulov"*/}
			{/*		/>*/}
			{/*	</div>*/}
			{/*</Paper>*/}

			<div className="flex-grow">
				<Paper elevation={0} square classes={{ root: classes.paperRoot }}>
					{companyId && (
						<Scrollbars style={{ height: '100%' }} autoHide>
							<List
								component="nav"
								aria-label="main navigation"
								classes={{ padding: classes.nullPaddings }}
							>
								{navItems.map((item: NavItem) => {
									return (
										<ListItem
											truncate
											key={item.text}
											text={item.text}
											IconComponent={item.iconComponent}
											to={item.to}
											type={item.type}
										/>
									);
								})}
							</List>
						</Scrollbars>
					)}
				</Paper>
			</div>

			<Paper elevation={0} square /*classes={{ root: classes.paperRoot }}*/>
				<div className="flex-shrink-0 cursor-pointer p-4" onClick={onLogout}>
					Logout
				</div>
			</Paper>
		</div>
	);
}
