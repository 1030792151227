import React, { useEffect, useRef, useState } from 'react';
import { Radio, RadioGroup, FormControlLabel } from 'shared/components/Elements';
import { useNotistack } from '../../../../shared/hooks';
import AsyncSelectJs from '../../../../shared/components/Fields/AsyncSelectJs';
import { KntTwilioStyle } from '../../../../shared/services';
import { getLastSubDepartmentId } from '../../helpers';
import { Field } from 'formik';
import get from 'lodash/get';
import { useAppState } from '../../../../shared/state';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useTranslationHelpers } from 'shared/hooks/useTranslationHelpers';

type Props = {
	values: any;
	assigneeTypeValue: any;
	setAssigneeTypeValue: any;
};

export function CreateCaseAssigneeUser({ values, assigneeTypeValue, setAssigneeTypeValue }: Props) {
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();
	const { isRTL } = useTranslationHelpers();

	const { companyId } = useAppState();
	const { showNotification } = useNotistack();

	const assigneeTypeValueRef = useRef('all');

	//These 2 useEffect is necessary for unmount and remount departments AsyncSelect when we change case type
	//If we do not remount this components AsyncSelect component does not refetch new assignee members due to isCached=true prop
	//If we do not set isCached to true, navigating with tabs does work properly/

	useEffect(() => {
		if (assigneeTypeValue !== assigneeTypeValueRef.current) {
			setLoading(true);
			assigneeTypeValueRef.current = assigneeTypeValue;
		}
	}, [assigneeTypeValue, values]);

	useEffect(() => {
		if (loading) {
			setLoading(false);
		}
	}, [loading]);

	return (
		<div className="react-select-dropdown mb-4 flex items-center">
			<label
				className={clsx('mb-2 block text-xs font-light text-kgrey-welcome', {
					'mr-4': !isRTL,
					'ml-4': isRTL
				})}
			>
				<span>{t('assign')}</span>
			</label>
			<div className="w-full">
				{!loading && (
					<Field
						className="w-full"
						component={AsyncSelectJs}
						customStylesProps={KntTwilioStyle}
						name="assignee_ids"
						placeholder={t('select_user_to_assign')}
						optionLabel={(option: any) =>
							`${get(option, 'user.first_name', '')} ${get(option, 'user.last_name', '')}`
						}
						optionValue="id"
						loadOptionsUrl={
							assigneeTypeValue === 'all'
								? `/${companyId}/members/`
								: `/${companyId}/departments/${getLastSubDepartmentId(
										values.department,
										values.sub_department
								  )}/users/`
						}
						loadOptionsParams={(search: string) => ({
							extra: {
								search
							}
						})}
						isSearchable
						maxMenuHeight={220}
						isClearable
						isMulti
						loadOptionsKey="results"
						isCached
						onChange={(option: any) => {}}
					/>
				)}
			</div>
		</div>
	);
}
