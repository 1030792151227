import { t } from "i18next";

export const repeat_types = [
	{
		name: 'Day',
		code: 'DAY'
	},
	{
		name: 'Week',
		code: 'WEEK'
	},
	{
		name: 'Month',
		code: 'MONTH'
	},
	{
		name: 'Year',
		code: 'YEAR'
	}
];

export const weekDays = [
	{ name: 'M', value: 'monday' },
	{ name: 'T', value: 'tuesday' },
	{ name: 'W', value: 'wednesday' },
	{ name: 'T', value: 'thursday' },
	{ name: 'F', value: 'friday' },
	{ name: 'S', value: 'saturday' },
	{ name: 'S', value: 'sunday' }
];

export const momentDays = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday'
];
export const weekOrders = ['first', 'second', 'third', 'last'];

export const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export function createDayDetails(mJsDate) {
	const currentDay = momentDays[mJsDate.day()];
	return {
		day: currentDay,
		weekOrder: Math.ceil(mJsDate.date() / 7) <= 4 ? Math.ceil(mJsDate.date() / 7) : 4
	};
}

function setRecurringText({ baseText, end_date, end_type, moment, occurrences }) {
	if (end_type === 'NEVER') {
		return `${baseText}. \n ${t('never_ends')}`;
	} else if (end_type === 'DATETIME') {
		return `${baseText} until ${moment(end_date).format('ddd, MMM DD, YYYY')}`;
	}
	return `${baseText} ${occurrences} ${occurrences > 1 ? 'times' : 'time'}`;
}

//Handle form inputs
export const stateFieldHandler = (e, handler) => {
	const {
		target: { name, value }
	} = e;

	if (typeof handler === 'function') {
		handler((prev) => ({ ...prev, [name]: value }));
	}
};

export const recurrConfigCreator = ({
	repeat_every,
	moment,
	end_type,
	end_date,
	selectedTime,
	repeat_type,
	occurrences,
	monday,
	tuesday,
	wednesday,
	thursday,
	friday,
	saturday,
	sunday,
	month_repeat_type,
	ON_DATE,
	ON_WEEK_DAY,
	month_repeat_val,
	currentDay,
	days,
	translate
}) => {
	const every = Number(repeat_every);
	let config = {
		end_date: end_type === 'DATETIME' ? end_date : null,
		on_time: moment.utc(selectedTime).format('HH:mm'),
		repeat_type,
		end_type,
		repeat_every: every,
		occurrences: end_type === 'OCCURRENCES' ? Number(occurrences) : 0
	};

	const weekRecurrDaysText =
		'' +
		(monday ? `${translate('monday')}, ` : '') +
		(tuesday ? `${translate('tuesday')}, ` : '') +
		(wednesday ? `${translate('wednesday')}, ` : '') +
		(thursday ? `${translate('thursday')}, ` : '') +
		(friday ? `${translate('friday')}, ` : '') +
		(saturday ? `${translate('saturday')}, ` : '') +
		(sunday ? `${translate('sunday')}, ` : '') +
		'';

	const computedRecurringWeekDayText = (weekDays = '') => {
		const weekDaysArray = weekDays.split(',');
		weekDaysArray.pop();

		if(weekDaysArray.length > 1) {
			return weekDaysArray.join(',')
		} 

		return weekDaysArray[0]
	}	

	switch (repeat_type) {
		case 'DAY': {
			const baseText = every > 1 ? translate('every_days', {count: every}) : translate('daily');
			config = {
				...config,
				recurring_text: setRecurringText({ baseText, end_date, end_type, moment, occurrences })
			};
			break;
		}
		case 'WEEK': {
			const baseText =
				every > 1
					? translate('repeat_every_weeks_on_date', {every, weekdayText: month_repeat_type === 'ON_DATE' ? computedRecurringWeekDayText(weekRecurrDaysText) : ''})
					: translate('weekly_on_weekday', {day: computedRecurringWeekDayText(weekRecurrDaysText)});
			config = {
				...config,
				recurring_text: setRecurringText({ baseText, end_date, end_type, moment, occurrences }),
				monday,
				tuesday,
				wednesday,
				thursday,
				friday,
				saturday,
				sunday
			};
			break;
		}
		case 'MONTH': {
			const isOnWeekDay = month_repeat_type === 'ON_WEEK_DAY';
			const monthDayOption = month_repeat_type === 'ON_DATE' ? ON_DATE : ON_WEEK_DAY;
			const baseText =
				every > 1 ? translate('every_month_on_date', {every, monthDayOption}) : translate('monthly_on_day', {day: monthDayOption});
			config = {
				...config,
				month_repeat_type,
				month_repeat_val: isOnWeekDay ? month_repeat_val : null,
				on_date: !isOnWeekDay ? moment().format('YYYY-MM-DD') : null,
				recurring_text: setRecurringText({ baseText, end_date, end_type, moment, occurrences }),
				[days[currentDay - 1]]: isOnWeekDay
			};
			break;
		}
		case 'YEAR': {
			const yearDayOption = `on ${moment().format('MMMM D')}`;
			const baseText =
				every > 1 ? translate('repeat_every_year', {every, yearDayOption}) : translate('annually', {date: yearDayOption});
			config = {
				...config,
				recurring_text: setRecurringText({ baseText, end_date, end_type, moment, occurrences }),
				on_date: moment().format('YYYY-MM-DD')
			};
			break;
		}

		default:
			return null;
	}

	return config;
};

export const createModifiedOptions = (recurringOptions, config) => {
	let clonedRecurredOptions;

	if (recurringOptions[recurringOptions.length - 2].value === 'CUSTOM_VALUE') {
		clonedRecurredOptions = [...recurringOptions];
		clonedRecurredOptions.splice(-2, 1);
	} else {
		clonedRecurredOptions = [...recurringOptions];
	}

	clonedRecurredOptions.splice(-1, 0, { name: config.recurring_text, value: 'CUSTOM_VALUE' });

	return clonedRecurredOptions;
};

export const setRecurringOption = ({
	moment,
	selectedTime,
	setRecurOption,
	recurOption,
	currentWeekday,
	currentDayObj,
	ON_WEEK_DAY,
	handler
}) => {
	const config = {
		end_date: null,
		end_type: 'NEVER',
		occurrences: null,
		repeat_every: 1,
		on_time: moment.utc(selectedTime).format('HH:mm')
	};
	if (recurOption === 'DAILY') {
		setRecurOption({
			...config,
			recurring_text: 'Kundalik',
			repeat_type: 'DAY'
		});
	}
	if (recurOption === 'WEEKLY_ON_WEEKDAY') {
		setRecurOption({
			...config,
			recurring_text: `Repeat every Week on ${currentWeekday}`,
			repeat_type: 'WEEK',
			[currentWeekday.toLowerCase()]: true
		});
	}
	if (recurOption === 'MONTHLY_ON_WEEKDAY') {
		setRecurOption({
			...config,
			month_repeat_type: 'ON_WEEK_DAY',
			month_repeat_val: currentDayObj.weekOrder,
			recurring_text: `Repeat every Month on ${ON_WEEK_DAY}`,
			repeat_type: 'MONTH',
			[currentWeekday.toLowerCase()]: true
		});
	}
	if (recurOption === 'ANNUALLY_ON_DATE') {
		setRecurOption({
			...config,
			recurring_text: `Repeat every Year on ${moment().format('MMMM D')}`,
			repeat_type: 'YEAR',
			on_date: moment().format('YYYY-MM-DD')
		});
	}
	if (recurOption === 'EVERY_WEEKDAY') {
		setRecurOption({
			...config,
			friday: true,
			monday: true,
			thursday: true,
			tuesday: true,
			wednesday: true,
			recurring_text: 'Repeat every Week on Monday, Tuesday, Wednesday, Thursday, Friday',
			repeat_type: 'WEEK'
		});
	}
	if (recurOption === 'CUSTOM') handler((prev) => ({ ...prev, showCustomRecurring: true }));
};


export const setDropdownOptions = ({
	handler,
	moment,
	currentWeekday,
	ON_WEEK_DAY,
	editMode = false,
	customValue = {},
	translate
}) => {
	const options = [
		{
			name: translate('daily'),
			value: 'DAILY',
			text: `${translate('daily')}. \n ${translate('never_ends')}`
		},
		{
			name: translate('weekly_on_weekday', {day: currentWeekday}),
			value: 'WEEKLY_ON_WEEKDAY',
			text: `${translate('weekly_on_weekday', {day: currentWeekday})}. \n ${translate('never_ends')}`
		},
		{
			name: translate('monthly_on_weekday', {day: ON_WEEK_DAY}),
			value: 'MONTHLY_ON_WEEKDAY',
			text: `${translate('monthly_on_weekday', {day: ON_WEEK_DAY})}. \n ${translate('never_ends')}`

		},
		{
			name: translate('annually_on_date', {date: moment().format('MMMM D')}),
			value: 'ANNUALLY_ON_DATE',
			text: `${translate('annually_on_date', {date: moment().format('MMMM D')})}. \n ${translate('never_ends')}`
		},
		{
			name: translate('every_weekday'),
			value: 'EVERY_WEEKDAY',
			text: `${translate('every_weekday')}. \n ${translate('never_ends')}`
		},
		{
			name: translate('custom'),
			value: 'CUSTOM'
		}
	];

	if (editMode) options.splice(-1, 0, customValue);

	handler((prev) => ({
		...prev,
		recurringOptions: options
	}));
};

export function convertUTCDateToLocalDate(date) {
	const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

	const offset = date.getTimezoneOffset() / 60;
	const hours = date.getHours();

	newDate.setHours(hours - offset);

	return newDate;
}
