import React from 'react';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import { TabProps } from '@material-ui/core';
import { isMobile } from 'react-device-detect';

type StyledTabProps = {
	label: string;
	component: React.ReactNode;
	to: string;
};

export const KennektTab = withStyles((theme: Theme) => {
	return createStyles({
		root: {
			fontWeight: 500,
			fontFamily: 'Poppins',
			fontSize: `${isMobile ? '13' : '16'}px`,
			borderRadius: '10px',
			transition: 'all, .2s',
			textTransform: 'none',
			lineHeight: 1,
			paddingTop: '12px',
			paddingBottom: '12px',
			color: theme.palette.type === 'dark' ? 'white' : '#000',
			minHeight: '0',
			'&:hover': {
				backgroundColor: 'rgb(0, 208, 189, .2)'
			},
			'&$selected': {
				backgroundColor: 'rgb(0, 208, 189, .2)'
			},
			'&:not(:last-child)': {
				marginRight: '20px'
			}
		},
		selected: {}
	});
})((props: TabProps & StyledTabProps) => <Tab {...props} />);
