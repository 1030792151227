import { useSelector } from 'react-redux';
import Selectors from 'modules/entity/selectors';
const getAll = Selectors.getAll();
const getOne = Selectors.getOne();

type EntityType = {
	entityId?: number | string;
	entity: string;
	entityName: string;
};

export function useGetEntity({ entityId = 0, entity, entityName }: EntityType) {
	const all = useSelector((state) =>
		getAll(state, {
			id: entityId,
			entity,
			name: entityName
		})
	);

	const one = useSelector((state) =>
		getOne(state, {
			id: entityId,
			entity,
			name: entityName
		})
	);

	return { one, all };
}
