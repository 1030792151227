/** @jsxImportSource @emotion/react */
import { FC } from 'react';
import clsx from 'clsx';
import { css } from '@emotion/react';
import { Scrollbars } from 'react-custom-scrollbars-2';

type ComponentProps = {
	title: string;
	wrapperContentClasses?: string;
	hasFixedContentHeight?: boolean;
	fixedHeight?: number;
};
type Props = FC<ComponentProps>;

export const FilterWrapper: Props = ({
	title,
	fixedHeight,
	hasFixedContentHeight,
	wrapperContentClasses = '',
	children
}) => {
	return (
		<div className="filter-wrapper rounded-lg">
			<div
				className={clsx(
					'filter-wrapper__head rounded-t-lg bg-kgrey-bg px-5 py-3 text-base font-medium leading-none',
					'dark:bg-kdark-lighter'
				)}
			>
				{title}
			</div>
			<div
				className={clsx(
					'filter-wrapper__content min-h-[400px] rounded-b-lg bg-kgrey-light-grey-2 py-4 pl-5 pr-2',
					'dark:bg-kdark-dark333',
					{ 'pr-2': hasFixedContentHeight, 'pr-6': !hasFixedContentHeight },
					wrapperContentClasses
				)}
				css={css`
					height: ${hasFixedContentHeight ? fixedHeight + 'px' : 'auto'};
				`}
			>
				{hasFixedContentHeight ? (
					<Scrollbars
						className="rct-scroll"
						style={{ height: fixedHeight ? fixedHeight - 60 + 'px' : 'auto' }}
						autoHide
					>
						{children}
					</Scrollbars>
				) : (
					<div>{children}</div>
				)}
			</div>
		</div>
	);
};
