import clsx from 'clsx';
import { useTranslationHelpers } from 'shared/hooks/useTranslationHelpers';
import { useAppState } from 'shared/state';
import { Calendar } from 'react-date-range';
import { uz, ru, enUS, arSA } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Button } from 'shared/components/Elements';
import { TimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

const getLocale = (language: string) => {
	switch (language) {
		case 'UZ': {
			return uz;
		}
		case 'RU': {
			return ru;
		}
		case 'EN': {
			return enUS;
		}
		case 'AR': {
			return arSA;
		}
	}
};

type Props = {
	schedulingDate: Date;
	schedulingTime: {
		hour: number;
		minute: number;
	};
	scheduleError: {
		error: boolean;
		message: string;
	} | null;
	handleDateChange: (date: any) => void;
	handleTimeChange: (date: MaterialUiPickersDate) => void;
};

export default function ScheduleDatePicker(props: Props) {
	const { isRTL } = useTranslationHelpers();
	const { isDarkMode } = useAppState();
	const { i18n, t } = useTranslation();

	const {
		scheduleError,
		schedulingDate,
		schedulingTime,
		handleDateChange,
		handleTimeChange
	} = props;

	return (
		<div className="schedule-custom-date__wrapper">
			<div className="schedule-custom-date__header text-xl font-semibold">
				{t('choose_date_and_time')}
			</div>
			<div className="schedule-custom-date__content flex justify-between">
				<div
					className={clsx({ 'w-[100%]': isRTL, 'w-[70%]': !isRTL }, { 'dark-theme': isDarkMode })}
				>
					<Calendar
						locale={getLocale(i18n.language)}
						classNames={{
							selected: '!text-primary-dark'
						}}
						date={schedulingDate}
						onChange={handleDateChange}
						minDate={new Date()}
					/>
				</div>
				<div className="date-content__selected pt-10">
					<div>{dayjs(schedulingDate).format('MMM D, YYYY')}</div>
					<div className="pt-4">
						<TimePicker
							variant="inline"
							autoOk
							value={dayjs().hour(schedulingTime.hour).minute(schedulingTime.minute)}
							onChange={handleTimeChange}
							inputVariant="standard"
							size="small"
							error={scheduleError?.error}
							helperText={scheduleError?.message}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
