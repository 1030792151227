import { useMemo } from 'react';
import { IMessage } from 'shared/interfaces';
import { useAuth } from 'modules/auth/hooks/useAuth';

export function useMessageFields(message: IMessage) {
	const { userData } = useAuth();
	const isOwnMsg = message?.from_user?.id === userData.id;
	const messageId = message.id || message.message_id;
	const type = message.type || message.message_type;
	const message_type = message?.message_type;

	const {
		created_date,
		edited,
		entity_id,
		entity_type,
		location,
		file_type,
		from_user,
		to_user,
		status,
		text = '',
		reacted_users,
		played_time,
		read_time,
		replied_to,
		file,
		contact,
		custom_uuid,
		sent_time,
		original_text,
		translated_text,
		is_translation_shown,
		settings,
		is_deleted,
		scheduled_to
	} = message;

	const reactedUsersMemo = useMemo(() => reacted_users, [reacted_users]);

	return {
		created_date,
		edited,
		entity_id,
		entity_type,
		location,
		file_type,
		from_user,
		to_user,
		type,
		status,
		messageId,
		isOwnMsg,
		text,
		reacted_users: reactedUsersMemo,
		played_time,
		read_time,
		replied_to,
		file,
		contact,
		custom_uuid,
		message_type,
		sent_time,
		original_text,
		translated_text,
		is_translation_shown,
		settings,
		is_deleted,
		scheduled_to
	};
}
