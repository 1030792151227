import React from 'react';
import clsx from 'clsx';

type Props = {
	onClickHandler?: () => void;
	type?: 'button' | 'submit';
	variant?: 'outlined' | 'contained';
	color?: 'default' | 'primary' | 'secondary';
	children: React.ReactNode;
	className?: string;
	disabled?: boolean;
};

export function Button({
	onClickHandler = () => {},
	type = 'button',
	variant = 'outlined',
	children,
	className = '',
	color = 'default',
	disabled = false
}: Props) {
	let classes = '';

	switch (variant) {
		case 'outlined':
			classes +=
				'border border-kgrey text-kgrey  rounded-lg px-5 py-2 transition hover:border-primary hover:text-primary';
			break;
		case 'contained':
			classes +=
				'bg-primary-dark rounded-lg !text-white px-5 py-2 ' +
				'border border-transparent hover:border-primary-dark' +
				' transition-colors hover:bg-white ' +
				'hover:text-primary-dark enabled:opacity-100 disabled:opacity-50 disabled:pointer-events-none';
			break;
	}

	switch (color) {
		case 'primary': {
			classes +=
				' border-primary !text-primary hover:!border-primary-dark hover:!text-primary-dark';
			break;
		}
	}

	return (
		<button
			type={type}
			className={clsx(classes, className)}
			onClick={onClickHandler}
			disabled={disabled}
		>
			{children}
		</button>
	);
}
