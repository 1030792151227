import api from '../api';
import queryBuilder from '../queryBuilder';

export const parseNAsyncValidate = ({
	csvData,
	companyId,
	resolve,
	reject,
	setCsvData,
	type,
	file,
	setLoadingImage
}) => {
	// members parsed from csv
	const members = [...csvData.data.slice(1)].reduce((acc, curr) => {
		if (type === 'client')
			return [...acc, { company_name: curr.clientBusinessName, username: curr.email }];
		else return [...acc, { company_id: companyId, username: curr.email }];
	}, []);
	// check user whether they are already in business,
	api.request
		.post(queryBuilder(`/${companyId}/check_user/`, {}), members)
		.then(({ data: { results } }) => {
			let existingResults;

			if (type === 'client') existingResults = results.filter((user) => user.is_member);
			else existingResults = results.filter((user) => user.is_staff);

			const existingResultsMessages = existingResults.reduce(
				(acc, curr) =>
					`${acc} <div class="red">${curr.username} is already a ${
						type === 'client' ? 'user in current client company' : 'staff in current business'
					} </div>`,
				''
			);
			// upload csv only user have not been registered before
			if (!existingResults.length) resolve(file);
			else {
				setCsvData(existingResultsMessages);
				reject(new Error(`${type === 'client' ? 'Clients exist' : 'Staffs exist'}`));
				setLoadingImage(false);
			}
		})
		.catch((err) => console.error('err in csv validation', err));
};
