import React from 'react';
import { CaseDetailsRow } from './CaseDetailsRow';
import { Rating } from 'shared/components/Elements';

type Props = {
	rate: string | number | null;
};

export function CdRating({ rate }: Props) {
	return (
		<>
			{rate !== null && Number(rate) > 0 && (
				<CaseDetailsRow fieldName="Rating">
					<Rating name="simple-controlled" value={Number(rate)} />
				</CaseDetailsRow>
			)}
		</>
	);
}
