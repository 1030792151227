import React from 'react';
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { IconWrapper } from 'shared/components';
import { useAppState } from 'shared/state';
import { BiChevronRight } from 'react-icons/bi';

type Props = {
	icon: React.ReactNode;
	text: string;
	noArrow?: boolean;
	isRounded?: boolean;
	classNames?: string;
	scrollToLeftMostUnread?: () => void;
} & NavLinkProps;

export function NavItemLogout({
	icon,
	text,
	noArrow = true,
	isRounded = true,
	classNames = '',
	scrollToLeftMostUnread = () => {},
	...rest
}: Props) {
	const { toggleChatPanel, navCollapsed } = useAppState();
	const location = useLocation();
	const isActive = location.pathname === rest.to;

	return (
		<div onClick={() => toggleChatPanel(false)}>
			<div
				className={clsx(
					'group flex items-center justify-between px-3.5 py-2 transition-colors',
					isActive && 'bg-primary-200 ',
					{ 'rounded-xl': isRounded },
					classNames
				)}
			>
				<NavLink {...rest} className="icon-text-wr flex items-center">
					<IconWrapper isActive={isActive}>
						<div
							className={clsx(
								'fit-icon-size h-3.5 w-3.5 transition-colors group-hover:text-primary',
								isActive && 'dark:text-white',
								!isActive && 'text-kgrey'
							)}
						>
							{icon}
						</div>
					</IconWrapper>
					{!navCollapsed && (
						<div
							className={clsx(
								'px-2 font-poppins  text-sm font-semibold text-kgrey-menuText transition-colors group-hover:text-primary dark:text-white'
							)}
						>
							{text}
						</div>
					)}
				</NavLink>
				{noArrow && (
					<div className="menu-item__arrow flex-shrink-0  group-hover:text-primary">
						<BiChevronRight />
					</div>
				)}
			</div>
		</div>
	);
}
