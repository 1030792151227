const storage = {
	get: (key: string): string | null => {
		return (window.localStorage && window.localStorage.getItem(key)) || null;
	},
	set: (key: string, value: string): void => {
		if (!value || value.length <= 0) {
			return;
		}
		if (window.localStorage) {
			window.localStorage.setItem(key, value);
		}
	},
	remove: (key: string): any => {
		if (window.localStorage && window.localStorage[key]) {
			window.localStorage.removeItem(key);
			return true;
		}
	}
};

export default storage;
