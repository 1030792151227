import React, { useEffect, useState, useCallback } from 'react';
import { IconSmile, IconTelegram } from 'shared/icons';
import { isMobile } from 'react-device-detect';
import {
	useChatHelpers,
	useChatInput,
	useChatMessage,
	useChatUpload,
	useComponentVisible,
	useDraftMessage,
	useQueryParams,
	useWhiteModal
} from 'shared/hooks';
import axios from 'axios';
import Upload from 'rc-upload';
import { useChatInputContext, useChatContext, useChatSocket } from 'shared/contexts';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { VoiceRecorder, ErrorBoundary, Loader } from 'shared/components';
import clsx from 'clsx';
import { default as dayjs } from 'dayjs';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import { ScrollDownBtn } from 'shared/hooks/chat-hooks/ScrollDownBtn';
import { RepliedMessage } from './RepliedMessage';
import { EdiMessage } from './EditMessage';
import { ConfirmationButtons } from 'pages/Cases/components/ConfirmationButtons';
import { useAppState } from 'shared/state';
import { ChangeStatusPopover } from 'pages/Cases/components/ChangeStatusPopover';
import { ImAttachment } from 'react-icons/im';
import { BsFillMicFill } from 'react-icons/bs';
import { CgClose } from 'react-icons/cg';
import { RiSendPlaneFill } from 'react-icons/ri';
import { createMessageFileFormData, getFileType } from 'shared/services';
import { getRepliedToId } from '../utils';
import { useCheckForLastPageIsFetched } from '../hooks/useCheckForLastPageIsFetched';
import { ChatMentionInput } from './ChatMentionInput/ChatMentionInput';
import { useMessageActions } from 'shared/hooks/chat-hooks/useMessageActions';
import { ScheduleDateTime } from './ScheduleDateTime';
import { SendButtonContextMenu } from './SendButtonContextMenu';
import { IconBtn } from './IconBtn';
import { ScheduledChatIcon } from './ScheduledChatIcon';
import { useForwardMessageForSchedule } from 'shared/hooks/chat-hooks/useForwardMessageForSchedule';
import { useTranslation } from 'react-i18next';
import { useTranslationHelpers } from 'shared/hooks/useTranslationHelpers';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

type Props = {
	onFormSubmit: (
		e: React.FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLTextAreaElement>
	) => void;
	isGroup?: boolean;
	isAllowedSendMsg?: boolean;
};

export function ChatInputLayoutInner({
	onFormSubmit,
	isAllowedSendMsg = true,
	isGroup = false
}: Props) {
	const handleFormSubmit = useCallback(
		(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
			//todo: check onFormSubmit to make it memoized
			onFormSubmit(e);
		},
		[onFormSubmit]
	);

	const {
		inputRef,
		handleStopRecordAudio,
		handleStartRecordAudio,
		handleCancelRecord,
		handleOnStopRecording,
		onEmojiClick
	} = useChatInput(handleFormSubmit);
	const {
		message,
		setMessage,
		command,
		time,
		editingMessage,
		reply,
		replyEditCancel,
		isDelayedMessage,
		setIsMentionSearching,
		messageScheduleDate,
		openScheduleModal,
		handleScheduleModalToggle,
		tempMessageEvent,
		setMessageScheduleDate,
		setTempMessageEvent
	} = useChatInputContext();
	const { isRTL } = useTranslationHelpers();
	const { userData } = useAuth();
	const { entityId, entityType, typingUsers, isScheduledChat } = useChatContext();
	const { sendJsonMessage, connectionStatus } = useChatSocket();
	const { getTypingInfo } = useChatHelpers();
	const [replyEditCancelState, setReplyEditCancelState] = useState(false);
	const { areRestMessagesLoaded } = useChatMessage({
		sendJsonMessage,
		entityId,
		entityType
	});
	const { query, changeUrlParams } = useQueryParams();
	const { saveDraft } = useDraftMessage();
	const { handleSaveMessageForSchedule } = useForwardMessageForSchedule();
	const { networkState, companyId, isDarkMode, scheduledMessageIds } = useAppState();

	const { ref: emojiRef, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

	const isSupportCase = query.isSupportCase;

	const { token } = useAuth();
	const {
		fileOnStart,
		fileOnSuccess,
		onProgress,
		onError,
		uuids,
		cancelTokensByUid,
		initialFiles,
		action
	} = useChatUpload({
		isDragging: false
	});

	const { contextMenu, handleContextClick, handleContextClose } = useMessageActions();
	const { WhiteModal } = useWhiteModal();

	const { handleUploadStart } = useChatUpload({ isDragging: true });

	const { lastPageIsNotFetched, setTempFileList, setIsDelayedMessage, handleScheduledFileUpload } =
		useCheckForLastPageIsFetched({ handleUpload: handleUploadStart });

	useEffect(() => {
		if (editingMessage?.text) {
			setMessage(editingMessage?.text);
		}
		if (editingMessage || reply) {
			setReplyEditCancelState(false);
			inputRef?.current?.focus();
		}
	}, [editingMessage, reply]);

	//Re-scheduling message
	useEffect(() => {
		if (isScheduledChat && messageScheduleDate) {
			if (tempMessageEvent?.type === 'RE_SCHEDULE' && tempMessageEvent?.data) {
				const payload = {
					type: 'edit_scheduled_message',
					id: tempMessageEvent.data?.messageId,
					scheduled_to: messageScheduleDate
				};

				sendJsonMessage?.(payload);

				setMessageScheduleDate(null);
				setTempMessageEvent(null);
			}
		}
	}, [messageScheduleDate, tempMessageEvent, isScheduledChat]);

	//4227 - when switching cases, audio track should be stopped across the browser
	useEffect(() => {
		return () => {
			if (command === 'start') {
				handleCancelRecord();
			}
		};
	}, [entityId, command]);

	/*
	 * TODO:Disable inputs if
	 *  	no internet
	 * */

	let isInputDisabled = false;
	const defaultIsDisabledOptions =
		connectionStatus !== 'Open' || !areRestMessagesLoaded || isDelayedMessage;
	if (isGroup) {
		isInputDisabled = defaultIsDisabledOptions || !isAllowedSendMsg || networkState === 'offline';
	} else {
		isInputDisabled = defaultIsDisabledOptions;
	}

	const handleDraft = useCallback(
		(type?: 'chat' | 'user') => {
			//In edit and reply mode
			//We must not save draft message if user is going to cancel editing message
			setTimeout(() => {
				setReplyEditCancelState((prev) => {
					if (!prev && message.length) {
						//Save draft only if edit or reply close icon is not clicked to cancel edit or reply mode
						saveDraft(type, message);
					}
					return false;
				});
			}, 1000);
		},
		[message]
	);

	const handleEditReplyCancelling = useCallback((isCancelling: boolean) => {
		setReplyEditCancelState(isCancelling);
	}, []);

	useEffect(() => {
		handleEditReplyCancelling(replyEditCancel);
	}, [replyEditCancel, handleEditReplyCancelling]);

	const handleButtonSubmit = () => {
		handleEditReplyCancelling(true);
	};

	const handleSubmitButtonHover = () => {
		setIsMentionSearching(false);
	};

	const handleOpenScheduledChat = useCallback(() => {
		changeUrlParams({ isScheduledChat: true });
		handleSaveMessageForSchedule(message, entityId);
		handleContextClose();
	}, [entityId, message]);

	const onScheduleModalCloseClicked = useCallback(() => {
		setTempMessageEvent(null);
		handleScheduleModalToggle();
	}, []);

	// @ts-ignore
	return (
		<div className={'chat-inp-controls p-2 pt-0 font-poppins lg:p-5 lg:pt-0'}>
			<form onSubmit={onFormSubmit}>
				<div className="chat-inp-controls__inner relative z-10 flex items-center justify-between">
					<>
						<div className="chat-inp-controls__extra absolute left-0 right-0 bottom-full">
							{entityType === 'case' && !isScheduledChat ? <ConfirmationButtons /> : null}
							<RepliedMessage handleEditReplyCancelling={handleEditReplyCancelling} />
							<EdiMessage handleEditReplyCancelling={handleEditReplyCancelling} />
							{typingUsers?.filter((user) => user.user.id !== userData.id).length ? (
								<div className="chat-inp-controls__typing from-bg-kgrey-bgmenu absolute left-0 bottom-0  inline-block min-w-[250px] bg-gradient-to-r via-kgrey-bgmenu  to-transparent pr-4 text-sm italic text-[#747F8D]  dark:bg-kdark dark:bg-none dark:text-white">
									{getTypingInfo({ typingUsers, entityId })}
								</div>
							) : null}
						</div>
						<ScrollDownBtn />
						<div
							className={clsx(
								'chat-inp-controls__inputs flex-grow rounded-lg border border-transparent bg-kgrey-inputBg dark:border-white/20 dark:bg-kgrey/20',
								{
									'h-11': command === 'start'
								}
							)}
						>
							<div className="chat-inp-controls__inputs-inner flex justify-between">
								<div className={'flex'}>
									{!isSupportCase && !isScheduledChat ? <ChangeStatusPopover /> : null}
									<Upload
										//@ts-ignore
										beforeUpload={(file, FileList) => {
											if (isScheduledChat && !messageScheduleDate && !editingMessage) {
												return handleScheduledFileUpload(FileList, true);
											}

											if (lastPageIsNotFetched) {
												return handleScheduledFileUpload(FileList, false);
											}

											fileOnStart(file);
										}}
										customRequest={async ({ file, filename }) => {
											const fileType = getFileType(file as File);
											const formDataNewMessage = await createMessageFileFormData({
												filename: filename as string,
												file: file as File,
												companyId: companyId!.toString(),
												entityType: isGroup ? 'ims' : 'case',
												entityId: entityId?.toString(),
												fileType,
												//@ts-ignore
												custom_uuid: uuids[file?.uid],
												repliedToId: getRepliedToId(reply)
											});
											const formDataEditing = new FormData();
											formDataEditing.append(filename as string, file);
											const formData = editingMessage ? formDataEditing : formDataNewMessage;
											axios
												.post(action, formData, {
													headers: {
														Authorization: 'Token ' + token
													},
													onUploadProgress: (progressEvent) => {
														const { total, loaded } = progressEvent;

														onProgress(
															{
																percent: {
																	// @ts-ignore
																	uuid: [uuids[file.uid]],
																	percent: Number(Math.round((loaded / total) * 100).toFixed(2))
																}
															},
															file
														);
													},
													// @ts-ignore
													cancelToken: cancelTokensByUid[file.uid].token
												})
												.then(({ data: response }) => {
													fileOnSuccess({
														response: response,
														_file: file,
														// @ts-ignore
														currFile: initialFiles[uuids[file.uid]],
														// @ts-ignore
														currentUuid: uuids[file.uid]
													});
												})
												.catch((err) => {
													onError(err);
												});

											return {
												abort() {
													console.log('upload progress is aborted.');
												}
											};
										}}
										multiple={true}
										disabled={command === 'start'}
									>
										<div
											className={clsx(
												'chat-inp-controls__attach-icon flex-centered h-full flex-shrink-0 border-white dark:border-white/20',
												{ hidden: !isAllowedSendMsg, 'border-r': !isRTL, 'border-l': isRTL }
											)}
										>
											<div className="cursor-pointer p-3">
												<div
													className={clsx('rct-notify h-5 w-5 rounded-full bg-kred-pink', {
														hidden: !command || command !== 'start'
													})}
												/>
												<div
													className={clsx(
														'flex-centered h-5 w-5 transition hover:text-primary dark:text-white dark:hover:text-primary',
														{
															hidden: command === 'start'
														}
													)}
												>
													<ImAttachment />
												</div>
											</div>
										</div>
									</Upload>
								</div>

								{command === 'start' ? (
									<div
										className={clsx(
											'absolute top-1/2 z-10 -translate-y-1/2 leading-none text-[#747F8D] dark:text-white',
											{
												'left-24': entityType === 'case' && !isRTL,
												'right-24': entityType === 'case' && isRTL,
												'left-14': entityType === 'ims' && !isRTL,
												'right-14': entityType === 'ims' && isRTL
											}
										)}
									>
										{/*@ts-ignore*/}
										{dayjs(time).utc().format('HH:mm:ss')}
									</div>
								) : null}
								{command === 'start' ? null : (
									<div className={clsx('chat-inp-controls__input relative flex-grow')}>
										{isInputDisabled && isAllowedSendMsg ? (
											<div className="absolute top-2 left-2 z-10">
												<Loader size={'30px'} />
											</div>
										) : null}
										<ChatMentionInput
											ref={inputRef}
											handleDraft={handleDraft}
											isInputDisabled={isInputDisabled}
											handleFormSubmit={handleFormSubmit}
											isAllowedSendMsg={isAllowedSendMsg}
											isComponentVisible={isComponentVisible}
										/>
									</div>
								)}
								{isAllowedSendMsg && (
									<div className="flex-centered flex-shrink-0">
										<IconBtn
											colorClass={'text-kdark/60 dark:text-white'}
											hoverClass={'hover:text-kred-pink'}
											classnames={clsx({ hidden: command !== 'start' })}
											onClick={handleCancelRecord}
										>
											<CgClose />
										</IconBtn>
										<IconBtn
											colorClass={'text-kdark/60 dark:text-white'}
											hoverClass={'hover:text-kred-pink'}
											classnames={clsx({ hidden: command !== 'start' })}
											onClick={handleStopRecordAudio}
										>
											<RiSendPlaneFill />
										</IconBtn>
										<ScheduledChatIcon onClick={handleOpenScheduledChat} />
										<IconBtn
											colorClass={'text-[#CFCFCF] relative'}
											classnames={clsx({
												hidden: command === 'start'
											})}
											onClick={() => setIsComponentVisible(true)}
										>
											<>
												<IconSmile />
											</>
											<div
												className={clsx('emoji-picker-wrapper absolute bottom-full z-10', {
													'emoji-picker-wrapper-mobile -right-12': isMobile,
													'left-0': isRTL,
													'right-0': !isRTL
												})}
												ref={emojiRef}
											>
												{isComponentVisible ? (
													<Picker
														showPreview={false}
														theme={isDarkMode ? 'dark' : 'light'}
														showSkinTones={false}
														onSelect={(e) => onEmojiClick(e)}
														style={{ width: isMobile ? '300px' : '338px' }}
													/>
												) : null}
											</div>
										</IconBtn>
										<IconBtn
											colorClass={'text-[#4F5660] dark:text-white dark:hover:text-primary'}
											classnames={clsx({ hidden: command === 'start' })}
											onClick={handleStartRecordAudio}
										>
											<BsFillMicFill />
										</IconBtn>
									</div>
								)}
							</div>
						</div>
						{isAllowedSendMsg && (
							<button
								className={clsx(
									'chat-inp-controls__send-btn flex-shrink-0 text-white ' +
										' flex-centered h-6 w-6 rounded-sm transition ' +
										'border border-transparent ',
									{
										'bg-primary hover:border-primary hover:bg-white hover:text-primary':
											!defaultIsDisabledOptions,
										'pointer-events-none bg-kgrey': defaultIsDisabledOptions,
										hidden: command === 'start',
										'ml-5': !isRTL,
										'mr-5': isRTL
									}
								)}
								type={'submit'}
								onClick={handleButtonSubmit}
								disabled={defaultIsDisabledOptions}
								onMouseEnter={handleSubmitButtonHover}
								// @ts-ignore
								onContextMenu={handleContextClick}
							>
								<IconTelegram />
							</button>
						)}
					</>
				</div>
				<VoiceRecorder command={command} onStop={handleOnStopRecording} />
			</form>

			{isScheduledChat ? (
				<WhiteModal
					maxWidth={500}
					minWidth={500}
					handleModalOpen={onScheduleModalCloseClicked}
					open={openScheduleModal}
				>
					<ScheduleDateTime onScheduleModalCloseClicked={handleScheduleModalToggle} />
				</WhiteModal>
			) : null}

			<SendButtonContextMenu
				onMenuClose={handleContextClose}
				contextMenu={contextMenu}
				onMenuItemClick={handleOpenScheduledChat}
			/>
		</div>
	);
}

export function ChatInputLayout(props: Props) {
	return (
		<ErrorBoundary>
			<ChatInputLayoutInner {...props} />
		</ErrorBoundary>
	);
}
