import React from 'react';

export function IconRecentDropdown({ className = '' }: { className?: string }) {
	return (
		<svg
			width="14"
			height="14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<circle cx="7" cy="7" r="6" stroke="currentColor" strokeWidth="2" />
			<path
				d="M9.813 7H7.25A.25.25 0 0 1 7 6.75V4.812"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	);
}
