import React from 'react';
import { useAppState } from 'shared/state';
import config from 'config';
import { Field } from 'formik';
import AsyncSelectJs from 'shared/components/Fields/AsyncSelectJs';
import { KntTwilioStyle } from 'shared/services';
import { isMobile } from 'react-device-detect';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { CreateCaseClientUser } from './CreateCaseClientUser';
import { useTranslation } from 'react-i18next';

type Props = {
	values: any;
	client_company: any;
	setState: any;
	setFieldValue: any;
	state: any;
	titleRef: any;
};

export function CreateCaseClientCompanies({
	values,
	client_company,
	setState,
	setFieldValue,
	state,
	titleRef
}: Props) {
	const { caseType, companyId } = useAppState();
	const { t } = useTranslation();

	return (
		<>
			{caseType === config.CLIENT && (
				<>
					<div className="react-select-dropdown mb-4">
						{/*Label is removed*/}
						<Field
							component={AsyncSelectJs}
							customStylesProps={KntTwilioStyle}
							name="client_company"
							placeholder={t('client_business_name_placeholder')}
							optionLabel={(option: any) =>
								option.is_individual && option.owner
									? `${option?.owner.first_name} ${option?.owner.last_name} (${option.name})`
									: option.name
							}
							optionValue="id"
							isSearchable={!isMobile}
							isClearable
							isCached
							loadOptionsKey="results"
							loadOptionsUrl={`/${companyId}/client_companies/`}
							loadOptionsParams={(search: string) => ({
								extra: {
									search
								}
							})}
							onChange={(option: any) => {
								//to clear the client_user if other business selected or business cleared
								if (
									get(values, 'client_company') &&
									get(values, 'client_user') &&
									(!client_company || !option || client_company.id !== option.id)
								) {
									setState((prevState: any) => ({ ...prevState, client_user: {} }));
									setFieldValue('client_user', null);
								}

								// setFieldValue("client_user", null);

								if (option === null) {
									setFieldValue('client_user', null);
								} else {
									setState((prevState: any) => ({ ...prevState, selected_client_company: option }));
									setState((prevState: any) => ({ ...prevState, client_company: option }));

									if (!isEmpty(state.client_user)) {
										setFieldValue('client_user', state.client_user);
									}
								}
								setTimeout(() => {
									setFieldValue('title', titleRef.current);
								}, 0);
							}}
						/>
					</div>
					{get(values, 'client_company') && (
						<CreateCaseClientUser
							companyId={companyId}
							setFieldValue={setFieldValue}
							titleRef={titleRef}
							setState={setState}
							state={state}
							values={values}
						/>
					)}
				</>
			)}
		</>
	);
}
