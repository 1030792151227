import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { scrollToRequiredElement, subDepOnChange } from '../helpers';
import { Loader } from 'shared/components';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayJsUtils from '@date-io/dayjs';
import {
	CreateCaseAssigneeUser,
	CreateCaseButton,
	CreateCaseClientCompanies,
	CreateCaseDepartments,
	CreateCaseDueDate,
	CreateCaseFormHeader,
	CreateCasePriority,
	CreateCaseStaffAccess,
	CreateCaseStatus,
	CreateCaseSwitch,
	CreateCaseTitle
} from './create-case';

import { CreateCaseRecurring } from './recurring-feature';
import { BsChevronDown } from 'react-icons/bs';
import clsx from 'clsx';
import { useAppState } from '../../../shared/state';
import config from '../../../config';
import { useTranslation } from 'react-i18next';
import { useTranslationHelpers } from 'shared/hooks/useTranslationHelpers';

type Props = {
	isSubmitting: boolean;
	values: any;
	errors: any;
	setFieldValue: any;
	state: any;
	data_fields: any;
	data_fields_names: any;
	data_fields_errors: any;
	setState: any;
	titleRef: any;
	departmentRef: any;
	resetForm: any;
	client_company: any;
	hasToShowGuard: () => void;
	formValuesSetter: (fieldValues: string[]) => void;
};

export const CreateCaseElements = forwardRef<any, Props>(function CreateCaseElementFunc(
	{
		isSubmitting,
		values,
		setFieldValue,
		state,
		data_fields,
		data_fields_names,
		data_fields_errors,
		setState,
		titleRef,
		departmentRef,
		resetForm,
		client_company,
		hasToShowGuard,
		formValuesSetter,
		errors
	},
	ref
) {
	const { caseType } = useAppState();
	const { t } = useTranslation();
	const { isRTL } = useTranslationHelpers();

	const [formState, setFormState] = useState({
		isSubDepChanged: false,
		membersShow: false
	});

	const [assigneeTypeValue, setAssigneeTypeValue] = useState('all');
	const [additionalOptionToggle, showAdditionalOptionToggle] = useState(false);

	const toggleAdditionalOptions = () => showAdditionalOptionToggle((prev) => !prev);

	const { isSubDepChanged } = formState;
	const { data_fields_names_persisted } = state;
	const isThereDataFieldsError = Object.values(data_fields_errors).includes(true);
	const subDepIdsRef = React.useRef([]);

	const { department, sub_department } = values;

	//Default Assignee required feature
	useEffect(() => {
		if (department?.assign_required && department?.default_assignees?.length) {
			setFieldValue('assignee_ids', department?.default_assignees);
		}

		if (!department) {
			setFieldValue('assignee_ids', '');
		}
	}, [department]);

	useEffect(() => {
		const subDepartmentsAssignees = Object.keys(sub_department)
			.filter((key) => key !== '1')
			.filter((key) => sub_department[key]?.default_assignees?.length)
			.reduce((acc: any, key: string) => {
				return [...acc, ...sub_department[key]?.default_assignees];
			}, []);

		if (subDepartmentsAssignees.length) {
			setFieldValue(
				'assignee_ids',
				department?.default_assignees
					? [...department?.default_assignees, ...subDepartmentsAssignees]
					: subDepartmentsAssignees
			);
		}

		if (!department) {
			setFieldValue('assignee_ids', '');
		}

		if (!subDepartmentsAssignees.length) {
			setFieldValue(
				'assignee_ids',
				department?.default_assignees ? department?.default_assignees : ''
			);
		}
	}, [sub_department]);

	//Preserve title input text when switch case type
	useEffect(() => {
		if (!values?.title?.length && titleRef.current?.length && titleRef.current !== null) {
			setFieldValue('title', titleRef.current);
		}
	}, [values, titleRef.current]);

	/*
	 * Just to solve problem when we have old formik values when react select changes
	 * In current way, we run subDepOnChange again when values
	 * */

	useEffect(() => {
		if (isSubDepChanged)
			subDepOnChange({
				values,
				setState,
				data_fields_names_persisted
			});
	}, [isSubDepChanged, values]);

	//Form guard filled fields setter
	useEffect(() => {
		const checkableFields = [
			'title',
			'department',
			'assignee_ids',
			'recurring',
			'member_list',
			'exception_member_list',
			'due_date'
		];
		if (caseType === config.CLIENT) {
			checkableFields.push('client_company', 'client_user');
		}

		const fieldValues = Object.keys(values).filter((field) => {
			const fieldHasValue = Array.isArray(values[field]) ? values[field].length : values[field];
			if (fieldHasValue && checkableFields.includes(field)) {
				return values[field];
			}
		});
		formValuesSetter(fieldValues);
	}, [values]);

	const scrollToRequired = () => {
		//We wait Required elements have to be rendered
		setTimeout(() => {
			scrollToRequiredElement();
		}, 100);
	};

	// const handleCancelClick = useCallback(() => {
	// 	hasToShowGuard();
	// }, [formValues]);

	const onClearCreateCaseFormHandler = useCallback(() => {
		resetForm();

		//CASE: 4164 - clearing title, department data fields in the create case form
		titleRef.current = '';
		setState((prev: any) => ({
			...prev,
			data_fields: [],
			data_fields_names: {},
			data_fields_errors: {},
			data_fields_names_persisted: {}
		}));
	}, [titleRef.current]);

	return (
		<MuiPickersUtilsProvider utils={DayJsUtils}>
			<div className="font-poppins">
				<CreateCaseFormHeader resetForm={onClearCreateCaseFormHandler} />
				<CreateCaseSwitch />
				<div>
					{/*Case Create title*/}
					<CreateCaseTitle titleRef={titleRef} />

					{/*Case Create client companies*/}
					<CreateCaseClientCompanies
						values={values}
						client_company={client_company}
						setState={setState}
						setFieldValue={setFieldValue}
						state={state}
						titleRef={titleRef}
					/>

					{/*Case Create departments*/}
					<CreateCaseDepartments
						setFieldValue={setFieldValue}
						titleRef={titleRef}
						setState={setState}
						data_fields_errors={data_fields_errors}
						data_fields_names={data_fields_names}
						departmentRef={departmentRef}
						state={state}
						setFormState={setFormState}
						values={values}
						data_fields={data_fields}
						subDepIdsRef={subDepIdsRef}
						setAssigneeTypeValue={setAssigneeTypeValue}
					/>

					<CreateCaseAssigneeUser
						values={values}
						assigneeTypeValue={assigneeTypeValue}
						setAssigneeTypeValue={setAssigneeTypeValue}
					/>

					{/*Case Create select assignee user*/}

					{/*Case Create select statuses */}
					<CreateCaseStatus state={state} setState={setState} />

					{/*Case Create select priority */}
					<CreateCasePriority />

					{/*Additional options toggler*/}
					<button
						className="!mb-6 flex w-full items-center justify-between font-light transition hover:text-primary focus:text-primary"
						onClick={toggleAdditionalOptions}
						type="button"
					>
						<span>{t('additional_options')}</span>
						<span className={clsx('transition', { 'rotate-180': additionalOptionToggle })}>
							<BsChevronDown />
						</span>
					</button>
					{/* Additional options */}
					{additionalOptionToggle ? (
						<>
							{/*Case Create set staff accesses */}
							<CreateCaseStaffAccess
								state={state}
								setState={setState}
								setFieldValue={setFieldValue}
								setFormState={setFormState}
							/>

							{/*Case Create set recurring case options */}
							<CreateCaseRecurring
								errors={errors}
								state={state}
								setState={setState}
								setFieldValue={setFieldValue}
							/>

							{/*Case Create set ETC case options */}
							<CreateCaseDueDate
								state={state}
								setState={setState}
								setFieldValue={setFieldValue}
								values={values}
							/>
						</>
					) : null}
				</div>
				<div className={'flex items-center'}>
					{isSubmitting && <Loader tiled withOverlay />}
					<CreateCaseButton
						isSubmitting={isSubmitting}
						values={values}
						isThereDataFieldsError={isThereDataFieldsError}
						memberingType={state.memberingType}
						membersShow={formState.membersShow}
						scrollToRequiredElement={scrollToRequired}
						handleCancelClick={hasToShowGuard}
					/>
				</div>
			</div>
		</MuiPickersUtilsProvider>
	);
});
