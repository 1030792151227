import ContentLoader from 'react-content-loader';
import { isMobile } from 'react-device-detect';
import React from 'react';

export function ChatMessagesLoader() {
	return (
		<>
			{[...Array(3)].map((item, i) => (
				<ContentLoader
					viewBox={`0 0 ${isMobile ? 320 : 446} 160`}
					height={'33%'}
					width={isMobile ? 320 : 446}
					key={i}
				>
					<circle cx="19" cy="25" r="16" />
					<rect x="39" y="12" rx="5" ry="5" width="220" height="10" />
					<rect x="40" y="29" rx="5" ry="5" width="220" height="10" />
					<circle cx="420" cy="71" r="16" />
					<rect x="179" y="76" rx="5" ry="5" width="220" height="10" />
					<rect x="179" y="58" rx="5" ry="5" width="220" height="10" />
					<circle cx="21" cy="117" r="16" />
					<rect x="45" y="104" rx="5" ry="5" width="220" height="10" />
					<rect x="45" y="122" rx="5" ry="5" width="220" height="10" />
				</ContentLoader>
			))}
		</>
	);
}
