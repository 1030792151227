import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { Button } from '../components/Elements';
import { useTranslation } from 'react-i18next';

type Notification = {
	message: string;
	action?: (key: any) => React.ReactNode | undefined;
	variant?: string;
	persist?: boolean;
	duration?: number;
	closable?: boolean;
};

export function useNotistack() {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar() ?? {};
	const { t } = useTranslation();

	const actionBtn = (snackbarId: any) => (
		<Button classes={{ label: 'text-white' }} onClick={() => closeSnackbar(snackbarId)}>
			{t('dismiss')}
		</Button>
	);

	const showNotification = useCallback(
		({
			message,
			action = undefined,
			variant = 'success',
			persist = false,
			duration = 3000,
			closable = true
		}: Notification) => {
			const options = {
				variant,
				autoHideDuration: duration,
				...{
					...(action
						? action
						: closable
						? {
								action: actionBtn
						  }
						: {})
				}
			};

			try {
				// @ts-ignore
				enqueueSnackbar(message, options);
			} catch (e) {
				console.error('something went wrong', e);
			}
		},
		[enqueueSnackbar]
	);

	return {
		showNotification
	};
}
