import { useAppState } from 'shared/state';
import config from 'config';
import { useCallback, useState } from 'react';
import { api, queryBuilder } from '../../../shared/services';
import { useAxios, useNotistack } from '../../../shared/hooks';

type CaseDetailsHook = {
	caseId: number | null;
	closeCaseDetailModal: any;
};

export function useCaseDetails({ caseId, closeCaseDetailModal }: CaseDetailsHook) {
	const { caseType, companyId } = useAppState();
	const [members, setMembers] = useState<any[] | null>(null);
	const { showNotification } = useNotistack();
	const [loading, setLoading] = useState<boolean>(false);
	const [assignedUsers, setAssignedUsers] = useState<any[] | null>(null);
	const apiURL = `/${companyId}/cases/as_business/${caseId}/members/`;
	const [assignRequired, setAssignRequired] = useState<boolean>(false);

	const defaultState = {
		isDateTimeSet: false,
		sub_dep: {},
		depHierarchy: {},
		subDepsForHierarchy: {},
		consistedSubDeps: {},
		clearDepartment: false,
		recurring: null,
		recurringStatus: '',
		recurringText: '',
		recurringOptions: [],
		recurOption: 'CUSTOM_VALUE',
		dropdownPresetOptions: [
			'DAILY',
			'WEEKLY_ON_WEEKDAY',
			'MONTHLY_ON_WEEKDAY',
			'ANNUALLY_ON_DATE',
			'EVERY_WEEKDAY'
		],
		showCustomRecurring: false,
		updateLoading: false,
		isCaseDataFieldsOpen: false
	};
	const [state, setState] = useState(defaultState);

	const params = {
		extra: {}
	};
	if (caseType === config.STAFF) {
		params.extra = {
			client_company: companyId
		};
	}
	// fetch Assign List
	const { fetchData: caseAssignListChangeRequest } = useAxios({
		url: `/${companyId}/cases/as_business/${caseId}/actions_on_assignees/`,
		values: {
			assignee_ids: assignedUsers?.length
				? assignedUsers?.map((member) => (member?.user ? member?.user?.id : member?.id))
				: []
		},
		method: 'put',
		cb: {
			success: (data, args) => {
				setLoading(false);
				closeCaseDetailModal();
			},
			error: (error, args) => {
				setLoading(false);
				showNotification({
					message: error.response?.data?.message,
					variant: 'error'
				});
			}
		}
	});

	// fetch Access List
	const { fetchData: caseAccessListChangeRequest } = useAxios({
		url: `/${companyId}/cases/as_business/${caseId}/actions_on_access_list/`,
		values: {
			access_list: members?.length
				? members?.map((member) => (member?.user ? member?.user?.id : member?.id))
				: []
		},
		method: 'put',
		cb: {
			success: (data, args) => {
				caseAssignListChangeRequest();
			},
			error: (error, args) => {
				setLoading(false);
				showNotification({
					message: error.response?.data?.detail,
					variant: 'error'
				});
			}
		}
	});

	const getCaseAccessMembers = useCallback(async () => {
		const { data } = await api.request.get(queryBuilder(`${apiURL}?page_size=30`, {}));
		setMembers(data);
	}, [apiURL]);

	return {
		params,
		state,
		setState,
		getCaseAccessMembers,
		members,
		assignedUsers,
		setAssignedUsers,
		setAssignRequired,
		assignRequired,
		setMembers,
		caseAssignListChangeRequest,
		caseAccessListChangeRequest,
		setLoading,
		loading
	};
}
