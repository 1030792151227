import React from 'react';
import { SiMaildotru } from 'react-icons/si';
import clsx from 'clsx';
import { useAppState } from 'shared/state';
import { useChatContext } from 'shared/contexts';
import { useChatMentionUpdate } from '../hooks/useChatMentionUpdate';

type Props = {
	allUnreadCount?: number;
};
export const MentionScrollButton = (props: Props) => {
	const { allUnreadCount } = props;
	const { chatsMentionList, companyId } = useAppState();
	const { entityId, setScrollToMessageHandler } = useChatContext();
	const mentionsList = chatsMentionList?.[entityId];
	const hasMentions = mentionsList?.length;
	const { decrementChatMentions } = useChatMentionUpdate();

	const scrollToMentionedMessage = () => {
		const mentionIds = chatsMentionList?.[entityId].slice();
		const currentMentionId = mentionIds?.pop();

		if (currentMentionId) {
			setScrollToMessageHandler(currentMentionId);
			decrementChatMentions({
				entityId,
				messageIds: [currentMentionId],
				currentMsgCompanyId: companyId
			});
		}
	};

	return (
		<>
			{!!hasMentions && !!allUnreadCount && allUnreadCount > 0 && (
				<div
					onClick={scrollToMentionedMessage}
					className="flex-centered group
					absolute right-0 -top-14 h-8 w-8
					cursor-pointer rounded-full
					border
					border-kgrey-chatGrey/30 text-kgrey-chatGrey/30 transition hover:border-primary hover:bg-primary hover:text-white"
				>
					<SiMaildotru />
					<div
						className={clsx(
							'flex-centered absolute left-2 border border-transparent' +
								' -top-3 h-4 w-4 rounded-full bg-primary' +
								' text-xs leading-none text-white  transition group-hover:border-primary group-hover:bg-white group-hover:text-primary'
						)}
					>
						{hasMentions}
					</div>
				</div>
			)}
		</>
	);
};
