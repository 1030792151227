import { useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { Scrollbars } from 'react-custom-scrollbars-2';
import EntityContainer from 'modules/entity/containers';
import { useChatContext } from 'shared/contexts';
import { EmojiType, IGroupChat, IUserMessage } from 'shared/interfaces';
import { AvatarSized } from 'shared/components/Elements';
import { useAppState } from 'shared/state';
import { useAxios } from 'shared/hooks';
import { Emojies, Loader } from 'shared/components';
import { BiLike } from 'react-icons/bi';
import { convertMsgTime } from '../utils';
import { useTranslation } from 'react-i18next';

type Props = {
	messageId: number | undefined;
	reactedUsers: EmojiType[];
};

type ViewerTime = string | null;

type Viewer = {
	delivered: ViewerTime;
	emoji: any;
	message_id: number;
	played_time: ViewerTime;
	read: ViewerTime;
	recipient: IUserMessage;
	reacted_at: number | null;
};
export function EmojiCountInfo({ messageId, reactedUsers }: Props) {
	const { entityType, entityId } = useChatContext();
	const { companyId } = useAppState();
	const [caseMembers, setCaseMember] = useState<any>([]);
	const { t } = useTranslation();

	const { loading: membersLoading, fetchData: fetchMembers } = useAxios({
		url: `/${companyId}/cases/as_business/${entityId}/members/`,
		params: {
			limit: 30,
			extra: {
				members_count: 'true'
			}
		},
		cb: {
			success: (data, additionalArgs) => {
				setCaseMember(data);
			},
			error: (error, additionalArgs) => {}
		}
	});

	useEffect(() => {
		if (entityType === 'case') {
			fetchMembers();
		}
	}, [messageId, entityId, entityType]);

	return (
		<EntityContainer.One
			id={entityId}
			relations={''}
			entity={entityType === 'case' ? 'cases' : 'ims-chats'}
			name={`${entityType === 'case' ? 'Case' : 'Group'}Chat-${entityId}`}
			url={`/${companyId}/ims/groups/${entityId}/`}
			params={{}}
		>
			{({ item: entity, isFetched }) => {
				const typedImsChat = { ...entity } as IGroupChat;
				return (
					<div className="msg-info w-full">
						<div className="msg-info__header w-full border-b pb-4 ">
							<h2 className={'mb-4 text-xl font-semibold'}>{t('message_reaction_info')}</h2>
							<div className={'flex'}>
								<div className="w-1/2">
									<div className={'mb-2'}>
										<dl className={'flex items-center'}>
											<dt>{t('total_members')}</dt>
											<dd className={'ml-2'}>
												{entityType === 'case' ? (
													<>{!membersLoading ? caseMembers.members_count : <Loader />}</>
												) : (
													<>{isFetched ? typedImsChat.members.length : <Loader />}</>
												)}
											</dd>
										</dl>
									</div>
									<div className={'mb-2'}>
										<dl className={'flex items-center'}>
											<dt>{t('total_counts')}</dt>
											<dd className={'ml-2'}>
												{
													reactedUsers.reduce(
														(acc: number[], reaction) => [...acc, ...reaction.users],
														[]
													).length
												}
											</dd>
										</dl>
									</div>
								</div>
								<div className="-mt-2 w-1/2">
									<Scrollbars className="rct-scroll" autoHide style={{ height: '94px' }}>
										<Emojies.EmojiList inModal={true} reactedUsers={reactedUsers} />
									</Scrollbars>
								</div>
							</div>
						</div>
						<div className={'pt-4'}>
							<div className=" mb-4 flex items-center font-light">
								<div className="icon mr-1 text-primary">
									<BiLike />
								</div>
								<div className="text-sm">{t('reacted_users')}:</div>
							</div>
							<div className="msg-info__content h-28 w-full">
								<Scrollbars style={{ height: '100%' }} autoHide>
									<EntityContainer.All
										entity={`MessageEmojies`}
										name={`MessageEmojies-${messageId}`}
										url={`messages/${messageId}/view_reacted_members/`}
										params={{}}
										dataKey={(data: any) => data}
									>
										{({ items: viewers, isFetched: viewersIsFetched, meta }) => {
											const viewerItems = Object.values(viewers) as Viewer[];

											return (
												<>
													{viewersIsFetched ? (
														<>
															{viewerItems.map((item: any) => {
																return (
																	<div
																		className="msg-info__content-user mb-3 flex items-center"
																		key={item.key}
																	>
																		<div className="avatar mr-3 flex-shrink-0">
																			<AvatarSized
																				size={36}
																				smallSize={36}
																				className={''}
																				letter={`${item.recipient.first_name[0]}${item.recipient.last_name[0]}`}
																				alt={`${item.recipient.first_name} ${item.recipient.last_name}`}
																				src={
																					item.recipient.avatar
																						? item.recipient.avatar?.thumbnail_150 ||
																						  item.recipient.avatar?.thumbnail ||
																						  item.recipient.avatar?.url
																						: null
																				}
																				color={item.recipient.color}
																			/>
																		</div>
																		<div className="msg-info__user-content flex flex-grow items-center justify-between">
																			<div className="msg-info__user-name mb-1 text-sm">
																				{`${item.recipient.first_name} ${item.recipient.last_name}`}
																			</div>
																			<div className="msg-info__content-info-list flex-center-y">
																				{item.reacted_at ? (
																					<div className="mr-4 whitespace-nowrap text-xs text-kgrey-menuText">
																						{convertMsgTime(item.reacted_at)}
																					</div>
																				) : null}
																				<div className="mt-1 flex-shrink-0">
																					<Emojies.EmojiCustom emoji={item.emoji} size={20} />
																				</div>
																			</div>
																		</div>
																	</div>
																);
															})}
														</>
													) : (
														<ViewerLoaderList />
													)}
												</>
											);
										}}
									</EntityContainer.All>
								</Scrollbars>
							</div>
						</div>
					</div>
				);
			}}
		</EntityContainer.One>
	);
}

const ViewerLoaderList = () => {
	return (
		<>
			{[...Array(6)].map((item, i) => (
				<ContentLoader
					key={i}
					speed={2}
					width={369}
					height={60}
					viewBox="0 0 369 60"
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
				>
					<circle cx="75" cy="125" r="27" />
					<circle cx="27" cy="26" r="20" />
					<rect x="62" y="1" rx="4" ry="4" width="130" height="16" />
					<rect x="62" y="26" rx="3" ry="3" width="128" height="9" />
					<rect x="62" y="40" rx="3" ry="3" width="129" height="9" />
				</ContentLoader>
			))}
		</>
	);
};
