import { useState, useEffect } from 'react';
import { useAppState } from 'shared/state';
import { useDispatch } from 'react-redux';
import { default as dayjs } from 'dayjs';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import { queryBuilder } from 'shared/services';
import qs from 'query-string';
import Actions from 'store/actions';
import { MemberUser } from '../../../shared/typings/member.types';
import { StatusType } from '../components/Status';
import { PriorityType } from '../../../shared/typings/priority.types';
import config from '../../../config';
import { FilterTemplateType } from 'shared/typings';
import { useNotistack, useUpdateEntities } from 'shared/hooks';
import { useHistory } from 'react-router-dom';
import { useCaseFilterSelect } from './useCaseFilterSelect';
import { Department } from '../cases.types';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

const FormRequest = Actions.entity.formRequest;

type State = {
	flatSubdepartments: {
		departments: number[];
		sub_departments: number[];
	};
	dateRange: string;
};
type Range = {
	startDate: Date;
	endDate: Date | undefined;
	key: string;
};
type DateStateType = Range[];

export type innerDep = {
	id: number;
	name: string;
};

function resetDatePicker(setFieldValue: (field: string, value: string) => void) {
	setFieldValue('start_date', '');
	setFieldValue('end_date', '');
}

function formatCaseRangeDate(date: Date) {
	return dayjs(date).toISOString();
}

function getDateOfMonthAgo(date: Date) {
	// Create a new Date object to manipulate
	const dateAMonthAgo = new Date(date);

	// Set the month to one month ago
	dateAMonthAgo.setMonth(date.getMonth() - 1);

	return dateAMonthAgo;
}

export function useNewCaseFilter(onSuccess: () => void) {
	const { companyId, caseType, selectedCaseFilter, activeFilterTemplate, setActiveFilterTemplate } =
		useAppState();
	const { updateEntities } = useUpdateEntities();
	const dispatch = useDispatch();
	const history = useHistory();
	const { onFilterTemplateClick } = useCaseFilterSelect();
	const currentFilter = selectedCaseFilter[caseType];
	const { t } = useTranslation();
	const { showNotification } = useNotistack();

	const nextInitialState = {
		start_date: undefined,
		end_date: undefined,
		filter_status: undefined,
		department: undefined,
		sub_department: undefined,
		priority: undefined,
		client_type: null,
		client_company: null,
		client_user: null,
		unread: null,
		unresponded: null,
		rate: null,
		is_individual: null,
		member: null,
		assignees: null,
		assigned_by: null,
		title: null,
		filter_type: null
	};

	const initState = {
		flatSubdepartments: {
			departments: currentFilter?.filter_json.query_web?.department
				? currentFilter?.filter_json.query_web?.department
						?.split(',')
						?.reduce((acc: number[], curr: string) => [...acc, +curr], [])
				: [],
			sub_departments: currentFilter?.filter_json.query_web?.sub_department
				? currentFilter?.filter_json.query_web?.sub_department
						?.split(',')
						?.reduce((acc: number[], curr: string) => [...acc, +curr], [])
				: []
		},
		dateRange: ''
	};

	const today = new Date();
	const dateAMonthAgo = getDateOfMonthAgo(today);

	const initDateState = {
		startDate: dateAMonthAgo,
		endDate: new Date(),
		key: 'selection'
	};

	const [state, setState] = useState<State>(initState);
	const [open, setOpen] = useState(false);
	const [dateState, setDateState] = useState<DateStateType>([initDateState]);

	const activeFilterFields = !isEmpty(activeFilterTemplate?.fields_json)
		? activeFilterTemplate?.fields_json
		: [];

	const isStatusFilterAdded = activeFilterFields?.find((filter) => filter.name === 'status');

	useEffect(() => {
		// @ts-ignore
		const startD = dayjs(dateState[0].startDate).format('MMM D YY');
		// @ts-ignore
		const endD = dayjs(dateState[0].endDate).format('MMM D YY');
		if (dateState[0].endDate) {
			setState((prev) => ({ ...prev, dateRange: `${startD} - ${endD}` }));
		}
		if (!dateState[0].endDate) {
			setState((prev) => ({ ...prev, dateRange: '' }));
		}
	}, [dateState]);

	//Restore date picker values if previous filter has datepicker filter
	useEffect(() => {
		const initDateStateToRestore = {
			startDate:
				activeFilterTemplate && activeFilterTemplate?.filter_json?.start_date
					? new Date(activeFilterTemplate?.filter_json?.start_date)
					: dateAMonthAgo,
			endDate:
				activeFilterTemplate && activeFilterTemplate?.filter_json?.end_date
					? new Date(activeFilterTemplate?.filter_json?.end_date)
					: today,
			key: 'selection'
		};

		setDateState([initDateStateToRestore]);
	}, [activeFilterTemplate]);

	const handleDatePickerOpen = () => {
		setOpen((prev) => !prev);
	};

	const setDepartments = (deps: number[], subdeps: number[]) => {
		setState((prev) => ({
			...prev,
			flatSubdepartments: {
				departments: deps,
				sub_departments: [...new Set(subdeps)]
			}
		}));
	};

	const makeFlatSubdepartments = (subdepartments: Department[], departments: Department[]) => {
		//Make an array of last 2 sub departments
		const flatSubdepartments = subdepartments.reduce((acc: any, curr: Department) => {
			return [
				...acc,
				[curr.hierarchy[curr.hierarchy.length - 2].id, curr.hierarchy[curr.hierarchy.length - 1].id]
			];
		}, []);

		//Divide into separated list
		const depSupDeps = flatSubdepartments.reduce(
			(acc, [dep, subdep]) => {
				return {
					...acc,
					departments: [...acc.departments, dep],
					sub_departments: [...acc.sub_departments, subdep]
				};
			},
			{ departments: [], sub_departments: [] }
		);

		//Extract first level parent of subdepartments
		const firstLevelDepartmentsOfSubdepartments = subdepartments.reduce(
			(acc: any, curr: Department) => {
				return [...acc, curr.hierarchy[0].id];
			},
			[]
		);
		//Extract ids of departments
		const firstLevelDepartmentsFromValues = departments.reduce((acc: any, curr: Department) => {
			return [...acc, curr.id];
		}, []);

		//Exclude ids from sub_departments which are in departments field.
		const filteredSubDeps = depSupDeps.sub_departments.filter(
			(dep: number) => !depSupDeps.departments.includes(dep)
		);

		/*Leave only those department ids which have no selected sub_departments.
        Because if there is sub_department we not need to include its department it to the filter request query
        */
		const filteredTopLevelDeps = [...new Set(firstLevelDepartmentsFromValues)].filter(
			(dep) => !firstLevelDepartmentsOfSubdepartments.includes(dep)
		);

		setDepartments(filteredTopLevelDeps, filteredSubDeps);
		return {
			departments: filteredTopLevelDeps,
			subDepartments: filteredSubDeps
		};
	};

	//create department and sub_departments arrays for query on initial load
	useEffect(() => {
		if (!currentFilter) {
			return;
		}
		const { sub_department, department } = currentFilter?.filter_json;
		// console.log("currentFilter", { currentFilter ,sub_department, department});
		if (isArray(department) && department.length > 0) {
			if (currentFilter?.filter_json.sub_department) {
				makeFlatSubdepartments(sub_department, department);
			}

			if (department && !sub_department) {
				setDepartments(
					department.map((dep) => dep.id),
					[]
				);
			}
		}
	}, [currentFilter]);

	const initialValues = {
		start_date: get(activeFilterTemplate, 'filter_json.start_date', null) as any,
		end_date: get(activeFilterTemplate, 'filter_json.end_date', null) as any,
		filter_status: get(activeFilterTemplate, 'filter_json.filter_status', null) as any,
		priority: get(activeFilterTemplate, 'filter_json.priority', null) as any,
		department: get(activeFilterTemplate, 'filter_json.department', null) as any,
		sub_department: get(activeFilterTemplate, 'filter_json.sub_department', null) as any,
		client_type: get(activeFilterTemplate, 'filter_json.client_type', null),
		client_company: get(activeFilterTemplate, 'filter_json.client_company', null),
		client_user: get(activeFilterTemplate, 'filter_json.client_user', null),
		unread: get(activeFilterTemplate, 'filter_json.unread', null),
		unresponded: get(activeFilterTemplate, 'filter_json.unresponded', null),
		pinned: get(activeFilterTemplate, 'filter_json.pinned', null),
		expired: get(activeFilterTemplate, 'filter_json.expired', null),
		rate: get(activeFilterTemplate, 'filter_json.rate', null),
		member: get(activeFilterTemplate, 'filter_json.member', null),
		assignees: get(activeFilterTemplate, 'filter_json.assignees', null),
		assigned_by: get(activeFilterTemplate, 'filter_json.assigned_by', null),
		tagged: get(activeFilterTemplate, 'filter_json.tagged', null),
		scheduled: get(activeFilterTemplate, 'filter_json.scheduled', null),
		title: activeFilterTemplate?.title ?? '',
		filter_type: activeFilterTemplate?.filter_type ?? { label: 'And', value: 'and' }
	};

	const onSubmit = (
		values: any,
		{ setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
		isImmediateApply: boolean
	) => {
		// For passing to request as params
		const filterQuery = {
			client_company: get(values, 'client_company.id', ''),
			department: values?.department?.length
				? makeFlatSubdepartments(values.sub_department || [], values.department || []).departments
				: [],
			sub_department: values?.sub_department
				? makeFlatSubdepartments(values.sub_department || [], values.department || [])
						.subDepartments
				: [],
			member: isArray(get(values, 'member', []))
				? (get(values, 'member', []) as MemberUser[]).reduce(
						(acc: number[], curr) => [...acc, get(curr, 'user.id')],
						[]
				  )
				: '',
			assignees: isArray(get(values, 'assignees', []))
				? (get(values, 'assignees', []) as MemberUser[]).reduce(
						(acc: number[], curr) => [...acc, get(curr, 'user.id')],
						[]
				  )
				: '',
			assigned_by: isArray(get(values, 'assigned_by', []))
				? (get(values, 'assigned_by', []) as MemberUser[]).reduce(
						(acc: number[], curr) => [...acc, get(curr, 'user.id')],
						[]
				  )
				: '',
			start_date: get(values, 'start_date')
				? '' + dayjs(get(values, 'start_date')).startOf('day').toISOString()
				: '', //dayjs(date).toISOString()
			end_date: get(values, 'end_date')
				? '' + dayjs(get(values, 'end_date')).endOf('day').toISOString()
				: '',
			is_individual: get(values, 'client_type.value'),
			priority: isArray(get(values, 'priority', []))
				? (get(values, 'priority', []) as PriorityType[]).reduce(
						(acc: string[], curr) => [...acc, curr.code],
						[]
				  )
				: '',
			rate: get(values, 'rate.value', ''), //???
			filter_status: isArray(get(values, 'filter_status', []))
				? (get(values, 'filter_status', []) as StatusType[]).reduce(
						(acc: number[], curr) => [...acc, curr.id],
						[]
				  )
				: '',
			unread: '' + (get(values, 'unread') ?? ''),
			pinned: '' + (get(values, 'pinned') ?? ''),
			expired: '' + (get(values, 'expired') ?? ''),
			unresponded: '' + (get(values, 'unresponded') ?? ''),
			search: '' + get(values, 'search', ''),
			client_user: get(values, 'client_user.id', ''),
			filter_type: get(values, 'filter_type.value'),
			tagged: String(get(values, 'tagged') ?? ''),
			scheduled: String(get(values, 'scheduled', '') ?? '')
		};

		//need to convert filterQuery fields value to string or array since queryBuilder cannot handle other data type
		const stringifiedQuery = queryBuilder('', {
			extra: {
				...filterQuery,
				rate: filterQuery.rate === 'all' ? '' : filterQuery.rate,
				...{
					...(values.filter_type.value === 'or' && isStatusFilterAdded
						? { status_filter_with_or: 'true' }
						: {})
				}
			}
		});
		const normalizedValues = qs.parse(stringifiedQuery);

		//Delete empty keys which are redundant for showing action caseFilter button state
		Object.keys(normalizedValues).forEach(
			(key) =>
				(normalizedValues[key] === '' || normalizedValues[key] === 'false') &&
				delete normalizedValues[key]
		);

		const copiedValues = {
			...values,
			sub_department: values?.sub_department?.length ? values?.sub_department : null
		};
		delete copiedValues.title;
		delete copiedValues.filter_type;

		const combinedValues = {
			fields_json: activeFilterTemplate?.fields_json,
			is_selected: activeFilterTemplate?.is_selected,
			title: values.title,
			is_default: false,
			filter_type:
				activeFilterTemplate?.fields_json?.length === 1
					? { label: 'And', value: 'and' }
					: values.filter_type,
			filter_json: {
				...copiedValues,
				query_web: normalizedValues
			},
			is_staff: caseType === config.STAFF
		};

		const entity = 'case-filters';
		const entityName = `AllCaseFilters-${companyId}-${caseType}`;

		if (activeFilterTemplate?.isNew) {
			//CREATE filter template
			dispatch(
				FormRequest({
					entity: entity,
					name: entityName,
					url: `/${companyId}/case-filters/`,
					method: 'post',
					params: {},
					appendData: true,
					values: combinedValues,
					normalizeData: (data: FilterTemplateType) => data,
					cb: {
						success: (data: FilterTemplateType) => {
							if (isImmediateApply) {
								onFilterTemplateClick(data);
								onSuccess();
							}

							if (!isImmediateApply) {
								setActiveFilterTemplate(data);
							}
						},
						error: (error: any) => {
							showNotification({
								message: error?.response?.data?.message ?? t('something_went_wrong'),
								variant: 'error'
							});
							console.log('error', error);
						},
						finally: () => {
							setSubmitting(false);
						}
					}
				})
			);
		} else {
			//EDIT filter
			dispatch(
				FormRequest({
					id: activeFilterTemplate?.id,
					entity: entity,
					name: entityName,
					url: `/${companyId}/case-filters/${activeFilterTemplate?.id}/`,
					method: 'put',
					params: {},
					updateData: true,
					values: combinedValues,
					normalizeData: (data: FilterTemplateType) => data,
					cb: {
						success: (data: FilterTemplateType) => {
							//Update state to up to date in case edited filter immediately applied
							try {
								updateEntities({
									entity,
									entityId: activeFilterTemplate?.id as number,
									updatingData: {
										fields_json: data.fields_json,
										filter_json: {
											...data.filter_json,
											query_web: data.filter_json.query_web
										}
									}
								});
							} catch (e) {
								console.log('cannot update state', e);
							}

							if (isImmediateApply || data.is_selected) {
								onFilterTemplateClick(data);
								onSuccess();
							}

							if (!isImmediateApply) {
								setActiveFilterTemplate(data);
							}
						},
						error: (error: AxiosError) => {
							showNotification({
								message: error?.response?.data?.message ?? t('something_went_wrong'),
								variant: 'error'
							});
							console.log('error', error);
						},
						finally: () => {
							setSubmitting(false);
						}
					}
				})
			);
		}
	};

	const goToAllCasesPage = () => {
		onSuccess();
		history.push(`/all-cases`);
	};

	return {
		onSubmit,
		initState,
		state,
		setState,
		dateState,
		today,
		setDateState,
		initialValues,
		nextInitialState,
		setDepartments,
		open,
		setOpen,
		makeFlatSubdepartments,
		initDateState,
		resetDatePicker,
		handleDatePickerOpen,
		formatCaseRangeDate,
		goToAllCasesPage
	};
}
