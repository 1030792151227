import { PiClockClockwise } from 'react-icons/pi';
import React from 'react';

export function ScheduledIcon() {
	return (
		<div className="case-scheduled__icon -mt-1 items-start text-xl text-kgrey-menuText">
			<PiClockClockwise />
		</div>
	);
}
