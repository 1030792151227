import React, { useEffect, useState } from 'react';
import { EmojiType } from 'shared/interfaces';
import { EmojiCustom } from './EmojiCustom';
import clsx from 'clsx';
type Props = {
	reactedUsers: EmojiType[];
	inModal?: boolean;
	fileType?: string | null;
};

const countableEmojiesList = [
	{ emoji: '👍', users: [234, 2345] },
	{ emoji: '♥️', users: [234, 2345] },
	{ emoji: '👏', users: [234, 2345] },
	{ emoji: '🤝', users: [234, 2345] },
	{ emoji: '😕', users: [234, 2345] },
	{ emoji: '👎', users: [234, 2345] },
	{ emoji: '😡', users: [234, 2345] },
	{ emoji: '🤝', users: [234, 2345] },
	{ emoji: '😕', users: [234, 2345] },
	{ emoji: '👎', users: [234, 2345] },
	{ emoji: '😡', users: [234, 2345] },
	{ emoji: '👎', users: [234, 2345] },
	{ emoji: '😡', users: [234, 2345] },
	{ emoji: '😕', users: [234, 2345] },
	{ emoji: '👎', users: [234, 2345] },
	{ emoji: '😡', users: [234, 2345] },
	{ emoji: '👎', users: [234, 2345] },
	{ emoji: '😡', users: [234, 2345] }
];

export function EmojiList({ reactedUsers, inModal = false, fileType = '' }: Props) {
	const [viewAll, setViewAll] = useState<boolean>(inModal);

	const splicedReactedUsers = viewAll ? reactedUsers : reactedUsers.slice(0, 5);

	return (
		<div
			className={clsx('flex flex-wrap items-center truncate py-2', {
				'w-[350px]':
					fileType === 'VIDEO' ||
					fileType === 'IMAGE' ||
					fileType === 'FILE' ||
					fileType === 'LOCATION',
				'w-[290px]': fileType === 'AUDIO' || fileType === 'CONTACT'
			})}
		>
			{splicedReactedUsers
				.filter((emoji: EmojiType) => !emoji.emoji.startsWith('U'))
				.map((emoji, i) => {
					return (
						<div
							className="chat-emoji-custom mb-1.5 mr-2 flex transform cursor-pointer items-center
							rounded-2xl bg-white px-2 py-1 leading-none shadow-md transition hover:-translate-y-1 active:shadow-none active:shadow-inner dark:bg-white/20"
							key={i}
						>
							<div className={`chat-emoji__unicode `}>
								<EmojiCustom emoji={emoji.emoji} size={20} />
							</div>
							<div className="chat-emoji__count ml-2 text-sm font-bold  text-kdark dark:text-white">
								{emoji?.users.length > 1 && emoji?.users.length}
							</div>
						</div>
					);
				})}
			<>
				{reactedUsers.length > 5 && !inModal && (
					<div
						onClick={(evt) => {
							evt.stopPropagation();
							setViewAll((prev) => !prev);
						}}
						className="chat-emoji-custom mb-1.5 mr-2 flex transform cursor-pointer items-center rounded-2xl
							bg-white px-3 py-1.5 text-15px leading-none tracking-wider shadow-md transition hover:-translate-y-1 active:shadow-none active:shadow-inner dark:bg-white/20 dark:text-white"
					>
						...
					</div>
				)}
			</>
		</div>
	);
}
