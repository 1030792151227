import React, { useEffect } from 'react';
import { Field } from 'formik';
import { CustomDTPicker } from 'shared/components/Fields';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AsyncSelectJs from 'shared/components/Fields/AsyncSelectJs';
import { KntTwilioStyle } from 'shared/services';
import config from 'config';
import { useAppState } from 'shared/state';
import clsx from 'clsx';
import { useSwitch } from 'shared/hooks';
import { FormControlLabel } from 'shared/components/Elements';
import { IOSSwitch } from 'shared/components/Elements/Fields/SwitchComp';
import dayjs from 'dayjs';
import { SupRequired } from 'shared/components';
import { useTranslation } from 'react-i18next';

type Props = {
	state: any;
	setState: any;
	setFieldValue: (name: string, value: any) => void;
	values: Record<string, any>;
};

export function CreateCaseDueDate({ state, setState, setFieldValue, values }: Props) {
	const { companyId, caseType, isDarkMode } = useAppState();
	const { checked, handleChange } = useSwitch({ due_date_toggle: false });
	const { t } = useTranslation();

	useEffect(() => {
		//If toggle is on, we set default ETC value to three days advance from now. 3340's fix.
		if (checked.due_date_toggle) {
			const defaultDate = dayjs().hour(8).minute(0).second(0).add(1, 'days').format();
			setFieldValue('due_date', defaultDate);
			setState((prevState: any) => ({
				...prevState,
				isDateTimeSet: true
			}));
		} else {
			setFieldValue('due_date', null);
			setState((prevState: any) => ({
				...prevState,
				isDateTimeSet: false
			}));
		}
	}, [checked.due_date_toggle]);

	return (
		<div className="react-select-dropdown mb-4">
			<div className="flex items-center">
				<FormControlLabel
					control={
						<IOSSwitch
							checked={checked.due_date_toggle}
							onChange={handleChange}
							name="due_date_toggle"
						/>
					}
					label={t('expected_time_of_completion')}
				/>
			</div>
			{checked.due_date_toggle && (
				<>
					<div className="my-4 flex">
						<div className={`flex-grow ${state.isDateTimeSet ? 'date-set' : 'date-unset'}`}>
							<Field
								component={CustomDTPicker}
								name="due_date"
								inputVariant="outlined"
								size="small"
								dateValue={values?.due_date}
								fullWidth
								emptyLabel={dayjs()
									.hour(8)
									.minute(0)
									.add(1, 'days')
									.format('ddd, MMM DD, YYYY, h:mm:ss a')}
								isDateTimeSet={state.isDateTimeSet}
								onChange={() => {
									setState((prevState: any) => ({
										...prevState,
										isDateTimeSet: true
									}));
								}}
							/>
						</div>
						{state.isDateTimeSet && (
							<>
								{/* Case: 4185 - removed x button bcs ETC toggler is doing x button's job, the button is not needed anymore */}
								{/* <div
									className="case-form__input-row-icon mx-1.5"
									onClick={() =>
										setState((prevState: any) => ({
											...prevState,
											isDateTimeSet: false
										}))
									}
								>
									<IconButton>
										<CloseIcon />
									</IconButton>
								</div> */}
								<div className={clsx('mx-1.5 min-w-[150px]')}>
									<Field
										component={AsyncSelectJs}
										customStylesProps={{
											...KntTwilioStyle,
											menu: (provided: any) => ({
												...provided,
												background: isDarkMode ? '#424242' : 'hsl(0, 0%, 100%)'
											}),
											singleValue: (provided: any) => ({
												...provided,
												color: isDarkMode ? '#fff' : '#424653'
											}),
											control: (provided: any) => ({
												...provided,
												background: isDarkMode ? '#424242' : 'hsl(0, 0%, 100%)'
											})
										}}
										name="complete_status"
										optionLabel="title"
										optionValue="id"
										isSearchable
										isCached
										placeholder={t('to_status_hint')}
										reverse={true}
										loadOptionsUrl={`/${companyId}/my/company/case_statuses/`}
										loadOptionsParams={(search: string) => ({
											extra: {
												search,
												is_staff: caseType === config.STAFF ? 'true' : ''
											}
										})}
										loadOptionsKey=""
									/>
								</div>
							</>
						)}
					</div>
				</>
			)}
		</div>
	);
}
