export function sortChatList<
	T extends { pin_time: string | null; last_message_time: number; reversed_date?: number }
>(items: T[]): T[] {
	return items.sort((itemA, itemB) => {
		//if reverse ordering is active, then sort by reversed_data
		if (itemA.reversed_date && itemB.reversed_date) {
			return itemA.reversed_date - itemB.reversed_date;
		}

		//To stand latest pinned group up - for sorting sorted pinned groups.
		if (itemA.pin_time && !itemB.pin_time) {
			return -1;
		}

		if (!itemA.pin_time && itemB.pin_time) {
			return 1;
		}

		const aHasPinTime = Boolean(itemA.pin_time);
		const bHasPinTime = Boolean(itemB.pin_time);

		// handle case when both compared chats have pin_time
		if (aHasPinTime && bHasPinTime) {
			return itemB.last_message_time - itemA.last_message_time;
		}

		// both items don't have pin time, sort by last_message_time
		return itemB.last_message_time - itemA.last_message_time;
	});
}
