import { useChatContext, useChatInputContext } from 'shared/contexts';
import { storage } from 'shared/services';
import { useUpdateEntities } from '../useUpdateEntities';

const SHOW_LOGS = false;

export function useDraftFunctions() {
	const { entityType, entityId } = useChatContext();
	const { message, reply, editingMessage } = useChatInputContext();
	const { updateEntities } = useUpdateEntities();

	function getLS(entityIdArg?: number): { [key: string]: any } {
		const entity = JSON.parse(storage.get(`${entityType}Draft`) || '{}');
		if (entityIdArg) {
			if (entityIdArg in entity) return entity[entityIdArg];
		}
		return entity;
	}

	function updateEntityDraft(type: 'add' | 'reset', draftMessageContent?: string) {
		let draftMessage = null;

		if (type === 'add') {
			draftMessage = {
				editing_id: editingMessage ? editingMessage.id : null,
				id: entityId,
				reply_to: reply && reply.id ? reply.id : null,
				text: draftMessageContent ?? message
			};
		}

		updateEntities({
			entity: entityType === 'case' ? 'cases' : 'ims-chats',
			entityId,
			updatingData: {
				draft_message: draftMessage
			}
		});
	}

	function removeLS(entityIdArg: number = entityId, callback?: () => void) {
		const entity = { ...getLS() };
		if (entityIdArg in entity) delete entity[entityIdArg];
		if (process.env.NODE_ENV !== 'production' && SHOW_LOGS) {
			console.log('removing draft', entity);
		}

		storage.set(`${entityType}Draft`, JSON.stringify(entity));
		if (typeof callback === 'function') setTimeout(callback, 0);
	}

	return {
		getLS,
		updateEntityDraft,
		removeLS
	};
}
