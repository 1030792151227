import React, { useMemo } from 'react';
import { ErrorBoundary, KntButton } from 'shared/components';
import { FilterWrapper } from './FilterWrapper';
import useDimensions from 'react-use-dimensions';
import { NCTemplates } from './NCTemplates';
import { NCEditTemplate } from './NCEditTemplate';
import { NCAddFilterItems } from './NCAddFilterItems';
import { IconAddFilter } from 'shared/icons';
import { useAppState } from 'shared/state';
import { useCaseFilterFunctions, useNewCaseFilter } from '../hooks';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

type Props = {
	handleModalOpen: () => void;
};

export function NewCaseFilterInner({ handleModalOpen }: Props) {
	const { t } = useTranslation();
	const [caseFilterRef, { height: caseFilterHeight }] = useDimensions();
	const { activeFilterTemplate, isAdmin } = useAppState();
	const { initialValues, onSubmit, goToAllCasesPage } = useNewCaseFilter(handleModalOpen);
	const { isImmediateApply, addNewFilterTemplate, saveAndApply } = useCaseFilterFunctions();

	const isFilterTemplateEmpty = activeFilterTemplate?.fields_json?.length;

	const CaseFilterFormikSchema = useMemo(
		() =>
			Yup.object().shape({
				title: Yup.string()
					.required(t('required'))
					.max(40, t('maximum_character_validation', { maximum: 40 }))
			}),
		[t]
	);

	return (
		<Formik
			onSubmit={(values, actions) => {
				onSubmit(values, actions, isImmediateApply);
			}}
			initialValues={initialValues}
			enableReinitialize
			validationSchema={CaseFilterFormikSchema}
		>
			{({ handleSubmit, values, setFieldValue, resetForm, isSubmitting }) => {
				const isThereAnySubdepartments =
					Array.isArray(values.department) &&
					values.department.filter((dep) => dep.sub_departments_count > 0);

				if (process.env.NODE_ENV === 'development') {
					console.log('values new case filter', values);
				}
				return (
					<form onSubmit={handleSubmit}>
						<div className="new-c-filter w-[1086px] p-6 pt-1 font-poppins" ref={caseFilterRef}>
							<div className="new-c-filter__header flex-center-between mb-4">
								<div className="new-c-filter__heading text-base font-bold text-kdark-text-2 dark:text-white">
									{t('filter_setup')}
								</div>
								<div className="new-c-filter__actions">
									{isAdmin ? (
										<KntButton
											className={'mr-5 border border-kgrey text-kgrey'}
											color="default"
											onClick={goToAllCasesPage}
										>
											{t('all_cases')}
										</KntButton>
									) : null}
									<KntButton
										className={'mr-5 border border-kgrey text-kgrey'}
										color="default"
										onClick={handleModalOpen}
									>
										{t('cancel')}
									</KntButton>
									{!activeFilterTemplate?.is_default ? (
										<KntButton
											type="submit"
											className="mr-4 disabled:!opacity-80 dark:text-white dark:hover:bg-primary-300 dark:focus:ring-primary-300"
											isSubmitting={isSubmitting}
											color="primary"
											isDisabled={!isFilterTemplateEmpty}
											onClick={saveAndApply}
										>
											{t('save_apply')}
										</KntButton>
									) : null}
									<KntButton
										className={
											'disabled:!opacity-80 dark:text-white dark:hover:bg-primary-300 dark:focus:ring-primary-300'
										}
										type="submit"
										isSubmitting={isSubmitting}
										isDisabled={!isFilterTemplateEmpty}
										color="primary"
									>
										{t('save_filter')}
									</KntButton>
								</div>
							</div>
							<div className="new-c-filter__content flex justify-between">
								<div className="new-c-filter__column relative w-[316px]">
									<FilterWrapper
										title={t('filter_template_list')}
										wrapperContentClasses="pb-12 "
										hasFixedContentHeight
										fixedHeight={caseFilterHeight ? caseFilterHeight - 127 : 0}
									>
										<NCTemplates />
									</FilterWrapper>
									<div className="absolute left-0 bottom-4 z-10 flex w-full justify-center">
										<button
											className=" group flex items-center"
											type="button"
											onClick={addNewFilterTemplate}
										>
											<IconAddFilter />
											<span className="kgrey-dark-text-2 text-md ml-2 transition group-hover:text-primary-dark">
												{t('add_new_filter_template')}
											</span>
										</button>
									</div>
								</div>
								<div className="new-c-filter__column w-[370px]">
									<FilterWrapper title={t('edit_filter')}>
										<NCEditTemplate
											setFieldValue={setFieldValue}
											isThereAnySubdepartments={!!(isThereAnySubdepartments as number[]).length}
											values={values}
										/>
									</FilterWrapper>
								</div>

								<div className="new-c-filter__column w-[316px]">
									{!activeFilterTemplate || !activeFilterTemplate.is_default ? (
										<FilterWrapper
											title={t('available_filter_options')}
											hasFixedContentHeight
											fixedHeight={caseFilterHeight ? caseFilterHeight - 127 : 0}
										>
											<NCAddFilterItems setFieldValue={setFieldValue} />
										</FilterWrapper>
									) : null}
								</div>
							</div>
						</div>
					</form>
				);
			}}
		</Formik>
	);
}

export function NewCaseFilter(props: Props) {
	return (
		<ErrorBoundary>
			<NewCaseFilterInner {...props} />
		</ErrorBoundary>
	);
}
