import React, { useState } from 'react';
import { useAppState } from 'shared/state';
import { useEntityForm } from 'modules/entity/hooks/useEntityForm';
import { useAxios, useGetEntity, useLoadAll, useNotistack, useUnreadCount } from 'shared/hooks';
import config from 'config';
import { StatusType } from '../components/Status';
import { useCaseExtraParams } from './useCaseExtraParams';
import { useTranslation } from 'react-i18next';

export function useStatusHeaderMenu() {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [statusDeleteLoader, setStatusDeleteLoader] = useState(false);
	const [markAllReadLoader, setMarkAllReadLoader] = useState(false);
	const open = Boolean(anchorEl);
	const { selectedStatus, setSelectedStatus, companyId, caseType, isStaff } = useAppState();
	const { fetchCompanyAllUnreadCounts } = useUnreadCount();
	const { showNotification } = useNotistack();
	const { reloadEntityAll } = useLoadAll();
	const { t } = useTranslation();

	const statusId = Number(selectedStatus?.statusId);

	const { selectedFilterTemplate, casesUrl, extraParams } = useCaseExtraParams({
		statusId
	});

	const statusesEntityName = `AllStatuses-${companyId}`;

	const { all: statuses } = useGetEntity({
		entity: 'statuses',
		entityName: statusesEntityName
	});

	const { fetchData: sendStatusReorderRequest } = useAxios({
		url: '',
		dynamicUrl: true,
		dynamicValues: true,
		method: 'post',
		cb: {
			success: (data, args) => {
				setStatusDeleteLoader(false);
				setSelectedStatus(null);
				showNotification({ message: 'Status has been deleted', variant: 'success' });
			},
			error: (error, args) => {
				showNotification({
					message: error?.response?.data?.message ?? t('something_went_wrong'),
					variant: 'error'
				});
			}
		}
	});

	// Mark All Read Request
	const { fetchData: markReadAllRequest } = useAxios({
		url: '',
		dynamicUrl: true,
		method: 'put',
		cb: {
			success: (data, args) => {
				const { fnCallback } = args[0] || {};

				if (typeof fnCallback === 'function') {
					fnCallback();
				}
			},
			error: (error, args) => {
				showNotification({
					message: error?.response?.data?.message ?? t('something_went_wrong'),
					variant: 'error'
				});
				setMarkAllReadLoader(false);
			}
		}
	});

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	const deleteStatusHandler = () => {
		setStatusDeleteLoader(true);
		handleStatusDeleteRequest();
	};

	//Delete Status Async Request
	const { handleRequest: handleStatusDeleteRequest } = useEntityForm({
		entity: 'statuses',
		name: `AllStatuses-${companyId}`,
		method: 'delete',
		url: `/${companyId}/my/company/case_statuses/${statusId}/`,
		deleteData: true,
		id: statusId,
		params: {
			extra: { is_staff: caseType === config.STAFF ? 'true' : '' }
		},
		cb: {
			success: async (data) => {
				//We must reorder statuses array. Because if we delete any status between status list there might be gap
				// That is, there might not be consistency in order_number
				reloadEntityAll({
					entity: 'statuses',
					name: `AllStatuses-${companyId}`,
					url: `/${companyId}/my/company/case_statuses/`,
					params: {
						extra: {
							is_staff: caseType === config.STAFF ? 'true' : 'false'
						}
					},
					dataKey: (data: any) => data,
					reverse: true,
					cb: {
						success: (data) => {
							sendStatusReorderRequest({
								url: `/${companyId}/my/company/case_statuses/insert_list/`,
								values: data
							});
						}
					}
				});
			},
			error: (error) => {
				setStatusDeleteLoader(false);
				showNotification({
					message: error?.response?.data?.message ?? t('something_went_wrong'),
					variant: 'error'
				});
			},
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			finally: () => {}
		}
	});

	const { fetchData: markFilteredStatusAsRead } = useAxios({
		url: casesUrl,
		params: {
			extra: {
				...extraParams,
				mark_as_read: 'true'
			}
		},
		cb: {
			success: async (data) => {
				await afterMarkReadAllSuccessHandler();
			},
			error: (error) => {
				showNotification({
					message: error?.response?.data?.message ?? t('something_went_wrong'),
					variant: 'error'
				});
				setMarkAllReadLoader(false);
			}
		}
	});

	async function markAllReadHandler() {
		setMarkAllReadLoader(true);
		if (selectedFilterTemplate) {
			await markFilteredStatusAsRead();
		} else {
			await markReadAllRequest({
				url: `/${companyId}/my/company/case_statuses/${statusId}/mark_as_read/`,
				fnCallback: async () => {
					await afterMarkReadAllSuccessHandler();
				}
			});
		}
	}

	async function afterMarkReadAllSuccessHandler() {
		await fetchCompanyAllUnreadCounts();

		showNotification({ message: 'The Cases Marked As Read', variant: 'success' });
		setSelectedStatus(null);
		setMarkAllReadLoader(false);
	}

	return {
		open,
		anchorEl,
		handleClick,
		handleCloseMenu,
		deleteStatusHandler,
		statusDeleteLoader,
		markAllReadLoader,
		markAllReadHandler
	};
}
