import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';

type Props = {
	clipboardFile: any;
	handleClipboardClose: any;
	clipboardImageRef: any;
};

export function CopyPasteDialog({ clipboardFile, handleClipboardClose, clipboardImageRef }: Props) {
	const { t } = useTranslation();
	return (
		<Dialog
			open={!!clipboardFile}
			onClose={handleClipboardClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{t('you_sure_to_send_from_clipboard')}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description" className="text-center">
					{clipboardFile && (
						<span className="clipboard-image-wr flex-centered">
							<img src="" alt="" className="clipboard-img" ref={clipboardImageRef} />
						</span>
					)}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClipboardClose} color="primary">
					{t('cancel')}
				</Button>
				<Button onClick={() => handleClipboardClose(clipboardFile)} color="primary" autoFocus>
					{t('send')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
