import { useAppState } from 'shared/state';
import { useCallback, useEffect, useState } from 'react';
import { UserType } from 'shared/typings/user.types';

type Props = {
	caseId: number | null;
	staffAccessList: UserType[];
	setFieldValue: any;
	setAssignedUsers: any;
	assignedUsers: any[] | null;
	caseAssigneeList: UserType[];
};
export function useCDAssigneeEdit({
	caseId,
	staffAccessList,
	setFieldValue,
	setAssignedUsers,
	assignedUsers,
	caseAssigneeList
}: Props) {
	const { companyId } = useAppState();
	const [assigneeTypeValue, setAssigneeTypeValue] = useState('all');
	const [localAssigneeUsers, setLocalAssigneeUsers] = useState<any>(caseAssigneeList);

	useEffect(() => {
		setFieldValue('assignee_ids', assignedUsers);
	}, [assignedUsers]);

	//todo: remove later below
	/*const loadAssigneeUsers = useCallback(async () => {
		const { data } = await api.request.get(
			queryBuilder(`/${companyId}/cases/as_business/${caseId}/assigned_users/`, {})
		);
		//setAssignedUsers
		if (Array.isArray(data)) {
			setAssignedUsers(data);

			//Set local Assignee users (We need this, because setAssignedUsers function is not working correctly and it is not setting loaded assigneed users)
			setLocalAssigneeUsers(data);
		} else {
			setAssignedUsers([]);
		}
	}, [open, caseId, companyId]);*/

	// set assignee users (We need this, because setAssignedUsers function is not working correctly and it is not setting loaded assigneed users)
	useEffect(() => {
		if (localAssigneeUsers.length && !assignedUsers?.length) {
			setAssignedUsers(localAssigneeUsers);
		}
	}, [localAssigneeUsers, assignedUsers]);

	//Assignee type radio buttons change handler
	const handleAssigneeTypeChange = useCallback((event) => {
		setAssigneeTypeValue(event.target.value);
	}, []);

	const removeMemberFromAssigneeList = (members: number[]) => {
		const filteredAssigneeUsers = caseAssigneeList?.filter((member: any) =>
			members.includes(member?.user ? member.user?.id : member.id)
		);

		setAssignedUsers(filteredAssigneeUsers);
		setLocalAssigneeUsers(filteredAssigneeUsers);
	};

	//when user is removed in access list and he/she is in assignee list,
	// need to remove that user from assignees list as well
	useEffect(() => {
		if (staffAccessList && staffAccessList.length) {
			const membersIds = staffAccessList.map((member: any) =>
				member?.user ? member?.user?.id : member?.id
			);
			removeMemberFromAssigneeList(membersIds);
		}
	}, [staffAccessList]);

	const handleAssignedMember = (option: any) => {
		setAssignedUsers(option);
		setLocalAssigneeUsers(option);
	};

	return {
		assigneeTypeValue,
		handleAssigneeTypeChange,
		handleAssignedMember
	};
}
