import React from 'react';
import { Component } from 'react';
import MicRecorder from 'mic-recorder-to-mp3';
import { ErrorBoundary } from 'shared/components';
import { Command } from 'shared/contexts/reducers/chatInputReducer';
const Mp3Recorder = new MicRecorder({ bitRate: 128 });

type Props = {
	command: Command;
	onStop: (blob: any) => void;
};

type State = {
	isRecording: boolean;
	blobURL: string;
	isBlocked: boolean;
	stream: any;
};

class RecordVoice extends Component<Props, State> {
	state = {
		isRecording: false,
		blobURL: '',
		isBlocked: false,
		stream: null,
		mp3Recorder: new MicRecorder({ bitRate: 128 })
	};

	componentDidMount() {
		// this.props.command === 'start' &&  navigator &&
		// navigator.mediaDevices.getUserMedia(
		// 	{ audio: true },
		// 	() => {
		// 		console.log("Permission Granted");
		// 		this.setState({ isBlocked: false });
		// 	},
		// 	() => {
		// 		console.log("Permission Denied");
		// 		this.setState({ isBlocked: true });
		// 	}
		// );
	}

	componentWillUnmount(): void {
		this.cancelRecording();
	}

	componentDidUpdate(prevProps: Props) {
		if (
			this.props.command &&
			this.props.command !== 'none' &&
			prevProps.command !== this.props.command
		) {
			if (this.props.command === 'start') {
				navigator.mediaDevices
					.getUserMedia(
						{ audio: true }
						// () => {
						// 	console.log('Permission Granted');
						// 	this.setState({ isBlocked: false });
						// 	this[this.props.command]();
						// },
						// () => {
						// 	console.log('Permission Denied');
						// 	this.setState({ isBlocked: true });
						// }
					)
					.then((stream) => {
						this.setState({ isBlocked: false });
						this.setState({ stream });
						// @ts-ignore
						this[this.props.command]();
					})
					.catch((err) => {
						this.setState({ isBlocked: true });
						if (err.toString().includes('NotFoundError: Requested device not found'))
							alert('Mic not detected');
						else if (err.toString().includes('Permission denied'))
							alert('Unblock microphone usage for this site please');
						else alert('Error recording audio');
					});
			} else {
				this[this.props.command]();
			}
		}
	}

	start = () => {
		if (this.state.isBlocked) {
			// console.log("Permission Denied");
		} else {
			this.state.mp3Recorder
				.start()
				.then(() => {
					this.setState({ isRecording: true });
				})
				.catch((e: Error) => console.error(e));
		}
	};

	stop = () => {
		const { onStop } = this.props;

		this.state.mp3Recorder
			.stop()
			.getMp3()
			//@ts-ignore
			.then(([buffer, blob]) => {
				const blobURL = URL.createObjectURL(blob);
				this.setState({ blobURL, isRecording: false });
				// console.log("blob", blob);
				// console.log("blobURL", this.state.blobURL);
				onStop(blob);
				// @ts-ignore
				this.state.stream?.getTracks()[0].stop();
			})
			.catch((e: Error) => console.log(e));

		// if (!window.streamReference) return;

		// window.streamReference.getAudioTracks().forEach(function (track) {
		// 	track.stop();
		// });

		// window.streamReference.getVideoTracks().forEach(function (track) {
		// 	track.stop();
		// });

		// window.streamReference = null;
	};

	cancelRecording = () => {
		if (this.state.isRecording) {
			this.state.mp3Recorder
				.stop()
				.getMp3()
				.catch((e: Error) => console.error('Error cancelling recording:', e));
		}
		// @ts-ignore
		this.state.stream?.getTracks().forEach((track) => track.stop());
	};

	render() {
		return null;
	}
}

export default function RecordVoiceWithErrorBoundary(props: Props) {
	return (
		<ErrorBoundary isCompact>
			<RecordVoice {...props} />
		</ErrorBoundary>
	);
}
