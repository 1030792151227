import React from 'react';
import { CaseDetailsRow } from './CaseDetailsRow';
import { KntInputField } from 'shared/components/Fields';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

export function CdNote() {
	const {t} = useTranslation()
	return (
		<CaseDetailsRow fieldName={t('notes')} align="top">
			<Field
				name="note"
				component={KntInputField}
				placeholder={t('case_notes')}
				type="textarea"
				inputElementClassNames="border-kgrey-inputBd bg-transparent"
			/>
		</CaseDetailsRow>
	);
}
