import React from 'react';
import clsx from 'clsx';

export type BusinessDetailsInfoType = {
	title: string;
	children: React.ReactNode;
	text: number | string;
	textClassName?: string;
	wrapperClassnames?: string;
};

export function DetailsInfo({
	title,
	children,
	text,
	textClassName = '',
	wrapperClassnames = ''
}: BusinessDetailsInfoType) {
	return (
		<div
			className={clsx(
				'mx-2.5 mb-4 rounded-10 bg-white p-3.5 dark:border-sm dark:border-zinc-700 dark:bg-kdark-lighter ',
				wrapperClassnames
			)}
		>
			<h4 className="mb-2.5 text-sm font-medium text-kgrey">{title}</h4>
			<div className="flex items-center">
				<div className="flex h-9 w-9 flex-shrink-0 items-center justify-center rounded-full bg-kgrey-bgDashboardItem dark:bg-black">
					{children}
				</div>
				<p
					className={clsx('mx-4 text-2xl font-semibold leading-5 text-primary-dark', textClassName)}
				>
					{text}
				</p>
			</div>
		</div>
	);
}
