import { IMessage, ScrollToMsg } from 'shared/interfaces';
import { useMessageFields } from 'shared/hooks/chat-hooks/useMessageFields';
import { RepliedMediaMsgWrapper } from './RepliedMediaMsgWrapper';
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import React, { useCallback } from 'react';
import { useGoogleMapsInit } from '../../../../pages/BusinessProfileMain/components/BusinessProfile/hooks';
import { useWhiteModal } from '../../../hooks';
import { useCachedReactedUsers } from '../../../hooks/chat-hooks/useCachedReactedUsers';
import clsx from 'clsx';
import { EmojiCountInfo } from './EmojiCountInfo';
import { DeletedMsg } from './DeletedMsg';

type Props = {
	message: IMessage;
	setScrollToMsgId: ScrollToMsg;
};

const containerStyle = {
	width: '100%',
	height: '100%'
};

const options = {
	streetViewControl: false,
	zoomControl: true,
	scrollwheel: false
};

export const LocationMsg = ({ message, setScrollToMsgId }: Props) => {
	const { is_deleted, location, replied_to, isOwnMsg, reacted_users, file_type, messageId } =
		useMessageFields(message);

	const { isLoaded } = useGoogleMapsInit();

	const [map, setMap] = React.useState(null);

	const [selected, setSelected] = React.useState(false);

	const onLoad = useCallback(function callback(map) {
		const bounds = new window.google.maps.LatLngBounds();
		map.fitBounds(bounds);
		setMap(map);
	}, []);

	const onUnmount = useCallback(function callback(map) {
		setMap(null);
	}, []);

	let position = [0, 0];
	if (location) {
		position = [location?.lat, location?.long];
	}

	const [lat, lng] = position;
	const locationName = location?.formatted_name;

	const {
		open: isEmojiCountOpen,
		modalHandler: isEmojiCountOpenHandler,
		WhiteModal: EmojiWhiteModal
	} = useWhiteModal();

	const { cachedReactions } = useCachedReactedUsers({
		reacted_users,
		file_type,
		isEmojiCountOpenHandler
	});

	if (is_deleted) {
		return <DeletedMsg isOwnMsg={isOwnMsg} type="location" />;
	}

	return (
		<div
			className={clsx(
				'overflow-hidden break-words text-sm text-kdark shadow-text-msg dark:text-white',
				{
					'rounded-b-xl rounded-tr-xl bg-white dark:bg-white/20': !isOwnMsg,
					'rounded-t-xl rounded-bl-xl bg-primary-200': isOwnMsg
				}
			)}
		>
			<RepliedMediaMsgWrapper
				replied_to={replied_to}
				isOwnMsg={isOwnMsg}
				setScrollToMsgId={setScrollToMsgId}
			>
				<div className={'location-msg relative h-[200px] w-96'}>
					{isLoaded ? (
						<GoogleMap
							mapContainerStyle={containerStyle}
							options={options}
							center={{
								lat,
								lng
							}}
							zoom={10}
							onLoad={onLoad}
							onUnmount={onUnmount}
						>
							<>
								<Marker
									key={locationName}
									position={{
										lat,
										lng
									}}
									onClick={() => {
										setSelected(true);
									}}
								/>
								{selected ? (
									<InfoWindow
										position={{ lat, lng }}
										onCloseClick={() => {
											setSelected(false);
										}}
									>
										<>
											<div>{locationName}</div>
										</>
									</InfoWindow>
								) : null}
							</>
						</GoogleMap>
					) : (
						<p>Loading map</p>
					)}
				</div>
			</RepliedMediaMsgWrapper>
			<div className="px-2">{reacted_users?.length ? cachedReactions : null}</div>
			<EmojiWhiteModal handleModalOpen={isEmojiCountOpenHandler} open={isEmojiCountOpen}>
				<EmojiCountInfo messageId={messageId} reactedUsers={reacted_users} />
			</EmojiWhiteModal>
		</div>
	);
};
