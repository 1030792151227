import React from 'react';
import { FormHeader } from 'shared/components';
import { useTranslation } from 'react-i18next';

type Props = {
	resetForm: any;
};

export function CreateCaseFormHeader({ resetForm }: Props) {
	const { t } = useTranslation();
	return <FormHeader resetForm={resetForm} title={t('create_case')} />;
}
