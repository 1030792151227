import * as React from 'react';

function SvgIconAdd(props: any) {
	return (
		<svg
			data-name="plus_circle [#1427]"
			xmlns="http://www.w3.org/2000/svg"
			width={17.364}
			height={17.364}
			{...props}
		>
			<path data-name="plus_circle [#1427] (Background)" fill="none" d="M0 0h17.364v17.364H0z" />
			<path
				data-name="plus_circle [#1427]"
				d="M8.682 14.194a5.662 5.662 0 01-5.788-5.513 5.662 5.662 0 015.788-5.512 5.662 5.662 0 015.788 5.512 5.662 5.662 0 01-5.788 5.513zm0-9.922a4.529 4.529 0 00-4.63 4.41 4.529 4.529 0 004.63 4.41 4.53 4.53 0 004.631-4.41 4.53 4.53 0 00-4.631-4.413zm0 6.615a.566.566 0 01-.579-.551v-1.1H6.946a.552.552 0 110-1.1h1.158v-1.1a.579.579 0 011.157 0v1.1h1.158a.552.552 0 110 1.1H9.261v1.1a.566.566 0 01-.578.55z"
				fill="#fff"
			/>
		</svg>
	);
}

export default SvgIconAdd;
