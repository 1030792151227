import Papa from 'papaparse';
import isFunction from 'lodash/isFunction';
import isValuesUnique from 'famulus/isValuesUnique';

/**
 * @param {File} csvFile
 * @param {Object} config
 */
export default function CSVFileValidator(csvFile, config) {
	return new Promise(function (resolve, reject) {
		Papa.parse(csvFile, {
			complete: function (results) {
				resolve(_prepareDataAndValidateFile(results.data, config));
			},
			error: function (error, file) {
				reject({ error: error, file: file });
			}
		});
	});
}

/**
 * @param {Array} csvData
 * @param {Object} config
 * @private
 */
function _prepareDataAndValidateFile(csvData, config) {
	const file = {
		inValidMessages: [],
		data: []
	};

	csvData.forEach(function (row, rowIndex) {
		const columnData = {};
		const headers = [];

		for (let i = 0; i < config.headers.length; i++) {
			const data = config.headers[i];

			if (!data.optional) {
				headers.push(data);
			}
		}

		if (row.length < headers.length) {
			return;
		}

		row.forEach(function (columnValue, columnIndex) {
			const valueConfig = config.headers[columnIndex];

			if (!valueConfig) {
				return;
			}

			// header validation
			if (rowIndex === 0) {
				if (valueConfig.name !== columnValue) {
					file.inValidMessages.push(
						isFunction(valueConfig.headerError)
							? valueConfig.headerError(columnValue)
							: 'Header name ' + columnValue + ' is not correct or missing'
					);
				}

				return;
			}

			if (valueConfig.required && !columnValue.length) {
				file.inValidMessages.push(
					isFunction(valueConfig.requiredError)
						? valueConfig.requiredError(valueConfig.name, rowIndex + 1, columnIndex + 1, csvData)
						: String(
								valueConfig.name +
									' is required in the ' +
									(rowIndex + 1) +
									' row / ' +
									(columnIndex + 1) +
									' column'
						  )
				);
			} else if (valueConfig.validate && !valueConfig.validate(columnValue)) {
				file.inValidMessages.push(
					isFunction(valueConfig.validateError)
						? valueConfig.validateError(valueConfig.name, rowIndex + 1, columnIndex + 1, csvData)
						: String(
								valueConfig.name +
									' is not valid in the ' +
									(rowIndex + 1) +
									' row / ' +
									(columnIndex + 1) +
									' column'
						  )
				);
			}

			if (valueConfig.optional) {
				columnData[valueConfig.inputName] = columnValue;
			}

			if (valueConfig.isArray) {
				columnData[valueConfig.inputName] = columnValue.split(',').map(function (value) {
					return value.trim();
				});
			} else {
				columnData[valueConfig.inputName] = columnValue;
			}
		});

		file.data.push(columnData);
	});

	_checkUniqueFields(file, config);

	return file;
}

/**
 * @param {Object} file
 * @param {Object} config
 * @private
 */
function _checkUniqueFields(file, config) {
	if (!file.data.length) {
		return;
	}

	config.headers
		.filter(function (header) {
			return header.unique;
		})
		.forEach(function (header) {
			if (!isValuesUnique(file.data, header.inputName)) {
				file.inValidMessages.push(
					isFunction(header.uniqueError)
						? header.uniqueError(header.name)
						: String(header.name + ' is not unique')
				);
			}
		});
}
