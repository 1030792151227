import React from 'react';
import ContentLoader from 'react-content-loader';
import { Scrollbars } from 'react-custom-scrollbars-2';
import EntityContainer from 'modules/entity/containers';
import { useChatContext } from 'shared/contexts';
import { IMessage, IUserMessage } from 'shared/interfaces';
import { SimpleChatMessage } from './SimpleChatMessage';
import { AvatarSized } from 'shared/components/Elements';
import { time } from 'shared/services';
import { MdDone, MdDoneAll } from 'react-icons/md';
import { DeletedMessageContent } from './DeletedMessageContent';
import { useTranslation } from 'react-i18next';

type Props = {
	messageId: number | undefined;
};

type ViewerTime = string | null;

type Viewer = {
	delivered: ViewerTime;
	emoji: any;
	message_id: number;
	played_time: ViewerTime;
	read: ViewerTime;
	recipient: IUserMessage;
};
export function MessageInfo({ messageId }: Props) {
	const { entityType } = useChatContext();
	const { t } = useTranslation();
	return (
		<EntityContainer.One
			id={messageId as number}
			relations={''}
			entity={`${entityType}Messages`}
			name={`Message-${messageId}`}
			url={`/messages/${messageId}/`}
			params={{
				extra: { with_deleted: 'true' }
			}}
		>
			{({ item: message, isFetched }) => {
				const typedMessage = { ...message } as IMessage;
				return (
					<div className="msg-info w-full">
						<div className="msg-info__header w-full border-b pb-4 ">
							<h2 className={'mb-4 text-xl font-semibold'}>{t('message_info')}</h2>
							{isFetched ? (
								<>
									<div className="msg-info__msg-text mb-2">
										<div className="ml-5 mb-1.5 text-sm font-light uppercase">
											{t('message_dot')}
										</div>
										<div className="max-w-md rounded-xl border border-kgrey-msgBorder p-3 text-sm shadow-text-msg">
											{typedMessage.is_deleted ? (
												<DeletedMessageContent
													messageId={typedMessage?.message_id ?? typedMessage?.id}
													text={typedMessage.text}
													fileType={typedMessage.file_type}
													fileUrl={typedMessage.file?.url}
													contact={typedMessage.contact}
													location={typedMessage.location}
												/>
											) : (
												<SimpleChatMessage
													text={typedMessage.text}
													fileType={typedMessage.file_type}
												/>
											)}
										</div>
									</div>
									<div className="mb-1 flex items-center font-light uppercase">
										<div className="icon mr-1">
											<MdDone />
										</div>
										<div className="text-sm">{t('sent_by')}</div>
									</div>
									<div className="msg-info__sender flex items-center">
										<div className="msg-info__sender-avatar mx-2">
											<AvatarSized
												size={36}
												smallSize={36}
												className={''}
												letter={`${typedMessage?.from_user?.first_name?.[0]}${typedMessage?.from_user?.last_name?.[0]}`}
												alt={`${typedMessage?.from_user?.first_name} ${typedMessage?.from_user?.last_name}`}
												src={
													typedMessage?.from_user?.avatar?.thumbnail_150 ||
													typedMessage?.from_user?.avatar?.thumbnail ||
													typedMessage?.from_user?.avatar?.url
												}
												color={typedMessage?.from_user?.color}
											/>
										</div>
										<div className="msg-info__sender-info">
											<div className="msg-info__sender-name">
												{`${typedMessage?.from_user?.first_name} ${typedMessage?.from_user?.last_name}`}
											</div>
											<div className="msg-info__data text-sm text-kgrey">
												{time.toLastTime(typedMessage.sent_time, true)}
											</div>
										</div>
									</div>
								</>
							) : (
								<MessageInfoHeaderLoader />
							)}
						</div>
						<div className={'pt-4'}>
							<div className="mb-1 flex items-center font-light uppercase">
								<div className="icon mr-1 text-primary">
									<MdDoneAll />
								</div>
								<div className="text-sm">{t('seen_by')}</div>
							</div>
							<div className="msg-info__content h-28 w-full">
								<Scrollbars style={{ height: '100%' }}>
									<EntityContainer.All
										entity={`MessageViewer`}
										name={`MessageViewer-${messageId}`}
										url={`messages/${messageId}/viewers/`}
										params={{
											extra: {
												with_deleted: 'true'
											}
										}}
										dataKey={(data: any) => data}
										replaceIds={true}
										primaryKey={'id'}
									>
										{({ items: viewers, isFetched: ViewersIsFetched, meta }) => {
											const viewerItems = Object.values(viewers) as Viewer[];
											return (
												<>
													{ViewersIsFetched ? (
														<>
															{viewerItems.map((item: any) => {
																return (
																	<div
																		className="msg-info__content-user mb-3 flex items-center"
																		key={item.key}
																	>
																		<div className="avatar mx-3 flex-shrink-0">
																			<AvatarSized
																				size={36}
																				smallSize={36}
																				className={''}
																				letter={`${item.recipient.first_name[0]}${item.recipient.last_name[0]}`}
																				alt={`${item.recipient.first_name} ${item.recipient.last_name}`}
																				src={
																					item.recipient?.avatar?.thumbnail_150 ||
																					item.recipient?.avatar?.thumbnail ||
																					item.recipient?.avatar?.url
																				}
																				color={item.recipient.color}
																			/>
																		</div>
																		<div className="msg-info__user-content">
																			<div className="msg-info__user-name mb-1 text-sm">
																				{`${item.recipient.first_name} ${item.recipient.last_name}`}
																			</div>
																			<div className="msg-info__content-info-list">
																				{item.delivered ? (
																					<DeliveryInfoItem
																						infoTime={item.delivered}
																						text={t('delievered_time')}
																					/>
																				) : null}
																				{item.read ? (
																					<DeliveryInfoItem
																						infoTime={item.read}
																						text={t('seen_time')}
																					/>
																				) : null}
																				{item.played_time ? (
																					<DeliveryInfoItem
																						infoTime={item.delivered}
																						text={'Played'}
																					/>
																				) : null}
																			</div>
																		</div>
																	</div>
																);
															})}
														</>
													) : (
														<ViewerLoaderList />
													)}
												</>
											);
										}}
									</EntityContainer.All>
								</Scrollbars>
							</div>
						</div>
					</div>
				);
			}}
		</EntityContainer.One>
	);
}

function DeliveryInfoItem({ text, infoTime }: { text: string; infoTime: string }) {
	return (
		<div className="msg-info__content-item mb-1 items-center justify-between text-sm leading-none text-kgrey lg:flex">
			<div className="text mr-2">{`${text}`}</div>
			<div className="text">{time.toLastTime(infoTime, true, false)}</div>
		</div>
	);
}

const MessageInfoHeaderLoader = () => (
	<ContentLoader
		speed={2}
		width={369}
		height={100}
		viewBox="0 0 369 100"
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
	>
		<rect x="4" y="0" rx="5" ry="5" width="221" height="19" />
		<rect x="5" y="34" rx="2" ry="2" width="141" height="11" />
		<circle cx="75" cy="125" r="27" />
		<circle cx="28" cy="77" r="21" />
		<rect x="64" y="59" rx="4" ry="4" width="185" height="17" />
		<rect x="63" y="87" rx="4" ry="4" width="129" height="14" />
	</ContentLoader>
);

const ViewerLoaderList = () => {
	return (
		<>
			{[...Array(6)].map((item, i) => (
				<ContentLoader
					key={i}
					speed={2}
					width={369}
					height={60}
					viewBox="0 0 369 60"
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
				>
					<circle cx="75" cy="125" r="27" />
					<circle cx="27" cy="26" r="20" />
					<rect x="62" y="1" rx="4" ry="4" width="130" height="16" />
					<rect x="62" y="26" rx="3" ry="3" width="128" height="9" />
					<rect x="62" y="40" rx="3" ry="3" width="129" height="9" />
				</ContentLoader>
			))}
		</>
	);
};
