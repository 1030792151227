import React from 'react';
import NestedMenuItem from 'material-ui-nested-menu-item';
import { isMobile } from 'react-device-detect';
import MenuItem from '@material-ui/core/MenuItem';
import { StatusType } from './Status';
import { useChangeStatus } from '../hooks/useChangeStatus';
import { useQueryParams } from '../../../shared/hooks';
import { useTranslation } from 'react-i18next';

type Props = {
	anchorEl: any;
	handleCloseMenu: () => void;
	statusId: number;
	caseId: number;
};
export function CaseMenuStatusChange({ anchorEl, handleCloseMenu, statusId, caseId }: Props) {
	const { handleStatusChange, statuses, handleMobileStatusChange } = useChangeStatus(
		caseId,
		statusId
	);
	const { t } = useTranslation();
	const { query } = useQueryParams<{ case: number; status: number }>();

	return (
		<>
			{isMobile ? (
				<MenuItem
					onClick={(e) => {
						e.stopPropagation();
						handleMobileStatusChange();
						handleCloseMenu();
					}}
				>
					{t('change_status')}
				</MenuItem>
			) : (
				<NestedMenuItem
					label={t('change_status')}
					parentMenuOpen={Boolean(anchorEl)}
					onClick={(e) => {
						e.stopPropagation();
						handleCloseMenu();
					}}
				>
					<div className="relative z-30 flex max-h-96 flex-col overflow-y-auto">
						{statuses.items &&
							(statuses.items as StatusType[]).map((status) => {
								return (
									<MenuItem
										key={status.id}
										disabled={statusId === status.id}
										className="flex-shrink-0 dark:text-white"
										onClick={async () => {
											handleCloseMenu();
											await handleStatusChange(status, 'status' in query);
										}}
									>
										{status.title}
									</MenuItem>
								);
							})}
					</div>
				</NestedMenuItem>
			)}
		</>
	);
}
