import React from 'react';

export function IconDashboard({ className = '' }: { className?: string }) {
	return (
		<svg
			width="12"
			height="12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M1.333 6.583h3.5c.321 0 .584-.262.584-.583V1.333A.585.585 0 0 0 4.833.75h-3.5a.585.585 0 0 0-.583.583V6c0 .32.262.583.583.583Zm0 4.667h3.5c.321 0 .584-.262.584-.583V8.333a.585.585 0 0 0-.584-.583h-3.5a.585.585 0 0 0-.583.583v2.334c0 .32.262.583.583.583Zm5.834 0h3.5c.32 0 .583-.262.583-.583V6a.585.585 0 0 0-.583-.583h-3.5A.585.585 0 0 0 6.583 6v4.667c0 .32.263.583.584.583Zm-.584-9.917v2.334c0 .32.263.583.584.583h3.5c.32 0 .583-.263.583-.583V1.333a.585.585 0 0 0-.583-.583h-3.5a.585.585 0 0 0-.584.583Z"
				fill="currentColor"
			/>
		</svg>
	);
}
