import React from 'react';
import { Emoji, getEmojiDataFromNative } from 'emoji-mart';
import data from 'emoji-mart/data/all.json';

type Props = {
	emoji: string;
	size: number;
};
export const EmojiCustom = ({ emoji, size }: Props) => {
	const getEmojiData = (emojiUnicode: string) =>
		// @ts-ignore
		getEmojiDataFromNative(emojiUnicode, 'apple', data);
	const emojiData = getEmojiData(emoji);
	const emojiDataDefault = getEmojiData('♥️');
	return <Emoji emoji={emojiData ? emojiData : emojiDataDefault} size={size} />;
};
