import clsx from 'clsx';
import React from 'react';
import { BiSearch } from 'react-icons/bi';
import { useTranslationHelpers } from 'shared/hooks/useTranslationHelpers';

export function SwitchBusinessSearch({
	query,
	handleSearch,
	placeholder
}: {
	query: string;
	handleSearch: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
	placeholder?: string;
}) {
	const { isRTL } = useTranslationHelpers();

	return (
		<div className={'flex justify-end'}>
			<div className={'relative inline-block'}>
				<div
					className={clsx('absolute top-1/2 -translate-y-2/4 text-2xl text-[#A5A7B2]', {
						'right-1': isRTL,
						'left-1': !isRTL
					})}
				>
					<BiSearch />
				</div>
				<input
					value={query}
					onChange={handleSearch}
					type="search"
					className={clsx(
						'w-[200px] rounded-2xl py-2 pr-4 focus:outline-none focus:ring-2 focus:ring-primary-300 dark:bg-kdark dark:text-white dark:focus:ring-primary',
						{ 'pl-8': !isRTL, 'pr-8': isRTL }
					)}
					placeholder={placeholder || 'Search'}
				/>
			</div>
		</div>
	);
}
