import { RefObject, useEffect, useRef } from 'react';
import { useTranslationHelpers } from './useTranslationHelpers';

export function useChatWindowResize<T extends HTMLElement = HTMLDivElement>(
	setChatPanelUpdatedWidth: (num: number) => void,
	element?: RefObject<T>
) {
	const { isRTL } = useTranslationHelpers();
	const mouseDownHandlerRef = useRef<(event: MouseEvent) => void>();

	useEffect(() => {
		const targetElement = element?.current;

		if (!(targetElement && targetElement.addEventListener)) {
			return;
		}

		if (mouseDownHandlerRef.current !== mouseDown) {
			mouseDownHandlerRef.current = mouseDown;
		}

		// Create event listener that calls handler function stored in ref
		const mouseDownListener = (event: MouseEvent) => {
			if (!!mouseDownHandlerRef?.current) {
				mouseDownHandlerRef.current(event);
			}
		};

		targetElement.addEventListener('mousedown', mouseDownListener);

		function mouseDown(e: MouseEvent) {
			let prevX = e.clientX;
			let prevY = e.clientY;

			const chatPanelEl = document.querySelector('.chat-panel');

			window.addEventListener('mousemove', mouseMove);
			window.addEventListener('mouseup', mouseUp);

			function mouseMove(e: MouseEvent) {
				const rect = chatPanelEl?.getBoundingClientRect();

				if (chatPanelEl && rect) {
					// Determine the new width based on RTL or LTR
					const newWidth = isRTL
						? rect.width - (prevX - e.clientX)
						: rect.width + (prevX - e.clientX);

					//@ts-ignore
					chatPanelEl.style.width = `${newWidth}px`;
				}

				prevX = e.clientX;
				prevY = e.clientY;
			}

			function mouseUp() {
				window.removeEventListener('mousemove', mouseMove);
				window.removeEventListener('mouseup', mouseUp);

				// Update width in state to calculate needed values for dynamically set some CSS properties in MainLayout
				const rect = chatPanelEl?.getBoundingClientRect();
				if (typeof rect?.width === 'number') {
					setChatPanelUpdatedWidth(rect.width);
				}
			}
		}

		return () => {
			targetElement.removeEventListener('mousedown', mouseDownListener);
		};
	}, [element, isRTL]);
}
