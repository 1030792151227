import { createStore, compose, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './reducers';
import rootSaga from './sagas';

import middlewares, { sagaMiddleware } from './middlewares';

// eslint-disable-next-line import/no-anonymous-default-export
export default (initialState = {}): any => {
	let store: any;

	if (process.env.NODE_ENV === 'production') {
		store = createStore(
			//@ts-ignore
			rootReducer,
			initialState,
			compose(applyMiddleware(...middlewares))
		);
	} else {
		store = createStore(
			//@ts-ignore
			rootReducer,
			composeWithDevTools(applyMiddleware(...middlewares))
		);
	}

	sagaMiddleware.run(rootSaga);

	if (module.hot) {
		module.hot.accept('./reducers', () => {
			// eslint-disable-next-line @typescript-eslint/no-var-requires
			store.replaceReducer(require('./reducers').default);
		});
	}

	return store;
};
