import get from 'lodash/get';
import { Dep } from './components/CaseItemMarkup';
import { Department } from './cases.types';
import isEmpty from 'lodash/isEmpty';
import { StatusType } from './components/Status';
import { IRootState } from '../../shared/interfaces';
import { getCaseEntityName } from '../../shared/services';
import { CaseType, CaseTypes } from '../../shared/state/reducer';
//These function were copy-pasted from web business. As they
// have already been tested well(manually) and they are working well I did not write type annotations
//to save time;
export const handleCaseInputChange = ({ event, setState }: any) => {
	const target = event.target;
	const value = target.value;
	const name = target.name;
	const isFieldRequired = target?.required;

	setState((prev: any) => ({
		...prev,
		data_fields_names: { ...prev.data_fields_names, [name]: value },
		data_fields_names_persisted: { ...prev.data_fields_names_persisted, [name]: value },
		data_fields_errors: {
			...prev.data_fields_errors,
			//Case:4134 - only update field errors if the data field is required
			...(isFieldRequired ? { [name]: value.length <= 0 } : {})
		}
	}));
};

export function getDepartmentPayload(values: any) {
	const ids = Object.keys(values.sub_department);

	if (ids.length > 1) {
		if (get(values, `sub_department.${ids[ids.length - 1]}`)) {
			return { id: Number(ids[ids.length - 2]) };
		} else {
			if (ids.length === 2) {
				return { id: get(values, 'department.id') };
			} else {
				return { id: Number(ids[ids.length - 3]) };
			}
		}
	} else {
		return { id: get(values, 'department.id') };
	}
}

export function getSubDepartmentPayload({ values, departmentRef }: any) {
	const ids = Object.keys(values.sub_department);
	const countRef = get(departmentRef.current, 'sub_departments_count');
	if (ids.length > 0 && countRef > 0) {
		if (get(values, `sub_department.${ids[ids.length - 1]}`) && Number(ids[ids.length - 1]) !== 1) {
			return { id: Number(ids[ids.length - 1]) };
		} else if (Number(ids[ids.length - 1]) === 1) {
			return {};
		} else {
			return { id: Number(ids[ids.length - 2]) };
		}
	} else {
		return {};
	}
}

export const createDataFieldsOnSubmit = ({ data_fields, data_fields_names }: any) => {
	return data_fields.length > 0
		? Object.keys(data_fields_names).reduce((acc: any, curr) => {
				const key = curr.split('_').join(' ');
				const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
				if (data_fields_names[curr].length > 0) {
					return [...acc, [capitalizedKey, data_fields_names[curr]]];
				} else {
					return [...acc];
				}
		  }, [])
		: null;
};

export const depOnChange = ({
	option,
	setFieldValue,
	titleRef,
	setState,
	data_fields_errors,
	data_fields_names
}: any) => {
	const depDataFields = get(option, 'data_field');
	setFieldValue('title', titleRef.current);
	if (depDataFields) {
		setState((prev: any) => ({
			...prev,
			department: option,
			data_fields: [...depDataFields],
			data_fields_names: depDataFields.reduce(
				(acc: any, curr: any) => ({ ...acc, [curr.fieldName]: '' }),
				data_fields_names
			),
			data_fields_errors: depDataFields.reduce((acc: any, curr: any) => {
				return curr.isRequired ? { ...acc, [curr.fieldName]: true } : { ...acc };
			}, data_fields_errors)
		}));
	} else {
		setState((prev: any) => ({
			...prev,
			department: option,
			data_fields: [],
			data_fields_names: {},
			data_fields_errors: {}
		}));
	}
	setTimeout(() => {
		setFieldValue('department', option);
	}, 0);
};

export const subDepOnChange = ({ values, setState, data_fields_names_persisted }: any) => {
	const depDataFields = get(values, 'department.data_field') || [];

	//Filtered data fields which are not duplicated in 'data_fields'
	const allSubDepFields = (Object.keys(values?.sub_department) || [])
		.map((subdep) => values?.sub_department[subdep] && values?.sub_department[subdep]?.data_field)
		.filter((dataField) => dataField)
		.reduce((acc, curr) => [...acc, ...curr], []);
	const allFields = [...depDataFields, ...allSubDepFields];
	setState((prev: any) => ({
		...prev,
		data_fields: allFields,
		data_fields_names: allFields.reduce(
			(acc, curr) => ({
				...acc,
				[curr.fieldName]:
					curr.fieldName in data_fields_names_persisted
						? data_fields_names_persisted[curr.fieldName]
						: ''
			}),
			{}
		),
		data_fields_errors: allFields.reduce((acc, curr) => {
			return curr.isRequired
				? {
						...acc,
						//4134 - disabling validation if data field has persisted value when sup department changes
						[curr.fieldName]: data_fields_names_persisted?.[curr.fieldName]?.length <= 0
				  }
				: { ...acc };
		}, {})
	}));
};

export const runSideEffectsAfterDepartmentChange = ({
	departmentRef,
	option,
	setFieldValue
}: any) => {
	departmentRef.current = option;
	if (option && option.sub_departments.length > 0) {
		setFieldValue('sub_department', {
			1: {
				id: 1,
				name: `Select Sub Department Name`,
				department: { id: option.id }
			}
		});
	} else {
		setFieldValue('sub_department', {});
	}
};
export const runSideEffectsAfterSubDepartmentChange = ({
	values,
	subdep,
	option,
	setFieldValue
}: any) => {
	//When we select any option
	if (option) {
		for (const subdepId in values.sub_department) {
			//Selecting the current option value
			if (Number(subdepId) === Number(subdep)) {
				/*If selected sub department does not have sub sub department
										We delete all other saved option s and replace current option value
									*/
				if (option.sub_departments.length === 0) {
					const obj = { ...values.sub_department };

					Object.keys(obj).forEach((subdepId) => {
						if (Number(subdepId) > Number(subdep)) {
							delete obj[subdepId];
						}
					});
					delete obj[subdep];

					setFieldValue('sub_department', { ...obj, [option.id]: option });
					/*
										if subdep has sub sub departments, delete all other options
										and save current subdeps sub department
								*/
				} else {
					const obj = { ...values.sub_department };

					Object.keys(obj).forEach((subdepId) => {
						if (Number(subdepId) > Number(subdep)) {
							delete obj[subdepId];
						}
					});
					delete obj[subdep];

					setFieldValue('sub_department', {
						...obj,
						[option.id]: option,
						[get(option, 'sub_departments[0].id')]: null
					});
				}
			}
		}
		//When we delete the option
	} else {
		for (const subdepId in values.sub_department) {
			if (Number(subdepId) === Number(subdep) || Number(subdepId) > Number(subdep)) {
				const obj = { ...values.sub_department };

				Object.keys(obj).forEach((subdepId) => {
					if (Number(subdepId) > Number(subdep)) {
						delete obj[Number(subdepId)];
					}
				});
				setFieldValue('sub_department', { ...obj, [subdep]: null });
			}
		}
	}
};

type DepSubInput = {
	department?: Dep;
	sub_department: Dep;
};

export const getDepartmentIcon = ({ department, sub_department }: DepSubInput) => {
	if (sub_department?.icon && typeof sub_department?.icon === 'object') {
		return sub_department.icon?.thumbnail_150 || sub_department.icon?.thumbnail;
	}
	if (typeof sub_department?.icon === 'string') return sub_department.icon;

	if (department?.icon && typeof department?.icon === 'object') {
		return department.icon?.thumbnail_150 || department.icon?.thumbnail;
	}
	if (typeof department?.icon === 'string') return department.icon;
	return undefined;
};

export const getLastSubDepartmentId = (department: Department, subDepartments: Department[]) => {
	const subDepKeys = Object.keys(subDepartments) as any;

	//get department id if not sub department is selected
	if (isEmpty(subDepartments) || subDepKeys[0] === '1' || subDepartments[subDepKeys[0]] === null) {
		return department?.id;
	}
	//get last nested sub department id if  sub departments(or sub sub department) are selected
	return Object.keys(subDepartments)
		.map((id) => +id)
		.filter((id) => subDepartments[id])
		.sort((a, b) => b - a)[0];
};

export const membersListHandler = (value: any, values: any, memberingType: string) => {
	const assigneeUsers = values['assignee_ids'];

	// console.log("value access extra,assigneeUsers ", value, assigneeUsers);

	if (!value && memberingType.toLowerCase() === 'custom') {
		return [];
	}

	if (assigneeUsers?.length && !value) {
		return assigneeUsers;
	}

	if (assigneeUsers?.length && value.length) {
		return [...new Set([...value.map((user: any) => user.user.id), ...assigneeUsers])];
	}

	return memberingType.toLowerCase() === 'extra' && value && value.length > 0
		? value.reduce((acc: any, curr: any) => [...acc, get(curr, 'user.id')], [])
		: [];
};

export const exceptionMembersListHandler = (
	value: any,
	values: any,
	userId: number,
	memberingType: string
) => {
	// console.log("value, values in exceptionMembersListHandler", value, values);
	function generateExceptionUserIds(members: any) {
		return members.reduce(
			(acc: any, curr: any) => {
				return [...acc, get(curr, 'user.id')];
			},
			userId !== values.client_user ? [get(values, 'client_user')] : []
		);
	}
	// if (memberingType.toLowerCase !== "custom" ) {
	// 	return;
	// }
	const assigneeUsers = values['assignee_ids'];
	//Merge ids if assignee has selected users
	if (assigneeUsers?.length && value) {
		const exMembers = generateExceptionUserIds(value);
		// console.log("exMembers", exMembers);
		return [...new Set([...exMembers, ...assigneeUsers])];
	}

	return memberingType.toLowerCase() === 'custom' && value && value.length > 0
		? generateExceptionUserIds(value)
		: [];
};

export function scrollToRequiredElement() {
	const aTags = document.getElementsByTagName('span');
	const searchText = 'Required';
	let found;

	for (let i = 0; i < aTags.length; i++) {
		if (aTags[i].textContent === searchText) {
			found = aTags[i];
			break;
		}
	}

	if (found) {
		(found as HTMLSpanElement)!.scrollIntoView({ block: 'start' });
	}
}

export function scrollToStatus(statusId: number) {
	const unreadStatusElement = document.querySelector(`.status-${statusId}`);
	if (unreadStatusElement) {
		unreadStatusElement.scrollIntoView({
			block: 'start',
			inline: 'start',
			behavior: 'smooth'
		});
	}

	return unreadStatusElement;
}

type SearchOrFilterMatchingCasesOptionType = {
	caseType: CaseType;
	searchQuery: string;
	selectedFilterTemplate: any;
	statuses: StatusType[];
	cases: IRootState['entity']['cases'];
};

export function isThereAnySearchOrFilterMatchingCase({
	caseType,
	searchQuery,
	selectedFilterTemplate,
	statuses,
	cases
}: SearchOrFilterMatchingCasesOptionType): boolean {
	return (
		(searchQuery.length || selectedFilterTemplate) &&
		statuses.some((status) => {
			const entityName = getCaseEntityName({
				statusId: status.id,
				caseType,
				searchQuery,
				selectedFilterTemplate
			});

			return cases?.[entityName]?.ids?.length;
		})
	);
}
