import React, { useEffect, useState } from 'react';
import { Field } from 'formik';
import AsyncSelectJs from 'shared/components/Fields/AsyncSelectJs';
import get from 'lodash/get';
import { runSideEffectsAfterSubDepartmentChange } from '../../helpers';
import { KntTwilioStyle } from 'shared/services';
import { useAppState } from 'shared/state';
import config from 'config';
import { useTranslation } from 'react-i18next';

type Props = {
	subdep: any;
	companyId: any;
	values: any;
	setFieldValue: any;
	setFormState?: any;
	titleRef?: any;
	subDepIdsRef: any;
	setAssigneeTypeValue?: any;
	isDisabled?: boolean;
	label?: string;
	labelClasses?: string;
};

export const CreateCaseSubDepComponent = React.forwardRef<any, Props>(function SubDepComponent(
	props,
	ref
) {
	const {
		subdep,
		companyId,
		values,
		setFieldValue,
		setFormState,
		titleRef,
		subDepIdsRef,
		setAssigneeTypeValue = () => {},
		isDisabled,
		label = null,
		labelClasses = ''
	} = props;
	const rselectRef = React.useRef(null);
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		//@ts-ignore
		rselectRef?.current?.focus();
	}, [subdep]);
	const hasToAutoOpen = !subDepIdsRef?.current.includes(+subdep);

	const { caseType } = useAppState();
	const isClientCaseType = config.CLIENT === caseType;

	//Just to unmount and remount to remove cached subdepartment dropdown if subdepartments dropdown has been opened but selected
	useEffect(() => {
		if (loading) {
			setLoading(false);
		}
	}, [loading]);

	const singleUnselectedSubDep =
		Object.keys(values?.sub_department).length === 1 && Object.keys(values?.sub_department)[0];

	const isSubDepIsNotSelected =
		values?.department &&
		('1' in values?.sub_department ||
			!values?.sub_department ||
			(!!singleUnselectedSubDep && !values?.sub_department?.[+singleUnselectedSubDep])) &&
		isClientCaseType;

	return (
		<div className="react-select-dropdown mb-4">
			{/*Label has been removed*/}
			{!loading && (
				<Field
					component={AsyncSelectJs}
					customStylesProps={KntTwilioStyle}
					name={`sub_department[${subdep}]`}
					label={label}
					labelClasses={labelClasses}
					placeholder={t('select_sub_department')}
					optionLabel="name"
					optionValue="id"
					isSearchable
					isClearable
					isCached
					isDisabled={isDisabled}
					reFocusAfterChange
					openMenuOnFocus={hasToAutoOpen}
					innerRef={rselectRef}
					loadOptionsKey="results"
					loadOptionsUrl={`/${companyId}/departments/${
						get(values, `sub_department[${subdep}].department.id`, 0) ||
						Object.keys(values.sub_department)[Object.keys(values.sub_department).length - 2] ||
						get(values, 'department.id')
					}/sub_departments/`}
					loadOptionsParams={(search: any) => ({
						extra: {
							search,
							ordering: 'name'
						}
					})}
					onChange={(option: any) => {
						if (titleRef !== undefined) {
							setFieldValue('title', titleRef.current);
						}

						runSideEffectsAfterSubDepartmentChange({
							values,
							subdep,
							option,
							setFieldValue
						});
						//To run subDepOnChange in useEffect
						setFormState?.((prev: any) => ({
							...prev,
							subDepOnChangeState: option,
							isSubDepChanged: true
						}));
						setTimeout(() => {
							setFormState?.((prev: any) => ({ ...prev, isSubDepChanged: false }));
						}, 200);
						if (!subDepIdsRef.current?.includes?.(option?.id))
							subDepIdsRef.current?.push?.(option?.id);

						setAssigneeTypeValue('all');
					}}
					onMenuClose={() => {
						//this means no subdepartment has been selected
						if (subdep === '1') {
							setLoading(true);
						}
					}}
				/>
			)}
			{/*
				Show Required field in Client Case Creating mode. Because In Client Case creating mode selecting
				Sub department is REQUIRED if the selected department has sub departments.

				And there was not found ways set required flag for sub department and not to allow  submitting form
				if sub department was not selected in Client Case Creating mode
			*/}
			{isSubDepIsNotSelected && <span className="text-xs text-red-500">{t('required_hint')}</span>}
		</div>
	);
});
