export function getDeletedMessageType(type: string) {
	switch (type) {
		case 'audio':
			return 'Audio';
		case 'image':
			return 'Image';
		case 'pdf':
			return 'PDF';
		case 'location':
			return 'Location';
		case 'file':
			return 'File';
		case 'video':
			return 'Video';
		case 'contact':
			return 'Contact';
		default:
			return 'Text';
	}
}
