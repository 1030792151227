import { useState } from 'react';

export function useEntityActionPopup<T>() {
	const [loading, setLoading] = useState(false);
	const [visible, setVisible] = useState<null | T>(null);

	const toggleLoading = () => setLoading((prev) => !prev);

	const showVisible = (entity: T) => setVisible(entity);
	const closePopup = () => setVisible(null);

	return {
		loading,
		visible,
		toggleLoading,
		showVisible,
		closePopup
	};
}
