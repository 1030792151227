import React from 'react';

export function IconPaypal(props: any) {
	return (
		<svg width="21" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g clipPath="url(#a)">
				<path
					d="M17.586 1.868C16.48.606 14.477.065 11.916.065H4.484a1.064 1.064 0 0 0-1.05.897L.338 20.59a.638.638 0 0 0 .63.737h4.588l1.153-7.309-.036.23a1.06 1.06 0 0 1 1.047-.898h2.18c4.284 0 7.637-1.74 8.617-6.773.03-.149.055-.294.076-.435-.123-.066-.123-.066 0 0 .292-1.86-.002-3.127-1.008-4.273"
					fill="#27346A"
				/>
				<path
					d="M8.465 5.47a.933.933 0 0 1 .402-.09h5.826c.69 0 1.334.045 1.922.14a8.078 8.078 0 0 1 1.174.28c.289.097.558.21.805.34.292-1.86-.002-3.126-1.008-4.272C16.478.606 14.477.065 11.916.065H4.484c-.524 0-.969.38-1.05.897L.338 20.588a.638.638 0 0 0 .63.738h4.588l2.39-15.16a.931.931 0 0 1 .518-.695Z"
					fill="#27346A"
				/>
				<path
					d="M18.518 6.576c-.98 5.032-4.333 6.773-8.617 6.773h-2.18a1.06 1.06 0 0 0-1.047.897L5.24 23.334a.558.558 0 0 0 .551.646H9.66a.93.93 0 0 0 .92-.785l.037-.198.729-4.62.047-.255a.93.93 0 0 1 .918-.785h.58c3.746 0 6.68-1.522 7.537-5.925.358-1.84.173-3.375-.774-4.454a3.695 3.695 0 0 0-1.059-.817c-.022.142-.047.286-.076.435Z"
					fill="#2790C3"
				/>
				<path
					d="M17.569 5.732a7.132 7.132 0 0 0-.462-.118 8.734 8.734 0 0 0-.491-.094 12.092 12.092 0 0 0-1.923-.14H8.867a.929.929 0 0 0-.919.786L6.71 14.017l-.036.23a1.06 1.06 0 0 1 1.047-.898h2.181c4.283 0 7.637-1.74 8.617-6.773.029-.149.054-.293.076-.435a5.29 5.29 0 0 0-1.026-.409"
					fill="#1F264F"
				/>
			</g>
			<defs>
				<clipPath id="a">
					<path fill="#fff" transform="translate(.325)" d="M0 0h20.35v24H0z" />
				</clipPath>
			</defs>
		</svg>
	);
}
