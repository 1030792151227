import * as React from 'react';

function SvgIconExit(props: any) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="14" height="12.2" {...props}>
			<path
				d="M3.578 12.2a.765.765 0 01-.778-.753V8.811a.7.7 0 011.4 0v1.28a.765.765 0 00.778.753h6.844a.766.766 0 00.778-.753V2.109a.766.766 0 00-.778-.753H4.978a.765.765 0 00-.778.753v1.28a.7.7 0 01-1.4 0V.753A.765.765 0 013.578 0h9.644A.766.766 0 0114 .753v10.694a.766.766 0 01-.778.753zM7.284 9a.736.736 0 01.029-1.036l1.224-1.186H.7a.678.678 0 110-1.356h7.837l-1.28-1.239a.68.68 0 010-.958.735.735 0 01.99 0l2.42 2.343a.736.736 0 010 1.065l-2.42 2.343a.711.711 0 01-.495.191A.719.719 0 017.284 9zm-.027-.024L7.284 9l.029.029z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default SvgIconExit;
