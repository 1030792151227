import React from 'react';
import { Field } from 'formik';
import AsyncSelectJs from 'shared/components/Fields/AsyncSelectJs';
import { caseFilterStyle } from 'shared/services';
import { isMobile } from 'react-device-detect';
import { useAppState } from '../../../../shared/state';
import get from 'lodash/get';
import { MemberUser } from '../../../../shared/typings/member.types';

type Props = {
	label: string;
	name: string;
	placeholder: string;
};

export function MemberFilterItem({ label, name, placeholder }: Props) {
	const { companyId } = useAppState();
	return (
		<div className="dropdown mb-4">
			<div className="mb-2 text-sm leading-none text-kgrey-menuText dark:text-white">{label}</div>
			<div className="max-w-[305px]">
				<Field
					component={AsyncSelectJs}
					customStylesProps={caseFilterStyle}
					title={label}
					labelClasses={'truncate'}
					optionLabel={(option: MemberUser) =>
						`${get(option, 'user.first_name')} ${get(option, 'user.last_name')}`
					}
					optionValue="id"
					loadOptionsUrl={`/${companyId}/members/`}
					loadOptionsParams={(search: string) => ({
						extra: {
							search
						}
					})}
					isSearchable={!isMobile}
					name={name}
					loadOptionsKey="results"
					placeholder={placeholder}
					isMulti
					isCached
				/>
			</div>
		</div>
	);
}
