import React, { memo } from 'react';
import { IFileMsgType, IMessage } from 'shared/interfaces';
import { useMessageFields } from 'shared/hooks/chat-hooks/useMessageFields';
import {
	AudioMsg,
	ContactMsg,
	FileMsg,
	ImageMsg,
	LocationMsg,
	MessageLayout,
	PDFMsg,
	ServiceMsg,
	VideoMsg
} from 'shared/components/Chat/components';
import { useChatContext } from 'shared/contexts/ChatContext';
import isEqual from 'lodash/isEqual';
// import { ErrorBoundary } from 'shared/components';

type Props = {
	message: IMessage;
	isSameFromUserWithPrev: boolean;
	isSameFromUserWithNext: boolean;
};

function areEqual(prev: any, next: any) {
	return (
		prev.message.file_type === next.message.file_type &&
		prev.message?.is_translation_shown === next.message?.is_translation_shown &&
		prev.message?.translated_text === next.message?.translated_text &&
		prev.message?.is_deleted === next.message?.is_deleted &&
		prev.message.text === next.message.text &&
		prev.message?.type === next.message?.type &&
		prev.message?.message_type === next.message?.message_type &&
		prev.message?.id === next.message?.id &&
		prev.message?.message_id === next.message?.message_id &&
		prev.message?.read_time === next.message?.read_time &&
		prev.isSameFromUserWithNext === next.isSameFromUserWithNext &&
		prev.isSameFromUserWithPrev === next.isSameFromUserWithPrev &&
		isEqual(prev.message.reacted_users, next.message.reacted_users) &&
		isEqual(prev.message.settings, next.message.settings) &&
		isEqual(prev.message.scheduled_to, next.message.scheduled_to) &&
		isEqual(prev.message?.replied_to, next.message?.replied_to)
	);
}

const getFileExtension = (filename: string) => {
	const splittedFile = (filename ?? '').split('.');
	return splittedFile[splittedFile.length - 1];
};

export const ChatMessage = memo(function ChatMessage({
	message,
	isSameFromUserWithPrev,
	isSameFromUserWithNext
}: Props) {
	const { file_type, type, messageId, message_type, file } = useMessageFields(message);
	const { setScrollToMessageHandler } = useChatContext();

	const renderedMessage = (file_type: IFileMsgType, type: string) => {
		if (file && getFileExtension(file.name) === 'svg') {
			return (
				<MessageLayout
					message={message}
					isSameFromUserWithPrev={isSameFromUserWithPrev}
					isSameFromUserWithNext={isSameFromUserWithNext}
					MessageView={<FileMsg message={message} setScrollToMsgId={setScrollToMessageHandler} />}
				/>
			);
		}
		if (file && getFileExtension(file.name) === 'pdf') {
			return (
				<MessageLayout
					message={message}
					isSameFromUserWithPrev={isSameFromUserWithPrev}
					isSameFromUserWithNext={isSameFromUserWithNext}
					MessageView={<PDFMsg message={message} setScrollToMsgId={setScrollToMessageHandler} />}
				/>
			);
		}

		switch (file_type) {
			case 'VIDEO': {
				return (
					<MessageLayout
						message={message}
						isSameFromUserWithPrev={isSameFromUserWithPrev}
						isSameFromUserWithNext={isSameFromUserWithNext}
						MessageView={
							<VideoMsg message={message} setScrollToMsgId={setScrollToMessageHandler} />
						}
					/>
				);
			}
			case 'AUDIO': {
				return (
					<MessageLayout
						message={message}
						isSameFromUserWithPrev={isSameFromUserWithPrev}
						isSameFromUserWithNext={isSameFromUserWithNext}
						MessageView={
							<AudioMsg message={message} setScrollToMsgId={setScrollToMessageHandler} />
						}
					/>
				);
			}
			case 'CONTACT': {
				return (
					<MessageLayout
						message={message}
						isSameFromUserWithPrev={isSameFromUserWithPrev}
						isSameFromUserWithNext={isSameFromUserWithNext}
						MessageView={
							<ContactMsg message={message} setScrollToMsgId={setScrollToMessageHandler} />
						}
					/>
				);
			}
			case 'LOCATION': {
				return (
					<MessageLayout
						message={message}
						isSameFromUserWithPrev={isSameFromUserWithPrev}
						isSameFromUserWithNext={isSameFromUserWithNext}
						MessageView={
							<LocationMsg message={message} setScrollToMsgId={setScrollToMessageHandler} />
						}
					/>
				);
			}
			case 'IMAGE': {
				return (
					<MessageLayout
						message={message}
						isSameFromUserWithPrev={isSameFromUserWithPrev}
						isSameFromUserWithNext={isSameFromUserWithNext}
						MessageView={
							<ImageMsg message={message} setScrollToMsgId={setScrollToMessageHandler} />
						}
						fileType="IMAGE"
					/>
				);
			}
			case 'FILE': {
				return (
					<MessageLayout
						message={message}
						isSameFromUserWithPrev={isSameFromUserWithPrev}
						isSameFromUserWithNext={isSameFromUserWithNext}
						MessageView={<FileMsg message={message} setScrollToMsgId={setScrollToMessageHandler} />}
					/>
				);
			}
			default: {
				if (
					type === 'scheduled_message' ||
					type === 'edit_scheduled_message' ||
					type === 'MESSAGE' ||
					((type === 'new_message' || type === 'edit_message') && !message_type)
				) {
					return (
						<MessageLayout
							message={message}
							setScrollToMsgId={setScrollToMessageHandler}
							isSameFromUserWithPrev={isSameFromUserWithPrev}
							isSameFromUserWithNext={isSameFromUserWithNext}
							isTextMessage
						/>
					);
				}
				return <ServiceMsg message={message} />;
			}
		}
	};

	return (
		<div dir="ltr" id={`message-${String(messageId)}`} className={'msg-wrp pb-1'}>
			{renderedMessage(file_type, type)}
		</div>
	);
},
areEqual);

// export function ChatMessage(props: Props) {
// 	return (
// 		<ErrorBoundary isCompact>
// 			<ChatMessageInner {...props} />
// 		</ErrorBoundary>
// 	);
// }
