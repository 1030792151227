import React from 'react';

export function IconSmile({ className = '' }: { className?: string }) {
	return (
		<svg
			width="22"
			height="22"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
			fill="currentColor"
		>
			<path
				d="M11 22c6.075 0 11-4.925 11-11S17.075 0 11 0 0 4.925 0 11s4.925 11 11 11z"
				fill="currentColor"
				className={className}
			/>
			<path
				d="M6.426 14.435c.027.11.714 2.676 4.574 2.676s4.547-2.567 4.574-2.676a.305.305 0 00-.145-.338.309.309 0 00-.366.046c-.012.012-1.195 1.135-4.063 1.135-2.869 0-4.052-1.123-4.063-1.134a.306.306 0 00-.365-.05.306.306 0 00-.146.341zM7.333 10.389c.844 0 1.528-.958 1.528-2.139 0-1.181-.684-2.139-1.528-2.139-.843 0-1.527.958-1.527 2.139 0 1.181.684 2.139 1.527 2.139zM14.667 10.389c.844 0 1.528-.958 1.528-2.139 0-1.181-.684-2.139-1.528-2.139s-1.528.958-1.528 2.139c0 1.181.684 2.139 1.528 2.139z"
				fill="#4F5660"
			/>
		</svg>
	);
}

// <svg
// 	width="6"
// 	height="24"
// 	className={className}
// 	fill="currentColor"
// 	xmlns="http://www.w3.org/2000/svg">
// 	<path
// 		fillRule="evenodd"
// 		clipRule="evenodd"
// 		d="M6 21a3 3 0 10-6 0 3 3 0 006 0zm0-9a3 3 0 10-6 0 3 3 0 006 0zM3 0a3 3 0 110 6 3 3 0 010-6z"
// 		// fill="#6A7480"
// 	/>
// </svg>
