import {
	IAction,
	ActionProps as LoadAllProps,
	ILoadAllError,
	IECFormAction,
	IECFormSuccess,
	IECFormError,
	ILoadAllSuccess,
	ILoadOneSuccess,
	ILoadOneError,
	ILoadOneActionProps
} from 'shared/interfaces';

type EntityAndName = {
	entityName: string;
	entity: string;
};

export enum EntityContTypes {
	LOAD_ALL_ENTITY_TRIGGER = 'LOAD_ALL_ENTITY/TRIGGER',
	LOAD_ALL_ENTITY_REQUEST = 'LOAD_ALL_ENTITY/REQUEST',
	LOAD_ALL_ENTITY_SUCCESS = 'LOAD_ALL_ENTITY/SUCCESS',
	LOAD_ALL_ENTITY_FAILURE = 'LOAD_ALL_ENTITY/FAILURE',
	LOAD_ONE_ENTITY_TRIGGER = 'LOAD_ONE_ENTITY/TRIGGER',
	LOAD_ONE_ENTITY_REQUEST = 'LOAD_ONE_ENTITY/REQUEST',
	LOAD_ONE_ENTITY_SUCCESS = 'LOAD_ONE_ENTITY/SUCCESS',
	LOAD_ONE_ENTITY_FAILURE = 'LOAD_ONE_ENTITY/FAILURE',
	FORM_ENTITY_TRIGGER = 'FORM_ENTITY/TRIGGER',
	FORM_ENTITY_REQUEST = 'FORM_ENTITY/REQUEST',
	FORM_ENTITY_SUCCESS = 'FORM_ENTITY/SUCCESS',
	FORM_ENTITY_FAILURE = 'FORM_ENTITY/FAILURE',
	INCREMENT_ENTITY_TOTAL = 'INCREMENT_ENTITY_TOTAL',
	DECREMENT_ENTITY_TOTAL = 'DECREMENT_ENTITY_TOTAL',
	RESET_ENTITY_LIST = 'RESET_ENTITY_LIST'
}

export type IncrementEntityTotal = IAction<EntityContTypes.INCREMENT_ENTITY_TOTAL, EntityAndName>;
export type DecrementEntityTotal = IAction<EntityContTypes.DECREMENT_ENTITY_TOTAL, EntityAndName>;
export type ResetEntityList = IAction<EntityContTypes.RESET_ENTITY_LIST, EntityAndName>;

export function incrementEntityTotal(entity: string, entityName: string): IncrementEntityTotal {
	return {
		type: EntityContTypes.INCREMENT_ENTITY_TOTAL,
		payload: {
			entityName,
			entity
		}
	};
}

export function decrementEntityTotal(entity: string, entityName: string): DecrementEntityTotal {
	return {
		type: EntityContTypes.DECREMENT_ENTITY_TOTAL,
		payload: {
			entityName,
			entity
		}
	};
}

export function resetEntityList(entity: string, entityName: string): ResetEntityList {
	return {
		type: EntityContTypes.RESET_ENTITY_LIST,
		payload: {
			entityName,
			entity
		}
	};
}

export type LoadAllActionRequest = IAction<EntityContTypes.LOAD_ALL_ENTITY_REQUEST, LoadAllProps>;

export type LoadAllActionSuccess = IAction<
	EntityContTypes.LOAD_ALL_ENTITY_SUCCESS,
	ILoadAllSuccess
>;

export type LoadAllActionFailure = IAction<EntityContTypes.LOAD_ALL_ENTITY_FAILURE, ILoadAllError>;

export function loadAllRequest(payload: LoadAllProps): LoadAllActionRequest {
	return {
		type: EntityContTypes.LOAD_ALL_ENTITY_REQUEST,
		payload
	};
}

export function loadAllSuccess(payload: ILoadAllSuccess): LoadAllActionSuccess {
	return {
		type: EntityContTypes.LOAD_ALL_ENTITY_SUCCESS,
		payload
	};
}

export function loadAllFailure(payload: ILoadAllError): LoadAllActionFailure {
	return {
		type: EntityContTypes.LOAD_ALL_ENTITY_FAILURE,
		payload
	};
}

export type LoadOneActionRequest = IAction<
	EntityContTypes.LOAD_ONE_ENTITY_REQUEST,
	ILoadOneActionProps
>;

export type LoadOneActionSuccess = IAction<
	EntityContTypes.LOAD_ONE_ENTITY_SUCCESS,
	ILoadOneSuccess
>;

export type LoadOneActionFailure = IAction<EntityContTypes.LOAD_ONE_ENTITY_FAILURE, ILoadOneError>;

export function loadOneRequest(payload: ILoadOneActionProps): LoadOneActionRequest {
	return {
		type: EntityContTypes.LOAD_ONE_ENTITY_REQUEST,
		payload
	};
}

export function loadOneSuccess(payload: ILoadOneSuccess): LoadOneActionSuccess {
	return {
		type: EntityContTypes.LOAD_ONE_ENTITY_SUCCESS,
		payload
	};
}

export function loadOneFailure(payload: ILoadOneError): LoadOneActionFailure {
	return {
		type: EntityContTypes.LOAD_ONE_ENTITY_FAILURE,
		payload
	};
}

export type FormActionRequest = IAction<EntityContTypes.FORM_ENTITY_REQUEST, IECFormAction>;

export type FormActionSuccess = IAction<EntityContTypes.FORM_ENTITY_SUCCESS, IECFormSuccess>;

export type FormActionFailure = IAction<EntityContTypes.FORM_ENTITY_FAILURE, IECFormError>;

export function formRequest(payload: IECFormAction): FormActionRequest {
	return {
		type: EntityContTypes.FORM_ENTITY_REQUEST,
		payload
	};
}

export function formSuccess(payload: IECFormSuccess): FormActionSuccess {
	return {
		type: EntityContTypes.FORM_ENTITY_SUCCESS,
		payload
	};
}

export function formFailure(payload: IECFormError): FormActionFailure {
	return {
		type: EntityContTypes.FORM_ENTITY_FAILURE,
		payload
	};
}

export default {
	loadAllRequest,
	loadAllSuccess,
	loadAllFailure,
	loadOneRequest,
	loadOneSuccess,
	loadOneFailure,
	formRequest,
	formSuccess,
	formFailure,
	incrementEntityTotal,
	decrementEntityTotal,
	resetEntityList
};
