import React from 'react';
import { useChatContext, useChatInputContext } from 'shared/contexts';
import { FaAngleDown } from 'react-icons/fa';
import { useAppState } from '../../state';
import { useGetEntity } from '../useGetEntity';
import clsx from 'clsx';
import { MentionScrollButton } from '../../components/Chat/components/MentionScrollButton';

export function ScrollDownBtn() {
	const {
		isInBottom,
		entityId,
		entityType,
		isLastPageFetched,
		setIsFirstPageFetched,
		scrollToBottom,
		isScheduledChat
	} = useChatContext();
	const { reply, editingMessage } = useChatInputContext();

	const oneEntity = entityType === 'case' ? 'caseMessages' : 'imsMessages';
	const oneEntityName = `All${entityType === 'case' ? 'Case' : 'Ims'}Messages-${entityId}-${
		isScheduledChat ? 'scheduled' : 'normal'
	}`;
	const { all: messages } = useGetEntity({
		entityId,
		entity: oneEntity,
		entityName: oneEntityName
	});

	const { setIsDelayedMessage } = useChatInputContext();
	const { unreadMessagesCount, groupsUnreadCountData } = useAppState();
	const allUnreadCount =
		entityType === 'case' ? unreadMessagesCount?.[entityId] : groupsUnreadCountData?.[entityId];

	const handleScrollingToBottom = () => {
		//TODO: set start first page flag => check later side-effects if any
		setIsFirstPageFetched(false);

		if (!isLastPageFetched) {
			setIsDelayedMessage(true);

			return;
		}

		scrollToBottom();
	};

	return (
		<>
			{!isInBottom ? (
				<div
					className={clsx('chat-inp-controls__scroll-bottom  absolute -right-1 -top-8 z-10 ', {
						'-top-24': reply || editingMessage
					})}
				>
					<MentionScrollButton allUnreadCount={allUnreadCount} />
					<div
						className={clsx(
							'flex-centered group h-8 w-8 cursor-pointer rounded-full border border-kgrey-border-solid bg-white shadow-lg transition hover:bg-primary',
							{ 'pointer-events-none': !messages.isFetched }
						)}
						onClick={handleScrollingToBottom}
					>
						{allUnreadCount && allUnreadCount > 0 ? (
							<div className="order flex-centered absolute -top-4 h-5 w-5 rounded-full  border-kgrey-border-solid bg-primary text-xs text-white">
								{allUnreadCount}
							</div>
						) : null}

						<span className={clsx('text-kdark/80 transition group-hover:text-white')}>
							<FaAngleDown
								className={clsx({
									'opacity-60': !messages.isFetched
								})}
							/>
						</span>
					</div>
				</div>
			) : null}
		</>
	);
}
