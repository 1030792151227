import { useAxios, useUnreadCount, useUpdateEntities } from 'shared/hooks';
import { ChatEntityType, useAppState } from 'shared/state';
import { useGroupUnreadLoad } from 'pages/GroupChat/hooks/useGroupUnreadLoad';

export function useChatFunctions() {
	const { updateEntities } = useUpdateEntities();
	const {
		unreadMessagesCount,
		setUnreadMessagesCount,
		groupsUnreadCountData,
		setGroupUnreadMessage,
		companyId,
		funcDecrementUnread
	} = useAppState();
	//Fetch unread count for single case
	const { fetchData: fetchSingleUnreadCount } = useAxios({
		url: '',
		dynamicUrl: true,
		cb: {
			success: (data, args) => {
				const entityId = args[0]?.entityId;
				const unreadCount = data[`case_${entityId}`];
				if (unreadMessagesCount && entityId) {
					//Set unread count each time we delete case message
					setUnreadMessagesCount({ ...unreadMessagesCount, [entityId]: unreadCount || 0 });
				}
			},
			error: (error, args) => {}
		}
	});
	const { fetchCompanyAllUnreadCounts } = useUnreadCount();

	function deleteMessage(message: any) {
		const entity = message.entity_type === 'case' ? 'caseMessages' : 'imsMessages';
		const entityId = message.entity_id;

		updateEntities({
			entity: entity,
			entityId: message.custom_uuid || '',
			updatingData: {
				is_deleted: true,
				file: null,
				text: ''
			}
		});

		const lastMessageTextEntity = message.entity_type === 'case' ? 'cases' : 'ims-chats';
		const { prev_text, prev_type, is_last: isLastMsgDeleted } = message;

		if (isLastMsgDeleted) {
			updateEntities({
				entity: lastMessageTextEntity,
				entityId,
				updatingData: {
					last_message_text: prev_text,
					last_message_type: prev_type
				}
			});
		}
		//Update unread count when deleting message
		//decrement unread count in case if there is any
		//TODO: CHANGE URL IN BUSINESS APP
		if (message.entity_type === 'case' && unreadMessagesCount && entityId in unreadMessagesCount) {
			fetchSingleUnreadCount({
				url: `/${companyId}/message_counts/?entity_type=case&entity_id=${entityId}&as_client_company=true&case_type=client`,
				entityId
			});
			return;
		}
		//decrement unread count in group chat if there is any
		if (
			message.entity_type === 'ims' &&
			groupsUnreadCountData &&
			entityId in groupsUnreadCountData
		) {
			if (companyId) {
				fetchCompanyAllUnreadCounts({
					groupUnread: true
				});
			}
		}
	}

	type UnreadRemoveType = {
		entityType: string;
		entityId: number;

		statusId: number;
	};
	//remove unread count when we receive {type: no_unread_left}
	function removeUnread({ entityType, entityId, statusId }: UnreadRemoveType) {
		if (entityType === 'case') {
			if (unreadMessagesCount && hasUnreadCount({ unreadObj: unreadMessagesCount, entityId })) {
				const unreadCountObj = prepareNewUnreadCountObject(unreadMessagesCount, entityId);
				setUnreadMessagesCount(unreadCountObj);
			}
		}
		//decrement unread count in group chat if there is any
		if (
			entityType === 'ims' &&
			groupsUnreadCountData &&
			hasUnreadCount({ unreadObj: groupsUnreadCountData, entityId })
		) {
			setGroupUnreadMessage(prepareNewUnreadCountObject(groupsUnreadCountData, entityId));
		}

		function prepareNewUnreadCountObject(
			unreadCountObject: Record<string, number>,
			entityId: number
		) {
			const unreadCountObjectCloned = { ...unreadCountObject };
			delete unreadCountObjectCloned[entityId];
			return unreadCountObjectCloned;
		}
	}

	type DecrementUnreadType = {
		entityType: ChatEntityType;
		entityId: number;
	};

	//Decrementing case or chat unread count when receive message_read action because we read it in our another device
	function decrementUnread({ entityType, entityId }: DecrementUnreadType) {
		funcDecrementUnread(entityId, entityType);
	}

	type HasUnreadCount = {
		unreadObj: Record<string, number>;
		entityId: number;
	};

	function hasUnreadCount({ unreadObj, entityId }: HasUnreadCount): boolean {
		return entityId in unreadObj && unreadObj[entityId] >= 0;
	}

	return {
		deleteMessage,
		removeUnread,
		decrementUnread,
		hasUnreadCount
	};
}
