import React from 'react';
import { useTranslation } from 'react-i18next';
type Props = {
	resetForm: any;
	title: string;
	isEditing?: boolean;
};
export function FormHeader({ resetForm, title, isEditing = false }: Props) {
	const { t } = useTranslation();
	return (
		<div className="case-create-header  flex-center-between -ml-6 -mr-6 mb-5 border-b border-black/10 px-4 pb-4">
			<h2 className={'text-base font-medium'}>{title}</h2>
			{!isEditing ? (
				<button
					className="text-sm text-kgrey-dark-2 underline hover:no-underline"
					onClick={resetForm}
					type="button"
				>
					{t('clear')}
				</button>
			) : null}
		</div>
	);
}
