import { useSelector } from 'react-redux';
import Selectors from 'modules/entity/selectors';
const getOne = Selectors.getOne();

type EntityType = {
	entityId: number | string;
	entity: string;
	entityName: string;
};

export function useGetEntityOne({ entityId, entity, entityName }: EntityType) {
	return useSelector((state) =>
		getOne(state, {
			id: entityId,
			entity,
			name: entityName
		})
	);
}
