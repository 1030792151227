import qs from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import { useCallback, useRef } from 'react';

export function useQueryParams<T>() {
	const location = useLocation();
	const history = useHistory();
	const query = qs.parse(location.search);

	const navigateTo = useRef(history.push).current;

	const changeUrlParams = useCallback(
		(params: T, replace = false) => {
			const newQuery = { ...query, ...params } as T;
			navigateTo({
				search: qs.stringify(replace ? params : newQuery)
			});
		},
		[query, navigateTo]
	);

	const removeOneQueryParam = useCallback(
		(param: string) => {
			const newQuery = { ...query };

			const newParams = Object.keys(newQuery)
				.filter((key) => key !== param)
				.reduce((acc: any, curr) => {
					return { ...acc, [curr]: newQuery[curr] };
				}, {});

			navigateTo({ search: qs.stringify(newParams) });
		},
		[query, navigateTo]
	);

	const removeMultipleParams = useCallback(
		(paramList: string[]) => {
			const newQuery = { ...query };

			const newParams = Object.keys(newQuery)
				.filter((key) => !paramList.includes(key))
				.reduce((acc: any, curr) => {
					return { ...acc, [curr]: newQuery[curr] };
				}, {});

			navigateTo({ search: qs.stringify(newParams) });
		},
		[query, navigateTo]
	);

	const removeQueryParams = useCallback(() => {
		navigateTo({
			search: qs.stringify({})
		});
	}, [navigateTo]);

	const handleSort = (field: string, orderField: string) => {
		const search = {
			...query,
			[orderField]: field,
			page: 1
		};

		navigateTo({
			search: qs.stringify(search)
		});
	};

	return {
		changeUrlParams,
		query,
		removeQueryParams,
		handleSort,
		removeOneQueryParam,
		removeMultipleParams
	};
}
